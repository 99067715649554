export function obterListaIndicadorPresenca() {
  return (
    [
      { label: 'Não se aplica (ex.: Nota Fiscal complementar ou de ajuste)', value: 'NAOSEAPLICA' },
      { label: 'Operação presencial', value: 'PRESENCIAL' },
      { label: 'Operação não presencial, internet', value: 'INTERNET' },
      { label: 'Operação não presencial, teleatendimento', value: 'TELEATENDIMENTO' },
      { label: 'NFC-e entrega em domicílio', value: 'NFCEENTREGADOMICILIO' },
      { label: 'Operação presencial, fora do estabelecimento', value: 'PRESENCIALFORAESTABELECIMENTO' },
      { label: 'Não presencial, outros', value: 'OUTROS' },
    ]
  );
}
