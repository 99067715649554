export function obterListaCteTipoTomador() {
  return (
    [
      { label: '0 - Remetente', value: 'REMETENTE' },
      { label: '1 - Expedidor', value: 'EXPEDIDOR' },
      { label: '2 - Recebedor', value: 'RECEBEDOR' },
      { label: '3 - Destinatário', value: 'DESTINATARIO' },
      { label: '4 - Outros', value: 'OUTROS' },
    ]
  );
}
