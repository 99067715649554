export function obterListaCsosn() {
  return (
    [
      { label: '101 - Tributada pelo Simples Nacional COM permissão de crédito', value: '101' },
      { label: '102 - Tributada pelo Simples Nacional SEM permissão de crédito', value: '102' },
      { label: '103 - Isenção do ICMS no Simples Nacional para faixa de receita bruta', value: '103' },
      { label: '201 - Tributada pelo Simples Nacional COM permissão de crédito e com cobrança do ICMS por substituição tributária', value: '201' },
      { label: '202 - Tributada pelo Simples Nacional SEM permissão de crédito e com cobrança do ICMS por substituição tributária', value: '202' },
      { label: '203 - Isenção do ICMS no Simples Nacional para faixa de receita bruta e com cobrança do ICMS por substituição tributária', value: '203' },
      { label: '300 - Imune', value: '300' },
      { label: '400 - Não tributada pelo Simples Nacional', value: '400' },
      { label: '500 - ICMS cobrado anteriormente por substituição tributária (substituído) ou por antecipação', value: '500' },
      { label: '900 - Outros', value: '900' },
    ]
  );
}
