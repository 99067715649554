import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputNumber } from 'primereact/inputnumber';

import * as actions from '../../../../store/modules/auth/actions';

import { displayErrors } from '../../../../util/diversos';
import { Input200px } from '../../../../styles/inputGroup';

import Card from '../../../../components/card';
import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Loading from '../../../loading';

export default function EstampariaPrecificacao() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [id, setId] = useState(0);
  const [valorSilkTela, setValorSilkTela] = useState(0);
  const [valorSublimacao, setValorSublimacao] = useState(0);
  const [naoCobrarAcimaDe, setNaoCobrarAcimaDe] = useState(0);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const newTitle = 'Precificação (Estamparia) - Edição';

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getData() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const params = `/estampariaprecificacao?idUsuario=${idUsuario}`;

        const { data } = await axiosAdmin.get(params);

        setId(data.id);
        setValorSilkTela(data.valorSilkTela);
        setValorSublimacao(data.valorSublimacao);
        setNaoCobrarAcimaDe(data.naoCobrarAcimaDe);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const shippingData = {
        idUsuario,
        valorSilkTela,
        valorSublimacao,
        naoCobrarAcimaDe,
      };

      await axiosAdmin.put(`/estampariaprecificacao/${id}`, shippingData);

      toast.success('Precificação (Estamparia) atualizada com sucesso');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErros = false;

    if (formErros) return;

    salvarRegistro();
  };

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>Configuração do Sistema - Edição</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Valor da Tela de Silk
              </Input200px>
              <InputNumber
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorSilkTela}
                onValueChange={e => setValorSilkTela(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Valor de Sublimação
              </Input200px>
              <InputNumber
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorSublimacao}
                onValueChange={e => setValorSublimacao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Não Cobrar Acima De (Íncluindo)
              </Input200px>
              <InputNumber
                mode="decimal"
                inputId="minmaxfraction"
                minFractionDigits={3}
                maxFractionDigits={3}
                value={naoCobrarAcimaDe}
                onValueChange={e => setNaoCobrarAcimaDe(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
