import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { addLocale } from 'primereact/api';
import { get, orderBy } from 'lodash';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors, formataMoeda } from '../../../../util/diversos';
import { fillColumns } from '../../../../components/table';
import { Input100px } from '../../../../styles/inputGroup';

import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Card from '../../../../components/card';
import Loading from '../../../loading';

import CadastroEstampariaRecebimentoPedido from './cadastroEstampariaRecebimentoPedido';
import ConsultaParticipante from '../../participante/consultaParticipante';

export default function ConsultaEstampariaRecebimentoPedido() {
  const [isLoading, setIsLoading] = useState(false);
  const [showCadastro, setShowCadastro] = useState(false);
  const [showConsultaParticipante, setShowConsultaParticipante] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataTableList, setDataTableList] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [idCliente, setIdCliente] = useState(null);
  const [nomeCliente, setNomeCliente] = useState('');
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [payloadCadastro, setPayloadCadastro] = useState({});
  const [columnsSetup] = useState([
    { field: 'id', header: 'Número', sort: true },
    { field: 'data', header: 'Data', sort: true },
    { field: 'nomePedido', header: 'Nome do Pedido', sort: true },
    { field: 'cliente.razaoSocial', header: 'Razão Social do Cliente', sort: false },
    { field: 'valorTotal', header: 'Valor Pedido', sort: false },
    { field: 'valorPago', header: 'Valor Pago', sort: false },
  ]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getData(_idCliente, page, size) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/estampariapedido/search-em-aberto-por-cliente?idUsuario=${idUsuario}&idCliente=${_idCliente}`;

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleReceber = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setPayloadCadastro({ id });
      setShowCadastro(true);
    } else {
      toast.warning('Por favor, selecione o pedido para recebimento');
    }
  };

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    getData(idCliente, event.page, event.rows);
  };

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const onHideConsultaParticipante = () => {
    setShowConsultaParticipante(false);
  };

  const childToParentCliente = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdCliente(e.id);
      }
      if (e.razaoSocial) {
        setNomeCliente(e.razaoSocial);
      }

      setShowConsultaParticipante(false);

      const _lazyParams = { ...lazyParams, first: 0, page: 0 };

      setLazyParams(_lazyParams);

      getData(e.id, 0, lazyParams.rows);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentCadastro = (payloadDataTableList, id) => {
    if (payloadDataTableList) {
      if (payloadDataTableList.valorPago < payloadDataTableList.valorTotal) {
        const index = dataTableList.map(e => e.id).indexOf(id);

        if (index >= 0) {
          dataTableList[index] = payloadDataTableList;
        }
      } else {
        const _itens = dataTableList.filter(val => val.id !== id);
        setDataTableList(_itens);
      }
    }

    setShowCadastro(false);
  };

  const valorRestanteBodyTemplate = (rowData) => {
    let valorRestante = rowData.valorTotal - rowData.valorPago;

    if (valorRestante < 0) {
      valorRestante = 0;
    }

    return formataMoeda(valorRestante);
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroEstampariaRecebimentoPedido payloadCadastro={payloadCadastro} childToParentCadastro={childToParentCadastro} />
      </Dialog>

      <Dialog
        header="Consulta de Participantes"
        visible={showConsultaParticipante}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaParticipante()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParentCliente} />
      </Dialog>

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              RECEBIMENTO DE PEDIDO
            </h6>
          </div>
          <div className="row align-items-center">
            <div className="col-9">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Cliente
                </Input100px>
                <InputText
                  disabled
                  maxLength={255}
                  value={nomeCliente}
                  onChange={e => setNomeCliente(e.target.value)}
                />
                <Button
                  icon="pi pi-search"
                  className="p-button-warning"
                  onClick={() => setShowConsultaParticipante(true)}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <button
                type="button"
                title="Receber"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleReceber(e)}
              >
                <i className="pi pi-pencil"> Receber</i>
              </button>
            </div>
          </div>
          <br />
          <div className="row align-items-center" style={{ marginTop: '10px' }}>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
            <Column field="rating" header="Valor Restante" body={valorRestanteBodyTemplate}></Column>
          </DataTable>
        </div>
      </Card>
    </>
  );
}
