import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { addLocale } from 'primereact/api';

import * as actions from '../../../../store/modules/auth/actions';

import { displayErrors, tratarNumero, findInList, converteData, formataDataMoment } from '../../../../util/diversos';
import { Input100px, Input155px, Input120px } from '../../../../styles/inputGroup';
import { fillColumns } from '../../../../components/table';

import Card from '../../../../components/card';
import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Loading from '../../../loading';

import ConsultaParticipante from '../../participante/consultaParticipante';
import ConsultaVendedor from '../../vendedor/consultaVendedor';
import ConsultaProduto from '../../produto/consultaProduto';
import ConsultaItemPadrao from '../estampariaItemPadrao/consultaEstampariaItemPadrao';

export default function CadastroEstampariaOrcamento({ payloadCadastro, childToParentCadastro }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);

  const [showConsultaParticipante, setShowConsultaParticipante] = useState(false);
  const [showConsultaVendedor, setShowConsultaVendedor] = useState(false);
  const [showConfirmDeleteItem, setShowConfirmDeleteItem] = useState(false);
  const [showConfirmDeleteItemAcessorio, setShowConfirmDeleteItemAcessorio] = useState(false);
  const [flagCalcularValorOutro, setFlagCalcularValorOutro] = useState(0);
  const [flagCalcularTela, setFlagCalcularTela] = useState(false);
  const [dataOrcamento, setDataOrcamento] = useState(null);
  const [dataValidade, setDataValidade] = useState(null);
  const [nomeOrcamento, setNomeOrcamento] = useState('');
  const [condicaoPagamento, setCondicaoPagamento] = useState('');
  const [observacao, setObservacao] = useState('');
  const [idCliente, setIdCliente] = useState(null);
  const [nomeCliente, setNomeCliente] = useState('');
  const [idVendedor, setIdVendedor] = useState('');
  const [nomeVendedor, setNomeVendedor] = useState('');
  const [codigoVendedor, setCodigoVendedor] = useState('');
  const [valorTotal, setValorTotal] = useState(0);
  const [itemActiveTabIndex, setItemActiveTabIndex] = useState(0);
  const [tipoServicoActiveTabIndex, setTipoServicoActiveTabIndex] = useState(0);
  const [mostrarAbaTipoServico, setMostrarAbaTipoServico] = useState(false);
  const [mostrarManga, setMostrarManga] = useState(false);
  const [mostrarMangaAcabamento, setMostrarMangaAcabamento] = useState(false);
  const [mostrarGola, setMostrarGola] = useState(false);
  const [mostrarGolaAcabamento, setMostrarGolaAcabamento] = useState(false);
  const [mostrarBolso, setMostrarBolso] = useState(false);
  const [valorSilkTela, setValorSilkTela] = useState(0);
  const [valorSublimacao, setValorSublimacao] = useState(0);
  const [naoCobrarAcimaDe, setNaoCobrarAcimaDe] = useState(0);
  const [listaItem, setListaItem] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [showCadastroItem, setShowCadastroItem] = useState(false);
  const [columnsSetupItem] = useState([
    { field: 'item', header: 'Ítem', sort: true },
    { field: 'produto', header: 'Registro', sort: true },
    { field: 'descricao', header: 'Descrição', sort: true },
    { field: 'tamanhoDescricao', header: 'Tamanho', sort: false },
    { field: 'quantidade', header: 'Quantidade', sort: false },
    { field: 'precoMercadoria', header: 'Base', sort: false },
    { field: 'precoOutro', header: 'Outros', sort: false },
    { field: 'precoTelaSilk', header: 'Tela Silk', sort: false },
    { field: 'precoSublimacao', header: 'Sublimação', sort: false },
    { field: 'precoFinal', header: 'Preço Final', sort: false },
    { field: 'totalItem', header: 'Total Ítem', sort: false },
  ]);
  const [listaItemAcessorio, setListaItemAcessorio] = useState([]);
  const [selectedItensAcessorios, setSelectedItensAcessorios] = useState(null);
  const [showCadastroItemAcessorio, setShowCadastroItemAcessorio] = useState(false);
  const [editandoItemAcessorio, setEditandoItemAcessorio] = useState(0);
  const [columnsSetupItemAcessorio] = useState([
    { field: 'descricaoAcessorio', header: 'Acessório', sort: true },
    { field: 'quantidade', header: 'Quantidade', sort: false },
    { field: 'valorAcrescimo', header: 'Valor Acréscimo', sort: false },
    { field: 'totalAcrescimo', header: 'Total Acréscimo', sort: false },
  ]);
  const [editandoItem, setEditandoItem] = useState(0);
  const [showConsultaProduto, setShowConsultaProduto] = useState(false);
  const [showConsultaItemPadrao, setShowConsultaItemPadrao] = useState(false);
  const [itemIdProduto, setItemIdProduto] = useState(0);
  const [itemDescricaoProduto, setItemDescricaoProduto] = useState('');
  const [itemUnidadeProduto, setItemUnidadeProduto] = useState('');
  const [itemTipoProduto, setItemTipoProduto] = useState('');
  const [itemQuantidade, setItemQuantidade] = useState(0);
  const [itemPrecoMercadoria, setItemPrecoMercadoria] = useState(0);
  const [itemPrecoOutro, setItemPrecoOutro] = useState(0);
  const [itemPrecoFinal, setItemPrecoFinal] = useState(0);
  const [itemTotalItem, setItemTotalItem] = useState(0);
  const [itemModelo, setItemModelo] = useState(null);
  const [itemTamanho, setItemTamanho] = useState(null);
  const [itemMalha, setItemMalha] = useState(null);
  const [itemCor, setItemCor] = useState(null);
  const [itemBolso, setItemBolso] = useState(null);
  const [itemBolsoCor, setItemBolsoCor] = useState(null);
  const [itemGola, setItemGola] = useState(null);
  const [itemGolaAcabamento, setItemGolaAcabamento] = useState(null);
  const [itemGolaCor, setItemGolaCor] = useState(null);
  const [itemManga, setItemManga] = useState(null);
  const [itemMangaAcabamento, setItemMangaAcabamento] = useState(null);
  const [itemMangaCor, setItemMangaCor] = useState(null);
  const [itemMangaAcabamentoCor, setItemMangaAcabamentoCor] = useState(null);
  const [itemFrenteSilkar, setItemFrenteSilkar] = useState(false);
  const [itemFrenteSilk, setItemFrenteSilk] = useState(null);
  const [itemFrenteSublimar, setItemFrenteSublimar] = useState(false);
  const [itemFrenteSublimacao, setItemFrenteSublimacao] = useState(null);
  const [itemFrenteBordar, setItemFrenteBordar] = useState(false);
  const [itemFrenteBordado, setItemFrenteBordado] = useState(null);
  const [itemCostasSilkar, setItemCostasSilkar] = useState(false);
  const [itemCostasSilk, setItemCostasSilk] = useState(null);
  const [itemCostasSublimar, setItemCostasSublimar] = useState(false);
  const [itemCostasSublimacao, setItemCostasSublimacao] = useState(null);
  const [itemCostasBordar, setItemCostasBordar] = useState(false);
  const [itemCostasBordado, setItemCostasBordado] = useState(null);
  const [itemMangaDireitaSilkar, setItemMangaDireitaSilkar] = useState(false);
  const [itemMangaDireitaSilk, setItemMangaDireitaSilk] = useState(null);
  const [itemMangaDireitaSublimar, setItemMangaDireitaSublimar] = useState(false);
  const [itemMangaDireitaSublimacao, setItemMangaDireitaSublimacao] = useState(null);
  const [itemMangaDireitaBordar, setItemMangaDireitaBordar] = useState(false);
  const [itemMangaDireitaBordado, setItemMangaDireitaBordado] = useState(null);
  const [itemMangaEsquerdaSilkar, setItemMangaEsquerdaSilkar] = useState(false);
  const [itemMangaEsquerdaSilk, setItemMangaEsquerdaSilk] = useState(null);
  const [itemMangaEsquerdaSublimar, setItemMangaEsquerdaSublimar] = useState(false);
  const [itemMangaEsquerdaSublimacao, setItemMangaEsquerdaSublimacao] = useState(null);
  const [itemMangaEsquerdaBordar, setItemMangaEsquerdaBordar] = useState(false);
  const [itemMangaEsquerdaBordado, setItemMangaEsquerdaBordado] = useState(null);
  const [itemAcessorio, setItemAcessorio] = useState(null);
  const [itemAcessorioQuantidade, setItemAcessorioQuantidade] = useState(0);
  const [itemAcessorioValorAcrescimo, setItemAcessorioValorAcrescimo] = useState(0);
  const [itemAcessorioTotalAcrescimo, setItemAcessorioTotalAcrescimo] = useState(0);
  const [listaCor, setListaCor] = useState([]);
  const [listaTamanho, setListaTamanho] = useState([]);
  const [listaModelo, setListaModelo] = useState([]);
  const [listaMalha, setListaMalha] = useState([]);
  const [listaManga, setListaManga] = useState([]);
  const [listaMangaAcabamento, setListaMangaAcabamento] = useState([]);
  const [listaGola, setListaGola] = useState([]);
  const [listaGolaAcabamento, setListaGolaAcabamento] = useState([]);
  const [listaBolso, setListaBolso] = useState([]);
  const [listaSilk, setListaSilk] = useState([]);
  const [listaSublimacao, setListaSublimacao] = useState([]);
  const [listaBordado, setListaBordado] = useState([]);
  const [listaCorCompleto, setListaCorCompleto] = useState([]);
  const [listaTamanhoCompleto, setListaTamanhoCompleto] = useState([]);
  const [listaModeloCompleto, setListaModeloCompleto] = useState([]);
  const [listaMalhaCompleto, setListaMalhaCompleto] = useState([]);
  const [listaMangaCompleto, setListaMangaCompleto] = useState([]);
  const [listaMangaAcabamentoCompleto, setListaMangaAcabamentoCompleto] = useState([]);
  const [listaGolaCompleto, setListaGolaCompleto] = useState([]);
  const [listaGolaAcabamentoCompleto, setListaGolaAcabamentoCompleto] = useState([]);
  const [listaBolsoCompleto, setListaBolsoCompleto] = useState([]);
  const [listaSilkCompleto, setListaSilkCompleto] = useState([]);
  const [listaSublimacaoCompleto, setListaSublimacaoCompleto] = useState([]);
  const [listaBordadoCompleto, setListaBordadoCompleto] = useState([]);
  const [listaAcessorio, setListaAcessorio] = useState([]);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const atualizarTotal = () => {
      setItemTotalItem(itemQuantidade * itemPrecoFinal);
    };

    function calcularValorOutro() {
      let valorOutro = 0;
      let valorTelaSilk = 0;
      let totalTelas = 0;
      let qtdeItemTotal = 0;
      let numeroOcorrenciasSilk = 0;

      if (flagCalcularTela) {
        let _incluirSublimacao = false;

        listaItem.forEach(element => {
          qtdeItemTotal = qtdeItemTotal + element.quantidade;

          if (totalTelas <= 0) {
            element.listaEstampariaOrcamentoTipoServico.forEach(tipoServico => {
              totalTelas = totalTelas + tratarNumero(findInList(listaSilk, tipoServico.silk, 'numeroTelas'));
            });
          }
          element.listaEstampariaOrcamentoTipoServico.forEach(tipoServico => {
            if (tipoServico.silk) {
              numeroOcorrenciasSilk = numeroOcorrenciasSilk + 1;
            }
            if (tipoServico.sublimar) {
              _incluirSublimacao = true;
            }
          });
        });

        if (qtdeItemTotal < naoCobrarAcimaDe) {
          const _newListaItem = [];

          listaItem.forEach(element => {
            const _element = element;

            if (element.recalcular) {
              let _precoFinal = element.precoMercadoria + element.precoOutro;

              if (totalTelas && totalTelas > 0) {
                valorTelaSilk = tratarNumero((valorSilkTela * totalTelas) / qtdeItemTotal);
                valorTelaSilk = Math.round(valorTelaSilk * 100) / 100;

                _element.precoTelaSilk = valorTelaSilk;
                _precoFinal = _precoFinal + valorTelaSilk;
              }

              if (_incluirSublimacao) {
                _element.precoSublimacao = (valorSublimacao / qtdeItemTotal);
                _element.precoSublimacao = Math.round(_element.precoSublimacao * 100) / 100;
                _precoFinal = _precoFinal + _element.precoSublimacao;
              }

              _precoFinal = Math.round(_precoFinal * 100) / 100;
              _element.precoBruto = _precoFinal;
              _element.precoFinal = _precoFinal;

              let _totalItem = element.quantidade * _precoFinal;
              _totalItem = Math.round(_totalItem * 100) / 100;
              _element.totalItem = _totalItem;
            }

            _newListaItem.push(_element);
          });

          setListaItem(_newListaItem);

          let _valorTotal = 0;
          _newListaItem.forEach(element => {
            _valorTotal = _valorTotal + element.totalItem;
          });
          setValorTotal(_valorTotal);
        }

        setFlagCalcularTela(false);
      } else {
        valorOutro = valorOutro + tratarNumero(findInList(listaModelo, itemModelo, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaTamanho, itemTamanho, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaMalha, itemMalha, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaCor, itemCor, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaBolso, itemBolso, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaGola, itemGola, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaGolaAcabamento, itemGolaAcabamento, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaManga, itemManga, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaMangaAcabamento, itemMangaAcabamento, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaSilk, itemFrenteSilk, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaSilk, itemCostasSilk, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaSilk, itemMangaDireitaSilk, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaSilk, itemMangaEsquerdaSilk, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaSublimacao, itemFrenteSublimacao, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaSublimacao, itemCostasSublimacao, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaSublimacao, itemMangaDireitaSublimacao, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaSublimacao, itemMangaEsquerdaSublimacao, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaBordado, itemFrenteBordado, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaBordado, itemCostasBordado, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaBordado, itemMangaDireitaBordado, 'valorAcrescimo'));
        valorOutro = valorOutro + tratarNumero(findInList(listaBordado, itemMangaEsquerdaBordado, 'valorAcrescimo'));

        if (listaItemAcessorio) {
          listaItemAcessorio.forEach(_acessorio => {
            valorOutro = valorOutro + tratarNumero(_acessorio.totalAcrescimo);
          });
        }

        setItemPrecoOutro(valorOutro);

        const _itemPrecoFinal = itemPrecoMercadoria + valorOutro;

        setItemPrecoFinal(_itemPrecoFinal);
        setItemTotalItem(itemQuantidade * _itemPrecoFinal);
      }
    }

    atualizarTotal();

    calcularValorOutro();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemQuantidade, itemPrecoMercadoria, itemPrecoOutro, itemPrecoFinal, itemModelo, itemTamanho, itemMalha, itemCor, itemBolso, itemGola, itemGolaAcabamento, itemManga, itemMangaAcabamento,
    itemFrenteSilk, itemCostasSilk, itemMangaDireitaSilk, itemMangaEsquerdaSilk, itemFrenteSublimacao, itemCostasSublimacao, itemMangaDireitaSublimacao,
    itemMangaEsquerdaSublimacao, itemFrenteBordado, itemCostasBordado, itemMangaDireitaBordado, itemMangaEsquerdaBordado, itemFrenteSublimar, itemCostasSublimar,
    itemMangaDireitaSublimar, flagCalcularValorOutro,
  ]);

  useEffect(() => {
    let newTitle = 'Orçamento - Cadastro';
    if (id > 0) {
      newTitle = 'Orçamento - Edição';
    }

    let mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getEstampariaPrecificacao() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const { data: _precificacao } = await axiosAdmin.get(`/estampariaprecificacao?idUsuario=${idUsuario}`);

        setValorSilkTela(_precificacao.valorSilkTela);
        setValorSublimacao(_precificacao.valorSublimacao);
        setNaoCobrarAcimaDe(_precificacao.naoCobrarAcimaDe);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaCor() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _cores } = await axiosAdmin.get(`/estampariacor?idUsuario=${idUsuario}`);

        _cores.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaCor(listDropdown);
        setListaCorCompleto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaTamanho() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _tamanhos } = await axiosAdmin.get(`/estampariatamanho?idUsuario=${idUsuario}`);

        _tamanhos.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaTamanho(listDropdown);
        setListaTamanhoCompleto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaModelo() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _modelos } = await axiosAdmin.get(`/estampariamodelo?idUsuario=${idUsuario}`);

        _modelos.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaModelo(listDropdown);
        setListaModeloCompleto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaMalha() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _malhas } = await axiosAdmin.get(`/estampariamalha?idUsuario=${idUsuario}`);

        _malhas.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaMalha(listDropdown);
        setListaMalhaCompleto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaManga() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _mangas } = await axiosAdmin.get(`/estampariamanga?idUsuario=${idUsuario}`);

        _mangas.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaManga(listDropdown);
        setListaMangaCompleto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaMangaAcabamento() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _mangaacabamentos } = await axiosAdmin.get(`/estampariamangaacabamento?idUsuario=${idUsuario}`);

        _mangaacabamentos.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaMangaAcabamento(listDropdown);
        setListaMangaAcabamentoCompleto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaGola() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _golas } = await axiosAdmin.get(`/estampariagola?idUsuario=${idUsuario}`);

        _golas.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaGola(listDropdown);
        setListaGolaCompleto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaGolaAcabamento() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _golaacabamentos } = await axiosAdmin.get(`/estampariagolaacabamento?idUsuario=${idUsuario}`);

        _golaacabamentos.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaGolaAcabamento(listDropdown);
        setListaGolaAcabamentoCompleto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaBolso() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _bolsos } = await axiosAdmin.get(`/estampariabolso?idUsuario=${idUsuario}`);

        _bolsos.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaBolso(listDropdown);
        setListaBolsoCompleto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaSilk() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _silks } = await axiosAdmin.get(`/estampariasilk?idUsuario=${idUsuario}`);

        _silks.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo, numeroTelas: element.numeroTelas });
        });

        setListaSilk(listDropdown);
        setListaSilkCompleto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaSublimacao() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _sublimacoes } = await axiosAdmin.get(`/estampariasublimacao?idUsuario=${idUsuario}`);

        _sublimacoes.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaSublimacao(listDropdown);
        setListaSublimacaoCompleto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaBordado() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _bordados } = await axiosAdmin.get(`/estampariabordado?idUsuario=${idUsuario}`);

        _bordados.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaBordado(listDropdown);
        setListaBordadoCompleto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaAcessorio() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _acessorios } = await axiosAdmin.get(`/estampariaacessorio?idUsuario=${idUsuario}`);

        _acessorios.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaAcessorio(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    getEstampariaPrecificacao();
    getEstampariaModelo();
    getEstampariaTamanho();
    getEstampariaMalha();
    getEstampariaCor();
    getEstampariaBolso();
    getEstampariaGola();
    getEstampariaGolaAcabamento();
    getEstampariaManga();
    getEstampariaMangaAcabamento();
    getEstampariaBordado();
    getEstampariaSilk();
    getEstampariaSublimacao();
    getEstampariaAcessorio();

    if (id <= 0) {
      setDataOrcamento(new Date());
      return;
    }

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/estampariaorcamento/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          displayErrors('Acesso negado', dispatch, navigate, '/consultaestampariaorcamento', '');
        }

        const _data = converteData(data.data);

        const _dataValidade = converteData(data.dataValidade);

        setDataOrcamento(_data);
        setDataValidade(_dataValidade);
        setNomeOrcamento(data.nomeOrcamento);
        setCondicaoPagamento(data.condicaoPagamento);
        setObservacao(data.observacao);
        setIdCliente(get(data.cliente, 'id', null));
        setNomeCliente(get(data.cliente, 'razaoSocial', ''));
        setValorTotal(data.valorTotal);
        setNomeVendedor(get(data.vendedor, 'nome', ''));
        setIdVendedor(get(data.vendedor, 'id', null));
        setCodigoVendedor(get(data.vendedor, 'codigo', ''));

        const _listaItem = [];
        data.listaEstampariaOrcamentoItem.forEach(element => {
          const _listaTipoServico = [];

          element.listaEstampariaOrcamentoTipoServico.forEach(_tipoServico => {
            _listaTipoServico.push({
              parteVestuario: _tipoServico.parteVestuario,
              silkar: _tipoServico.silkar,
              silk: get(_tipoServico.silk, 'id', null),
              sublimar: _tipoServico.sublimar,
              sublimacao: get(_tipoServico.sublimacao, 'id', null),
              bordar: _tipoServico.bordar,
              bordado: get(_tipoServico.bordado, 'id', null),
            });
          });

          const _listaItemAcessorio = [];

          let item = 0;

          element.listaEstampariaOrcamentoItemAcessorio.forEach(_acessorio => {
            item = item + 1;

            _listaItemAcessorio.push({
              item,
              estampariaAcessorio: get(_acessorio, 'estampariaAcessorio.id', 0),
              descricaoAcessorio: get(_acessorio, 'estampariaAcessorio.descricao', ''),
              quantidade: _acessorio.quantidade,
              valorAcrescimo: _acessorio.valorAcrescimo,
              totalAcrescimo: _acessorio.totalAcrescimo,
            });
          });

          _listaItem.push({
            id: get(element.produto, 'id', 0),
            item: element.item,
            produto: get(element.produto, 'id', 0),
            descricao: get(element.produto, 'descricao', ''),
            siglaUnidadeMedida: get(element.produto.unidadeMedida, 'sigla', ''),
            tipoProduto: get(element.produto, 'tipo', ''),
            estampariaManga: get(element.produto, 'estampariaManga', false),
            estampariaMangaAcabamento: get(element.produto, 'estampariaMangaAcabamento', false),
            estampariaGola: get(element.produto, 'estampariaGola', false),
            estampariaGolaAcabamento: get(element.produto, 'estampariaGolaAcabamento', false),
            estampariaBolso: get(element.produto, 'estampariaBolso', false),
            quantidade: element.quantidade,
            precoMercadoria: element.precoMercadoria,
            precoOutro: element.precoOutro,
            precoTelaSilk: element.precoTelaSilk,
            precoSublimacao: element.precoSublimacao,
            precoBruto: element.precoBruto,
            precoFinal: element.precoFinal,
            totalItem: element.totalItem,
            recalcular: false,
            modelo: get(element.estampariaModelo, 'id', null),
            tamanho: get(element.estampariaTamanho, 'id', null),
            tamanhoDescricao: get(element.estampariaTamanho, 'descricao', ''),
            malha: get(element.estampariaMalha, 'id', null),
            cor: get(element.estampariaCor, 'id', null),
            bolso: get(element.estampariaBolso, 'id', null),
            bolsoCor: get(element.estampariaBolsoCor, 'id', null),
            gola: get(element.estampariaGola, 'id', null),
            golaAcabamento: get(element.estampariaGolaAcabamento, 'id', null),
            golaCor: get(element.estampariaGolaCor, 'id', null),
            manga: get(element.estampariaManga, 'id', null),
            mangaAcabamento: get(element.estampariaMangaAcabamento, 'id', null),
            mangaCor: get(element.estampariaMangaCor, 'id', null),
            mangaAcabamentoCor: get(element.estampariaMangaAcabamentoCor, 'id', null),
            listaEstampariaOrcamentoTipoServico: _listaTipoServico,
            listaEstampariaOrcamentoItemAcessorio: _listaItemAcessorio,
            listaEstampariaCor: element.produto.listaEstampariaCor,
            listaEstampariaTamanho: element.produto.listaEstampariaTamanho,
            listaEstampariaModelo: element.produto.listaEstampariaModelo,
            listaEstampariaMalha: element.produto.listaEstampariaMalha,
            listaEstampariaManga: element.produto.listaEstampariaManga,
            listaEstampariaMangaAcabamento: element.produto.listaEstampariaMangaAcabamento,
            listaEstampariaGola: element.produto.listaEstampariaGola,
            listaEstampariaGolaAcabamento: element.produto.listaEstampariaGolaAcabamento,
            listaEstampariaBolso: element.produto.listaEstampariaBolso,
            listaEstampariaSilk: element.produto.listaEstampariaSilk,
            listaEstampariaSublimacao: element.produto.listaEstampariaSublimacao,
            listaEstampariaBordado: element.produto.listaEstampariaBordado,
          });
        });

        setListaItem(_listaItem);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  // eslint-disable-next-line consistent-return
  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const idOperador = get(store.getState().auth, 'idOperador', null);

      let _data = null;
      if (dataOrcamento) {
        _data = formataDataMoment(dataOrcamento, '-');
      }
      let _dataValidade = null;
      if (dataValidade) {
        _dataValidade = formataDataMoment(dataValidade, '-');
      }

      if (idVendedor === null) {
        toast.info('Vendedor deve ser informado.');
        return false;
      }

      const shippingData = {
        idUsuario,
        operador: idOperador,
        data: _data,
        dataValidade: _dataValidade,
        nomeOrcamento,
        condicaoPagamento,
        observacao,
        cliente: idCliente,
        valorTotal,
        idVendedor,
        listaEstampariaOrcamentoItem: listaItem,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/estampariaorcamento/${id}`, shippingData), 'data', {});
        toast.success('Orçamento atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/estampariaorcamento', shippingData), 'data', {});
        toast.success('Orçamento cadastrado com sucesso');
      }

      childToParentCadastro(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentCadastro();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!dataOrcamento) {
      formErros = true;
      toast.error('Data do Orcamento deve ser informada');
    }
    if (!nomeOrcamento) {
      formErros = true;
      toast.error('Nome do Orçamento deve ser informado');
    }
    if (valorTotal <= 0) {
      formErros = true;
      toast.error('Orçamento não pode ser salvo com o total zerado!');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const onHideConsultaParticipante = () => {
    setShowConsultaParticipante(false);
  };

  const childToParent = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdCliente(e.id);
      }
      if (e.razaoSocial) {
        setNomeCliente(e.razaoSocial);
      }
      setShowConsultaParticipante(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentVendedor = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdVendedor(e.id);
      }
      if (e.codigo) {
        setCodigoVendedor(e.codigo);
      }
      if (e.nome) {
        setNomeVendedor(e.nome);
      }
      setShowConsultaVendedor(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const onHideConsultaVendedor = () => {
    setShowConsultaVendedor(false);
  };

  function limparCamposItem(todosCampos) {
    setTipoServicoActiveTabIndex(0);

    setItemModelo(null);
    setItemTamanho(null);
    setItemMalha(null);
    setItemCor(null);
    setItemBolso(null);
    setItemBolsoCor(null);
    setItemGola(null);
    setItemGolaAcabamento(null);
    setItemGolaCor(null);
    setItemManga(null);
    setItemMangaAcabamento(null);
    setItemMangaCor(null);
    setItemMangaAcabamentoCor(null);
    setItemFrenteSilkar(false);
    setItemFrenteSilk(null);
    setItemFrenteSublimar(false);
    setItemFrenteSublimacao(null);
    setItemFrenteBordar(false);
    setItemFrenteBordado(null);
    setItemCostasSilkar(false);
    setItemCostasSilk(null);
    setItemCostasSublimar(false);
    setItemCostasSublimacao(null);
    setItemCostasBordar(false);
    setItemCostasBordado(null);
    setItemMangaDireitaSilkar(false);
    setItemMangaDireitaSilk(null);
    setItemMangaDireitaSublimar(false);
    setItemMangaDireitaSublimacao(null);
    setItemMangaDireitaBordar(false);
    setItemMangaDireitaBordado(null);
    setItemMangaEsquerdaSilkar(false);
    setItemMangaEsquerdaSilk(null);
    setItemMangaEsquerdaSublimar(false);
    setItemMangaEsquerdaSublimacao(null);
    setItemMangaEsquerdaBordar(false);
    setItemMangaEsquerdaBordado(null);
    setListaItemAcessorio([]);

    if (todosCampos === true) {
      setItemIdProduto(0);
      setItemDescricaoProduto('');
      setItemUnidadeProduto('');
      setItemTipoProduto('');
      setItemQuantidade(0);
      setItemPrecoMercadoria(0);
      setItemPrecoOutro(0);
      setItemPrecoFinal(0);
      setItemTotalItem(0);
    }
  }

  function limparCamposItemAcessorio() {
    setItemAcessorio(null);
    setItemAcessorioQuantidade(0);
    setItemAcessorioValorAcrescimo(0);
    setItemAcessorioTotalAcrescimo(0);
  }

  const handleFocus = (event) => event.target.select();

  const titulo = () => (id > 0 ? 'Orçamento - Alteração' : 'Orçamento - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h5>{titulo()}</h5>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const onHideCadastroItem = () => {
    setShowCadastroItem(false);
  };

  const onHideCadastroItemAcessorio = () => {
    setShowCadastroItemAcessorio(false);
  };

  const onHideConsultaProduto = () => {
    setShowConsultaProduto(false);
  };

  async function atualizarListaEstamparia(idProduto) {
    try {
      setIsLoading(true);

      const { data } = await axiosAdmin.get(`/produto/${idProduto}`);

      if (data.listaEstampariaCor && data.listaEstampariaCor.length > 0) {
        const _listDropdown = [];
        data.listaEstampariaCor.forEach(element => {
          _listDropdown.push({ label: element.estampariaCor.descricao, value: element.estampariaCor.id, valorAcrescimo: element.estampariaCor.valorAcrescimo });
        });
        setListaCor(_listDropdown);
      } else {
        setListaCor(listaCorCompleto);
      }

      if (data.listaEstampariaTamanho && data.listaEstampariaTamanho.length > 0) {
        const _listDropdown = [];
        data.listaEstampariaTamanho.forEach(element => {
          _listDropdown.push({ label: element.estampariaTamanho.descricao, value: element.estampariaTamanho.id, valorAcrescimo: element.estampariaTamanho.valorAcrescimo });
        });
        setListaTamanho(_listDropdown);
      } else {
        setListaTamanho(listaTamanhoCompleto);
      }

      if (data.listaEstampariaModelo && data.listaEstampariaModelo.length > 0) {
        const _listDropdown = [];
        data.listaEstampariaModelo.forEach(element => {
          _listDropdown.push({ label: element.estampariaModelo.descricao, value: element.estampariaModelo.id, valorAcrescimo: element.estampariaModelo.valorAcrescimo });
        });
        setListaModelo(_listDropdown);
      } else {
        setListaModelo(listaModeloCompleto);
      }

      if (data.listaEstampariaMalha && data.listaEstampariaMalha.length > 0) {
        const _listDropdown = [];
        data.listaEstampariaMalha.forEach(element => {
          _listDropdown.push({ label: element.estampariaMalha.descricao, value: element.estampariaMalha.id, valorAcrescimo: element.estampariaMalha.valorAcrescimo });
        });
        setListaMalha(_listDropdown);
      } else {
        setListaMalha(listaMalhaCompleto);
      }

      if (data.listaEstampariaManga && data.listaEstampariaManga.length > 0) {
        const _listDropdown = [];
        data.listaEstampariaManga.forEach(element => {
          _listDropdown.push({ label: element.estampariaManga.descricao, value: element.estampariaManga.id, valorAcrescimo: element.estampariaManga.valorAcrescimo });
        });
        setListaManga(_listDropdown);
      } else {
        setListaManga(listaMangaCompleto);
      }

      if (data.listaEstampariaMangaAcabamento && data.listaEstampariaMangaAcabamento.length > 0) {
        const _listDropdown = [];
        data.listaEstampariaMangaAcabamento.forEach(element => {
          _listDropdown.push({ label: element.estampariaMangaAcabamento.descricao, value: element.estampariaMangaAcabamento.id, valorAcrescimo: element.estampariaMangaAcabamento.valorAcrescimo });
        });
        setListaMangaAcabamento(_listDropdown);
      } else {
        setListaMangaAcabamento(listaMangaAcabamentoCompleto);
      }

      if (data.listaEstampariaGola && data.listaEstampariaGola.length > 0) {
        const _listDropdown = [];
        data.listaEstampariaGola.forEach(element => {
          _listDropdown.push({ label: element.estampariaGola.descricao, value: element.estampariaGola.id, valorAcrescimo: element.estampariaGola.valorAcrescimo });
        });
        setListaGola(_listDropdown);
      } else {
        setListaGola(listaGolaCompleto);
      }

      if (data.listaEstampariaGolaAcabamento && data.listaEstampariaGolaAcabamento.length > 0) {
        const _listDropdown = [];
        data.listaEstampariaGolaAcabamento.forEach(element => {
          _listDropdown.push({ label: element.estampariaGolaAcabamento.descricao, value: element.estampariaGolaAcabamento.id, valorAcrescimo: element.estampariaGolaAcabamento.valorAcrescimo });
        });
        setListaGolaAcabamento(_listDropdown);
      } else {
        setListaGolaAcabamento(listaGolaAcabamentoCompleto);
      }

      if (data.listaEstampariaBolso && data.listaEstampariaBolso.length > 0) {
        const _listDropdown = [];
        data.listaEstampariaBolso.forEach(element => {
          _listDropdown.push({ label: element.estampariaBolso.descricao, value: element.estampariaBolso.id, valorAcrescimo: element.estampariaBolso.valorAcrescimo });
        });
        setListaBolso(_listDropdown);
      } else {
        setListaBolso(listaBolsoCompleto);
      }

      if (data.listaEstampariaSilk && data.listaEstampariaSilk.length > 0) {
        const _listDropdown = [];
        data.listaEstampariaSilk.forEach(element => {
          _listDropdown.push({ label: element.estampariaSilk.descricao, value: element.estampariaSilk.id, valorAcrescimo: element.estampariaSilk.valorAcrescimo });
        });
        setListaSilk(_listDropdown);
      } else {
        setListaSilk(listaSilkCompleto);
      }

      if (data.listaEstampariaSublimacao && data.listaEstampariaSublimacao.length > 0) {
        const _listDropdown = [];
        data.listaEstampariaSublimacao.forEach(element => {
          _listDropdown.push({ label: element.estampariaSublimacao.descricao, value: element.estampariaSublimacao.id, valorAcrescimo: element.estampariaSublimacao.valorAcrescimo });
        });
        setListaSublimacao(_listDropdown);
      } else {
        setListaSublimacao(listaSublimacaoCompleto);
      }

      if (data.listaEstampariaBordado && data.listaEstampariaBordado.length > 0) {
        const _listDropdown = [];
        data.listaEstampariaBordado.forEach(element => {
          _listDropdown.push({ label: element.estampariaBordado.descricao, value: element.estampariaBordado.id, valorAcrescimo: element.estampariaBordado.valorAcrescimo });
        });
        setListaBordado(_listDropdown);
      } else {
        setListaBordado(listaBordadoCompleto);
      }
    } catch (err) {
      // displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const childToParentConsultaProduto = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setItemIdProduto(e.id);
        setItemDescricaoProduto(e.descricao);
        setItemUnidadeProduto(get(e.unidadeMedida, 'sigla', ''));
        setItemTipoProduto(e.tipo);
        if (e.tipo !== '09') {
          setMostrarAbaTipoServico(false);
          setTipoServicoActiveTabIndex(0);
          limparCamposItem(false);
        } else {
          setMostrarAbaTipoServico(true);
          setTipoServicoActiveTabIndex(0);
          setMostrarManga(e.estampariaManga);
          setMostrarMangaAcabamento(e.estampariaMangaAcabamento);
          setMostrarGola(e.estampariaGola);
          setMostrarGolaAcabamento(e.estampariaGolaAcabamento);
          setMostrarBolso(e.estampariaBolso);

          atualizarListaEstamparia(e.id);
        }
        setItemPrecoMercadoria(e.precoVenda);
        setItemPrecoOutro(0);
        setItemPrecoFinal(e.precoVenda);
      }
      setShowConsultaProduto(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const onHideConsultaItemPadrao = () => {
    setShowConsultaItemPadrao(false);
  };

  const childToParentConsultaItemPadrao = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      setItemIdProduto(get(e.produto, 'id', null));
      setItemDescricaoProduto(get(e.produto, 'descricao', ''));
      setItemUnidadeProduto(get(e.produto.unidadeMedida, 'descricao', ''));
      setItemTipoProduto(e.produto.tipo);
      if (e.produto.tipo !== '09') {
        setMostrarAbaTipoServico(false);
        setTipoServicoActiveTabIndex(0);
        limparCamposItem(false);
      } else {
        setMostrarAbaTipoServico(true);
        setTipoServicoActiveTabIndex(0);
        setMostrarManga(e.produto.estampariaManga);
        setMostrarMangaAcabamento(e.produto.estampariaMangaAcabamento);
        setMostrarGola(e.produto.estampariaGola);
        setMostrarGolaAcabamento(e.produto.estampariaGolaAcabamento);
        setMostrarBolso(e.produto.estampariaBolso);

        atualizarListaEstamparia(get(e.produto, 'id', null));
      }
      setItemPrecoMercadoria(get(e.produto, 'precoVenda', 0));
      setItemPrecoOutro(0);
      setItemPrecoFinal(get(e.produto, 'precoVenda', 0));

      setItemModelo(get(e.estampariaModelo, 'id', null));
      setItemTamanho(get(e.estampariaTamanho, 'id', null));
      setItemMalha(get(e.estampariaMalha, 'id', null));
      setItemCor(get(e.estampariaCor, 'id', null));
      setItemBolso(get(e.estampariaBolso, 'id', null));
      setItemBolsoCor(get(e.estampariaBolsoCor, 'id', null));
      setItemGola(get(e.estampariaGola, 'id', null));
      setItemGolaAcabamento(get(e.estampariaGolaAcabamento, 'id', null));
      setItemGolaCor(get(e.estampariaGolaCor, 'id', null));
      setItemManga(get(e.estampariaManga, 'id', null));
      setItemMangaAcabamento(get(e.estampariaMangaAcabamento, 'id', null));
      setItemMangaCor(get(e.estampariaMangaCor, 'id', null));
      setItemMangaAcabamentoCor(get(e.estampariaMangaAcabamentoCor, 'id', null));

      e.listaEstampariaItemPadraoTipoServico.forEach(element => {
        if (element.parteVestuario === 'FRENTE') {
          setItemFrenteSilkar(element.silkar);
          setItemFrenteSilk(get(element.silk, 'id', null));
          setItemFrenteSublimar(element.sublimar);
          setItemFrenteSublimacao(get(element.sublimacao, 'id', null));
          setItemFrenteBordar(element.bordar);
          setItemFrenteBordado(get(element.bordado, 'id', null));
        }
        if (element.parteVestuario === 'COSTAS') {
          setItemCostasSilkar(element.silkar);
          setItemCostasSilk(get(element.silk, 'id', null));
          setItemCostasSublimar(element.sublimar);
          setItemCostasSublimacao(get(element.sublimacao, 'id', null));
          setItemCostasBordar(element.bordar);
          setItemCostasBordado(get(element.bordado, 'id', null));
        }
        if (element.parteVestuario === 'MANGADIREITA') {
          setItemMangaDireitaSilkar(element.silkar);
          setItemMangaDireitaSilk(get(element.silk, 'id', null));
          setItemMangaDireitaSublimar(element.sublimar);
          setItemMangaDireitaSublimacao(get(element.sublimacao, 'id', null));
          setItemMangaDireitaBordar(element.bordar);
          setItemMangaDireitaBordado(get(element.bordado, 'id', null));
        }
        if (element.parteVestuario === 'MANGAESQUERDA') {
          setItemMangaEsquerdaSilkar(element.silkar);
          setItemMangaEsquerdaSilk(get(element.silk, 'id', null));
          setItemMangaEsquerdaSublimar(element.sublimar);
          setItemMangaEsquerdaSublimacao(get(element.sublimacao, 'id', null));
          setItemMangaEsquerdaBordar(element.bordar);
          setItemMangaEsquerdaBordado(get(element.bordado, 'id', null));
        }
      });

      const _listaItemAcessorio = [];

      let item = 0;

      e.listaEstampariaItemPadraoAcessorio.forEach(_acessorio => {
        item = item + 1;

        _listaItemAcessorio.push({
          item,
          estampariaAcessorio: get(_acessorio, 'estampariaAcessorio.id', 0),
          descricaoAcessorio: get(_acessorio, 'estampariaAcessorio.descricao', ''),
          quantidade: _acessorio.quantidade,
          valorAcrescimo: _acessorio.valorAcrescimo,
          totalAcrescimo: _acessorio.totalAcrescimo,
        });
      });

      setListaItemAcessorio(_listaItemAcessorio);

      setShowConsultaItemPadrao(false);
    } else {
      toast.warning('Por favor, selecione o ítem padrão');
    }
  };

  const cancelDeleteItem = () => {
    setShowConfirmDeleteItem(false);
  };

  const confirmDeleteItem = () => {
    const item = get(selectedItens, 'item', 0);

    if (item > 0) {
      const _listaItem = [...listaItem];

      const { produto: _produto, totalItem: _valorEstorno } = _listaItem.find(element => element.item === item);

      const index = _listaItem.indexOf(_listaItem.find(element => element.item === item));

      if (index >= 0) {
        _listaItem.splice(index, 1);

        _listaItem.forEach(element => {
          if (element.item > item) {
            const _index = _listaItem.indexOf(element);

            _listaItem[_index].item = _listaItem[_index].item - 1;
          }
        });

        if (_valorEstorno && _valorEstorno > 0) {
          setValorTotal(valorTotal - _valorEstorno);
        }

        setListaItem(_listaItem);

        setItemIdProduto(_produto);
        setFlagCalcularTela(true);
        setFlagCalcularValorOutro(flagCalcularValorOutro + 1);
      }
    }

    setShowConfirmDeleteItem(false);
  };

  const confirmDialogFooterItem = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteItem} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteItem} />
    </div>
  );

  const handleDeleteItem = (e) => {
    e.preventDefault();

    const item = get(selectedItens, 'item', 0);

    if (item > 0) {
      setShowConfirmDeleteItem(true);
    } else {
      toast.warning('Por favor, selecione o ítem para exclusão');
    }
  };

  const handleIncluirItem = () => {
    setEditandoItem(0);
    limparCamposItem(true);
    setMostrarAbaTipoServico(false);
    setShowCadastroItem(true);
  };

  const handleEditItem = (e) => {
    e.preventDefault();

    const item = get(selectedItens, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItem.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o ítem para edição');
        return;
      }

      setEditandoItem(item);

      limparCamposItem(true);

      if (_itemSelecionado.tipoProduto !== '09') {
        setMostrarAbaTipoServico(false);
      } else {
        setMostrarAbaTipoServico(true);
        setMostrarManga(_itemSelecionado.estampariaManga);
        setMostrarMangaAcabamento(_itemSelecionado.estampariaMangaAcabamento);
        setMostrarGola(_itemSelecionado.estampariaGola);
        setMostrarGolaAcabamento(_itemSelecionado.estampariaGolaAcabamento);
        setMostrarBolso(_itemSelecionado.estampariaBolso);

        atualizarListaEstamparia(_itemSelecionado.produto);
      }

      setItemIdProduto(_itemSelecionado.produto);
      setItemDescricaoProduto(_itemSelecionado.descricao);
      setItemUnidadeProduto(_itemSelecionado.siglaUnidadeMedida);
      setItemTipoProduto(_itemSelecionado.tipoProduto);
      setItemQuantidade(_itemSelecionado.quantidade);
      setItemPrecoMercadoria(_itemSelecionado.precoMercadoria);
      setItemPrecoOutro(_itemSelecionado.precoOutro);
      setItemPrecoFinal(_itemSelecionado.precoFinal);
      setItemTotalItem(_itemSelecionado.totalItem);
      setItemModelo(_itemSelecionado.modelo);
      setItemTamanho(_itemSelecionado.tamanho);
      setItemMalha(_itemSelecionado.malha);
      setItemCor(_itemSelecionado.cor);
      setItemBolso(_itemSelecionado.bolso);
      setItemBolsoCor(_itemSelecionado.bolsoCor);
      setItemGola(_itemSelecionado.gola);
      setItemGolaAcabamento(_itemSelecionado.golaAcabamento);
      setItemGolaCor(_itemSelecionado.golaCor);
      setItemManga(_itemSelecionado.manga);
      setItemMangaAcabamento(_itemSelecionado.mangaAcabamento);
      setItemMangaCor(_itemSelecionado.mangaCor);
      setItemMangaAcabamentoCor(_itemSelecionado.mangaAcabamentoCor);
      setListaItemAcessorio(_itemSelecionado.listaEstampariaOrcamentoItemAcessorio);

      _itemSelecionado.listaEstampariaOrcamentoTipoServico.forEach(element => {
        if (element.parteVestuario === 'FRENTE') {
          setItemFrenteSilkar(element.silkar);
          setItemFrenteSilk(element.silk);
          setItemFrenteSublimar(element.sublimar);
          setItemFrenteSublimacao(element.sublimacao);
          setItemFrenteBordar(element.bordar);
          setItemFrenteBordado(element.bordado);
        }
        if (element.parteVestuario === 'COSTAS') {
          setItemCostasSilkar(element.silkar);
          setItemCostasSilk(element.silk);
          setItemCostasSublimar(element.sublimar);
          setItemCostasSublimacao(element.sublimacao);
          setItemCostasBordar(element.bordar);
          setItemCostasBordado(element.bordado);
        }
        if (element.parteVestuario === 'MANGADIREITA') {
          setItemMangaDireitaSilkar(element.silkar);
          setItemMangaDireitaSilk(element.silk);
          setItemMangaDireitaSublimar(element.sublimar);
          setItemMangaDireitaSublimacao(element.sublimacao);
          setItemMangaDireitaBordar(element.bordar);
          setItemMangaDireitaBordado(element.bordado);
        }
        if (element.parteVestuario === 'MANGAESQUERDA') {
          setItemMangaEsquerdaSilkar(element.silkar);
          setItemMangaEsquerdaSilk(element.silk);
          setItemMangaEsquerdaSublimar(element.sublimar);
          setItemMangaEsquerdaSublimacao(element.sublimacao);
          setItemMangaEsquerdaBordar(element.bordar);
          setItemMangaEsquerdaBordado(element.bordado);
        }
      });

      setFlagCalcularValorOutro(flagCalcularValorOutro + 1);

      setShowCadastroItem(true);
    } else {
      toast.warning('Por favor, selecione o ítem para edição');
    }
  };

  const handleClonarItem = (e) => {
    e.preventDefault();

    setEditandoItem(0);

    const item = get(selectedItens, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItem.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o ítem para clonar');
        return;
      }

      limparCamposItem(true);

      if (_itemSelecionado.tipoProduto !== '09') {
        setMostrarAbaTipoServico(false);
      } else {
        setMostrarAbaTipoServico(true);
        setMostrarManga(_itemSelecionado.estampariaManga);
        setMostrarMangaAcabamento(_itemSelecionado.estampariaMangaAcabamento);
        setMostrarGola(_itemSelecionado.estampariaGola);
        setMostrarGolaAcabamento(_itemSelecionado.estampariaGolaAcabamento);
        setMostrarBolso(_itemSelecionado.estampariaBolso);

        atualizarListaEstamparia(_itemSelecionado.produto);
      }

      setItemIdProduto(_itemSelecionado.produto);
      setItemDescricaoProduto(_itemSelecionado.descricao);
      setItemUnidadeProduto(_itemSelecionado.siglaUnidadeMedida);
      setItemTipoProduto(_itemSelecionado.tipoProduto);
      setItemQuantidade(0);
      setItemPrecoMercadoria(_itemSelecionado.precoMercadoria);
      setItemPrecoOutro(_itemSelecionado.precoOutro);
      setItemPrecoFinal(_itemSelecionado.precoFinal);
      setItemTotalItem(_itemSelecionado.totalItem);
      setItemModelo(_itemSelecionado.modelo);
      setItemTamanho(_itemSelecionado.tamanho);
      setItemMalha(_itemSelecionado.malha);
      setItemCor(_itemSelecionado.cor);
      setItemBolso(_itemSelecionado.bolso);
      setItemBolsoCor(_itemSelecionado.bolsoCor);
      setItemGola(_itemSelecionado.gola);
      setItemGolaAcabamento(_itemSelecionado.golaAcabamento);
      setItemGolaCor(_itemSelecionado.golaCor);
      setItemManga(_itemSelecionado.manga);
      setItemMangaAcabamento(_itemSelecionado.mangaAcabamento);
      setItemMangaCor(_itemSelecionado.mangaCor);
      setItemMangaAcabamentoCor(_itemSelecionado.mangaAcabamentoCor);
      setListaItemAcessorio(_itemSelecionado.listaEstampariaOrcamentoItemAcessorio);

      _itemSelecionado.listaEstampariaOrcamentoTipoServico.forEach(element => {
        if (element.parteVestuario === 'FRENTE') {
          setItemFrenteSilkar(element.silkar);
          setItemFrenteSilk(element.silk);
          setItemFrenteSublimar(element.sublimar);
          setItemFrenteSublimacao(element.sublimacao);
          setItemFrenteBordar(element.bordar);
          setItemFrenteBordado(element.bordado);
        }
        if (element.parteVestuario === 'COSTAS') {
          setItemCostasSilkar(element.silkar);
          setItemCostasSilk(element.silk);
          setItemCostasSublimar(element.sublimar);
          setItemCostasSublimacao(element.sublimacao);
          setItemCostasBordar(element.bordar);
          setItemCostasBordado(element.bordado);
        }
        if (element.parteVestuario === 'MANGADIREITA') {
          setItemMangaDireitaSilkar(element.silkar);
          setItemMangaDireitaSilk(element.silk);
          setItemMangaDireitaSublimar(element.sublimar);
          setItemMangaDireitaSublimacao(element.sublimacao);
          setItemMangaDireitaBordar(element.bordar);
          setItemMangaDireitaBordado(element.bordado);
        }
        if (element.parteVestuario === 'MANGAESQUERDA') {
          setItemMangaEsquerdaSilkar(element.silkar);
          setItemMangaEsquerdaSilk(element.silk);
          setItemMangaEsquerdaSublimar(element.sublimar);
          setItemMangaEsquerdaSublimacao(element.sublimacao);
          setItemMangaEsquerdaBordar(element.bordar);
          setItemMangaEsquerdaBordado(element.bordado);
        }
      });

      setShowCadastroItem(true);
    } else {
      toast.warning('Por favor, selecione o ítem para clonagem');
    }
  };

  const handleOkItem = () => {
    let _itemGolaCor = itemGolaCor;
    let _itemMangaCor = itemMangaCor;
    let _itemMangaAcabamentoCor = itemMangaAcabamentoCor;

    if (itemIdProduto <= 0) {
      toast.warning('Produto deve ser informado');
      return;
    }
    if (itemQuantidade <= 0) {
      toast.warning('Quantidade deve ser informada');
      return;
    }
    if (!itemModelo && mostrarAbaTipoServico) {
      toast.warning('Modelo deve ser informado');
      setTipoServicoActiveTabIndex(0);
      return;
    }
    if (!itemMalha && mostrarAbaTipoServico) {
      toast.warning('Malha deve ser informada');
      setTipoServicoActiveTabIndex(0);
      return;
    }
    if (!itemCor && mostrarAbaTipoServico) {
      toast.warning('Cor deve ser informada');
      setTipoServicoActiveTabIndex(0);
      return;
    }
    if (!itemGolaCor && mostrarAbaTipoServico) {
      setItemGolaCor(itemCor);
      _itemGolaCor = itemCor;
    }
    if (!itemMangaCor && mostrarAbaTipoServico) {
      setItemMangaCor(itemCor);
      _itemMangaCor = itemCor;
    }
    if (!itemMangaAcabamentoCor && mostrarAbaTipoServico) {
      setItemMangaAcabamentoCor(itemCor);
      _itemMangaAcabamentoCor = itemCor;
    }
    if (!itemBolso && mostrarAbaTipoServico && mostrarBolso) {
      toast.warning('Bolso deve ser informado');
      setTipoServicoActiveTabIndex(0);
      return;
    }
    if (!itemGola && mostrarAbaTipoServico && mostrarGola) {
      toast.warning('Gola deve ser informada');
      setTipoServicoActiveTabIndex(0);
      return;
    }
    if (!itemGolaAcabamento && mostrarAbaTipoServico && mostrarGolaAcabamento) {
      toast.warning('Acabamento da Gola deve ser informado');
      setTipoServicoActiveTabIndex(0);
      return;
    }
    if (!itemManga && mostrarAbaTipoServico && mostrarManga) {
      toast.warning('Manga deve ser informada');
      setTipoServicoActiveTabIndex(0);
      return;
    }
    if (!itemMangaAcabamento && mostrarAbaTipoServico && mostrarMangaAcabamento) {
      toast.warning('Acabamento da Manga deve ser informado');
      setTipoServicoActiveTabIndex(0);
      return;
    }
    if (itemFrenteSilkar && (!itemFrenteSilk || itemFrenteSilk <= 0)) {
      toast.warning('Frente - Tipo de Silk deve ser informado');
      setTipoServicoActiveTabIndex(1);
      return;
    }
    if (itemFrenteSublimar && (!itemFrenteSublimacao || itemFrenteSublimacao <= 0)) {
      toast.warning('Frente - Tipo de Sublimação deve ser informado');
      setTipoServicoActiveTabIndex(1);
      return;
    }
    if (itemFrenteBordar && (!itemFrenteBordado || itemFrenteBordado <= 0)) {
      toast.warning('Frente - Bordado deve ser informado');
      setTipoServicoActiveTabIndex(1);
      return;
    }
    if (itemCostasSilkar && (!itemCostasSilk || itemCostasSilk <= 0)) {
      toast.warning('Costas - Tipo de Silk deve ser informado');
      setTipoServicoActiveTabIndex(2);
      return;
    }
    if (itemCostasSublimar && (!itemCostasSublimacao || itemCostasSublimacao <= 0)) {
      toast.warning('Costas - Tipo de Sublimação deve ser informado');
      setTipoServicoActiveTabIndex(2);
      return;
    }
    if (itemCostasBordar && (!itemCostasBordado || itemCostasBordado <= 0)) {
      toast.warning('Costas - Bordado deve ser informado');
      setTipoServicoActiveTabIndex(2);
      return;
    }
    if (itemMangaDireitaSilkar && (!itemMangaDireitaSilk || itemMangaDireitaSilk <= 0)) {
      toast.warning('Manga Direita - Tipo de Silk deve ser informado');
      setTipoServicoActiveTabIndex(3);
      return;
    }
    if (itemMangaDireitaSublimar && (!itemMangaDireitaSublimacao || itemMangaDireitaSublimacao <= 0)) {
      toast.warning('Manga Direita - Tipo de Sublimação deve ser informado');
      setTipoServicoActiveTabIndex(3);
      return;
    }
    if (itemMangaDireitaBordar && (!itemMangaDireitaBordado || itemMangaDireitaBordado <= 0)) {
      toast.warning('Manga Direita - Bordado deve ser informado');
      setTipoServicoActiveTabIndex(3);
      return;
    }
    if (itemMangaEsquerdaSilkar && (!itemMangaEsquerdaSilk || itemMangaEsquerdaSilk <= 0)) {
      toast.warning('Manga Esquerda - Tipo de Silk deve ser informado');
      setTipoServicoActiveTabIndex(4);
      return;
    }
    if (itemMangaEsquerdaSublimar && (!itemMangaEsquerdaSublimacao || itemMangaEsquerdaSublimacao <= 0)) {
      toast.warning('Manga Esquerda - Tipo de Sublimação deve ser informado');
      setTipoServicoActiveTabIndex(4);
      return;
    }
    if (itemMangaEsquerdaBordar && (!itemMangaEsquerdaBordado || itemMangaEsquerdaBordado <= 0)) {
      toast.warning('Manga Esquerda - Bordado deve ser informado');
      setTipoServicoActiveTabIndex(4);
      return;
    }

    const _listaItem = [...listaItem];

    let _valorEstorno = 0;

    let item = 0;
    listaItem.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoItem && editandoItem > 0) {
      item = editandoItem;

      _valorEstorno = listaItem.find(element => element.item === editandoItem).totalItem;

      const index = _listaItem.indexOf(_listaItem.find(element => element.item === editandoItem));

      if (index >= 0) {
        _listaItem.splice(index, 1);
      }
    }

    let tamanhoDescricao = '';
    if (mostrarAbaTipoServico) {
      tamanhoDescricao = listaTamanho.find(element => element.value === itemTamanho).label;
    }

    _listaItem.push({
      item,
      produto: itemIdProduto,
      descricao: itemDescricaoProduto,
      siglaUnidadeMedida: itemUnidadeProduto,
      tipoProduto: itemTipoProduto,
      estampariaManga: mostrarManga,
      estampariaMangaAcabamento: mostrarMangaAcabamento,
      estampariaGola: mostrarGola,
      estampariaGolaAcabamento: mostrarGolaAcabamento,
      estampariaBolso: mostrarBolso,
      quantidade: itemQuantidade,
      precoMercadoria: itemPrecoMercadoria,
      precoOutro: itemPrecoOutro,
      precoTelaSilk: 0,
      precoSublimacao: 0,
      precoBruto: itemPrecoFinal,
      precoFinal: itemPrecoFinal,
      totalItem: itemTotalItem,
      recalcular: true,
      modelo: itemModelo,
      tamanho: itemTamanho,
      tamanhoDescricao,
      malha: itemMalha,
      cor: itemCor,
      bolso: itemBolso,
      bolsoCor: itemBolsoCor,
      gola: itemGola,
      golaAcabamento: itemGolaAcabamento,
      golaCor: _itemGolaCor,
      manga: itemManga,
      mangaAcabamento: itemMangaAcabamento,
      mangaCor: _itemMangaCor,
      mangaAcabamentoCor: _itemMangaAcabamentoCor,
      listaEstampariaCor: listaCor,
      listaEstampariaTamanho: listaTamanho,
      listaEstampariaModelo: listaModelo,
      listaEstampariaMalha: listaMalha,
      listaEstampariaManga: listaManga,
      listaEstampariaMangaAcabamento: listaMangaAcabamento,
      listaEstampariaGola: listaGola,
      listaEstampariaGolaAcabamento: listaGolaAcabamento,
      listaEstampariaBolso: listaBolso,
      listaEstampariaSilk: listaSilk,
      listaEstampariaSublimacao: listaSublimacao,
      listaEstampariaBordado: listaBordado,
      listaEstampariaOrcamentoItemAcessorio: listaItemAcessorio,
      listaEstampariaOrcamentoTipoServico: [
        {
          parteVestuario: 'FRENTE',
          silkar: itemFrenteSilkar,
          silk: itemFrenteSilk,
          sublimar: itemFrenteSublimar,
          sublimacao: itemFrenteSublimacao,
          bordar: itemFrenteBordar,
          bordado: itemFrenteBordado,
        },
        {
          parteVestuario: 'COSTAS',
          silkar: itemCostasSilkar,
          silk: itemCostasSilk,
          sublimar: itemCostasSublimar,
          sublimacao: itemCostasSublimacao,
          bordar: itemCostasBordar,
          bordado: itemCostasBordado,
        },
        {
          parteVestuario: 'MANGADIREITA',
          silkar: itemMangaDireitaSilkar,
          silk: itemMangaDireitaSilk,
          sublimar: itemMangaDireitaSublimar,
          sublimacao: itemMangaDireitaSublimacao,
          bordar: itemMangaDireitaBordar,
          bordado: itemMangaDireitaBordado,
        },
        {
          parteVestuario: 'MANGAESQUERDA',
          silkar: itemMangaEsquerdaSilkar,
          silk: itemMangaEsquerdaSilk,
          sublimar: itemMangaEsquerdaSublimar,
          sublimacao: itemMangaEsquerdaSublimacao,
          bordar: itemMangaEsquerdaBordar,
          bordado: itemMangaEsquerdaBordado,
        },
      ],
    });

    if (itemTotalItem && itemTotalItem > 0) {
      setValorTotal(valorTotal + itemTotalItem - _valorEstorno);
    }

    setListaItem(_listaItem);

    setFlagCalcularTela(true);
    setFlagCalcularValorOutro(flagCalcularValorOutro + 1);

    setShowCadastroItem(false);
  };

  const handleIncluirItemAcessorio = (e) => {
    e.preventDefault();

    setEditandoItemAcessorio(0);

    limparCamposItemAcessorio();

    setShowCadastroItemAcessorio(true);
  };

  const handleEditItemAcessorio = (e) => {
    e.preventDefault();

    const item = get(selectedItensAcessorios, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItemAcessorio.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o ítem para edição');
        return;
      }

      setEditandoItemAcessorio(item);

      limparCamposItemAcessorio();

      setItemAcessorio(_itemSelecionado.estampariaAcessorio);
      setItemAcessorioQuantidade(_itemSelecionado.quantidade);
      setItemAcessorioValorAcrescimo(_itemSelecionado.valorAcrescimo);
      setItemAcessorioTotalAcrescimo(_itemSelecionado.totalAcrescimo);

      setShowCadastroItemAcessorio(true);
    } else {
      toast.warning('Por favor, selecione o ítem para edição');
    }

    setShowCadastroItemAcessorio(true);
  };

  const handleDeleteItemAcessorio = (e) => {
    e.preventDefault();

    const item = get(selectedItensAcessorios, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItemAcessorio.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o acessório para exclusão');
        return;
      }

      setShowConfirmDeleteItemAcessorio(true);
    } else {
      toast.warning('Por favor, selecione o acessório para exclusão');
    }
  };

  const cancelDeleteItemAcessorio = () => {
    setShowConfirmDeleteItemAcessorio(false);
  };

  const confirmDeleteItemAcessorio = () => {
    const item = get(selectedItensAcessorios, 'item', 0);

    if (item > 0) {
      const _listaItemAcessorio = [...listaItemAcessorio];

      const index = _listaItemAcessorio.indexOf(_listaItemAcessorio.find(element => element.item === item));

      if (index >= 0) {
        _listaItemAcessorio.splice(index, 1);

        _listaItemAcessorio.forEach(element => {
          if (element.item > item) {
            const _index = _listaItemAcessorio.indexOf(element);

            _listaItemAcessorio[_index].item = _listaItemAcessorio[_index].item - 1;
          }
        });
      }

      setListaItemAcessorio(_listaItemAcessorio);

      setFlagCalcularValorOutro(flagCalcularValorOutro + 1);
    }

    setShowConfirmDeleteItemAcessorio(false);
  };

  const confirmDialogFooterItemAcessorio = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteItemAcessorio} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteItemAcessorio} />
    </div>
  );

  const handleOkItemAcessorio = () => {
    if (!itemAcessorio || itemAcessorio <= 0) {
      toast.warning('Acessório deve ser informado');
      return;
    }
    if (itemAcessorioQuantidade <= 0) {
      toast.warning('Quantidade deve ser informada');
      return;
    }
    if (itemAcessorioTotalAcrescimo <= 0) {
      toast.warning('Valor deve ser informado');
      return;
    }

    const _listaItemAcessorio = [...listaItemAcessorio];

    const descricaoAcessorio = listaAcessorio.find(element => element.value === itemAcessorio).label;

    let item = 0;
    listaItemAcessorio.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoItemAcessorio && editandoItemAcessorio > 0) {
      item = editandoItemAcessorio;

      const index = _listaItemAcessorio.indexOf(_listaItemAcessorio.find(element => element.item === editandoItemAcessorio));

      if (index >= 0) {
        _listaItemAcessorio.splice(index, 1);
      }
    }

    _listaItemAcessorio.push({
      item,
      estampariaAcessorio: itemAcessorio,
      descricaoAcessorio,
      quantidade: itemAcessorioQuantidade,
      valorAcrescimo: itemAcessorioValorAcrescimo,
      totalAcrescimo: itemAcessorioTotalAcrescimo,
    });

    setListaItemAcessorio(_listaItemAcessorio);

    setFlagCalcularValorOutro(flagCalcularValorOutro + 1);

    setShowCadastroItemAcessorio(false);
  };

  // eslint-disable-next-line consistent-return
  async function handleBuscarVendedorByCodigo(e) {
    try {
      setIsLoading(true);

      if (e.length <= 0) {
        setIdVendedor('');
        setNomeVendedor('');
        return false;
      }

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/vendedor?idUsuario=${idUsuario}`;

      params = `${params}&codigo=${e.trim()}`;

      const response = await axiosAdmin.get(params);

      if (response.data.length > 0) {
        setIdVendedor(response.data[0].id);
        setNomeVendedor(response.data[0].nome);
      } else {
        setIdVendedor('');
        setNomeVendedor('');
        toast.info('Nenhum vendedor com esse código encontrado');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Vendedor"
        visible={showConsultaVendedor}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaVendedor()}
      >
        <ConsultaVendedor menuMode={false} childToParent={childToParentVendedor} somenteLeitura="true" />
      </Dialog>

      <Dialog
        header="Consulta de Participantes"
        visible={showConsultaParticipante}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaParticipante()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParent} />
      </Dialog>

      <Dialog
        header="Consulta de Produtos"
        visible={showConsultaProduto}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaProduto()}
      >
        <ConsultaProduto menuMode={false} childToParent={childToParentConsultaProduto} somenteLeitura />
      </Dialog>

      <Dialog
        header="Consulta de Ítem Padrão"
        visible={showConsultaItemPadrao}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaItemPadrao()}
      >
        <ConsultaItemPadrao menuMode={false} childToParent={childToParentConsultaItemPadrao} somenteLeitura />
      </Dialog>

      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Orçamento
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataOrcamento}
                onChange={(e) => setDataOrcamento(e.value)}
              />
            </div>
          </div>
          <div className="col-5">
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Valor Total
              </Input100px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorTotal}
                onValueChange={e => setValorTotal(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nome Orçamento
              </Input155px>
              <InputText
                maxLength={100}
                value={nomeOrcamento}
                onChange={e => setNomeOrcamento(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Cód. Vendedor
              </Input155px>
              <InputText
                maxLength={255}
                value={codigoVendedor}
                onChange={e => setCodigoVendedor(e.target.value)}
                onBlur={e => handleBuscarVendedorByCodigo(e.target.value)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaVendedor(true)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <InputText
                disabled
                maxLength={255}
                value={nomeVendedor}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-8">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Cliente
              </Input100px>
              <InputText
                disabled
                maxLength={255}
                value={nomeCliente}
                onChange={e => setNomeCliente(e.target.value)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaParticipante(true)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Validade
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataValidade}
                onChange={(e) => setDataValidade(e.value)}
              />
            </div>
          </div>
        </div>
        <TabView activeIndex={itemActiveTabIndex} onTabChange={(e) => setItemActiveTabIndex(e.index)}>
          <TabPanel header="Produtos">
            <div className="estamparia-orcamento-item">
              <DataTable
                value={listaItem}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                sortField="item"
                selectionMode="single"
                selection={selectedItens}
                onSelectionChange={e => setSelectedItens(e.value)}
                selectableRows
                dataKey="item"
                emptyMessage="Nenhum Ítem cadastrado"
              >
                {fillColumns(columnsSetupItem)}
              </DataTable>
            </div>
            <br />

            <Dialog
              header="Confirmação"
              visible={showConfirmDeleteItem}
              style={{ width: '50vw' }}
              footer={confirmDialogFooterItem}
              onHide={() => setShowConfirmDeleteItem(false)}
            >
              <p>
                Excluir o ítem?
              </p>
            </Dialog>

            <Dialog
              header="Orçamento - Ítem"
              visible={showCadastroItem}
              style={{ width: '98vw' }}
              onHide={() => onHideCadastroItem()}
            >
              <div className="row form-elo">
                <div className="col-8">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Produto
                    </Input155px>
                    <InputText
                      disabled
                      value={itemDescricaoProduto}
                    />
                    <Button
                      icon="pi pi-search"
                      className="p-button-warning"
                      onClick={() => setShowConsultaProduto(true)}
                    />
                    <Button
                      tooltip="Busca por Ítem Padrão"
                      icon="pi pi-star"
                      className="p-button-help"
                      onClick={() => setShowConsultaItemPadrao(true)}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Total do Ítem
                    </Input155px>
                    <InputNumber
                      disabled
                      mode="decimal"
                      inputId="minmaxfraction"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      value={itemTotalItem}
                      onValueChange={e => setItemTotalItem(e.target.value)}
                      onFocus={handleFocus}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-3">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Quantidade
                    </Input155px>
                    <InputNumber
                      mode="decimal"
                      inputId="minmaxfraction"
                      minFractionDigits={3}
                      maxFractionDigits={3}
                      value={itemQuantidade}
                      onValueChange={e => {
                        setItemTotalItem(e.target.value * itemPrecoFinal);
                        setItemQuantidade(e.target.value);
                      }}
                      onFocus={handleFocus}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-inputgroup">
                    <Input100px className="p-inputgroup-addon">
                      Valor Base
                    </Input100px>
                    <InputNumber
                      mode="currency"
                      inputId="currency-br"
                      currency="BRL"
                      locale="pt-BR"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      value={itemPrecoMercadoria}
                      onValueChange={e => setItemPrecoMercadoria(e.target.value)}
                      onFocus={handleFocus}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Valor Outros
                    </Input155px>
                    <InputNumber
                      disabled
                      mode="currency"
                      inputId="currency-br"
                      currency="BRL"
                      locale="pt-BR"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      value={itemPrecoOutro}
                      onValueChange={e => setItemPrecoOutro(e.target.value)}
                      onFocus={handleFocus}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <div className="p-inputgroup">
                    <Input100px className="p-inputgroup-addon">
                      Valor Final
                    </Input100px>
                    <InputNumber
                      disabled
                      mode="currency"
                      inputId="currency-br"
                      currency="BRL"
                      locale="pt-BR"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      value={itemPrecoFinal}
                      onValueChange={e => setItemPrecoFinal(e.target.value)}
                      onFocus={handleFocus}
                    />
                  </div>
                </div>
              </div>
              {mostrarAbaTipoServico ? (
                <TabView activeIndex={tipoServicoActiveTabIndex} onTabChange={(e) => setTipoServicoActiveTabIndex(e.index)}>
                  <TabPanel header="Especificações">
                    <div className="row form-elo">
                      <div className="col-lg-3">
                        <div className="p-inputgroup">
                          <Input100px className="p-inputgroup-addon">
                            Tamanho
                          </Input100px>
                          <Dropdown
                            filter
                            filterBy="label"
                            options={listaTamanho}
                            value={itemTamanho}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemTamanho(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="p-inputgroup">
                          <Input100px className="p-inputgroup-addon">
                            Modelo
                          </Input100px>
                          <Dropdown
                            filter
                            filterBy="label"
                            options={listaModelo}
                            value={itemModelo}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemModelo(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="p-inputgroup">
                          <Input100px className="p-inputgroup-addon">
                            Malha
                          </Input100px>
                          <Dropdown
                            filter
                            filterBy="label"
                            options={listaMalha}
                            value={itemMalha}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemMalha(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="p-inputgroup">
                          <Input100px className="p-inputgroup-addon">
                            Cor
                          </Input100px>
                          <Dropdown
                            filter
                            filterBy="label"
                            options={listaCor}
                            value={itemCor}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemCor(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row form-elo">
                      {mostrarManga ? (
                        <>
                          <div className="col-lg-3">
                            <div className="p-inputgroup">
                              <Input100px className="p-inputgroup-addon">
                                Manga
                              </Input100px>
                              <Dropdown
                                filter
                                filterBy="label"
                                options={listaManga}
                                value={itemManga}
                                emptyMessage="Nenhum registro a ser exibido"
                                onChange={e => setItemManga(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="p-inputgroup">
                              <Input100px className="p-inputgroup-addon">
                                Manga Cor
                              </Input100px>
                              <Dropdown
                                filter
                                showClear
                                filterBy="label"
                                options={listaCor}
                                value={itemMangaCor}
                                emptyMessage="Nenhum registro a ser exibido"
                                onChange={e => setItemMangaCor(e.target.value)}
                              />
                            </div>
                          </div>
                        </>
                      ) : ('')}
                      {mostrarMangaAcabamento ? (
                        <>
                          <div className="col-lg-3">
                            <div className="p-inputgroup">
                              <Input120px className="p-inputgroup-addon">
                                Manga Acab
                              </Input120px>
                              <Dropdown
                                filter
                                filterBy="label"
                                options={listaMangaAcabamento}
                                value={itemMangaAcabamento}
                                emptyMessage="Nenhum registro a ser exibido"
                                onChange={e => setItemMangaAcabamento(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="p-inputgroup">
                              <Input155px className="p-inputgroup-addon">
                                Manga Acab Cor
                              </Input155px>
                              <Dropdown
                                filter
                                showClear
                                filterBy="label"
                                options={listaCor}
                                value={itemMangaAcabamentoCor}
                                emptyMessage="Nenhum registro a ser exibido"
                                onChange={e => setItemMangaAcabamentoCor(e.target.value)}
                              />
                            </div>
                          </div>
                        </>
                      ) : ('')}
                    </div>
                    <div className="row form-elo">
                      {mostrarGola ? (
                        <>
                          <div className="col-lg-3">
                            <div className="p-inputgroup">
                              <Input100px className="p-inputgroup-addon">
                                Golas
                              </Input100px>
                              <Dropdown
                                filter
                                filterBy="label"
                                options={listaGola}
                                value={itemGola}
                                emptyMessage="Nenhum registro a ser exibido"
                                onChange={e => setItemGola(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="p-inputgroup">
                              <Input120px className="p-inputgroup-addon">
                                Gola Cor
                              </Input120px>
                              <Dropdown
                                filter
                                showClear
                                filterBy="label"
                                options={listaCor}
                                value={itemGolaCor}
                                emptyMessage="Nenhum registro a ser exibido"
                                onChange={e => setItemGolaCor(e.target.value)}
                              />
                            </div>
                          </div>
                        </>
                      ) : ('')}
                      {mostrarGolaAcabamento ? (
                        <div className="col-lg-3">
                          <div className="p-inputgroup">
                            <Input155px className="p-inputgroup-addon">
                              Gola Acabamento
                            </Input155px>
                            <Dropdown
                              filter
                              filterBy="label"
                              options={listaGolaAcabamento}
                              value={itemGolaAcabamento}
                              emptyMessage="Nenhum registro a ser exibido"
                              onChange={e => setItemGolaAcabamento(e.target.value)}
                            />
                          </div>
                        </div>
                      ) : ('')}
                    </div>
                    <div className="row form-elo">
                      {mostrarBolso ? (
                        <>
                          <div className="col-lg-3">
                            <div className="p-inputgroup">
                              <Input100px className="p-inputgroup-addon">
                                Bolso
                              </Input100px>
                              <Dropdown
                                filter
                                filterBy="label"
                                options={listaBolso}
                                value={itemBolso}
                                emptyMessage="Nenhum registro a ser exibido"
                                onChange={e => setItemBolso(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="col-lg-3">
                            <div className="p-inputgroup">
                              <Input155px className="p-inputgroup-addon">
                                Bolso Cor
                              </Input155px>
                              <Dropdown
                                filter
                                showClear
                                filterBy="label"
                                options={listaCor}
                                value={itemBolsoCor}
                                emptyMessage="Nenhum registro a ser exibido"
                                onChange={e => setItemBolsoCor(e.target.value)}
                              />
                            </div>
                          </div>
                        </>
                      ) : ('')}
                    </div>
                  </TabPanel>
                  <TabPanel header="Frente">
                    <div className="row form-elo">
                      <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="p-inputgroup">
                          <Checkbox
                            checked={itemFrenteSilkar}
                            onChange={e => {
                              setItemFrenteSilkar(e.checked);
                              if (!e.checked) {
                                setItemFrenteSilk(null);
                              }
                            }}
                          >
                          </Checkbox>
                          <label
                            htmlFor="cb1"
                            className="p-checkbox-label"
                          >
                              &nbsp;Silkar
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-inputgroup">
                          <Input155px className="p-inputgroup-addon">
                            Tipo de Silk
                          </Input155px>
                          <Dropdown
                            disabled={!itemFrenteSilkar}
                            filter
                            filterBy="label"
                            options={listaSilk}
                            value={itemFrenteSilk}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemFrenteSilk(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row form-elo">
                      <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="p-inputgroup">
                          <Checkbox
                            checked={itemFrenteSublimar}
                            onChange={e => {
                              setItemFrenteSublimar(e.checked);
                              if (!e.checked) {
                                setItemFrenteSublimacao(null);
                              }
                            }}
                          >
                          </Checkbox>
                          <label
                            htmlFor="cb1"
                            className="p-checkbox-label"
                          >
                              &nbsp;Sublimar
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-inputgroup">
                          <Input155px className="p-inputgroup-addon">
                            Tipo Sublimação
                          </Input155px>
                          <Dropdown
                            disabled={!itemFrenteSublimar}
                            filter
                            filterBy="label"
                            options={listaSublimacao}
                            value={itemFrenteSublimacao}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemFrenteSublimacao(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row form-elo">
                      <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="p-inputgroup">
                          <Checkbox
                            checked={itemFrenteBordar}
                            onChange={e => {
                              setItemFrenteBordar(e.checked);
                              if (!e.checked) {
                                setItemFrenteBordado(null);
                              }
                            }}
                          >
                          </Checkbox>
                          <label
                            htmlFor="cb1"
                            className="p-checkbox-label"
                          >
                              &nbsp;Bordar
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-inputgroup">
                          <Input155px className="p-inputgroup-addon">
                            Bordado
                          </Input155px>
                          <Dropdown
                            disabled={!itemFrenteBordar}
                            filter
                            filterBy="label"
                            options={listaBordado}
                            value={itemFrenteBordado}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemFrenteBordado(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel header="Costas">
                    <div className="row form-elo">
                      <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="p-inputgroup">
                          <Checkbox
                            checked={itemCostasSilkar}
                            onChange={e => {
                              setItemCostasSilkar(e.checked);
                              if (!e.checked) {
                                setItemCostasSilk(null);
                              }
                            }}
                          >
                          </Checkbox>
                          <label
                            htmlFor="cb1"
                            className="p-checkbox-label"
                          >
                              &nbsp;Silkar
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-inputgroup">
                          <Input155px className="p-inputgroup-addon">
                            Tipo de Silk
                          </Input155px>
                          <Dropdown
                            disabled={!itemCostasSilkar}
                            filter
                            filterBy="label"
                            options={listaSilk}
                            value={itemCostasSilk}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemCostasSilk(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row form-elo">
                      <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="p-inputgroup">
                          <Checkbox
                            checked={itemCostasSublimar}
                            onChange={e => {
                              setItemCostasSublimar(e.checked);
                              if (!e.checked) {
                                setItemCostasSublimacao(null);
                              }
                            }}
                          >
                          </Checkbox>
                          <label
                            htmlFor="cb1"
                            className="p-checkbox-label"
                          >
                              &nbsp;Sublimar
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-inputgroup">
                          <Input155px className="p-inputgroup-addon">
                            Tipo Sublimação
                          </Input155px>
                          <Dropdown
                            disabled={!itemCostasSublimar}
                            filter
                            filterBy="label"
                            options={listaSublimacao}
                            value={itemCostasSublimacao}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemCostasSublimacao(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row form-elo">
                      <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="p-inputgroup">
                          <Checkbox
                            checked={itemCostasBordar}
                            onChange={e => {
                              setItemCostasBordar(e.checked);
                              if (!e.checked) {
                                setItemCostasBordado(null);
                              }
                            }}
                          >
                          </Checkbox>
                          <label
                            htmlFor="cb1"
                            className="p-checkbox-label"
                          >
                              &nbsp;Bordar
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-inputgroup">
                          <Input155px className="p-inputgroup-addon">
                            Bordado
                          </Input155px>
                          <Dropdown
                            disabled={!itemCostasBordar}
                            filter
                            filterBy="label"
                            options={listaBordado}
                            value={itemCostasBordado}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemCostasBordado(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel header="Manga Direita">
                    <div className="row form-elo">
                      <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="p-inputgroup">
                          <Checkbox
                            checked={itemMangaDireitaSilkar}
                            onChange={e => {
                              setItemMangaDireitaSilkar(e.checked);
                              if (!e.checked) {
                                setItemMangaDireitaSilk(null);
                              }
                            }}
                          >
                          </Checkbox>
                          <label
                            htmlFor="cb1"
                            className="p-checkbox-label"
                          >
                              &nbsp;Silkar
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-inputgroup">
                          <Input155px className="p-inputgroup-addon">
                            Tipo de Silk
                          </Input155px>
                          <Dropdown
                            disabled={!itemMangaDireitaSilkar}
                            filter
                            filterBy="label"
                            options={listaSilk}
                            value={itemMangaDireitaSilk}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemMangaDireitaSilk(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row form-elo">
                      <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="p-inputgroup">
                          <Checkbox
                            checked={itemMangaDireitaSublimar}
                            onChange={e => {
                              setItemMangaDireitaSublimar(e.checked);
                              if (!e.checked) {
                                setItemMangaDireitaSublimacao(null);
                              }
                            }}
                          >
                          </Checkbox>
                          <label
                            htmlFor="cb1"
                            className="p-checkbox-label"
                          >
                              &nbsp;Sublimar
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-inputgroup">
                          <Input155px className="p-inputgroup-addon">
                            Tipo Sublimação
                          </Input155px>
                          <Dropdown
                            disabled={!itemMangaDireitaSublimar}
                            filter
                            filterBy="label"
                            options={listaSublimacao}
                            value={itemMangaDireitaSublimacao}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemMangaDireitaSublimacao(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row form-elo">
                      <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="p-inputgroup">
                          <Checkbox
                            checked={itemMangaDireitaBordar}
                            onChange={e => {
                              setItemMangaDireitaBordar(e.checked);
                              if (!e.checked) {
                                setItemMangaDireitaBordado(null);
                              }
                            }}
                          >
                          </Checkbox>
                          <label
                            htmlFor="cb1"
                            className="p-checkbox-label"
                          >
                              &nbsp;Bordar
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-inputgroup">
                          <Input155px className="p-inputgroup-addon">
                            Bordado
                          </Input155px>
                          <Dropdown
                            disabled={!itemMangaDireitaBordar}
                            filter
                            filterBy="label"
                            options={listaBordado}
                            value={itemMangaDireitaBordado}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemMangaDireitaBordado(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel header="Manga Esquerda">
                    <div className="row form-elo">
                      <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="p-inputgroup">
                          <Checkbox
                            checked={itemMangaEsquerdaSilkar}
                            onChange={e => {
                              setItemMangaEsquerdaSilkar(e.checked);
                              if (!e.checked) {
                                setItemMangaEsquerdaSilk(null);
                              }
                            }}
                          >
                          </Checkbox>
                          <label
                            htmlFor="cb1"
                            className="p-checkbox-label"
                          >
                              &nbsp;Silkar
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-inputgroup">
                          <Input155px className="p-inputgroup-addon">
                            Tipo de Silk
                          </Input155px>
                          <Dropdown
                            disabled={!itemMangaEsquerdaSilkar}
                            filter
                            filterBy="label"
                            options={listaSilk}
                            value={itemMangaEsquerdaSilk}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemMangaEsquerdaSilk(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row form-elo">
                      <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="p-inputgroup">
                          <Checkbox
                            checked={itemMangaEsquerdaSublimar}
                            onChange={e => {
                              setItemMangaEsquerdaSublimar(e.checked);
                              if (!e.checked) {
                                setItemMangaEsquerdaSublimacao(null);
                              }
                            }}
                          >
                          </Checkbox>
                          <label
                            htmlFor="cb1"
                            className="p-checkbox-label"
                          >
                              &nbsp;Sublimar
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-inputgroup">
                          <Input155px className="p-inputgroup-addon">
                            Tipo Sublimação
                          </Input155px>
                          <Dropdown
                            disabled={!itemMangaEsquerdaSublimar}
                            filter
                            filterBy="label"
                            options={listaSublimacao}
                            value={itemMangaEsquerdaSublimacao}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemMangaEsquerdaSublimacao(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row form-elo">
                      <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className="p-inputgroup">
                          <Checkbox
                            checked={itemMangaEsquerdaBordar}
                            onChange={e => {
                              setItemMangaEsquerdaBordar(e.checked);
                              if (!e.checked) {
                                setItemMangaEsquerdaBordado(null);
                              }
                            }}
                          >
                          </Checkbox>
                          <label
                            htmlFor="cb1"
                            className="p-checkbox-label"
                          >
                              &nbsp;Bordar
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-inputgroup">
                          <Input155px className="p-inputgroup-addon">
                            Bordado
                          </Input155px>
                          <Dropdown
                            disabled={!itemMangaEsquerdaBordar}
                            filter
                            filterBy="label"
                            options={listaBordado}
                            value={itemMangaEsquerdaBordado}
                            emptyMessage="Nenhum registro a ser exibido"
                            onChange={e => setItemMangaEsquerdaBordado(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel header="Acessórios">
                    <div className="estamparia-orcamento-item-acessorio">
                      <DataTable
                        value={listaItemAcessorio}
                        size="small"
                        stripedRows
                        responsiveLayout="scroll"
                        sortField="item"
                        selectionMode="single"
                        selection={selectedItensAcessorios}
                        onSelectionChange={e => setSelectedItensAcessorios(e.value)}
                        selectableRows
                        dataKey="item"
                        emptyMessage="Nenhum Acessório cadastrado"
                      >
                        {fillColumns(columnsSetupItemAcessorio)}
                      </DataTable>
                    </div>
                    <br />

                    <Dialog
                      header="Confirmação"
                      visible={showConfirmDeleteItemAcessorio}
                      style={{ width: '50vw' }}
                      footer={confirmDialogFooterItemAcessorio}
                      onHide={() => setShowConfirmDeleteItemAcessorio(false)}
                    >
                      <p>
                        Excluir o acessório?
                      </p>
                    </Dialog>

                    <Dialog
                      header="Orçamento - Ítem - Acessório"
                      visible={showCadastroItemAcessorio}
                      style={{ width: '98vw' }}
                      onHide={() => onHideCadastroItemAcessorio()}
                    >
                      <div className="row form-elo">
                        <div className="col-lg-12">
                          <div className="p-inputgroup">
                            <Input155px className="p-inputgroup-addon">
                              Acessório
                            </Input155px>
                            <Dropdown
                              filter
                              filterBy="label"
                              options={listaAcessorio}
                              value={itemAcessorio}
                              emptyMessage="Nenhum registro a ser exibido"
                              onChange={e => {
                                setItemAcessorio(e.target.value);
                                const valorAcrescimo = tratarNumero(findInList(listaAcessorio, e.target.value, 'valorAcrescimo'));
                                setItemAcessorioValorAcrescimo(valorAcrescimo);
                                setItemAcessorioTotalAcrescimo(itemAcessorioQuantidade * valorAcrescimo);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form-elo">
                        <div className="col-4">
                          <div className="p-inputgroup">
                            <Input155px className="p-inputgroup-addon">
                              Quantidade
                            </Input155px>
                            <InputNumber
                              mode="decimal"
                              inputId="minmaxfraction"
                              minFractionDigits={3}
                              maxFractionDigits={3}
                              value={itemAcessorioQuantidade}
                              onValueChange={e => {
                                setItemAcessorioTotalAcrescimo(e.target.value * itemAcessorioValorAcrescimo);
                                setItemAcessorioQuantidade(e.target.value);
                              }}
                              onFocus={handleFocus}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="p-inputgroup">
                            <Input155px className="p-inputgroup-addon">
                              Valor
                            </Input155px>
                            <InputNumber
                              disabled
                              mode="currency"
                              inputId="currency-br"
                              currency="BRL"
                              locale="pt-BR"
                              minFractionDigits={2}
                              maxFractionDigits={2}
                              value={itemAcessorioValorAcrescimo}
                              onValueChange={e => setItemAcessorioValorAcrescimo(e.target.value)}
                              onFocus={handleFocus}
                            />
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="p-inputgroup">
                            <Input155px className="p-inputgroup-addon">
                              Total Acessório
                            </Input155px>
                            <InputNumber
                              disabled
                              mode="currency"
                              inputId="currency-br"
                              currency="BRL"
                              locale="pt-BR"
                              minFractionDigits={2}
                              maxFractionDigits={2}
                              value={itemAcessorioTotalAcrescimo}
                              onValueChange={e => setItemAcessorioTotalAcrescimo(e.target.value)}
                              onFocus={handleFocus}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row form-elo">
                        <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <button
                            type="button"
                            className="btn btn-success"
                            onClick={handleOkItemAcessorio}
                          >
                            <i className="pi pi-check"></i>
                            Ok
                          </button>
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() => setShowCadastroItemAcessorio(false)}
                          >
                            <i className="pi pi-times"></i>
                            Cancelar
                          </button>
                        </div>
                      </div>
                    </Dialog>
                    <div className="row form-elo">
                      <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <button
                          type="button"
                          title="Incluir Acessório"
                          className="btn btn-primary"
                          style={{ borderRadius: '15px' }}
                          onClick={(e) => handleIncluirItemAcessorio(e)}
                        >
                          <i className="pi pi-plus"></i>
                        </button>
                        <button
                          type="button"
                          title="Alterar Acessório"
                          className="btn btn-warning"
                          style={{ borderRadius: '15px' }}
                          onClick={(e) => handleEditItemAcessorio(e)}
                        >
                          <i className="pi pi-pencil"></i>
                        </button>
                        <button
                          type="button"
                          title="Cancelar Acessório"
                          className="btn btn-danger"
                          style={{ borderRadius: '15px' }}
                          onClick={(e) => handleDeleteItemAcessorio(e)}
                        >
                          <i className="pi pi-trash"></i>
                        </button>
                      </div>
                      <Divider />
                    </div>
                  </TabPanel>
                </TabView>
              ) : ('')}
              <div className="row form-elo">
                <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleOkItem}
                  >
                    <i className="pi pi-check"></i>
                    Ok
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => setShowCadastroItem(false)}
                  >
                    <i className="pi pi-times"></i>
                    Cancelar
                  </button>
                </div>
              </div>
            </Dialog>

            <div className="row form-elo">
              <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button
                  type="button"
                  title="Incluir Ítem"
                  className="btn btn-primary"
                  style={{ borderRadius: '15px' }}
                  onClick={() => handleIncluirItem()}
                >
                  <i className="pi pi-plus"></i>
                </button>
                <button
                  type="button"
                  title="Clonar ítem"
                  className="btn btn-success"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleClonarItem(e)}
                >
                  <i className="pi pi-clone"></i>
                </button>
                <button
                  type="button"
                  title="Alterar Ítem"
                  className="btn btn-warning"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleEditItem(e)}
                >
                  <i className="pi pi-pencil"></i>
                </button>
                <button
                  type="button"
                  title="Excluir Ítem"
                  className="btn btn-danger"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleDeleteItem(e)}
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Observação">
            <div className="row form-elo">
              <InputTextarea
                rows={10}
                value={observacao}
                onChange={e => setObservacao(e.target.value)}
              />
            </div>
          </TabPanel>
        </TabView>
      </Card>
    </div>
  );
}
