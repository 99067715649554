export function obterListaCodigoSituacao() {
  return (
    [
      { label: '00 - Documento Regular', value: 'REGULAR' },
      { label: '01 - Escrituração extemporânea de documento regular', value: 'EXTREGULAR' },
      { label: '02 - Documento cancelado', value: 'CANCELADO' },
      { label: '03 - Escrituração extemporânea de documento cancelado', value: 'EXTCANCELADO' },
      { label: '04 - NF-e, NFC-e ou CT-e - denegado', value: 'DENEGADO' },
      { label: '05 - NF-e, NFC-e ou CT-e - Numeração inutilizada', value: 'INUTILIZADO' },
      { label: '06 - Documento Fiscal Complementar', value: 'COMPLEMENTAR' },
      { label: '07 - Escrituração extemporânea de documento complementar', value: 'EXTCOMPLEMENTAR' },
      { label: '08 - Documento Fiscal emitido com base em Regime Especial ou Norma Específica', value: 'REGIMEESPECIAL' },
    ]
  );
}
