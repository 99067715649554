/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unneeded-ternary */
import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get, orderBy, isNumber } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { ToggleButton } from 'primereact/togglebutton';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import moment from 'moment';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors, converteData, replaceAll, formataDataMoment, formataMoeda, arredondar } from '../../../util/diversos';
import { Input100px, Input155px, Input200px, Input250px, Input400px } from '../../../styles/inputGroup';
import { fillColumns } from '../../../components/table';

import { obterListaTipoMovimentacao } from '../../../services/tipoMovimentacaoService';
import { obterListaFinalidadeEmissao } from '../../../services/finalidadeEmissaoService';
import { obterListaIndicadorPresenca } from '../../../services/indicadorPresencaService';
import { obterListaIndicadorIntermediario } from '../../../services/indicadorIntermediarioService';
import { obterListaModalidadeFrete } from '../../../services/modalidadeFreteService';
import { obterListaOrigemMercadoria } from '../../../services/origemMercadoriaService';
import { obterListaModalidadeBcIcms } from '../../../services/modalidadeBcIcmsService';
import { obterListaModalidadeBcIcmsSt } from '../../../services/modalidadeBcIcmsServiceSt';
import { obterListaCsosn } from '../../../services/csosnService';
import { obterListaIpiCst } from '../../../services/ipiCstService';
import { obterListaTipoOperacao } from '../../../services/tipoOperacaoService';
import { obterListaTipoViaTransporte } from '../../../services/tipoViaTransporteService';
import { obterListaTipoIntermedio } from '../../../services/tipoIntermedio';
import { obterListaTipoPagamentoNFe } from '../../../services/tipoPagamentoNFe';
import { obterListaCodigoSituacao } from '../../../services/situacaoDocumento';
import { obterListaTipoArmamento } from '../../../services/tipoArmamento';
import { obterListaMotivoDesoneracao } from '../../../services/motivoDesoneracaoService';
import { obterListaMotivoDesoneracaoSt } from '../../../services/motivoDesoneracaoStService';
import { obterListaIndicadorProcessoRef } from '../../../services/indicadorProcessoRefService';
import { obterListaTipoAtoConcessorioProcessoReferenciado } from '../../../services/tipoAtoConcessorioProcessoReferenciadoService';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

import ConsultaParticipante from '../participante/consultaParticipante';
import ConsultaNaturezaOperacao from '../naturezaOperacao/consultaNaturezaOperacao';
import ConsultaProduto from '../produto/consultaProduto';
import ConsultaUnidadeMedida from '../unidadeMedida/consultaUnidadeMedida';

export default function CadastroNFePropria({ payloadCadastro, childToParentConsulta }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [disableEdicaoEnviada, setDisableEdicaoEnviada] = useState(false);

  const id = get(payloadCadastro, 'id', 0);
  const empresaCnpj = get(payloadCadastro, 'cnpjEmpresa', 0);
  const [empresaAliquotaCofins, setEmpresaAliquotaCofins] = useState(get(payloadCadastro, 'aliquotaCofins', 0));
  const [empresaAliquotaPis, setEmpresaAliquotaPis] = useState(get(payloadCadastro, 'aliquotaPis', 0));
  const [empresaRegimeTributario, setEmpresaRegimeTributario] = useState(get(payloadCadastro, 'regimeTributario', 0));
  const tipoMovimentacao = get(payloadCadastro, 'tipoMovimentacao', '');
  const propsTipoMovimentacao = get(payloadCadastro, 'propsTipoMovimentacao', '');

  let seriePadrao = get(payloadCadastro, 'seriePadrao', '1');
  if (!seriePadrao) {
    seriePadrao = '1';
  }

  const [ufEmpresa, setUfEmpresa] = useState(get(payloadCadastro, 'uf', ''));

  const [flag, setFlag] = useState('D'); // D = digitada , T = transmitida
  const [chaveAcesso, setChaveAcesso] = useState('');
  const [protocolo, setProtocolo] = useState('');
  const [numeroRecibo, setNumeroRecibo] = useState('');
  const [emissaoPropria, setEmissaoPropria] = useState(propsTipoMovimentacao === 'entrada' ? 'false' : 'true');
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');

  const [nNf, setNNf] = useState('0');
  const [dhEmi, setDhEmi] = useState(new Date());
  const [dhSaiEnt, setDhSaiEnt] = useState(null);
  const [tpNf, setTpNf] = useState(propsTipoMovimentacao === 'entrada' ? 'ENTRADA' : 'SAIDA');
  const [xNome, setXNome] = useState('');
  const [natOp, setNatOp] = useState('');
  const [finNfe, setFinNfe] = useState('NORMAL');
  const [tpEmis, setTpEmis] = useState('NORMAL');
  const [codSit, setCodSit] = useState('REGULAR');
  const [mod, setMod] = useState('55');
  const [serie, setSerie] = useState(seriePadrao);
  const [indFinal, setIndFinal] = useState(false); // consumidor final false = 0, true = 1
  const [idDest, setIdDest] = useState(''); // localdestino nota, 1 interna, 2 interestadual, 3 exterior
  const [vSeg, setVSeg] = useState(0);
  const [vOutro, setVOutro] = useState(0);

  const [idParticipante, setIdParticipante] = useState(null);
  const [UfParticipante, setUfParticipante] = useState('');

  const [indPres, setIndPres] = useState('NAOSEAPLICA');
  const [indIntermed, setIndIntermed] = useState('NAO');
  const [infCpl, setInfCpl] = useState('');
  const [infAdFisco, setInfAdFisco] = useState('');

  const [pagTPag, setPagTPag] = useState('DINHEIRO');
  const [pagXPag, setPagXPag] = useState('');
  const [pagVPag, setPagVPag] = useState(0);
  const [pagDVenc, setPagDVenc] = useState(null);

  const [cobrFatNFat, setCobrFatNFat] = useState('');
  const [cobrFatVOrig, setCobrFatVOrig] = useState(0);
  const [cobrFatVDesc, setCobrFatVDesc] = useState(0);
  const [cobrFatVLiq, setCobrFatVLiq] = useState(0);

  const [cobrDupCondicaoPagamento, setCobrDupCondicaoPagamento] = useState('');
  const [cobrDupDVenc, setCobrDupDVenc] = useState('');
  const [cobrDupVDup, setCobrDupVDup] = useState(0);

  const [idParticipanteTransporta, setIdParticipanteTransporta] = useState(null);
  const [razaoSocialTranporta, setRazaoSocialTransporta] = useState('');
  const [transpModFrete, setTranspModFrete] = useState('CIF');
  const [transpCfop, setTranspCfop] = useState('');
  const [transpVServ, setTranspVServ] = useState(0);
  const [transpMarca, setTranspMarca] = useState('');
  const [transpQVol, setTranspQVol] = useState(0);
  const [transpEsp, setTranspEsp] = useState('');
  const [transpNVol, setTranspNVol] = useState('');
  const [transpPesoL, setTranspPesoL] = useState(0);
  const [transpPesoB, setTranspPesoB] = useState(0);
  const [transpVBCRet, setTranspVBCRet] = useState(0);
  const [transpVICMSRet, setTranspVICMSRet] = useState(0);
  const [transpVeicTranspPlaca, setTranspVeicTranspPlaca] = useState('');
  const [transpVeicTranspUF, setTranspVeicTranspUF] = useState('');
  const [transpVeicTranspRNTC, setTranspVeicTranspRNTC] = useState('');
  const [transpNLacre, setTranspNLacre] = useState('');

  const [refNFeIdEmitente, setRefNFeIdEmitente] = useState(null);
  const [razaoSocialRefNFeEmitente, setRazaoSocialRefNFeEmitente] = useState('');
  const [refNFe, setRefNFe] = useState('');
  const [refNFeMod, setRefNFeMod] = useState('');
  const [refNFeSerie, setRefNFeSerie] = useState('');
  const [refNFeNNF, setRefNFeNNF] = useState('');
  const [refNFeAAMM, setRefNFeAAMM] = useState(null);
  const [refNFeTipoOperacao, setRefNFeTipoOperacao] = useState('ENTRADA');

  const [refEcfMod, setRefEcfMod] = useState('');
  const [refEcfNEcf, setRefEcfNEcf] = useState('');
  const [refEcfNCoo, setRefEcfNCoo] = useState('');

  const [refProcNProc, setRefProcNProc] = useState('');
  const [refProcIndProc, setRefProcIndProc] = useState('');
  const [refProcTpAto, setRefProcTpAto] = useState('');

  const [autXmlCnpj, setAutXmlCnpj] = useState('');
  const [autXmlCpf, setAutXmlCpf] = useState('');

  const [totalVBC, setTotalVBC] = useState(0);
  const [totalVICMS, setTotalVICMS] = useState(0);
  const [totalVICMSDeson, setTotalVICMSDeson] = useState(0);
  const [totalVFCPUFDest, setTotalVFCPUFDest] = useState(0);
  const [totalVICMSUFDest, setTotalVICMSUFDest] = useState(0);
  const [totalVIICMSUFRemet, setTotalVIICMSUFRemet] = useState(0);
  const [totalVFCP, setTotalVFCP] = useState(0);
  const [totalVBCST, setTotalVBCST] = useState(0);
  const [totalVST, setTotalVST] = useState(0);
  const [totalVFCPST, setTotalVFCPST] = useState(0);
  const [totalVFCPStRet, setTotalVFCPStRet] = useState(0);
  const [totalVProd, setTotalVProd] = useState(0);
  const [totalVFrete, setTotalVFrete] = useState(0);
  const [totalVSeg, setTotalVSeg] = useState(0);
  const [totalVDesc, setTotalVDesc] = useState(0);
  const [totalVII, setTotalVII] = useState(0);
  const [totalVIPI, setTotalVIPI] = useState(0);
  const [totalVIPIDevol, setTotalVIPIDevol] = useState(0);
  const [totalVPIS, setTotalVPIS] = useState(0);
  const [totalVCOFINS, setTotalVCOFINS] = useState(0);
  const [totalVOutro, setTotalVOutro] = useState(0);
  const [totalVICMSIsento, setTotalVICMSIsento] = useState(0);
  const [totalVICMSOutro, setTotalVICMSOutro] = useState(0);
  const [totalVNF, setTotalVNF] = useState(0);

  const [itemMovFisicaEstoque, setItemMovFisicaEstoque] = useState(true);
  const [itemSomarIpiNaBc, setItemSomarIpiNaBc] = useState(true);
  const [itemActiveTabIndexCadastroProduto, setItemActiveTabIndexCadastroProduto] = useState(0);

  const [editandoFinanceiro, setEditandoFinanceiro] = useState(0);
  const [editandoDuplicata, setEditandoDuplicata] = useState(0);
  const [editandoTranspNLacre, setEditandoTranspNLacre] = useState(0);
  const [editandoRefNFe, setEditandoRefNFe] = useState(0);
  const [editandoRefEcf, setEditandoRefEcf] = useState(0);
  const [editandoRefProc, setEditandoRefProc] = useState(0);
  const [editandoAutXml, setEditandoAutXml] = useState(0);
  const [editandoItem, setEditandoItem] = useState(0);
  const [editandoItemRastro, setEditandoItemRastro] = useState(0);
  const [editandoItemDi, setEditandoItemDi] = useState(0);
  const [editandoItemAdi, setEditandoItemAdi] = useState(0);
  const [editandoItemDetExport, setEditandoItemDetExport] = useState(0);

  const [showCadastroFinanceiro, setShowCadastroFinanceiro] = useState(false);
  const [showCadastroDuplicata, setShowCadastroDuplicata] = useState(false);
  const [showCadastroAutXml, setShowCadastroAutXml] = useState(false);
  const [showCadastroTranspNLacre, setShowCadastroTranspNLacre] = useState(false);
  const [showCadastroItem, setShowCadastroItem] = useState(false);
  const [showCadastroItemCompleto, setShowCadastroItemCompleto] = useState(false);
  const [showCadastroItemRastro, setShowCadastroItemRastro] = useState(false);
  const [showCadastroItemDi, setShowCadastroItemDi] = useState(false);
  const [showCadastroItemAdi, setShowCadastroItemAdi] = useState(false);
  const [showCadastroItemDetExport, setShowCadastroItemDetExport] = useState(false);
  const [showCadastroRefNFe, setShowCadastroRefNFe] = useState(false);
  const [showCadastroRefEcf, setShowCadastroRefEcf] = useState(false);
  const [showCadastroRefProc, setShowCadastroRefProc] = useState(false);

  const [showConsultaProduto, setShowConsultaProduto] = useState(false);
  const [showConsultaUnidadeMedida, setShowConsultaUnidadeMedida] = useState(false);
  const [showConsultaParticipante, setShowConsultaParticipante] = useState(false);
  const [showConsultaNaturezaOperacao, setShowConsultaNaturezaOperacao] = useState(false);
  const [showConsultaTransportadora, setShowConsultaTransportadora] = useState(false);
  const [showConsultaRefNFeEmitente, setShowConsultaRefNFeEmitente] = useState(false);

  const [showConfirmDeleteFinanceiro, setShowConfirmDeleteFinanceiro] = useState(false);
  const [showConfirmDeleteDuplicata, setShowConfirmDeleteDuplicata] = useState(false);
  const [showConfirmDeleteItem, setShowConfirmDeleteItem] = useState(false);
  const [showConfirmDeleteItemRastro, setShowConfirmDeleteItemRastro] = useState(false);
  const [showConfirmDeleteItemDi, setShowConfirmDeleteItemDi] = useState(false);
  const [showConfirmDeleteItemAdi, setShowConfirmDeleteItemAdi] = useState(false);
  const [showConfirmDeleteItemDetExport, setShowConfirmDeleteItemDetExport] = useState(false);
  const [showConfirmDeleteRefNFe, setShowConfirmDeleteRefNFe] = useState(false);
  const [showConfirmDeleteRefEcf, setShowConfirmDeleteRefEcf] = useState(false);
  const [showConfirmDeleteRefProc, setShowConfirmDeleteRefProc] = useState(false);
  const [showConfirmDeleteAutXml, setShowConfirmDeleteAutXml] = useState(false);
  const [showConfirmDeleteTranspNLacre, setShowConfirmDeleteTranspNLacre] = useState(false);

  const [showConfirmEdicaoEnviada, setShowConfirmEdicaoEnviada] = useState(false);
  const [showConfirmCancelarDigitacaoNFe, setShowConfirmCancelarDigitacaoNFe] = useState(false);

  const [showDadosAdicionais, setShowDadosAdicionais] = useState(false);
  const [showFinanceiro, setShowFinanceiro] = useState(false);
  const [showTotaisNota, setShowTotaisNota] = useState(false);

  const [ibptAliqEstadual, setIbptAliqEstadual] = useState(0);
  const [ibptAliqMunicipal, setIbptAliqMunicipal] = useState(0);
  const [ibptAliqNacionalFederal, setIbptAliqNacionalFederal] = useState(0);
  const [ibptAliqImportadosFederal, setIbptAliqImportadosFederal] = useState(0);

  const [itemRegistro, setItemRegistro] = useState(0);
  const [itemIdProduto, setItemIdProduto] = useState(0);
  const [itemDescricaoProduto, setItemDescricaoProduto] = useState('');
  const [itemUCom, setItemUCom] = useState('UN');
  const [itemQCom, setItemQCom] = useState(1);
  const [itemPrecoBruto, setItemPrecoBruto] = useState(0);
  const [itemPrecoVenda, setItemPrecoVenda] = useState(0);
  const [itemTotalBruto, setItemTotalBruto] = useState(0);
  const [itemVDesc, setItemVDesc] = useState(0);
  const [itemVFrete, setItemVFrete] = useState(0);
  const [itemTotalFinal, setItemTotalFinal] = useState(0);
  const [itemNumeroPedidoCompra, setItemNumeroPedidoCompra] = useState('');
  const [itemNumeroItemPedidoCompra, setItemNumeroItemPedidoCompra] = useState('');
  const [itemIndTot, setItemIndTot] = useState(true);

  const [itemTotalItem, setItemTotalItem] = useState(0);
  const [itemCfop, setItemCfop] = useState('');
  const [itemCst, setItemCst] = useState('');
  const [itemCsosn, setItemCsosn] = useState('');
  const [itemOrig, setItemOrig] = useState(0);
  const [itemNaturezaReceita, setItemNaturezaReceita] = useState('');
  const [itemNcm, setItemNcm] = useState('');
  const [itemCest, setItemCest] = useState('');
  const [itemCta, setItemCta] = useState('');
  const [itemIndEscala, setItemIndEscala] = useState(false);
  const [itemCnpjFab, setItemCnpjFab] = useState('');
  const [itemCBenef, setItemCBenef] = useState('');

  const [itemIpiCst, setItemIpiCst] = useState('');
  const [itemIpiVBc, setItemIpiVBc] = useState(0);
  const [itemIpiPIpi, setItemIpiPIpi] = useState(0);
  const [itemIpiVIpi, setItemIpiVIpi] = useState(0);
  const [itemPDevol, setItemPDevol] = useState(0);
  const [itemIpiVIpiDevol, setItemIpiVIpiDevol] = useState(0);
  const [itemIpiEntradaAliquota, setItemIpiEntradaAliquota] = useState(0);
  const [itemIpiSaidaAliquota, setItemIpiSaidaAliquota] = useState(0);

  const [itemModBc, setItemModBc] = useState(0);
  const [itemPRedBc, setItemPRedBc] = useState(0);
  const [itemVBc, setItemVBc] = useState(0);
  const [itemPIcms, setItemPIcms] = useState(0);
  const [itemVIcms, setItemVIcms] = useState(0);

  const [itemModBcST, setItemModBcST] = useState(0);
  const [itemPRedBcSt, setItemPRedBcSt] = useState(0);
  const [itemPMvaSt, setItemPMvaSt] = useState(0);
  const [itemVBcSt, setItemVBcSt] = useState(0);
  const [itemPIcmsSt, setItemPIcmsSt] = useState(0);
  const [itemVIcmsSt, setItemVIcmsSt] = useState(0);

  const [itemVIcmsDeson, setItemVIcmsDeson] = useState(0); // serve para st e tributado
  const [itemMotDesIcms, setItemMotDesIcms] = useState(0);
  const [itemVIcmsStDeson, setItemVIcmsStDeson] = useState(0); // serve para st e tributado
  const [itemMotDesIcmsSt, setItemMotDesIcmsSt] = useState(0);

  const [itemVOutro, setItemVOutro] = useState(0); // vem de outras despesas adicionais

  const [itemVICMSIsento, setItemVICMSIsento] = useState(0);
  const [itemVICMSOutro, setItemVICMSOutro] = useState(0); // vem do calculo outros com relacao a isento

  const [itemPisCst, setItemPisCst] = useState('99');
  const [itemPisVBc, setItemPisVBc] = useState(0);
  const [itemPisPPis, setItemPisPPis] = useState(0);
  const [itemPisVPis, setItemPisVPis] = useState(0);
  const [itemPisStVBc, setItemPisStVBc] = useState(0);
  const [itemPisStPPis, setItemPisStPPis] = useState(0);
  const [itemPisStVPis, setItemPisStVPis] = useState(0);

  const [itemCofinsCst, setItemCofinsCst] = useState('99');
  const [itemCofinsVBc, setItemCofinsVBc] = useState(0);
  const [itemCofinsPCofins, setItemCofinsPCofins] = useState(0);
  const [itemCofinsVCofins, setItemCofinsVCofins] = useState(0);
  const [itemCofinsStVBc, setItemCofinsStVBc] = useState(0);
  const [itemCofinsStPCofins, setItemCofinsStPCofins] = useState(0);
  const [itemCofinsStVCofins, setItemCofinsStVCofins] = useState(0);

  const [itemIiVBc, setItemIiVBc] = useState(0);
  const [itemIiDespAdu, setItemIiDespAdu] = useState(0);
  const [itemIiVIof, setItemIiVIof] = useState(0);
  const [itemIiVIi, setItemIiVIi] = useState(0);

  const [itemPCredSn, setItemPCredSn] = useState(0);
  const [itemVCredIcmsSn, setItemVCredIcmsSn] = useState(0);

  const [itemPartilhaVBcUfDest, setItemPartilhaVBcUfDest] = useState(0);
  const [itemPartilhaPIcmsUfDest, setItemPartilhaPIcmsUfDest] = useState(0);
  const [itemPartilhaPIcmsInter, setItemPartilhaPIcmsInter] = useState(0);
  const [itemPartilhaPIcmsInterPart, setItemPartilhaPIcmsInterPart] = useState(0);
  const [itemPartilhaVIcmsUfDest, setItemPartilhaVIcmsUfDest] = useState(0);
  const [itemPartilhaVIcmsUfRemet, setItemPartilhaVIcmsUfRemet] = useState(0);

  const [itemVBcFcp, setItemVBcFcp] = useState(0);
  const [itemPFcp, setItemPFcp] = useState(0);
  const [itemVFcp, setItemVFcp] = useState(0);
  const [itemVBcFcpSt, setItemVBcFcpSt] = useState(0);
  const [itemPFcpSt, setItemPFcpSt] = useState(0);
  const [itemVFcpSt, setItemVFcpSt] = useState(0);
  const [itemVBcFcpStRet, setItemVBcFcpStRet] = useState(0);
  const [itemPFcpStRet, setItemPFcpStRet] = useState(0);
  const [itemVFcpStRet, setItemVFcpStRet] = useState(0);
  const [itemVBcFcpUfDest, setItemVBcFcpUfDest] = useState(0);
  const [itemPFcpUfDest, setItemPFcpUfDest] = useState(0);
  const [itemVFcpUfDest, setItemVFcpUfDest] = useState(0);

  const [itemRastroNLote, setItemRastroNLote] = useState('');
  const [itemRastroQLote, setItemRastroQLote] = useState('');
  const [itemRastroDFab, setItemRastroDFab] = useState('');
  const [itemRastroDVal, setItemRastroDVal] = useState('');
  const [itemRastroCAgreg, setItemRastroCAgreg] = useState('');

  const [itemDiNDi, setItemDiNDi] = useState('');
  const [itemDiDDi, setItemDiDDi] = useState(null);
  const [itemDiXLocDesemb, setItemDiXLocDesemb] = useState('');
  const [itemDiUfDesemb, setItemDiUfDesemb] = useState('');
  const [itemDiDDesemb, setItemDiDDesemb] = useState(null);
  const [itemDiTpViaTransp, setItemDiTpViaTransp] = useState('MARITIMA');
  const [itemDiVAfrmm, setItemDiVAfrmm] = useState('');
  const [itemDiTpIntermedio, setItemDiTpIntermedio] = useState('CONTAPROPRIA');
  const [itemDiCnpj, setItemDiCnpj] = useState('');
  const [itemDiUfTerceiro, setItemDiUfTerceiro] = useState('');
  const [itemDiCExportador, setItemDiCExportador] = useState('');

  const [itemAdiNAdicao, setItemAdiNAdicao] = useState('');
  const [itemAdiNSeqAdic, setItemAdiNSeqAdic] = useState('');
  const [itemAdiCFabricante, setItemAdiCFabricante] = useState('');
  const [itemAdiVDescDi, setItemAdiVDescDi] = useState(0);
  const [itemAdiNDraw, setItemAdiNDraw] = useState('');

  const [itemDetExportNDraw, setItemDetExportNDraw] = useState('');

  const [itemArmaTpArma, setItemArmaTpArma] = useState('PERMITIDO');
  const [itemArmaNSerie, setItemArmaNSerie] = useState('');
  const [itemArmaNCano, setItemArmaNCano] = useState('');
  const [itemArmaDescr, setItemArmaDescr] = useState('');

  const [valorTotalItens, setValorTotalItens] = useState(0);
  const [valorDescontoRatear, setValorDescontoRatear] = useState(0);

  const [listaItem, setListaItem] = useState([]);
  const [listaItemRastro, setListaItemRastro] = useState([]);
  const [listaItemDi, setListaItemDi] = useState([]);
  const [listaItemDiUfDesemb, setListaItemDiUfDesemb] = useState([]);
  const [listaItemDiUfTerceiro, setListaItemDiUfTerceiro] = useState([]);
  const [listaTranspVeicTranspUF, setListaTranspVeicTranspUF] = useState([]);
  const [listaItemAdi, setListaItemAdi] = useState([]);
  const [listaItemDetExport, setListaItemDetExport] = useState([]);
  const [listaNatOps, setListaNatOps] = useState([]);
  const [listaUnidadeMedidas, setListaUnidadeMedidas] = useState([]);
  const [listaItemCfops, setListaItemCfops] = useState([]);
  const [listaItemCsts, setListaItemCsts] = useState();
  const [listaPISCOFINS, setListaPISCOFINS] = useState([]);
  const [listaTranspCfops, setListaTranspCfops] = useState([]);
  const [listaRefNFe, setListaRefNFe] = useState([]);
  const [listaRefEcf, setListaRefEcf] = useState([]);
  const [setListaEmpresas] = useState([]);
  const [listaRefProc, setListaRefProc] = useState([]);
  const [listaFinanceiro, setListaFinanceiro] = useState([]); // detpag da nfe
  const [listaDuplicatas, setListaDuplicatas] = useState([]);
  const [listaAutXml, setListaAutXml] = useState([]);
  const [listaTranspNLacre, setListaTranspNLacre] = useState([]);
  const [listaItemCtas, setListaItemCtas] = useState([]);
  const [listaTotaisPorCfopCst, setListaTotaisPorCfopCst] = useState([]);

  const [selectedItens, setSelectedItens] = useState(null);
  const [selectedItemRastros, setSelectedItemRastros] = useState(null);
  const [selectedItemDis, setSelectedItemDis] = useState(null);
  const [selectedItemAdi, setSelectedItemAdi] = useState(null);
  const [selectedItemDetExport, setSelectedItemDetExport] = useState(null);
  const [selectedRefNFes, setSelectedRefNFes] = useState(null);
  const [selectedRefEcfs, setSelectedRefEcfs] = useState(null);
  const [selectedRefProc, setSelectedRefProc] = useState(null);
  const [selectedFinanceiros, setSelectedFinanceiros] = useState(null);
  const [selectedDuplicatas, setSelectedDuplicatas] = useState(null);
  const [selectedAutXml, setSelectedAutXml] = useState(null);
  const [selectedTranspNLacre, setSelectedTranspNLacre] = useState(null);

  const [filteredItemDiUfDesemb, setFilteredItemDiUfDesemb] = useState(null);
  const [filteredItemDiUfTerceiro, setFilteredItemDiUfTerceiro] = useState(null);
  const [filteredTranspVeicTranspUF, setFilteredTranspVeicTranspUF] = useState(null);
  const [filteredNatOp, setFilteredNatOp] = useState(null);
  const [filteredUnidadeMedida, setFilteredUnidadeMedida] = useState(null);
  const [filteredItemCfop, setFilteredItemCfop] = useState(null);
  const [filteredItemCst, setFilteredItemCst] = useState(null);
  const [filteredPisCst, setFilteredPisCst] = useState(null);
  const [filteredCofinsCst, setFilteredCofinsCst] = useState(null);
  const [filteredTranspCfop, setFilteredTranspCfop] = useState(null);
  const [filteredItemCta, setFilteredItemCta] = useState(null);

  const [columnsSetupItem] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'registro', header: 'Registro', sort: true },
    { field: 'descricao', header: 'Descrição', sort: true },
    { field: 'uCom', header: 'Unidade', sort: false },
    { field: 'precoBruto', header: 'Preço Bruto', sort: false, moeda: true },
    { field: 'qCom', header: 'Quantidade', sort: false },
    { field: 'vDesc', header: 'Desconto', sort: false, moeda: true },
    { field: 'precoVenda', header: 'Valor UN', sort: false, moeda: true },
    { field: 'totalItem', header: 'Total Item', sort: false, moeda: true },
  ]);

  const [columnsSetupItemRastro] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'nLote', header: 'Número Lote', sort: true },
    { field: 'qLote', header: 'Qtde no Lote', sort: true },
    { field: 'dFab', header: 'Data Fabricação', sort: true, formatarDataComBarra: true },
    { field: 'dVal', header: 'Data Validade', sort: true, formatarDataComBarra: true },
    { field: 'cAgreg', header: 'Código Agregação', sort: true },
  ]);

  const [columnsSetupItemDi] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'nDi', header: 'Nº. DI/DSI/DA', sort: true },
    { field: 'dDi', header: 'Data Registro', sort: true, formatarDataComBarra: true },
    { field: 'xLocDesemb', header: 'Local do Desemb Aduaneiro', sort: true },
    { field: 'ufDesemb', header: 'UF Ocorrência', sort: true },
    { field: 'tpViaTransp', header: 'Via Transporte.', sort: true },
    { field: 'vAfrmm', header: 'Valor AFRMM', sort: true },
  ]);

  const [columnsSetupItemAdi] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'nAdicao', header: 'Nº. Adição', sort: true },
    { field: 'nSeqAdic', header: 'Nº. Seq. Item', sort: true },
    { field: 'cFabricante', header: 'Cód. Fabricante Estrangeiro', sort: true },
    { field: 'vDescDi', header: 'Valor Desconto', sort: true, moeda: true },
    { field: 'nDraw', header: 'Nº. Ato Drawback.', sort: true },
  ]);

  const [columnsSetupItemDetExport] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'nDraw', header: 'Nº Ato Concessório de Drawback', sort: true },
  ]);

  const [columnsSetupRefNFe] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'razaoSocial', header: 'Emitente', sort: true },
    { field: 'tipoOperacao', header: 'Tipo Operação', sort: true },
    { field: 'aamm', header: 'Data', sort: true },
    { field: 'nnf', header: 'Número', sort: true },
    { field: 'mod', header: 'Modelo', sort: true },
    { field: 'serie', header: 'Serie', sort: true },
    { field: 'refNFe', header: 'Chave Acesso', sort: true },
  ]);

  const [columnsSetupRefEcf] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'mod', header: 'Modelo', sort: true },
    { field: 'nEcf', header: 'Número ECF', sort: true },
    { field: 'nCoo', header: 'Número COO', sort: true },
  ]);

  const [columnsSetupRefProc] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'nProc', header: 'Número', sort: true },
    { field: 'indProc', header: 'Indicador Origem', sort: true },
    { field: 'tpAto', header: 'Tipo Ato Concessório', sort: true },
  ]);

  const [columnsSetupFinanceiro] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'tPag', header: 'Tipo Pagamento', sort: true },
    { field: 'dVenc', header: 'Data Vencimento', sort: true, formatarDataComBarra: true },
    { field: 'xPag', header: 'Descrição pagamento', sort: true },
    { field: 'vPag', header: 'Valor', sort: true, moeda: true },
  ]);

  const [columnsSetupDuplicata] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'nDup', header: 'Nº Duplicata', sort: true },
    { field: 'dVenc', header: 'Data Vencimento', sort: true, formatarDataComBarra: true },
    { field: 'vDup', header: 'Valor', sort: true, moeda: true },
  ]);

  const [columnsSetupAutXml] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'cnpj', header: 'CNPJ', sort: true },
    { field: 'cpf', header: 'CPF', sort: true },
  ]);

  const [columnsSetupTranspNLacre] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'nLacre', header: 'Número Lacre', sort: true },
  ]);

  const listaCodigoSituacao = obterListaCodigoSituacao();
  const listaTipoPagamentoNFe = obterListaTipoPagamentoNFe();
  const listaTipoMovimentacao = obterListaTipoMovimentacao();
  const listaTipoViaTransporte = obterListaTipoViaTransporte();
  const listaTipoIntermedio = obterListaTipoIntermedio();
  const listaTipoOperacao = obterListaTipoOperacao();
  const listaFinalidadeEmissao = obterListaFinalidadeEmissao();
  const listaIndicadorPresenca = obterListaIndicadorPresenca();
  const listaIndicadorIntermediario = obterListaIndicadorIntermediario();
  const listaModalidadeFrete = obterListaModalidadeFrete();
  const listaItemOrig = obterListaOrigemMercadoria();
  const listaModalidadeBcIcms = obterListaModalidadeBcIcms();
  const listaModalidadeBcIcmsSt = obterListaModalidadeBcIcmsSt();
  const listaItemCsosn = obterListaCsosn();
  const listaItemIpiCst = obterListaIpiCst();
  const listaItemTpArma = obterListaTipoArmamento();
  const listaMotivoDesoneracao = obterListaMotivoDesoneracao();
  const listaMotivoDesoneracaoSt = obterListaMotivoDesoneracaoSt();
  const listaIndicadorProcessoRef = obterListaIndicadorProcessoRef();
  const listaTipoAtoConcessorioProcRef = obterListaTipoAtoConcessorioProcessoReferenciado();

  async function setUltimoNumero(serie) {
    if (emissaoPropria && id === 0) {
      try {
        setIsLoading(true);

        (serie === '') ? serie = '1' : '';

        await axiosAdmin.get(`/nfe/ultimonumero?cnpjEmpresa=${empresaCnpj}&emissaoPropria=${emissaoPropria}&mod=${mod}&serie=${serie}`).then(res => {
          const ultimoNumero = res.data;
          if (res.data !== null) {
            const proximoNumero = parseInt(ultimoNumero, 10) + 1;
            setNNf(proximoNumero.toString());
          }
        }).catch(() => {
          setNNf(nNf);
        });
      } catch (err) {
        toast.warn('Não foi possível achar o número da nota');
      } finally {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    if (propsTipoMovimentacao !== 'entrada') {
      setUltimoNumero(serie.toString());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serie]);

  const setarListaAutXml = (data) => {
    let itemAutXml = 0;
    const _dataAutXml = [];

    data.listaNFeAutXML.forEach(elementAutXml => {
      itemAutXml = itemAutXml + 1;
      _dataAutXml.push({
        item: itemAutXml,
        cnpj: elementAutXml.cnpj,
        cpf: elementAutXml.cpf,
      });
    });

    setListaAutXml(_dataAutXml);
  };

  const setarListaNFeTranspLacre = (data) => {
    let itemTranspNLacre = 0;
    const _dataTranspNLacre = [];
    data.listaNFeTranspLacre.forEach(elementNLacre => {
      itemTranspNLacre = itemTranspNLacre + 1;

      _dataTranspNLacre.push({
        item: itemTranspNLacre,
        nLacre: elementNLacre.nlacre,
      });
    });
    setListaTranspNLacre(_dataTranspNLacre);
  };

  const setarListaFinanceiro = (data) => {
    let itemFinanceiro = 0;
    const _dataFinanceiro = [];
    data.listaNFePag.forEach(elementFinanceiro => {
      itemFinanceiro = itemFinanceiro + 1;

      const _dVenc = formataDataMoment(converteData(elementFinanceiro.dvenc), '/');
      _dataFinanceiro.push({
        item: itemFinanceiro,
        indPag: elementFinanceiro.indPag,
        tPag: elementFinanceiro.tpag,
        dVenc: _dVenc,
        vPag: elementFinanceiro.vpag,
        xPag: elementFinanceiro.xpag,
      });
    });

    setListaFinanceiro(_dataFinanceiro);
  };

  const setarListaDuplicatas = (data) => {
    let itemDuplicata = 0;
    const _dataDuplicata = [];
    data.listaNFeCobrDup.forEach(elementDuplicata => {
      itemDuplicata = itemDuplicata + 1;

      const _dVenc = formataDataMoment(converteData(elementDuplicata.dvenc), '/');
      _dataDuplicata.push({
        item: itemDuplicata,
        dVenc: _dVenc,
        nDup: elementDuplicata.ndup,
        vDup: elementDuplicata.vdup,
      });
    });

    setListaDuplicatas(_dataDuplicata);
  };

  const setarListaRefNFe = (data) => {
    let itemRefNFe = 0;
    const _dataRefNFe = [];
    data.listaNFeNFRefRefNFe.forEach(elementRefNFe => {
      itemRefNFe = itemRefNFe + 1;
      const _aamm = formataDataMoment(converteData(elementRefNFe.aamm), '-');
      _dataRefNFe.push({
        item: itemRefNFe,
        refNFe: elementRefNFe.refNFe,
        aamm: _aamm,
        nnf: elementRefNFe.nnf,
        mod: elementRefNFe.mod,
        serie: elementRefNFe.serie,
        tipoOperacao: elementRefNFe.tipoOperacao,
        razaoSocial: elementRefNFe.razaoSocial,
      });
    });

    setListaRefNFe(_dataRefNFe);
  };

  const setarListaRefEcf = (data) => {
    let itemRefECF = 0;
    const _dataRefECF = [];
    data.listaNFeNFRefRefECF.forEach(elementRefECF => {
      itemRefECF = itemRefECF + 1;

      _dataRefECF.push({
        item: itemRefECF,
        mod: elementRefECF.mod,
        nEcf: elementRefECF.necf,
        nCoo: elementRefECF.ncoo,
      });
    });

    setListaRefEcf(_dataRefECF);
  };

  const setarListaRefProc = (data) => {
    let itemProcRef = 0;
    const _dataProcRef = [];
    data.listaNFeProcRef.forEach(elementProcRef => {
      itemProcRef = itemProcRef + 1;

      _dataProcRef.push({
        item: itemProcRef,
        indProc: elementProcRef.indProc,
        nProc: elementProcRef.nproc,
        tpAto: elementProcRef.tpAto,
      });
    });

    setListaRefProc(_dataProcRef);
  };

  const setarListaNFeItem = async (data) => {
    try {
      const _dataItens = [];

      let somaVSeg = 0;
      data.listaNFeItem.forEach(element => {
        // console.log('element setar litaitem: ', element);
        if (element.vseg) {
          somaVSeg += Number(element.vseg);
        }

        let itemRastro = 0;
        const _dataItensRastro = [];
        if (element.listaNFeItemRastro !== undefined) {
          element.listaNFeItemRastro.forEach(elementRastro => {
            itemRastro = itemRastro + 1;
            const _dFab = formataDataMoment(converteData(elementRastro.dfab), '-');
            const _dVal = formataDataMoment(converteData(elementRastro.dval), '-');
            _dataItensRastro.push({
              item: itemRastro,
              cAgreg: elementRastro.cagreg,
              dFab: _dFab,
              dVal: _dVal,
              nLote: elementRastro.nlote,
              qLote: elementRastro.qlote,
            });
          });
        }

        let itemDetExport = 0;
        const _dataItensDetExport = [];
        if (element.listaNFeItemDetExport !== undefined) {
          element.listaNFeItemDetExport.forEach(elementDetExport => {
            itemDetExport = itemDetExport + 1;
            const detExport = {
              item: itemDetExport,
              nDraw: elementDetExport.ndraw,
            };

            _dataItensDetExport.push(detExport);
          });
        }

        let itemDi = 0;
        const _dataItensDi = [];
        if (element.listaNFeItemDI !== undefined) {
          element.listaNFeItemDI.forEach(elementDi => {
            let itemAdi = 0;
            const _dataItensAdi = [];
            elementDi.listaNFeItemADI.forEach(elementAdi => {
              itemAdi = itemAdi + 1;
              _dataItensAdi.push({
                item: itemAdi,
                nAdicao: elementAdi.nadicao,
                nSeqAdic: elementAdi.nseqAdic,
                cFabricante: elementAdi.cfabricante,
                vDescDI: elementAdi.vdescDi,
                nDraw: elementAdi.ndraw,
              });
            });

            itemDi = itemDi + 1;
            const _dDi = formataDataMoment(converteData(elementDi.ddi), '-');
            const _dDesemb = formataDataMoment(converteData(elementDi.ddesemb), '-');
            _dataItensDi.push({
              item: itemDi,
              nDi: elementDi.ndi,
              dDi: _dDi,
              xLocDesemb: elementDi.xlocDesemb,
              ufDesemb: elementDi.ufDesemb,
              dDesemb: _dDesemb,
              tpIntermedio: elementDi.tpIntermedio,
              tpViaTransp: elementDi.tpViaTransp,
              vAfrmm: elementDi.vafrmm,
              cnpj: elementDi.cnpj,
              ufTerceiro: elementDi.ufTerceiro,
              cExportador: elementDi.cexportador,
              listaNFeItemADIDTO: _dataItensAdi,
            });
          });
        }

        if (ufEmpresa === data.participante.uf) {
          setIdDest('INTERNA');
          if (tpNf !== 'entrada') {
            if (element.cfop.length < 4) {
              element.cfop = '5' + element.cfop;
            }
          }
        } else {
          setIdDest('INTERESTADUAL');
          if (tpNf !== 'entrada') {
            if (element.cfop.length < 4) {
              element.cfop = '6' + element.cfop;
            }
          }
        }

        // console.log('element: ', element);

        let _ipiCst = '99';
        let _ipiPIpi = 0;
        if (tpNf !== 'entrada') {
          _ipiCst = element.ipiCst;
          _ipiPIpi = element.ipiPIpi;
        } else {
          _ipiCst = element.ipiCst;
          _ipiPIpi = element.ipiPIpi;
        }

        _dataItens.push({
          item: element.item,
          idProduto: element.produto.id,
          registro: element.produto.registro,
          descricao: element.produto.descricao.trim(),
          uCom: element.ucom,
          qCom: element.qcom,
          vDesc: element.vdesc,
          precoBruto: element.precoBruto,
          ncm: element.ncm,
          cest: element.cest,
          precoVenda: +element.precoBruto - +element.vdesc,
          totalItem: (+element.precoBruto - +element.vdesc) * +element.qcom,
          vUnCom: element.vunCom,
          vProd: +element.precoBruto * +element.qcom,
          vFrete: element.vfrete ? element.vfrete : 0,
          vSeg: element.vseg,
          xPed: element.xped,
          nItemPed: element.nitemPed,
          indTot: element.indTot,

          cfop: element.cfop,
          cst: element.cst,
          csosn: element.csosn,
          orig: element.orig.toString(),
          cta: element.cta,
          indEscala: element.indEscala ? 'SIM' : 'NAO',
          cnpjFab: element.cnpjFab,
          cBenef: element.cBenef,

          vICMSIsento: element.vicmsisento ? element.vicmsisento : 0,
          vICMSOutro: element.vicmsoutro ? element.vicmsoutro : 0,
          vOutro: element.voutro,
          vTotTrib: element.vtotTrib,

          ipiCst: _ipiCst,
          ipiVBc: element.ipiVBc,
          ipiPIpi: _ipiPIpi,
          ipiVIpi: element.ipiVIpi,
          itemPDevol: element.impostoDevolPDevol,
          ipiVIpiDevol: element.ipiVIpiDevol,

          modBc: element.modBc.toString(),
          pRedBc: element.predBc,
          vBc: element.vbc,
          pIcms: element.picms,
          vIcms: element.vicms,
          motDesIcms: element.motDesIcms,
          vIcmsDeson: element.vicmsDeson,

          modBcSt: element.modBcSt.toString(),
          pRedBcSt: element.predBcSt,
          pMvaSt: element.pmvaSt,
          vBcSt: element.vbcSt,
          pIcmsSt: element.picmsSt,
          vIcmsSt: element.vicmsSt,
          motDesIcmsSt: element.motDesIcmsSt,
          vIcmsStDeson: element.vicmsStDeson,

          pisCst: element.pisCst,
          pisVBc: element.pisVBc,
          pisPPis: element.pisPPis,
          pisVPis: element.pisVPis,
          pisStVBc: element.pisStVBc, // nao tem no backend
          pisStPPis: element.pisStPPis, // nao tem no backend
          pisStVPis: element.pisStVPis, // nao tem no backend
          naturezaReceita: element.naturezaReceita,

          cofinsCst: element.cofinsCst,
          cofinsVBc: element.cofinsVBc,
          cofinsPCofins: element.cofinsPCofins,
          cofinsVCofins: element.cofinsVCofins,
          cofinsStVBc: element.cofinsStVBc, // nao tem no backend
          cofinsStPCofins: element.cofinsStPCofins, // nao tem no backend
          cofinsStVCofins: element.cofinsStVCofins, // nao tem no backend

          iiVBc: element.iiVBc,
          iiDespAdu: element.iiDespAdu,
          iiVIof: element.iiVIof,
          iiVIi: element.iiVIi,

          pCredSn: element.pcredSn,
          vCredIcmsSn: element.vcredIcmsSn,

          VBcUfDest: element.icmsUfDestVBcUfDest,
          PIcmsUfDest: element.icmsUfDestPIcmsUfDest,
          pIcmsInter: element.icmsUfDestPIcmsInter,
          pIcmsInterPart: element.icmsUfDestPIcmsInterPart,
          vIcmsUfDest: element.icmsUfDestVIcmsUfDest,
          vIcmsUfRemet: element.icmsUfDestVIcmsUfRemet,

          vBcFcp: element.vbcFcp,
          pFcp: element.pfcp,
          vFcp: element.vfcp,
          vBcFcpSt: element.vbcFcpSt,
          pFcpSt: element.pfcpSt,
          vFcpSt: element.vfcpSt,
          vBcFcpStRet: element.vbcFcpStRet,
          pFcpStRet: element.pfcpStRet,
          vFcpStRet: element.vfcpStRet,
          vBcFcpUfDest: element.icmsUfDestVBcFcpUfDest,
          pFcpUfDest: element.icmsUfDestPFcpUfDest,
          vFcpUfDest: element.icmsUfDestVFcpUfDest,

          armaTpArma: element.armaTpArma,
          armaNSerie: element.armaNSerie,
          armaNCano: element.armaNCano,
          armaDescr: element.armaDescr,

          listaItemRastro: _dataItensRastro,
          listaNFeItemDIDTO: _dataItensDi,
          listaNFeItemDetExportDTO: _dataItensDetExport,

          movimentacaoFisica: element.movimentacaoFisica,
        });
      });

      // console.log('_dataItens: ', _dataItens);

      setVSeg(somaVSeg);
      setListaItem(_dataItens);
    } catch (err) {
      toast.info('setar itens error' + err);
    }
  };

  const setarListaNFeCobrFat = (data) => {
    if (data.listaNFeCobrFat.length > 0) {
      setCobrFatNFat(data.listaNFeCobrFat[0].nfat);
      setCobrFatVOrig(data.listaNFeCobrFat[0].vorig.toString());
      setCobrFatVDesc(data.listaNFeCobrFat[0].vdesc.toString());
      setCobrFatVLiq(data.listaNFeCobrFat[0].vliq.toString());
    } else {
      setCobrFatNFat(data.numeroNf);
    }
  };

  const setarDadosTranporte = (data) => {
    if (data.participanteTransporta) {
      setIdParticipanteTransporta(data.participanteTransporta.id);
      setRazaoSocialTransporta(data.participanteTransporta.razaoSocial);
    }
    setTranspVeicTranspPlaca(data.transpVeicTranspPlaca);
    setTranspVeicTranspUF(data.transpVeicTranspUF);
    setTranspCfop(data.transpCFOP ? data.transpCFOP : '');
    setTranspModFrete(data.transpModFrete);
    setTranspQVol(data.transpQVol);
    setTranspEsp(data.transpEsp);
    setTranspNVol(data.transpNVol);
    setTranspPesoL(data.transpPesoL);
    setTranspPesoB(data.transpPesoB);
    setTranspVServ(data.transpVServ ? data.transpVServ : 0);
    setTranspMarca(data.transpMarca);
    setTranspVBCRet(data.transpVBCRet);
    setTranspVICMSRet(data.transpVICMSRet);
    setTranspVeicTranspRNTC(data.transpVeicTranspRNTC);
  };

  useEffect(() => {
    let newTitle = 'NF-e Emissão Própria - Cadastro';
    if (id) {
      newTitle = 'NF-e Emissão Própria - Edição';
    }

    dispatch(actions.titleHeaderChange({ newTitle }));

    const chaveAcesso = get(payloadCadastro, 'chaveAcesso', '');

    if (!id && tipoMovimentacao !== 'entrada') return; // tipoMovimentacao vem do handleregister do consultanfe

    const getDataById = async () => {
      const { data } = await axiosAdmin.get(`/nfe/${id}`);

      // console.log('nfe: ', data);

      if (data.flag === 'T' && data.emissaoPropria) {
        setShowConfirmEdicaoEnviada(true);
        setDisableEdicaoEnviada(true);
      }
      return data;
    };

    // eslint-disable-next-line consistent-return
    const getParticipanteByXml = async (data) => {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        // eslint-disable-next-line prefer-destructuring
        let cnpj = data.cnpj;
        if (data.cpf) {
          cnpj = data.cpf;
        }

        const params = `/participante?idUsuario=${idUsuario}&cnpjEmpresa=${empresaCnpj}&cnpj=${cnpj}`;
        const response = await axiosAdmin.get(params);

        if (response.data.length > 0) {
          const participante = {
            id: response.data[0].id,
            razaoSocial: response.data[0].razaoSocial,
            uf: response.data[0].uf,
          };

          return participante;
        }
        return [];
      } catch (e) {
        toast.info('Não foi possível coletar o xml 1' + e);
      } finally {
        setIsLoading(false);
      }
    };

    const getListaAutXmlbyXml = async (data) => {
      let itemAutXml = 0;
      const _dataAutXml = [];

      data.forEach(el => {
        itemAutXml = itemAutXml + 1;
        _dataAutXml.push({
          item: itemAutXml,
          cnpj: el.cnpj,
          cpf: el.cpf,
        });
      });

      return _dataAutXml;
    };

    const getConversaoXmlNfe = async (item, chave) => {
      try {
        const idUsuario = get(store.getState().auth.user, 'id', 0);

        return await axiosAdmin.get(`/conversaoxmlnfe?idUsuario=${idUsuario}&chaveAcesso=${chave}&item=${item}`);
      } catch (e) {
        toast.info('Não foi possível pegar a conversão de xml nfe');
      }
      return null;
    };

    const getProdutoById = async (id) => {
      try {
        return await axiosAdmin.get(`/produto/${id}`);
      } catch (e) {
        toast.info('Não foi possível pegar a descrição do produto');
      }
      return '';
    };

    const getImpostoIcms = async (data) => {
      let icms = '';
      data.forEach(el => {
        if (el.name.includes('ICMS')) {
          const result = Object.keys(el.value).map((key) => [el.value[key]]);
          // eslint-disable-next-line no-restricted-syntax
          for (const value of result) {
            if (value[0] !== null) {
              // eslint-disable-next-line prefer-destructuring
              icms = value;
              break;
            }
          }
        }
      });
      return icms;
    };

    const getImpostoPis = async (data) => {
      let pis = '';
      data.forEach(el => {
        if (el.name.includes('PIS')) {
          const result = Object.keys(el.value).map((key) => [el.value[key]]);
          // eslint-disable-next-line no-restricted-syntax
          for (const value of result) {
            if (value[0] !== null) {
              // eslint-disable-next-line prefer-destructuring
              pis = value;
              break;
            }
          }
        }
      });
      return pis;
    };

    const getImpostoCofins = async (data) => {
      let cofins = '';
      data.forEach(el => {
        if (el.name.includes('COFINS')) {
          const result = Object.keys(el.value).map((key) => [el.value[key]]);
          // eslint-disable-next-line no-restricted-syntax
          for (const value of result) {
            if (value[0] !== null) {
              // eslint-disable-next-line prefer-destructuring
              cofins = value;
              break;
            }
          }
        }
      });
      return cofins;
    };

    const getImpostoIi = async (data) => {
      // console.log('data ii: ', data);
      const value = [];
      data.forEach(el => {
        if (el.name.includes('II')) {
          value.push(el.value);
        }
      });
      return value;
    };

    const getImpostoIpi = async (data) => {
      const ipi = [];
      data.forEach(el => {
        if (el.name.includes('IPI')) {
          ipi.push(el.value);
        }
      });
      return ipi;
    };

    const getListaNFeCobrFatByXml = async (dados, data) => {
      const fat = {
        nfat: dados.nfat ? dados.nfat : '',
        vorig: dados.vorig ? dados.vorig : 0,
        vdesc: dados.vdesc ? dados.vdesc : 0,
        vliq: dados.vliq ? dados.vliq : 0,
      };
      data.listaNFeCobrFat.push(fat);
    };

    const getListaNFeCobrDupByXml = async (dados, data) => {
      dados.forEach(element => {
        const _dvenc = element.dvenc.split('-');
        const dup = {
          dvenc: _dvenc,
          ndup: element.ndup,
          vdup: element.vdup,
        };
        data.listaNFeCobrDup.push(dup);
      });
    };

    const getListaFinanceiroByXml = async (dados, data) => {
      dados.forEach(element => {
        let tpag = 'DINHEIRO';
        element.tpag === '02' ? tpag = 'CHEQUE' : '';
        element.tpag === '03' ? tpag = 'CARTAOCREDITO' : '';
        element.tpag === '04' ? tpag = 'CARTAODEBITO' : '';
        element.tpag === '05' ? tpag = 'CREDITOLOJA' : '';
        element.tpag === '10' ? tpag = 'VALEALIMENTACAO' : '';
        element.tpag === '11' ? tpag = 'VALEREFEICAO' : '';
        element.tpag === '12' ? tpag = 'VALEPRESENTE' : '';
        element.tpag === '13' ? tpag = 'VALECOMBUSTIVEL' : '';
        element.tpag === '14' ? tpag = 'DUPLICATA' : '';
        element.tpag === '15' ? tpag = 'BOLETO' : '';
        element.tpag === '16' ? tpag = 'DEPOSITO' : '';
        element.tpag === '17' ? tpag = 'PIX' : '';
        element.tpag === '18' ? tpag = 'TED' : '';
        element.tpag === '19' ? tpag = 'PROGRAMAFIDELIDADE' : '';
        element.tpag === '90' ? tpag = 'SEMPAGAMENTO' : '';
        element.tpag === '99' ? tpag = 'OUTROS' : '';

        const pag = {
          indPag: element.indPag === '1' ? 'APRAZO' : 'AVISTA',
          dvenc: data.dhEmi,
          tpag,
          vpag: element.vpag,
          xpag: element.xpag,
        };
        data.listaNFePag.push(pag);
      });
    };

    const getDadosTransporteByXml = async (dados, data) => {
      let modFrete = 'CIF';
      dados.modFrete === '1' ? modFrete = 'FOB' : '';
      dados.modFrete === '2' ? modFrete = 'TERCEIROS' : '';
      dados.modFrete === '3' ? modFrete = 'PROPRIOREMETENTE' : '';
      dados.modFrete === '4' ? modFrete = 'PROPRIODESTINATARIO' : '';
      dados.modFrete === '9' ? modFrete = 'SEMTRANSPORTE' : '';

      data.transpModFrete = modFrete;
      if (dados.veicTransp) {
        data.transpVeicTranspPlaca = dados.veicTransp.placa ? dados.veicTransp.placa : '';
        data.transpVeicTranspUF = dados.veicTransp.uf ? dados.veicTransp.uf : '';
        data.transpVeicTranspRNTC = dados.veicTransp.rntc ? dados.veicTransp.rntc : '';
      }
      if (dados.retTransp) {
        data.transpCFOP = dados.retTransp.cfop ? dados.retTransp.cfop : '';
        data.transpVServ = dados.retTransp.vserv ? dados.retTransp.vserv : 0;
        data.transpVBCRet = dados.retTransp.vbcret ? dados.retTransp.vbcret : 0;
        data.transpVICMSRet = dados.retTransp.vbicmsret ? dados.retTransp.vbicmsret : 0;
      }
      if (dados.vol[0]) {
        data.transpQVol = dados.vol[0].qvol ? dados.vol[0].qvol : 0;
        data.transpEsp = dados.vol[0].esp ? dados.vol[0].esp : '';
        data.transpNVol = dados.vol[0].nvol ? dados.vol[0].nvol : '';
        data.transpPesoL = dados.vol[0].pesoL ? dados.vol[0].pesoL : 0;
        data.transpPesoB = dados.vol[0].pesoB ? dados.vol[0].pesoB : 0;
        data.transpMarca = dados.vol[0].marca ? dados.vol[0].marca : '';

        if (dados.vol[0].lacres) {
          dados.vol[0].lacres.forEach(elementLacres => {
            const lacre = {
              nlacre: elementLacres.nlacre,
            };
            data.listaNFeTranspLacre.push(lacre);
          });
        }
      }
    };

    const getListaECFRefByXml = async (ecfRef, data) => {
      const ref = {
        mod: ecfRef.mod,
        necf: ecfRef.necf,
        ncoo: ecfRef.ncoo,
      };
      data.listaNFeNFRefRefECF.push(ref);
    };

    const getListaNFeRefByXml = async (nfeRef, data) => {
      const ref = {
        refNFe: nfeRef,
      };
      data.listaNFeNFRefRefNFe.push(ref);
    };

    const getListaProcRefByXml = async (procRef, data) => {
      procRef.forEach(elementProcRef => {
        let indProc = 'SEFAZ';
        elementProcRef.indProc === '1' ? indProc = 'JUSTICAFEDERAL' : '';
        elementProcRef.indProc === '2' ? indProc = 'JUSTICAESTADUAL' : '';
        elementProcRef.indProc === '3' ? indProc = 'SECEXRFB' : '';
        elementProcRef.indProc === '9' ? indProc = 'OUTROS' : '';

        let tipoAto = 'TERMODEACORDO';
        elementProcRef.tpAto === '10' ? tipoAto = 'REGIMEESPECIAL' : '';
        elementProcRef.tpAto === '12' ? tipoAto = 'AUTORIZACAOESPECIFICA' : '';

        const proc = {
          indProc,
          nproc: elementProcRef.nproc,
          tpAto: tipoAto,
        };

        data.listaNFeProcRef.push(proc);
      });
    };

    const calcularTributosByXml = async (dataItem) => {
      // console.log('dataItem:', dataItem);
      const cfop = dataItem.cfop.substring(1, 4);
      dataItem.totalItem = dataItem.qcom * (dataItem.vunCom - dataItem.vdesc);

      if (cfop === '152') {
        dataItem.picms = 0;
        dataItem.picmsSt = 0;
      }

      // eslint-disable-next-line prefer-destructuring
      const cst = dataItem.cst;

      let cstTributado = 'N';
      let calcularSubstituicao = 'N';
      let cstIsenta = 'N';
      let cstOutras = 'N';

      ['00', '10', '20'].includes(cst) ? cstTributado = 'S' : '';
      ['10', '30', '70'].includes(cst) ? calcularSubstituicao = 'S' : '';
      ['20', '30', '40', '41'].includes(cst) ? cstIsenta = 'S' : '';
      ['50', '51', '60', '70', '90'].includes(cst) ? cstOutras = 'S' : '';

      let valoricmsisento = 0;
      let valoricmsoutro = 0;

      if (cstTributado === 'S') {
        // estudando mais 10, 20
        // no retaguarda nao faz nada
      }

      if (calcularSubstituicao === 'S') {
        // no retaguarda nao calcula, apenas salva os valores na entrada da nota igual do xml
      }

      (dataItem.picms <= 0) ? dataItem.vicmsisento = dataItem.totalItem : 0;

      if (cstIsenta === 'S') {
        if (dataItem.picms > 0 && dataItem.predBc > 0) {
          valoricmsisento = +dataItem.totalItem - +dataItem.vbc - +dataItem.ipiVIpi;
        }
      }
      dataItem.vicmsisento = valoricmsisento > 0 ? valoricmsisento : 0;

      if (cstOutras === 'S') {
        if (dataItem.picms > 0 && dataItem.vicmsSt <= 0) {
          valoricmsoutro = arredondar((+dataItem.totalItem) + +dataItem.vicmsSt + +dataItem.ipiVIpi - +dataItem.vicmsisento, -2);
        } else {
          valoricmsoutro = arredondar(+dataItem.totalItem + +dataItem.vicmsSt + +dataItem.ipiVIpi - +dataItem.vicmsisento, -2);
        }
      }
      dataItem.vicmsoutro = valoricmsoutro > 0 ? valoricmsoutro : 0;

      dataItem.vcredIcmsSn = 0;
      if (dataItem.pcredSn > 0) {
        const valorCredito = arredondar(+dataItem.totalItem * (+dataItem.pcredSn / 100), -2);
        dataItem.vcredIcmsSn = valorCredito > 0 ? valorCredito : 0;
      }

      dataItem.totalFinal = dataItem.totalItem;
    };

    const getListaItensByXml = async (itens, data) => {
      const _listaItensXml = [];

      itens.forEach(async el => {
        const vdesc = el.prod.vDesc ? Number(el.prod.vDesc) : 0;
        const precoBruto = Number(el.prod.vunCom);
        const dataItem = {
          id: 0,
          item: Number(el.nitem),
          produto: [], // pego da conversaoxmnfe abaixo
          ucom: el.prod.ucom,
          qcom: Number(el.prod.qcom),
          vdesc,
          ncm: el.prod.ncm,
          cest: el.prod.cest ? el.prod.cest.trim() : '',
          precoBruto: Number(precoBruto),
          vunCom: Number(el.prod.vunCom),
          vfrete: el.prod.vfrete ? el.prod.vfrete : 0,
          vseg: el.prod.vseg ? el.prod.vseg : 0,
          xped: el.prod.xped,
          nitemPed: el.prod.nitemPed,
          indTot: el.prod.indTot === '0' ? 'NAOCOMPOE' : 'COMPOE',

          cfop: el.prod.cfop,

          indEscala: el.prod.indEscala ? 'SIM' : 'NAO',
          cnpjFab: el.prod.cnpjFab ? el.prod.cnpjFab : '',
          cBenef: el.prod.cBenef ? el.prod.cBenef : '',

          voutro: el.prod.voutro ? el.prod.voutro : 0,
          vicmsisento: el.prod.vICMSisento ? el.prod.vICMSisento : 0,
          vicmsoutro: el.prod.vICMSOutro ? el.prod.vICMSOutro : 0,
          vtotTrib: el.prod.vtotTrib ? el.prod.vtotTrib : 0, // ???

          iiVBc: 0,
          iiDespAdu: 0,
          iiVIof: 0,
          iiVIi: 0,

          ipiVBc: 0,
          ipiPIpi: 0,
          ipiVIpi: 0,
          ipiVUnid: 0,
          ipiQUnid: 0,

          armaTpArma: 'PERMITIDO',
          armaNSerie: '',
          armaNCano: '',
          armaDescr: '',

          listaNFeItemDetExport: [],
          listaNFeProcRef: [],
          movimentacaoFisica: true,
        };

        await getConversaoXmlNfe(el.nitem, chaveAcesso).then(async conv => {
          if (conv.data !== null) {
            const prod = await getProdutoById(conv.data.idProduto);

            const produto = {
              id: conv.data.idProduto,
              registro: prod.data.registro,
              descricao: prod.data.descricao.trim(),
            };

            // pega o pis cofins do produto depois verifica na conversaopiscofins
            dataItem.pisCst = prod.data.pisCofinsEntradaCst;
            dataItem.cofinsCst = prod.data.pisCofinsEntradaCst;
            dataItem.ipiCst = prod.data.ipiEntradaCst ? prod.data.ipiEntradaCst : '';

            const totalItem = Number(el.prod.vunCom) * dataItem.qcom;
            dataItem.qcom = dataItem.qcom * conv.data.qtdePorCx;
            dataItem.precoBruto = totalItem / dataItem.qcom;
            dataItem.vunCom = dataItem.precoBruto;
            dataItem.cfop = conv.data.cfopDestino;
            dataItem.cst = conv.data.cstDestino.substring(1, 3);
            dataItem.orig = conv.data.cstDestino.substring(0, 1);
            dataItem.csosn = prod.data.csosnVenda;
            dataItem.cta = prod.data.ctaEntrada; // verificar se no backend tem cta na tabela nfe
            dataItem.produto = produto;
            dataItem.naturezaReceita = prod.data.naturezaReceita;
          }
        });

        const icms = await getImpostoIcms(el.imposto.content);
        dataItem.modBc = icms[0].modBC ? icms[0].modBC : '0';
        dataItem.predBc = icms[0].predBC ? icms[0].predBC : '0';
        dataItem.vbc = icms[0].vbc ? icms[0].vbc : 0;
        dataItem.picms = icms[0].picms ? icms[0].picms : 0;
        dataItem.vicms = icms[0].vicms ? icms[0].vicms : 0;
        dataItem.motDesIcms = icms[0].motDesICMS ? icms[0].motDesICMS : '';
        dataItem.vicmsDeson = icms[0].vicmsdeson ? icms[0].vicmsdeson : '';
        dataItem.pCredSn = icms[0].pcredsn ? icms[0].pcredsn : 0;
        dataItem.vCredIcmsSn = icms[0].vcredicmssn ? icms[0].vcredicmssn : 0;

        dataItem.modBcSt = icms[0].modBCST ? icms[0].modBCST : '0';
        dataItem.predBcSt = icms[0].predBCST ? icms[0].predBCST : 0;
        dataItem.pmvaSt = icms[0].pmvast ? icms[0].pmvast : 0;
        dataItem.vbcSt = icms[0].vbcst ? icms[0].vbcst : 0;
        dataItem.picmsSt = icms[0].picmsst ? icms[0].picmsst : 0;
        dataItem.vicmsSt = icms[0].vicmsst ? icms[0].vicmsst : 0;
        dataItem.motDesIcmsSt = icms[0].motDesICMSST ? icms[0].motDesICMSST : '';
        dataItem.vicmsStDeson = icms[0].vicmsstdeson ? icms[0].vicmsstdeson : '';

        dataItem.vbcFcp = icms[0].vbcFcp ? icms[0].vbcFcp : 0;
        dataItem.pfcp = icms[0].pfcp ? icms[0].pfcp : 0;
        dataItem.vfcp = icms[0].vfcp ? icms[0].vfcp : 0;
        dataItem.vbcFcpSt = icms[0].vbcfcpst ? icms[0].vbcfcpst : 0;
        dataItem.pfcpSt = icms[0].pfcpst ? icms[0].pfcpst : 0;
        dataItem.vfcpSt = icms[0].vfcpst ? icms[0].vfcpst : 0;
        dataItem.vbcFcpStRet = icms[0].vbcfcpstret ? icms[0].vbcfcpstret : 0; // achar xml pra testar
        dataItem.pfcpStRet = icms[0].pfcpstret ? icms[0].pfcpstret : 0; // achar xml pra testar
        dataItem.vfcpStRet = icms[0].vfcpstret ? icms[0].vfcpstret : 0; // achar xml pra testar

        const pis = await getImpostoPis(el.imposto.content);
        // dataItem.pisCst pego no cadastro do produto
        dataItem.pisVBc = pis[0].vbc ? pis[0].vbc : 0;
        dataItem.pisPPis = pis[0].ppis ? pis[0].ppis : 0;
        dataItem.pisVPis = pis[0].vpis ? pis[0].vpis : 0;
        dataItem.pisStVBc = pis[0].pisStVBC ? pis[0].pisStVBC : 0; // pegar xml pra testar
        dataItem.pisStPPis = pis[0].pisStPPis ? pis[0].pisStPPis : 0; // pegar xml pra testar
        dataItem.pisStVPis = pis[0].pisStVPis ? pis[0].pisStVPis : 0; // pegar xml pra testar

        const cofins = await getImpostoCofins(el.imposto.content);
        // dataItem.cofinsCst pego do cadastro do produto
        dataItem.cofinsVBc = cofins[0].vbc ? cofins[0].vbc : 0;
        dataItem.cofinsPCofins = cofins[0].pcofins ? cofins[0].pcofins : 0;
        dataItem.cofinsVCofins = cofins[0].vcofins ? cofins[0].vcofins : 0;
        dataItem.cofinsStVBc = cofins[0].cofinsStVBc ? cofins[0].cofinsStVBc : 0; // pegar xml para testar
        dataItem.cofinsStPCofins = cofins[0].cofinsStPCofins ? cofins[0].cofinsStPCofins : 0; // pegar xml para testar
        dataItem.cofinsStVCofins = cofins[0].cofinsStVCofins ? cofins[0].cofinsStVCofins : 0; // pegar xml para testar

        const ii = await getImpostoIi(el.imposto.content);
        if (ii.length > 0) {
          dataItem.iiVBc = ii[0].vbc ? ii[0].vbc : 0;
          dataItem.iiDespAdu = ii[0].vdespAdu ? ii[0].vdespAdu : 0;
          dataItem.iiVIof = ii[0].viof ? ii[0].viof : 0;
          dataItem.iiVIi = ii[0].vii ? ii[0].vii : 0;
        }

        const ipi = await getImpostoIpi(el.imposto.content); // pegar xml pra testar
        if (ipi.length > 0) {
          if (ipi[0].ipint) {
            dataItem.ipiCst = ipi[0].ipint.cst ? ipi[0].ipint.cst : '';
          } else if (ipi[0].ipitrib !== null) {
            dataItem.ipiCst = ipi[0].ipitrib.cst ? ipi[0].ipitrib.cst : '';
            dataItem.ipiVBc = ipi[0].ipitrib.vbc ? ipi[0].ipitrib.vbc : 0;
            dataItem.ipiPIpi = ipi[0].ipitrib.pipi ? ipi[0].ipitrib.pipi : 0;
            dataItem.ipiVIpi = ipi[0].ipitrib.vipi ? ipi[0].ipitrib.vipi : 0;
            dataItem.ipiVUnid = ipi[0].ipitrib.vunid ? ipi[0].ipitrib.vunid : 0;
            dataItem.ipiQUnid = ipi[0].ipitrib.qunid ? ipi[0].ipitrib.qunid : 0;
          }
        }

        dataItem.itemPDevol = el.impostoDevolPDevol ? el.impostoDevolPDevol : 0;
        dataItem.ipiVIpiDevol = el.ipiVIpiDevol ? el.ipiVIpiDevol : 0;

        // pegar xml testar
        dataItem.pcredSn = el.pcredSn ? el.pcredSn : 0;
        dataItem.vcredIcmsSn = el.vcredIcmsSn ? el.vcredIcmsSn : 0;

        // pegar xml testar
        dataItem.icmsUfDestVBcUfDest = el.icmsUfDestVBcUfDest ? el.icmsUfDestVBcUfDest : 0;
        dataItem.icmsUfDestPIcmsUfDest = el.icmsUfDestPIcmsUfDest ? el.icmsUfDestPIcmsUfDest : 0;
        dataItem.icmsUfDestPIcmsInter = el.icmsUfDestPIcmsInter ? el.icmsUfDestPIcmsInter : 0;
        dataItem.icmsUfDestPIcmsInterPart = el.icmsUfDestPIcmsInterPart ? el.icmsUfDestPIcmsInterPart : 0;
        dataItem.icmsUfDestVIcmsUfDest = el.icmsUfDestVIcmsUfDest ? el.icmsUfDestVIcmsUfDest : 0;
        dataItem.icmsUfDestVIcmsUfRemet = el.icmsUfDestVIcmsUfRemet ? el.icmsUfDestVIcmsUfRemet : 0;

        // pegar xml testar
        dataItem.icmsUfDestVBcFcpUfDest = el.icmsUfDestVBcFcpUfDest ? el.icmsUfDestVBcFcpUfDest : 0;
        dataItem.icmsUfDestPFcpUfDest = el.icmsUfDestPFcpUfDest ? el.icmsUfDestPFcpUfDest : 0;
        dataItem.icmsUfDestVFcpUfDest = el.icmsUfDestVFcpUfDest ? el.icmsUfDestVFcpUfDest : 0;

        await calcularTributosByXml(dataItem);

        if (el.prod.arma.length > 0) {
          let tparma = 'PERMITIDO';
          if (el.prod.arma) {
            el.prod.arma[0].tpArma !== 0 ? tparma = 'RESTRITO' : '';
          }

          dataItem.armaTpArma = tparma;
          dataItem.armaNSerie = el.prod.arma[0].nserie ? el.prod.arma[0].nserie : '';
          dataItem.armaNCano = el.prod.arma[0].ncano ? el.prod.arma[0].ncano : '';
          dataItem.armaDescr = el.prod.arma[0].descr ? el.prod.arma[0].descr : '';
        }

        let itemRastro = 0;
        const _dataItensRastro = [];
        if (el.prod.rastro !== undefined) {
          el.prod.rastro.forEach(elementRastro => {
            itemRastro = itemRastro + 1;

            let _dFab = elementRastro.dfab.substring(0, 10);
            _dFab = _dFab.split('-');
            _dFab = [Number(_dFab[0]), Number(_dFab[1]), Number(_dFab[2])];

            let _dVal = elementRastro.dval.substring(0, 10);
            _dVal = _dVal.split('-');
            _dVal = [Number(_dVal[0]), Number(_dVal[1]), Number(_dVal[2])];

            _dataItensRastro.push({
              item: itemRastro,
              cagreg: elementRastro.cagreg,
              dfab: _dFab,
              dval: _dVal,
              nlote: elementRastro.nlote,
              qlote: elementRastro.qlote,
            });
          });
        }

        const _dataItensDi = [];
        if (el.prod.di !== undefined) {
          el.prod.di.forEach(elementDi => {
            const _dataItensAdi = [];
            if (elementDi.adi.length > 0) {
              elementDi.adi.forEach(elementAdi => {
                const adi = {
                  nadicao: elementAdi.nadicao,
                  nseqAdic: elementAdi.nseqAdic,
                  cfabricante: elementAdi.cfabricante,
                  vdescDi: elementAdi.vdescDI,
                  nDraw: elementAdi.ndraw,
                };
                _dataItensAdi.push(adi);
              });
            }

            let _dDi = elementDi.ddi.substring(0, 10);
            _dDi = _dDi.split('-');
            _dDi = [Number(_dDi[0]), Number(_dDi[1]), Number(_dDi[2])];

            let _dDesemb = elementDi.ddesemb.substring(0, 10);
            _dDesemb = _dDesemb.split('-');
            _dDesemb = [Number(_dDesemb[0]), Number(_dDesemb[1]), Number(_dDesemb[2])];

            let viaTransp = 'MARITIMA';
            elementDi.tpViaTransp === '2' ? viaTransp = 'FLUVIAL' : '';
            elementDi.tpViaTransp === '3' ? viaTransp = 'LACUSTRE' : '';
            elementDi.tpViaTransp === '4' ? viaTransp = 'AEREA' : '';
            elementDi.tpViaTransp === '5' ? viaTransp = 'POSTAL' : '';
            elementDi.tpViaTransp === '6' ? viaTransp = 'FERROVIARIA' : '';
            elementDi.tpViaTransp === '7' ? viaTransp = 'RODOVIARIA' : '';
            elementDi.tpViaTransp === '8' ? viaTransp = 'CONDUTO' : '';
            elementDi.tpViaTransp === '9' ? viaTransp = 'MEIOSPROPRIOS' : '';
            elementDi.tpViaTransp === '10' ? viaTransp = 'FICTA' : '';

            let tipoIntermedio = 'CONTAPROPRIA';
            elementDi.tpIntermedio === '2' ? tipoIntermedio = 'CONTAORDEM' : '';
            elementDi.tpIntermedio === '3' ? tipoIntermedio = 'ENCOMENDA' : '';

            _dataItensDi.push({
              ndi: elementDi.ndi,
              ddi: _dDi,
              xlocDesemb: elementDi.xlocDesemb,
              ufDesemb: elementDi.ufdesemb,
              ddesemb: _dDesemb,
              tpIntermedio: tipoIntermedio,
              tpViaTransp: viaTransp,
              vafrmm: elementDi.vafrmm,
              cnpj: elementDi.cnpj,
              ufTerceiro: elementDi.ufterceiro,
              cexportador: elementDi.cexportador,
              listaNFeItemADI: _dataItensAdi,
            });
          });
        }

        // detExport
        const _dataItensDetExport = [];
        if (el.prod.detExport.length > 0) {
          el.prod.detExport.forEach(elementDetExport => {
            const detExport = {
              ndraw: elementDetExport.ndraw,
            };
            _dataItensDetExport.push(detExport);
          });
        }

        dataItem.listaNFeItemRastro = _dataItensRastro;
        dataItem.listaNFeItemDI = _dataItensDi;
        dataItem.listaNFeItemDetExport = _dataItensDetExport;

        data.listaNFeItem.push(dataItem);
        if (itens.length === data.listaNFeItem.length) {
          setarListaNFeItem(data);
        }
      });
    };

    const montaNFeEntradaByXml = async (data) => {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const arquivoXml = get(payloadCadastro, 'arquivoXml', null);

        if (arquivoXml === null) {
          toast.info('Nenhum arquivo xml selecionado');
          return;
        }

        if (arquivoXml.base64.split(',')[1] !== null) {
          const shippingData = {
            xml: arquivoXml.base64.split(',')[1],
          };

          await axiosAdmin.post('/nfeutil/lexml/', shippingData).then(async res => {
            let dhEmi = res.data.nfe.infNFe.ide.dhEmi.substring(0, 10);
            const hora = res.data.nfe.infNFe.ide.dhEmi.substring(11, 13);
            const min = res.data.nfe.infNFe.ide.dhEmi.substring(14, 16);
            const segundo = res.data.nfe.infNFe.ide.dhEmi.substring(17, 19);
            dhEmi = dhEmi.split('-');
            dhEmi = [Number(dhEmi[0]), Number(dhEmi[1]), Number(dhEmi[2]), Number(hora), Number(min), Number(segundo)];

            let dhSaiEnt = '';
            if (res.data.nfe.infNFe.ide.dhSaiEnt) {
              dhSaiEnt = res.data.nfe.infNFe.ide.dhSaiEnt.substring(0, 10);
              const horaSai = res.data.nfe.infNFe.ide.dhSaiEnt.substring(11, 13);
              const minSai = res.data.nfe.infNFe.ide.dhSaiEnt.substring(14, 16);
              const segundoSai = res.data.nfe.infNFe.ide.dhSaiEnt.substring(17, 19);
              dhSaiEnt = dhSaiEnt.split('-');
              dhSaiEnt = [Number(dhSaiEnt[0]), Number(dhSaiEnt[1]), Number(dhSaiEnt[2]), Number(horaSai), Number(minSai), Number(segundoSai)];
            } else {
              dhSaiEnt = dhEmi;
            }

            let listaAutsXml = [];
            if (res.data.nfe.infNFe.autXML) {
              listaAutsXml = await getListaAutXmlbyXml(res.data.nfe.infNFe.autXML);
            }

            let indicadorPresenca = 'NAOSEAPLICA';
            res.data.nfe.infNFe.ide.indPres === '1' ? indicadorPresenca = 'PRESENCIAL' : '';
            res.data.nfe.infNFe.ide.indPres === '2' ? indicadorPresenca = 'INTERNET' : '';
            res.data.nfe.infNFe.ide.indPres === '3' ? indicadorPresenca = 'TELEATENDIMENTO' : '';
            res.data.nfe.infNFe.ide.indPres === '4' ? indicadorPresenca = 'NFCEENTREGADOMICILIO' : '';
            res.data.nfe.infNFe.ide.indPres === '5' ? indicadorPresenca = 'PRESENCIALFORAESTABELECIMENTO' : '';
            res.data.nfe.infNFe.ide.indPres === '9' ? indicadorPresenca = 'OUTROS' : '';

            let indIntermed = 'NAO';
            res.data.nfe.infNFe.ide.indIntermed === '1' ? indIntermed = 'SIM' : '';

            data = {
              idUsuario,
              id: 0,
              flag: 'T',
              emissaoPropria: false,
              chaveAcesso: res.data.protNFe.infProt.chNFe,
              protocolo: res.data.protNFe.infProt.nprot,
              recibo: '',
              numeroNf: res.data.nfe.infNFe.ide.nnf,
              serie: res.data.nfe.infNFe.ide.serie,
              dhEmi,
              dhSaiEnt,
              tpNf: 'ENTRADA',
              natOp: res.data.nfe.infNFe.ide.natOp,
              finNFe: 'NORMAL',
              tpEmis: 'NORMAL',
              mod: res.data.nfe.infNFe.ide.mod,
              indFinal: res.data.nfe.infNFe.ide.indFinal === '0' ? 'NAO' : 'SIM',
              indPres: indicadorPresenca,
              indIntermed,
              codSit: 'REGULAR',
              participante: await getParticipanteByXml(res.data.nfe.infNFe.emit),
              participanteTransporta: res.data.nfe.infNFe.transp.transporta ? await getParticipanteByXml(res.data.nfe.infNFe.transp.transporta) : '',
              infCpl: res.data.nfe.infNFe.infAdic.infCpl,
              infAdFisco: res.data.nfe.infNFe.infAdic.infAdFisco ? res.data.nfe.infNFe.infAdic.infAdFisco : '',
              listaNFeItem: [],
              listaNFeNFRefRefNFe: [],
              listaNFeNFRefRefNF: [],
              listaNFeNFRefRefNFP: [],
              listaNFeNFRefRefECF: [],
              listaNFeAutXML: listaAutsXml,
              listaNFeCobrFat: [],
              listaNFeCobrDup: [],
              listaNFePag: [],
              listaNFeProcRef: [],
              listaNFeTranspLacre: [],
            };

            if (res.data.nfe.infNFe.ide.nfref.length > 0) {
              res.data.nfe.infNFe.ide.nfref.forEach(async el => {
                /* if (el.refCTe) {
                  // await getListaNFeRefByXml(res.data.nfe.ide.nfref, data);
                } */
                if (el.refECF) {
                  await getListaECFRefByXml(el.refECF, data);
                }
                /* if (el.refNF) {
                  // await getListaNFeRefByXml(res.data.nfe.ide.nfref, data);
                }
                if (el.refNFP) {
                  await getListaNFeRefByXml(el.refNFe, data);
                } */
                if (el.refNFe) {
                  await getListaNFeRefByXml(el.refNFe, data);
                }
                /* if (el.refNFeSig) {
                  // await getListaNFeRefByXml(res.data.nfe.ide.nfref, data);
                } */
              });
            }

            if (res.data.nfe.infNFe.infAdic.procRef) {
              await getListaProcRefByXml(res.data.nfe.infNFe.infAdic.procRef, data);
            }

            if (res.data.nfe.infNFe.transp) {
              await getDadosTransporteByXml(res.data.nfe.infNFe.transp, data);
            }

            await getListaItensByXml(res.data.nfe.infNFe.det, data);

            // pegar o financeiro da nota se houver
            if (res.data.nfe.infNFe.pag) {
              if (res.data.nfe.infNFe.pag.detPag.length > 0) {
                await getListaFinanceiroByXml(res.data.nfe.infNFe.pag.detPag, data);
              }
            }
            // pegar as duplicatas da nota se houver
            if (res.data.nfe.infNFe.cobr) {
              if (res.data.nfe.infNFe.cobr.fat) {
                await getListaNFeCobrFatByXml(res.data.nfe.infNFe.cobr.fat, data);
              }
              if (res.data.nfe.infNFe.cobr.dup) {
                await getListaNFeCobrDupByXml(res.data.nfe.infNFe.cobr.dup, data);
              }
            }

            data.totalVBC = res.data.nfe.infNFe.total.icmstot.vbc ? res.data.nfe.infNFe.total.icmstot.vbc : 0;
            data.totalVICMS = res.data.nfe.infNFe.total.icmstot.icms ? res.data.nfe.infNFe.total.icmstot.vicms : 0;
            data.totalVICMSDeson = res.data.nfe.infNFe.total.icmstot.icmsdeson ? res.data.nfe.infNFe.total.icmstot.vicmsdeson : 0;
            data.totalVFCPUFDest = res.data.nfe.infNFe.total.icmstot.vfcpufdest ? res.data.nfe.infNFe.total.icmstot.vfcpufdest : 0;
            data.totalVICMSUFDest = res.data.nfe.infNFe.total.icmstot.vicmsufdest ? res.data.nfe.infNFe.total.icmstot.vicmsufdest : 0;
            data.totalVIICMSUFRemet = res.data.nfe.infNFe.total.icmstot.vicmsufremet ? res.data.nfe.infNFe.total.icmstot.vicmsufremet : 0;
            data.totalVFCP = res.data.nfe.infNFe.total.icmstot.vfcp ? res.data.nfe.infNFe.total.icmstot.vfcp : 0;

            data.totalVBCST = res.data.nfe.infNFe.total.icmstot.vbcst ? res.data.nfe.infNFe.total.icmstot.vbcst : 0;
            data.totalVST = res.data.nfe.infNFe.total.icmstot.vst ? res.data.nfe.infNFe.total.icmstot.vst : 0;
            data.totalVFCPST = res.data.nfe.infNFe.total.icmstot.vfcpst ? res.data.nfe.infNFe.total.icmstot.vfcpst : 0;
            data.totalVFCPStRet = res.data.nfe.infNFe.total.icmstot.vfcpstret ? res.data.nfe.infNFe.total.icmstot.vfcpstret : 0;
            data.totalVProd = res.data.nfe.infNFe.total.icmstot.vprod ? res.data.nfe.infNFe.total.icmstot.vprod : 0;

            data.totalVFrete = res.data.nfe.infNFe.total.icmstot.vfrete > 0 ? res.data.nfe.infNFe.total.icmstot.vfrete : 0;
            data.totalVSeg = res.data.nfe.infNFe.total.icmstot.vseg ? res.data.nfe.infNFe.total.icmstot.vseg : 0;
            data.totalVDesc = res.data.nfe.infNFe.total.icmstot.vdesc ? res.data.nfe.infNFe.total.icmstot.vdesc : 0;
            data.totalVII = res.data.nfe.infNFe.total.icmstot.vii ? res.data.nfe.infNFe.total.icmstot.vii : 0;
            data.totalVIPI = res.data.nfe.infNFe.total.icmstot.vipi ? res.data.nfe.infNFe.total.icmstot.vipi : 0;
            data.totalVIPIDevol = res.data.nfe.infNFe.total.icmstot.vipidevol ? res.data.nfe.infNFe.total.icmstot.vipidevol : 0;
            data.totalVPIS = res.data.nfe.infNFe.total.icmstot.vpis ? res.data.nfe.infNFe.total.icmstot.vpis : 0;
            data.totalVCOFINS = res.data.nfe.infNFe.total.icmstot.vcofins ? res.data.nfe.infNFe.total.icmstot.vcofins : 0;
            data.totalVOutro = res.data.nfe.infNFe.total.icmstot.voutro ? res.data.nfe.infNFe.total.icmstot.voutro : 0;
            data.totalVNF = res.data.nfe.infNFe.total.icmstot.vnf ? res.data.nfe.infNFe.total.icmstot.vnf : 0;
          });

          // eslint-disable-next-line consistent-return
          return data;
        } else {
          toast.success('Não foi possível carregar o xml.');
        }
      } catch (err) {
        toast.warn('Arquivo não contêm xml válido.' + err);
      } finally {
        setIsLoading(false);
      }
    };

    async function getData() {
      try {
        let data = null;
        if (tipoMovimentacao === 'entrada') {
          data = await montaNFeEntradaByXml(data);
        }

        if (id > 0) {
          data = await getDataById();
        }

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultanfepropria');
          toast.error('Acesso negado');
        }

        const _dhEmi = converteData(data.dhEmi, true);
        const _dhSaiEnt = converteData(data.dhSaiEnt, true);

        setFlag(data.flag);
        setEmissaoPropria(data.emissaoPropria);
        setChaveAcesso(data.chaveAcesso);
        setProtocolo(data.protocolo);
        setNumeroRecibo(data.numeroRecibo);
        setNNf(data.numeroNf);
        setDhEmi(_dhEmi);
        setDhSaiEnt(_dhSaiEnt);
        setTpNf(data.tpNf);
        setNatOp(data.natOp);
        setFinNfe(data.finNFe);
        setTpEmis(data.tpEmis);
        setMod(data.mod);
        setSerie(data.serie);
        setIndFinal((data.indFinal === 'SIM' || data.indFinal === 1) ? true : false);
        setIndPres(data.indPres);
        setIndIntermed(data.indIntermed === 'SIM' ? 'SIM' : 'NAO');
        setCodSit(data.codSit);

        setIdParticipante(data.participante.id);
        setXNome(data.participante.razaoSocial);
        setUfParticipante(data.participante.uf);

        setInfCpl(data.infCpl ? data.infCpl.trim() : '');
        setInfAdFisco(data.infAdFisco);

        setarListaAutXml(data);
        setarListaNFeTranspLacre(data);
        setarListaFinanceiro(data);
        setarListaDuplicatas(data);
        setarListaRefNFe(data);
        setarListaRefEcf(data);
        setarListaRefProc(data);
        if (tipoMovimentacao !== 'entrada') {
          setarListaNFeItem(data);
        }
        setarListaNFeCobrFat(data);
        setarDadosTranporte(data);

        setVOutro(data.totalVOutro);

        setTotalVBC(data.totalVBC);
        setTotalVICMS(data.totalVICMS);
        setTotalVICMSDeson(data.totalVICMSDeson);
        setTotalVFCPUFDest(data.totalVFCPUFDest);
        setTotalVICMSUFDest(data.totalVICMSUFDest);
        setTotalVIICMSUFRemet(data.totalVIICMSUFRemet);
        setTotalVFCP(data.totalVFCP);

        setTotalVBCST(data.totalVBCST);
        setTotalVST(data.totalVST);
        setTotalVFCPST(data.totalVFCPST);
        setTotalVFCPStRet(data.totalVFCPStRet);
        setTotalVProd(data.totalVProd);

        setValorTotalItens(+data.totalVProd - +data.totalVDesc);

        setTotalVFrete(data.totalVFrete);
        setTotalVSeg(data.totalVSeg);
        setTotalVDesc(data.totalVDesc);
        setTotalVII(data.totalVII);
        setTotalVIPI(data.totalVIPI);
        setTotalVIPIDevol(data.totalVIPIDevol);
        setTotalVPIS(data.totalVPIS);
        setTotalVCOFINS(data.totalVCOFINS);
        setTotalVOutro(data.totalVOutro);
        setTotalVICMSIsento(data.totalVICMSIsento);
        setTotalVICMSOutro(data.totalVICMSOutro);
        setTotalVNF(data.totalVNF);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, navigate]);

  async function getUnidadeMedidas(filtrarCnpjEmpresa) {
    const idUsuario = get(store.getState().auth.user, 'id', 0);

    const listDropdown = [];
    try {
      setIsLoading(true);

      let params = `/unidademedida?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      const { data: _unidadeMedidas } = await axiosAdmin.get(params);

      _unidadeMedidas.forEach(element => {
        listDropdown.push({ label: element.sigla, value: element.id });
      });

      setListaUnidadeMedidas(listDropdown);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function verificarSeMultiEmpresa(idUsuario) {
    try {
      setIsLoading(true);

      const params = `/usuario/${idUsuario}`;

      const response = await axiosAdmin.get(params);

      const _listaEmpresas = get(response.data, 'listaEmpresas', []);

      const listDropdown = [];

      let cnpjPreenchido = false;
      let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

      if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
        setCnpjEmpresa(_cnpjEmpresaStore);
        cnpjPreenchido = true;
      }

      _listaEmpresas.forEach(element => {
        if (!cnpjPreenchido) {
          setCnpjEmpresa(element.cnpj);
          setEmpresaAliquotaCofins(element.aliquotaCofins);
          setEmpresaAliquotaPis(element.aliquotaPis);
          _cnpjEmpresaStore = element.cnpj;
          cnpjPreenchido = true;
        }

        if (element.cnpj === _cnpjEmpresaStore) {
          setUfEmpresa(element.uf); // para calculo tributos
        }

        const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

        listDropdown.push({ label: nomeEmpresa, value: element.cnpj });
      });

      listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

      setListaEmpresas(listDropdown);

      getUnidadeMedidas(_cnpjEmpresaStore);
    } catch (err) {
      // if (mostrarMensagemErro) {
      //   displayErrors(err, dispatch, navigate, '/', '');
      // }
      // mostrarMensagemErro = false;
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  async function getNatOps() {
    const idUsuario = get(store.getState().auth.user, 'id', 0);

    const listDropdown = [];
    try {
      setIsLoading(true);

      const { data: _natOps } = await axiosAdmin.get(`/naturezaoperacao/?idUsuario=${idUsuario}`);

      _natOps.forEach(element => {
        listDropdown.push({ label: element.descricao, value: element.id });
      });

      setListaNatOps(listDropdown);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getNatOps();

    async function getCfops() {
      const listDropdown = [];
      try {
        setIsLoading(true);

        const { data: _cfops } = await axiosAdmin.get('/cfop/');

        _cfops.forEach(element => {
          const descricao = element.codigo + ' - ' + element.descricao.trim();
          listDropdown.push({ label: descricao, value: element.id });
        });

        setListaItemCfops(listDropdown);
        setListaTranspCfops(listDropdown);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }
    getCfops();

    async function getCsts() {
      const listDropdown = [];
      try {
        setIsLoading(true);

        const { data: _csts } = await axiosAdmin.get('/cst/');

        _csts.forEach(element => {
          const descricao = element.codigo + ' - ' + element.descricao.trim();
          listDropdown.push({ label: descricao, value: element.id });
        });

        setListaItemCsts(listDropdown);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }
    getCsts();

    async function getPISCOFINS() {
      const listDropdown = [];
      try {
        setIsLoading(true);

        const { data: _piscofins } = await axiosAdmin.get('/piscofins/');

        _piscofins.forEach(element => {
          const descricao = element.codigo + ' - ' + element.descricao;
          listDropdown.push({ label: descricao, value: element.id });
        });

        setListaPISCOFINS(listDropdown);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }
    getPISCOFINS();

    async function getCtas() {
      const idUsuario = get(store.getState().auth.user, 'id', 0);
      const listDropdown = [];
      try {
        setIsLoading(true);

        const { data: _ctas } = await axiosAdmin.get(`/contaanalitica/?idUsuario=${idUsuario}`);

        _ctas.forEach(element => {
          const descricao = element.codigo + ' - ' + element.descricao;
          listDropdown.push({ label: descricao, value: element.id });
        });

        setListaItemCtas(listDropdown);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }
    getCtas();

    async function getUnidadeFederacao() {
      const listDropdown = [];
      try {
        setIsLoading(true);

        const { data: _ufs } = await axiosAdmin.get('/unidadefederacao/');

        _ufs.forEach(element => {
          listDropdown.push({ label: element.sigla, value: element.sigla });
        });

        setListaItemDiUfDesemb(listDropdown);
        setListaItemDiUfTerceiro(listDropdown);
        setListaTranspVeicTranspUF(listDropdown);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }
    getUnidadeFederacao();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // calculos do cadastro do item
  useEffect(() => {
    if (itemVDesc > 0 && itemVDesc >= itemPrecoBruto) {
      toast.info('Valor de desconto incorreto');
      return;
    }

    if (itemQCom > 0 && itemPrecoBruto > 0) {
      setItemTotalBruto(arredondar(itemQCom * itemPrecoBruto, -3));
      setItemPrecoVenda(arredondar(itemPrecoBruto - itemVDesc, -3));
      setItemTotalFinal(itemQCom * itemPrecoVenda);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemQCom, itemPrecoBruto, itemVDesc]);

  useEffect(() => {
    setItemTotalFinal(itemQCom * itemPrecoVenda);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemPrecoVenda]);

  useEffect(() => {
    setItemPrecoBruto(itemTotalBruto / itemQCom);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemTotalBruto]);
  // fim calculos item

  function limparCamposFinanceiro() {
    setPagXPag('');
    setPagVPag(0);
    setPagDVenc(new Date());
  }

  function limparCamposDuplicata() {
    setCobrDupDVenc(new Date());
    setCobrDupVDup(0);
    setCobrDupCondicaoPagamento('');
  }

  function limparCamposRefNFe() {
    setRefNFeIdEmitente('');
    setRazaoSocialRefNFeEmitente('');
    setRefNFe('');
    setRefNFeMod('');
    setRefNFeSerie('');
    setRefNFeNNF('');
    setRefNFeAAMM(null);
    setRefNFeTipoOperacao('ENTRADA');
  }

  function limparCamposRefEcf() {
    setRefEcfMod('');
    setRefEcfNEcf('');
    setRefEcfNCoo('');
  }

  function limparCamposRefProc() {
    setRefProcNProc('');
    setRefProcIndProc('');
    setRefProcTpAto('');
  }

  function limparCamposAutXml() {
    setAutXmlCnpj('');
    setAutXmlCpf('');
  }

  function limparCamposTranspNLacre() {
    setTranspNLacre('');
  }

  function limparCamposItem() {
    setItemDescricaoProduto('');
    setItemUCom('');
    setItemQCom(0);
    setItemPrecoBruto(0);
    setItemTotalBruto(0);
    setItemVDesc(0);
    setItemPrecoVenda(0);
    setItemTotalFinal(0);
    setItemNumeroItemPedidoCompra('');
    setItemNumeroPedidoCompra('');
    setItemIndTot('COMPOE'); // compor valor final da nota

    setItemCfop('5403');
    setItemCst('60');
    setItemCsosn('500');
    setItemOrig(0);
    setItemNcm('');
    setItemCta('');
    setItemIndEscala('');
    setItemCnpjFab('');
    setItemCBenef('');
    setItemNaturezaReceita('');

    setItemIpiCst('');
    setItemIpiVBc(0);
    setItemIpiPIpi(0);
    setItemIpiVIpi(0);
    setItemPDevol(0);
    setItemIpiVIpiDevol(0);

    setItemModBc(0);
    setItemPRedBc(0);
    setItemVBc(0);
    setItemPIcms(0);
    setItemVIcms(0);
    setItemModBcST(0);
    setItemPRedBcSt(0);
    setItemPMvaSt(0);
    setItemVBcSt(0);
    setItemPIcmsSt(0);
    setItemVIcmsSt(0);
    setItemMotDesIcms('');
    setItemVIcmsDeson(0);
    setItemMotDesIcmsSt('');
    setItemVIcmsStDeson(0);

    setItemPisCst('99');
    setItemPisVBc(0);
    setItemPisPPis(0);
    setItemPisVPis(0);
    setItemPisStVBc(0);
    setItemPisStPPis(0);
    setItemPisStVPis(0);

    setItemCofinsCst('99');
    setItemCofinsVBc(0);
    setItemCofinsPCofins(0);
    setItemCofinsVCofins(0);
    setItemCofinsStVBc(0);
    setItemCofinsStPCofins(0);
    setItemCofinsStVCofins(0);

    setItemIiVBc(0);
    setItemIiDespAdu(0);
    setItemIiVIof(0);
    setItemIiVIi(0);

    setItemPCredSn(0);
    setItemVCredIcmsSn(0);

    setItemPartilhaVBcUfDest(0);
    setItemPartilhaPIcmsUfDest(0);
    setItemPartilhaPIcmsInter(0);
    setItemPartilhaPIcmsInterPart(0);
    setItemPartilhaVIcmsUfDest(0);
    setItemPartilhaVIcmsUfRemet(0);

    setItemVBcFcp(0);
    setItemPFcp(0);
    setItemVFcp(0);
    setItemVBcFcpSt(0);
    setItemPFcpSt(0);
    setItemVFcpSt(0);
    setItemVBcFcpStRet(0);
    setItemPFcpStRet(0);
    setItemVFcpStRet(0);

    setListaItemRastro([]);

    setListaItemDi([]);
    setListaItemAdi([]);
    setListaItemDetExport([]);

    setItemArmaTpArma('PERMITIDO');
    setItemArmaNSerie('');
    setItemArmaNCano('');
    setItemArmaDescr('');
  }

  function limparCamposItemRastro() {
    setItemRastroNLote('');
    setItemRastroQLote('');
    setItemRastroCAgreg('');
    setItemRastroDFab(null);
    setItemRastroDVal(null);
  }

  function limparCamposItemDi() {
    setItemDiNDi('');
    setItemDiDDi(null);
    setItemDiTpViaTransp('MARITIMA');
    setItemDiVAfrmm('');
    setItemDiXLocDesemb('');
    setItemDiUfDesemb('');
    setItemDiDDesemb(null);
    setItemDiTpIntermedio('CONTAPROPRIA');
    setItemDiCnpj('');
    setItemDiUfTerceiro('');
    setItemDiCExportador('');
    setListaItemAdi([]);
  }

  function limparCamposItemAdi() {
    setItemAdiNAdicao('');
    setItemAdiNSeqAdic('');
    setItemAdiCFabricante('');
    setItemAdiVDescDi(0);
    setItemAdiNDraw('');
  }

  function limparCamposItemDetExport() {
    setItemDetExportNDraw('');
  }

  const calcularTotaisNotaFiscalEnviada = (_itens) => {
    let _valorTotalVPis = 0;
    let _valorTotalVCofins = 0;

    _itens.forEach(element => {
      _valorTotalVPis = _valorTotalVPis + +element.pisVPis;
      _valorTotalVCofins = _valorTotalVCofins + +element.cofinsVCofins;
    });

    setTotalVPIS(_valorTotalVPis);
    setTotalVCOFINS(_valorTotalVCofins);
  };

  const calcularTotaisNotaFiscal = (_itens) => {
    let _valorTotalBc = 0;
    let _valorTotalIcms = 0;
    let _valorTotalIcmsDeson = 0;
    let _valorTotalVFcp = 0;
    let _valorTotalBcSt = 0;
    let _valorTotalVIcmsSt = 0;
    let _valorTotalVFcpSt = 0;
    let _valorTotalVFcpStRet = 0;
    let _valorTotalVFcpUfDest = 0;
    let _valorTotalVDesc = 0;
    let _valorTotalVPis = 0;
    let _valorTotalVCofins = 0;
    let _valorTotalVICMSIsento = 0;
    let _valorTotalVICMSOutro = 0;
    let _valorTotalVII = 0;
    let _valorTotalItens = 0;
    let _valorTotalVIPI = 0;
    let _valorTotalVIPIDevol = 0;
    let _valorTotalVSeg = 0;
    let _valorTotalVFrete = 0;
    let _valorTotalVOutro = 0;
    let _valorTotalVProd = 0;
    let _valorTotalVTotTrib = 0;

    _itens.forEach(element => {
      _valorTotalBc = +_valorTotalBc + +element.vBc;
      _valorTotalIcms = +_valorTotalIcms + +element.vIcms;

      _valorTotalIcmsDeson = +_valorTotalIcmsDeson + +element.vIcmsDeson;

      _valorTotalVFcp = +_valorTotalVFcp + +element.vFcp;
      _valorTotalVFcpSt = +_valorTotalVFcpSt + +element.vFcpSt;
      _valorTotalVFcpStRet = +_valorTotalVFcpStRet + +element.vFcpStRet;
      _valorTotalVFcpUfDest = +_valorTotalVFcpUfDest + +(element.VFcpUfDest ? element.VFcpUfDest : 0);

      _valorTotalBcSt = +_valorTotalBcSt + +element.vBcSt;
      _valorTotalVIcmsSt = +_valorTotalVIcmsSt + +element.vIcmsSt;

      _valorTotalVDesc = +_valorTotalVDesc + ((+element.precoBruto * +element.qCom) - (+element.totalItem));

      _valorTotalVPis = +_valorTotalVPis + +element.pisVPis;
      _valorTotalVCofins = +_valorTotalVCofins + +element.cofinsVCofins;
      _valorTotalVICMSIsento = +_valorTotalVICMSIsento + +element.vICMSIsento;
      _valorTotalVICMSOutro = +_valorTotalVICMSOutro + +element.vICMSOutro;
      _valorTotalVII = +_valorTotalVII + +element.iiVIi;
      _valorTotalVIPI = +_valorTotalVIPI + +element.ipiVIpi;
      _valorTotalVIPIDevol = +_valorTotalVIPIDevol + +element.ipiVIpiDevol;

      _valorTotalVSeg = +_valorTotalVSeg + +element.vSeg;

      if (transpModFrete === 'CIF' && tpNf === 'SAIDA') {
        _valorTotalVFrete = +_valorTotalVFrete + +element.vFrete;
      }

      _valorTotalVOutro = +_valorTotalVOutro + +element.vOutro;

      _valorTotalItens = +_valorTotalItens + +element.totalItem;
      _valorTotalVProd = +_valorTotalVProd + (+element.precoBruto * +element.qCom);
      _valorTotalVTotTrib = +_valorTotalVTotTrib + +element.vTotTrib;
    });

    setTotalVBC(_valorTotalBc);
    setTotalVICMS(_valorTotalIcms);

    setTotalVICMSDeson(_valorTotalIcmsDeson);

    setTotalVFCP(_valorTotalVFcp);
    setTotalVFCPST(_valorTotalVFcpSt);
    setTotalVFCPStRet(_valorTotalVFcpStRet);
    setTotalVFCPUFDest(_valorTotalVFcpUfDest);

    setTotalVBCST(_valorTotalBcSt);
    setTotalVST(_valorTotalVIcmsSt);

    setTotalVDesc(_valorTotalVDesc >= 0 ? _valorTotalVDesc : 0);

    setTotalVPIS(_valorTotalVPis);
    setTotalVCOFINS(_valorTotalVCofins);
    setTotalVICMSIsento(_valorTotalVICMSIsento);
    setTotalVICMSOutro(_valorTotalVICMSOutro);
    setTotalVII(_valorTotalVII);
    setTotalVIPI(_valorTotalVIPI);
    setTotalVIPIDevol(_valorTotalVIPIDevol);

    setValorTotalItens(_valorTotalItens);
    setTotalVProd(_valorTotalVProd);

    setTotalVSeg(_valorTotalVSeg);
    setTotalVFrete(_valorTotalVFrete);
    setTotalVOutro(_valorTotalVOutro);

    setTotalVNF(_valorTotalVProd - (+_valorTotalVDesc + +_valorTotalIcmsDeson) + +_valorTotalVIcmsSt + +_valorTotalVFcpSt + +_valorTotalVFrete + +_valorTotalVSeg + +_valorTotalVOutro + +_valorTotalVII + +_valorTotalVIPI + +_valorTotalVIPIDevol);
  };

  const onHideCadastroFinanceiro = () => {
    limparCamposFinanceiro();
    setShowCadastroFinanceiro(false);
  };

  const onHideCadastroDuplicata = () => {
    limparCamposDuplicata();
    setShowCadastroDuplicata(false);
  };

  const onHideTotaisNota = () => {
    setShowTotaisNota(false);
  };

  const onHideDadosAdicionais = () => {
    setShowDadosAdicionais(false);
  };

  const onHideFinanceiro = () => {
    setShowFinanceiro(false);
  };

  const onHideCadastroAutXml = () => {
    limparCamposAutXml();
    setShowCadastroAutXml(false);
  };

  const onHideCadastroTranspNLacre = () => {
    limparCamposTranspNLacre();
    setShowCadastroTranspNLacre(false);
  };

  const onHideCadastroRefNFe = () => {
    limparCamposRefNFe();
    setShowCadastroRefNFe(false);
  };

  const onHideCadastroRefEcf = () => {
    limparCamposRefEcf();
    setShowCadastroRefEcf(false);
  };

  const onHideCadastroRefProc = () => {
    limparCamposRefProc();
    setShowCadastroRefProc(false);
  };

  const onHideCadastroItem = () => {
    limparCamposItem();
    setShowCadastroItem(false);
  };

  const onHideCadastroItemRastro = () => {
    limparCamposItemRastro();
    setShowCadastroItemRastro(false);
  };

  const onHideCadastroItemDi = () => {
    limparCamposItemDi();
    setShowCadastroItemDi(false);
  };

  const onHideCadastroItemAdi = () => {
    limparCamposItemAdi();
    setShowCadastroItemAdi(false);
  };

  const onHideCadastroItemDetExport = () => {
    limparCamposItemDetExport();
    setShowCadastroItemDetExport(false);
  };

  const getDistintosCfopCstPIcmsItem = async (_itens) => {
    const listaCfopCst = [];
    let cfop = '';
    let cst = '';
    let pIcms = '';
    _itens.forEach(element => {
      if (!listaCfopCst.find(element => (element.cfop === cfop && element.cst === cst)) && (cfop !== '' && cst !== '' && pIcms !== '')) {
        //
      } else {
        // eslint-disable-next-line no-lonely-if
        if (element.cfop !== cfop || element.cst !== cst || element.pIcms !== pIcms) {
          listaCfopCst.push({
            cfop: element.cfop,
            cst: element.cst,
            pIcms: element.pIcms,
          });
        }
      }
      cfop = element.cfop;
      cst = element.cst;
      pIcms = element.pIcms;
    });

    return listaCfopCst;
  };

  const calcularTotaisCfopCst = async (_itens) => {
    await getDistintosCfopCstPIcmsItem(_itens).then(res => {
      const _listaTotaisPorCfopCst = [];

      res.forEach(element => {
        let vBcIcms = 0;
        let pIcms = 0;
        let vIcms = 0;
        let vBcIcmsSt = 0;
        let vIcmsSt = 0;
        let vIcmsUfRemet = 0;
        let vIcmsUfDest = 0;
        let vFcp = 0;
        let vFcpStRet = 0;
        let vFcpUfDest = 0;
        let vICMSIsento = 0;
        let vICMSOutro = 0;
        let vOutro = 0;
        let ipiVBc = 0;
        let ipiVIpi = 0;
        let pisVBc = 0;
        let pisVPis = 0;
        let pisVBcSt = 0;
        let pisVPisSt = 0;
        let cofinsVBc = 0;
        let cofinsVCofins = 0;
        let cofinsVBcSt = 0;
        let cofinsVCofinsSt = 0;
        let vIi = 0;
        let vDesc = 0;
        let vRedBcIcms = 0;
        let vSeg = 0;
        let vFrete = 0;

        _itens.forEach(item => {
          if (item.cfop === element.cfop && item.cst === element.cst && item.pIcms === element.pIcms) {
            vBcIcms = vBcIcms + item.vBc;
            pIcms = item.pIcms;
            vIcms = vIcms + item.vIcms;
            vBcIcmsSt = vBcIcmsSt + item.vBcSt;
            vIcmsSt = vIcmsSt + item.vIcmsSt;
            vIcmsUfRemet = vIcmsUfRemet + item.vIcmsUfRemet;
            vIcmsUfDest = vIcmsUfDest + item.vIcmsUfDest;
            vFcp = vFcp + item.vFcp;
            vFcpStRet = vFcpStRet + item.vFcpStRet;
            vFcpUfDest = vFcpUfDest + item.vFcpUfDest;
            vICMSIsento = vICMSIsento + item.vICMSIsento;
            vICMSOutro = vICMSOutro + item.vICMSOutro;
            vOutro = vOutro + item.vOutro;
            ipiVBc = ipiVBc + item.ipiVBc;
            ipiVIpi = ipiVIpi + item.ipiVIpi;
            pisVBc = pisVBc + item.pisVBc;
            pisVPis = pisVPis + item.pisVPis;
            pisVBcSt = pisVBcSt + item.pisStVBc;
            pisVPisSt = pisVPisSt + item.pisStVPis;
            cofinsVBc = cofinsVBc + item.cofinsVBc;
            cofinsVCofins = cofinsVCofins + item.cofinsVCofins;
            cofinsVBcSt = cofinsVBcSt + item.cofinsStVBc;
            cofinsVCofinsSt = cofinsVCofinsSt + item.cofinsStVCofins;
            vIi = vIi + item.iiVIi;
            vDesc = vDesc + item.vDesc;
            vRedBcIcms = vRedBcIcms + (item.vBc * (item.pRedBc / 100));
            vSeg = vSeg + item.vSeg;
            vFrete = vFrete + item.vFrete;
          }
        });

        _listaTotaisPorCfopCst.push({
          cfop: element.cfop,
          cst: element.cst,
          vBcIcms,
          pIcms,
          vIcms,
          vBcIcmsSt,
          vIcmsSt,
          vIcmsUfRemet,
          vIcmsUfDest,
          vFcp,
          vFcpStRet,
          vFcpUfDest,
          vICMSIsento: vICMSIsento >= 0 ? vICMSIsento : 0,
          vICMSOutro: vICMSOutro >= 0 ? vICMSOutro : 0,
          vOutro,
          ipiVBc,
          ipiVIpi,
          pisVBc,
          pisVPis,
          pisVBcSt,
          pisVPisSt,
          cofinsVBc,
          cofinsVCofins,
          cofinsVBcSt,
          cofinsVCofinsSt,
          vIi,
          vDesc,
          vRedBcIcms,
          vSeg,
          vFrete,
        });
      });

      setListaTotaisPorCfopCst(_listaTotaisPorCfopCst);
    });
  };

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);
      const cnpjEmpresa = get(payloadCadastro, 'cnpjEmpresa', '');

      let _dhEmi = null;
      if (dhEmi) {
        _dhEmi = formataDataMoment(dhEmi, '-', true);
      }

      let _dhSaiEnt = null;
      if (dhSaiEnt) {
        _dhSaiEnt = formataDataMoment(dhSaiEnt, '-', true);
      } else {
        _dhSaiEnt = formataDataMoment(dhEmi, '-', true);
      }

      const percFrete = (transpVServ / valorTotalItens);
      const percSeguro = (vSeg / valorTotalItens);
      const percOutrasDespAcess = (vOutro / valorTotalItens);

      let valorTotTrib = 0;

      listaItem.forEach(element => {
        element.vFrete = +element.totalItem * +percFrete;
        element.vSeg = +element.totalItem * +percSeguro;
        element.vOutro = +element.totalItem * +percOutrasDespAcess;
        valorTotTrib += +element.vTotTrib;
      });

      listaFinanceiro.forEach(element => {
        element.dVenc = replaceAll(element.dVenc, '/', '-');
      });

      listaDuplicatas.forEach(element => {
        element.dVenc = replaceAll(element.dVenc, '/', '-');
      });

      const listaFaturas = [];

      if (cobrFatNFat !== '' && cobrFatNFat !== null && cobrFatNFat !== undefined) {
        listaFaturas.push({
          nFat: cobrFatNFat,
          vOrig: cobrFatVOrig,
          vDesc: cobrFatVDesc,
          vLiq: cobrFatVLiq,
        });
      }

      const _listaItem = orderBy(listaItem, 'item', 'asc');

      let informacaoComplementar = '';
      if (valorTotTrib > 0) {
        informacaoComplementar = 'Valor total aproximado de tributos : ' + formataMoeda(valorTotTrib);
      }

      informacaoComplementar = informacaoComplementar + ' ' + infCpl;

      const shippingData = {
        flag,
        chaveAcesso,
        protocolo,
        numeroRecibo,
        idDest,
        emissaoPropria,
        idUsuario,
        cnpjEmpresa,
        idParticipante,
        cNf: Math.random().toString().slice(2, 10),
        numeroNf: nNf,
        dhEmi: _dhEmi,
        dhSaiEnt: _dhSaiEnt,
        tpNf,
        natOp: natOp.label ? natOp.label : natOp,
        finNfe,
        tpEmis,
        mod,
        codSit,
        serie,
        indFinal: indFinal === true || indFinal === '1' ? 'SIM' : 'NAO',
        indPres,
        indIntermed: indIntermed === 'SIM' ? 'SIM' : 'NAO',
        infCpl: informacaoComplementar ? informacaoComplementar.trim() : '',
        infAdFisco,
        totalVFrete,
        idParticipanteTransporta,
        transpModFrete,
        transpCFOP: transpCfop.label ? transpCfop.label.substring(0, 4) : transpCfop,
        transpQVol,
        transpPesoL,
        transpPesoB,
        transpMarca,
        transpEsp,
        transpNVol,
        transpVeicTranspPlaca,
        transpVeicTranspUF,
        transpVeicTranspRNTC,
        transpVServ,
        transpVBCRet,
        transpVICMSRet,

        totalVBC,
        totalVICMS: totalVICMS.toFixed(2),
        totalVICMSDeson,
        totalVFCPUFDest,
        totalVICMSUFDest,
        totalVIICMSUFRemet,
        totalVFCP,
        totalVBCST,
        totalVST,
        totalVFCPST,
        totalVFCPStRet,
        totalVfrete: transpVServ,
        totalVSeg,
        totalVII,
        totalVIPI,
        totalVIPIDevol,
        totalVPIS,
        totalVCOFINS,
        totalVOutro,
        totalVICMSIsento,
        totalVICMSOutro,
        totalVDesc,
        totalVProd,
        totalVNF,
        totalVTotTrib: valorTotTrib,

        listaNFeItemDTO: _listaItem,
        listaNFeNFRefRefNFeDTO: listaRefNFe,
        listaNFeNFRefRefECFDTO: listaRefEcf,
        listaNFeProcRefDTO: listaRefProc,
        listaNFePagDTO: listaFinanceiro,
        listaNFeCobrFatDTO: listaFaturas,
        listaNFeCobrDupDTO: listaDuplicatas,
        listaNFeAutXMLDTO: listaAutXml,
        listaNFeTranspLacreDTO: listaTranspNLacre,
        listaNFeItemDetExportDTO: listaItemDetExport,
        // listaTotaisPorCfopCst,
      };

      // console.log('shippingdata: ', shippingData);

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/nfe/${id}`, shippingData), 'data', {});
        payloadDataTableList.id = id + 1;
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/nfe', shippingData), 'data', {});
      }

      toast.success('NF-e salva com sucesso');
      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      // console.log('err:', err);
      displayErrors(err, dispatch, navigate, '', 'Erro ao salvar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    setShowConfirmCancelarDigitacaoNFe(true);
  };

  const handleTotaisNota = () => {
    if (listaFinanceiro.length <= 0 && propsTipoMovimentacao !== 'entrada' && propsTipoMovimentacao !== '') {
      toast.error('Nenhuma forma de pagamento informada');
      return;
    }

    let somaFinanceiro = 0;
    listaFinanceiro.forEach(element => {
      somaFinanceiro = +somaFinanceiro + +element.vPag;
    });

    if (finNfe === 'NORMAL') {
      if ((somaFinanceiro < (totalVNF - 0.01) || somaFinanceiro > (totalVNF + 0.01)) && tpNf !== 'ENTRADA') {
        toast.info('A soma das formas de pagamento difere do total da Nota');
        return;
      }
    }

    if (cobrFatVLiq > 0 && listaDuplicatas.length === 0) {
      toast.info('Duplicata(s) não informada(s) para a fatura');
      return;
    }

    if (cobrFatVLiq === 0 && listaDuplicatas.length > 0) {
      toast.info('Duplicata(s) sem informação da fatura');
      return;
    }

    let somaDuplicatas = 0;
    listaDuplicatas.forEach(item => {
      somaDuplicatas = +somaDuplicatas + +item.vDup;
    });

    if (+cobrFatVLiq > 0 && listaDuplicatas.length > 0 && +cobrFatVLiq !== somaDuplicatas) {
      toast.info('Total líquido Fatura difere da soma das duplicatas');
      return;
    }

    setShowDadosAdicionais(false);
    setShowFinanceiro(false);
    setShowTotaisNota(true);
  };

  const handleDadosAdicionais = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!dhEmi) {
      formErros = true;
      toast.error('Data de Emissão deve ser informada');
    }
    if (idParticipante <= 0) {
      formErros = true;
      toast.error('Destinatário não selecionado');
    }

    if (natOp === '' || natOp === null) {
      formErros = true;
      toast.error('Natureza da Operação deve ser informada');
    }
    if (mod === '' || mod <= 0) {
      formErros = true;
      toast.error('Modelo da nota fiscal deve ser informado');
    }
    if (serie === '' || serie <= 0) {
      formErros = true;
      toast.error('Série da nota fiscal deve ser informada');
    }
    if (listaItem.length <= 0) {
      formErros = true;
      toast.error('Nenhum produto informado');
    }

    if (formErros) return;

    setShowDadosAdicionais(true);
  };

  const zerarRateioOutrasDespesas = async () => {
    listaItem.forEach(element => {
      element.vOutro = 0;
    });
  };

  const calcularPercOutrasDespesas = () => {
    setTotalVOutro(vOutro);
    return vOutro / valorTotalItens;
  };

  const handleRatearValorOutroItens = async () => {
    if (valorTotalItens <= 0 && !isNaN(valorTotalItens)) {
      toast.warning('Nenhum produto na nota fiscal para rateio de outras despesas acessórias');
      return;
    }

    const percOutro = calcularPercOutrasDespesas();

    if (listaItem.length > 0) {
      if (percOutro === 0) {
        zerarRateioOutrasDespesas();
      } else {
        await zerarRateioOutrasDespesas().then(() => {
          listaItem.forEach(element => {
            const valorOutros = (element.precoVenda * percOutro * element.qCom);
            element.vOutro = valorOutros;
          });
        });
      }
    }
  };

  const handleFinanceiro = (e) => {
    e.preventDefault();

    if (dhSaiEnt != null && dhSaiEnt < dhEmi) {
      toast.info('Data saída menor que Data Emissão informada.');
      return;
    }

    // se finalidade for devolucao verificar se tem doc referenciado
    if (finNfe !== 'NORMAL' && listaRefNFe.length === 0 && listaRefEcf.length === 0 && tpNf !== 'ENTRADA') {
      toast.warn('Nenhum documento referenciado informado para nota de ' + finNfe);
      return;
    }

    if (!disableEdicaoEnviada) {
      handleRatearValorOutroItens().then(() => {
        calcularTotaisNotaFiscal(listaItem);
      });
    } else {
      calcularTotaisNotaFiscalEnviada(listaItem);
    }

    setShowFinanceiro(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    salvarRegistro();
  };

  const onHideConsultaParticipante = () => {
    setShowConsultaParticipante(false);
  };

  const onHideConsultaProduto = () => {
    setShowConsultaProduto(false);
  };

  const onHideConsultaUnidadeMedida = () => {
    setShowConsultaUnidadeMedida(false);
    getUnidadeMedidas(cnpjEmpresa);
  };

  const onHideConsultaNaturezaOperacao = () => {
    setShowConsultaNaturezaOperacao(false);
    getNatOps();
  };

  const onHideConsultaTransportadora = () => {
    setShowConsultaTransportadora(false);
  };

  const onHideConsultaRefNFeEmitente = () => {
    setShowConsultaRefNFeEmitente(false);
  };

  const childToParent = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (empresaCnpj === e.cnpjEmpresa.trim()) {
        setIdParticipante(idElement);

        if (e.razaoSocial) {
          setXNome(e.razaoSocial);
        }
        if (e.uf) {
          setUfParticipante(e.uf);
          ufEmpresa === e.uf ? setIdDest('INTERNA') : setIdDest('INTERESTADUAL');
        }

        setShowConsultaParticipante(false);
      } else {
        toast.warning('Participante selecionado não pertence a empresa emitente da NF-e');
      }
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentNatOp = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.descricao) {
        setNatOp(e.descricao);
      }
      setShowConsultaNaturezaOperacao(false);
      getNatOps();
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const calculaTotTribItem = (valorItem) => {
    let valorTotTrib = 0;

    valorTotTrib += +valorItem * (+ibptAliqMunicipal / 100);
    valorTotTrib += +valorItem * (+ibptAliqEstadual / 100);
    valorTotTrib += +valorItem * (+ibptAliqNacionalFederal / 100);

    if (itemOrig > 0) {
      valorTotTrib += +valorItem * (+ibptAliqImportadosFederal / 100);
    }

    return arredondar(valorTotTrib, -2);
  };

  const getIbpt = async (itemNcm) => {
    try {
      setIsLoading(true);

      const { data: _ibpt } = await axiosAdmin.get(`/ibpt/?ncm=${itemNcm}`);

      _ibpt.forEach(element => {
        setIbptAliqEstadual(element.estadual);
        setIbptAliqMunicipal(element.municipal);
        setIbptAliqNacionalFederal(element.nacionalFederal);
        setIbptAliqImportadosFederal(element.importadosFederal);
      });
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (itemNcm.trim() !== '' && itemNcm !== null && itemNcm.length === 8) {
      getIbpt(itemNcm);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemNcm]);

  const childToParentConsultaProduto = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (empresaCnpj === e.cnpjEmpresa.trim()) {
        if (e.id) {
          // console.log('e child to produto: ', e);
          setItemUCom(e.unidadeMedida.sigla);
          setItemPrecoBruto(e.precoVenda);

          let cfopProduto = '';
          if (idDest === 'INTERNA') { cfopProduto = '5' + e.cfopVenda; }
          if (idDest === 'INTERESTADUAL') { cfopProduto = '6' + e.cfopVenda; }

          setItemCfop(cfopProduto);
          setItemCst(e.cstVenda);
          setItemCsosn(e.csosnVenda);

          setItemIdProduto(e.id);
          setItemRegistro(e.registro);
          setItemDescricaoProduto(e.descricao);

          if (tpNf === 'SAIDA') {
            setItemPisCst(e.pisCofinsSaidaCst);
            setItemPisPPis(e.pisSaidaAliquota);
            setItemCofinsCst(e.pisCofinsSaidaCst);
            setItemCofinsPCofins(e.cofinsSaidaAliquota);
            setItemIpiCst(e.ipiSaidaCst);
            setItemIpiPIpi(e.ipiSaidaAliquota);
          } else {
            setItemPisCst(e.pisCofinsEntradaCst);
            setItemPisPPis(e.pisEntradaAliquota);
            setItemCofinsCst(e.pisCofinsEntradaCst);
            setItemCofinsPCofins(e.cofinsEntradaAliquota);
            setItemIpiCst(e.ipiEntradaCst);
            setItemIpiPIpi(e.ipiEntradaAliquota);
          }

          setItemOrig(e.origemMercadoria.toString());

          setItemPIcms(e.icmsAliquota);
          setItemModBc(e.icmsModalidadeBc.toString());
          setItemPRedBc(e.icmsPRedBc);

          setItemPIcmsSt(e.icmsAliquotaSt);
          setItemModBcST(e.icmsModalidadeBcSt.toString());
          setItemPRedBcSt(e.icmsPRedBcSt);
          setItemPMvaSt(e.mvaSt);

          setItemPFcp(e.icmsAliquotaFcp);
          setItemPMvaSt(e.mvaSt);

          setItemNaturezaReceita(e.naturezaReceita);

          setItemNcm(e.ncm);
          setItemCest(e.cest);
          setItemCta(tpNf === 'ENTRADA' ? e.ctaEntrada : e.ctaSaida);
        }
        setShowConsultaProduto(false);
      } else {
        toast.warn('Produto selecionado não pertence a empresa emitente da NF-e');
      }
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentConsultaUnidadeMedida = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setItemUCom(e.sigla);
      }
      setShowConsultaUnidadeMedida(false);
      getUnidadeMedidas(cnpjEmpresa);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentTransportadora = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdParticipanteTransporta(e.id);
      }
      if (e.razaoSocial) {
        setRazaoSocialTransporta(e.razaoSocial);
      }
      setShowConsultaTransportadora(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentRefNFeEmitente = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setRefNFeIdEmitente(e.id);
      }
      if (e.razaoSocial) {
        setRazaoSocialRefNFeEmitente(e.razaoSocial);
      }
      setShowConsultaRefNFeEmitente(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const cancelDeleteItem = () => {
    setShowConfirmDeleteItem(false);
  };

  const cancelDeleteItemRastro = () => {
    setShowConfirmDeleteItemRastro(false);
  };

  const cancelDeleteItemDi = () => {
    setShowConfirmDeleteItemDi(false);
  };

  const cancelDeleteItemAdi = () => {
    setShowConfirmDeleteItemAdi(false);
  };

  const cancelDeleteItemDetExport = () => {
    setShowConfirmDeleteItemDetExport(false);
  };

  const cancelDeleteRefNFe = () => {
    setShowConfirmDeleteRefNFe(false);
  };

  const cancelDeleteRefEcf = () => {
    setShowConfirmDeleteRefEcf(false);
  };

  const cancelDeleteRefProc = () => {
    setShowConfirmDeleteRefProc(false);
  };

  const cancelTotaisNota = () => {
    setShowTotaisNota(false);
  };

  const cancelDadosAdicionais = () => {
    setShowDadosAdicionais(false);
  };

  const cancelFinanceiro = () => {
    setShowFinanceiro(false);
  };

  const cancelDeleteFinanceiro = () => {
    setShowConfirmDeleteFinanceiro(false);
  };

  const cancelDeleteDuplicata = () => {
    setShowConfirmDeleteDuplicata(false);
  };

  const cancelDeleteAutXml = () => {
    setShowConfirmDeleteAutXml(false);
  };

  const cancelEdicaoEnviada = () => {
    childToParentConsulta();
  };

  const cancelDeleteTranspNLacre = () => {
    setShowConfirmDeleteTranspNLacre(false);
  };

  const cancelCancelarDigitacaoNFe = () => {
    setShowConfirmCancelarDigitacaoNFe(false);
  };

  const confirmDeleteRefNFe = () => {
    const item = get(selectedRefNFes, 'item', 0);

    if (item > 0) {
      const _listaRefNFe = listaRefNFe;

      const index = _listaRefNFe.indexOf(_listaRefNFe.find(element => element.item === item));

      if (index >= 0) {
        _listaRefNFe.splice(index, 1);

        _listaRefNFe.forEach(element => {
          if (element.item > item) {
            const _index = _listaRefNFe.indexOf(element);

            _listaRefNFe[_index].item = _listaRefNFe[_index].item - 1;
          }
        });

        setListaRefNFe(_listaRefNFe);

        setShowConfirmDeleteRefNFe(false);
      }
    }
  };

  const confirmDeleteFinanceiro = () => {
    const item = get(selectedFinanceiros, 'item', 0);

    if (item > 0) {
      const _listaFinanceiro = listaFinanceiro;

      const index = _listaFinanceiro.indexOf(_listaFinanceiro.find(element => element.item === item));

      if (index >= 0) {
        _listaFinanceiro.splice(index, 1);

        _listaFinanceiro.forEach(element => {
          if (element.item > item) {
            const _index = _listaFinanceiro.indexOf(element);

            _listaFinanceiro[_index].item = _listaFinanceiro[_index].item - 1;
          }
        });

        setListaFinanceiro(_listaFinanceiro);

        setShowConfirmDeleteFinanceiro(false);
      }
    }
  };

  const confirmDeleteDuplicata = () => {
    const item = get(selectedDuplicatas, 'item', 0);

    if (item > 0) {
      const _listaDuplicatas = listaDuplicatas;

      const index = _listaDuplicatas.indexOf(_listaDuplicatas.find(element => element.item === item));

      if (index >= 0) {
        _listaDuplicatas.splice(index, 1);

        _listaDuplicatas.forEach(element => {
          if (element.item > item) {
            const _index = _listaDuplicatas.indexOf(element);

            _listaDuplicatas[_index].item = _listaDuplicatas[_index].item - 1;
          }
        });

        setListaDuplicatas(_listaDuplicatas);

        setShowConfirmDeleteDuplicata(false);
      }
    }
  };

  const confirmEdicaoEnviada = () => {
    setShowConfirmEdicaoEnviada(false);
  };

  const confirmDeleteAutXml = () => {
    const item = get(selectedAutXml, 'item', 0);

    if (item > 0) {
      const _listaAutXml = listaAutXml;

      const index = _listaAutXml.indexOf(_listaAutXml.find(element => element.item === item));

      if (index >= 0) {
        _listaAutXml.splice(index, 1);

        _listaAutXml.forEach(element => {
          if (element.item > item) {
            const _index = _listaAutXml.indexOf(element);

            _listaAutXml[_index].item = _listaAutXml[_index].item - 1;
          }
        });

        setListaAutXml(_listaAutXml);

        setShowConfirmDeleteAutXml(false);
      }
    }
  };

  const confirmCancelarDigitacaoNFe = () => {
    childToParentConsulta();
  };

  const confirmDeleteTranspNLacre = () => {
    const item = get(selectedTranspNLacre, 'item', 0);

    if (item > 0) {
      const _listaTranspNLacre = listaTranspNLacre;

      const index = _listaTranspNLacre.indexOf(_listaTranspNLacre.find(element => element.item === item));

      if (index >= 0) {
        _listaTranspNLacre.splice(index, 1);

        _listaTranspNLacre.forEach(element => {
          if (element.item > item) {
            const _index = _listaTranspNLacre.indexOf(element);

            _listaTranspNLacre[_index].item = _listaTranspNLacre[_index].item - 1;
          }
        });

        setListaTranspNLacre(_listaTranspNLacre);

        setShowConfirmDeleteTranspNLacre(false);
      }
    }
  };

  const confirmDeleteItem = () => {
    const item = get(selectedItens, 'item', 0);

    if (item > 0) {
      const _listaItem = listaItem;

      const { produto: _produto, totalItem: _valorEstorno } = _listaItem.find(element => element.item === item);

      const index = _listaItem.indexOf(_listaItem.find(element => element.item === item));

      if (index >= 0) {
        _listaItem.splice(index, 1);

        _listaItem.forEach(element => {
          if (element.item > item) {
            const _index = _listaItem.indexOf(element);

            _listaItem[_index].item = _listaItem[_index].item - 1;
          }
        });

        if (_valorEstorno && _valorEstorno > 0) {
          setValorTotalItens(valorTotalItens - _valorEstorno);
          setTotalVProd(valorTotalItens - _valorEstorno);
        }

        setListaItem(_listaItem);

        setItemIdProduto(_produto);
        setShowConfirmDeleteItem(false);
      }
    }
  };

  const handleDeleteFinanceiro = (e) => {
    e.preventDefault();

    const item = get(selectedFinanceiros, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaFinanceiro.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteFinanceiro(true);
    } else {
      toast.warning('Por favor, selecione o Financeiro para exclusão');
    }
  };

  const handleDeleteDuplicata = (e) => {
    e.preventDefault();

    const item = get(selectedDuplicatas, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaDuplicatas.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione a duplicata para exclusão');
        return;
      }

      setShowConfirmDeleteDuplicata(true);
    } else {
      toast.warning('Por favor, selecione o Financeiro para exclusão');
    }
  };

  const handleDeleteAutXml = (e) => {
    e.preventDefault();

    const item = get(selectedAutXml, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaAutXml.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteAutXml(true);
    } else {
      toast.warning('Por favor, selecione o CNPJ/CPF autorizado para exclusão');
    }
  };

  const handleDeleteTranspNLacre = (e) => {
    e.preventDefault();

    const item = get(selectedTranspNLacre, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaTranspNLacre.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o lacre para exclusão');
        return;
      }

      setShowConfirmDeleteTranspNLacre(true);
    } else {
      toast.warning('Por favor, selecione o lacre para exclusão');
    }
  };

  const zerarRateioDesconto = async () => {
    listaItem.forEach(element => {
      element.vDesc = 0;
      element.precoVenda = element.precoBruto;
      element.vUnCom = element.precoBruto;
      element.totalItem = +element.precoBruto * +element.qCom;
      element.vProd = +element.precoBruto * +element.qCom;
    });
    setListaItem(listaItem);
  };

  async function calcularPercDesconto() {
    setTotalVDesc(valorDescontoRatear);
    return valorDescontoRatear / valorTotalItens;
  }

  const calcularTributos = (_data) => {
    if (_data.cfop === '' || _data.cfop === null) {
      toast.info('CFOP não selecionado');
      return;
    }

    if (_data.cst === '' || _data.cst === null) {
      toast.info('CST não selecionado');
      return;
    }

    const cfop = (_data.cfop.label && _data.cfop.label.length === 4) ? _data.cfop.label.substring(1, 4) : _data.cfop.substring(1, 4);

    if (cfop === '152') {
      _data.pIcms = 0;
      _data.pIcmsSt = 0;
    }

    if (empresaRegimeTributario === 'SIMPLESNACIONAL' && finNfe === 'NORMAL') {
      _data.vBc = 0;
      _data.pRedBc = 0;
      _data.pIcms = 0;
    }

    _data.vBc = 0;

    if (_data.pIcms <= 0) {
      _data.pRedBc = 0;
    }

    if (_data.pIcms > 0) {
      if (_data.pRedBc > 0) {
        _data.vBc = _data.totalItem - arredondar(_data.totalItem * (_data.pRedBc / 100), -2);
      } else {
        _data.vBc = _data.totalItem;
      }

      _data.vIcms = _data.vBc * (_data.pIcms / 100);
    }

    if (_data.pFcp > 0) {
      _data.vBcFcp = _data.totalItem;
      _data.vFcp = arredondar(_data.totalItem * (_data.pFcp / 100), -2);
    }

    let cstTributado = 'N';
    let calcularSubstituicao = 'N';
    let cstIsenta = 'N';
    let cstOutras = 'N';

    const cst = _data.cst.label ? _data.cst.label.substring(0, 2) : _data.cst;

    ['00', '10', '20'].includes(cst) ? cstTributado = 'S' : '';
    ['10', '30', '70'].includes(cst) ? calcularSubstituicao = 'S' : '';
    ['20', '30', '40', '41'].includes(cst) ? cstIsenta = 'S' : '';
    ['50', '51', '60', '70', '90'].includes(cst) ? cstOutras = 'S' : '';

    let baseSubstituicao = 0;
    let valorSubstituicao = 0;

    if (calcularSubstituicao === 'S') {
      if (ufEmpresa === UfParticipante) {
        if (_data.pRedBcSt > 0) {
          _data.vBcSt = _data.totalItem - arredondar(_data.totalItem * (_data.pRedBcSt / 100), -2);
        } else {
          _data.vBcSt = _data.totalItem;
        }

        if (_data.pIcmsSt <= 0) {
          _data.vBcSt = 0;
          // baseSubstituicao = 0; // antes teste cst 70 usava assim
        }

        _data.vIcmsSt = arredondar(_data.vBcSt * (_data.pIcmsSt / 100), -2);

        baseSubstituicao = arredondar((_data.vBcSt + (_data.totalItem * (_data.ipiPIpi / 100))) * (1 + _data.pMvaSt / 100), -2);
        valorSubstituicao = arredondar(baseSubstituicao * (_data.pIcmsSt / 100), -2);

        if (valorSubstituicao < 0) {
          valorSubstituicao = 0;
        }
      } else {
        if (_data.pRedBcSt > 0) {
          _data.vBcSt = _data.totalItem - arredondar(_data.totalItem * (_data.pRedBcSt / 100), -2);
        } else {
          _data.vBcSt = _data.totalItem;
        }

        if (_data.pIcmsSt <= 0) {
          _data.vBcSt = 0;
        }

        _data.vIcmsSt = arredondar(_data.vBcSt * (_data.pIcmsSt / 100), -2);

        baseSubstituicao = arredondar((_data.vBcSt + (_data.totalItem * (_data.ipiPIpi / 100))) * (1 + _data.pMvaSt / 100), -2);
        valorSubstituicao = arredondar(baseSubstituicao * (_data.pIcmsSt / 100) - _data.vIcmsSt, -2);
        if (valorSubstituicao < 0) {
          valorSubstituicao = 0;
        }
      }
    }

    _data.vBcSt = baseSubstituicao;
    _data.vIcmsSt = valorSubstituicao;

    (_data.pIcms <= 0 && cstIsenta === 'S') ? _data.vICMSIsento = _data.totalItem : 0;

    let valorICMSIsento = 0;
    let valorICMSOutro = 0;
    // let valorOutras = 0;

    if (_data.pIcms > 0 && _data.pRedBc > 0) {
      valorICMSIsento = _data.totalItem - _data.vBc - _data.ipiVIpi;
    }
    if (_data.pIcms <= 0) {
      if (cstIsenta === 'S') {
        valorICMSIsento = _data.totalItem;
      }
    }

    _data.vICMSIsento = valorICMSIsento > 0 ? valorICMSIsento : 0;

    if (_data.pIcms > 0 && _data.vIcmsSt <= 0) {
      valorICMSOutro = arredondar((_data.totalItem - _data.vBc) + _data.vIcmsSt + _data.ipiVIpi - _data.vICMSIsento, -2);
    } else {
      valorICMSOutro = arredondar(_data.totalItem + _data.vIcmsSt + _data.ipiVIpi - _data.vICMSIsento, -2);
    }

    _data.vICMSOutro = valorICMSOutro > 0 ? valorICMSOutro : 0;

    _data.pisVPis = 0;
    _data.pisVBc = 0;
    const pisCst = _data.pisCst.label ? _data.pisCst.label.substring(0, 2) : _data.pisCst;
    if (pisCst === '01') {
      if (_data.pisPPis > 0) {
        _data.pisVBc = _data.totalItem;
        const pisValor = arredondar(_data.totalItem * (_data.pisPPis / 100), -2);
        _data.pisVPis = pisValor > 0 ? pisValor : 0;
      }
    }

    _data.cofinsVCofins = 0;
    _data.cofinsVBc = 0;
    const cofinsCst = _data.cofinsCst.label ? _data.cofinsCst.label.substring(0, 2) : _data.cofinsCst;
    if (_data.cofinsPCofins > 0) {
      _data.cofinsVBc = _data.totalItem;
      const cofinsValor = arredondar(_data.totalItem * (_data.cofinsPCofins / 100), -2);
      _data.cofinsVCofins = cofinsValor > 0 ? cofinsValor : 0;
    }

    // UsuarioCalcularIPInaEmissaoDeNfe vem do cadastro da empresa
    const UsuarioCalcularIPInaEmissaoDeNfe = 'S';
    if (UsuarioCalcularIPInaEmissaoDeNfe === 'S') {
      if (_data.ipiPIpi > 0) {
        _data.ipiVBc = _data.totalFinal;
        const ipiValor = arredondar(_data.totalFinal * (_data.ipiPIpi / 100), -2);
        _data.ipiVIpi = ipiValor > 0 ? ipiValor : 0;
      } else {
        _data.ipiVBc = 0;
        _data.ipiVIpi = 0;
        _data.ipiPIpi = 0;
      }
    }

    _data.vCredIcmsSn = 0;
    if (_data.pCredSn > 0) {
      const valorCredito = arredondar(_data.totalItem * (_data.pCredSn / 100), -2);
      _data.vCredIcmsSn = valorCredito > 0 ? valorCredito : 0;
    }

    _data.totalFinal = _data.totalItem;

    // eslint-disable-next-line consistent-return
    return _data;
  };

  const calcularTributosRateioDesconto = (listaItem) => {
    listaItem.forEach(element => {
      calcularTributos(element);
    });
  };

  const handleRatearDescontoItens = async () => {
    if (valorTotalItens <= 0 && !isNaN(valorTotalItens)) {
      toast.warning('Nenhum produto na nota fiscal para rateio do desconto');
      return;
    }
    if (valorDescontoRatear < 0 || valorDescontoRatear >= valorTotalItens) {
      toast.warning('Valor do rateio não permitido');
      setValorDescontoRatear(0);
      return;
    }

    await calcularPercDesconto().then(async (percDesc) => {
      if (listaItem.length > 0) {
        if (percDesc === 0 || isNaN(percDesc)) {
          await zerarRateioDesconto().then(() => {
            let _valorTotalItens = 0;
            listaItem.forEach(element => {
              _valorTotalItens = _valorTotalItens + element.totalItem;
            });

            setValorTotalItens(_valorTotalItens);
            setTotalVProd(_valorTotalItens);
            calcularTributosRateioDesconto(listaItem);
          });
        } else {
          await zerarRateioDesconto().then(() => {
            let _valorTotalItens = 0;
            listaItem.forEach(element => {
              const valorDescontado = (+element.precoBruto * +percDesc);
              const precoVenda = +element.precoBruto - +valorDescontado;
              element.vDesc = valorDescontado;
              element.precoVenda = precoVenda;
              element.vUnCom = +element.precoBruto;
              element.vProd = +element.qCom * +element.precoBruto;
              element.totalItem = precoVenda * element.qCom;
              _valorTotalItens = _valorTotalItens + (precoVenda * element.qCom);
            });

            setValorTotalItens(_valorTotalItens);
            setTotalVProd(_valorTotalItens);
            calcularTributosRateioDesconto(listaItem);
          });
        }
      } else {
        toast.info('Não há produtos na nota fiscal');
      }
    });
  };

  const handleDeleteItem = (e) => {
    e.preventDefault();

    const item = get(selectedItens, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItem.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteItem(true);
    } else {
      toast.warning('Por favor, selecione o item para exclusão');
    }
  };

  const confirmDeleteItemRastro = () => {
    const item = get(selectedItemRastros, 'item', 0);

    if (item > 0) {
      const _listaItemRastro = listaItemRastro;

      const index = _listaItemRastro.indexOf(_listaItemRastro.find(element => element.item === item));

      if (index >= 0) {
        _listaItemRastro.splice(index, 1);

        _listaItemRastro.forEach(element => {
          if (element.item > item) {
            const _index = _listaItemRastro.indexOf(element);

            _listaItemRastro[_index].item = _listaItemRastro[_index].item - 1;
          }
        });

        setListaItemRastro(_listaItemRastro);

        setShowConfirmDeleteItemRastro(false);
      }
    }
  };

  const confirmDeleteItemDi = () => {
    const item = get(selectedItemDis, 'item', 0);

    if (item > 0) {
      const _listaItemDi = listaItemDi;

      const index = _listaItemDi.indexOf(_listaItemDi.find(element => element.item === item));

      if (index >= 0) {
        _listaItemDi.splice(index, 1);

        _listaItemDi.forEach(element => {
          if (element.item > item) {
            const _index = _listaItemDi.indexOf(element);

            _listaItemDi[_index].item = _listaItemDi[_index].item - 1;
          }
        });

        setListaItemDi(_listaItemDi);

        setShowConfirmDeleteItemDi(false);
      }
    }
  };

  const confirmDeleteItemAdi = () => {
    const item = get(selectedItemAdi, 'item', 0);

    if (item > 0) {
      const _listaItemAdi = listaItemAdi;

      const index = _listaItemAdi.indexOf(_listaItemAdi.find(element => element.item === item));

      if (index >= 0) {
        _listaItemAdi.splice(index, 1);

        _listaItemAdi.forEach(element => {
          if (element.item > item) {
            const _index = _listaItemAdi.indexOf(element);

            _listaItemAdi[_index].item = _listaItemAdi[_index].item - 1;
          }
        });

        setListaItemAdi(_listaItemAdi);

        setShowConfirmDeleteItemAdi(false);
      }
    }
  };

  const confirmDeleteItemDetExport = () => {
    const item = get(selectedItemDetExport, 'item', 0);

    if (item > 0) {
      const _listaItemDetExport = listaItemDetExport;

      const index = _listaItemDetExport.indexOf(_listaItemDetExport.find(element => element.item === item));

      if (index >= 0) {
        _listaItemDetExport.splice(index, 1);

        _listaItemDetExport.forEach(element => {
          if (element.item > item) {
            const _index = _listaItemDetExport.indexOf(element);

            _listaItemDetExport[_index].item = _listaItemDetExport[_index].item - 1;
          }
        });

        setListaItemDetExport(_listaItemDetExport);

        setShowConfirmDeleteItemDetExport(false);
      }
    }
  };

  const handleDeleteItemRastro = (e) => {
    e.preventDefault();

    const item = get(selectedItemRastros, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItemRastro.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteItemRastro(true);
    } else {
      toast.warning('Por favor, selecione o item para exclusão');
    }
  };

  const handleDeleteItemDi = (e) => {
    e.preventDefault();

    const item = get(selectedItemDis, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItemDi.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteItemDi(true);
    } else {
      toast.warning('Por favor, selecione o item para exclusão');
    }
  };

  const handleDeleteItemAdi = (e) => {
    e.preventDefault();

    const item = get(selectedItemAdi, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItemAdi.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteItemAdi(true);
    } else {
      toast.warning('Por favor, selecione o item para exclusão');
    }
  };

  const handleDeleteItemDetExport = (e) => {
    e.preventDefault();

    const item = get(selectedItemDetExport, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItemDetExport.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para exclusão');
        return;
      }

      setShowConfirmDeleteItemDetExport(true);
    } else {
      toast.warning('Por favor, selecione o item para exclusão');
    }
  };

  const handleDeleteRefNFe = (e) => {
    e.preventDefault();

    const item = get(selectedRefNFes, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaRefNFe.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o Documento Referenciado para exclusão');
        return;
      }

      setShowConfirmDeleteRefNFe(true);
    } else {
      toast.warning('Por favor, selecione o Documento Referenciado para exclusão');
    }
  };

  const handleEditFinanceiro = (e) => {
    e.preventDefault();

    const item = get(selectedFinanceiros, 'item', 0);

    if (item > 0) {
      const _financeiroSelecionado = listaFinanceiro.find(element => element.item === item);

      if (!_financeiroSelecionado) {
        toast.warning('Por favor, selecione o Financeiro para edição');
        return;
      }

      setEditandoFinanceiro(item);

      limparCamposFinanceiro();

      setPagTPag(_financeiroSelecionado.tPag);
      setPagDVenc(moment(_financeiroSelecionado.dVenc, 'DD-MM-YYYY').toDate());
      setPagXPag(_financeiroSelecionado.xPag);
      setPagVPag(_financeiroSelecionado.vPag);

      setShowCadastroFinanceiro(true);
    }
  };

  const handleEditDuplicata = (e) => {
    e.preventDefault();

    const item = get(selectedDuplicatas, 'item', 0);

    if (item > 0) {
      const _duplicataSelecionada = listaDuplicatas.find(element => element.item === item);

      if (!_duplicataSelecionada) {
        toast.warning('Por favor, selecione a Duplicata para edição');
        return;
      }

      setEditandoDuplicata(item);

      limparCamposDuplicata(true);

      setCobrDupDVenc(moment(_duplicataSelecionada.dVenc, 'DD-MM-YYYY').toDate());
      setCobrDupVDup(_duplicataSelecionada.vDup);
      setCobrDupCondicaoPagamento(_duplicataSelecionada.cobrDupCondicaoPagamento);

      setShowCadastroDuplicata(true);
    }
  };

  const handleEditAutXml = (e) => {
    e.preventDefault();

    const item = get(selectedAutXml, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaAutXml.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o CNPJ/CPF autorizado para edição');
        return;
      }

      setEditandoAutXml(item);

      limparCamposAutXml();

      setAutXmlCnpj(_itemSelecionado.cnpj);
      setAutXmlCpf(_itemSelecionado.cpf);

      setShowCadastroAutXml(true);
    }
  };

  const handleEditTranspNLacre = (e) => {
    e.preventDefault();

    const item = get(selectedTranspNLacre, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaTranspNLacre.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o lacre para edição');
        return;
      }

      setEditandoTranspNLacre(item);

      limparCamposTranspNLacre(true);

      setTranspNLacre(_itemSelecionado.nLacre);

      setShowCadastroTranspNLacre(true);
    }
  };

  const handleEditRefNFe = (e) => {
    e.preventDefault();

    const item = get(selectedRefNFes, 'item', 0);

    if (item > 0) {
      const _refNFeSelecionado = listaRefNFe.find(element => element.item === item);

      if (!_refNFeSelecionado) {
        toast.warning('Por favor, selecione o Doc. Referenciado para edição');
        return;
      }

      setEditandoRefNFe(item);

      limparCamposRefNFe();

      setRefNFeAAMM(moment(_refNFeSelecionado.aamm, 'DD-MM-YYYY').toDate());
      setRefNFeIdEmitente(_refNFeSelecionado.idEmitente);
      setRazaoSocialRefNFeEmitente(_refNFeSelecionado.razaoSocial);
      setRefNFeTipoOperacao(_refNFeSelecionado.tipoOperacao);
      setRefNFe(_refNFeSelecionado.refNFe);
      setRefNFeMod(_refNFeSelecionado.mod);
      setRefNFeSerie(_refNFeSelecionado.serie);
      setRefNFeNNF(_refNFeSelecionado.nnf);

      setShowCadastroRefNFe(true);
    }
  };

  const handleEditRefProc = (e) => {
    e.preventDefault();

    const item = get(selectedRefProc, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaRefProc.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o Processo Referenciado para edição');
        return;
      }

      setEditandoRefProc(item);

      limparCamposRefProc();

      setRefProcNProc(_itemSelecionado.nProc);
      setRefProcIndProc(_itemSelecionado.indProc);
      setRefProcTpAto(_itemSelecionado.tpAto);

      setShowCadastroRefProc(true);
    }
  };

  const handleOkRefNFe = () => {
    if (refNFeAAMM == null) {
      toast.warning('Data do Doc. Referenciado deve ser informada');
      return;
    }
    if (refNFeIdEmitente <= 0) {
      toast.warning('Emitente do Doc. Referenciado deve ser informado');
      return;
    }
    if (refNFe === '') {
      toast.warning('Chave de Acesso deve ser informada');
      return;
    }
    if (refNFe.length < 44) {
      toast.warning('Chave de Acesso deve conter 44 caracteres');
      return;
    }
    if (refNFeMod === '') {
      toast.warning('Modelo do Doc. Referenciado deve ser informado');
      return;
    }
    if (refNFeMod.length < 2) {
      toast.warning('Modelo do Doc. Referenciado deve conter dois dígitos');
      return;
    }
    if (refNFeSerie === '') {
      toast.warning('Serie do Doc. Referenciado deve ser informada');
      return;
    }
    if (refNFeNNF === '') {
      toast.warning('Número do Doc. Referenciado deve ser informada');
      return;
    }

    const _listaRefNFe = listaRefNFe;

    let item = 0;
    listaRefNFe.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoRefNFe && editandoRefNFe > 0) {
      item = editandoRefNFe;

      const index = _listaRefNFe.indexOf(_listaRefNFe.find(element => element.item === editandoRefNFe));

      if (index >= 0) {
        _listaRefNFe.splice(index, 1);
      }
    }

    _listaRefNFe.push({
      item,
      idEmitente: refNFeIdEmitente,
      razaoSocial: razaoSocialRefNFeEmitente,
      refNFe,
      mod: refNFeMod,
      serie: refNFeSerie,
      nnf: refNFeNNF,
      aamm: formataDataMoment(refNFeAAMM, '-'),
      tipoOperacao: refNFeTipoOperacao,
    });

    setListaRefNFe(_listaRefNFe);

    limparCamposRefNFe();
    setShowCadastroRefNFe(false);
  };

  const confirmDeleteRefEcf = () => {
    const item = get(selectedRefEcfs, 'item', 0);

    if (item > 0) {
      const _listaRefEcf = listaRefEcf;

      const index = _listaRefEcf.indexOf(_listaRefEcf.find(element => element.item === item));

      if (index >= 0) {
        _listaRefEcf.splice(index, 1);

        _listaRefEcf.forEach(element => {
          if (element.item > item) {
            const _index = _listaRefEcf.indexOf(element);

            _listaRefEcf[_index].item = _listaRefEcf[_index].item - 1;
          }
        });

        setListaRefEcf(_listaRefEcf);

        setShowConfirmDeleteRefEcf(false);
      }
    }
  };

  const confirmDeleteRefProc = () => {
    const item = get(selectedRefProc, 'item', 0);

    if (item > 0) {
      const _listaRefProc = listaRefProc;

      const index = _listaRefProc.indexOf(_listaRefProc.find(element => element.item === item));

      if (index >= 0) {
        _listaRefProc.splice(index, 1);

        _listaRefProc.forEach(element => {
          if (element.item > item) {
            const _index = _listaRefProc.indexOf(element);

            _listaRefProc[_index].item = _listaRefProc[_index].item - 1;
          }
        });

        setListaRefProc(_listaRefProc);

        setShowConfirmDeleteRefProc(false);
      }
    }
  };

  const handleDeleteRefEcf = (e) => {
    e.preventDefault();

    const item = get(selectedRefEcfs, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaRefEcf.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o ECF Referenciado para exclusão');
        return;
      }

      setShowConfirmDeleteRefEcf(true);
    } else {
      toast.warning('Por favor, selecione o ECF Referenciado para exclusão');
    }
  };

  const handleDeleteRefProc = (e) => {
    e.preventDefault();

    const item = get(selectedRefProc, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaRefProc.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o Processo Referenciado para exclusão');
        return;
      }

      setShowConfirmDeleteRefProc(true);
    } else {
      toast.warning('Por favor, selecione o Processo Referenciado para exclusão');
    }
  };

  const handleEditRefEcf = (e) => {
    e.preventDefault();

    const item = get(selectedRefEcfs, 'item', 0);

    if (item > 0) {
      const _refEcfSelecionado = listaRefEcf.find(element => element.item === item);

      if (!_refEcfSelecionado) {
        toast.warning('Por favor, selecione o ECF Referenciado para edição');
        return;
      }

      setEditandoRefEcf(item);

      limparCamposRefEcf();

      setRefEcfMod(_refEcfSelecionado.mod);
      setRefEcfNEcf(_refEcfSelecionado.nEcf);
      setRefEcfNCoo(_refEcfSelecionado.nCoo);

      setShowCadastroRefEcf(true);
    }
  };

  const handleOkRefEcf = () => {
    if (refEcfMod === '') {
      toast.warning('Modelo do ECF deve ser informado');
      return;
    }
    if (refEcfMod.length < 2) {
      toast.warning('Modelo do ECF deve conter 2 dígitos');
      return;
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(refEcfNEcf)) {
      toast.warning('Número do ECF inválido');
      return;
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(refEcfNCoo)) {
      toast.warning('Número do COO inválido');
      return;
    }

    const _listaRefEcf = listaRefEcf;

    let item = 0;
    listaRefEcf.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoRefEcf && editandoRefEcf > 0) {
      item = editandoRefEcf;

      const index = _listaRefEcf.indexOf(_listaRefEcf.find(element => element.item === editandoRefEcf));

      if (index >= 0) {
        _listaRefEcf.splice(index, 1);
      }
    }

    _listaRefEcf.push({
      item,
      mod: refEcfMod,
      nEcf: refEcfNEcf,
      nCoo: refEcfNCoo,
    });

    limparCamposRefEcf();
    setShowCadastroRefEcf(false);
  };

  const handleOkRefProc = () => {
    if (refProcNProc === '') {
      toast.warning('Número do Processo deve ser informado');
      return;
    }
    if (refProcIndProc === '') {
      toast.warning('Indicador Origem deve ser informado');
      return;
    }

    const _listaRefProc = listaRefProc;

    let item = 0;
    listaRefProc.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoRefProc && editandoRefProc > 0) {
      item = editandoRefProc;

      const index = _listaRefProc.indexOf(_listaRefProc.find(element => element.item === editandoRefProc));

      if (index >= 0) {
        _listaRefProc.splice(index, 1);
      }
    }

    _listaRefProc.push({
      item,
      nProc: refProcNProc,
      indProc: refProcIndProc,
      tpAto: refProcTpAto,
    });

    limparCamposRefProc();
    setShowCadastroRefProc(false);
  };

  const handleEditItemRastro = (e) => {
    e.preventDefault();

    const item = get(selectedItemRastros, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItemRastro.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para edição');
        return;
      }

      setEditandoItemRastro(item);

      setItemRastroNLote(_itemSelecionado.nLote);
      setItemRastroQLote(_itemSelecionado.qLote);
      setItemRastroDFab(moment(_itemSelecionado.dFab, 'DD-MM-YYYY').toDate());
      setItemRastroDVal(moment(_itemSelecionado.dVal, 'DD-MM-YYYY').toDate());
      setItemRastroCAgreg(_itemSelecionado.cAgreg);

      setShowCadastroItemRastro(true);
    }
  };

  const handleEditItemDi = (e) => {
    e.preventDefault();

    const item = get(selectedItemDis, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItemDi.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para edição');
        return;
      }

      setEditandoItemDi(item);

      setItemDiNDi(_itemSelecionado.nDi);
      setItemDiDDi(moment(_itemSelecionado.dDi, 'DD-MM-YYYY').toDate());
      setItemDiXLocDesemb(_itemSelecionado.xLocDesemb);
      setItemDiUfDesemb(_itemSelecionado.ufDesemb);
      setItemDiDDesemb(moment(_itemSelecionado.dDesemb, 'DD-MM-YYYY').toDate());
      setItemDiTpIntermedio(_itemSelecionado.tpIntermedio);
      setItemDiCnpj(_itemSelecionado.cnpj);
      setItemDiTpViaTransp(_itemSelecionado.tpViaTransp);
      setItemDiUfTerceiro(_itemSelecionado.ufTerceiro);
      setItemDiCExportador(_itemSelecionado.cExportador);
      setItemDiVAfrmm(_itemSelecionado.vAfrmm);

      setListaItemAdi(_itemSelecionado.listaNFeItemADIDTO ? _itemSelecionado.listaNFeItemADIDTO : _itemSelecionado.listaItemAdi);

      setShowCadastroItemDi(true);
    }
  };

  const handleEditItemAdi = (e) => {
    e.preventDefault();

    const item = get(selectedItemAdi, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItemAdi.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para edição');
        return;
      }

      setEditandoItemAdi(item);

      setItemAdiNAdicao(_itemSelecionado.nAdicao);
      setItemAdiNSeqAdic(_itemSelecionado.nSeqAdic);
      setItemAdiCFabricante(_itemSelecionado.cFabricante);
      setItemAdiVDescDi(_itemSelecionado.vDescDi);
      setItemAdiNDraw(_itemSelecionado.nDraw);
      setShowCadastroItemAdi(true);
    }
  };

  const handleEditItemDetExport = (e) => {
    e.preventDefault();

    const item = get(selectedItemDetExport, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItemDetExport.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para edição');
        return;
      }

      setEditandoItemDetExport(item);

      setItemDetExportNDraw(_itemSelecionado.nDraw);

      setShowCadastroItemDetExport(true);
    }
  };

  const handleOkItemDi = () => {
    if (itemDiNDi <= 0) {
      toast.warning('Número do Doc. de Importação deve ser informado.');
      return;
    }
    if (itemDiDDi === null) {
      toast.warning('Data do Doc. de Importação deve ser informada.');
      return;
    }
    if (itemDiXLocDesemb === '') {
      toast.warning('Local de Ocorrência do Desembaraço deve ser informada.');
      return;
    }
    if (itemDiUfDesemb === '') {
      toast.warning('UF do Desembaraço deve ser informada.');
      return;
    }
    if (itemDiCExportador === '') {
      toast.warning('Código do Exportador deve ser informado.');
      return;
    }
    if (itemDiDDesemb === null) {
      toast.warning('Data do Desembaraço Aduaneiro deve ser informada.');
      return;
    }

    const _listaItemDi = listaItemDi;

    let item = 0;
    listaItemDi.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoItemDi && editandoItemDi > 0) {
      item = editandoItemDi;

      const index = _listaItemDi.indexOf(_listaItemDi.find(element => element.item === editandoItemDi));

      if (index >= 0) {
        _listaItemDi.splice(index, 1);
      }
    }

    _listaItemDi.push({
      item,
      nDi: itemDiNDi,
      dDi: formataDataMoment(itemDiDDi, '-'),
      xLocDesemb: itemDiXLocDesemb,
      ufDesemb: itemDiUfDesemb.label ? itemDiUfDesemb.label : itemDiUfDesemb,
      dDesemb: formataDataMoment(itemDiDDesemb, '-'),
      tpIntermedio: itemDiTpIntermedio,
      tpViaTransp: itemDiTpViaTransp,
      vAfrmm: itemDiVAfrmm,
      cnpj: itemDiCnpj,
      ufTerceiro: itemDiUfTerceiro.label ? itemDiUfTerceiro.label : itemDiUfTerceiro,
      cExportador: itemDiCExportador,
      listaNFeItemADIDTO: listaItemAdi,
    });

    limparCamposItemDi();

    setShowCadastroItemDi(false);
  };

  const handleOkItemAdi = () => {
    if (itemAdiNSeqAdic === '') {
      toast.warning('Número da Sequência da Adição deve ser informado.');
      return;
    }
    if (itemAdiCFabricante === '') {
      toast.warning('Código Frabricante Usado Internametne deve ser informado.');
      return;
    }

    const _listaItemAdi = listaItemAdi;

    let item = 0;
    listaItemAdi.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoItemAdi && editandoItemAdi > 0) {
      item = editandoItemAdi;

      const index = _listaItemAdi.indexOf(_listaItemAdi.find(element => element.item === editandoItemAdi));

      if (index >= 0) {
        _listaItemAdi.splice(index, 1);
      }
    }

    _listaItemAdi.push({
      item,
      nAdicao: itemAdiNAdicao,
      nSeqAdic: itemAdiNSeqAdic,
      cFabricante: itemAdiCFabricante,
      vDescDi: itemAdiVDescDi,
      nDraw: itemAdiNDraw,
    });

    limparCamposItemAdi();
    setShowCadastroItemAdi(false);
  };

  const handleOkItemDetExport = () => {
    if (itemDetExportNDraw === '') {
      toast.warning('Número do Ato Concessório de Drawback deve ser informado.');
      return;
    }

    const _listaItemDetExport = listaItemDetExport;

    let item = 0;
    listaItemDetExport.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoItemDetExport && editandoItemDetExport > 0) {
      item = editandoItemDetExport;

      const index = _listaItemDetExport.indexOf(_listaItemDetExport.find(element => element.item === editandoItemDetExport));

      if (index >= 0) {
        _listaItemDetExport.splice(index, 1);
      }
    }

    _listaItemDetExport.push({
      item,
      nDraw: itemDetExportNDraw,
    });

    limparCamposItemDetExport();
    setShowCadastroItemDetExport(false);
  };

  const handleOkItemRastro = () => {
    if (itemRastroNLote <= 0) {
      toast.warning('Número do lote deve ser informado.');
      return;
    }
    if (itemRastroQLote <= 0) {
      toast.warning('Quantidade no lote deve ser informado.');
      return;
    }
    if (itemRastroDFab === null) {
      toast.warning('Data da Fabricação deve ser informada.');
      return;
    }
    if (itemRastroDVal === null) {
      toast.warning('Data da Validade deve ser informada.');
      return;
    }

    const _listaItemRastro = listaItemRastro;

    let item = 0;
    listaItemRastro.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoItemRastro && editandoItemRastro > 0) {
      item = editandoItemRastro;

      const index = _listaItemRastro.indexOf(_listaItemRastro.find(element => element.item === editandoItemRastro));

      if (index >= 0) {
        _listaItemRastro.splice(index, 1);
      }
    }

    _listaItemRastro.push({
      item,
      nLote: itemRastroNLote,
      qLote: itemRastroQLote,
      dFab: formataDataMoment(itemRastroDFab, '-'),
      dVal: formataDataMoment(itemRastroDVal, '-'),
      cAgreg: itemRastroCAgreg,
    });

    limparCamposItemRastro();
    setShowCadastroItemRastro(false);
  };

  const handleOkFinanceiro = () => {
    let _pagVPag = pagVPag;
    if (_pagVPag === 0 && pagTPag === 'DINHEIRO') {
      setPagVPag(totalVNF);
      _pagVPag = totalVNF;
    }

    if (_pagVPag <= 0 && pagTPag !== 'SEMPAGAMENTO') {
      toast.warning('Valor inválido para notas diferente de devolucaod');
      return;
    }

    if (pagTPag === 'SEMPAGAMENTO' && (finNfe !== 'DEVOLUCAO' && finNfe !== 'AJUSTE')) {
      toast.warning('Tipo Pagamento válido somente para notas de DEVOLUÇÃO ou AJUSTE');
      return;
    }

    const _listaFinanceiro = listaFinanceiro;

    let item = 0;
    listaFinanceiro.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoFinanceiro && editandoFinanceiro > 0) {
      item = editandoFinanceiro;

      const index = _listaFinanceiro.indexOf(_listaFinanceiro.find(element => element.item === editandoFinanceiro));

      if (index >= 0) {
        _listaFinanceiro.splice(index, 1);
      }
    }

    _listaFinanceiro.push({
      item,
      indPag: formataDataMoment(pagDVenc, '-') === formataDataMoment(dhEmi, '-') ? 'AVISTA' : 'APRAZO',
      tPag: pagTPag,
      dVenc: formataDataMoment(pagDVenc, '/'),
      xPag: pagXPag,
      vPag: _pagVPag,
    });

    setListaFinanceiro(_listaFinanceiro);
    limparCamposFinanceiro();
    setShowCadastroFinanceiro(false);
  };

  const handleOkDuplicata = () => {
    if (cobrDupDVenc === null) {
      toast.warning('Data Duplicata deve ser informada');
      return;
    }

    if (cobrDupVDup <= 0 && pagTPag !== 'SEMPAGAMENTO') {
      toast.warning('Valor inválido para notas diferente de devolução.');
      return;
    }

    if (pagTPag === 'SEMPAGAMENTO' && (finNfe !== 'DEVOLUCAO' && finNfe !== 'AJUSTE')) {
      toast.warning('Tipo Pagamento válido somente para notas de DEVOLUÇÃO ou AJUSTE');
      return;
    }

    const _listaDuplicatas = listaDuplicatas;

    let item = 0;
    listaDuplicatas.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoDuplicata && editandoDuplicata > 0) {
      item = editandoDuplicata;

      const index = _listaDuplicatas.indexOf(_listaDuplicatas.find(element => element.item === editandoDuplicata));

      if (index >= 0) {
        _listaDuplicatas.splice(index, 1);
      }
    }

    if (cobrDupCondicaoPagamento !== '' && cobrDupCondicaoPagamento !== null && cobrDupCondicaoPagamento !== undefined) {
      const parcelas = cobrDupCondicaoPagamento.split('/');
      if (parcelas.length > 0) {
        let vDup = cobrDupVDup / parcelas.length;
        let diferenca = 0.0;

        if (vDup % parcelas.length !== 0) {
          let somaDup = 0.0;
          for (let x = 0; x < parcelas.length; x++) {
            somaDup += +arredondar(vDup, -2);
          }

          if (somaDup !== arredondar(cobrFatVOrig, -2)) {
            diferenca = cobrDupVDup - somaDup;
          }
        }

        for (let x = 0; x < parcelas.length; x++) {
          const dias = parcelas[x];
          if (x === parcelas.length - 1 && diferenca !== 0) {
            if (diferenca > 0) {
              vDup = +vDup + +diferenca;
            }
          }

          const data = moment(cobrDupDVenc, 'DD-MM-YYYY').add(dias, 'days').toDate().toString();

          _listaDuplicatas.push({
            item,
            tPag: pagTPag,
            dVenc: formataDataMoment(data, '/'),
            vDup: vDup.toFixed(2).toString(),
            nDup: item,
            condicaoPagamento: cobrDupCondicaoPagamento ? cobrDupCondicaoPagamento : '',
          });
          item = item + 1;
        }
      }
    } else {
      _listaDuplicatas.push({
        item,
        nDup: item,
        dVenc: formataDataMoment(cobrDupDVenc, '/'),
        vDup: pagTPag !== 'SEMPAGAMENTO' ? arredondar(cobrDupVDup, -2) : 0,
        condicaoPagamento: cobrDupCondicaoPagamento ? cobrDupCondicaoPagamento : '',
      });
    }

    limparCamposDuplicata();
    setShowCadastroDuplicata(false);
  };

  const handleOkAutXml = () => {
    if (autXmlCnpj === '' && autXmlCpf === '') {
      toast.warning('CNPJ ou CPF deve ser informado');
      return;
    }

    const _listaAutXml = listaAutXml;

    let item = 0;
    listaAutXml.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoAutXml && editandoAutXml > 0) {
      item = editandoAutXml;

      const index = _listaAutXml.indexOf(_listaAutXml.find(element => element.item === editandoAutXml));

      if (index >= 0) {
        _listaAutXml.splice(index, 1);
      }
    }

    _listaAutXml.push({
      item,
      cnpj: autXmlCnpj,
      cpf: autXmlCpf,
    });

    limparCamposAutXml();
    setShowCadastroAutXml(false);
  };

  const handleOkTranspNLacre = () => {
    if (transpNLacre === '') {
      toast.warning('Número Lacre deve ser informado');
      return;
    }

    const _listaTranspNLacre = listaTranspNLacre;

    let item = 0;
    listaTranspNLacre.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoTranspNLacre && editandoTranspNLacre > 0) {
      item = editandoTranspNLacre;

      const index = _listaTranspNLacre.indexOf(_listaTranspNLacre.find(element => element.item === editandoTranspNLacre));

      if (index >= 0) {
        _listaTranspNLacre.splice(index, 1);
      }
    }

    _listaTranspNLacre.push({
      item,
      nLacre: transpNLacre,
    });

    limparCamposTranspNLacre();

    setShowCadastroTranspNLacre(false);
  };

  const handleCalcularTributosItem = async () => {
    const _data = {
      cfop: itemCfop,
      cst: itemCst,
      qCom: itemQCom,
      precoVenda: itemPrecoBruto,

      pRedBc: itemPRedBc,
      vBc: itemVBc,
      vIcms: itemVIcms,
      pIcms: itemPIcms,

      pRedBcSt: itemPRedBcSt,
      pMvaSt: itemPMvaSt,
      vBcSt: itemVBcSt,
      pIcmsSt: itemPIcmsSt,
      vIcmsSt: itemVIcmsSt,

      ipiVBc: itemIpiVBc,
      ipiVIpi: itemIpiVIpi,
      ipiPIpi: itemIpiPIpi,

      vBcFcp: itemVBcFcp,
      pFcp: itemPFcp,
      vFcp: itemVFcp,

      pisCst: itemPisCst,
      pisVBc: itemPisVBc,
      pisPPis: itemPisPPis,
      pisVPis: itemPisVPis,

      cofinsCst: itemCofinsCst,
      cofinsVBc: itemCofinsVBc,
      cofinsPCofins: itemCofinsPCofins,
      cofinsVCofins: itemCofinsVCofins,

      vCredIcmsSn: itemVCredIcmsSn,
      pCredSn: itemPCredSn,
      totalFinal: itemTotalFinal,
      totalItem: itemTotalFinal,
      vICMSIsento: itemVICMSIsento,
      vICMSOutro: itemVICMSOutro,
      vOutro: itemVOutro,
    };

    await calcularTributos(_data);

    // console.log('data apos calcular tributos: ', _data);

    setItemPRedBc(_data.pRedBc);
    setItemVBc(_data.vBc);
    setItemVIcms(_data.vIcms);
    setItemPIcms(_data.pIcms);

    setItemPRedBcSt(_data.pRedBcSt);
    setItemPMvaSt(_data.pMvaSt);
    setItemVBcSt(_data.vBcSt);
    setItemPIcmsSt(_data.pIcmsSt);
    setItemVIcmsSt(_data.vIcmsSt);

    setItemIpiVBc(_data.ipiVBc);
    setItemIpiVBc(_data.ipiVBc);
    setItemIpiPIpi(_data.ipiPIpi);
    setItemIpiVIpi(_data.ipiVIpi);

    setItemVBcFcp(_data.vBcFcp);
    setItemPFcp(_data.pFcp);
    setItemVFcp(_data.vFcp);

    setItemPisVBc(_data.pisVBc);
    setItemPisPPis(_data.pisPPis);
    setItemPisVPis(_data.pisVPis);

    setItemCofinsVBc(_data.cofinsVBc);
    setItemCofinsPCofins(_data.cofinsPCofins);
    setItemCofinsVCofins(_data.cofinsVCofins);

    setItemVCredIcmsSn(_data.vCredIcmsSn);
    setItemPCredSn(_data.pCredSn);
    setItemTotalFinal(_data.totalFinal);
    setItemTotalItem(_data.totalItem);
    setItemVICMSIsento(_data.vICMSIsento);
    setItemVICMSOutro(_data.vICMSOutro);
    setItemVOutro(_data.vOutro);
  }; // fim calcular tributos

  const handleEditItem = async (e) => {
    e.preventDefault();

    const item = get(selectedItens, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItem.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o item para edição');
        return;
      }

      // console.log('item selecionado: ', _itemSelecionado);

      setEditandoItem(item);

      limparCamposItem(true);

      setItemIdProduto(_itemSelecionado.idProduto);
      setItemRegistro(_itemSelecionado.registro);
      setItemDescricaoProduto(_itemSelecionado.descricao);
      setItemUCom(_itemSelecionado.uCom);
      setItemNumeroPedidoCompra(_itemSelecionado.xPed);
      setItemNumeroItemPedidoCompra(_itemSelecionado.nItemPed);
      setItemQCom(_itemSelecionado.qCom);
      setItemPrecoBruto(_itemSelecionado.precoBruto);
      setItemCest(_itemSelecionado.cest);
      setItemTotalBruto(_itemSelecionado.qCom * _itemSelecionado.precoBruto);
      setItemPrecoVenda(_itemSelecionado.precoBruto - (_itemSelecionado.vDesc / _itemSelecionado.qCom));
      setItemTotalFinal(_itemSelecionado.totalItem);
      setItemVDesc(_itemSelecionado.vDesc);
      setItemIndTot(_itemSelecionado.indTot === 'COMPOE' ? true : false);
      setItemVFrete(_itemSelecionado.vFrete ? _itemSelecionado.vFrete : 0);
      setItemVICMSIsento(_itemSelecionado.vICMSIsento);
      setItemVICMSOutro(_itemSelecionado.vICMSOutro);
      setItemVOutro(_itemSelecionado.vOutro);

      if (_itemSelecionado.cfop.length < 4) {
        if (idDest === 'INTERNA') {
          _itemSelecionado.cfop = '5' + _itemSelecionado.cfop;
        } else if (idDest === 'INTERESTADUAL') {
          _itemSelecionado.cfop = '6' + _itemSelecionado.cfop;
        }
      }

      setItemCfop(_itemSelecionado.cfop);
      setItemCst(_itemSelecionado.cst);
      setItemCsosn(_itemSelecionado.csosn);
      setItemOrig(_itemSelecionado.orig);
      setItemNcm(_itemSelecionado.ncm.trim() != null ? _itemSelecionado.ncm.trim() : '');
      setItemCta(_itemSelecionado.cta);
      setItemIndEscala(_itemSelecionado.indEscala);
      setItemCnpjFab(_itemSelecionado.cnpjFab);
      setItemCBenef(_itemSelecionado.cBenef);
      setItemNaturezaReceita(_itemSelecionado.naturezaReceita);

      setItemIpiCst(_itemSelecionado.ipiCst);
      setItemIpiVBc(_itemSelecionado.ipiVBc);
      setItemIpiPIpi(_itemSelecionado.ipiPIpi);
      setItemIpiVIpi(_itemSelecionado.ipiVIpi);
      setItemPDevol(_itemSelecionado.ipiVIpiDevol);
      setItemIpiVIpiDevol(_itemSelecionado.ipiVIpiDevol);

      setItemModBc(_itemSelecionado.modBc);
      setItemVBc(_itemSelecionado.vBc);
      setItemPRedBc(_itemSelecionado.pRedBc);
      setItemPIcms(_itemSelecionado.pIcms);
      setItemVIcms(_itemSelecionado.vIcms);
      setItemModBcST(_itemSelecionado.modBcSt);
      setItemPRedBcSt(_itemSelecionado.pRedBcSt);
      setItemPMvaSt(_itemSelecionado.pMvaSt);
      setItemVBcSt(_itemSelecionado.vBcSt);
      setItemPIcmsSt(_itemSelecionado.pIcmsSt);
      setItemVIcmsSt(_itemSelecionado.vIcmsSt);
      setItemVIcmsDeson(_itemSelecionado.vIcmsDeson);
      setItemMotDesIcms(_itemSelecionado.motDesIcms);
      setItemVIcmsStDeson(_itemSelecionado.vIcmsStDeson);
      setItemMotDesIcmsSt(_itemSelecionado.motDesIcmsSt);

      setItemPisCst(_itemSelecionado.pisCst);
      setItemPisVBc(_itemSelecionado.pisVBc);
      setItemPisPPis(_itemSelecionado.pisPPis);
      setItemPisVPis(_itemSelecionado.pisVPis);
      setItemPisStVBc(_itemSelecionado.pisStVBc);
      setItemPisStPPis(_itemSelecionado.pisStPPis);
      setItemPisStVPis(_itemSelecionado.pisStVPis);
      setItemNaturezaReceita(_itemSelecionado.naturezaReceita);

      setItemCofinsCst(_itemSelecionado.cofinsCst);
      setItemCofinsVBc(_itemSelecionado.cofinsVBc);
      setItemCofinsPCofins(_itemSelecionado.cofinsPCofins);
      setItemCofinsVCofins(_itemSelecionado.cofinsVCofins);
      setItemCofinsStVBc(_itemSelecionado.cofinsStVBc);
      setItemCofinsStPCofins(_itemSelecionado.cofinsStPCofins);
      setItemCofinsStVCofins(_itemSelecionado.cofinsStVCofins);

      setItemIiVBc(_itemSelecionado.iiVBc);
      setItemIiDespAdu(_itemSelecionado.iiDespAdu);
      setItemIiVIof(_itemSelecionado.iiVIof);
      setItemIiVIi(_itemSelecionado.iiVIi);

      setItemPCredSn(_itemSelecionado.pCredSn);
      setItemVCredIcmsSn(_itemSelecionado.vCredIcmsSn);

      setItemPartilhaVBcUfDest(_itemSelecionado.vBcFcpUfDest);
      setItemPartilhaPIcmsUfDest(_itemSelecionado.pIcmsUfDest);
      setItemPartilhaPIcmsInter(_itemSelecionado.pIcmsInter);
      setItemPartilhaPIcmsInterPart(_itemSelecionado.pIcmsInterPart);
      setItemPartilhaVIcmsUfDest(_itemSelecionado.vIcmsUfDest);
      setItemPartilhaVIcmsUfRemet(_itemSelecionado.vIcmsUfRemet);

      setItemVBcFcp(_itemSelecionado.vBcFcp);
      setItemPFcp(_itemSelecionado.pFcp);
      setItemVFcp(_itemSelecionado.vFcp);
      setItemVBcFcpSt(_itemSelecionado.vBcFcpSt);
      setItemPFcpSt(_itemSelecionado.pFcpSt);
      setItemVFcpSt(_itemSelecionado.vFcpSt);
      setItemVBcFcpStRet(_itemSelecionado.vBcFcpStRet);
      setItemPFcpStRet(_itemSelecionado.pFcpStRet);
      setItemVFcpStRet(_itemSelecionado.vFcpStRet);
      setItemVFcpUfDest(_itemSelecionado.vFcpUfDest);

      setItemArmaTpArma(_itemSelecionado.armaTpArma);
      setItemArmaNSerie(_itemSelecionado.armaNSerie);
      setItemArmaNCano(_itemSelecionado.armaNCano);
      setItemArmaDescr(_itemSelecionado.armaDescr);

      setListaItemDi(_itemSelecionado.listaNFeItemDIDTO ? _itemSelecionado.listaNFeItemDIDTO : _itemSelecionado.listaItemDi);
      setListaItemDetExport(_itemSelecionado.listaNFeItemDetExportDTO ? _itemSelecionado.listaNFeItemDetExportDTO : _itemSelecionado.listaItemDetExport);
      setListaItemRastro(_itemSelecionado.listaNFeItemRastro ? _itemSelecionado.listaNFeItemRastro : _itemSelecionado.listaItemRastro);

      setItemMovFisicaEstoque(_itemSelecionado.movimentacaoFisica);

      setShowCadastroItem(true);
    }
  };

  const handleOkItem = async () => {
    if (itemIdProduto <= 0) {
      toast.warning('Produto deve ser informado');
      return;
    }
    if (itemDescricaoProduto === '') {
      toast.warning('Produto deve ser informado');
      return;
    }
    if (itemQCom <= 0) {
      toast.warning('Quantidade deve ser informada');
      return;
    }
    if (itemPrecoBruto <= 0) {
      toast.warning('Preço Bruto deve ser informado');
      return;
    }
    if (itemTotalBruto <= 0) {
      toast.warning('Total Bruto deve ser informado');
      return;
    }
    if (itemVDesc < 0) {
      toast.warning('Valor de Desconto não pode ser negativo');
      return;
    }
    if (itemVDesc > itemPrecoBruto) {
      toast.warning('Valor de Desconto não pode ser maior que preço bruto');
      return;
    }
    if (itemTotalFinal <= 0) {
      toast.warning('Total Final deve ser informado');
      return;
    }

    if (itemCfop === '' || itemCfop === null) {
      toast.warning('CFOP não informado');
      return;
    }

    if (itemCst === '' || itemCst === null) {
      toast.warning('CST não informado');
      return;
    }

    if (itemNcm === '' || itemNcm === null || itemNcm.length < 8) {
      toast.warning('Ncm deve conter 8 números');
      return;
    }

    if (itemCst === '20' && itemPRedBc <= 0) {
      toast.warning('Base de redução deve ser maior que zero para o CST informado');
      return;
    }
    if (itemIpiVIpiDevol > 0 && itemPDevol <= 0) {
      toast.warning('Percentual devolução deve ser informado para o Valor do IPI Devolvido');
      return;
    }
    const pisCst = itemPisCst.label ? itemPisCst.label.substring(0, 2) : itemPisCst;
    if (tpNf === 'ENTRADA' && pisCst < '50') {
      toast.warning('CST do PIS para entrada tem que ser maior ou igual a 50');
      return;
    }
    if (tpNf === 'SAIDA' && (pisCst >= '50' && pisCst <= '98')) {
      toast.warning('CST do PIS para saída tem que ser menor que 50 ou igual a 99');
      return;
    }
    if (pisCst === '01' && itemPisPPis <= 0) {
      toast.warning('Para CST 01 do PIS alíquota deve ser informada');
      return;
    }
    if (pisCst === '03') {
      toast.warning('O sistema não controla tributação do PIS por quantidade');
      return;
    }
    if (itemPisPPis > 0 && (['04', '06', '07', '08', '09'].includes(pisCst))) {
      toast.warning('Alíquota para CST do PIS tem que ser zero para cst 04, 06, 07, 08 ou 09');
      return;
    }
    const cofinsCst = itemCofinsCst.label != null ? itemCofinsCst.label.substring(0, 2) : itemCofinsCst;
    if (tpNf === 'ENTRADA' && cofinsCst < '50') {
      toast.warning('CST do COFINS para entrada tem que ser maior ou igual a 50');
      return;
    }
    if (tpNf === 'SAIDA' && (cofinsCst >= '50' && cofinsCst <= '98')) {
      toast.warning('CST do COFINS para saída tem que ser menor que 50 ou igual a 99');
      return;
    }
    if (cofinsCst === '01' && itemCofinsPCofins <= 0) {
      toast.warning('Para CST 01 do COFINS alíquota deve ser informada');
      return;
    }
    if (cofinsCst === '03') {
      toast.warning('O sistema não controla tributação do COFINS por quantidade');
      return;
    }
    if (itemCofinsPCofins > 0 && (['04', '06', '07', '08', '09'].includes(cofinsCst))) {
      toast.warning('Alíquota para CST do COFINS tem que ser zero para cst 04, 06, 07, 08 ou 09');
      return;
    }
    //
    let ipiCst = itemIpiCst.label ? itemIpiCst.label.substring(0, 2) : itemIpiCst;
    if (ipiCst === '') {
      tpNf === 'ENTRADA' ? ipiCst = '49' : ipiCst = '99';
    }

    const _listaItem = listaItem;

    let _valorEstorno = 0;

    let item = 0;
    listaItem.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoItem && editandoItem > 0) {
      item = editandoItem;

      _valorEstorno = listaItem.find(element => element.item === editandoItem).totalItem;

      const index = _listaItem.indexOf(_listaItem.find(element => element.item === editandoItem));

      if (index >= 0) {
        _listaItem.splice(index, 1);
      }
    }

    if (!showCadastroItemCompleto) { // inclusao rápida
      const _data = {
        cfop: itemCfop,
        cst: itemCst,
        qCom: itemQCom,
        precoVenda: itemPrecoBruto,
        vOutro: itemVOutro,

        pRedBc: itemPRedBc,
        vBc: itemVBc,
        vIcms: itemVIcms,
        pIcms: itemPIcms,

        pRedBcSt: itemPRedBcSt,
        pMvaSt: itemPMvaSt,
        vBcSt: itemVBcSt,
        pIcmsSt: itemPIcmsSt,
        vIcmsSt: itemVIcmsSt,

        ipiCst: itemIpiCst,
        ipiVBc: itemIpiVBc,
        ipiVIpi: itemIpiVIpi,
        ipiPIpi: itemIpiPIpi,

        vBcFcp: itemVBcFcp,
        pFcp: itemPFcp,
        vFcp: itemVFcp,

        pisCst: itemPisCst,
        pisVBc: itemPisVBc,
        pisPPis: itemPisPPis,
        pisVPis: itemPisVPis,

        cofinsCst: itemCofinsCst,
        cofinsVBc: itemCofinsVBc,
        cofinsPCofins: itemCofinsPCofins,
        cofinsVCofins: itemCofinsVCofins,

        vCredIcmsSn: itemVCredIcmsSn,
        pCredSn: itemPCredSn,
        totalFinal: itemTotalFinal,
        totalItem: itemTotalFinal,
        vICMSIsento: itemVICMSIsento,
        vICMSOutro: itemVICMSOutro,
      };

      await calcularTributos(_data);

      const valorTotTrib = calculaTotTribItem(arredondar((itemPrecoBruto - itemVDesc) * itemQCom, -3));

      _listaItem.push({
        item,
        idProduto: itemIdProduto,
        registro: itemRegistro,
        descricao: itemDescricaoProduto.trim(),
        uCom: itemUCom.label ? itemUCom.label : itemUCom,
        qCom: itemQCom,
        vUnCom: arredondar(itemPrecoBruto, -10),
        vProd: arredondar(itemPrecoBruto * itemQCom, -3),
        precoBruto: arredondar(itemPrecoBruto, -3),
        ncm: itemNcm,
        cest: itemCest,
        vDesc: arredondar(itemVDesc, -2),
        precoVenda: itemPrecoVenda,
        totalItem: arredondar((itemPrecoBruto - itemVDesc) * itemQCom, -3),
        totalFinal: arredondar((itemPrecoBruto - itemVDesc) * itemQCom, -3),
        vFrete: itemVFrete ? itemVFrete : 0, // calculado ao preencher valor frete
        vSeg: 0, // calculado ao salvar registro
        xPed: itemNumeroPedidoCompra,
        nItemPed: itemNumeroItemPedidoCompra,
        indTot: itemIndTot ? 'COMPOE' : 'NAOCOMPOE',

        cfop: itemCfop.label ? itemCfop.label.substring(0, 4) : itemCfop,
        cst: itemCst.label ? itemCst.label.substring(0, 2) : itemCst,
        csosn: itemCsosn,
        orig: itemOrig,
        cta: itemCta.label ? itemCta.label : itemCta,
        indEscala: itemIndEscala ? 'SIM' : 'NAO',
        cnpjFab: itemCnpjFab,
        cBenef: itemCBenef,
        naturezaReceita: itemNaturezaReceita,

        ipiCst,
        ipiVBc: _data.ipiVBc,
        ipiPIpi: itemIpiPIpi,
        ipiVIpi: _data.ipiVIpi,
        impostoDevolPDevol: itemPDevol,
        ipiVIpiDevol: itemIpiVIpiDevol,

        modBc: itemModBc,
        pRedBc: _data.pRedBc,
        vBc: _data.vBc,
        pIcms: _data.pIcms,
        vIcms: _data.vIcms,

        modBcSt: itemModBcST,
        pRedBcSt: _data.pRedBcSt,
        pMvaSt: _data.pMvaSt,
        vBcSt: _data.vBcSt,
        pIcmsSt: _data.pIcmsSt,
        vIcmsSt: _data.vIcmsSt,
        motDesIcms: itemMotDesIcms,
        vIcmsDeson: itemVIcmsDeson,
        motDesIcmsSt: itemMotDesIcmsSt,
        vIcmsStDeson: itemVIcmsStDeson,
        vICMSIsento: itemVICMSIsento,
        vICMSOutro: itemVICMSOutro,
        vOutro: itemVOutro,
        vTotTrib: valorTotTrib,

        pisCst: itemPisCst.label ? itemPisCst.label.substring(0, 2) : itemPisCst,
        pisVBc: _data.pisVBc,
        pisPPis: _data.pisPPis,
        pisVPis: _data.pisVPis,
        pisStVBc: itemPisStVBc,
        pisStPPis: itemPisStPPis,
        pisStVPis: itemPisStVPis,

        cofinsCst: itemCofinsCst.label ? itemCofinsCst.label.substring(0, 2) : itemCofinsCst,
        cofinsVBc: _data.cofinsVBc,
        cofinsPCofins: _data.cofinsPCofins,
        cofinsVCofins: _data.cofinsVCofins,
        cofinsStVBc: itemCofinsStVBc,
        cofinsStPCofins: itemCofinsStPCofins,
        cofinsStVCofins: itemCofinsStVCofins,

        iiVBc: itemIiVBc,
        iiDespAdu: itemIiDespAdu,
        iiVIof: itemIiVIof,
        iiVIi: itemIiVIi,

        pCredSn: _data.pCredSn,
        vCredIcmsSn: _data.vCredIcmsSn,

        icmsUfDestVBcUfDest: itemPartilhaVBcUfDest,
        icmsUfDestPIcmsUfDest: itemPartilhaPIcmsUfDest,
        icmsUfDestPIcmsInter: itemPartilhaPIcmsInter,
        icmsUfDestPIcmsInterPart: itemPartilhaPIcmsInterPart,
        icmsUfDestVIcmsUfDest: itemPartilhaVIcmsUfDest,
        icmsUfDestVIcmsUfRemet: itemPartilhaVIcmsUfRemet,

        vBcFcp: _data.vBcFcp,
        pFcp: _data.pFcp,
        vFcp: _data.vFcp,
        vBcFcpSt: itemVBcFcpSt,
        pFcpSt: itemPFcpSt,
        vFcpSt: itemVFcpSt,
        vBcFcpStRet: itemVBcFcpStRet,
        pFcpStRet: itemPFcpStRet,
        vFcpStRet: itemVFcpStRet,

        icmsUfDestVBcFcpUfDest: itemVBcFcpUfDest,
        icmsUfDestPFcpUfDest: itemPFcpUfDest,
        icmsUfDestVFcpUfDest: itemVFcpUfDest,

        armaTpArma: itemArmaTpArma,
        armaNSerie: itemArmaNSerie,
        armaNCano: itemArmaNCano,
        armaDescr: itemArmaDescr,

        listaNFeItemRastroDTO: listaItemRastro,
        listaNFeItemDIDTO: listaItemDi,
        listaNFeItemDetExportDTO: listaItemDetExport,

        movimentacaoFisica: itemMovFisicaEstoque,
        somarIpiNaBc: itemSomarIpiNaBc ? 'SIM' : 'NAO',
      });
    } else { // inclusao completa
      const valorTotTrib = calculaTotTribItem(arredondar((itemPrecoBruto - itemVDesc) * itemQCom, -3));

      _listaItem.push({
        item,
        idProduto: itemIdProduto,
        registro: itemRegistro,
        descricao: itemDescricaoProduto.trim(),
        uCom: itemUCom.label ? itemUCom.label : itemUCom,
        qCom: itemQCom,
        vUnCom: arredondar(itemPrecoBruto, -10),
        vProd: arredondar(itemPrecoBruto * itemQCom, -3),
        precoBruto: arredondar(itemPrecoBruto, -3),
        ncm: itemNcm,
        cest: itemCest,
        vDesc: arredondar(itemVDesc, -2),
        precoVenda: itemPrecoVenda,
        totalItem: arredondar(arredondar(itemPrecoBruto - itemVDesc, -3) * itemQCom, -3),
        totalFinal: arredondar(arredondar(itemPrecoBruto - itemVDesc, -3) * itemQCom, -3),
        vFrete: itemVFrete ? itemVFrete : 0, // calculado ao preencher valor frete
        vSeg: 0, // calculado ao salvar registro
        xPed: itemNumeroPedidoCompra,
        nItemPed: itemNumeroItemPedidoCompra,
        indTot: itemIndTot ? 'COMPOE' : 'NAOCOMPOE',

        cfop: itemCfop.label ? itemCfop.label.substring(0, 4) : itemCfop,
        cst: itemCst.label ? itemCst.label.substring(0, 2) : itemCst,
        csosn: itemCsosn,
        orig: itemOrig,
        cta: itemCta.label ? itemCta.label : itemCta,
        indEscala: itemIndEscala ? 'SIM' : 'NAO',
        cnpjFab: itemCnpjFab,
        cBenef: itemCBenef,
        naturezaReceita: itemNaturezaReceita,

        ipiCst: itemIpiCst.label ? itemIpiCst.label.substring(0, 2) : itemIpiCst,
        ipiVBc: itemIpiVBc,
        ipiPIpi: itemIpiPIpi,
        ipiVIpi: itemIpiVIpi,
        impostoDevolPDevol: itemPDevol,
        ipiVIpiDevol: itemIpiVIpiDevol,

        modBc: itemModBc,
        pRedBc: itemPRedBc,
        vBc: itemVBc,
        pIcms: itemPIcms,
        vIcms: itemVIcms,

        modBcSt: itemModBcST,
        pRedBcSt: itemPRedBcSt,
        pMvaSt: itemPMvaSt,
        vBcSt: itemVBcSt,
        pIcmsSt: itemPIcmsSt,
        vIcmsSt: itemVIcmsSt,
        motDesIcms: itemMotDesIcms,
        vIcmsDeson: itemVIcmsDeson,
        motDesIcmsSt: itemMotDesIcmsSt,
        vIcmsStDeson: itemVIcmsStDeson,
        vICMSIsento: itemVICMSIsento,
        vICMSOutro: itemVICMSOutro,
        vOutro: itemVOutro,
        vTotTrib: valorTotTrib,

        pisCst: itemPisCst.label ? itemPisCst.label.substring(0, 2) : itemPisCst,
        pisVBc: itemPisVBc,
        pisPPis: itemPisPPis,
        pisVPis: itemPisVPis,
        pisStVBc: itemPisStVBc,
        pisStPPis: itemPisStPPis,
        pisStVPis: itemPisStVPis,

        cofinsCst: itemCofinsCst.label ? itemCofinsCst.label.substring(0, 2) : itemCofinsCst,
        cofinsVBc: itemCofinsVBc,
        cofinsPCofins: itemCofinsPCofins,
        cofinsVCofins: itemCofinsVCofins,
        cofinsStVBc: itemCofinsStVBc,
        cofinsStPCofins: itemCofinsStPCofins,
        cofinsStVCofins: itemCofinsStVCofins,

        iiVBc: itemIiVBc,
        iiDespAdu: itemIiDespAdu,
        iiVIof: itemIiVIof,
        iiVIi: itemIiVIi,

        pCredSn: itemPCredSn,
        vCredIcmsSn: itemVCredIcmsSn,

        icmsUfDestVBcUfDest: itemPartilhaVBcUfDest,
        icmsUfDestPIcmsUfDest: itemPartilhaPIcmsUfDest,
        icmsUfDestPIcmsInter: itemPartilhaPIcmsInter,
        icmsUfDestPIcmsInterPart: itemPartilhaPIcmsInterPart,
        icmsUfDestVIcmsUfDest: itemPartilhaVIcmsUfDest,
        icmsUfDestVIcmsUfRemet: itemPartilhaVIcmsUfRemet,

        vBcFcp: itemVBcFcp,
        pFcp: itemPFcp,
        vFcp: itemVFcp,
        vBcFcpSt: itemVBcFcpSt,
        pFcpSt: itemPFcpSt,
        vFcpSt: itemVFcpSt,
        vBcFcpStRet: itemVBcFcpStRet,
        pFcpStRet: itemPFcpStRet,
        vFcpStRet: itemVFcpStRet,

        icmsUfDestVBcFcpUfDest: itemVBcFcpUfDest,
        icmsUfDestPFcpUfDest: itemPFcpUfDest,
        icmsUfDestVFcpUfDest: itemVFcpUfDest,

        armaTpArma: itemArmaTpArma,
        armaNSerie: itemArmaNSerie,
        armaNCano: itemArmaNCano,
        armaDescr: itemArmaDescr,

        listaNFeItemRastroDTO: listaItemRastro,
        listaNFeItemDIDTO: listaItemDi,
        listaNFeItemDetExportDTO: listaItemDetExport,

        movimentacaoFisica: itemMovFisicaEstoque,
        somarIpiNaBc: itemSomarIpiNaBc ? 'SIM' : 'NAO',
      });
    }

    if (!disableEdicaoEnviada) {
      calcularTotaisCfopCst(_listaItem);
    }

    if (itemTotalItem && itemTotalItem > 0) {
      const total = valorTotalItens + itemTotalItem - _valorEstorno;
      setValorTotalItens(total);
      setTotalVProd(total);
    }

    let _valorTotalItens = 0;
    _listaItem.forEach(element => {
      _valorTotalItens = _valorTotalItens + element.totalItem;
    });

    setValorTotalItens(_valorTotalItens);
    setTotalVProd(_valorTotalItens);

    limparCamposItem();
    setShowCadastroItem(false);
  };

  const confirmFooterDadosAdicionais = (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <button
            onClick={handleFinanceiro}
            type="button"
            className="btn btn-success"
          >
            <i className="pi pi-check"></i>
            Continuar
          </button>
          <button
            onClick={cancelDadosAdicionais}
            type="button"
            className="btn btn-danger"
          >
            <i className="pi pi-times"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );

  const confirmDialogFooterTotaisNota = (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <button
            onClick={handleSubmit}
            type="button"
            className="btn btn-success"
          >
            <i className="pi pi-check"></i>
            Salvar NF-e
          </button>
          <button
            onClick={cancelTotaisNota}
            type="button"
            className="btn btn-danger"
          >
            <i className="pi pi-times"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );

  const confirmFooterFinanceiro = (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <button
            onClick={handleTotaisNota}
            type="button"
            className="btn btn-success"
          >
            <i className="pi pi-check"></i>
            Continuar
          </button>
          <button
            onClick={cancelFinanceiro}
            type="button"
            className="btn btn-danger"
          >
            <i className="pi pi-times"></i>
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );

  const confirmDialogFooterFinanceiro = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteFinanceiro} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteFinanceiro} />
    </div>
  );

  const confirmDialogFooterDuplicata = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteDuplicata} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteDuplicata} />
    </div>
  );

  const confirmDialogFooterItem = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteItem} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteItem} />
    </div>
  );

  const confirmDialogFooterItemRastro = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteItemRastro} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteItemRastro} />
    </div>
  );

  const confirmDialogFooterItemDi = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteItemDi} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteItemDi} />
    </div>
  );

  const confirmDialogFooterItemAdi = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteItemAdi} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteItemAdi} />
    </div>
  );

  const confirmDialogFooterItemDetExport = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteItemDetExport} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteItemDetExport} />
    </div>
  );

  const confirmDialogFooterRefNFe = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteRefNFe} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteRefNFe} />
    </div>
  );

  const confirmDialogFooterRefEcf = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteRefEcf} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteRefEcf} />
    </div>
  );

  const confirmDialogFooterRefProc = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteRefProc} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteRefProc} />
    </div>
  );

  const confirmDialogFooterAutXml = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteAutXml} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteAutXml} />
    </div>
  );

  const confirmDialogFooterEdicaoEnviada = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmEdicaoEnviada} />
      <Button label="Não" icon="pi pi-times" onClick={cancelEdicaoEnviada} />
    </div>
  );

  const confirmDialogFooterTranspNLacre = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteTranspNLacre} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteTranspNLacre} />
    </div>
  );

  const confirmDialogFooterCancelarDigitacaoNFe = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmCancelarDigitacaoNFe} />
      <Button label="Não" icon="pi pi-times" onClick={cancelCancelarDigitacaoNFe} />
    </div>
  );

  const searchNatOp = (event) => {
    setNatOp(event.query);

    const listDropdown = [];
    listaNatOps.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredNatOp(listDropdown);
  };

  const searchItemDiUfDesemb = (event) => {
    setItemDiUfDesemb(event.query);

    const listDropdown = [];
    listaItemDiUfDesemb.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredItemDiUfDesemb(listDropdown);
  };

  const searchItemDiUfTerceiro = (event) => {
    setItemDiUfTerceiro(event.query);

    const listDropdown = [];
    listaItemDiUfTerceiro.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredItemDiUfTerceiro(listDropdown);
  };

  const searchTranspVeicTranspUF = (event) => {
    setTranspVeicTranspUF(event.query);

    const listDropdown = [];
    listaTranspVeicTranspUF.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredTranspVeicTranspUF(listDropdown);
  };

  const searchUnidadeMedida = (event) => {
    setItemUCom(event.query);

    const listDropdown = [];
    listaUnidadeMedidas.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredUnidadeMedida(listDropdown);
  };

  const searchItemCfop = (event) => {
    setItemCfop(event.query);

    const listDropdown = [];
    listaItemCfops.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredItemCfop(listDropdown);
  };

  const searchTranspCfop = (event) => {
    setTranspCfop(event.query);

    const listDropdown = [];
    listaTranspCfops.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredTranspCfop(listDropdown);
  };

  const searchItemCst = (event) => {
    setItemCst(event.query);

    const listDropdown = [];
    listaItemCsts.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredItemCst(listDropdown);
  };

  const searchItemCta = (event) => {
    setItemCta(event.query);

    const listDropdown = [];
    listaItemCtas.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredItemCta(listDropdown);
  };

  const searchPisCst = (event) => {
    setItemPisCst(event.query);

    const listDropdown = [];
    listaPISCOFINS.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredPisCst(listDropdown);
  };

  const searchCofinsCst = (event) => {
    setItemCofinsCst(event.query);

    const listDropdown = [];
    listaPISCOFINS.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredCofinsCst(listDropdown);
  };

  const titulo = () => (id ? 'NF-e - Alteração' : 'NF-e - Inclusão');

  return (

    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Participantes"
        visible={showConsultaParticipante}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaParticipante()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParent} empresaCnpj={empresaCnpj} />
      </Dialog>

      <Dialog
        header="Consulta de Natureza da Operação"
        visible={showConsultaNaturezaOperacao}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaNaturezaOperacao()}
      >
        <ConsultaNaturezaOperacao menuMode={false} childToParent={childToParentNatOp} />
      </Dialog>

      <Dialog
        header="Consulta de Produtos"
        visible={showConsultaProduto}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaProduto()}
      >
        <ConsultaProduto menuMode={false} childToParent={childToParentConsultaProduto} empresaCnpj={empresaCnpj} />
      </Dialog>

      <Dialog
        header="Consulta Unidade de Medida"
        visible={showConsultaUnidadeMedida}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaUnidadeMedida()}
      >
        <ConsultaUnidadeMedida menuMode={false} childToParent={childToParentConsultaUnidadeMedida} empresaCnpj={empresaCnpj} />
      </Dialog>

      <Dialog
        header="Consulta de Transportadora"
        visible={showConsultaTransportadora}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaTransportadora()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParentTransportadora} empresaCnpj={empresaCnpj} />
      </Dialog>

      <Dialog
        header="Consulta de Emitente Doc. Referenciado"
        visible={showConsultaRefNFeEmitente}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaRefNFeEmitente()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParentRefNFeEmitente} empresaCnpj={empresaCnpj} />
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteFinanceiro}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterFinanceiro}
        onHide={() => setShowConfirmDeleteFinanceiro(false)}
      >
        <p>
          Excluir o Financeiro?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteDuplicata}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterDuplicata}
        onHide={() => setShowConfirmDeleteDuplicata(false)}
      >
        <p>
          Excluir a Duplicata?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmEdicaoEnviada}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterEdicaoEnviada}
        onHide={() => setShowConfirmEdicaoEnviada(false)}
      >
        <p>
          Nota Fiscal já foi enviada. Alteração limitada a alguns campos!
        </p>
        <p>
          Deseja Continuar?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteAutXml}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterAutXml}
        onHide={() => setShowConfirmDeleteAutXml(false)}
      >
        <p>
          Excluir o CNPJ/CPF autorizado?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteTranspNLacre}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterTranspNLacre}
        onHide={() => setShowConfirmDeleteTranspNLacre(false)}
      >
        <p>
          Excluir o lacre?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação Cancelamento da digitação da NFe"
        visible={showConfirmCancelarDigitacaoNFe}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterCancelarDigitacaoNFe}
        onHide={() => showConfirmCancelarDigitacaoNFe(false)}
      >
        <p>
          Deseja realmente cancelar a digitação da NFe?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteItem}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterItem}
        onHide={() => setShowConfirmDeleteItem(false)}
      >
        <p>
          Excluir o item?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteItemRastro}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterItemRastro}
        onHide={() => setShowConfirmDeleteItemRastro(false)}
      >
        <p>
          Excluir o rastro?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteItemDi}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterItemDi}
        onHide={() => setShowConfirmDeleteItemDi(false)}
      >
        <p>
          Excluir o DI?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteItemAdi}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterItemAdi}
        onHide={() => setShowConfirmDeleteItemAdi(false)}
      >
        <p>
          Excluir o ADI?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteItemDetExport}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterItemDetExport}
        onHide={() => setShowConfirmDeleteItemDetExport(false)}
      >
        <p>
          Excluir o Detalhe Exportação?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteRefNFe}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterRefNFe}
        onHide={() => setShowConfirmDeleteRefNFe(false)}
      >
        <p>
          Excluir o Doc. Referenciado?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteRefEcf}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterRefEcf}
        onHide={() => setShowConfirmDeleteRefEcf(false)}
      >
        <p>
          Excluir o ECF Referenciado?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showConfirmDeleteRefProc}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterRefProc}
        onHide={() => setShowConfirmDeleteRefProc(false)}
      >
        <p>
          Excluir o Processo Referenciado?
        </p>
      </Dialog>

      <Dialog
        header="Nota Fiscal - Financeiro"
        visible={showFinanceiro}
        style={{ width: '98vw' }}
        footer={confirmFooterFinanceiro}
        onHide={() => onHideFinanceiro()}
      >
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Total Nota Fiscal
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVNF}
                onValueChange={e => setTotalVNF(e.target.value)}
              />
            </div>
          </div>
        </div>

        <TabView>
          <TabPanel header="Formas de Pagamento*">
            <div className="estamparia-pedido-item">
              <DataTable
                header="Pagamentos"
                value={listaFinanceiro}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                sortField="item"
                selectionMode="single"
                selection={selectedFinanceiros}
                onSelectionChange={e => setSelectedFinanceiros(e.value)}
                selectableRows
                dataKey="item"
                emptyMessage="Nenhum Item cadastrado"
              >
                {fillColumns(columnsSetupFinanceiro)}
              </DataTable>
            </div>
            <br />
            <div className="row form-elo">
              <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Inclusão Financeiro"
                  className="btn btn-success"
                  style={{ borderRadius: '15px' }}
                  onClick={() => {
                    setEditandoFinanceiro(0);
                    limparCamposFinanceiro();
                    setShowCadastroFinanceiro(true);
                  }}
                >
                  <i className="pi pi-plus"></i>
                </button>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Alterar Financeiro"
                  className="btn btn-warning"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleEditFinanceiro(e)}
                >
                  <i className="pi pi-pencil"></i>
                </button>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Cancelar Financeiro"
                  className="btn btn-danger"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleDeleteFinanceiro(e)}
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Fatura">
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Nº Fatura
                  </Input155px>
                  <InputText
                    maxLength={60}
                    value={cobrFatNFat}
                    onChange={(e) => setCobrFatNFat(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Valor Bruto
                  </Input155px>
                  <InputNumber
                    onFocus={(e) => e.target.select()}
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={cobrFatVOrig}
                    onValueChange={e => setCobrFatVOrig(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Valor Desconto
                  </Input155px>
                  <InputNumber
                    onFocus={(e) => e.target.select()}
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={cobrFatVDesc}
                    onValueChange={e => setCobrFatVDesc(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Valor Líquido
                  </Input155px>
                  <InputNumber
                    onFocus={(e) => e.target.select()}
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={cobrFatVLiq}
                    onValueChange={e => setCobrFatVLiq(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Duplicatas">
            <div className="estamparia-pedido-item">
              <DataTable
                header="Duplicatas"
                value={listaDuplicatas}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                sortField="item"
                selectionMode="single"
                selection={selectedDuplicatas}
                onSelectionChange={e => setSelectedDuplicatas(e.value)}
                selectableRows
                dataKey="item"
                emptyMessage="Nenhum Item cadastrado"
              >
                {fillColumns(columnsSetupDuplicata)}
              </DataTable>
            </div>
            <br />
            <div className="row form-elo">
              <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Inclusão Duplicata"
                  className="btn btn-success"
                  style={{ borderRadius: '15px' }}
                  onClick={() => {
                    setEditandoDuplicata(0);
                    limparCamposDuplicata();
                    setShowCadastroDuplicata(true);
                  }}
                >
                  <i className="pi pi-plus"></i>
                </button>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Alterar Duplicata"
                  className="btn btn-warning"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleEditDuplicata(e)}
                >
                  <i className="pi pi-pencil"></i>
                </button>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Cancelar Duplicata"
                  className="btn btn-danger"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleDeleteDuplicata(e)}
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </Dialog>

      <Dialog
        header="Nota Fiscal - Dados Adicionais"
        visible={showDadosAdicionais}
        style={{ width: '98vw' }}
        footer={confirmFooterDadosAdicionais}
        onHide={() => onHideDadosAdicionais()}
      >
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Data Saída/Entrada
              </Input200px>
              <Calendar
                disabled={disableEdicaoEnviada}
                id="mask"
                showIcon
                showTime
                hourFormat="24"
                showSeconds
                mask="99/99/9999 99:99:99"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dhSaiEnt}
                onChange={(e) => setDhSaiEnt(e.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Indicador de Presença
              </Input200px>
              <Dropdown
                disabled={disableEdicaoEnviada}
                options={listaIndicadorPresenca}
                value={indPres}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setIndPres(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Indicador de Intermediário
              </Input200px>
              <Dropdown
                disabled={disableEdicaoEnviada}
                options={listaIndicadorIntermediario}
                value={indIntermed}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setIndIntermed(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Seguro
              </Input155px>
              <InputNumber
                disabled={disableEdicaoEnviada}
                onFocus={(e) => e.target.select()}
                mode="decimal"
                inputId="minmaxfraction"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={vSeg}
                onValueChange={e => {
                  setVSeg(e.target.value);
                  setTotalVSeg(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Outras Despesas Acessórias
              </Input200px>
              <InputNumber
                disabled={disableEdicaoEnviada}
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={vOutro}
                onValueChange={e => {
                  setVOutro(e.target.value);
                  setTotalVOutro(e.target.value);
                  setTotalVNF(totalVProd + +e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Infor. Complementar da NF
              </Input200px>
              <InputText
                disabled={disableEdicaoEnviada}
                maxLength={5000}
                value={infCpl}
                onChange={e => setInfCpl(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Infor. de Interesse do Fisco
              </Input200px>
              <InputText
                disabled={disableEdicaoEnviada}
                maxLength={2000}
                value={infAdFisco}
                onChange={e => setInfAdFisco(e.target.value)}
              />
            </div>
          </div>
        </div>
        <TabView>
          <TabPanel header="NFe Referenciada">
            <div className="estamparia-pedido-item">
              <DataTable
                value={listaRefNFe}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                sortField="item"
                selectionMode="single"
                selection={selectedRefNFes}
                onSelectionChange={e => setSelectedRefNFes(e.value)}
                selectableRows
                dataKey="item"
                emptyMessage="Nenhum Item cadastrado"
              >
                {fillColumns(columnsSetupRefNFe)}
              </DataTable>
            </div>
            <br />
            <div className="row form-elo">
              <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Inclusão Doc. Referenciado"
                  className="btn btn-success"
                  style={{ borderRadius: '15px' }}
                  onClick={() => {
                    setEditandoRefNFe(0);
                    limparCamposRefNFe();
                    setShowCadastroRefNFe(true);
                  }}
                >
                  <i className="pi pi-plus"></i>
                </button>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Alterar Doc. Referenciado"
                  className="btn btn-warning"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleEditRefNFe(e)}
                >
                  <i className="pi pi-pencil"></i>
                </button>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Cancelar Doc. Referenciado"
                  className="btn btn-danger"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleDeleteRefNFe(e)}
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="ECF Referenciado">
            <div className="estamparia-pedido-item">
              <DataTable
                value={listaRefEcf}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                sortField="item"
                selectionMode="single"
                selection={selectedRefEcfs}
                onSelectionChange={e => setSelectedRefEcfs(e.value)}
                selectableRows
                dataKey="item"
                emptyMessage="Nenhum Item cadastrado"
              >
                {fillColumns(columnsSetupRefEcf)}
              </DataTable>
            </div>
            <br />
            <div className="row form-elo">
              <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Inclusão ECF Referenciado"
                  className="btn btn-success"
                  style={{ borderRadius: '15px' }}
                  onClick={() => {
                    setEditandoRefEcf(0);
                    limparCamposRefEcf();
                    setShowCadastroRefEcf(true);
                  }}
                >
                  <i className="pi pi-plus"></i>
                </button>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Alterar Doc. Referenciado"
                  className="btn btn-warning"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleEditRefEcf(e)}
                >
                  <i className="pi pi-pencil"></i>
                </button>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Cancelar Doc. Referenciado"
                  className="btn btn-danger"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleDeleteRefEcf(e)}
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Processo Referenciado">
            <div className="estamparia-pedido-item">
              <DataTable
                value={listaRefProc}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                sortField="item"
                selectionMode="single"
                selection={selectedRefProc}
                onSelectionChange={e => setSelectedRefProc(e.value)}
                selectableRows
                dataKey="item"
                emptyMessage="Nenhum Item cadastrado"
              >
                {fillColumns(columnsSetupRefProc)}
              </DataTable>
            </div>
            <br />
            <div className="row form-elo">
              <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Inclusão Processo Referenciado"
                  className="btn btn-success"
                  style={{ borderRadius: '15px' }}
                  onClick={() => {
                    setEditandoRefProc(0);
                    limparCamposRefProc();
                    setShowCadastroRefProc(true);
                  }}
                >
                  <i className="pi pi-plus"></i>
                </button>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Alterar Processo Referenciado"
                  className="btn btn-warning"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleEditRefProc(e)}
                >
                  <i className="pi pi-pencil"></i>
                </button>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Cancelar Processo Referenciado"
                  className="btn btn-danger"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleDeleteRefProc(e)}
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Transporte">
            <div className="row form-elo">
              <div className="col-5">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Transportadora
                  </Input155px>
                  <InputText
                    disabled
                    maxLength={255}
                    value={razaoSocialTranporta}
                    onChange={e => setRazaoSocialTransporta(e.target.value)}
                  />
                  <Button
                    disabled={disableEdicaoEnviada}
                    icon="pi pi-search"
                    className="p-button-warning"
                    onClick={() => setShowConsultaTransportadora(true)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Modalidade do Frete
                  </Input200px>
                  <Dropdown
                    disabled={disableEdicaoEnviada}
                    options={listaModalidadeFrete}
                    value={transpModFrete}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={(e) => setTranspModFrete(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Transporte CFOP
                  </Input155px>
                  <AutoComplete
                    disabled={disableEdicaoEnviada}
                    value={transpCfop}
                    maxLength={4}
                    suggestions={filteredTranspCfop}
                    completeMethod={searchTranspCfop}
                    field="label"
                    dropdown
                    forceSelection
                    onChange={(e) => setTranspCfop(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Quantidade
                  </Input155px>
                  <InputText
                    disabled={disableEdicaoEnviada}
                    maxLength={60}
                    value={transpQVol}
                    onChange={e => setTranspQVol(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Peso Líquido
                  </Input155px>
                  <InputNumber
                    disabled={disableEdicaoEnviada}
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={transpPesoL}
                    onValueChange={e => setTranspPesoL(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Peso Bruto
                  </Input155px>
                  <InputNumber
                    disabled={disableEdicaoEnviada}
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={transpPesoB}
                    onValueChange={e => setTranspPesoB(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Marca
                  </Input155px>
                  <InputText
                    disabled={disableEdicaoEnviada}
                    maxLength={60}
                    value={transpMarca}
                    onChange={e => setTranspMarca(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Espécie
                  </Input155px>
                  <InputText
                    disabled={disableEdicaoEnviada}
                    maxLength={60}
                    value={transpEsp}
                    onChange={e => setTranspEsp(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Número Volumes
                  </Input155px>
                  <InputText
                    disabled={disableEdicaoEnviada}
                    maxLength={60}
                    value={transpNVol}
                    onChange={e => setTranspNVol(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Placa Veículo
                  </Input155px>
                  <InputText
                    disabled={disableEdicaoEnviada}
                    maxLength={7}
                    value={transpVeicTranspPlaca}
                    onChange={e => setTranspVeicTranspPlaca(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Veículo UF
                  </Input155px>
                  <AutoComplete
                    disabled={disableEdicaoEnviada}
                    value={transpVeicTranspUF}
                    maxLength={4}
                    suggestions={filteredTranspVeicTranspUF}
                    completeMethod={searchTranspVeicTranspUF}
                    field="label"
                    dropdown
                    forceSelection
                    onChange={(e) => setTranspVeicTranspUF(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Valor Frete
                  </Input155px>
                  <InputNumber
                    disabled={disableEdicaoEnviada}
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={transpVServ}
                    onValueChange={e => {
                      setTranspVServ(e.target.value);
                      setTotalVFrete(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Base Cálculo ICMS
                  </Input155px>
                  <InputNumber
                    disabled={disableEdicaoEnviada}
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={transpVBCRet}
                    onValueChange={e => setTranspVBCRet(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Valor ICMS
                  </Input155px>
                  <InputNumber
                    disabled={disableEdicaoEnviada}
                    mode="decimal"
                    inputId="minmaxfraction"
                    minFractionDigits={3}
                    maxFractionDigits={3}
                    value={transpVICMSRet}
                    onValueChange={e => setTranspVICMSRet(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Registro RNTC
                  </Input155px>
                  <InputText
                    disabled={disableEdicaoEnviada}
                    maxLength={20}
                    value={transpVeicTranspRNTC}
                    onChange={e => setTranspVeicTranspRNTC(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="estamparia-pedido-item">
              <DataTable
                header="Lacres de Transporte"
                value={listaTranspNLacre}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                sortField="item"
                selectionMode="single"
                selection={selectedTranspNLacre}
                onSelectionChange={e => setSelectedTranspNLacre(e.value)}
                selectableRows
                dataKey="item"
                emptyMessage="Nenhum Item cadastrado"
              >
                {fillColumns(columnsSetupTranspNLacre)}
              </DataTable>
            </div>
            <br />
            <div className="row form-elo">
              <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Inclusão Autorização XML"
                  className="btn btn-success"
                  style={{ borderRadius: '15px' }}
                  onClick={() => {
                    setEditandoTranspNLacre(0);
                    limparCamposTranspNLacre(true);
                    setShowCadastroTranspNLacre(true);
                  }}
                >
                  <i className="pi pi-plus"></i>
                </button>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Alterar autorização Xml"
                  className="btn btn-warning"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleEditTranspNLacre(e)}
                >
                  <i className="pi pi-pencil"></i>
                </button>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Cancelar autorização XML"
                  className="btn btn-danger"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleDeleteTranspNLacre(e)}
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Autorizar XML">
            <div className="estamparia-pedido-item">
              <DataTable
                value={listaAutXml}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                sortField="item"
                selectionMode="single"
                selection={selectedAutXml}
                onSelectionChange={e => setSelectedAutXml(e.value)}
                selectableRows
                dataKey="item"
                emptyMessage="Nenhum Item cadastrado"
              >
                {fillColumns(columnsSetupAutXml)}
              </DataTable>
            </div>
            <br />
            <div className="row form-elo">
              <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Inclusão Autorização XML"
                  className="btn btn-success"
                  style={{ borderRadius: '15px' }}
                  onClick={() => {
                    setEditandoAutXml(0);
                    limparCamposAutXml();
                    setShowCadastroAutXml(true);
                  }}
                >
                  <i className="pi pi-plus"></i>
                </button>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Alterar autorização Xml"
                  className="btn btn-warning"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleEditAutXml(e)}
                >
                  <i className="pi pi-pencil"></i>
                </button>
                <button
                  disabled={disableEdicaoEnviada}
                  type="button"
                  title="Cancelar autorização XML"
                  className="btn btn-danger"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleDeleteAutXml(e)}
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </Dialog>

      <Dialog
        header="Nota Fiscal - Totais"
        visible={showTotaisNota}
        style={{ width: '98vw' }}
        footer={confirmDialogFooterTotaisNota}
        onHide={() => onHideTotaisNota()}
      >
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Base de Cálc. de ICMS
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVBC}
                onValueChange={e => setTotalVBC(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor total ICMS
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVICMS}
                onValueChange={e => setTotalVICMS(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Total ICMS Deson.
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVICMSDeson}
                onValueChange={e => setTotalVICMSDeson(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Total FCP UF Dest
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                maxFractionDigits={2}
                value={totalVFCPUFDest}
                onValueChange={e => setTotalVFCPUFDest(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Total ICMS UF Dest
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVICMSUFDest}
                onValueChange={e => setTotalVICMSUFDest(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Total ICMS UF Remet
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVIICMSUFRemet}
                onValueChange={e => setTotalVIICMSUFRemet(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Total Base Cálc ST
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVBCST}
                onValueChange={e => setTotalVBCST(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Total ST
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVST}
                onValueChange={e => setTotalVST(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Total FCP
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVFCP}
                onValueChange={e => setTotalVFCP(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Total FCP ST
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVFCPST}
                onValueChange={e => setTotalVFCPST(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Total FCP ST Retido
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVFCPStRet}
                onValueChange={e => setTotalVFCPStRet(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Outras Despesas
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVOutro}
                onValueChange={e => setTotalVOutro(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Total de Frete
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVFrete}
                onValueChange={e => setTotalVFrete(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Total Seguro
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVSeg}
                onValueChange={e => setTotalVSeg(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Total COFINS
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVCOFINS}
                onValueChange={e => setTotalVCOFINS(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Total de Desconto
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVDesc}
                onValueChange={e => setTotalVDesc(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Total IPI
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVIPI}
                onValueChange={e => setTotalVIPI(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Total IPI Devolução
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVIPIDevol}
                onValueChange={e => setTotalVIPIDevol(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Total PIS
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVPIS}
                onValueChange={e => setTotalVPIS(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Total dos Produtos
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVProd}
                onValueChange={e => setTotalVProd(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Total Outros
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVICMSOutro}
                onValueChange={e => setTotalVICMSOutro(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Total Isento
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVICMSIsento}
                onValueChange={e => setTotalVICMSIsento(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Total II
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVII}
                onValueChange={e => setTotalVII(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Total Nota Fiscal
              </Input155px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVNF}
                onValueChange={e => setTotalVNF(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Nota Fiscal - Financeiro Inclusão"
        visible={showCadastroFinanceiro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroFinanceiro()}
      >
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo Pagamento NFe
              </Input155px>
              <Dropdown
                options={listaTipoPagamentoNFe}
                value={pagTPag}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setPagTPag(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Vencimento
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={pagDVenc}
                onChange={(e) => setPagDVenc(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Descrição Pagamento
              </Input155px>
              <InputText
                maxLength={60}
                value={pagXPag}
                onChange={(e) => setPagXPag(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={pagVPag}
                onValueChange={e => setPagVPag(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkFinanceiro}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroFinanceiro(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Nota Fiscal - Duplicata Inclusão"
        visible={showCadastroDuplicata}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroDuplicata()}
      >
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Vencimento
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={cobrDupDVenc}
                onChange={(e) => setCobrDupDVenc(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Condição de Pagamento (Ex: 7/14/21)
              </Input200px>
              <InputText
                onFocus={(e) => e.target.select()}
                maxLength={20}
                value={cobrDupCondicaoPagamento}
                onChange={e => setCobrDupCondicaoPagamento(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={cobrDupVDup}
                onValueChange={e => setCobrDupVDup(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkDuplicata}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroDuplicata(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Nota Fiscal - Autorização Download Xml"
        visible={showCadastroAutXml}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroAutXml()}
      >
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Cnpj
              </Input155px>
              <InputText
                maxLength={14}
                value={autXmlCnpj}
                onChange={e => setAutXmlCnpj(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CPF
              </Input155px>
              <InputText
                maxLength={14}
                value={autXmlCpf}
                onChange={e => setAutXmlCpf(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkAutXml}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroAutXml(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Nota Fiscal - Transporte Lacres"
        visible={showCadastroTranspNLacre}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroTranspNLacre()}
      >
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Número do Lacre
              </Input155px>
              <InputText
                maxLength={60}
                value={transpNLacre}
                onChange={e => setTranspNLacre(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkTranspNLacre}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroTranspNLacre(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Nota Fiscal - Documento Fiscal Referenciado"
        visible={showCadastroRefNFe}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroRefNFe()}
      >
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data*
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={refNFeAAMM}
                onChange={(e) => setRefNFeAAMM(e.value)}
              />
            </div>
          </div>
          <div className="col-5">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Emitente*
              </Input155px>
              <InputText
                disabled
                maxLength={255}
                value={razaoSocialRefNFeEmitente}
                onChange={e => setRazaoSocialRefNFeEmitente(e.target.value)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaRefNFeEmitente(true)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo de Operação
              </Input155px>
              <Dropdown
                options={listaTipoOperacao}
                value={refNFeTipoOperacao}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setRefNFeTipoOperacao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Chave Acesso*
              </Input200px>
              <InputText
                maxLength={44}
                value={refNFe}
                onChange={e => setRefNFe(e.target.value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Modelo*
              </Input100px>
              <InputText
                maxLength={2}
                value={refNFeMod}
                onChange={e => setRefNFeMod(e.target.value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Série*
              </Input100px>
              <InputText
                minLength={1}
                maxLength={3}
                value={refNFeSerie}
                onChange={e => setRefNFeSerie(e.target.value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Número*
              </Input100px>
              <InputText
                maxLength={9}
                value={refNFeNNF}
                onChange={e => setRefNFeNNF(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkRefNFe}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroRefNFe(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Nota Fiscal - ECF Referenciado"
        visible={showCadastroRefEcf}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroRefEcf()}
      >
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Modelo*
              </Input200px>
              <InputText
                maxLength={2}
                value={refEcfMod}
                onChange={e => setRefEcfMod(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Número do ECF*
              </Input200px>
              <InputText
                maxLength={3}
                value={refEcfNEcf}
                onChange={e => setRefEcfNEcf(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Número do COO*
              </Input200px>
              <InputText
                maxLength={6}
                value={refEcfNCoo}
                onChange={e => setRefEcfNCoo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkRefEcf}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroRefEcf(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Nota Fiscal - Proc. Referenciado"
        visible={showCadastroRefProc}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroRefProc()}
      >
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Número
              </Input200px>
              <InputText
                maxLength={2}
                value={refProcNProc}
                onChange={e => setRefProcNProc(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Indicador Origem do Processo
              </Input200px>
              <Dropdown
                options={listaIndicadorProcessoRef}
                value={refProcIndProc}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setRefProcIndProc(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Tipo do Ato Concessório
              </Input200px>
              <Dropdown
                options={listaTipoAtoConcessorioProcRef}
                value={refProcTpAto}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setRefProcTpAto(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkRefProc}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroRefProc(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Nota Fiscal - Item DI"
        visible={showCadastroItemDi}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroItemDi()}
      >
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nº DI/DSI/DA*
              </Input155px>
              <InputText
                maxLength={15}
                value={itemDiNDi}
                onChange={e => setItemDiNDi(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Registro*
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={itemDiDDi}
                onChange={(e) => setItemDiDDi(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Via de Tranporte
              </Input155px>
              <Dropdown
                options={listaTipoViaTransporte}
                value={itemDiTpViaTransp}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setItemDiTpViaTransp(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor AFRMM
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={itemDiVAfrmm}
                onValueChange={e => setItemDiVAfrmm(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input400px className="p-inputgroup-addon">
                Local de Ocorrência do Desembaraço Aduaneiro*
              </Input400px>
              <InputText
                maxLength={60}
                value={itemDiXLocDesemb}
                onChange={e => setItemDiXLocDesemb(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                UF*
              </Input155px>
              <AutoComplete
                value={itemDiUfDesemb}
                minLength={2}
                maxLength={60}
                suggestions={filteredItemDiUfDesemb}
                completeMethod={searchItemDiUfDesemb}
                field="label"
                dropdown
                forceSelection
                onChange={(e) => setItemDiUfDesemb(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Desembaraço*
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={itemDiDDesemb}
                onChange={(e) => setItemDiDDesemb(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input400px className="p-inputgroup-addon">
                Forma de Importação Quanto a Intermediação
              </Input400px>
              <Dropdown
                options={listaTipoIntermedio}
                value={itemDiTpIntermedio}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setItemDiTpIntermedio(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Cnpj Adquirente ou Encomendante
              </Input200px>
              <InputText
                maxLength={14}
                value={itemDiCnpj}
                onChange={e => setItemDiCnpj(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                UF
              </Input155px>
              <AutoComplete
                value={itemDiUfTerceiro}
                minLength={2}
                maxLength={60}
                suggestions={filteredItemDiUfTerceiro}
                completeMethod={searchItemDiUfTerceiro}
                field="label"
                dropdown
                forceSelection
                onChange={(e) => setItemDiUfTerceiro(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Código do Exportador*
              </Input155px>
              <InputText
                maxLength={60}
                value={itemDiCExportador}
                onChange={e => setItemDiCExportador(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="estamparia-pedido-item">
            <DataTable
              header="Inclusão de Adi"
              value={listaItemAdi}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              sortField="item"
              selectionMode="single"
              selection={selectedItemAdi}
              onSelectionChange={e => setSelectedItemAdi(e.value)}
              selectableRows
              dataKey="item"
              emptyMessage="Nenhum Item cadastrado"
            >
              {fillColumns(columnsSetupItemAdi)}
            </DataTable>
          </div>
          <br />
          <div className="row form-elo">
            <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button
                type="button"
                title="Inclusão ADI"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={() => {
                  setEditandoItemAdi(0);
                  limparCamposItemAdi(true);
                  setShowCadastroItemAdi(true);
                }}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                type="button"
                title="Alterar ADI"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEditItemAdi(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                type="button"
                title="Cancelar ADI"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDeleteItemAdi(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkItemDi}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroItemDi(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Nota Fiscal - Item ADI"
        visible={showCadastroItemAdi}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroItemAdi()}
      >
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nº Adicao
              </Input155px>
              <InputText
                maxLength={3}
                value={itemAdiNAdicao}
                onChange={e => setItemAdiNAdicao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input400px className="p-inputgroup-addon">
                Nº Sequencial do Item Dentro da Adicao*
              </Input400px>
              <InputText
                maxLength={5}
                value={itemAdiNSeqAdic}
                onChange={e => setItemAdiNSeqAdic(e.target.value)}
              />
            </div>
          </div>
          <div className="col-5">
            <div className="p-inputgroup">
              <Input400px className="p-inputgroup-addon">
                Código do Frabricante Usado Internamente*
              </Input400px>
              <InputText
                maxLength={60}
                value={itemAdiCFabricante}
                onChange={e => setItemAdiCFabricante(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Desconto Item
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={itemAdiVDescDi}
                onValueChange={e => setItemAdiVDescDi(e.target.value)}
              />
            </div>
          </div>
          <div className="col-9">
            <div className="p-inputgroup">
              <Input400px className="p-inputgroup-addon">
                Nº. Ato concessório de Drawback quando aplicável
              </Input400px>
              <InputText
                maxLength={20}
                value={itemAdiNDraw}
                onChange={e => setItemAdiNDraw(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkItemAdi}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroItemAdi(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Nota Fiscal - Item Detalhe Exportação"
        visible={showCadastroItemDetExport}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroItemDetExport()}
      >
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input400px className="p-inputgroup-addon">
                Nº. Ato concessório de Drawback
              </Input400px>
              <InputText
                maxLength={20}
                value={itemDetExportNDraw}
                onChange={e => setItemDetExportNDraw(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkItemDetExport}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroItemDetExport(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Nota Fiscal - Item Rastreabilidade"
        visible={showCadastroItemRastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroItemRastro()}
      >
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Número Lote*
              </Input155px>
              <InputText
                maxLength={20}
                value={itemRastroNLote}
                onChange={e => setItemRastroNLote(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Qtde no Lote*
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="decimal"
                inputId="minmaxfraction"
                maxFractionDigits={3}
                value={itemRastroQLote}
                onValueChange={(e) => setItemRastroQLote(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Código Agregação
              </Input200px>
              <InputText
                maxLength={20}
                value={itemRastroCAgreg}
                onChange={e => setItemRastroCAgreg(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Fabricação*
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={itemRastroDFab}
                onChange={(e) => setItemRastroDFab(e.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Validade*
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={itemRastroDVal}
                onChange={(e) => setItemRastroDVal(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkItemRastro}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroItemRastro(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Dialog
        header="Nota Fiscal - Item"
        visible={showCadastroItem}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroItem()}
      >
        <div className="row form-elo">
          <div className="col-8">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Produto*
              </Input200px>
              <InputText
                disabled
                value={itemDescricaoProduto}
              />
              <Button
                disabled={disableEdicaoEnviada}
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaProduto(true)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Unidade*
              </Input200px>
              <AutoComplete
                disabled={disableEdicaoEnviada}
                value={itemUCom}
                maxLength={6}
                suggestions={filteredUnidadeMedida}
                completeMethod={searchUnidadeMedida}
                field="label"
                dropdown
                forceSelection
                onChange={(e) => setItemUCom(e.target.value)}
              />
              <Button
                disabled={disableEdicaoEnviada}
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaUnidadeMedida(true)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Quantidade*
              </Input200px>
              <InputNumber
                disabled={disableEdicaoEnviada}
                onFocus={(e) => e.target.select()}
                mode="decimal"
                inputId="minmaxfraction"
                minFractionDigits={4}
                maxFractionDigits={4}
                value={itemQCom}
                onValueChange={(e) => setItemQCom(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Preço Bruto*
              </Input200px>
              <InputNumber
                disabled={disableEdicaoEnviada}
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={3}
                value={itemPrecoBruto}
                onValueChange={e => setItemPrecoBruto(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Total Bruto
              </Input200px>
              <InputNumber
                disabled={disableEdicaoEnviada}
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={itemTotalBruto}
                onValueChange={e => setItemTotalBruto(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Valor Desconto
              </Input200px>
              <InputNumber
                disabled={disableEdicaoEnviada}
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={itemVDesc}
                onValueChange={e => setItemVDesc(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Preço Venda
              </Input200px>
              <InputNumber
                disabled={disableEdicaoEnviada}
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={5}
                value={itemPrecoVenda}
                onValueChange={(e) => setItemPrecoVenda(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Total Final
              </Input200px>
              <InputNumber
                disabled={disableEdicaoEnviada}
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={itemTotalFinal}
                onValueChange={e => setItemTotalFinal(e.target.value)}
              />
            </div>
          </div>
        </div>
        { showCadastroItemCompleto ? (
          <>
            <div className="row form-elo">
              <div className="col-10">
              </div>
              <div className="col-2">
                <div className="p-inputgroup">
                  <button
                    disabled={disableEdicaoEnviada}
                    type="button"
                    title="Calcular Tributos"
                    className="btn btn-success"
                    style={{ borderRadius: '15px' }}
                    onClick={handleCalcularTributosItem}
                  >
                    <i className="pi pi-money-bill"></i> Calcular Tributos
                  </button>
                </div>
              </div>
            </div>
            <TabView activeIndex={itemActiveTabIndexCadastroProduto} onTabChange={(e) => setItemActiveTabIndexCadastroProduto(e.index)}>
              <TabPanel header="Codificação">
                <div className="row form-elo">
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input100px className="p-inputgroup-addon">
                        CFOP*
                      </Input100px>
                      <AutoComplete
                        value={itemCfop}
                        maxLength={4}
                        suggestions={filteredItemCfop}
                        completeMethod={searchItemCfop}
                        field="label"
                        dropdown
                        forceSelection
                        onChange={(e) => setItemCfop(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input100px className="p-inputgroup-addon">
                        CST*
                      </Input100px>
                      <AutoComplete
                        value={itemCst}
                        maxLength={2}
                        suggestions={filteredItemCst}
                        completeMethod={searchItemCst}
                        field="label"
                        dropdown
                        forceSelection
                        onChange={(e) => setItemCst(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input100px className="p-inputgroup-addon">
                        CSOSN
                      </Input100px>
                      <Dropdown
                        options={listaItemCsosn}
                        value={itemCsosn}
                        emptyMessage="Nenhum registro a ser exibido"
                        onChange={(e) => setItemCsosn(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input100px className="p-inputgroup-addon">
                        NCM
                      </Input100px>
                      <InputText
                        disabled={disableEdicaoEnviada}
                        maxLength={8}
                        value={itemNcm}
                        onChange={e => setItemNcm(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input100px className="p-inputgroup-addon">
                        CEST
                      </Input100px>
                      <InputText
                        disabled={disableEdicaoEnviada}
                        maxLength={7}
                        value={itemCest}
                        onChange={e => setItemCest(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-5">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Origem da Mercadoria
                      </Input200px>
                      <Dropdown
                        options={listaItemOrig}
                        value={itemOrig}
                        emptyMessage="Nenhum registro a ser exibido"
                        onChange={(e) => setItemOrig(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Cód. Conta Contábil
                      </Input200px>
                      <AutoComplete
                        value={itemCta}
                        suggestions={filteredItemCta}
                        completeMethod={searchItemCta}
                        field="label"
                        dropdown
                        forceSelection
                        onChange={(e) => setItemCta(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="ICMS">
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Base Cálculo ICMS
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemVBc}
                        onValueChange={e => setItemVBc(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Alíquota ICMS
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPIcms}
                        onValueChange={(e) => {
                          setItemPIcms(e.target.value);
                          setItemVIcms(itemPIcms * (e.target.value / 100) > 0 ? itemVBc * (e.target.value / 100) : 0);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor ICMS
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemVIcms}
                        onValueChange={e => setItemVIcms(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Modalidade BC ICMS
                      </Input200px>
                      <Dropdown
                        disabled={disableEdicaoEnviada}
                        options={listaModalidadeBcIcms}
                        value={itemModBc}
                        emptyMessage="Nenhum registro a ser exibido"
                        onChange={(e) => setItemModBc(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Base Redução
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="minmaxfraction"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPRedBc}
                        onValueChange={e => setItemPRedBc(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Base Cálculo ICMS ST
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemVBcSt}
                        onValueChange={e => setItemVBcSt(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Alíquota ICMS ST
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPIcmsSt}
                        onValueChange={e => {
                          setItemPIcmsSt(e.target.value);
                          setItemVIcmsSt(itemPIcmsSt * (e.target.value / 100) > 0 ? itemVBcSt * (e.target.value / 100) : 0);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor ICMS ST
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemVIcmsSt}
                        onValueChange={e => setItemVIcmsSt(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Modalidade BC ICMS ST
                      </Input200px>
                      <Dropdown
                        disabled={disableEdicaoEnviada}
                        options={listaModalidadeBcIcmsSt}
                        value={itemModBcST}
                        emptyMessage="Nenhum registro a ser exibido"
                        onChange={(e) => setItemModBcST(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Base Redução ST
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPRedBcSt}
                        onValueChange={e => setItemPRedBcSt(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        MVA ST
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPMvaSt}
                        onValueChange={e => setItemPMvaSt(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor ICMS Isento
                      </Input200px>
                      <InputNumber
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemVICMSIsento}
                        onValueChange={e => setItemVICMSIsento(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor ICMS Outras
                      </Input200px>
                      <InputNumber
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemVICMSOutro}
                        onValueChange={e => setItemVICMSOutro(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-6">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Motivo Desoneração
                      </Input200px>
                      <Dropdown
                        disabled={disableEdicaoEnviada}
                        options={listaMotivoDesoneracao}
                        value={itemMotDesIcms}
                        emptyMessage="Nenhum registro a ser exibido"
                        onChange={(e) => setItemMotDesIcms(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="p-inputgroup">
                      <Input250px className="p-inputgroup-addon">
                        Valor ICMS Desonerado
                      </Input250px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemVIcmsDeson}
                        onValueChange={e => setItemVIcmsDeson(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-6">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Motivo Desoneração ST
                      </Input200px>
                      <Dropdown
                        disabled={disableEdicaoEnviada}
                        options={listaMotivoDesoneracaoSt}
                        value={itemMotDesIcmsSt}
                        emptyMessage="Nenhum registro a ser exibido"
                        onChange={(e) => setItemMotDesIcmsSt(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="p-inputgroup">
                      <Input250px className="p-inputgroup-addon">
                        Valor ICMS ST Desonerado
                      </Input250px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemVIcmsStDeson}
                        onValueChange={e => setItemVIcmsStDeson(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="PIS">
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Código PIS
                      </Input200px>
                      <AutoComplete
                        value={itemPisCst}
                        maxLength={60}
                        suggestions={filteredPisCst}
                        completeMethod={searchPisCst}
                        field="label"
                        dropdown
                        forceSelection
                        onChange={(e) => setItemPisCst(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Base Cálculo PIS
                      </Input200px>
                      <InputNumber
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPisVBc}
                        onValueChange={e => setItemPisVBc(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Alíquota PIS
                      </Input200px>
                      <InputNumber
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPisPPis}
                        onValueChange={(e) => {
                          setItemPisPPis(e.target.value);
                          setItemPisVPis(itemPisVBc * (e.target.value / 100) > 0 ? itemPisVBc * (e.target.value / 100) : 0);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor do PIS
                      </Input200px>
                      <InputNumber
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPisVPis}
                        onValueChange={e => setItemPisVPis(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Base Cálculo PIS ST
                      </Input200px>
                      <InputNumber
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPisStVBc}
                        onValueChange={e => setItemPisStVBc(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Alíquota PIS ST
                      </Input200px>
                      <InputNumber
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPisStPPis}
                        onValueChange={(e) => {
                          setItemPisStPPis(e.target.value);
                          setItemPisStVPis(itemPisStVBc * (e.target.value / 100) > 0 ? itemPisStVBc * (e.target.value / 100) : 0);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor do PIS ST
                      </Input200px>
                      <InputNumber
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPisStVPis}
                        onValueChange={e => setItemPisStVPis(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Natureza da Receita
                      </Input200px>
                      <InputText
                        maxLength={3}
                        value={itemNaturezaReceita}
                        onChange={e => setItemNaturezaReceita(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="COFINS">
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Código COFINS
                      </Input200px>
                      <AutoComplete
                        value={itemCofinsCst}
                        maxLength={4}
                        suggestions={filteredCofinsCst}
                        completeMethod={searchCofinsCst}
                        field="label"
                        dropdown
                        forceSelection
                        onChange={(e) => setItemCofinsCst(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Base Cálculo COFINS
                      </Input200px>
                      <InputNumber
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemCofinsVBc}
                        onValueChange={e => setItemCofinsVBc(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Alíquota COFINS
                      </Input200px>
                      <InputNumber
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemCofinsPCofins}
                        onValueChange={(e) => {
                          setItemCofinsPCofins(e.target.value);
                          setItemCofinsVCofins(itemCofinsVBc * (e.target.value / 100));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor do COFINS
                      </Input200px>
                      <InputNumber
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemCofinsVCofins}
                        onValueChange={e => setItemCofinsVCofins(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Base Cálculo COFINS ST
                      </Input200px>
                      <InputNumber
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemCofinsStVBc}
                        onValueChange={e => setItemCofinsStVBc(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Alíquota COFINS ST
                      </Input200px>
                      <InputNumber
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemCofinsStPCofins}
                        onValueChange={(e) => {
                          setItemCofinsStPCofins(e.target.value);
                          setItemCofinsStVCofins(itemCofinsStVBc * (e.target.value / 100));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor do COFINS ST
                      </Input200px>
                      <InputNumber
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemCofinsStVCofins}
                        onValueChange={e => setItemCofinsStVCofins(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="IPI">
                <div className="row form-elo">
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        CST IPI
                      </Input155px>
                      <Dropdown
                        disabled={disableEdicaoEnviada}
                        options={listaItemIpiCst}
                        value={itemIpiCst}
                        emptyMessage="Nenhum registro a ser exibido"
                        onChange={(e) => setItemIpiCst(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Base Cálculo IPI
                      </Input155px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemIpiVBc}
                        onValueChange={e => setItemIpiVBc(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Alíquota IPI
                      </Input155px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="minmaxfraction"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemIpiPIpi}
                        onValueChange={(e) => {
                          setItemIpiPIpi(e.target.value);
                          setItemIpiVIpi(itemIpiVBc * (e.target.value / 100) > 0 ? itemIpiVBc * (e.target.value / 100) : 0);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Valor do IPI
                      </Input155px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemIpiVIpi}
                        onValueChange={e => setItemIpiVIpi(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Percentual Devolução
                      </Input155px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="minmaxfraction"
                        maxFractionDigits={2}
                        value={itemPDevol}
                        onValueChange={e => setItemPDevol(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Valor do IPI Devolução
                      </Input155px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        maxFractionDigits={2}
                        value={itemIpiVIpiDevol}
                        onValueChange={e => setItemIpiVIpiDevol(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="II">
                <div className="row form-elo">
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Base Cálculo II
                      </Input155px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemIiVBc}
                        onValueChange={e => setItemIiVBc(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Despesas Aduaneiras
                      </Input155px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemIiDespAdu}
                        onValueChange={e => setItemIiDespAdu(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Valor do IOF
                      </Input155px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemIiVIof}
                        onValueChange={e => setItemIiVIof(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Valor do Imposto
                      </Input155px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemIiVIi}
                        onValueChange={e => setItemIiVIi(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Outros">
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        CNPJ Fabricante
                      </Input200px>
                      <InputText
                        maxLength={14}
                        value={itemCnpjFab}
                        onChange={e => setItemCnpjFab(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Cód Benefício Fiscal
                      </Input200px>
                      <InputText
                        maxLength={14}
                        value={itemCBenef}
                        onChange={e => setItemCBenef(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input400px className="p-inputgroup-addon">
                        Indicador Produção Escala
                      </Input400px>
                      <ToggleButton
                        disabled={disableEdicaoEnviada}
                        onLabel="Sim"
                        offLabel="Não"
                        onIcon="pi pi-check"
                        offIcon="pi pi-times"
                        checked={itemIndEscala}
                        onChange={(e) => setItemIndEscala(e.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Pedido Compra
                      </Input200px>
                      <InputText
                        disabled={disableEdicaoEnviada}
                        maxLength={15}
                        value={itemNumeroPedidoCompra}
                        onChange={e => setItemNumeroPedidoCompra(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Número do Item
                      </Input200px>
                      <InputText
                        disabled={disableEdicaoEnviada}
                        maxLength={6}
                        value={itemNumeroItemPedidoCompra}
                        onChange={e => setItemNumeroItemPedidoCompra(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input400px className="p-inputgroup-addon">
                        Compor o Valor Final da NF-e
                      </Input400px>
                      <ToggleButton
                        disabled={disableEdicaoEnviada}
                        onLabel="Sim"
                        offLabel="Não"
                        onIcon="pi pi-check"
                        offIcon="pi pi-times"
                        checked={itemIndTot}
                        onChange={(e) => setItemIndTot(e.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input400px className="p-inputgroup-addon">
                        Somar IPI na BC
                      </Input400px>
                      <ToggleButton
                        disabled={disableEdicaoEnviada}
                        onLabel="Sim"
                        offLabel="Não"
                        onIcon="pi pi-check"
                        offIcon="pi pi-times"
                        checked={itemSomarIpiNaBc}
                        onChange={(e) => setItemSomarIpiNaBc(e.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input400px className="p-inputgroup-addon">
                        Movimentação Física de Estoque
                      </Input400px>
                      <ToggleButton
                        disabled={disableEdicaoEnviada}
                        onLabel="Sim"
                        offLabel="Não"
                        onIcon="pi pi-check"
                        offIcon="pi pi-times"
                        checked={itemMovFisicaEstoque}
                        onChange={(e) => setItemMovFisicaEstoque(e.value)}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Simples Nacional">
                <div className="row form-elo">
                  <div className="col-6">
                    <div className="p-inputgroup">
                      <Input400px className="p-inputgroup-addon">
                        Alíquota Aplicável de Cálculo do Crédito
                      </Input400px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPCredSn}
                        onValueChange={e => setItemPCredSn(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="p-inputgroup">
                      <Input400px className="p-inputgroup-addon">
                        Crédito de ICMS que pode ser aproveitado
                      </Input400px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemVCredIcmsSn}
                        onValueChange={e => setItemVCredIcmsSn(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Partilha ICMS">
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Base Cálculo UF Destino
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPartilhaVBcUfDest}
                        onValueChange={e => setItemPartilhaVBcUfDest(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Alíquota Interna UF Destino
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPartilhaPIcmsUfDest}
                        onValueChange={e => {
                          setItemPartilhaPIcmsUfDest(e.target.value);
                          setItemPartilhaVIcmsUfDest(itemPartilhaVBcUfDest >= 0 ? itemPartilhaVBcUfDest * (e.target.value / 100) : 0);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor Interna UF Destino
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPartilhaVIcmsUfDest}
                        onValueChange={e => setItemPartilhaVIcmsUfDest(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Alíquota partilha Interestadual
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPartilhaPIcmsInterPart}
                        onValueChange={e => setItemPartilhaPIcmsInterPart(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Alíq. Interestadual UF Envolvida
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPartilhaPIcmsInter}
                        onValueChange={e => setItemPartilhaPIcmsInter(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Alíquota Interna UF Remetente
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="decimal"
                        suffix=" %"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPartilhaVIcmsUfRemet}
                        onValueChange={e => setItemPartilhaVIcmsUfRemet(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="FCP">
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor Base Cálculo FCP
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemVBcFcp}
                        onValueChange={e => setItemVBcFcp(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Alíquota FCP
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPFcp}
                        onValueChange={e => {
                          setItemPFcp(e.target.value);
                          setItemVFcp(itemVBcFcp * (e.target.value / 100));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor do FCP
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemVFcp}
                        onValueChange={e => setItemVFcp(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor Base Cálculo FCP ST
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemVBcFcpSt}
                        onValueChange={e => setItemVBcFcpSt(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Alíquota FCP ST
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPFcpSt}
                        onValueChange={e => {
                          setItemPFcpSt(e.target.value);
                          setItemVFcpSt(itemVBcFcpSt * (e.target.value / 100));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor do FCP ST
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        maxFractionDigits={2}
                        value={itemVFcpSt}
                        onValueChange={e => setItemVFcpSt(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor BC FCP ST Retido Ant.
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemVBcFcpStRet}
                        onValueChange={e => setItemVBcFcpStRet(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Alíquota FCP ST Retido Ant.
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPFcpStRet}
                        onValueChange={e => {
                          setItemPFcpStRet(e.target.value);
                          setItemVFcpStRet(itemVBcFcpStRet * (e.target.value / 100));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor do FCP ST Retido Ant.
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        currency="BRL"
                        locale="pt-BR"
                        maxFractionDigits={2}
                        value={itemVFcpStRet}
                        onValueChange={e => setItemVFcpStRet(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor BC FCP UF Dest.
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        maxFractionDigits={2}
                        value={itemVBcFcpUfDest}
                        onValueChange={e => setItemVBcFcpUfDest(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Alíquota FCP UF Dest.
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        suffix=" %"
                        mode="decimal"
                        inputId="currency-br"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemPFcpUfDest}
                        onValueChange={e => {
                          setItemPFcpUfDest(e.target.value);
                          setItemVFcpUfDest(itemVBcFcpUfDest * (e.target.value / 100));
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input200px className="p-inputgroup-addon">
                        Valor do FCP UF Dest.
                      </Input200px>
                      <InputNumber
                        disabled={disableEdicaoEnviada}
                        onFocus={(e) => e.target.select()}
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemVFcpUfDest}
                        onValueChange={e => setItemVFcpUfDest(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Rastreabilidade">
                <div className="estamparia-pedido-item">
                  <DataTable
                    value={listaItemRastro}
                    size="small"
                    stripedRows
                    responsiveLayout="scroll"
                    sortField="item"
                    selectionMode="single"
                    selection={selectedItemRastros}
                    onSelectionChange={e => setSelectedItemRastros(e.value)}
                    selectableRows
                    dataKey="item"
                    emptyMessage="Nenhum Item cadastrado"
                  >
                    {fillColumns(columnsSetupItemRastro)}
                  </DataTable>
                </div>
                <br />
                <div className="row form-elo">
                  <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button
                      disabled={disableEdicaoEnviada}
                      type="button"
                      title="Inclusão Item Rastro"
                      className="btn btn-success"
                      style={{ borderRadius: '15px' }}
                      onClick={() => {
                        setEditandoItemRastro(0);
                        limparCamposItemRastro(true);
                        setShowCadastroItemRastro(true);
                      }}
                    >
                      <i className="pi pi-plus"></i>
                    </button>
                    <button
                      disabled={disableEdicaoEnviada}
                      type="button"
                      title="Alterar Item Rastro"
                      className="btn btn-warning"
                      style={{ borderRadius: '15px' }}
                      onClick={(e) => handleEditItemRastro(e)}
                    >
                      <i className="pi pi-pencil"></i>
                    </button>
                    <button
                      disabled={disableEdicaoEnviada}
                      type="button"
                      title="Cancelar Item Rastro"
                      className="btn btn-danger"
                      style={{ borderRadius: '15px' }}
                      onClick={(e) => handleDeleteItemRastro(e)}
                    >
                      <i className="pi pi-trash"></i>
                    </button>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="DI">
                <div className="estamparia-pedido-item">
                  <DataTable
                    value={listaItemDi}
                    size="small"
                    stripedRows
                    responsiveLayout="scroll"
                    sortField="item"
                    selectionMode="single"
                    selection={selectedItemDis}
                    onSelectionChange={e => setSelectedItemDis(e.value)}
                    selectableRows
                    dataKey="item"
                    emptyMessage="Nenhum Item cadastrado"
                  >
                    {fillColumns(columnsSetupItemDi)}
                  </DataTable>
                </div>
                <br />
                <div className="row form-elo">
                  <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button
                      disabled={disableEdicaoEnviada}
                      type="button"
                      title="Inclusão DI"
                      className="btn btn-success"
                      style={{ borderRadius: '15px' }}
                      onClick={() => {
                        setEditandoItemDi(0);
                        limparCamposItemDi(true);
                        setShowCadastroItemDi(true);
                      }}
                    >
                      <i className="pi pi-plus"></i>
                    </button>
                    <button
                      disabled={disableEdicaoEnviada}
                      type="button"
                      title="Alterar DI"
                      className="btn btn-warning"
                      style={{ borderRadius: '15px' }}
                      onClick={(e) => handleEditItemDi(e)}
                    >
                      <i className="pi pi-pencil"></i>
                    </button>
                    <button
                      disabled={disableEdicaoEnviada}
                      type="button"
                      title="Cancelar DI"
                      className="btn btn-danger"
                      style={{ borderRadius: '15px' }}
                      onClick={(e) => handleDeleteItemDi(e)}
                    >
                      <i className="pi pi-trash"></i>
                    </button>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Detalhe Exportação">
                <div className="row form-elo">
                  <div className="estamparia-pedido-item">
                    <DataTable
                      header="Inclusão de Detalhe Exportação"
                      value={listaItemDetExport}
                      size="small"
                      stripedRows
                      responsiveLayout="scroll"
                      sortField="item"
                      selectionMode="single"
                      selection={selectedItemDetExport}
                      onSelectionChange={e => setSelectedItemDetExport(e.value)}
                      selectableRows
                      dataKey="item"
                      emptyMessage="Nenhum Item cadastrado"
                    >
                      {fillColumns(columnsSetupItemDetExport)}
                    </DataTable>
                  </div>
                  <br />
                  <div className="row form-elo">
                    <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <button
                        type="button"
                        title="Inclusão Detalhe Exportação"
                        className="btn btn-success"
                        style={{ borderRadius: '15px' }}
                        onClick={() => {
                          setEditandoItemDetExport(0);
                          limparCamposItemDetExport(true);
                          setShowCadastroItemDetExport(true);
                        }}
                      >
                        <i className="pi pi-plus"></i>
                      </button>
                      <button
                        type="button"
                        title="Alterar Detalhe Exportação"
                        className="btn btn-warning"
                        style={{ borderRadius: '15px' }}
                        onClick={(e) => handleEditItemDetExport(e)}
                      >
                        <i className="pi pi-pencil"></i>
                      </button>
                      <button
                        type="button"
                        title="Cancelar Detalhe Exportação"
                        className="btn btn-danger"
                        style={{ borderRadius: '15px' }}
                        onClick={(e) => handleDeleteItemDetExport(e)}
                      >
                        <i className="pi pi-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel header="Armamento">
                <div className="row form-elo">
                  <div className="col-6">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Tipo Armamento
                      </Input155px>
                      <Dropdown
                        disabled={disableEdicaoEnviada}
                        options={listaItemTpArma}
                        value={itemArmaTpArma}
                        emptyMessage="Nenhum registro a ser exibido"
                        onChange={(e) => setItemArmaTpArma(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Número Série*
                      </Input155px>
                      <InputText
                        disabled={disableEdicaoEnviada}
                        maxLength={15}
                        value={itemArmaNSerie}
                        onChange={e => setItemArmaNSerie(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Número Cano*
                      </Input155px>
                      <InputText
                        disabled={disableEdicaoEnviada}
                        maxLength={15}
                        value={itemArmaNCano}
                        onChange={e => setItemArmaNCano(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-12">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Descrição da Arma*
                      </Input155px>
                      <InputText
                        disabled={disableEdicaoEnviada}
                        maxLength={255}
                        value={itemArmaDescr}
                        onChange={e => setItemArmaDescr(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
            </TabView>
          </>
        ) : ('')}
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              // title="Confirma Dados do Item"
              onClick={handleOkItem}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroItem(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <div className="row form-elo">
          <div className="col-2">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Modelo*
              </Input155px>
              <InputText
                minLength={2}
                maxLength={2}
                value={mod}
                onChange={e => setMod(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Série*
              </Input155px>
              <InputNumber
                disabled={disableEdicaoEnviada}
                onFocus={(e) => e.target.select()}
                maxFractionDigits={0}
                value={serie}
                onValueChange={e => {
                  setSerie(e.target.value);
                  propsTipoMovimentacao !== 'entrada' ? setUltimoNumero(e.target.value) : '';
                }}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Número*
              </Input155px>
              <InputNumber
                disabled={disableEdicaoEnviada}
                onFocus={(e) => e.target.select()}
                maxFractionDigits={0}
                value={nNf}
                onValueChange={e => setNNf(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Emissão*
              </Input155px>
              <Calendar
                disabled={disableEdicaoEnviada}
                id="mask"
                showIcon
                showTime
                hourFormat="24"
                showSeconds
                mask="99/99/9999 99:99:99"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dhEmi}
                onChange={(e) => setDhEmi(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-9">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Cliente*
              </Input155px>
              <InputText
                disabled
                maxLength={255}
                value={xNome}
                onChange={e => setXNome(e.target.value)}
              />
              <Button
                disabled={disableEdicaoEnviada}
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaParticipante(true)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Consumidor Final
              </Input155px>
              <ToggleButton
                disabled={disableEdicaoEnviada}
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={indFinal}
                onChange={(e) => setIndFinal(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Movimentação
              </Input155px>
              <Dropdown
                disabled={disableEdicaoEnviada}
                options={listaTipoMovimentacao}
                value={tpNf}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTpNf(e.target.value)}
              />
            </div>
          </div>
          <div className="col-8">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Natureza da Operação*
              </Input200px>
              <AutoComplete
                disabled={disableEdicaoEnviada}
                value={natOp}
                minLength={1}
                maxLength={60}
                suggestions={filteredNatOp}
                completeMethod={searchNatOp}
                field="label"
                dropdown
                forceSelection
                onChange={(e) => setNatOp(e.target.value)}
              />
              <Button
                disabled={disableEdicaoEnviada}
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaNaturezaOperacao(true)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Código Situação
              </Input155px>
              <Dropdown
                disabled={disableEdicaoEnviada}
                options={listaCodigoSituacao}
                value={codSit}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setCodSit(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Finalidade
              </Input200px>
              <Dropdown
                disabled={disableEdicaoEnviada}
                options={listaFinalidadeEmissao}
                value={finNfe}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setFinNfe(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Desconto à Ratear
              </Input200px>
              <InputNumber
                disabled={disableEdicaoEnviada}
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorDescontoRatear}
                onValueChange={e => setValorDescontoRatear(e.target.value)}
              />
              <Button
                disabled={disableEdicaoEnviada}
                icon="pi pi-refresh"
                className="p-button-success"
                onClick={() => handleRatearDescontoItens()}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          { /* <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Modo Contingência
              </Input200px>
              <Dropdown
                options={listaModoContingencia}
                value={tpEmis}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTpEmis(e.target.value)}
              />
            </div>
          </div> */}
          <div className="col-8">

          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Valor Total Produtos
              </Input200px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorTotalItens}
                onValueChange={e => setValorTotalItens(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div>
          <div className="estamparia-pedido-item">
            <DataTable
              header="Produtos"
              value={listaItem}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              sortField="item"
              selectionMode="single"
              selection={selectedItens}
              onSelectionChange={e => setSelectedItens(e.value)}
              selectableRows
              dataKey="item"
              emptyMessage="Nenhum Item cadastrado"
            >
              {fillColumns(columnsSetupItem)}
            </DataTable>
          </div>
          <br />
          <div className="row form-elo">
            <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button
                disabled={disableEdicaoEnviada}
                type="button"
                title="Inclusão rápida de Item"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={() => {
                  if (UfParticipante !== '') {
                    setEditandoItem(0);
                    limparCamposItem(true);
                    setShowCadastroItem(true);
                    setShowCadastroItemCompleto(false);
                  } else {
                    toast.info('Cliente deve ser selecionado antes da inclusão do produto');
                  }
                }}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                disabled={disableEdicaoEnviada}
                type="button"
                title="Inclusão Item"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={() => {
                  if (UfParticipante !== '') {
                    setEditandoItem(0);
                    limparCamposItem(true);
                    setShowCadastroItem(true);
                    setShowCadastroItemCompleto(true);
                  } else {
                    toast.info('Cliente deve ser selecionado antes da inclusão do produto');
                  }
                }}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                type="button"
                title="Alterar Item"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => {
                  handleEditItem(e);
                  setShowCadastroItemCompleto(true);
                }}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                disabled={disableEdicaoEnviada}
                type="button"
                title="Cancelar Item"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDeleteItem(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row float-end">
          <div className="col-lg-12 ">
            <button
              onClick={handleDadosAdicionais}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Continuar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
