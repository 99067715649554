export function obterListaTipoPagamentoNFe() {
  return (
    [
      { label: '01 - Dinheiro', value: 'DINHEIRO' },
      { label: '02 - Cheque', value: 'CHEQUE' },
      { label: '03 - Cartão de Crédito', value: 'CARTAOCREDITO' },
      { label: '04 - Cartão de Débito', value: 'CARTAODEBITO' },
      { label: '05 - Cartão da Loja', value: 'CARTAOLOJA' },
      { label: '10 - Vale Alimentação', value: 'VALEALIMENTACAO' },
      { label: '11 - Vale Refeição', value: 'VALEREFEICAO' },
      { label: '12 - Vale Presente', value: 'VALEPRESENTE' },
      { label: '13 - Vale Combustível', value: 'VALECOMBUSTIVEL' },
      { label: '14 - Duplicata Mercantil', value: 'DUPLICATA' },
      { label: '15 - Boleto Bancário', value: 'BOLETO' },
      { label: '16 - Depósito Bancário', value: 'DEPOSITO' },
      { label: '17 - Pagamento Instântaneo', value: 'PIX' },
      { label: '18 - Transferência Bancária', value: 'TED' },
      { label: '19 - Programa de Fidelidade', value: 'PROGRAMAFIDELIDADE' },
      { label: '20 - Pagamento Instantâneo (PIX) - Estático', value: 'PIXESTATICO' },
      { label: '21 - Crédito em Loja', value: 'CREDITOLOJA' },
      { label: '22 - Pagamento Eletrônico não informado', value: 'PAGAMENTONAOINFORMADO' },
      { label: '90 - Sem Pagamento', value: 'SEMPAGAMENTO' },
      { label: '99 - Outros', value: 'OUTROS' },
    ]
  );
}
