import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors, converteData } from '../../../util/diversos';
import { Input155px, Input200px, Input400px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function Pagamento() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [empresa, setEmpresa] = useState(null);
  const [id, setId] = useState(0);

  const [dataLicenca, setDataLicenca] = useState();
  const [valorMensalidade, setValorMensalidade] = useState(0);
  const [empresaAtiva, setEmpresaAtiva] = useState(false);

  const [tokenShipay, setTokenShipay] = useState(null);
  const [pagamentoStatus, setPagamentoStatus] = useState('pending');
  const [pagamentoOrderId, setPagamentoOrderId] = useState(null);
  const [pagamentoQrCodeTexto, setPagamentoQrCodeTexto] = useState('');
  const [pagamentoTempoExpiracao, setPagamentoTempoExpiracao] = useState(30);
  const [pagamentoIntervaloGetStatus] = useState(2);

  const [showInformacoesPagamento, setShowInformacoesPagamento] = useState(false);
  const [disableBotaoGerarPagamento, setDisableBotaoGerarPagamento] = useState(false);

  const [listaEmpresa, setListaEmpresa] = useState([]);

  async function getDataEmpresa(idEmpresa) {
    try {
      setIsLoading(true);

      const { data } = await axiosAdmin.get(`/empresa/${idEmpresa}`);

      setDataLicenca(converteData(data.dataLicenca, false));
      setValorMensalidade(data.valorMensal);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const getAutenticacao = async () => {
    if (tokenShipay == null) {
      try {
        const response = await axiosAdmin.post('shipay/autenticar');
        const responseToken = response.data.access_token;

        setTokenShipay(responseToken);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }
    }
  };

  useEffect(() => {
    const newTitle = 'Pagamento Mensalidade';

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getEmpresasDoUsuario(idUsuario) {
      try {
        setIsLoading(true);

        const listDropdown = [];
        const { data: _usuario } = await axiosAdmin.get(`/usuario/${idUsuario}`);

        const listaEmpresas = get(_usuario, 'listaEmpresas', []);

        let idEmpresaSelecionar = 0;
        let valorEmpresaSelecionar = 0;
        let empresaIsAtiva = false;

        listaEmpresas.forEach(element => {
          if (element.valorMensal > 0) {
            listDropdown.push({ label: `${element.razaoSocial}  (${element.nomeFantasia})`, value: element.id });

            if (idEmpresaSelecionar === 0 || idEmpresaSelecionar > element.id) {
              idEmpresaSelecionar = element.id;
              valorEmpresaSelecionar = element.valorMensal;
              empresaIsAtiva = element.ativo;
            }
          }
        });

        setListaEmpresa(listDropdown);
        setEmpresa(idEmpresaSelecionar);
        setValorMensalidade(valorEmpresaSelecionar);
        setEmpresaAtiva(empresaIsAtiva);

        if (idEmpresaSelecionar > 0) {
          getDataEmpresa(idEmpresaSelecionar);
        }
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    async function getData() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const params = `/configuracaosistema?idUsuario=${idUsuario}`;
        const { data } = await axiosAdmin.get(params);
        setId(data.id);

        getEmpresasDoUsuario(idUsuario);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, navigate]);

  useEffect(() => {
    const setarTokenShipay = () => {
      getAutenticacao();
    };

    setarTokenShipay(tokenShipay);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenShipay]);

  const handleSelecionarEmpresa = (e) => {
    setEmpresa(e);

    if (e > 0) {
      getDataEmpresa(e);
    }
  };

  // eslint-disable-next-line consistent-return
  const getPagamentoStatus = async (idPagamento) => {
    try {
      const shippingData = {
        access_token: tokenShipay,
      };

      const responseOrder = await axiosAdmin.post(`/shipay/pagamentostatus/${idPagamento}`, shippingData);
      const json = JSON.parse(responseOrder.data.body);

      return json.status;
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const deletePagamento = async (idPagamento) => {
    try {
      const shippingData = {
        access_token: tokenShipay,
      };

      await axiosAdmin.post(`/shipay/deletarpagamento/${idPagamento}`, shippingData);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const gerarPagamento = async () => {
    try {
      const shippingData = {
        access_token: tokenShipay,
      };

      return await axiosAdmin.post(`/shipay/gerarpagamento/${empresa}`, shippingData);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const inicializaCampos = async () => {
    setPagamentoOrderId(null);
    setPagamentoQrCodeTexto('');
    setPagamentoTempoExpiracao(pagamentoTempoExpiracao);
    setPagamentoStatus(null);

    setShowInformacoesPagamento(false);
    setDisableBotaoGerarPagamento(true);
  };

  // eslint-disable-next-line consistent-return
  const handleGerarPagamento = async () => {
    if (valorMensalidade <= 0) {
      toast.info('Valor da mensalidade inválido');
      return false;
    }

    if (empresa === null) {
      toast.info('Empresa não selecionada');
      return false;
    }

    if (!empresaAtiva) {
      toast.info('Empresa desativada. Geração de pagamento não permitido');
      return false;
    }

    try {
      setIsLoading(true);

      // eslint-disable-next-line consistent-return
      await axiosAdmin.get(`elorecebimento/obterporempresaemaberto/${empresa}`).then(async recebimento => {
        await gerarPagamento().then(pagamentoGerado => {
          setShowInformacoesPagamento(true);
          setDisableBotaoGerarPagamento(true);

          // console.log('pagamentoGerado: ', pagamentoGerado);

          setPagamentoStatus(pagamentoGerado.data.status);
          setPagamentoOrderId(pagamentoGerado.data.order_id);
          setPagamentoQrCodeTexto(pagamentoGerado.data.qr_code_text);

          let tempo = pagamentoTempoExpiracao;
          let newStatus = '';
          const timerGetStatus = setInterval(async () => {
            tempo = tempo - pagamentoIntervaloGetStatus;

            newStatus = await getPagamentoStatus(pagamentoGerado.data.order_id);

            if (newStatus !== 'approved') {
              setPagamentoStatus(newStatus);
              setPagamentoTempoExpiracao(tempo);
            } else {
              tempo = pagamentoTempoExpiracao;
              inicializaCampos();
              clearInterval(timerGetStatus);

              await axiosAdmin.put(`elorecebimento/atualizarviapagamentopix/${recebimento.data.id}`).then(() => {
                toast.info('Pagamento Realizado com sucesso. Nova Licença ativada');
              }).catch(() => {
                toast.info('Não foi possível efetuar o pagamento. Tente mais tarde');
              });
              setDisableBotaoGerarPagamento(false);
            }
          }, pagamentoIntervaloGetStatus * 1000);

          setTimeout(() => {
            clearInterval(timerGetStatus);

            if (newStatus === 'pending') {
              deletePagamento(pagamentoGerado.data.order_id);

              setPagamentoStatus(null);
              setShowInformacoesPagamento(false);
              setDisableBotaoGerarPagamento(false);
              setPagamentoTempoExpiracao(pagamentoTempoExpiracao);
            }
          }, pagamentoTempoExpiracao * 1000);
        }).catch(() => {

        });
      });
    } catch (err) {
      // setDisableBotaoGerarPagamento(false);
      toast.warning('Recebimento estará disponível no mês do vencimento da mensalidade');
    } finally {
      // setOrderId(orderId);
      setDisableBotaoGerarPagamento(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const atualizar = () => {
      const newId = pagamentoOrderId;
      const newTempo = pagamentoTempoExpiracao;
      const newStatus = pagamentoStatus;

      setPagamentoOrderId(newId);
      setPagamentoStatus(newStatus);
      setPagamentoTempoExpiracao(newTempo);
    };

    atualizar();
  }, [pagamentoStatus, pagamentoOrderId, pagamentoTempoExpiracao]);

  function copyToClipboard(qrCodecopy) {
    navigator.clipboard.writeText(qrCodecopy);
    toast.success('Texto Qrcode copiado!');
  }

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
            <div className="col-lg-9">
              <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
                <h6>Pagamento Mensalidade</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-lg-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Empresa
              </Input155px>
              <Dropdown
                options={listaEmpresa}
                value={empresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => handleSelecionarEmpresa(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Licença
              </Input155px>
              <Calendar
                disabled
                id="mask"
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showOnFocus={false}
                value={dataLicenca}
                onChange={(e) => setDataLicenca(e.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Valor Mensalidade
              </Input200px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorMensalidade}
                onValueChange={e => setValorMensalidade(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <button
                disabled={disableBotaoGerarPagamento}
                onClick={handleGerarPagamento}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Gerar Pagamento
              </button>
            </div>
          </div>
        </div>
        <div className="row form-elo">
          { showInformacoesPagamento ? (
            <>
              <br />
              <div className="col-10">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Texto para pagamento
                  </Input200px>
                  <InputText
                    disabled
                    type="text"
                    value={pagamentoQrCodeTexto}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="p-inputgroup">
                  <Button
                    type="Button"
                    className="p-button-info"
                    onClick={() => copyToClipboard(pagamentoQrCodeTexto)}
                  >
                    <i className="pi pi-clone"> </i>
                    &nbsp; Copiar texto
                  </Button>
                </div>
              </div>
              <div className="col-5">
                <div className="p-inputgroup">
                  <center>
                    <br />
                    <QRCode value={pagamentoQrCodeTexto} />
                    <br />
                  </center>
                </div>
              </div>
              <div className="col-7">
                <div className="p-inputgroup">
                  <p style={{ fontSize: '16px' }}> Opção 1: <br /> Leia o QrCode pelo aplicativo de pagamento</p>
                </div>
                <div className="p-inputgroup">
                  <br />
                  <p style={{ fontSize: '16px' }}> Opção 2: <br /> Copie o texto de pagamento e abra o aplicativo de pagamento</p>
                </div>
                <div className="p-inputgroup">
                  <Input400px className="p-inputgroup-addon" style={{ fontSize: '16px' }}>
                    Pagamento expira em {pagamentoTempoExpiracao} segundos
                  </Input400px>
                </div>
              </div>
            </>
          ) : ('')}
        </div>
      </Card>
    </div>
  );
}
