import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Password } from 'primereact/password';
import { get } from 'lodash';

import * as actions from '../../../store/modules/auth/actions';

import Card from '../../../components/card';
import store from '../../../store/store';
import Loading from '../../loading';
import axiosAdmin from '../../../services/axiosAdmin';

import { displayErrors } from '../../../util/diversos';
import { Input155px } from '../../../styles/inputGroup';

export default function AlterarSenha() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [senhaAtual, setSenhaAtual] = useState('');
  const [senha, setSenha] = useState('');
  const [senhaRepeticao, setSenhaRepeticao] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const newTitle = 'Usuário - Alterar Senha';

    setEmail(get(store.getState().auth.user, 'email', ''));

    dispatch(actions.titleHeaderChange({ newTitle }));
  }, [dispatch]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const shippingData = {
        email,
        senhaAtual,
        senha,
      };

      await axiosAdmin.post('/usuario/alterarsenha', shippingData);

      toast.success('Senha alterada com sucesso');

      navigate('/');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar a senha do usuário');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!senhaAtual) {
      formErros = true;
      toast.error('Senha atual deve ser informada');
    }
    if (senha.length < 3) {
      formErros = true;
      toast.error('Nova Senha deve ter no mínimo 3 caracteres');
    }
    if (senha !== senhaRepeticao) {
      formErros = true;
      toast.error('Senhas digitadas não conferem');
    }

    if (formErros) return;

    salvarRegistro();
  };

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>Usuário - Alteração de Senha</h6>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Senha Atual
              </Input155px>
              <Password
                id="inputSenhaAtual"
                toggleMask
                weakLabel="Fraca"
                mediumLabel="Média"
                strongLabel="Forte"
                value={senhaAtual}
                onChange={e => setSenhaAtual(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nova Senha
              </Input155px>
              <Password
                id="inputSenha"
                toggleMask
                weakLabel="Fraca"
                mediumLabel="Média"
                strongLabel="Forte"
                value={senha}
                onChange={e => setSenha(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Repita a Senha
              </Input155px>
              <Password
                id="inputSenhaRepeticao"
                toggleMask
                weakLabel="Fraca"
                mediumLabel="Média"
                strongLabel="Forte"
                value={senhaRepeticao}
                onChange={e => setSenhaRepeticao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
