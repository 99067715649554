import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors, converteData, formataDataMoment } from '../../../util/diversos';
import { Input100px, Input155px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function CadastroFluxoCaixa({ payloadCadastro, childToParentCadastro }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);
  const cnpjEmpresaPayload = get(payloadCadastro, 'cnpjEmpresa', '');

  const [listaEmpresa, setListaEmpresa] = useState([]);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [dataEmissao, setDataEmissao] = useState(null);
  const [valorCredito, setValorCredito] = useState(0);
  const [valorDebito, setValorDebito] = useState(0);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let newTitle = 'Fluxo Caixa - Cadastro';
    if (id > 0) {
      newTitle = 'Fluxo Caixa - Edição';
    }

    let mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getEmpresas() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        _listaEmpresas.forEach(element => {
          const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

          listDropdown.push({ label: nomeEmpresa, value: element.cnpj });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresa(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    getEmpresas();

    if (id <= 0) {
      setDataEmissao(new Date());
      setCnpjEmpresa(cnpjEmpresaPayload);
      return;
    }

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/fluxocaixa/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          displayErrors('Acesso negado', dispatch, navigate, '/consultamovimentacaofinanceira', '');
        }

        const _data = converteData(data.data);

        setCnpjEmpresa(data.cnpjEmpresa.trim());
        setDataEmissao(_data);
        setValorCredito(data.valorCredito);
        setValorDebito(data.valorDebito);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let _data = null;
      if (dataEmissao) {
        _data = formataDataMoment(dataEmissao, '-');
      }

      const shippingData = {
        idUsuario,
        cnpjEmpresa,
        data: _data,
        valorCredito,
        valorDebito,
        acertoSaldo: true,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/fluxocaixa/${id}`, shippingData), 'data', {});
        toast.success('Fluxo Caixa atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/fluxocaixa', shippingData), 'data', {});
        toast.success('Fluxo Caixa cadastrado com sucesso');
      }

      childToParentCadastro(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentCadastro();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!cnpjEmpresa) {
      formErros = true;
      toast.error('Empresa deve ser informada');
    }
    if (!dataEmissao) {
      formErros = true;
      toast.error('Data deve ser informada');
    }
    if (valorCredito < 0) {
      formErros = true;
      toast.error('Valor de Crédito não pode ser negativo');
    }
    if (valorDebito < 0) {
      formErros = true;
      toast.error('Valor de Débito não pode ser negativo');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id > 0 ? 'Fluxo Caixa - Alteração' : 'Fluxo Caixa - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h5>{titulo()}</h5>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-lg-12">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Empresa
              </Input100px>
              <Dropdown
                disabled
                options={listaEmpresa}
                value={cnpjEmpresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setCnpjEmpresa(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data
              </Input100px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataEmissao}
                onChange={(e) => setDataEmissao(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Crédito
              </Input155px>
              <InputNumber
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorCredito}
                onValueChange={e => setValorCredito(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Débito
              </Input155px>
              <InputNumber
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorDebito}
                onValueChange={e => setValorDebito(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
