import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { get, orderBy } from 'lodash';

import 'react-toastify/dist/ReactToastify.css';

import Modal from 'react-bootstrap/Modal';

import { displayErrors } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';
import { Input100px, InputCampoBusca100px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosAdmin from '../../../services/axiosAdmin';

export default function ConsultaUsuario() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [dataTableList, setDataTableList] = useState(null);
  const [usuarioLogAcessoList, setUsuarioLogAcessoList] = useState([]);
  const [usuarioLogAcessoTotalRecords, setUsuarioLogAcessoTotalRecords] = useState(0);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findField, setFindField] = useState('');
  const [sortField, setSortField] = useState('nome');
  const [sortOrder, setSortOrder] = useState(null);
  const [columnsSetup] = useState([
    { field: 'id', header: 'ID', sort: false },
    { field: 'nome', header: 'Nome', sort: true },
    { field: 'email', header: 'E-Mail', sort: true },
    { field: 'tipoUsuario', header: 'Tipo Usuário', sort: false },
    { field: 'senhaSync', header: 'Senha Retaguarda', sort: false },
    // { field: 'ativo', header: 'Ativo', sort: false, booleano: true },
  ]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  const [modalUsuarioLogAcessoShow, setModalUsuarioLogAcessoShow] = useState(false);

  async function getUsuarioLogAcesso(idUsuario, page, size) {
    try {
      setIsLoading(true);

      let params = `/usuariologacesso/search?idUsuario=${idUsuario}`;

      const _dataInicial = '01-01-1970 00:00:01';

      const _dataFinal = '31-12-2099 23:59:59';

      params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setUsuarioLogAcessoList(response.data.content);
      setUsuarioLogAcessoTotalRecords(response.data.totalElements);
    } finally {
      setIsLoading(false);
    }
  }

  const handleHideModalUsuarioLogAcesso = () => {
    setModalUsuarioLogAcessoShow(false);
  };

  const onPageUsuarioLogAcesso = (event) => {
    setLazyParams(event);

    const idUsuario = get(selectedItens, 'id', 0);

    getUsuarioLogAcesso(idUsuario, event.page, event.rows);
  };

  async function getData(searchFilter) {
    try {
      setIsLoading(true);

      let params = '/usuario';

      if (searchFilter && searchFilter.findField !== '') {
        params = `${params}?${sortField}=${searchFilter.findField}`;
      }

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = {
      findField,
    };

    setFindField('');

    getData(searchFilter);
  };

  useEffect(() => {
    getData('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleRegister = () => {
    navigate('/cadastrousuario');
  };

  const handleEdit = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      navigate(`/cadastrousuario/${id}`);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/usuario/${idExclusao}`);

        const _itens = dataTableList.filter(val => val.id !== idExclusao);
        setDataTableList(_itens);

        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function ModalUsuarioLogAcesso(props) {
    const [usuarioLogAcessoColumnsSetup] = useState([
      { field: 'dataHoraAcesso', header: 'Data/Hora', sort: false, dataHora: true },
      { field: 'email', header: 'email', sort: false },
      { field: 'autorizado', header: 'Autorizado', sort: false, booleano: true },
      { field: 'motivo', header: '', sort: false },
    ]);

    if (!props.show) {
      return null;
    }

    const headerTable = (title) => (
      <div className="table-header">
        {title}
      </div>
    );

    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" dialogClassName="main-modal">
        <Modal.Body className="show-grid">
          <div className="row form-elo">
            <div className="col-12">
              <DataTable
                header={headerTable('Log de Acesso do Usuário')}
                value={usuarioLogAcessoList}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                selectionMode="single"
                selectableRows
                dataKey="id"
                paginator
                lazy
                first={lazyParams.first}
                rows={lazyParams.rows}
                totalRecords={usuarioLogAcessoTotalRecords}
                onPage={onPageUsuarioLogAcesso}
                emptyMessage="Nenhum registro a ser exibido"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{currentPage} de {totalPages}"
                rowsPerPageOptions={[10, 25, 50]}
              >
                {fillColumns(usuarioLogAcessoColumnsSetup)}
              </DataTable>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleHideModalUsuarioLogAcesso}
            type="button"
            className="btn btn-primary"
          >
            <i className="pi pi-times"></i>
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  const handleViewUsuarioLogAcesso = (e) => {
    e.preventDefault();

    if (!selectedItens) {
      toast.warning('Por favor, selecione o registro para visualização do Histórico de Acessos do Usuário');
    } else {
      const idUsuario = get(selectedItens, 'id', 0);

      if (idUsuario > 0) {
        getUsuarioLogAcesso(idUsuario, 0, lazyParams.rows);
        setModalUsuarioLogAcessoShow(true);
      } else {
        toast.warning('Empresa selecionada não possui histórico de acessos');
      }
    }
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <ModalUsuarioLogAcesso
        show={modalUsuarioLogAcessoShow}
        onHide={handleHideModalUsuarioLogAcesso}
        dialogClassName="modal-90w"
      />

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>Usuários</h6>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Buscar por:
                </Input100px>
                <InputCampoBusca100px className="p-inputgroup-addon">
                  {columnsSetup.find(element => element.field === sortField).header}
                </InputCampoBusca100px>
                <InputText
                  maxLength={250}
                  value={findField}
                  onChange={e => setFindField(e.target.value)}
                />
              </div>
            </div>
            <div className="col">
              <button
                type="button"
                title="Buscar"
                style={{ borderRadius: '15px' }}
                className="btn btn-success"
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                type="button"
                title="Log de Acesso do Usuário"
                className="btn btn-secondary"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleViewUsuarioLogAcesso(e)}
              >
                <i className="pi pi-book"></i>
              </button>
              <button
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>

            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            selectionMode="single"
            stripedRows
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            dataKey="id"
            paginator
            rows={10}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
