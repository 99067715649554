/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { get, orderBy } from 'lodash';

import dateFormat from 'dateformat';

import 'react-toastify/dist/ReactToastify.css';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors, converteData } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';
import { Input100px, Input155px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';

import CadastroTicket from './cadastroTicket';

import ConsultaParticipante from '../../administrador/empresa/consultaEmpresa';

export default function ConsultaTicket({ somenteLeitura = false }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [permitirFechar, setPermitirFechar] = useState(true);
  const [permitirCadastrar, setPermitirCadastrar] = useState(true);
  const [permitirEditar, setPermitirEditar] = useState(true);
  const [permitirExcluir, setPermitirExcluir] = useState(true);

  const idUsuario = get(store.getState().auth.user, 'id', '');
  const tipoUsuario = get(store.getState().auth.user, 'tipoUsuario', '');

  const [totalRecords, setTotalRecords] = useState(0);
  const [dataTableList, setDataTableList] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showFecharConfirmDialog, setShowFecharConfirmDialog] = useState(false);
  const [filtroAtualizado, setFiltroAtualizado] = useState(null);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findDataInicial, setFindDataInicial] = useState(new Date('01-01-2000'));
  const [findDataFinal, setFindDataFinal] = useState(new Date());
  const [findSituacao, setFindSituacao] = useState('ABERTO');
  const [findTipo, setFindTipo] = useState('TODOS');
  const [sortField, setSortField] = useState('dataAbertura');
  const [sortOrder, setSortOrder] = useState(null);

  const [showCadastro, setShowCadastro] = useState(false);
  const [payloadCadastro, setPayloadCadastro] = useState({});

  const [findIdEmpresa, setFindIdEmpresa] = useState(null);
  const [nomeEmpresa, setNomeEmpresa] = useState('');
  const [showConsultaParticipante, setShowConsultaParticipante] = useState(false);

  const [columnsSetup] = useState([
    { field: 'id', header: 'Número', sort: true },
    { field: 'dataAbertura', header: 'Abertura', sort: true, dataHora: true },
    { field: 'dataFechamento', header: 'Fechamento', sort: true, dataHora: true },
    { field: 'empresa.nomeFantasia', header: 'Empresa', sort: true },
    { field: 'modulo.descricao', header: 'Módulo', sort: true },
    { field: 'descricao', header: 'Descrição', sort: true },
    { field: 'tipo', header: 'Tipo', sort: true },
    { field: 'situacao', header: 'Situação', sort: false },
  ]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 1000,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });
  const [listaSituacao] = useState([
    { label: 'Todos', value: 'TODOS' },
    { label: 'Aberto', value: 'ABERTO' },
    { label: 'Andamento', value: 'ANDAMENTO' },
    { label: 'Fechado', value: 'FECHADO' },
  ]);

  const [listaTipo] = useState([
    { label: 'Todos', value: 'TODOS' },
    { label: 'Indefinido', value: 'INDEFINIDO' },
    { label: 'Suporte Online', value: 'SUPORTEONLINE' },
    { label: 'Visita in loco', value: 'VISITAINLOCO' },
    { label: 'Alteração Sistema', value: 'ALTERACAOSISTEMA' },
  ]);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  async function getData(searchFilter, page = 0, size = 1000) {
    try {
      setIsLoading(true);

      let params = '/ticket/search';

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = `${dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy')} 00:00:00`;
        const _dataFinal = `${dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy')} 23:59:59`;

        params = `${params}?dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      if (searchFilter && searchFilter.findSituacao !== 'TODOS') {
        params = `${params}&situacao=${searchFilter.findSituacao}`;
      }

      if (searchFilter && searchFilter.findTipo !== 'TODOS') {
        params = `${params}&tipo=${searchFilter.findTipo}`;
      }

      if (searchFilter && searchFilter.findIdEmpresa !== null && searchFilter.findIdEmpresa > 0) {
        params = `${params}&idEmpresa=${searchFilter.findIdEmpresa}`;
      }

      if (tipoUsuario === 'EMPRESA') {
        params = `${params}&idUsuario=${idUsuario}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      const tickets = orderBy(response.data.content, 'id', 'asc');

      tickets.forEach(element => {
        if (element.descricao.trim().length > 50) {
          element.descricao = element.descricao.substring(0, 50) + '...';
        }
        if (element.tipo === 'SUPORTEONLINE') {
          element.tipo = 'Suporte Online';
        }
        if (element.tipo === 'ALTERACAOSISTEMA') {
          element.tipo = 'Alteração Sistema';
        }
        if (element.tipo === 'INDEFINIDO') {
          element.tipo = 'Indefinido';
        }
        if (element.tipo === 'VISITAINLOCO') {
          element.tipo = 'Visita in loco';
        }
        if (element.situacao === 'ABERTO') {
          element.situacao = 'Aberto';
        }
        if (element.situacao === 'ANDAMENTO') {
          element.situacao = 'Andamento';
        }
        if (element.situacao === 'FECHADO') {
          element.situacao = 'Fechado';
        }
      });

      setDataTableList(tickets);
      setTotalRecords(tickets.length);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal, findSituacao, findTipo, findIdEmpresa };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, 0, lazyParams.rows);
  };

  const getDataPrimeiroTicketEmAberto = async () => {
    const response = await axiosAdmin.get('/ticket/dataprimeiroticketemaberto');
    if (response.data !== '') {
      setFindDataInicial(converteData(response.data, false));
    } else {
      setFindDataInicial(new Date(store.getState().auth.consultaEstampariaPedidoDataInicial));
    }
  };

  useEffect(() => {
    const operadorGrupoAcesso = get(store.getState().auth.user, 'operadorGrupoAcesso', null);

    if (operadorGrupoAcesso != null) {
      let listaGrupoAcessoBody = [];
      listaGrupoAcessoBody = operadorGrupoAcesso.listaGrupoAcessoBody;
      const permissaoFechar = listaGrupoAcessoBody.filter(el => el.modulo === 'FecharTicket');
      const permissaoCadastrar = listaGrupoAcessoBody.filter(el => el.modulo === 'CadastrarTicket');
      const permissaoEditar = listaGrupoAcessoBody.filter(el => el.modulo === 'EditarTicket');
      const permissaoExcluir = listaGrupoAcessoBody.filter(el => el.modulo === 'ExcluirTicket');

      if (permissaoFechar.length <= 0) {
        setPermitirFechar(false);
      }

      if (permissaoCadastrar.length <= 0) {
        setPermitirCadastrar(false);
      }

      if (permissaoEditar.length <= 0) {
        setPermitirEditar(false);
      }

      if (permissaoExcluir.length <= 0) {
        setPermitirExcluir(false);
      }
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    async function verificarSeMultiEmpresa(idUsuario, searchFilter) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        await axiosAdmin.get(params);

        getData(searchFilter, 0, lazyParams.rows);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    let searchFilter = '';
    if (findDataInicial === null) {
      getDataPrimeiroTicketEmAberto();

      setFindDataFinal(new Date(store.getState().auth.consultaEstampariaPedidoDataFinal));

      const _dataInicial = new Date(store.getState().auth.consultaEstampariaPedidoDataFinal);

      const _dataFinal = new Date(store.getState().auth.consultaEstampariaPedidoDataFinal);

      searchFilter = { findDataInicial: _dataInicial, findDataFinal: _dataFinal, findSituacao, findTipo, findIdEmpresa };

      verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0), searchFilter);
    }

    if (filtroAtualizado) {
      setFiltroAtualizado(false);
      handleFind();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filtroAtualizado]);

  useEffect(() => {
    handleFind();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line consistent-return
  const handleEdit = (e) => {
    e.preventDefault();

    if (!permitirEditar) {
      toast.info('Usuário não autorizado a editar.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);
    const cnpj = get(selectedItens.empresa, 'cnpj', 0);

    if (id > 0) {
      setPayloadCadastro({ id, cnpj });
      setShowCadastro(true);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleFechar = (e) => {
    e.preventDefault();

    if (!permitirFechar) {
      toast.info('Usuário não autorizado a fechar ticket.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowFecharConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para fechamento');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleDelete = (e) => {
    e.preventDefault();

    if (!permitirExcluir) {
      toast.info('Usuário não autorizado a excluir.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/ticket/${idExclusao}`);

        const searchFilter = { findDataInicial, findDataFinal, findSituacao, findTipo, findIdEmpresa };

        getData(searchFilter, 0, lazyParams.rows);

        setSelectedItens(null);

        setShowConfirmDialog(false);
        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmFechar = async () => {
    try {
      setIsLoading(true);

      const idFechar = get(selectedItens, 'id', 0);

      if (idFechar > 0) {
        await axiosAdmin.put(`/ticket/fechar/${idFechar}`);

        const searchFilter = { findDataInicial, findDataFinal, findSituacao, findTipo, findIdEmpresa };

        getData(searchFilter, 0, lazyParams.rows);

        setSelectedItens(null);

        setShowFecharConfirmDialog(false);
        toast.success('Fechamento do ticket concluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível fechar o ticket');
    } finally {
      setIsLoading(false);
    }
  };

  const cancelFechar = () => {
    setShowFecharConfirmDialog(false);
  };

  const confirmDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  const confirmFecharDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmFechar} />
      <Button label="Não" icon="pi pi-times" onClick={cancelFechar} />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findDataInicial, findDataFinal, findSituacao, findTipo, findIdEmpresa };

    getData(searchFilter, event.page, event.rows);
  };

  // eslint-disable-next-line consistent-return
  const handleRegister = () => {
    if (!permitirCadastrar) {
      toast.info('Usuário não autorizado a cadastrar.');
      return false;
    }
    const id = 0;

    setPayloadCadastro({ id });

    setShowCadastro(true);
  };

  const childToParentConsulta = (payloadDataTableList, id) => {
    if (payloadDataTableList) {
      // eslint-disable-next-line no-param-reassign
      payloadDataTableList.data.descricao = payloadDataTableList.data.descricao.trim().substring(0, 50) + '...';

      if (id > 0) {
        const index = dataTableList.map(e => e.id).indexOf(id);

        if (index >= 0) {
          dataTableList[index] = payloadDataTableList.data;
        }
      } else {
        const _dataTableList = [];
        _dataTableList.push(payloadDataTableList.data);

        setDataTableList(_dataTableList);
      }

      handleFind();
    }

    setShowCadastro(false);
  };

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const onHideConsultaParticipante = () => {
    setShowConsultaParticipante(false);
  };

  const limparParticipanteSelecionado = () => {
    setFindIdEmpresa(null);
    setNomeEmpresa('');
  };

  const childToParentParticipante = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setFindIdEmpresa(e.id);
      }
      if (e.nomeFantasia) {
        setNomeEmpresa(e.nomeFantasia);
      } else if (e.razaoSocial) {
        setNomeEmpresa(e.razaoSocial);
      }
      setShowConsultaParticipante(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroTicket
          payloadCadastro={payloadCadastro}
          childToParentConsulta={childToParentConsulta}
        />
      </Dialog>

      <Dialog
        header="Consulta de Empresas"
        visible={showConsultaParticipante}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaParticipante()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParentParticipante} somenteLeitura="true" />
      </Dialog>

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              TICKETS
            </h6>
          </div>
          <div className="row form-elo">
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Data inicial
                </Input155px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => setFindDataInicial(e.value)}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Data final
                </Input155px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => {
                    setFindDataFinal(e.value);
                    const consultaEstampariaPedidoDataInicial = findDataInicial;
                    const consultaEstampariaPedidoDataFinal = e.value;
                    dispatch(actions.consultaEstampariaPedidoDataInicialChange({ consultaEstampariaPedidoDataInicial, consultaEstampariaPedidoDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Situação
                </Input100px>
                <Dropdown
                  options={listaSituacao}
                  value={findSituacao}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => setFindSituacao(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Tipo
                </Input100px>
                <Dropdown
                  options={listaTipo}
                  value={findTipo}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => setFindTipo(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row form-elo">
            <div className="col-6">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Empresa
                </Input155px>
                <InputText
                  disabled
                  maxLength={255}
                  value={nomeEmpresa}
                  onChange={e => setNomeEmpresa(e.target.value)}
                />
                <Button
                  // eslint-disable-next-line no-unneeded-ternary
                  disabled={tipoUsuario === 'EMPRESA' ? true : false}
                  icon="pi pi-search"
                  className="p-button-warning"
                  onClick={() => setShowConsultaParticipante(true)}
                />
                <Button
                  // eslint-disable-next-line no-unneeded-ternary
                  disabled={tipoUsuario === 'EMPRESA' ? true : false}
                  icon="pi pi-times"
                  className="p-button-danger"
                  onClick={() => limparParticipanteSelecionado()}
                />
              </div>
            </div>
            <div className="col-lg-3">

            </div>
            <div className="col-lg-3  text-end">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Fechar"
                className="btn btn-info"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleFechar(e)}
              >
                <i className="pi pi-check-square"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
              { /* renderBotaoSelect() */}
            </div>
          </div>
        </div>

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50, 1000]}
          >
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>

        <Dialog
          header="Confirmação"
          visible={showFecharConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmFecharDialogFooter}
          onHide={() => setShowFecharConfirmDialog(false)}
        >
          <p>
            Fechar o ticket?
          </p>
        </Dialog>

      </Card>
    </>
  );
}
