import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input155px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function CadastroConversaoCfop({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);

  const [cfopOrigem, setCfopOrigem] = useState('');
  const [cstOrigem, setCstOrigem] = useState('');
  const [cfopDestino, setCfopDestino] = useState('');
  const [cstDestino, setCstDestino] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let newTitle = 'Conversão de CFOP - Cadastro';
    if (id) {
      newTitle = 'Conversão de CFOP - Edição';
    }

    dispatch(actions.titleHeaderChange({ newTitle }));

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/conversaocfop/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultaconversaocfop');
          toast.error('Acesso negado');
        }

        setCfopOrigem(data.cfopOrigem);
        setCstOrigem(data.cstOrigem);
        setCfopDestino(data.cfopDestino);
        setCstDestino(data.cstDestino);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const shippingData = {
        idUsuario,
        cfopOrigem,
        cstOrigem,
        cfopDestino,
        cstDestino,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/conversaocfop/${id}`, shippingData), 'data', {});
        toast.success('Conversão de CFOP atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/conversaocfop', shippingData), 'data', {});
        toast.success('Conversão de CFOP cadastrado com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!cfopOrigem) {
      formErros = true;
      toast.error('CFOP de Origem deve ser informado');
    }
    if (!cstOrigem) {
      formErros = true;
      toast.error('CST de Origem deve ser informado');
    }
    if (!cfopDestino) {
      formErros = true;
      toast.error('CFOP de Destino deve ser informado');
    }
    if (!cstDestino) {
      formErros = true;
      toast.error('CST de Destino deve ser informado');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id ? 'Conversão de CFOP - Alteração' : 'Conversão de CFOP - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>{titulo()}</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CFOP Origem
              </Input155px>
              <InputText
                maxLength={4}
                value={cfopOrigem}
                onChange={e => setCfopOrigem(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CST Origem
              </Input155px>
              <InputText
                maxLength={3}
                value={cstOrigem}
                onChange={e => setCstOrigem(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CFOP Destino
              </Input155px>
              <InputText
                maxLength={4}
                value={cfopDestino}
                onChange={e => setCfopDestino(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CST Destino
              </Input155px>
              <InputText
                maxLength={3}
                value={cstDestino}
                onChange={e => setCstDestino(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
