export function obterListaTipoUsuario() {
  return (
    [
      { label: 'Selecione...', value: '' },
      { label: 'Administrador', value: 'ADMINISTRADOR' },
      { label: 'Empresa', value: 'EMPRESA' },
      { label: 'Contador', value: 'CONTADOR' },
      { label: 'Suporte', value: 'SUPORTE' },
    ]
  );
}
