import moment from 'moment';
import * as types from '../types';
import axiosAdmin from '../../../services/axiosAdmin';

const initialState = {
  isLoggedIn: false,
  token: false,
  user: {},
  idOperador: 0,
  isLoading: false,
  titleHeader: '',
  cnpjEmpresa: '',
  operadorGrupoAcesso: null,
  operadorOficina: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS: {
      const newState = { ...state };
      newState.isLoggedIn = true;
      newState.token = action.payload.token;
      newState.user = action.payload.user;
      newState.idOperador = action.payload.idOperador;
      newState.isLoading = false;
      newState.titleHeader = '';
      newState.cnpjEmpresa = '';
      newState.operadorGrupoAcesso = null;
      newState.operadorOficina = null;
      return newState;
    }

    case types.LOGIN_REQUEST: {
      const _DataInicial = new Date();
      _DataInicial.setHours(0);
      _DataInicial.setMinutes(0);
      _DataInicial.setSeconds(0);

      const _DataFinal = new Date();
      _DataFinal.setHours(23);
      _DataFinal.setMinutes(59);
      _DataFinal.setSeconds(59);

      const _DataHoraInicial = moment(_DataInicial).format();
      const _DataHoraFinal = moment(_DataFinal).format();

      const newState = {
        ...state,
        consultaMovimentacaoFinanceiraDataInicial: _DataHoraInicial,
        consultaMovimentacaoFinanceiraDataFinal: _DataHoraFinal,
        consultaMovimentacaoFinanceiraCnpjEmpresa: '',
        consultaPrevisaoFinanceiraDataInicial: _DataHoraInicial,
        consultaPrevisaoFinanceiraDataFinal: _DataHoraFinal,
        consultaPrevisaoFinanceiraCnpjEmpresa: '',
        consultaMovimentacaoBancariaDataInicial: _DataHoraInicial,
        consultaMovimentacaoBancariaDataFinal: _DataHoraFinal,
        consultaMovimentacaoBancariaContaBancaria: 0,
        consultaLivroCaixaDataInicial: _DataHoraInicial,
        consultaLivroCaixaDataFinal: _DataHoraFinal,
        consultaLivroCaixaCnpjEmpresa: '',
        consultaFluxoCaixaDataInicial: _DataHoraInicial,
        consultaFluxoCaixaDataFinal: _DataHoraFinal,
        consultaFluxoCaixaCnpjEmpresa: '',
        consultaEstampariaPedidoDataInicial: _DataHoraInicial,
        consultaEstampariaPedidoDataFinal: _DataHoraFinal,
        consultaEstampariaRecebimentoPedidoDataInicial: _DataHoraInicial,
        consultaEstampariaRecebimentoPedidoDataFinal: _DataHoraFinal,
        consultaEstampariaOrdemProducaoDataInicial: _DataHoraInicial,
        consultaEstampariaOrdemProducaoDataFinal: _DataHoraFinal,
        consultaEstampariaOrcamentoDataInicial: _DataHoraInicial,
        consultaEstampariaOrcamentoDataFinal: _DataHoraFinal,
      };

      newState.isLoading = true;
      return newState;
    }

    case types.LOGIN_FAILURE: {
      delete axiosAdmin.defaults.headers.Authorization;
      const newState = { ...initialState };
      return newState;
    }

    case types.TITLEHEADER_CHANGE: {
      const newState = { ...state };
      newState.titleHeader = action.payload.newTitle;
      return newState;
    }

    case types.CNPJEMPRESA_CHANGE: {
      const newState = { ...state };
      newState.cnpjEmpresa = action.payload.cnpjEmpresa;
      return newState;
    }

    case types.CONSULTAMOVIMENTACAOFINANCEIRADATAINICIAL_CHANGE: {
      const newState = { ...state };
      newState.consultaMovimentacaoFinanceiraDataInicial = action.payload.consultaMovimentacaoFinanceiraDataInicial;
      newState.consultaMovimentacaoFinanceiraDataFinal = action.payload.consultaMovimentacaoFinanceiraDataFinal;
      return newState;
    }

    case types.CONSULTAMOVIMENTACAOFINANCEIRACNPJEMPRESA_CHANGE: {
      const newState = { ...state };
      newState.consultaMovimentacaoFinanceiraCnpjEmpresa = action.payload.consultaMovimentacaoFinanceiraCnpjEmpresa;
      return newState;
    }

    case types.CONSULTAPREVISAOFINANCEIRADATAINICIAL_CHANGE: {
      const newState = { ...state };
      newState.consultaPrevisaoFinanceiraDataInicial = action.payload.consultaPrevisaoFinanceiraDataInicial;
      newState.consultaPrevisaoFinanceiraDataFinal = action.payload.consultaPrevisaoFinanceiraDataFinal;
      return newState;
    }

    case types.CONSULTAPREVISAOFINANCEIRACNPJEMPRESA_CHANGE: {
      const newState = { ...state };
      newState.consultaPrevisaoFinanceiraCnpjEmpresa = action.payload.consultaPrevisaoFinanceiraCnpjEmpresa;
      return newState;
    }

    case types.CONSULTAMOVIMENTACAOBANCARIADATAINICIAL_CHANGE: {
      const newState = { ...state };
      newState.consultaMovimentacaoBancariaDataInicial = action.payload.consultaMovimentacaoBancariaDataInicial;
      newState.consultaMovimentacaoBancariaDataFinal = action.payload.consultaMovimentacaoBancariaDataFinal;
      return newState;
    }

    case types.CONSULTAMOVIMENTACAOBANCARIACONTABANCARIA_CHANGE: {
      const newState = { ...state };
      newState.consultaMovimentacaoBancariaContaBancaria = action.payload.consultaMovimentacaoBancariaContaBancaria;
      return newState;
    }

    case types.CONSULTALIVROCAIXADATAINICIAL_CHANGE: {
      const newState = { ...state };
      newState.consultaLivroCaixaDataInicial = action.payload.consultaLivroCaixaDataInicial;
      newState.consultaLivroCaixaDataFinal = action.payload.consultaLivroCaixaDataFinal;
      return newState;
    }

    case types.CONSULTALIVROCAIXACNPJEMPRESA_CHANGE: {
      const newState = { ...state };
      newState.consultaLivroCaixaCnpjEmpresa = action.payload.consultaLivroCaixaCnpjEmpresa;
      return newState;
    }

    case types.CONSULTAFLUXOCAIXADATAINICIAL_CHANGE: {
      const newState = { ...state };
      newState.consultaFluxoCaixaDataInicial = action.payload.consultaFluxoCaixaDataInicial;
      newState.consultaFluxoCaixaDataFinal = action.payload.consultaFluxoCaixaDataFinal;
      return newState;
    }

    case types.CONSULTAFLUXOCAIXACNPJEMPRESA_CHANGE: {
      const newState = { ...state };
      newState.consultaFluxoCaixaCnpjEmpresa = action.payload.consultaFluxoCaixaCnpjEmpresa;
      return newState;
    }

    case types.CONSULTAESTAMPARIAPEDIDODATAINICIAL_CHANGE: {
      const newState = { ...state };

      newState.consultaEstampariaPedidoDataInicial = action.payload.consultaEstampariaPedidoDataInicial;
      newState.consultaEstampariaPedidoDataFinal = action.payload.consultaEstampariaPedidoDataFinal;

      return newState;
    }

    case types.CONSULTAESTAMPARIARECEBIMENTOPEDIDODATAINICIAL_CHANGE: {
      const newState = { ...state };

      newState.consultaEstampariaRecebimentoPedidoDataInicial = action.payload.consultaEstampariaRecebimentoPedidoDataInicial;
      newState.consultaEstampariaRecebimentoPedidoDataFinal = action.payload.consultaEstampariaRecebimentoPedidoDataFinal;

      return newState;
    }

    case types.CONSULTAESTAMPARIAORDEMPRODUCAODATAINICIAL_CHANGE: {
      const newState = { ...state };
      newState.consultaEstampariaOrdemProducaoDataInicial = action.payload.consultaEstampariaOrdemProducaoDataInicial;
      newState.consultaEstampariaOrdemProducaoDataFinal = action.payload.consultaEstampariaOrdemProducaoDataFinal;
      return newState;
    }

    case types.CONSULTAESTAMPARIAORCAMENTODATAINICIAL_CHANGE: {
      const newState = { ...state };

      newState.consultaEstampariaOrcamentoDataInicial = action.payload.consultaEstampariaOrcamentoDataInicial;
      newState.consultaEstampariaOrcamentoDataFinal = action.payload.consultaEstampariaOrcamentoDataFinal;

      return newState;
    }

    default: {
      return state;
    }
  }
}
