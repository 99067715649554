/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { get, orderBy } from 'lodash';
import { Base64 } from 'js-base64';

import dateFormat from 'dateformat';

import * as actions from '../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { converteData, displayErrors } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';
import { Input100px, Input200px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';

export default function ConsultaNFCe(props) {
  const [isLoading, setIsLoading] = useState(false);

  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [serieEmpresa, setSerieEmpresa] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [ufEmpresa, setUfEmpresa] = useState('');
  const [idSelecionado, setIdSelecionado] = useState('');

  const [empresaTipoAmbiente, setEmpresaTipoAmbiente] = useState('');

  const [motivoCancelamento, setMotivoCancelamento] = useState('');
  const [totalRecords, setTotalRecords] = useState(0);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [dataTableList, setDataTableList] = useState([]);
  const [showConfirmCancelamentoDialog, setShowConfirmCancelamentoDialog] = useState(false);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [sortField, setSortField] = useState('item');
  const [sortOrder, setSortOrder] = useState(null);

  const [columnsSetup] = useState([
    { field: 'dhEnvio', header: 'Data', sort: true, dataHora: true },
    { field: 'serie', header: 'Série', sort: false },
    { field: 'numeroNf', header: 'Número', sort: true },
    { field: 'chaveAcesso', header: 'Chave de Acesso', sort: true },
    { field: 'participante.razaoSocial', header: 'Participante', sort: false },
    { field: 'totalVNF', header: 'Total NFC-e', sort: false },
    { field: 'codSit', header: 'Situação', sort: false },
    { field: 'flag', header: 'Enviada', sort: true },
    { field: 'protocolo', header: 'Protocolo', sort: true },
  ]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getData(searchFilter, filtrarCnpjEmpresa, page, size) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/nfce/search?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy');
        const _dataFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy');

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  };

  useEffect(() => {
    async function verificarSeMultiEmpresa(idUsuario, searchFilter) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        const _cnpjConsultaPrevisaoFinanceiraCjpEmpresa = store.getState().auth.consultaPrevisaoFinanceiraCnpjEmpresa;

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '') {
          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            const consultaPrevisaoFinanceiraCnpjEmpresa = _cnpjEmpresaStore;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            cnpjPreenchido = true;
          }
        }

        _listaEmpresas.forEach(element => {
          if (element.ativo) {
            if (!cnpjPreenchido && (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '')) {
              setCnpjEmpresa(element.cnpj);
              setSerieEmpresa(element.nfeSerie);
              setUfEmpresa(element.uf);
              const consultaPrevisaoFinanceiraCnpjEmpresa = element.cnpj;
              dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
              _cnpjEmpresaStore = element.cnpj;
              cnpjPreenchido = true;
            }

            listDropdown.push({ label: element.razaoSocial.trim(), value: element.cnpj, id: element.id, serie: element.nfeSerie, uf: element.uf, nfeAmbiente: element.nfeAmbiente, nfceambiente: element.nfceAmbiente });
          }
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (!cnpjPreenchido && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== null && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== '') {
          setCnpjEmpresa(_cnpjConsultaPrevisaoFinanceiraCjpEmpresa);
          _cnpjEmpresaStore = _cnpjConsultaPrevisaoFinanceiraCjpEmpresa;
        }

        getData(searchFilter, _cnpjEmpresaStore, 0, lazyParams.rows);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    if (findDataInicial === null) {
      setFindDataInicial(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial));
      setFindDataFinal(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal));

      const _dataInicial = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial);
      const _dataFinal = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal);

      const searchFilter = { findDataInicial: _dataInicial, findDataFinal: _dataFinal };

      verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0), searchFilter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const searchFilter = { findDataInicial, findDataFinal };

    listaEmpresas.forEach(element => {
      if (element.value === cnpjEmpresa) {
        // eslint-disable-next-line no-unused-expressions
        element.nfceAmbiente === 'PRODUCAO' ? setEmpresaTipoAmbiente('Produção') : setEmpresaTipoAmbiente('AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL');
      }
    });
    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpjEmpresa, listaEmpresas]);

  const handleCancelamento = (e) => {
    const enviada = get(e, 'flag', 0);
    setIdSelecionado(get(e, 'id', 0));

    if (enviada !== 'T') {
      toast.warn('Somente Nota enviada pode ser cancelada');
      return;
    }

    const dataHoraAtual = new Date().getTime();
    const dataHoraEnvio = new Date(converteData(get(e, 'dhEnvio', 0), true)).getTime();
    if (dataHoraAtual > (dataHoraEnvio + (30 * 60 * 1000))) {
      toast.warn('Prazo máximo para cancelamento de 30 minutos do envio.');
      return;
    }

    const id = get(e, 'id', 0);

    if (id > 0) {
      setShowConfirmCancelamentoDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para cancelamento');
    }
  };

  const cancelCancelamento = () => {
    setShowConfirmCancelamentoDialog(false);
    setMotivoCancelamento('');
  };

  const confirmCancelamento = async () => {
    if (motivoCancelamento.length < 15) {
      toast.warn('Motivo cancelamento deve conter no mínimo 15 caracteres.');
      return;
    }

    setShowConfirmCancelamentoDialog(false);

    try {
      setIsLoading(true);

      const id = idSelecionado;

      if (id > 0) {
        await axiosAdmin.get(`/nfceutil/cancelarnfce?id=${id}&motivocancelamento=${motivoCancelamento}`);

        toast.success('Cancelamento registrado com sucesso');
        handleFind();
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível cancelar nota fiscal');
    } finally {
      setIsLoading(false);
    }
  };

  const visualizarCupom = (response) => {
    try {
      const byteCharacters = Base64.atob(response.data);

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      const blobFile = new Blob([byteArray], { type: 'application/pdf' });

      const pdf = URL.createObjectURL(blobFile);
      window.open(pdf);
    } catch (e) {
      toast.warn('Erro ao adicionar o pdf ao arquivo .zip');
    }
  };

  const handleVisualizarCupom = async (e) => {
    try {
      setIsLoading(true);

      const id = get(e, 'id', 0);
      const chave = get(e, 'chaveAcesso', 0);

      if (id > 0) {
        await axiosAdmin.get(`/nfceutil/downloaddanfce?chaveacesso=${chave}`).then(res => {
          visualizarCupom(res, chave);
        });
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível baixar os arquivos');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmCancelamentoDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmCancelamento} />
      <Button label="Não" icon="pi pi-times" onClick={cancelCancelamento} />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findDataInicial, findDataFinal };

    getData(searchFilter, cnpjEmpresa, event.page, event.rows);
  };

  // eslint-disable-next-line arrow-body-style
  const actionBodyTemplate = (rowData) => {
    const flag = get(rowData, 'flag', 0);
    const codigoSituacao = get(rowData, 'codSit', 0);

    if ((codigoSituacao !== 'REGULAR' && flag === 'T') || flag === 'I') {
      return ('');
    } else {
      return (
        <div className="row col-12">
          <div className="col-lg-6">
            <button
              type="button"
              title="Cancelar Cupom"
              className="btn-danger rounded outlined"
              style={{ borderRadius: '15px' }}
              onClick={() => handleCancelamento(rowData)}
            >
              <i className="pi pi-trash"></i>
            </button>
          </div>
          <div className="col-lg-6">
            <button
              type="button"
              title="Imprimir Cupom"
              className="btn-dark rounded outlined"
              style={{ borderRadius: '15px' }}
              onClick={() => handleVisualizarCupom(rowData)}
            >
              <i className="pi pi-eye"></i>
            </button>
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    setDataTableList([]);

    const searchFilter = { findDataInicial, findDataFinal };

    listaEmpresas.forEach(element => {
      if (element.value === cnpjEmpresa) {
        // eslint-disable-next-line no-unused-expressions
        element.nfeAmbiente === 'PRODUCAO' ? setEmpresaTipoAmbiente('Produção') : setEmpresaTipoAmbiente('AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL');
      }
    });

    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const renderTipoAmbiente = () => {
    if (empresaTipoAmbiente === 'AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL') {
      return (
        <h6 className="card-header" style={{ textAlign: 'center' }}>
          {empresaTipoAmbiente}
        </h6>
      );
    }
    return '';
  };

  async function licencaValida(empresaId) {
    // eslint-disable-next-line consistent-return
    const retorno = await axiosAdmin.get(`/empresa/verificardatalicenca/${empresaId}`);
    return retorno.data;
  }

  // eslint-disable-next-line consistent-return
  async function handleCnpjEmpresa(e) {
    let empresaId = 0;

    listaEmpresas.forEach(element => {
      if (element.value === e.target.value) {
        // eslint-disable-next-line no-unused-expressions
        empresaId = element.id;
      }
    });

    const isLicencaValida = await licencaValida(empresaId);
    if (!isLicencaValida) {
      toast.info('Cliente com licença expirada. Efetuar pagamento mensalidade.');
      // navigate('/pagamento');
      return false;
    }

    setCnpjEmpresa(e.target.value);
    const consultaPrevisaoFinanceiraCnpjEmpresa = e.target.value;
    dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
  }

  return (
    <>
      <Loading isLoading={isLoading} />

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              Nota Fiscal ao Consumidor Eletrônica
            </h6>
            {renderTipoAmbiente()}

          </div>
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data inicial
                </Input100px>
                <Calendar
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => {
                    setFindDataInicial(e.value);
                    const consultaPrevisaoFinanceiraDataInicial = e.value;
                    const consultaPrevisaoFinanceiraDataFinal = findDataFinal;
                    dispatch(actions.consultaPrevisaoFinanceiraDataInicialChange({ consultaPrevisaoFinanceiraDataInicial, consultaPrevisaoFinanceiraDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data final
                </Input100px>
                <Calendar
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => {
                    setFindDataFinal(e.value);
                    const consultaPrevisaoFinanceiraDataInicial = findDataInicial;
                    const consultaPrevisaoFinanceiraDataFinal = e.value;
                    dispatch(actions.consultaPrevisaoFinanceiraDataInicialChange({ consultaPrevisaoFinanceiraDataInicial, consultaPrevisaoFinanceiraDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              {listaEmpresas.length > 1 ? (
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Empresa
                  </Input100px>
                  <Dropdown
                    options={listaEmpresas}
                    value={cnpjEmpresa}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={(e) => handleCnpjEmpresa(e)}
                  />
                </div>
              ) : ('')}
            </div>
            <div className="col-lg-3">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
            <Column body={actionBodyTemplate} exportable={false} style={{ width: '90px' }}></Column>
          </DataTable>
        </div>

        <Dialog
          header="Confirmação"
          visible={showConfirmCancelamentoDialog}
          style={{ width: '70vw' }}
          footer={confirmCancelamentoDialogFooter}
          onHide={() => setShowConfirmCancelamentoDialog(false)}
        >
          <div className="row form-elo">
            <div className="col-12">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Motivo do Cancelamento
                </Input200px>
                <InputText
                  maxLength={50}
                  value={motivoCancelamento}
                  onChange={e => setMotivoCancelamento(e.target.value)}
                />
              </div>
            </div>
          </div>
          <p>
            Cancelar a Nota Fiscal Selecionada?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
