import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors, converteData, formataDataMoment } from '../../../util/diversos';
import { Input100px, Input155px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function CadastroMovimentacaoBancaria({ payloadCadastro, childToParentCadastro }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);
  const contaBancariaPayload = get(payloadCadastro, 'contaBancaria', '');

  const [listaContasBancarias, setListaContasBancarias] = useState([]);
  const [contaBancaria, setContaBancaria] = useState(0);
  const [dataEmissao, setDataEmissao] = useState(null);
  const [numeroDocumento, setNumeroDocumento] = useState('');
  const [historico, setHistorico] = useState('');
  const [valor, setValor] = useState(0);
  const [operacaoBancaria, setOperacaoBancaria] = useState('CREDITO');
  const [efetivado, setEfetivado] = useState(true);
  const [listaOperacaoBancaria] = useState([
    { label: 'Débito', value: 'DEBITO' },
    { label: 'Crédito', value: 'CREDITO' },
  ]);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let newTitle = 'Movimentação Bancária - Cadastro';
    if (id > 0) {
      newTitle = 'Movimentação Bancária - Edição';
    }

    const mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getContasBancarias() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];

        const { data: _contasbancarias } = await axiosAdmin.get(`/contabancaria?idUsuario=${idUsuario}`);

        _contasbancarias.forEach(element => {
          listDropdown.push({ id: element.id, label: `${element.banco} ${element.bancoDescricao} ${element.agencia} ${element.conta} ${element.descricao}`, value: element.id });
        });

        setListaContasBancarias(listDropdown);
      } catch (err) {
        displayErrors(`Não foi possível acessar a tabela de Contas Bancárias: ${err}`, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getContasBancarias();

    if (id <= 0) {
      setContaBancaria(contaBancariaPayload);
      setDataEmissao(new Date());
      return;
    }

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/movimentacaobancaria/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          displayErrors('Acesso negado', dispatch, navigate, '/consultamovimentacaobancaria', '');
        }

        const _data = converteData(data.data, true);

        setContaBancaria(data.contaBancaria.id);
        setDataEmissao(_data);
        setNumeroDocumento(data.numeroDocumento);
        setHistorico(data.historico);
        setValor(data.valor);
        setOperacaoBancaria(data.operacaoBancaria);
        setEfetivado(data.efetivado);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let _data = null;
      if (dataEmissao) {
        _data = formataDataMoment(dataEmissao, '-', true);
      }

      const shippingData = {
        idUsuario,
        contaBancaria,
        data: _data,
        numeroDocumento,
        historico,
        valor,
        operacaoBancaria,
        efetivado,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/movimentacaobancaria/${id}`, shippingData), 'data', {});
        toast.success('Movimentação Bancária atualizada com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/movimentacaobancaria', shippingData), 'data', {});
        toast.success('Movimentação Bancária cadastrada com sucesso');
      }

      childToParentCadastro(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentCadastro();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!contaBancaria) {
      formErros = true;
      toast.error('Conta Bancária deve ser informada');
    }
    if (!dataEmissao) {
      formErros = true;
      toast.error('Data deve ser informada');
    }
    if (valor <= 0) {
      formErros = true;
      toast.error('Valor deve ser informado');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id > 0 ? 'Movimentação Bancária - Alteração' : 'Movimentação Bancária - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h5>{titulo()}</h5>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-lg-7">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Conta Bancária
              </Input155px>
              <Dropdown
                disabled
                options={listaContasBancarias}
                value={contaBancaria}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setContaBancaria(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Operação
              </Input100px>
              <Dropdown
                autoFocus
                options={listaOperacaoBancaria}
                value={operacaoBancaria}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setOperacaoBancaria(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <div className="field-checkbox">
              <Checkbox
                inputId="binary"
                checked={efetivado}
                onChange={e => setEfetivado(e.checked)}
              />
              <label htmlFor="binary">
                &nbsp;Efetivado
              </label>
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data
              </Input100px>
              <Calendar
                id="mask"
                showIcon
                showTime
                hourFormat="24"
                showSeconds
                mask="99/99/9999 99:99:99"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataEmissao}
                onChange={(e) => setDataEmissao(e.value)}
              />
            </div>
          </div>
          <div className="col-8">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nº Documento
              </Input155px>
              <InputText
                maxLength={100}
                value={numeroDocumento}
                onChange={e => setNumeroDocumento(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-8">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Histórico
              </Input100px>
              <InputText
                maxLength={255}
                value={historico}
                onChange={e => setHistorico(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor
              </Input155px>
              <InputNumber
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valor}
                onValueChange={e => setValor(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
