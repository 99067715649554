import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

import ReactFileReader from 'react-file-reader';

import moment from 'moment';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input200px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function RemessaBoleto() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [anoMes, setAnoMes] = useState(null);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  useEffect(() => {
    const newTitle = 'Remessa de Boleto';

    dispatch(actions.titleHeaderChange({ newTitle }));
  }, [dispatch, navigate]);

  function download(filename, text) {
    const blob = new Blob([text], { type: 'text/plain' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
  }

  async function enviarRemessa(textoArquivo) {
    try {
      setIsLoading(true);

      const params = '/elorecebimento/atualizarretornoremessa';

      axiosAdmin.defaults.headers.post = { 'Content-Type': 'text/plain' };

      await axiosAdmin.post(params, textoArquivo);

      toast.success('Retorno de Remessa de Boleto atualizada com sucesso');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível enviar o arquivo de retorno remessa');
    } finally {
      setIsLoading(false);
    }
  }

  // async function salvarPDF(conteudo, nome) {
  //   await save(conteudo, nome);
  // }

  // async function handleDownloadPDF() {
  //   try {
  //     setIsLoading(true);

  //     const params = '/elorecebimento/downloadpdf';

  //     const response = await axiosAdmin.get(params);

  //     const listFiles = [];

  //     response.data.forEach(element => {
  //       const byteCharacters = Base64.atob(element.conteudo);

  //       const byteNumbers = new Array(byteCharacters.length);
  //       for (let i = 0; i < byteCharacters.length; i++) {
  //         byteNumbers[i] = byteCharacters.charCodeAt(i);
  //       }

  //       const byteArray = new Uint8Array(byteNumbers);

  //       const blobFile = new Blob([byteArray], { type: 'application/pdf' });

  //       listFiles.push(blobFile);

  //       salvarPDF(blobFile, element.nome + '.pdf');
  //     });
  //   } catch (err) {
  //     displayErrors(err, dispatch, navigate, '', 'Não foi possível realizar o download do(s) PDF(s): ' + err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  // async function handleEnviarBoletoEmail(textoArquivo) {
  //   try {
  //     setIsLoading(true);

  //     const params = '/elorecebimento/enviarboletopdf';

  //     await axiosAdmin.get(params, textoArquivo);

  //     toast.success('Envio de Boleto por E-Mail realizada com sucesso');
  //   } catch (err) {
  //     displayErrors(err, dispatch, navigate, '', 'Não foi possível enviar o(s) boleto(s) por e-mail');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  const handleImportarRemessa = files => {
    const reader = new FileReader();
    reader.onload = function () {
      enviarRemessa(reader.result);
    };
    reader.readAsText(files[0]);
  };

  async function gerarRemessaBoleto() {
    try {
      setIsLoading(true);

      const _anoMes = moment(anoMes).format('MMYYYY');

      const response = await axiosAdmin.get(`/elorecebimento/gerarremessaboleto/${_anoMes}`);

      let conteudoArquivo = '';
      let sequencialHeader = '0000000';

      response.data.forEach(element => {
        if (sequencialHeader === '0000000') {
          sequencialHeader = element.substring(111, 117);
        }

        conteudoArquivo = `${conteudoArquivo + element}\n`;
      });

      download('CI400_001_' + ('0000000' + sequencialHeader).slice(-7) + '.rem', conteudoArquivo);

      toast.success('Arquivo remessa gerado com sucesso');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    navigate('/');
  };

  const handleGerarRemessaBoleto = (e) => {
    e.preventDefault();

    const formErros = false;

    if (formErros) return;

    gerarRemessaBoleto();
  };

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
          <div className="row">
            <div className="col-lg-12">
              <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', top: '20%' }}>
                <h6>Remessa Boleto</h6>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Mês/Ano para Geração
              </Input200px>
              <Calendar
                view="month"
                mask="99/9999"
                dateFormat="mm/yy"
                locale="pt-BR"
                yearNavigator
                yearRange="2010:2099"
                value={anoMes}
                onChange={(e) => setAnoMes(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              onClick={handleGerarRemessaBoleto}
              type="button"
              className="btn btn-success"
              style={{ borderRadius: '15px' }}
            >
              <i className="pi pi-check"></i>
              Gerar Arquivo Remessa
            </button>
            &nbsp;&nbsp;&nbsp;
            <ReactFileReader handleFiles={handleImportarRemessa} fileTypes=". ">
              <button
                type="button"
                title="Importar Arquivo Retorno"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
              >
                <i className="pi pi-download"></i>
                Importar Arquivo Retorno
              </button>
            </ReactFileReader>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
              style={{ borderRadius: '15px' }}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
