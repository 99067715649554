/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import ReactFileReader from 'react-file-reader';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { ToggleButton } from 'primereact/togglebutton';
import { Password } from 'primereact/password';
import { InputNumber } from 'primereact/inputnumber';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input100px, Input155px, Input200px, Input400px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function ConfiguracaoSistema() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [empresa, setEmpresa] = useState(null);
  const [id, setId] = useState(0);
  const [desabilitarCadastroProduto, setDesabilitarCadastroProduto] = useState(false);
  const [habilitarAlteracaoEstoqueFilial, setHabilitarAlteracaoEstoqueFilial] = useState(false);
  const [urlLogotipo, setUrlLogotipo] = useState('');
  const [nfeAmbiente, setNfeAmbiente] = useState('PRODUCAO');
  const [nfeSerie, setNfeSerie] = useState('');
  const [balancaDigitosCodigo, setBalancaDigitosCodigo] = useState(5);
  const [balancaPorValor, setBalancaPorValor] = useState(false);
  const [pisCofinsCumulativo, setPisCofinsCumulativo] = useState(false);
  const [aliquotaPis, setAliquotaPis] = useState(0);
  const [aliquotaCofins, setAliquotaCofins] = useState(0);
  const [certificado, setCertificado] = useState(null);
  const [senhaCertificado, setSenhaCertificado] = useState(null);
  const [senhaAdm, setSenhaAdm] = useState(null);
  const [gerarInventario, setGerarInventario] = useState(false);
  const [listaEmpresa, setListaEmpresa] = useState([]);
  const [listaAmbiente] = useState([
    { label: 'Homologação', value: 'HOMOLOGACAO' },
    { label: 'Produção', value: 'PRODUCAO' },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  async function getDataEmpresa(idEmpresa) {
    try {
      setIsLoading(true);

      const { data } = await axiosAdmin.get(`/empresa/${idEmpresa}`);

      setNfeAmbiente(data.nfeAmbiente);
      setNfeSerie(data.nfeSerie);
      setCertificado(data.certificado);
      setSenhaCertificado(data.senhaCertificado);
      setBalancaDigitosCodigo(data.balancaDigitosCodigo);
      setBalancaPorValor(data.balancaPorValor);
      setPisCofinsCumulativo(data.pisCofinsCumulativo);
      setAliquotaPis(data.aliquotaPis);
      setAliquotaCofins(data.aliquotaCofins);
      setGerarInventario(data.gerarInventario);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const newTitle = 'Configuração do Sistema - Edição';

    dispatch(actions.titleHeaderChange({ newTitle }));

    let mostrarMensagemErro = true;

    async function getEmpresasDoUsuario(idUsuario) {
      try {
        setIsLoading(true);

        const listDropdown = [];
        const { data: _usuario } = await axiosAdmin.get(`/usuario/${idUsuario}`);

        const listaEmpresas = get(_usuario, 'listaEmpresas', []);

        let idEmpresaSelecionar = 0;

        listaEmpresas.forEach(element => {
          if (element.ativo) {
            listDropdown.push({ label: `${element.razaoSocial}  (${element.nomeFantasia})`, value: element.id });

            if (idEmpresaSelecionar === 0 || idEmpresaSelecionar > element.id) {
              idEmpresaSelecionar = element.id;
            }
          }
        });

        setListaEmpresa(listDropdown);
        setEmpresa(idEmpresaSelecionar);

        if (idEmpresaSelecionar > 0) {
          getDataEmpresa(idEmpresaSelecionar);
        }
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getData() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const params = `/configuracaosistema?idUsuario=${idUsuario}`;

        const { data } = await axiosAdmin.get(params);

        setId(data.id);
        setDesabilitarCadastroProduto(data.desabilitarCadastroProduto);
        setHabilitarAlteracaoEstoqueFilial(data.habilitarAlteracaoEstoqueFilial);
        setUrlLogotipo(data.urlLogotipo);
        setSenhaAdm(data.senhaAdm);

        getEmpresasDoUsuario(idUsuario);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, navigate]);

  // eslint-disable-next-line consistent-return
  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const tipoAcesso = get(store.getState().auth.user, 'tipoAcesso', null);
      if (tipoAcesso !== 'TOTAL') {
        toast.info('Usuário não autorizado a alterar configurações');
        return false;
      }

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const shippingData = {
        idUsuario,
        desabilitarCadastroProduto,
        habilitarAlteracaoEstoqueFilial,
        balancaDigitosCodigo,
        balancaPorValor,
        pisCofinsCumulativo,
        aliquotaPis,
        aliquotaCofins,
        urlLogotipo,
        idEmpresa: empresa,
        nfeAmbiente,
        nfeSerie,
        certificado,
        senhaCertificado,
        senhaAdm,
        gerarInventario,
      };

      await axiosAdmin.put(`/configuracaosistema/${id}`, shippingData);

      toast.success('Configuração do Sistema atualizada com sucesso');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErros = false;

    if (formErros) return;

    salvarRegistro();
  };

  async function licencaValida(empresaId) {
    // eslint-disable-next-line consistent-return
    const retorno = await axiosAdmin.get(`/empresa/verificardatalicenca/${empresaId}`);
    return retorno.data;
  }

  const handleSelecionarEmpresa = async (e) => {
    const isLicencaValida = await licencaValida(e);
    if (!isLicencaValida) {
      toast.info('Cliente com licença expirada. Efetuar pagamento mensalidade.');
      // navigate('/pagamento');
      return false;
    }

    setEmpresa(e);

    if (e > 0) {
      getDataEmpresa(e);
    }
  };

  const handleImportarCertificado = files => {
    const reader = new FileReader();
    reader.onload = function () {
      setCertificado(reader.result.split(',')[1]);
    };
    reader.readAsDataURL(files[0]);
  };

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>Configuração do Sistema - Edição</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-lg-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Empresa
              </Input155px>
              <Dropdown
                options={listaEmpresa}
                value={empresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => handleSelecionarEmpresa(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-lg-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                NFe Ambiente
              </Input155px>
              <Dropdown
                options={listaAmbiente}
                value={nfeAmbiente}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setNfeAmbiente(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Série
              </Input155px>
              <InputText
                minLength={1}
                maxLength={3}
                value={nfeSerie}
                onChange={e => setNfeSerie(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-lg-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Certificado Digital
              </Input155px>
              <InputText
                disabled
                rows={1}
                value={certificado}
                onChange={e => setCertificado(e.target.value)}
              />
              <ReactFileReader handleFiles={handleImportarCertificado} fileTypes=".pfx">
                <button
                  type="button"
                  title="Importar Certificado Digital"
                  className="btn btn-warning"
                  icon="pi pi-search"
                  style={{ borderRadius: '15px', marginTop: '5px', marginLeft: '5px', display: 'flex' }}
                >
                  <i className="pi pi-download"></i>
                </button>
              </ReactFileReader>
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Senha
              </Input155px>
              <Password
                id="inputSenha"
                toggleMask
                weakLabel="Fraca"
                mediumLabel="Média"
                strongLabel="Forte"
                value={senhaCertificado}
                onChange={e => setSenhaCertificado(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-8">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                URL Logotipo
              </Input155px>
              <InputText
                autoFocus
                value={urlLogotipo}
                onChange={e => setUrlLogotipo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Senha Administrador
              </Input200px>
              <Password
                id="inputSenha"
                toggleMask
                weakLabel="Fraca"
                mediumLabel="Média"
                strongLabel="Forte"
                value={senhaAdm}
                onChange={e => setSenhaAdm(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Balança Digitos Código
              </Input155px>
              <InputNumber
                showButtons
                min={1}
                max={9}
                value={balancaDigitosCodigo}
                onValueChange={e => setBalancaDigitosCodigo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Balança por Valor
              </Input155px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={balancaPorValor}
                onChange={(e) => setBalancaPorValor(e.value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Pis/Cofins Cumulativo
              </Input155px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={pisCofinsCumulativo}
                onChange={(e) => setPisCofinsCumulativo(e.value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Alíquota PIS
              </Input100px>
              <InputNumber
                suffix=" %"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={aliquotaPis}
                onValueChange={e => setAliquotaPis(e.target.value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Alíquota COFINS
              </Input100px>
              <InputNumber
                suffix=" %"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={aliquotaCofins}
                onValueChange={e => setAliquotaCofins(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input400px className="p-inputgroup-addon">
                Desabilitar Cadastro de Produto
              </Input400px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={desabilitarCadastroProduto}
                onChange={(e) => setDesabilitarCadastroProduto(e.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input400px className="p-inputgroup-addon">
                Habilitar Alteração Estoque da Filial
              </Input400px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={habilitarAlteracaoEstoqueFilial}
                onChange={(e) => setHabilitarAlteracaoEstoqueFilial(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Gerar Inventário
              </Input155px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={gerarInventario}
                onChange={(e) => setGerarInventario(e.value)}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
