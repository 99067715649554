/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import { displayErrors, formataMoeda, formataTelefone } from '../../../util/diversos';

export default function PdfEstampariaPedido({ payloadPrint, finishPrintOrcamento }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadPrint, 'id', 0);
  const idUsuario = get(store.getState().auth.user, 'id', 0);

  function headRows() {
    return [
      { qtde: 'Qtde', descricao: 'Descricao', precoFinal: 'Valor UN', totalItem: 'Total' },
    ];
  }

  const [headerTabelaImpressao] = useState(headRows());

  useEffect(() => {
    async function getData(img) {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF();

      try {
        const usuario = await axiosAdmin.get(`/usuario/${idUsuario}`);
        let cnpjEmpresa = usuario.data.listaEmpresas[0].cnpj;
        if (cnpjEmpresa.length === 14) {
          cnpjEmpresa = cnpjEmpresa.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }

        const { data } = await axiosAdmin.get(`/estampariaorcamento/${id}`);

        const result = [];

        data.listaEstampariaOrcamentoItem.forEach(element => {
          data.qtde = element.quantidade;
          let descricao = get(element.produto, 'descricao', '').trim();

          if (element.produto.bolso) {
            descricao += `, Bolso: ${element.produto.bolso}`;
          }
          if (element.estampariaModelo) {
            descricao += `, ${element.estampariaModelo.descricao}`;
          }
          if (element.estampariaMalha) {
            descricao += `, ${element.estampariaMalha.descricao}`;
          }

          descricao += '\n';
          if (element.estampariaGola) {
            descricao += '\n';
            descricao += `Gola: ${element.estampariaGola.descricao}`;
            if (element.estampariaGolaAcabamento) {
              descricao += ` com acabamento ${element.estampariaGolaAcabamento.descricao}`;
            }
          }
          if (element.estampariaManga) {
            descricao += '\n';
            descricao += `Manga: ${element.estampariaManga.descricao}`;
            if (element.estampariaMangaAcabamento) {
              descricao += ` com acabamento ${element.estampariaMangaAcabamento.descricao}`;
            }
          }

          if (element.estampariaBolso) {
            descricao += '\n';
            descricao += `Bolso: ${element.estampariaBolso.descricao}`;
          }

          let descricaoFrente = '';
          let descricaoCostas = '';
          let descricaoMangaDireita = '';
          let descricaoMangaEsquerda = '';
          for (let x = 0; x < element.listaEstampariaOrcamentoTipoServico.length; x++) {
            const servico = element.listaEstampariaOrcamentoTipoServico[x];
            if (servico.parteVestuario === 'FRENTE') {
              if (servico.silkar || servico.sublimar || servico.bordar) {
                descricaoFrente += '\n';
                descricaoFrente += '\n';
                descricaoFrente += 'Frente: ';
              }
              if (servico.silkar) {
                descricaoFrente += '\n';
                descricaoFrente += `     Silkar: ${servico.silk.descricao}`;
              }
              if (servico.sublimar) {
                descricaoFrente += '\n';
                descricaoFrente += `     Sublimar: ${servico.sublimacao.descricao}`;
              }
              if (servico.bordar) {
                descricaoFrente += '\n';
                descricaoFrente += `     Bordar: ${servico.bordado.descricao}`;
              }
            }
            if (servico.parteVestuario === 'COSTAS') {
              if (servico.silkar || servico.sublimar || servico.bordar) {
                descricaoCostas += '\n';
                descricaoCostas += 'Costas: ';
              }
              if (servico.silkar) {
                descricaoCostas += '\n';
                descricaoCostas += `     Silkar: ${servico.silk.descricao}`;
              }
              if (servico.sublimar) {
                descricaoCostas += '\n';
                descricaoCostas += `     Sublimar: ${servico.sublimacao.descricao}`;
              }
              if (servico.bordar) {
                descricaoCostas += '\n';
                descricaoCostas += `     Bordar: ${servico.bordado.descricao}`;
              }
            }
            if (servico.parteVestuario === 'MANGADIREITA') {
              if (servico.silkar || servico.sublimar || servico.bordar) {
                descricaoMangaDireita += '\n';
                descricaoMangaDireita += 'Manga Direita: ';
              }
              if (servico.silkar) {
                descricaoMangaDireita += '\n';
                descricaoMangaDireita += `     Silkar: ${servico.silk.descricao}`;
              }
              if (servico.sublimar) {
                descricaoMangaDireita += '\n';
                descricaoMangaDireita += `     Sublimar: ${servico.sublimacao.descricao}`;
              }
              if (servico.bordar) {
                descricaoMangaDireita += '\n';
                descricaoMangaDireita += `     Bordar: ${servico.bordado.descricao}`;
              }
            }
            if (servico.parteVestuario === 'MANGAESQUERDA') {
              if (servico.silkar || servico.sublimar || servico.bordar) {
                descricaoMangaEsquerda += '\n';
                descricaoMangaEsquerda += 'Manga Esquerda:';
              }
              if (servico.silkar) {
                descricaoMangaEsquerda += '\n';
                descricaoMangaEsquerda += `     Silkar: ${servico.silk.descricao}`;
              }
              if (servico.sublimar) {
                descricaoMangaEsquerda += '\n';
                descricaoMangaEsquerda += `     Sublimar: ${servico.sublimacao.descricao}`;
              }
              if (servico.bordar) {
                descricaoMangaEsquerda += '\n';
                descricaoMangaEsquerda += `     Bordar: ${servico.bordado.descricao}`;
              }
            }
          }
          descricao += descricaoFrente;
          descricao += descricaoCostas;
          descricao += descricaoMangaDireita;
          descricao += descricaoMangaEsquerda;

          if (element.listaEstampariaOrcamentoItemAcessorio.length > 0) {
            descricao += '\n';
            descricao += 'Acréscimo(s): \n     ';
            element.listaEstampariaOrcamentoItemAcessorio.forEach(_listaAcessorios => {
              descricao += `${_listaAcessorios.estampariaAcessorio.descricao}, `;
            });
          }

          data.descricao = descricao;
          data.precoFinal = formataMoeda(element.precoFinal);
          data.totalItem = formataMoeda(element.totalItem);
          result.push({ ...data });
        });

        // linha em branco
        let dados = {
          qtde: '',
          descricao: '',
          precoFinal: '',
          totalItem: '',
        };
        result.push({ ...dados });

        let observacao = '';
        if (data.observacao) {
          observacao = data.observacao + '\n';
        }

        observacao += '\n';
        observacao += 'Para Formalizarmos o pedido é necessário o pagamento de 50% do valor total do pedido como entrada. \n';
        observacao += '\n';
        observacao += 'Formas de Pagamento: \n';
        observacao += 'PIX ou link para cartão de débito ou crédito, depósito ou transferência. \n';
        observacao += 'Pagamento presencial em dinheiro ou cartão de débito ou crédito. \n';
        observacao += 'Valor dividido em até 3x no cartão terá acréscimo de 10% no valor total. \n';
        observacao += '\n';
        observacao += ' O restante do valor quando retirar o mesmo. NÃO aceitamos cheques.';

        dados = {
          qtde: 'Obs:',
          descricao: observacao,
          precoFinal: ' Total',
          totalItem: formataMoeda(data.valorTotal),
        };

        result.push({ ...dados });

        doc.autoTable({
          styles: { fontSize: 8 },
          margin: { top: 38, left: 5, right: 5 },
          theme: 'grid',
          head: headerTabelaImpressao,
          body: result,
          showHead: 'firstPage',
          showFoot: 'firstPage',
          didDrawPage: (_dados) => {
            // Header
            doc.rect(5, 5, 200, 30);
            doc.addImage(img, 'JPEG', 9, 8, 25, 25);
            doc.setFontSize(20);
            doc.text('Orçamento: ', 40, 13);
            doc.text(id.toString(), 78, 13);

            doc.setFontSize(8);
            usuario.data.listaEmpresas[0].nomeFantasia ? doc.text(usuario.data.listaEmpresas[0].nomeFantasia, 135, 8) : '';
            cnpjEmpresa ? doc.text(`CNPJ: ${cnpjEmpresa}`, 135, 12) : '';
            usuario.data.email ? doc.text(usuario.data.email, 135, 16) : '';
            if (usuario.data.listaEmpresas[0].endereco && usuario.data.listaEmpresas[0].enderecoNumero) {
              const endereco = `${usuario.data.listaEmpresas[0].endereco} - ${usuario.data.listaEmpresas[0].enderecoNumero}`;
              doc.text(endereco, 135, 20);
            }
            usuario.data.listaEmpresas[0].uf ? doc.text(usuario.data.listaEmpresas[0].uf, 135, 24) : '';
            usuario.data.listaEmpresas[0].cidade ? doc.text(usuario.data.listaEmpresas[0].cidade, 142, 24) : '';
            usuario.data.listaEmpresas[0].telefone_1 ? doc.text(usuario.data.listaEmpresas[0].telefone_1, 135, 28) : '';
            usuario.data.listaEmpresas[0].telefone_2 ? doc.text(usuario.data.listaEmpresas[0].telefone_2, 160, 28) : '';

            doc.setFontSize(10);
            data.nomeOrcamento ? doc.text(data.nomeOrcamento, 40, 19) : '';
            doc.setFontSize(8);
            if (data.cliente) {
              if (data.cliente.cnpj && data.cliente.cnpj.length === 14) {
                doc.text('CNPJ: ', 40, 22);
                doc.text(data.cliente.cnpj, 50, 22);
              }
            }

            doc.text('Cliente: ', 40, 25);
            let cliente = '';
            if (data.cliente) {
              cliente = `${data.cliente.id} - ${data.cliente.razaoSocial.substring(0, 45)}`;
            }
            doc.text(cliente, 50, 25);
            doc.text('Telefone: ', 40, 29);
            data.telefone_1 ? doc.text(formataTelefone(data.telefone_1), 58, 29) : '';

            doc.text('Vendedor: ', 40, 33);
            data.vendedor ? doc.text(data.vendedor.codigo + ' - ' + data.vendedor.nome, 54, 33) : '';

            doc.setFontSize(8);
            doc.text('Data:', 135, 33);
            doc.setFontSize(10);
            doc.text(new Date(data.data[0], data.data[1] - 1, data.data[2]).toLocaleDateString('pt-br'), 144, 33);
            if (data.dataValidade !== null) {
              doc.setFontSize(8);
              doc.text('Validade:', 170, 33);
              doc.setFontSize(10);
              data.dataValidade ? doc.text(new Date(data.dataValidade[0], data.dataValidade[1] - 1, data.dataValidade[2]).toLocaleDateString('pt-br'), 185, 33) : '';
            } else {
              doc.setFontSize(8);
              doc.text('Validade:', 170, 33);
              doc.setFontSize(10);
              doc.text('30 dias', 185, 33);
            }

            // footer
            const { pageSize } = doc.internal;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(`Pag ${doc.internal.getNumberOfPages()}`, _dados.settings.margin.right, pageHeight - 8);
            doc.text('Elo Informática Barbacena LTDA', 82, pageHeight - 8);
            const periodo = new Date();
            doc.text('Impresso: ', 172, pageHeight - 8);
            // eslint-disable-next-line prefer-template
            doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 189, pageHeight - 8);
          },
        });

        window.open(doc.output('bloburl'));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('erro', err);
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        // funcao no pai pra fechar a tela de impressao
        finishPrintOrcamento();
      }
    }

    const loadImage = async () => {
      let config = '';
      try {
        config = await axiosAdmin.get(`/configuracaosistema?idUsuario=${idUsuario}`);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }

      const img = new Image();
      img.src = `/logotipos/${config.data.urlLogotipo}`;
      await img.decode().then(() => {

      }).catch(() => {
        img.src = 'logo.png';
      });
      getData(img);
    };
    loadImage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch, navigate]);

  return (
    <div></div>
  );
}
