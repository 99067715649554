import React from 'react';
import { useSelector } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import Rotas from '../routes/rotas';
import Navbar from './navbar';

export default function Header() {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  document.body.style.background = '#D5DEE5';

  return (
    <HashRouter>
      <div className="container-fluid" style={{ backgroundColor: '#2c3e50' }}>
        {isLoggedIn ? (
          <>
            <div className="navbar" style={{ backgroundColor: '#2c3e50' }}>
              <div className="col-1">
                <img src="logo.png" alt="" width="86" height="53" className="navbar-brand" style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
              </div>
              <div className="col-11">
                <Navbar />
              </div>
            </div>
            <div className="row flex-xl-nowrap">
              <main className="col-12 col-md-12 col-xl-12 py-md-3 pl-md-5 bd-content" role="main" style={{ paddingRight: '20px', backgroundColor: '#D5DEE5' }}>
                <div style={{ paddingTop: '12px' }}>
                  <Rotas />
                </div>
              </main>
            </div>
          </>
        ) : (
          <>
            <div className="row" style={{ paddingTop: '50px', backgroundColor: '#D5DEE5' }}>
              <div className="col-md-6 offset-md-3" style={{ paddingTop: '50px' }}>
                <div className="bs-docs-section">
                  <Rotas />
                </div>
              </div>
            </div>
            <div className="container-fluid fixed-top">
              <div className="row flex-xl-nowrap" style={{ padding: '4px', color: '#fff', backgroundColor: '#2c3e50', fontSize: '14px' }}>
                Elo Sistemas - Versão 1.0.0
              </div>
            </div>
          </>
        ) }
      </div>
    </HashRouter>
  );
}
