import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { get } from 'lodash';
import dateFormat from 'dateformat';
import { toast } from 'react-toastify';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { addLocale } from 'primereact/api';
import { Dialog } from 'primereact/dialog';
import { Input155px, Input200px } from '../../../styles/inputGroup';
import Card from '../../../components/card';
import Loading from '../../loading';

import { displayErrors } from '../../../util/diversos';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';

import ConsultaProduto from '../produto/consultaProduto';
import ConsultaVendedor from '../vendedor/consultaVendedor';

export default function RelatorioMovimentacaoProduto() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [findTipo, setFindTipo] = useState('TODOS');
  const [findIdProduto, setFindIdProduto] = useState(null);
  const [findCnpjEmpresaDestino, setFindCnpjEmpresaDestino] = useState('TODOS');
  const [produtoDescricao, setProdutoDescricao] = useState('');
  const [produtoRegistro, setProdutoRegistro] = useState(null);

  const [nomeVendedor, setNomeVendedor] = useState('');
  const [findIdVendedor, setFindIdVendedor] = useState('');
  const [codigoVendedor, setCodigoVendedor] = useState('');

  const [showConsultaProduto, setShowConsultaProduto] = useState(false);
  const [showConsultaVendedor, setShowConsultaVendedor] = useState(false);

  const [listaEmpresas, setListaEmpresas] = useState([]);

  const [listaTipoMovimentacaoProduto] = useState([
    { label: 'Todos', value: 'TODOS' },
    { label: 'Entrada de Produtos', value: 'ENTRADAESTOQUE' },
    { label: 'Transferência de Produtos', value: 'TRANSFERENCIA' },
    { label: 'Saída de Produto Garantia', value: 'SAIDAGARANTIA' },
    /* { label: 'Saída Acerto de Estoque', value: 'SAIDAACERTOESTOQUE' },
    { label: 'Saída por Perda', value: 'SAIDAPERDA' },
    { label: 'Saída por Furto', value: 'SAIDAFURTO' },
    { label: 'Saída Consumo Próprio', value: 'SAIDACONSUMOPROPRIO' }, */
  ]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 99999,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  function headRows() {
    return [
      { id: 'id', tipo: 'Dados', qtde: 'Qtde' },
    ];
  }

  const [headerTabelaImpressao] = useState(headRows());

  const idUsuario = get(store.getState().auth.user, 'id', 0);

  async function getData(searchFilter, page, size, img) {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    try {
      setIsLoading(true);

      if (findDataInicial == null && findDataFinal == null) {
        toast.info('Datas devem ser informadas.');
        return;
      }

      if (findDataInicial > findDataFinal) {
        toast.info('Data final anterior a data inicial.');
        return;
      }

      let params = `/movimentacaoproduto/search?idUsuario=${idUsuario}`;

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = `${dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy')} 00:00:00`;
        const _dataFinal = `${dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy')} 23:59:59`;

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }
      if (searchFilter && searchFilter.findTipo !== 'TODOS') {
        params = `${params}&tipo=${searchFilter.findTipo}`;
      }

      if (searchFilter && searchFilter.findCnpjEmpresaDestino !== 'TODOS') {
        params = `${params}&cnpjEmpresaDestino=${searchFilter.findCnpjEmpresaDestino}`;
      }

      if (searchFilter && searchFilter.findIdVendedor !== null) {
        params = `${params}&idVendedor=${searchFilter.findIdVendedor}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      // console.log('response: ', response.data.content);

      if (response.data.content.length <= 0) {
        toast.info('Não há registros a serem exibidos.');
        return;
      }

      let data = {
        id: '0',
        tipo: '',
        qtde: '',
      };

      const result = [];
      for (let x = 0; x < response.data.content.length; x++) {
        const movimentacao = response.data.content[x];

        let tipo = '';
        let razaoSocialOrigem = '';
        let razaoSocialDestino = '';

        if (movimentacao.tipo === 'ENTRADAESTOQUE') {
          tipo = 'Entrada';
          razaoSocialDestino = movimentacao.empresaDestino.razaoSocial.trim();
        }

        if (movimentacao.tipo === 'TRANSFERENCIA') {
          tipo = 'Transferência';
          razaoSocialOrigem = movimentacao.empresaOrigem.razaoSocial.trim();
          razaoSocialDestino = movimentacao.empresaDestino.razaoSocial.trim();
        }

        if (movimentacao.tipo === 'SAIDAACERTOESTOQUE') {
          tipo = 'Saída Acerto Estoque';
          razaoSocialOrigem = movimentacao.empresaOrigem.razaoSocial.trim();
        }

        if (movimentacao.tipo === 'SAIDACONSUMOPROPRIO') {
          tipo = 'Saída Consumo';
          razaoSocialOrigem = movimentacao.empresaOrigem.razaoSocial.trim();
        }

        if (movimentacao.tipo === 'SAIDAFURTO') {
          // eslint-disable-next-line no-param-reassign
          tipo = 'Saída Furto';
          razaoSocialOrigem = movimentacao.empresaOrigem.razaoSocial.trim();
        }

        if (movimentacao.tipo === 'SAIDAPERDA') {
          // eslint-disable-next-line no-param-reassign
          tipo = 'Saída Perda';
          razaoSocialOrigem = movimentacao.empresaOrigem.razaoSocial.trim();
        }

        if (movimentacao.tipo === 'SAIDAGARANTIA') {
          // eslint-disable-next-line no-param-reassign
          tipo = 'Saída Garantia';
          razaoSocialOrigem = movimentacao.empresaOrigem.razaoSocial.trim();
        }

        if (findIdProduto != null && findIdProduto > 0) {
          let item = '';
          if (findIdVendedor > 0) {
            item = movimentacao.listaMovimentacaoProdutoItem.filter(el => (el.produto.id === findIdProduto && movimentacao.vendedor.id === findIdVendedor));
          } else {
            item = movimentacao.listaMovimentacaoProdutoItem.filter(el => el.produto.id === findIdProduto);
          }

          if (item.length > 0) {
            data.id = movimentacao.id.toString();
            const dataMovimentacao = `${('00' + movimentacao.data[2]).slice(-2)}/${('00' + movimentacao.data[1]).slice(-2)}/${movimentacao.data[0]}`;
            data.tipo = dataMovimentacao + ' - ' + tipo + ' - ' + razaoSocialOrigem + razaoSocialDestino;
            if (tipo === 'Transferência') {
              data.tipo = dataMovimentacao + ' - ' + tipo + ' - ' + razaoSocialOrigem + ' -> ' + razaoSocialDestino;
            }

            if (movimentacao.vendedor) {
              data.tipo += ' (' + movimentacao.vendedor.nome + ')';
            }

            data.qtde = '';

            result.push({ ...data });

            data.id = '';
            data.tipo = 'Item: ' + item[0].item.toString().padEnd(5, ' ')
                        + 'Registro: ' + item[0].produto.registro.toString().padEnd(10, ' ')
                        + ' - ' + item[0].produto.descricao.trim();
            data.qtde = item[0].quantidade;
            result.push({ ...data });

            data = {
              id: '',
              tipo: '',
              qtde: '',
            };
            result.push({ ...data });
          }
        } else {
          data.id = movimentacao.id.toString();
          const dataMovimentacao = `${('00' + movimentacao.data[2]).slice(-2)}/${('00' + movimentacao.data[1]).slice(-2)}/${movimentacao.data[0]}`;
          data.tipo = dataMovimentacao + ' - ' + tipo + ' - ' + razaoSocialOrigem + razaoSocialDestino;
          if (tipo === 'Transferência') {
            data.tipo = dataMovimentacao + ' - ' + tipo + ' - ' + razaoSocialOrigem + ' -> ' + razaoSocialDestino;
          }

          if (movimentacao.vendedor) {
            data.tipo += ' (' + movimentacao.vendedor.nome + ')';
          }

          data.qtde = '';

          result.push({ ...data });

          for (let y = 0; y < movimentacao.listaMovimentacaoProdutoItem.length; y++) {
            const item = movimentacao.listaMovimentacaoProdutoItem[y];
            data.id = '';
            data.tipo = 'Item: ' + item.item.toString().padEnd(5, ' ')
                        + 'Registro: ' + item.produto.registro.toString().padEnd(10, ' ')
                        + ' - ' + item.produto.descricao.trim();
            data.qtde = item.quantidade;
            result.push({ ...data });
          }

          data = {
            id: '',
            tipo: '',
            qtde: '',
          };
          result.push({ ...data });
        }
      }

      doc.autoTable({
        styles: {
          fontSize: 8,
        },
        margin: { top: 38, left: 5, right: 5 },
        columnStyles: {
          0: { cellWidth: 15 },
        },
        theme: 'grid',
        head: headerTabelaImpressao,
        body: result,
        didDrawPage: dados => {
          // Header
          doc.rect(5, 5, 200, 30);
          doc.addImage(img, 'JPEG', 9, 8, 25, 25);
          doc.setFontSize(20);
          doc.text('Movimentação de Produtos ', 70, 15);

          doc.setFontSize(8);
          doc.text('Tipo:', 50, 27);
          doc.text(findTipo, 63, 27);

          doc.setFontSize(8);
          doc.text('Período:', 50, 32);
          doc.text(dateFormat(findDataInicial, 'dd/mm/yyyy'), 63, 32);
          doc.text(' à ', 79, 32);
          doc.text(dateFormat(findDataFinal, 'dd/mm/yyyy'), 83, 32);

          // colocar a empresa destino se houver

          // footer
          const { pageSize } = doc.internal;
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(`Pag ${doc.internal.getNumberOfPages()}`, dados.settings.margin.right, pageHeight - 8);
          doc.text('Elo Informática Barbacena LTDA', 82, pageHeight - 8);
          const periodo = new Date();
          doc.text('Impresso: ', 172, pageHeight - 8);
          // eslint-disable-next-line prefer-template
          doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 189, pageHeight - 8);
        },
      });
      window.open(doc.output('bloburl'));
    } catch (e) {
      // console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    setFindDataInicial(new Date(store.getState().auth.consultaEstampariaPedidoDataInicial));
    setFindDataFinal(new Date(store.getState().auth.consultaEstampariaPedidoDataFinal));
  }, []);

  useEffect(() => {
    async function verificarSeMultiEmpresa() {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [{
          label: 'Todos',
          value: 'TODOS',
          id: 0,
        }];

        _listaEmpresas.forEach(element => {
          listDropdown.push(
            {
              label: element.razaoSocial,
              value: element.cnpj,
              id: element.id,
            });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    verificarSeMultiEmpresa();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal, findTipo, findCnpjEmpresaDestino, findIdVendedor };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    const loadImage = async () => {
      let config = '';
      try {
        config = await axiosAdmin.get(`/configuracaosistema?idUsuario=${idUsuario}`);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }

      const img = new Image();
      img.src = `/logotipos/${config.data.urlLogotipo}`;

      await img.decode().then(() => {
      }).catch(() => {
        img.src = 'logo.png';
      });
      getData(searchFilter, 0, lazyParams.rows, img);
    };
    loadImage();
    // getData(searchFilter, 0, lazyParams.rows);
  };

  // eslint-disable-next-line consistent-return
  const childToParentConsultaProduto = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (idUsuario === e.idUsuario) {
        if (e.id) {
          // console.log('e child to produto: ', e);
          setFindIdProduto(e.id);
          setProdutoRegistro(e.registro);
          setProdutoDescricao(e.descricao);
        }
        setShowConsultaProduto(false);
      } else {
        toast.warn('Produto selecionado não pertence a empresa');
      }
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  // eslint-disable-next-line consistent-return
  async function handleBuscarProdutoByIdUsuarioEmpresaDestino(e) {
    try {
      if (e.length <= 0) {
        setProdutoDescricao('');
        return false;
      }

      let params = `/produto/search?idUsuario=${idUsuario}`;

      params = `${params}&registro=${e.trim()}`;

      const response = await axiosAdmin.get(params);

      if (response.data) {
        setFindIdProduto(response.data.content[0].id);
        setProdutoRegistro(response.data.content[0].registro);
        setProdutoDescricao(response.data.content[0].descricao);
      }
    } catch (err) {
      setProdutoDescricao('');
      toast.info('Produto com esse código não encontrado');
      // displayErrors(err, dispatch, navigate, '/', '');
    }
  }

  const onHideConsultaProduto = () => {
    setShowConsultaProduto(false);
  };

  const childToParentVendedor = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setFindIdVendedor(e.id);
      }
      if (e.codigo) {
        setCodigoVendedor(e.codigo);
      }
      if (e.nome) {
        setNomeVendedor(e.nome);
      }
      setShowConsultaVendedor(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const onHideConsultaVendedor = () => {
    setShowConsultaVendedor(false);
  };

  // eslint-disable-next-line consistent-return
  async function handleBuscarVendedorByCodigo(e) {
    try {
      if (e.length <= 0) {
        setFindIdVendedor('');
        setNomeVendedor('');
        return false;
      }

      let params = `/vendedor?idUsuario=${idUsuario}`;

      params = `${params}&codigo=${e.trim()}`;

      const response = await axiosAdmin.get(params);

      if (response.data.length > 0) {
        setFindIdVendedor(response.data[0].id);
        setNomeVendedor(response.data[0].nome);
      } else {
        setFindIdVendedor('');
        setNomeVendedor('');
        toast.info('Nenhum vendedor com esse código encontrado');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    }
  }

  return (
    <>
      <Loading isLoading={isLoading} />

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}> Relatório de Movimentação de Produtos </h6>
        </div>

        <Dialog
          header="Consulta de Vendedor"
          visible={showConsultaVendedor}
          style={{ width: '98vw' }}
          onHide={() => onHideConsultaVendedor()}
        >
          <ConsultaVendedor menuMode={false} childToParent={childToParentVendedor} />
        </Dialog>

        <Dialog
          header="Consulta de Produtos"
          visible={showConsultaProduto}
          style={{ width: '98vw' }}
          onHide={() => onHideConsultaProduto()}
        >
          <ConsultaProduto menuMode={false} childToParent={childToParentConsultaProduto} />
        </Dialog>

        <div className="row form-elo">
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data inicial
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={findDataInicial}
                onChange={(e) => setFindDataInicial(e.value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data final
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={findDataFinal}
                onChange={(e) => setFindDataFinal(e.value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo
              </Input155px>
              <Dropdown
                options={listaTipoMovimentacaoProduto}
                value={findTipo}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setFindTipo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Empresa Destino
              </Input155px>
              <Dropdown
                options={listaEmpresas}
                value={findCnpjEmpresaDestino}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setFindCnpjEmpresaDestino(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Produto
              </Input155px>
              <InputText
                autoFocus
                value={produtoRegistro}
                onChange={e => {
                  setFindIdProduto(e.target.value);
                  setProdutoRegistro(e.target.value);
                }}
                onBlur={e => {
                  handleBuscarProdutoByIdUsuarioEmpresaDestino(e.target.value);
                }}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaProduto(true)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <InputText
                disabled
                maxLength={255}
                value={produtoDescricao}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Vendedor/Responsável
              </Input200px>
              <InputText
                maxLength={255}
                value={codigoVendedor}
                onChange={e => setCodigoVendedor(e.target.value)}
                onBlur={e => handleBuscarVendedorByCodigo(e.target.value)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaVendedor(true)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="p-inputgroup">
              <InputText
                disabled
                maxLength={255}
                value={nomeVendedor}
              />
            </div>
          </div>
          <div className="col-lg-1 text-end">
            <button
              type="button"
              title="Imprimir Relatório"
              className="btn btn-success"
              style={{ borderRadius: '15px' }}
              onClick={handleFind}
            >
              <i className="pi pi-print"></i>
            </button>
          </div>
        </div>
      </Card>
    </>
  );
}
