import * as types from '../types';

export function loginRequest(payload) {
  return {
    type: types.LOGIN_REQUEST,
    payload,
  };
}

export function loginSucess(payload) {
  return {
    type: types.LOGIN_SUCCESS,
    payload,
  };
}

export function loginFailure(payload) {
  return {
    type: types.LOGIN_FAILURE,
    payload,
  };
}

export function titleHeaderChange(payload) {
  return {
    type: types.TITLEHEADER_CHANGE,
    payload,
  };
}

export function cnpjEmpresaChange(payload) {
  return {
    type: types.CNPJEMPRESA_CHANGE,
    payload,
  };
}

export function consultaMovimentacaoFinanceiraDataInicialChange(payload) {
  return {
    type: types.CONSULTAMOVIMENTACAOFINANCEIRADATAINICIAL_CHANGE,
    payload,
  };
}

export function consultaMovimentacaoFinanceiraCnpjEmpresaChange(payload) {
  return {
    type: types.CONSULTAMOVIMENTACAOFINANCEIRACNPJEMPRESA_CHANGE,
    payload,
  };
}

export function consultaPrevisaoFinanceiraDataInicialChange(payload) {
  return {
    type: types.CONSULTAPREVISAOFINANCEIRADATAINICIAL_CHANGE,
    payload,
  };
}

export function consultaPrevisaoFinanceiraCnpjEmpresaChange(payload) {
  return {
    type: types.CONSULTAPREVISAOFINANCEIRACNPJEMPRESA_CHANGE,
    payload,
  };
}

export function consultaMovimentacaoBancariaDataInicialChange(payload) {
  return {
    type: types.CONSULTAMOVIMENTACAOBANCARIADATAINICIAL_CHANGE,
    payload,
  };
}

export function consultaMovimentacaoBancariaContaBancariaChange(payload) {
  return {
    type: types.CONSULTAMOVIMENTACAOBANCARIACONTABANCARIA_CHANGE,
    payload,
  };
}

export function consultaLivroCaixaDataInicialChange(payload) {
  return {
    type: types.CONSULTALIVROCAIXADATAINICIAL_CHANGE,
    payload,
  };
}

export function consultaLivroCaixaCnpjEmpresaChange(payload) {
  return {
    type: types.CONSULTALIVROCAIXACNPJEMPRESA_CHANGE,
    payload,
  };
}

export function consultaFluxoCaixaDataInicialChange(payload) {
  return {
    type: types.CONSULTAFLUXOCAIXADATAINICIAL_CHANGE,
    payload,
  };
}

export function consultaFluxoCaixaCnpjEmpresaChange(payload) {
  return {
    type: types.CONSULTAFLUXOCAIXACNPJEMPRESA_CHANGE,
    payload,
  };
}

export function consultaEstampariaPedidoDataInicialChange(payload) {
  return {
    type: types.CONSULTAESTAMPARIAPEDIDODATAINICIAL_CHANGE,
    payload,
  };
}

export function consultaEstampariaRecebimentoPedidoDataInicialChange(payload) {
  return {
    type: types.CONSULTAESTAMPARIARECEBIMENTOPEDIDODATAINICIAL_CHANGE,
    payload,
  };
}

export function consultaEstampariaOrdemProducaoDataInicialChange(payload) {
  return {
    type: types.CONSULTAESTAMPARIAORDEMPRODUCAODATAINICIAL_CHANGE,
    payload,
  };
}

export function consultaEstampariaOrcamentoDataInicialChange(payload) {
  return {
    type: types.CONSULTAESTAMPARIAORCAMENTODATAINICIAL_CHANGE,
    payload,
  };
}
