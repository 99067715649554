import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';

import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input100px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosAdmin from '../../../services/axiosAdmin';

export default function CadastroMunicipio() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(useParams(), 'id', 0);

  const [codigo, setCodigo] = useState(0);
  const [descricao, setDescricao] = useState('');
  const [uf, setUf] = useState('');
  const [listaUf, setListaUf] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let newTitle = 'Município - Cadastro';
    if (id) {
      newTitle = 'Município - Edição';
    }

    let mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getUnidadesFederacao() {
      try {
        setIsLoading(true);

        const listDropdown = [{ label: 'Selecione...', value: '' }];

        const { data: ufs } = await axiosAdmin.get('/unidadefederacao');

        ufs.forEach(element => {
          listDropdown.push({ label: element.sigla, value: element.sigla });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaUf(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    getUnidadesFederacao();

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/municipio/${id}`);

        setCodigo(data.codigo);
        setDescricao(data.descricao);
        setUf(data.uf);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      // eslint-disable-next-line prefer-template
      const codigoEnvio = ('0000000' + codigo).slice(-7);

      const shippingData = {
        codigo: codigoEnvio,
        descricao,
        uf,
      };

      if (id > 0) {
        await axiosAdmin.put(`/municipio/${id}`, shippingData);
        toast.success('Município atualizado com sucesso');
      } else {
        await axiosAdmin.post('/municipio', shippingData);
        toast.success('Município cadastrado com sucesso');
      }

      navigate('/consultamunicipio');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    navigate('/consultamunicipio');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (codigo < 1 || codigo > 9999999) {
      formErros = true;
      toast.error('Código inválido');
    }
    if (descricao.length < 3 || descricao.length > 255) {
      formErros = true;
      toast.error('Descrição deve ter entre 3 a 255 caracteres');
    }
    if (!uf) {
      formErros = true;
      toast.error('UF deve ser informada');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id ? 'Município - Alteração' : 'Município - Inclusão');

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Código
              </Input100px>
              <InputNumber
                min={1}
                max={9999999}
                value={codigo}
                onValueChange={e => setCodigo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-9">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Descrição
              </Input100px>
              <InputText
                maxLength={255}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                UF
              </Input100px>
              <Dropdown
                options={listaUf}
                value={uf}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setUf(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
