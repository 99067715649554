import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input155px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function CadastroFinanceiroDre({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);

  const [descricao, setDescricao] = useState('');
  const [operacaoFinanceira, setOperacaoFinanceira] = useState('PAGAR');
  const [lucroBruto, setLucroBruto] = useState(true);
  const [listaOperacaoFinanceira] = useState([
    { label: 'Pagar', value: 'PAGAR' },
    { label: 'Receber', value: 'RECEBER' },
  ]);
  const [listaLucroBruto] = useState([
    { label: 'Lucro Bruto', value: true },
    { label: 'Lucro Líquido', value: false },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let newTitle = 'DRE Financeiro - Cadastro';
    if (id) {
      newTitle = 'DRE Financeiro - Edição';
    }

    dispatch(actions.titleHeaderChange({ newTitle }));

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/financeirodre/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultafinanceirodre');
          toast.error('Acesso negado');
        }

        setDescricao(data.descricao);
        setOperacaoFinanceira(data.operacaoFinanceira);
        setLucroBruto(data.lucroBruto);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const sistema = false;

      let payloadDataTableList = {};

      const shippingData = {
        idUsuario,
        descricao,
        operacaoFinanceira,
        lucroBruto,
        sistema,
      };

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/financeirodre/${id}`, shippingData), 'data', {});
        toast.success('DRE Financeiro atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/financeirodre', shippingData), 'data', {});
        toast.success('DRE Financeiro cadastrado com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!descricao) {
      formErros = true;
      toast.error('Descrição deve ser informada');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id ? 'DRE Financeiro - Alteração' : 'DRE Financeiro - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>{titulo()}</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Descrição
              </Input155px>
              <InputText
                autoFocus
                maxLength={100}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-lg-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Operação
              </Input155px>
              <Dropdown
                autoFocus
                options={listaOperacaoFinanceira}
                value={operacaoFinanceira}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setOperacaoFinanceira(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo de Lucro
              </Input155px>
              <Dropdown
                autoFocus
                options={listaLucroBruto}
                value={lucroBruto}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setLucroBruto(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
