import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { addLocale } from 'primereact/api';

import * as actions from '../../../../store/modules/auth/actions';

import { displayErrors, converteData, formataDataMoment } from '../../../../util/diversos';
import { Input155px, Input100px } from '../../../../styles/inputGroup';
import { fillColumns } from '../../../../components/table';

import Card from '../../../../components/card';
import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Loading from '../../../loading';

import ConsultaEstampariaPedido from '../estampariaPedido/consultaEstampariaPedido';
import ConsultaVendedor from '../../vendedor/consultaVendedor';

export default function CadastroEstampariaOrdemProducao({ payloadCadastro, childToParentCadastro }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);

  const [showConsultaEstampariaPedido, setShowConsultaEstampariaPedido] = useState(false);
  const [showConsultaVendedor, setShowConsultaVendedor] = useState(false);
  const [showConfirmDeleteItem, setShowConfirmDeleteItem] = useState(false);
  const [idEstampariaPedido, setIdEstampariaPedido] = useState(0);
  const [dataPedido, setDataPedido] = useState(null);
  const [dataInicioProducao, setDataInicioProducao] = useState(null);
  const [dataTerminoProducao, setDataTerminoProducao] = useState(null);
  const [nomePedido, setNomePedido] = useState('');
  const [observacao, setObservacao] = useState('');
  const [garantia, setGarantia] = useState(false);
  const [geracaoAutomatica, setGeracaoAutomatica] = useState(false);
  const [situacao, setSituacao] = useState('PLANEJADA');
  const [idVendedor, setIdVendedor] = useState('');
  const [nomeVendedor, setNomeVendedor] = useState('');
  const [codigoVendedor, setCodigoVendedor] = useState('');
  const [listaSituacaoOrdemProducao] = useState([
    { label: 'Planejada', value: 'PLANEJADA' },
    { label: 'Firme', value: 'FIRME' },
    { label: 'Liberada', value: 'LIBERADA' },
    { label: 'Aberta', value: 'ABERTA' },
    { label: 'Iniciada', value: 'INICIADA' },
    { label: 'Parada', value: 'PARADA' },
    { label: 'Finalizada', value: 'FINALIZADA' },
    { label: 'Cancelada', value: 'CANCELADA' },
  ]);
  const [listaItem, setListaItem] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [showCadastroItem, setShowCadastroItem] = useState(false);
  const [columnsSetupItem] = useState([
    { field: 'item', header: 'Ítem', sort: true },
    { field: 'produto', header: 'Produto', sort: false },
    { field: 'descricao', header: 'Descrição', sort: true },
    { field: 'siglaUnidadeMedida', header: 'Unid', sort: false },
    { field: 'quantidade', header: 'Quantidade', sort: false },
  ]);
  const [item, setItem] = useState(0);
  const [itemQuantidade, setItemQuantidade] = useState(0);
  const [itemObservacao, setItemObservacao] = useState('');
  const [listaPedidoItem, setListaPedidoItem] = useState([]);
  const [itemDisabled, setItemDisabled] = useState(false);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const [isLoading, setIsLoading] = useState(false);

  async function getPedidoItens(idPedido) {
    try {
      setIsLoading(true);

      const listDropdown = [];
      const { data: _pedido } = await axiosAdmin.get(`/estampariapedido/${idPedido}`);

      const _itens = get(_pedido, 'listaEstampariaPedidoItem', []);

      _itens.forEach(element => {
        listDropdown.push({
          label: `[Ítem: ${element.item}]  [${element.produto.descricao}]  [Quantidade: ${element.quantidade}]`,
          value: element.item,
          item: element.item,
          idProduto: element.produto.id,
          descricaoProduto: element.produto.descricao,
          unidadeMedidaProduto: element.produto.unidadeMedida.sigla,
          quantidade: element.quantidade,
        });
      });

      setListaPedidoItem(listDropdown);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    let newTitle = 'Ordem de Produção - Cadastro';
    if (id > 0) {
      newTitle = 'Ordem de Produção - Edição';
    }

    const mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    if (id <= 0) {
      return;
    }

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/estampariaordemproducao/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          displayErrors('Acesso negado', dispatch, navigate, '/consultaestampariaordemproducao', '');
        }

        const _dataPedido = converteData(data.dataPedido, true);

        const _dataInicioProducao = converteData(data.dataInicioProducao, true);

        const _dataTerminoProducao = converteData(data.dataTerminoProducao, true);

        setIdEstampariaPedido(data.idEstampariaPedido);
        setDataPedido(_dataPedido);
        setDataInicioProducao(_dataInicioProducao);
        setDataTerminoProducao(_dataTerminoProducao);
        setNomePedido(data.nomePedido);
        setNomeVendedor(get(data.vendedor, 'nome', ''));
        setIdVendedor(get(data.vendedor, 'id', null));
        setCodigoVendedor(get(data.vendedor, 'codigo', ''));
        setObservacao(data.observacao);
        setGarantia(data.garantia);
        setGeracaoAutomatica(data.geracaoAutomatica);
        setSituacao(data.situacao);

        const _listaItem = [];

        data.listaEstampariaOrdemProducaoItem.forEach(element => {
          _listaItem.push({
            item: element.item,
            produto: get(element.produto, 'id', 0),
            descricao: get(element.produto, 'descricao', ''),
            siglaUnidadeMedida: get(element.produto.unidadeMedida, 'sigla', ''),
            quantidade: element.quantidade,
            observacao: element.observacao,
          });
        });

        setListaItem(_listaItem);

        getPedidoItens(data.idEstampariaPedido);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const idOperador = get(store.getState().auth, 'idOperador', 0);

      let _dataPedido = null;
      if (dataPedido) {
        _dataPedido = formataDataMoment(dataPedido, '-', true);
      }
      let _dataInicioProducao = null;
      if (dataInicioProducao) {
        _dataInicioProducao = formataDataMoment(dataInicioProducao, '-', true);
      }
      let _dataTerminoProducao = null;
      if (dataTerminoProducao) {
        _dataTerminoProducao = formataDataMoment(dataTerminoProducao, '-', true);
      }

      if (idVendedor === null) {
        toast.info('Vendedor deve ser informado.');
        return false;
      }

      const shippingData = {
        idUsuario,
        operador: idOperador,
        dataPedido: _dataPedido,
        dataInicioProducao: _dataInicioProducao,
        dataTerminoProducao: _dataTerminoProducao,
        nomePedido,
        observacao,
        garantia,
        geracaoAutomatica,
        situacao,
        idVendedor,
        idEstampariaPedido,
        listaEstampariaOrdemProducaoItem: listaItem,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/estampariaordemproducao/${id}`, shippingData), 'data', {});
        toast.success('Ordem de Produção atualizada com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/estampariaordemproducao', shippingData), 'data', {});
        toast.success('Ordem de Produção cadastrada com sucesso');
      }

      childToParentCadastro(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentCadastro();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!idEstampariaPedido || idEstampariaPedido <= 0) {
      toast.warning('Por favor, informe o pedido antes de salvar');
      formErros = true;
    }

    if (formErros) return;

    salvarRegistro();
  };

  function limparCamposItem() {
    setItem(0);
    setItemQuantidade(0);
    setItemObservacao('');
  }

  const handleFocus = (event) => event.target.select();

  const titulo = () => (id > 0 ? 'Ordem de Produção - Alteração' : 'Ordem de Produção - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h5>{titulo()}</h5>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const onHideCadastroItem = () => {
    setShowCadastroItem(false);
  };

  const cancelDeleteItem = () => {
    setShowConfirmDeleteItem(false);
  };

  const confirmDeleteItem = () => {
    const _item = get(selectedItens, 'item', 0);

    if (_item > 0) {
      const _listaItem = listaItem;

      const index = _listaItem.indexOf(_listaItem.find(element => element.item === _item));

      if (index >= 0) {
        _listaItem.splice(index, 1);
      }

      setListaItem(_listaItem);
    }

    setShowConfirmDeleteItem(false);
  };

  const confirmDialogFooterItem = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteItem} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteItem} />
    </div>
  );

  const handleDeleteItem = (e) => {
    e.preventDefault();

    const _item = get(selectedItens, 'item', 0);

    if (_item > 0) {
      setShowConfirmDeleteItem(true);
    } else {
      toast.warning('Por favor, selecione o ítem para exclusão');
    }
  };

  const handleIncluirItem = (e) => {
    e.preventDefault();

    if (!idEstampariaPedido || idEstampariaPedido <= 0) {
      toast.warning('Por favor, informe o pedido antes de continuar');
      return;
    }

    setItemDisabled(false);

    limparCamposItem(true);
    setShowCadastroItem(true);
  };

  const handleEditItem = (e) => {
    e.preventDefault();

    if (!idEstampariaPedido || idEstampariaPedido <= 0) {
      toast.warning('Por favor, informe o pedido antes de continuar');
      return;
    }

    const _item = get(selectedItens, 'item', 0);

    if (_item > 0) {
      const _itemSelecionado = listaItem.find(element => element.item === _item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o ítem para edição');
        return;
      }

      limparCamposItem();

      setItem(_item);
      setItemQuantidade(_itemSelecionado.quantidade);
      setItemObservacao(_itemSelecionado.observacao);
      setNomeVendedor(_itemSelecionado.nomeVendedor);
      setIdVendedor(_itemSelecionado.idVendedor);

      setItemDisabled(true);

      setShowCadastroItem(true);
    } else {
      toast.warning('Por favor, selecione o ítem para edição');
    }
  };

  const handleOkItem = () => {
    if (item <= 0) {
      toast.warning('Ítem deve ser informado');
      return;
    }

    const index = listaPedidoItem.indexOf(listaPedidoItem.find(element => element.value === item));

    let itemIdProduto = null;
    let itemDescricaoProduto = null;
    let itemUnidadeProduto = null;

    if (index >= 0) {
      itemIdProduto = listaPedidoItem[index].idProduto;
      itemDescricaoProduto = listaPedidoItem[index].descricaoProduto;
      itemUnidadeProduto = listaPedidoItem[index].unidadeMedidaProduto;
    } else {
      toast.warning('Ítem deve ser informado');
      return;
    }

    if (!itemDisabled && listaItem.find(element => element.item === item) !== undefined) {
      toast.warning('Ítem já cadastrado na Ordem de Produção');
      return;
    }

    if (itemQuantidade <= 0) {
      toast.warning('Quantidade deve ser informada');
      return;
    }

    if (listaPedidoItem[index].quantidade < itemQuantidade) {
      toast.warning('Quantidade não pode ser maior que a quantidade do pedido');
      return;
    }

    const _listaItem = listaItem;

    if (itemDisabled) {
      const _itemExcluir = get(selectedItens, 'item', 0);

      if (_itemExcluir > 0) {
        const indexExcluir = _listaItem.indexOf(_listaItem.find(element => element.item === _itemExcluir));

        if (indexExcluir >= 0) {
          _listaItem.splice(indexExcluir, 1);
        }
      }
    }

    _listaItem.push({
      item,
      produto: itemIdProduto,
      descricao: itemDescricaoProduto,
      siglaUnidadeMedida: itemUnidadeProduto,
      quantidade: itemQuantidade,
      observacao: itemObservacao,
    });

    setListaItem(_listaItem);

    setShowCadastroItem(false);
  };

  const onHideConsultaEstampariaPedido = () => {
    setShowConsultaEstampariaPedido(false);
  };

  const childToParentConsultaEstampariaPedido = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        const _dataPedido = converteData(e.data, true);

        setIdEstampariaPedido(e.id);
        setDataPedido(_dataPedido);
        setNomePedido(e.nomePedido);
        setIdVendedor(e.vendedor.id);
        setNomeVendedor(e.vendedor.nome.trim());

        getPedidoItens(e.id);
      }
      setShowConsultaEstampariaPedido(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentVendedor = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdVendedor(e.id);
      }
      if (e.codigo) {
        setCodigoVendedor(e.codigo);
      }
      if (e.nome) {
        setNomeVendedor(e.nome);
      }
      setShowConsultaVendedor(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const onHideConsultaVendedor = () => {
    setShowConsultaVendedor(false);
  };

  const handleConsultaPedido = () => {
    if (listaItem.length > 0) {
      toast.warning('Pedido só pode ser alterado sem ítem selecionado');
    } else {
      setShowConsultaEstampariaPedido(true);
    }
  };

  // eslint-disable-next-line consistent-return
  async function handleBuscarVendedorByCodigo(e) {
    try {
      setIsLoading(true);

      if (e.length <= 0) {
        setIdVendedor('');
        setNomeVendedor('');
        return false;
      }

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/vendedor?idUsuario=${idUsuario}`;

      params = `${params}&codigo=${e.trim()}`;

      const response = await axiosAdmin.get(params);

      if (response.data.length > 0) {
        setIdVendedor(response.data[0].id);
        setNomeVendedor(response.data[0].nome);
      } else {
        setIdVendedor('');
        setNomeVendedor('');
        toast.info('Nenhum vendedor com esse código encontrado');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Vendedor"
        visible={showConsultaVendedor}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaVendedor()}
      >
        <ConsultaVendedor menuMode={false} childToParent={childToParentVendedor} />
      </Dialog>

      <Dialog
        header="Consulta de Pedidos"
        visible={showConsultaEstampariaPedido}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaEstampariaPedido()}
      >
        <ConsultaEstampariaPedido menuMode={false} childToParent={childToParentConsultaEstampariaPedido} somenteLeitura />
      </Dialog>

      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nº Pedido
              </Input155px>
              <InputNumber
                inputId="minmax-buttons"
                min={0}
                value={idEstampariaPedido}
                onValueChange={e => setIdEstampariaPedido(e.target.value)}
                onFocus={handleFocus}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => handleConsultaPedido()}
              />
            </div>
          </div>
          <div className="col-5">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nome do Pedido
              </Input155px>
              <InputText
                disabled
                maxLength={100}
                value={nomePedido}
                onChange={e => setNomePedido(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Cód. Vendedor
              </Input155px>
              <InputText
                maxLength={255}
                value={codigoVendedor}
                onChange={e => setCodigoVendedor(e.target.value)}
                onBlur={e => handleBuscarVendedorByCodigo(e.target.value)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaVendedor(true)}
              />
            </div>
          </div>
          <div className="col-5">
            <div className="p-inputgroup">
              <InputText
                disabled
                maxLength={255}
                value={nomeVendedor}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Pedido
              </Input155px>
              <Calendar
                disabled
                id="mask"
                showIcon
                showTime
                showSeconds
                hourFormat="24"
                mask="99/99/9999 99:99:99"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataPedido}
                onChange={(e) => setDataPedido(e.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Início Produção
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                showTime
                showSeconds
                hourFormat="24"
                mask="99/99/9999 99:99:99"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataInicioProducao}
                onChange={(e) => setDataInicioProducao(e.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Término Produção
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                showTime
                showSeconds
                hourFormat="24"
                mask="99/99/9999 99:99:99"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataTerminoProducao}
                onChange={(e) => setDataTerminoProducao(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-7">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Observação
              </Input155px>
              <InputText
                maxLength={100}
                value={observacao}
                onChange={e => setObservacao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Situação
              </Input155px>
              <Dropdown
                options={listaSituacaoOrdemProducao}
                value={situacao}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setSituacao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-1" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <div className="field-checkbox">
              <Checkbox
                checked={garantia}
                onChange={e => setGarantia(e.checked)}
              />
              <label htmlFor="binary">
                &nbsp;Garantia
              </label>
            </div>
          </div>
        </div>

        <div className="estamparia-pedido-item">
          <DataTable
            value={listaItem}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField="item"
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="item"
            emptyMessage="Nenhum Ítem cadastrado"
          >
            {fillColumns(columnsSetupItem)}
          </DataTable>
        </div>

        <br />

        <Dialog
          header="Confirmação"
          visible={showConfirmDeleteItem}
          style={{ width: '50vw' }}
          footer={confirmDialogFooterItem}
          onHide={() => setShowConfirmDeleteItem(false)}
        >
          <p>
            Excluir o ítem?
          </p>
        </Dialog>

        <Dialog
          header="Ordem de Produção - Ítem do Pedido"
          visible={showCadastroItem}
          style={{ width: '98vw' }}
          onHide={() => onHideCadastroItem()}
        >
          <div className="row form-elo">
            <div className="col-9">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Item
                </Input155px>
                <Dropdown
                  disabled={itemDisabled}
                  options={listaPedidoItem}
                  value={item}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={e => setItem(e.target.value)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Quantidade
                </Input155px>
                <InputNumber
                  mode="decimal"
                  inputId="minmaxfraction"
                  minFractionDigits={3}
                  maxFractionDigits={3}
                  value={itemQuantidade}
                  onValueChange={e => setItemQuantidade(e.target.value)}
                  onFocus={handleFocus}
                />
              </div>
            </div>
          </div>
          <div className="row form-elo">
            <div className="col-12">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Observação
                </Input155px>
                <InputText
                  maxLength={100}
                  value={itemObservacao}
                  onChange={e => setItemObservacao(e.target.value)}
                />
              </div>
            </div>
          </div>

          <div className="row form-elo">
            <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleOkItem}
              >
                <i className="pi pi-check"></i>
                Ok
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => setShowCadastroItem(false)}
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </Dialog>

        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              title="Incluir Ítem"
              className="btn btn-primary"
              style={{ borderRadius: '15px' }}
              onClick={(e) => handleIncluirItem(e)}
            >
              <i className="pi pi-plus"></i>
            </button>
            <button
              type="button"
              title="Alterar Ítem"
              className="btn btn-warning"
              style={{ borderRadius: '15px' }}
              onClick={(e) => handleEditItem(e)}
            >
              <i className="pi pi-pencil"></i>
            </button>
            <button
              type="button"
              title="Cancelar Ítem"
              className="btn btn-danger"
              style={{ borderRadius: '15px' }}
              onClick={(e) => handleDeleteItem(e)}
            >
              <i className="pi pi-trash"></i>
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
