import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input100px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function CadastroCentroCusto({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);

  const [listaFinanceiroDre, setListaFinanceiroDre] = useState([]);
  const [codigo, setCodigo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [operacaoFinanceira, setOperacaoFinanceira] = useState('PAGAR');
  const [financeiroDre, setFinanceiroDre] = useState(0);
  const [listaOperacaoFinanceira] = useState([
    { label: 'Pagar', value: 'PAGAR' },
    { label: 'Receber', value: 'RECEBER' },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let newTitle = 'Centro de Custo - Cadastro';
    if (id) {
      newTitle = 'Centro de Custo - Edição';
    }

    let mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getFinanceiroDre() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];

        const { data: _financeiroDre } = await axiosAdmin.get(`/financeirodre?idUsuario=${idUsuario}`);

        _financeiroDre.forEach(element => {
          listDropdown.push({ label: `${element.descricao}`, value: element.id });
        });

        setListaFinanceiroDre(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    getFinanceiroDre();

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/centrocusto/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultacentrocusto');
          toast.error('Acesso negado');
        }

        setCodigo(data.codigo);
        setDescricao(data.descricao);
        setOperacaoFinanceira(data.operacaoFinanceira);
        setFinanceiroDre(get(data, 'financeiroDre.id', null));
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const shippingData = {
        idUsuario,
        codigo,
        descricao,
        operacaoFinanceira,
        financeiroDre,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/centrocusto/${id}`, shippingData), 'data', {});
        toast.success('Centro de Custo atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/centrocusto', shippingData), 'data', {});
        toast.success('Centro de Custo cadastrado com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!descricao) {
      formErros = true;
      toast.error('Descrição deve ser informada');
    }
    if (!financeiroDre) {
      formErros = true;
      toast.error('DRE deve ser informado');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id ? 'Centro de Custo - Alteração' : 'Centro de Custo - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>{titulo()}</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Código
              </Input100px>
              <InputText
                autoFocus
                maxLength={60}
                value={codigo}
                onChange={e => setCodigo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-9">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Descrição
              </Input100px>
              <InputText
                maxLength={100}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Operação
              </Input100px>
              <Dropdown
                options={listaOperacaoFinanceira}
                value={operacaoFinanceira}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setOperacaoFinanceira(e.target.value)}
              />
            </div>
          </div>
          <div className="col-9">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                DRE
              </Input100px>
              <Dropdown
                value={financeiroDre}
                options={listaFinanceiroDre}
                onChange={(e) => setFinanceiroDre(e.value)}
                filter
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
