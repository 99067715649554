/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unneeded-ternary */
import React, { useState, useEffect, useRef } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get, orderBy, isNumber, findLast, isEmpty } from 'lodash';

import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Base64 } from 'js-base64';
import moment from 'moment';
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';

import useSound from 'use-sound';
import mySound from '../../../sounds/notify.wav';

import * as actions from '../../../store/modules/auth/actions';

import { soNumeros, displayErrors, formataDataMoment, formataMoeda, arredondar, replaceAll, converteData, formataDataArray } from '../../../util/diversos';
import { Input100px, Input155px, Input400px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

import ConsultaParticipante from '../participante/consultaParticipante';
import ConsultaProduto from '../produto/consultaProduto';
import ConsultaNFCe from './consultaNFCe';

export default function EmissaoNFCe() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [playSound] = useSound(mySound);

  const UseFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus(); };

    return [htmlElRef, setFocus];
  };

  const [inputDadosEntradaFocus, setInputDadosEntradaFocus] = UseFocus();

  const [empresaId, setEmpresaId] = useState(0);
  const [empresaRegimeTributario, setEmpresaRegimeTributario] = useState('SIMPLESNACIONAL');
  const [empresaTipoAmbiente, setEmpresaTipoAmbiente] = useState('');
  const [empresaDigitosBalanca, setEmpresaDigitosBalanca] = useState(1);
  const [empresaBalancaPorValor, setEmpresaBalancaPorValor] = useState(true);
  const [empresaAliquotaPis, setEmpresaAliquotaPis] = useState();
  const [empresaAliquotaCofins, setEmpresaAliquotaCofins] = useState();

  const [itemOrig, setItemOrig] = useState(0);
  const [itemNcm, setItemNcm] = useState('');

  const [totalIbptEstadual, setTotalIbptEstadual] = useState(0);
  const [totalIbptMunicipal, setTotalIbptMunicipal] = useState(0);
  const [totalIbptNacionalFederal, setTotalIbptNacionalFederal] = useState(0);

  const idUsuario = get(store.getState().auth.user, 'id', 0);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [razaoSocialEmpresa, setRazaoSocialEmpresa] = useState('');
  const [serieEmpresa, setSerieEmpresa] = useState('');
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const mod = '65';
  const [nNf, setNNf] = useState('1');
  const [alterarNumeroCupom, setAlterarNumeroCupom] = useState('1');

  const [dadosEntrada, setDadosEntrada] = useState('');
  const [mudarEmpresaDisable, setMudarEmpresaDisable] = useState(true);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [selectedProdutos, setSelectedProdutos] = useState(null);
  const [totalCupom, setTotalCupom] = useState(formataMoeda(0));
  const [totalVolumes, setTotalVolumes] = useState(0);

  const [listaPagamentos, setListaPagamentos] = useState([]);

  const [pagoDinheiro, setPagoDinheiro] = useState(0);
  const [pagoCheque, setPagoCheque] = useState(0);
  const [pagoCartao, setPagoCartao] = useState(0);
  const [pagoDigital, setPagoDigital] = useState(0);
  const [valorDesconto, setValorDesconto] = useState(0);
  const [valorPago, setValorPago] = useState(0);
  const [valorTroco, setValorTroco] = useState(0);

  const [listaTiposCartoes, setListaTiposCartoes] = useState([
    { label: 'Crédito', value: 'CREDITO' },
    { label: 'Débito', value: 'DEBITO' },
  ]);

  const [listaTiposPix, setListaTiposPix] = useState([
    { label: 'Pix', value: 'PIX' },
    { label: 'GooglePlay', value: 'GOOGLEPLAY' },
    { label: 'Pic Pay', value: 'PICPAY' },
    { label: 'Mercado Pago', value: 'MERCADOPAGO' },
  ]);

  const [listaPagamentosCartoes, setListaPagamentosCartoes] = useState([]);
  const [selectedPagamentoCartao, setSelectedPagamentoCartao] = useState(null);
  const [tipoCartao, setTipoCartao] = useState('CREDITO');
  const [numeroParcelasCartao, setNumeroParcelasCartao] = useState(1);
  const [valorParcelamentoCartao, setValorParcelamentoCartao] = useState(0);
  const [valorRestanteCartao, setValorRestanteCartao] = useState(0);

  const [listaPagamentosPix, setListaPagamentosPix] = useState([]);
  const [selectedPagamentoPix, setSelectedPagamentoPix] = useState(null);
  const [tipoPix, setTipoPix] = useState('PIX');
  const [valorPagamentoPix, setValorPagamentoPix] = useState(0);
  const [valorRestantePix, setValorRestantePix] = useState(0);

  const [showEscolhaEmpresaDialog, setShowEscolhaEmpresaDialog] = useState(false);
  const [showComandosDialog, setShowComandosDialog] = useState(false);
  const [showAdministrativoDialog, setShowAdministrativoDialog] = useState(false);
  const [showConsultaProduto, setShowConsultaProduto] = useState(false);
  const [showConfirmDeleteProdutoDialog, setShowConfirmDeleteProdutoDialog] = useState(false);
  const [showConfirmCancelarCupomDialog, setShowConfirmCancelarCupomDialog] = useState(false);
  const [showProdutoNaoPodeSerFracionado, setShowProdutoNaoPodeSerFracionado] = useState(false);
  const [showCodigoProdutoInvalido, setShowCodigoProdutoInvalido] = useState(false);
  const [showQuantidadeProdutoInvalida, setShowQuantidadeProdutoInvalida] = useState(false);
  const [showProdutoNaoEncontrado, setShowProdutoNaoEncontrado] = useState(false);
  const [showPagamentoDialog, setShowPagamentoDialog] = useState(false);
  const [showConsultaParticipante, setShowConsultaParticipante] = useState(false);
  const [showPagamentoCartaoDialog, setShowPagamentoCartaoDialog] = useState(false);
  const [showPagamentoPixDialog, setShowPagamentoPixDialog] = useState(false);
  const [showConsultaNFCe, setShowConsultaNFCe] = useState(false);
  const [showAlterarNumeroCupom, setShowAlterarNumeroCupom] = useState(false);

  const [liberarFechamentoCupom, setLiberarFechamentoCupom] = useState(false);

  // backend necessario na nfCe, analisar se vai precisar na nfce
  const [flag, setFlag] = useState('D'); // D = digitada , T = transmitida
  const [chaveAcesso, setChaveAcesso] = useState('');
  const [protocolo, setProtocolo] = useState('');
  const [numeroRecibo, setNumeroRecibo] = useState('');
  const [emissaoPropria, setEmissaoPropria] = useState('true');

  // nfce header
  const [dhEmi, setDhEmi] = useState(new Date());
  const [dhEnvio, setDhEnvio] = useState(new Date()); // verificar essa informacao
  const [numeroNf, setNumeroNf] = useState(0); // setado puxando o ultimo numero de nfce
  const [serie, setSerie] = useState(serieEmpresa);
  const [codSit, setCodSit] = useState('REGULAR');
  const [tpEmis, setTpEmis] = useState('NORMAL');
  const [idParticipante, setIdParticipante] = useState(null);
  const [clienteCnpj, setClienteCnpj] = useState('');
  const [clienteIe, setClienteIe] = useState('');
  const [clienteNome, setClienteNome] = useState('');
  const [clienteEMail, setClienteEMail] = useState('');
  const [infoAdFisco, setInfoAdFisco] = useState('');
  const [infCpl, setInfCpl] = useState('');

  const [totalVBC, setTotalVBC] = useState(0);
  const [totalVICMS, setTotalVICMS] = useState(0);
  const [totalVICMSDeson, setTotalVICMSDeson] = useState(0);

  const [totalVBCST, setTotalVBCST] = useState(0);
  const [totalVST, setTotalVST] = useState(0);

  const [totalVFCP, setTotalVFCP] = useState(0);
  const [totalVFCPST, setTotalVFCPST] = useState(0);
  const [totalVFCPStRet, setTotalVFCPStRet] = useState(0);

  const [totalVProd, setTotalVProd] = useState(0);
  const [totalVFrete, setTotalVFrete] = useState(0);
  const [totalVSeg, setTotalVSeg] = useState(0);
  const [totalVDesc, setTotalVDesc] = useState(0);

  const [totalVII, setTotalVII] = useState(0);

  const [totalVIPI, setTotalVIPI] = useState(0);
  const [totalVIPIDevol, setTotalVIPIDevol] = useState(0);

  const [totalVPIS, setTotalVPIS] = useState(0);
  const [totalVCOFINS, setTotalVCOFINS] = useState(0);

  const [totalVOutro, setTotalVOutro] = useState(0);

  const [totalVICMSIsento, setTotalVICMSIsento] = useState(0);
  const [totalVICMSOutro, setTotalVICMSOutro] = useState(0);

  const [totalVNF, setTotalVNF] = useState(0);

  const [totalVTotTrib, setTotalVTotTrib] = useState(0);

  const [vTroco, setVTroco] = useState(0);

  const fontSizeTitulo = (window.screen.availWidth / window.screen.availHeight) * 25;
  const fontSizeDadosEntrada = (window.screen.availWidth / window.screen.availHeight) * 20;
  const alturaScrollProdutos = window.innerHeight * 0.6;

  async function setUltimoNumero(serie) {
    try {
      (serie === '') ? serie = '1' : '';

      await axiosAdmin.get(`/nfce/ultimonumero?cnpjEmpresa=${cnpjEmpresa}&serie=${serie}`).then(res => {
        const ultimoNumero = res.data;
        if (res.data !== null) {
          const proximoNumero = parseInt(ultimoNumero, 10) + 1;
          setNNf(proximoNumero.toString());
        }
      });
    } catch (err) {
      toast.warn('Não foi possível achar o número da nota');
    }
  }

  useEffect(() => {
    async function verificarSeMultiEmpresa() {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;
        const response = await axiosAdmin.get(params);
        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        const _cnpjConsultaPrevisaoFinanceiraCjpEmpresa = store.getState().auth.consultaPrevisaoFinanceiraCnpjEmpresa;

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '') {
          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            const consultaPrevisaoFinanceiraCnpjEmpresa = _cnpjEmpresaStore;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            cnpjPreenchido = true;
          }
        }

        _listaEmpresas.forEach(element => {
          if (element.ativo) {
            if (!cnpjPreenchido && (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '')) {
              setEmpresaId(element.id);
              setCnpjEmpresa(element.cnpj);
              setSerieEmpresa(element.nfeSerie);
              setEmpresaDigitosBalanca(element.balancaDigitosCodigo);
              setEmpresaAliquotaPis(element.aliquotaPis);
              setEmpresaAliquotaCofins(element.aliquotaCofins);
              const consultaPrevisaoFinanceiraCnpjEmpresa = element.cnpj;
              dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
              _cnpjEmpresaStore = element.cnpj;
              cnpjPreenchido = true;
            }

            listDropdown.push({
              id: element.id,
              label: element.razaoSocial.trim(),
              value: element.cnpj,
              serie: element.nfeSerie,
              uf: element.uf,
              nfceAmbiente: element.nfceAmbiente,
              digitosBalanca: element.balancaDigitosCodigo,
              balancaPorValor: element.balancaPorValor,
              aliquotaPis: element.aliquotaPis,
              aliquotaCofins: element.aliquotaCofins,
              regimeTributario: element.regimeTributario,
            });
          }
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (!cnpjPreenchido && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== null && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== '') {
          setCnpjEmpresa(_cnpjConsultaPrevisaoFinanceiraCjpEmpresa);
          _cnpjEmpresaStore = _cnpjConsultaPrevisaoFinanceiraCjpEmpresa;
        }

        if (listDropdown.length > 1) {
          setShowEscolhaEmpresaDialog(true);
        }

        // console.log(1024 * 1024 * 5 - unescape(encodeURIComponent(JSON.stringify(localStorage))).length);

        // getData(searchFilter, _cnpjEmpresaStore, 0, lazyParams.rows);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    verificarSeMultiEmpresa();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setUltimoNumero(serieEmpresa.toString());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serieEmpresa]);

  const renderTipoAmbiente = () => {
    if (empresaTipoAmbiente === 'HOMOLOGACAO') {
      return (
        <h6 className="card-header" style={{ textAlign: 'center', fontSize: `${fontSizeTitulo - 20}px` }}>
          AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL
        </h6>
      );
    }
    return '';
  };

  const zerarTotaisCupom = () => {
    setTotalVST(0);
    setTotalVSeg(0);
    setTotalVOutro(0);
    setTotalVBC(0);
    setTotalVBCST(0);
    setTotalVCOFINS(0);
    setTotalVDesc(0);
    setTotalVFCP(0);
    setTotalVFCPST(0);
    setTotalVFCPStRet(0);
    setTotalVFrete(0);
    setTotalVICMS(0);
    setTotalVICMSDeson(0);
    setTotalVICMSIsento(0);
    setTotalVICMSOutro(0);
    setTotalVICMSOutro(0);
    setTotalVII(0);
    setTotalVIPI(0);
    setTotalVIPIDevol(0);
    setTotalVPIS(0);
    setTotalVProd(0);
  };

  const fecharDialogos = () => {
    zerarTotaisCupom();
    setShowPagamentoDialog(false);
    setShowEscolhaEmpresaDialog(false);
    setShowAdministrativoDialog(false);
    setShowComandosDialog(false);
    setShowConfirmDeleteProdutoDialog(false);
    setShowConsultaProduto(false);
    setShowConsultaNFCe(false);
  };

  const inicializarCupom = () => {
    setListaProdutos([]);
    setSelectedProdutos(null);
    setTotalCupom(0);
    setTotalVolumes(0);
    setTotalIbptEstadual(0);
    setTotalIbptMunicipal(0);
    setTotalIbptNacionalFederal(0);

    fecharDialogos();
    setInputDadosEntradaFocus();
  };

  useEffect(() => {
    let empresa = 0;
    listaEmpresas.forEach(el => {
      if (el.value === cnpjEmpresa) {
        empresa = el.id;
        setEmpresaId(el.id);
        setSerieEmpresa(el.serie);
        setEmpresaDigitosBalanca(el.digitosBalanca);
        setEmpresaBalancaPorValor(el.balancaPorValor);
        setEmpresaTipoAmbiente(el.nfceAmbiente);
        setEmpresaAliquotaCofins(el.aliquotaCofins);
        setEmpresaAliquotaPis(el.aliquotaPis);
        setTotalVolumes(0);
        setUltimoNumero(el.Serie);
        setEmpresaRegimeTributario(el.regimeTributario);
      }
    });
    setShowPagamentoDialog(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpjEmpresa]);

  const handleCancelarCupom = () => {
    if (listaProdutos.length > 0) {
      setShowConfirmCancelarCupomDialog(true);
      setShowComandosDialog(false);
      return false;
    }
    toast.info('Nenhum cupom em aberto para cancelamento');
  };

  const handleConsultaProdutos = () => {
    setShowComandosDialog(false);
    setShowConsultaProduto(true);
  };

  const handleConsultaNFCe = () => {
    setShowComandosDialog(false);
    setShowConsultaNFCe(true);
  };

  const handleComandos = () => {
    setShowComandosDialog(true);
  };

  const handleAdministrativo = () => {
    setShowAdministrativoDialog(true);
  };

  // eslint-disable-next-line consistent-return
  const getProduto = async (codigo) => {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/produto?idUsuario=${idUsuario}`;

      params = `${params}&cnpjEmpresa=${cnpjEmpresa}`;

      if (codigo.length < 8) {
        params = `${params}&registro=${codigo}`;
      } else if ((codigo.length === 8 || codigo.length === 13)) {
        params = `${params}&codigoEan=${codigo}`;
      }

      const response = await axiosAdmin.get(params);

      if (response.data.length > 0) {
        return response.data;
      } else {
        setShowProdutoNaoEncontrado(true);
        playSound();
        return null;
      }
    } catch (err) {
      toast.warn('Produto não encontrado ou falha de conexão' + err);
    } finally {
      setIsLoading(false);
    }
  };

  const calcularTotalVolumes = (listaProdutos) => {
    const _listaProdutos = [...listaProdutos];
    let totalVolumes = 0;
    totalVolumes = _listaProdutos.reduce((totalVolumes, item) => {
      let qtde = Number(item.qCom);
      qtde = Number.isInteger(qtde) ? qtde : 1;
      return totalVolumes + qtde;
    }, 0);
    setTotalVolumes(totalVolumes);
  };

  const calcularTotalCupom = (listaProdutos) => {
    const _listaProdutos = [...listaProdutos];
    let total = 0;
    total = _listaProdutos.reduce((total, item) => {
      const valor = item.totalItem ? item.totalItem.replace('R$', '').replace('.', '').replace(',', '.') : 0;
      return total + +valor;
    }, 0);
    setTotalCupom(total);
  };

  const isCodigoBalanca = (dadosEntrada) => {
    // ex: 2000100002044 -> 5 digitos balanca
    // ex: 2000010002044 -> 6 digitos balanca
    // console.log('digitos: ', empresaDigitosBalanca, 'por valor: ', empresaBalancaPorValor);
    if (dadosEntrada.length === 13 && dadosEntrada[0] === '2' && !dadosEntrada.includes('*')) {
      return true;
    }
    return false;
  };

  const isSomenteCodigo = (dadosEntrada) => {
    if (dadosEntrada.length < 8 && !dadosEntrada.includes(',') && !dadosEntrada.includes('*')) {
      return true;
    }
    return false;
  };

  const isCodigoEAN = (dadosEntrada) => {
    // 7891024183014 exemplo
    // 7898380260427
    const primeiroDigito = dadosEntrada[0];

    if (dadosEntrada.length > 7 && !dadosEntrada.includes(',') && !dadosEntrada.includes('*') && primeiroDigito !== '2') {
      return true;
    }
    return false;
  };

  const isValidoCodigoValorEQtde = (dados) => {
    if ((!Number(dados.codigo) || Number(dados.codigo) < 0) && dados.valor === 0) {
      return false;
    }

    if (!Number(dados.qtde) || Number(dados.qtde) > 9999999 || Number(dados.qtde) <= 0) {
      return false;
    }

    return true;
  };

  const tratarDadosEntrada = async (dadosEntrada) => {
    const dados = {
      qtde: '1',
      codigo: '0',
      valor: 0,
      tipo: null,
    };

    if (isCodigoBalanca(dadosEntrada)) {
      dados.tipo = 'CODIGOBALANCA';
      dados.codigo = Number(dadosEntrada.substring(1, empresaDigitosBalanca)).toString();

      if (empresaBalancaPorValor) {
        dados.valor = Number(dadosEntrada.substring(7, 10) + '.' + dadosEntrada.substring(10, 12));
      } else {
        dados.qtde = Number(dadosEntrada.substring(7, 10) + '.' + dadosEntrada.substring(10, 12)).toString();
      }
    }

    if (isSomenteCodigo(dadosEntrada)) {
      dados.tipo = 'CODIGO';
      dados.codigo = dadosEntrada;
    }

    if (isCodigoEAN(dadosEntrada)) {
      dados.tipo = 'CODIGOEAN';
      dados.codigo = dadosEntrada;
    }

    if (dadosEntrada.includes('*')) {
      dados.tipo = 'QTDEECODIGO';
      const dadosSeparados = dadosEntrada.split('*');
      // eslint-disable-next-line prefer-destructuring
      dados.qtde = dadosSeparados[0];
      // eslint-disable-next-line prefer-destructuring
      dados.codigo = dadosSeparados[1];
    }

    if (dados.qtde.includes(',')) {
      dados.qtde = dados.qtde.replace('.', '').replace(',', '.');
    }

    if (dados.qtde.length > 8) {
      dados.qtde = dados.qtde.substring(0, 8);
    }

    if (!isValidoCodigoValorEQtde(dados)) {
      setDadosEntrada('');
      playSound();
      setShowCodigoProdutoInvalido(true);
      return false;
    }

    return dados;
  };

  const calculaTotTribItem = async (dados) => {
    let valorTotTrib = 0;
    let totalMunicipal = 0;
    let totalEstadual = 0;
    let totalFederal = 0;

    totalMunicipal = (+dados.vProd - +dados.vDesc) * (+dados.ibptAliqMunicipal / 100);
    setTotalIbptMunicipal(totalIbptMunicipal + totalMunicipal);
    valorTotTrib += totalMunicipal;

    totalEstadual = (+dados.vProd - +dados.vDesc) * (+dados.ibptAliqEstadual / 100);
    setTotalIbptEstadual(totalIbptEstadual + totalEstadual);
    valorTotTrib += totalEstadual;

    totalFederal = (+dados.vProd - +dados.vDesc) * (+dados.ibptAliqNacionalFederal / 100);
    setTotalIbptNacionalFederal(totalIbptNacionalFederal + totalFederal);
    valorTotTrib += totalFederal;

    dados.vTotTrib = arredondar(valorTotTrib, -2);
  };

  const preencherProdutoAliquotasIbpt = async (dados) => {
    try {
      setIsLoading(true);

      const { data: _ibpt } = await axiosAdmin.get(`/ibpt/?ncm=${dados.ncm}`);

      _ibpt.forEach(element => {
        dados.ibptAliqEstadual = element.estadual;
        dados.ibptAliqMunicipal = element.municipal;
        dados.ibptAliqNacionalFederal = element.nacionalFederal;
      });
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  const onHideConfirmDeleteProduto = () => {
    setShowConfirmDeleteProdutoDialog(false);
    setInputDadosEntradaFocus();
  };

  const handleConfirmDeleteProduto = () => {
    const _listaProdutos = listaProdutos.filter(el => el.item !== selectedProdutos.item);

    // reordenar o item na lista
    _listaProdutos.forEach(element => {
      if (element.item > selectedProdutos.item) {
        const _index = _listaProdutos.indexOf(element);

        _listaProdutos[_index].item = _listaProdutos[_index].item - 1;
      }
    });

    setListaProdutos(_listaProdutos);
    setShowConfirmDeleteProdutoDialog(false);
    setSelectedProdutos(null);
    calcularTotalCupom(_listaProdutos);
    calcularTotalVolumes(_listaProdutos);
    setInputDadosEntradaFocus();
  };

  const handleDeleteProduto = async () => {
    if (selectedProdutos) {
      setShowConfirmDeleteProdutoDialog(true);
    }
  };

  const atualizarListaProdutos = async (produto, dados) => {
    listaProdutos.unshift({
      item: listaProdutos.length + 1,
      descricao: produto[0].descricaoResumida ? produto[0].descricaoResumida : produto[0].descricao,
      uCom: produto[0].unidadeMedida.sigla ? produto[0].unidadeMedida.sigla : 'UN',
      qCom: dados.qtde === 0 ? 1 : dados.qtde.replace('.', ','),
      precoBruto: formataMoeda(produto[0].precoVenda),
      totalItem: formataMoeda(+produto[0].precoVenda * +dados.qtde),
      ...dados,
    });
  };

  const preencherDadosProduto = async (produto, dados) => {
    dados.idProduto = produto[0].id;
    dados.orig = produto[0].origemMercadoria;
    dados.cfop = '5' + produto[0].cfopVenda; // ??
    dados.cst = '0' + produto[0].cstVenda;
    dados.csosn = produto[0].csosnVenda;
    dados.ncm = produto[0].ncm;
    dados.cest = produto[0].cest.trim();
    dados.naturezaReceita = produto[0].naturezaReceita;
    dados.movimentacaoFisica = true;

    dados.pisCst = produto[0].pisCofinsSaidaCst;
    dados.pisPPis = empresaAliquotaPis;
    dados.pisVAliqProd = 0; // nao tem no cadastro do produto ??
    dados.pisIndSomaPisSt = 0; // nao tem no cadastro do produto ??

    dados.cofinsCst = produto[0].pisCofinsSaidaCst;
    dados.cofinsPCofins = empresaAliquotaCofins;
    dados.cofinsVAliqProd = 0; // nao tem no cadastro do produto ??
    dados.cofinsIndSomaCofinsSt = 0; // nao tem no cadastro do produto ??

    dados.indTot = 'COMPOE'; // ??

    dados.mva = produto[0].mva;
    dados.modBc = produto[0].icmsModalidadeBc;
    dados.pRedBc = produto[0].icmsPRedBc;
    dados.pIcms = produto[0].icmsAliquota;

    dados.mvaSt = produto[0].mvaSt;
    dados.modBcSt = produto[0].icmsModalidadeBcSt;
    dados.pRedBcSt = produto[0].icmsPRedBcSt;
    dados.pIcmsSt = produto[0].icmsAliquotaSt;

    dados.pFcp = produto[0].icmsAliquotaFcp;

    dados.pDif = 0; // nao tem esse campo no produto

    dados.pCredSn = 0; // nao tem esse campo no produto

    dados.medCProdAnvisa = ''; // nao tem esse campo no produto
    dados.medXMotivoIsencao = ''; // nao tem esse campo no produto
    dados.medVPmc = 0; // nao tem esse campo no produto

    dados.uCom = produto[0].unidadeMedida.sigla;
    dados.qCom = dados.qtde;
    dados.vUnCom = produto[0].precoVenda;
    dados.precoBruto = produto[0].precoVenda;
    dados.vProd = +dados.precoBruto * dados.qCom;

    dados.vDesc = 0; // calcular no fechamento
    dados.vFrete = 0; // ???
    dados.vSeg = 0; // ??
    dados.vOutro = 0; // ??

    dados.uTrib = dados.uCom;
    dados.qTrib = dados.qCom;
    dados.vUnTrib = dados.vUnCom;

    await preencherProdutoAliquotasIbpt(dados);

    await calculaTotTribItem(dados);
  };

  const calcularProdutoImpostos = async () => {
    const percDesc = +valorDesconto / +totalCupom;

    listaProdutos.forEach(el => {
      const valorDescontado = percDesc > 0 ? (el.precoBruto * percDesc).toFixed(2) : 0;
      const totalItem = el.qCom * (el.vUnCom - el.vDesc);

      if (empresaRegimeTributario === 'SIMPLESNACIONAL') {
        el.pIcms = 0;
      }

      el.vBc = 0;
      if (el.pIcms <= 0) {
        el.pRedBc = 0;
      }

      el.vIcms = 0;
      if (el.pIcms > 0) {
        if (el.pRedBc > 0) {
          el.vBc = totalItem - arredondar(totalItem * (el.pRedBc / 100), -2);
        } else {
          el.vBc = totalItem;
        }
        el.vIcms = el.vBc * (el.pIcms / 100);
      }

      el.vFcp = 0;
      el.vBcFcp = 0;
      if (el.pFcp > 0) {
        el.vBcFcp = totalItem;
        el.vFcp = arredondar(totalItem * (el.pFcp / 100), -2);
      }

      let cstTributado = 'N';
      let calcularSubstituicao = 'N';
      let cstIsenta = 'N';
      let cstOutras = 'N';

      const cst = el.cst.substring(1, 3);

      ['00', '10', '20'].includes(cst) ? cstTributado = 'S' : '';
      ['10', '30', '70'].includes(cst) ? calcularSubstituicao = 'S' : '';
      ['20', '30', '40', '41'].includes(cst) ? cstIsenta = 'S' : '';
      ['50', '51', '60', '70', '90'].includes(cst) ? cstOutras = 'S' : '';

      let baseSubstituicao = 0;
      let valorSubstituicao = 0;

      el.ipiPIpi = el.ipiPIpi !== undefined ? el.ipiPIpi : 0;
      el.pMvaSt = el.pMvaSt !== undefined ? el.pMvaSt : 0;

      if (calcularSubstituicao === 'S') {
        if (el.pRedBcSt > 0) {
          el.vBcSt = totalItem - arredondar(totalItem * (el.pRedBcSt / 100), -2);
        } else {
          el.vBcSt = totalItem;
        }

        if (el.pIcmsSt <= 0) {
          el.vBcSt = 0;
        }

        el.vIcmsSt = arredondar(el.vBcSt * (el.pIcmsSt / 100), -2);

        baseSubstituicao = arredondar((el.vBcSt + (totalItem * (el.ipiPIpi / 100))) * (1 + el.pMvaSt / 100), -2);
        valorSubstituicao = arredondar(baseSubstituicao * (el.pIcmsSt / 100), -2);

        if (valorSubstituicao < 0) {
          valorSubstituicao = 0;
        }
      }

      el.vBcSt = baseSubstituicao;
      el.vIcmsSt = valorSubstituicao;

      (el.pIcms <= 0 && cstIsenta === 'S') ? el.vICMSIsento = totalItem : 0;

      let valorICMSIsento = 0;
      let valorICMSOutro = 0;

      if (el.pIcms > 0 && el.pRedBc > 0) {
        valorICMSIsento = totalItem - el.vBc - el.ipiVIpi;
      }
      if (el.pIcms <= 0) {
        if (cstIsenta === 'S') {
          valorICMSIsento = totalItem;
        }
      }

      el.vICMSIsento = valorICMSIsento > 0 ? valorICMSIsento : 0;

      if (el.pIcms > 0 && el.vIcmsSt <= 0) {
        valorICMSOutro = arredondar((totalItem - el.vBc) + el.vIcmsSt + el.ipiVIpi - el.vICMSIsento, -2);
      } else {
        valorICMSOutro = arredondar(totalItem + el.vIcmsSt + el.ipiVIpi - el.vICMSIsento, -2);
      }

      el.vICMSOutro = valorICMSOutro > 0 ? valorICMSOutro : 0;

      el.pisVBc = 0;
      el.pisVPis = 0;
      if (el.pisCst !== '05' && el.pisCst !== '07' && el.pisCst !== '08' && el.pisCst !== '09') {
        if (el.pisPPis > 0) {
          el.pisVBc = totalItem;
          const pisValor = arredondar(totalItem * (el.pisPPis / 100), -2);
          el.pisVPis = pisValor > 0 ? pisValor : 0;
        }
      }

      el.cofinsVBc = 0;
      el.cofinsVCofins = 0;
      if (el.cofinsCst !== '05' && el.cofinsCst !== '07' && el.cofinsCst !== '08' && el.cofinsCst !== '09') {
        if (el.cofinsPCofins > 0) {
          el.cofinsVBc = totalItem;
          const cofinsValor = arredondar(totalItem * (el.cofinsPCofins / 100), -2);
          el.cofinsVCofins = cofinsValor > 0 ? cofinsValor : 0;
        }
      }

      el.vCredIcmsSn = 0;
      if (el.pCredSn > 0) {
        const valorCredito = arredondar(totalItem * (el.pCredSn / 100), -2);
        el.vCredIcmsSn = valorCredito > 0 ? valorCredito : 0;
      }

      el.totalItem = totalItem;
    });
  };

  const montaShippingData = async (_listaProdutos) => {
    let _dhEmi = null;
    if (dhEmi) {
      _dhEmi = formataDataMoment(dhEmi, '-', true);
    }

    const _dhEnvio = formataDataMoment(dhEmi, '-', true);

    const idUsuario = get(store.getState().auth.user, 'id', 0);

    let _totalVBC = 0;
    let _totalVICMS = 0;

    const _totalVICMSDeson = 0; // nao tem no item e tem no cupom

    let _totalVBCST = 0;
    let _totalVST = 0;

    const _totalVIPI = 0;
    const _totalVIPIDevol = 0;

    let _totalVPIS = 0;

    let _totalVCOFINS = 0;

    let _totalVFCP = 0;
    const _totalVFCPST = 0; // nao tem no item e tem no cupom
    const _totalVFCPStRet = 0; // nao tem no item e tem no cupom

    const _totalVII = 0; // nao tem no item

    let _totalVProd = 0;

    let _totalVFrete = 0;
    let _totalVSeg = 0;

    let _totalVICMSOutro = 0;
    let _totalVICMSIsento = 0;

    let _totalVOutro = 0;

    let _totalVTotTrib = 0;

    const _vTroco = 0;

    listaProdutos.forEach(async (el) => {
      _totalVBC = _totalVBC + +el.vBc;
      _totalVICMS = _totalVICMS + +el.vIcms;

      _totalVBCST = _totalVBCST + +el.vBcSt;
      _totalVST = _totalVST + +el.vIcmsSt;

      _totalVPIS = _totalVPIS + +el.pisVPis;

      _totalVCOFINS = _totalVCOFINS + +el.cofinsVCofins;

      _totalVFCP = _totalVFCP + +el.vFcp;

      _totalVProd = _totalVProd + +el.vProd;

      _totalVFrete = _totalVFrete + +el.vFrete;
      _totalVSeg = _totalVSeg + +el.vSeg;

      _totalVOutro = _totalVOutro + +el.vOutro;
      _totalVICMSOutro = _totalVICMSOutro + +el.vICMSOutro;
      _totalVICMSIsento = _totalVICMSIsento + +el.vICMSIsento;

      _totalVTotTrib = _totalVTotTrib + +el.vTotTrib;
    });

    setTotalVBC(_totalVBC);
    setTotalVICMS(_totalVICMS);

    setTotalVICMSDeson(_totalVICMSDeson);

    setTotalVBCST(_totalVBCST);
    setTotalVST(_totalVST);

    setTotalVPIS(_totalVPIS);
    setTotalVCOFINS(_totalVCOFINS);

    setTotalVFCP(_totalVFCP);

    setTotalVProd(_totalVProd);

    setTotalVFrete(_totalVFrete);
    setTotalVSeg(_totalVSeg);
    setTotalVOutro(_totalVOutro);

    setTotalVICMSOutro(_totalVICMSOutro);
    setTotalVICMSIsento(_totalVICMSIsento);

    setTotalVTotTrib(_totalVTotTrib);

    let informacaoComplementar = '';
    informacaoComplementar = 'Valor total aproximado de tributos:';
    informacaoComplementar += ' Mun: ' + formataMoeda(totalIbptMunicipal);
    informacaoComplementar += ' Est: ' + formataMoeda(totalIbptEstadual);
    informacaoComplementar += ' Fed: ' + formataMoeda(totalIbptNacionalFederal);

    const shippingData = {
      flag,
      chaveAcesso,
      protocolo,
      recibo: numeroRecibo,
      dhEmi: _dhEmi,
      dhEnvio: _dhEnvio,
      emissaoPropria,
      idUsuario,
      cnpjEmpresa: cnpjEmpresa.trim(),
      idParticipante,
      clienteNome,
      clienteCnpj,
      clienteIe,
      clienteEMail,
      cNf: Math.random().toString().slice(2, 10),
      numeroNf: nNf,
      serie: serieEmpresa,
      codSit,
      tpEmis,
      infoAdFisco,
      infCpl: informacaoComplementar ? informacaoComplementar.trim() : '',
      totalVBC: _totalVBC,
      totalVICMS: _totalVICMS,
      totalVICMSDeson: _totalVICMSDeson,
      totalVII,
      totalVIPI,
      totalVIPIDevol,
      totalVBCST: _totalVBCST,
      totalVST: _totalVST,
      totalVPIS: _totalVPIS,
      totalVCOFINS: _totalVCOFINS,
      totalVFCP: _totalVFCP,
      totalVFCPST,
      totalVFCPStRet,
      totalVProd: totalCupom,
      totalVDesc: valorDesconto,
      totalVFrete: _totalVFrete,
      totalVSeg: _totalVSeg,
      totalVOutro: _totalVOutro,
      totalVICMSIsento: _totalVICMSIsento,
      totalVICMSOutro: _totalVICMSOutro,
      totalVNF: totalCupom - valorDesconto,
      totalBruto: totalCupom - valorDesconto,
      totalVTotTrib: _totalVTotTrib, // ibpt
      vTroco: ((+pagoDinheiro + +pagoCheque + +pagoCartao + +pagoDigital) - (+totalCupom - +valorDesconto)).toFixed(2),
      // totalVICMSDeson, nao tem no item mas tem no cupom
      listaNFCeItemDTO: _listaProdutos,
      listaNFCePagDTO: listaPagamentos,
      listaNFCeAutXMLDTO: [],
    };

    localStorage.setItem('cupom', JSON.stringify(shippingData));

    // console.log('shippingdata: ', shippingData);

    return shippingData;
  };

  // eslint-disable-next-line consistent-return
  const handleAdicionarProduto = async (dadosEntrada) => {
    try {
      setIsLoading(true);
      if (dadosEntrada) {
        let produto = null;

        const dados = await tratarDadosEntrada(dadosEntrada);

        if (dados.tipo === 'CODIGO' || dados.tipo === 'CODIGOEAN' || dados.tipo === 'QTDEECODIGO') {
          produto = await getProduto(dados.codigo);
        }

        if (produto[0].precoPromocao > 0 && produto[0].precoPromocao < produto[0].precoVenda) {
          let dataInicio = '';
          let dataFim = '';
          const dataAtual = new Date();
          dataAtual.setHours(0, 0, 1, 0);

          let usarPromocao = false;

          if (produto[0].dataInicioPromocao !== null && produto[0].dataFimPromocao !== null) {
            dataInicio = new Date(produto[0].dataInicioPromocao[0], produto[0].dataInicioPromocao[1] - 1, produto[0].dataInicioPromocao[2]);
            dataInicio.setHours(0, 0, 0, 0);
            dataFim = new Date(produto[0].dataFimPromocao[0], produto[0].dataFimPromocao[1] - 1, produto[0].dataFimPromocao[2]);
            dataFim.setHours(23, 59, 59, 0);

            if (dataInicio <= dataAtual && dataAtual <= dataFim) {
              usarPromocao = true;
            }
          } else if (produto[0].dataInicioPromocao !== null && produto[0].dataFimPromocao === null) {
            dataInicio = new Date(produto[0].dataInicioPromocao[0], produto[0].dataInicioPromocao[1] - 1, produto[0].dataInicioPromocao[2]);
            dataInicio.setHours(0, 0, 0, 0);

            if (dataAtual >= dataInicio) {
              usarPromocao = true;
            }
          } else if (produto[0].dataFimPromocao !== null && produto[0].dataInicioPromocao === null) {
            dataFim = new Date(produto[0].dataFimPromocao[0], produto[0].dataFimPromocao[1] - 1, produto[0].dataFimPromocao[2]);
            dataFim.setHours(23, 59, 59, 0);

            if (dataAtual <= dataFim) {
              usarPromocao = true;
            }
          } else if (produto[0].dataInicioPromocao === null && produto[0].dataFimPromocao === null) {
            usarPromocao = true;
          }

          if (usarPromocao) {
            produto[0].precoVenda = produto[0].precoPromocao;
          }
        }

        if (dados.tipo === 'CODIGOBALANCA') {
          produto = await getProduto(dados.codigo);
          // calcular a quantidade baseado no
          if (empresaBalancaPorValor) {
            dados.qtde = (+dados.valor / +produto[0].precoVenda).toFixed(4).toString();
            dados.valor = produto[0].precoVenda;
          }
        }

        if (produto && produto.length > 0) {
          if ((produto[0].unidadeMedida.sigla === 'UN' || produto[0].unidadeMedida.sigla === 'PC') && !Number.isInteger(Number(dados.qtde))) {
            setShowProdutoNaoPodeSerFracionado(true);
            setDadosEntrada('');
            playSound();
            return false;
          }

          await preencherDadosProduto(produto, dados);

          await atualizarListaProdutos(produto, dados);

          setDadosEntrada('');
        }

        calcularTotalCupom(listaProdutos);
        calcularTotalVolumes(listaProdutos);

        const _listaProdutos = orderBy(listaProdutos, 'item', 'asc');

        montaShippingData(_listaProdutos);
      }
      setDadosEntrada('');
      setSelectedProdutos(null);
    } catch (err) {
      toast.info('Ocorreu um erro ao adicionar produto');
    } finally {
      setIsLoading(false);
    }

    // declarando localstorage como 5 megas
    // const localStorageTamanhofixo = 1024 * 1024 * 5 - unescape(encodeURIComponent(JSON.stringify(localStorage))).length;

    // if (localStorageTamanhofixo > 5000) {
    //  localStorage.setItem
    // }

    // console.log(1024 * 1024 * 5 - unescape(encodeURIComponent(JSON.stringify(localStorage))).length);
  };

  const handleSair = () => {
    if (listaProdutos.length > 0) {
      toast.info('Cupom com produtos, saída não permitida.');
      return false;
    }
    navigate('/home');
  };

  useEffect(() => {
    if (totalCupom > 0) {
      const dinheiro = arredondar(pagoDinheiro, -2);
      const cheque = arredondar(pagoCheque, -2);
      const cartao = arredondar(pagoCartao, -2);
      const digital = arredondar(pagoDigital, -2);
      const desconto = arredondar(valorDesconto, -2);

      if (desconto < 0) {
        setValorDesconto(0);
        toast.info('Valor desconto não pode ser negativo');
        return false;
      }

      const pago = dinheiro + cheque + cartao + digital;

      const troco = (pago - (totalCupom - desconto));

      if (pago >= (totalCupom - desconto)) {
        if (desconto < totalCupom) {
          setLiberarFechamentoCupom(true);
        } else {
          setLiberarFechamentoCupom(false);
          toast.info('Desconto deve ser menor que o valor do cupom');
          return false;
        }
      } else {
        setLiberarFechamentoCupom(false);
      }

      setValorPago(arredondar(pago, -2));
      setValorTroco(arredondar(troco, -2));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagoDinheiro, pagoCheque, pagoCartao, pagoDigital, valorDesconto]);

  const inicializarFechamentoCupom = () => {
    setPagoDinheiro(0);
    setPagoCheque(0);
    setPagoCartao(0);
    setPagoDigital(0);
    setIdParticipante(null);
    setClienteNome('');
    setClienteCnpj('');
    setClienteIe('');
    setClienteEMail('');
    setValorDesconto(0);
    setValorTroco(0);
    setListaPagamentos([]);
    setListaPagamentosCartoes([]);
    setNumeroParcelasCartao(1);
    setValorParcelamentoCartao(0);
    setValorRestanteCartao(0);
    setListaPagamentosPix([]);
    setValorPagamentoPix(0);
    setValorRestantePix(0);
  };

  const handlePagamento = async () => {
    if (totalCupom > 0) {
      inicializarFechamentoCupom();
      setShowPagamentoDialog(true);
    }
  };

  const handleF9 = async () => {
    if (listaProdutos.length > 0) {
      await handlePagamento();
      const input = document.getElementById('pagoDinheiro');
      input.focus();
    } else {
      toast.info('Nenhum produto informado para venda');
    }
  };

  const childToParent = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (cnpjEmpresa === e.cnpjEmpresa.trim()) {
        setIdParticipante(idElement);

        if (e.razaoSocial) {
          setClienteNome(e.razaoSocial);
          setClienteCnpj(e.cnpj);
          setClienteIe(e.inscricaoEstadual);
          setClienteEMail(e.email);
        }

        setShowConsultaParticipante(false);
      } else {
        toast.warning('Participante selecionado não pertence a empresa emitente da NFC-e');
      }
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentConsultaProduto = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (cnpjEmpresa === e.cnpjEmpresa.trim()) {
        if (e.id) {
          if (e.codigoEan) {
            setDadosEntrada(dadosEntrada + e.codigoEan);
          } else {
            setDadosEntrada(dadosEntrada + e.registro);
          }
        }
        setShowConsultaProduto(false);
      } else {
        toast.warn('Produto selecionado não pertence a empresa emitente da NFC-e');
      }
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentConsultaNFCe = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (cnpjEmpresa === e.cnpjEmpresa.trim()) {
        setShowConsultaNFCe(false);
      } else {
        toast.warn('NFCe selecionado não pertence a empresa emitente da NFC-e');
      }
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const coletarCupomPendente = async () => {
    const cupomPendente = JSON.parse(localStorage.getItem('cupom'));
    if (cupomPendente !== null && cupomPendente.cnpjEmpresa === cnpjEmpresa) {
      setListaProdutos(cupomPendente.listaNFCeItemDTO);
      cupomPendente.listaNFCeItemDTO.forEach(el => {
        handleAdicionarProduto(el.qCom + '*' + el.codigo);
      });
    }
  };

  async function licencaValida(empresaId) {
    // eslint-disable-next-line consistent-return
    const retorno = await axiosAdmin.get(`/empresa/verificardatalicenca/${empresaId}`);
    return retorno.data;
  }

  const handleConfirmEscolhaEmpresa = async () => {
    let empresaId = 0;

    listaEmpresas.forEach(element => {
      if (element.value === cnpjEmpresa) {
        empresaId = element.id;
      }
    });

    const isLicencaValida = await licencaValida(empresaId);
    if (!isLicencaValida) {
      toast.info('Cliente com licença expirada. Efetuar pagamento mensalidade.');
      navigate('/pagamento');
      return false;
    }

    setEmpresaAliquotaCofins(empresaAliquotaCofins);
    setEmpresaAliquotaPis(empresaAliquotaPis);
    setDadosEntrada('');
    setTotalCupom(formataMoeda(0));
    setListaProdutos([]);
    setShowEscolhaEmpresaDialog(false);
    setInputDadosEntradaFocus();
    setUltimoNumero(serieEmpresa.toString());
    coletarCupomPendente();
  };

  const calcularProdutoDesconto = async () => {
    if (valorDesconto > 0) {
      const percDesc = valorDesconto / totalCupom;
      listaProdutos.forEach(el => {
        el.vDesc = (+el.precoBruto * +percDesc) * el.qCom;
        el.precoVenda = +el.precoBruto - (+el.precoBruto * +percDesc);
      });
    }
  };

  const montaPagamento = async () => {
    let pagamento = {};
    /*
      dVenc: formataDataMoment(dhEmi, '-', true),
      indPag: 0, //  0 = a vista, 1 = prazo
      tPag: '01',

      01=Dinheiro
      02=Cheque
      03=Cartão de Crédito
      04=Cartão de Débito
      05=Crédito Loja
      10=Vale Alimentação
      11=Vale Refeição
      12=Vale Presente
      13=Vale Combustível
      15=Boleto Bancário
      16=Depósito Bancário
      17=Pagamento Instantâneo (PIX)
      18=Transferência bancária, Carteira Digital
      19=Programa de fidelidade, Cashback, Crédito Virtual
      90=Sem Pagamento;
      99=Outros.

      xPag: '', // descricao opcional
      vPag: 0, // valor pagamento
      cardTpIntegra: 2, // nao obrigatorio 1 = TEF, 2 = POS
      cardCnpj: '', // nao obrigatorio cnpj do adquirente
      cardTBand: '', // nao obrigatorio cnpj a instituicao de pagamento
      cardCAut: '', // nao obrigatorio numero da autorizacao da autorizacao do cartao
      */

    setListaPagamentos([]);

    if (pagoDinheiro > 0) {
      pagamento.dVenc = formataDataMoment(dhEmi, '-', false);
      pagamento.indPag = 'AVISTA';
      pagamento.tPag = 'DINHEIRO';
      pagamento.vPag = pagoDinheiro;
      pagamento.xPag = 'DINHEIRO';
      listaPagamentos.push(pagamento);
    }

    if (listaPagamentosCartoes.length > 0) {
      listaPagamentosCartoes.forEach(el => {
        el.vencimento = replaceAll(el.vencimento, '/', '-');
      });

      listaPagamentosCartoes.forEach(el => {
        pagamento = {};
        pagamento.indPag = 'APRAZO';
        pagamento.dVenc = el.vencimento;
        pagamento.tPag = el.tipo === 'CREDITO' ? 'CARTAOCREDITO' : 'CARTAODEBITO';
        pagamento.vPag = el.valor;
        pagamento.xPag = el.tipo;
        listaPagamentos.push(pagamento);
      });
    }

    if (listaPagamentosPix.length > 0) {
      listaPagamentosPix.forEach(el => {
        el.vencimento = replaceAll(el.vencimento, '/', '-');
      });

      listaPagamentosPix.forEach(el => {
        pagamento = {};
        pagamento.indPag = 'AVISTA';
        pagamento.dVenc = formataDataMoment(dhEmi, '-', false);
        pagamento.tPag = 'PIX';
        pagamento.xPag = el.tipo;
        pagamento.vPag = el.valor;
        listaPagamentos.push(pagamento);
      });
    }
  };

  const visualizarCupom = (response) => {
    try {
      const byteCharacters = Base64.atob(response.data);

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blobFile = new Blob([byteArray], { type: 'application/pdf' });

      const pdf = URL.createObjectURL(blobFile);
      window.open(pdf);
    } catch (e) {
      toast.warn('Erro ao adicionar o pdf ao arquivo .zip');
    }
  };

  const handleVisualizarCupom = async (e) => {
    try {
      const id = get(e, 'id', 0);
      const chave = get(e, 'chaveAcesso', 0);

      if (id > 0) {
        await axiosAdmin.get(`/nfceutil/downloaddanfce?chaveacesso=${chave}`).then(res => {
          visualizarCupom(res, chave);
        });
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível baixar os arquivos');
    }
  };

  const consultarStatusServico = async () => {
    try {
      setIsLoading(true);

      const { data: statusRetorno } = await axiosAdmin.get(`/nfeutil/statusservico/${empresaId}`);

      if (statusRetorno.substring(0, 4) === 'Erro') {
        toast.error(statusRetorno);
        return true;
      } else {
        toast.success(statusRetorno);
        return false;
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível consultar o status do serviço');
    } finally {
      setIsLoading(false);
    }
  };

  const salvarRegistro = async () => {
    setIsLoading(true);

    if (isNaN(valorDesconto) || valorDesconto === '' || valorDesconto === undefined || valorDesconto === null || valorDesconto < 0) {
      toast.info('valor desconto inválido');
      return false;
    }

    if (+pagoDinheiro + +pagoCheque + +pagoCartao + +pagoDigital <= 0) {
      toast.info('Confira as formas de pagamento antes do envio.');
      return false;
    }

    if (pagoCartao > 0 && listaPagamentosCartoes.length <= 0) {
      toast.info('Confira as formas de pagamento de cartão.');
      return false;
    }

    if (pagoDigital > 0 && listaPagamentosPix.length <= 0) {
      toast.info('Confira as formas de pagamento de pix.');
      return false;
    }

    if (pagoCartao > 0 || pagoDigital > 0) {
      if (valorTroco > 0) {
        toast.info('Troco não permitido para pagamento multiplo contendo cartão ou digital.');
        return false;
      }
    }

    if ((totalCupom - valorDesconto) > 3000 && clienteNome === '' && clienteCnpj === '') {
      toast.info('NFC-e com valor maior que R$ 3.000,00 deve ter indentificação do cliente.');
      return false;
    }

    if (clienteNome !== '' && clienteCnpj === '') {
      toast.info('Nome e CNPJ/CPF precisam devem ser preenchidos.');
      setLiberarFechamentoCupom(true);
      return false;
    }

    if (clienteNome === '' && clienteCnpj !== '') {
      toast.info('Nome e CNPJ/CPF precisam devem ser preenchidos.');
      setLiberarFechamentoCupom(true);
      return false;
    }

    if (clienteCnpj !== '' && (clienteCnpj.length !== 11 && clienteCnpj.length !== 14)) {
      toast.info('CNPJ/CPF devem ter 14 ou 11 caracteres respectivamente.');
      setLiberarFechamentoCupom(true);
      return false;
    }

    if (clienteCnpj !== '') {
      if (!cpfValidator.isValid(soNumeros(clienteCnpj)) && !cnpjValidator.isValid(soNumeros(clienteCnpj))) {
        toast.info('CNPJ/CPF inválido.');
        setLiberarFechamentoCupom(true);
        return false;
      }
    }

    listaProdutos.forEach(el => {
      if (el.ncm === null || isEmpty(el.ncm)) {
        el.ncm = '99999999';
      }
    });

    const idUsuario = get(store.getState().auth.user, 'id', 0);

    try {
      setLiberarFechamentoCupom(false);
      await calcularProdutoDesconto().then(async () => {
        await calcularProdutoImpostos().then(async () => {
          await montaPagamento().then(async () => {
            const _listaProdutos = orderBy(listaProdutos, 'item', 'asc');

            const shippingData = await montaShippingData(_listaProdutos);

            // console.log('shippingData', shippingData);

            const cupomPendente = JSON.parse(localStorage.getItem('cupom'));
            if (cupomPendente !== null && cupomPendente.cnpjEmpresa === cnpjEmpresa) {
              shippingData.id = cupomPendente.id;
            }

            if (shippingData.id && shippingData.id > 0) {
              await axiosAdmin.delete(`/nfce/${shippingData.id}`);
            } else {
              shippingData.id = '';
              await axiosAdmin.post('/nfce', shippingData).then(async (res) => {
                shippingData.id = res.data.id;
                shippingData.chaveAcesso = res.data.chaveAcesso;

                localStorage.setItem('cupom', JSON.stringify(shippingData));

                await axiosAdmin.get(`/nfceutil/emitirnfce?id=${res.data.id}&contingencia=false`).then(async (resposta) => {
                  // console.log('resposta da emissao: ', resposta);
                  handleVisualizarCupom(resposta.data);

                  toast.success('NFCe enviada com sucesso.');

                  localStorage.removeItem('cupom');

                  inicializarCupom();

                  setUltimoNumero(serieEmpresa);
                }).catch(async (error) => {
                  // console.log('erro :', error.response);
                  // falha de internet http
                  // 100 - 199 - avisos diversos
                  // 200 - 299 - sucesso
                  // 300 - 299 - redirecionamento
                  // 400 - erro de dados enviados pelo cliente
                  // 500 - falha servidor
                  if (error.response.status >= 500) {
                    // console.log('error: ', error);
                    // nao fazer nada
                    toast.warn('Verifique sua internet, faça o login novamente e tente reenviar');
                    // se erro de internet nao vai deletar,
                    // se erro na montagem da nfce vai deletar a nfce salva nao deixando pular numeracao
                    await axiosAdmin.delete(`/nfce/${shippingData.id}`);
                    return false;
                  } else if (error.response.status >= 400 && error.response.status <= 499) {
                    shippingData.flag = 'D';
                    shippingData.tpEmis = 'NFCEOFFLINE';

                    await axiosAdmin.get(`/nfceutil/emitirnfce?id=${res.data.id}&contingencia=true`).then(async (resposta) => {
                      handleVisualizarCupom(resposta.data);

                      toast.success('NFCe enviada em contigência.');

                      localStorage.removeItem('cupom');

                      inicializarCupom();

                      setUltimoNumero(serieEmpresa);
                    });
                  } else {
                    shippingData.id = null;
                    shippingData.flag = 'D';
                    await axiosAdmin.post('/nfce', shippingData).then(async (res) => {
                      await axiosAdmin.get(`/nfceutil/emitirnfce?id=${res.data.id}&contingencia=true`).then(async (resposta) => {
                        handleVisualizarCupom(resposta.data);

                        toast.success('NFCe enviada em contingência.');

                        localStorage.removeItem('cupom');

                        inicializarCupom();

                        setUltimoNumero(serieEmpresa);
                      });
                    });
                  }
                });
              });
            }
          });
        });
      });
    } catch (err) {
      // console.log('err: ', err);
      displayErrors(err, dispatch, '/', '');
    } finally {
      setIsLoading(false);
      setLiberarFechamentoCupom(true);
    }
  };

  const handleConfirmCancelarCupom = () => {
    setListaProdutos([]);
    setTotalCupom(formataMoeda(0));
    setTotalVolumes(0);
    setDadosEntrada('');
    setSelectedProdutos(null);
    setShowConfirmCancelarCupomDialog(false);
    localStorage.removeItem('cupom');
  };

  const handleCancelDeleteProduto = () => {
    setShowConfirmDeleteProdutoDialog(false);
    setSelectedProdutos(null);
  };

  const handleCancelAlterarNumeroCupom = () => {
    setShowAlterarNumeroCupom(false);
  };

  const handleCancelEscolhaEmpresa = () => {
    handleSair();
  };

  const dialogEscolhaEmpresaFooter = (
    <div>
      <Button
        type="button"
        className="btn btn-success"
        onClick={handleConfirmEscolhaEmpresa}
      >
        <i className="pi pi-check"></i>
        Confirmar
      </Button>
      <Button
        type="button"
        className="btn btn-danger"
        onClick={handleCancelEscolhaEmpresa}
      >
        <i className="pi pi-times"></i>
        Cancelar
      </Button>
    </div>
  );

  const handleConfirmPagamentosCartao = () => {
    toast.info('confirmação de cartões');
    if (valorRestanteCartao !== 0 && listaPagamentosCartoes.length > 0) {
      toast.info('Total informado cartão não confere com parcela(s) cadastrada(s)');
      return false;
    }
    setShowPagamentoCartaoDialog(false);
  };

  const handleConfirmaPagamentosPix = () => {
    toast.info('confirmção de pix');
    if (valorRestantePix !== 0 && listaPagamentosPix.length > 0) {
      toast.info('Total informado pix não confere com pix cadastrado(s)');
      return false;
    }
    setShowPagamentoPixDialog(false);
  };

  const dialogPagamentoCartaoFooter = (
    <div>
      <Button
        type="button"
        className="btn btn-success"
        onClick={() => handleConfirmPagamentosCartao()}
      >
        <i className="pi pi-check"></i>
        Continuar
      </Button>
    </div>
  );

  const dialogPagamentoPixFooter = (
    <div>
      <Button
        type="button"
        className="btn btn-success"
        onClick={() => handleConfirmaPagamentosPix()}
      >
        <i className="pi pi-check"></i>
        Continuar
      </Button>
    </div>
  );

  const dialogCancelarCupomFooter = (
    <div>
      <Button
        type="button"
        className="btn btn-success"
        onClick={handleConfirmCancelarCupom}
      >
        <i className="pi pi-check"></i>
        Sim
      </Button>
      <Button
        type="button"
        className="btn btn-danger"
        onClick={() => setShowConfirmCancelarCupomDialog(false)}
      >
        <i className="pi pi-times"></i>
        Não
      </Button>
    </div>
  );

  const dialogDeleteProdutoFooter = (
    <div>
      <Button
        type="button"
        className="btn btn-success"
        onClick={handleConfirmDeleteProduto}
      >
        <i className="pi pi-check"></i>
        Continuar
      </Button>
      <Button
        type="button"
        className="btn btn-danger"
        onClick={handleCancelDeleteProduto}
      >
        <i className="pi pi-times"></i>
        Cancelar
      </Button>
    </div>
  );

  const handleAlterarNumeroCupom = () => {
    if (nNf === '1') {
      setShowAlterarNumeroCupom(true);
    }
  };

  const handleConfimrAlterarNumeroCupom = () => {
    setShowAlterarNumeroCupom(false);
  };

  const dialogAlterarNumeroCupomFooter = (
    <div>
      <Button
        type="button"
        className="btn btn-success"
        onClick={handleConfimrAlterarNumeroCupom}
      >
        <i className="pi pi-check"></i>
        Continuar
      </Button>
      <Button
        type="button"
        className="btn btn-danger"
        onClick={handleCancelAlterarNumeroCupom}
      >
        <i className="pi pi-times"></i>
        Cancelar
      </Button>
    </div>
  );

  const dialogEmissaoNFCeFooter = (
    <div className="row form-elo">
      <div className="col-4" style={{ fontSize: '32px', textAlign: 'left' }}>
        <Button>Série: {serieEmpresa} </Button>
        <Button onClick={handleAlterarNumeroCupom}>Número: {nNf} </Button>
      </div>
      <div className="col-8" style={{ fontSize: '32px', textAlign: 'right' }}>
        <Button label="(Ctrl + C) Comandos" icon="" onClick={handleComandos} />
        <Button label="(Ctrl + A) Administrativo" icon="" onClick={handleAdministrativo} />
        <Button label="(Ctrl + S) Sair" icon="pi pi-times" onClick={handleSair} />
      </div>
    </div>
  );

  const onHideProdutoNaoEncontrado = () => {
    setDadosEntrada('');
    setShowProdutoNaoEncontrado(false);
    setInputDadosEntradaFocus();
  };

  const onHideCodigoProdutoInvalido = () => {
    setDadosEntrada('');
    setShowCodigoProdutoInvalido(false);
    setInputDadosEntradaFocus();
  };

  const onHideAlterarNumeroCupom = () => {
    setDadosEntrada('');
    setShowAlterarNumeroCupom(false);
    setInputDadosEntradaFocus();
  };

  const onHideQuantidadeProdutoInvalida = () => {
    setDadosEntrada('');
    setShowQuantidadeProdutoInvalida(false);
    setInputDadosEntradaFocus();
  };

  const onHidePagamentoCartaoDialog = () => {
    setDadosEntrada('');
    setShowPagamentoCartaoDialog(false);
    setInputDadosEntradaFocus();
  };

  const onHidePagamentoPixDialog = () => {
    setDadosEntrada('');
    setShowPagamentoPixDialog(false);
    setInputDadosEntradaFocus();
  };

  const onHideComandosDialog = () => {
    setDadosEntrada('');
    setShowComandosDialog(false);
    setInputDadosEntradaFocus();
  };

  const onHideAdministrativoDialog = () => {
    setDadosEntrada('');
    setShowAdministrativoDialog(false);
    setInputDadosEntradaFocus();
  };

  const onHideProdutoNaoPodeSerFracionado = () => {
    setDadosEntrada('');
    setShowProdutoNaoPodeSerFracionado(false);
    setInputDadosEntradaFocus();
  };

  const pegarSomaParcelasCartao = (lista) => {
    let valor = 0;
    // eslint-disable-next-line arrow-body-style
    valor = lista.reduce((valor, item) => {
      return valor + +item.valor;
    }, 0);

    return valor;
  };

  const pegarSomaParcelasPix = (lista) => {
    let valor = 0;
    // eslint-disable-next-line arrow-body-style
    valor = lista.reduce((valor, item) => {
      return valor + +item.valor;
    }, 0);

    return valor;
  };

  const handlePagamentoCartao = (valorCartao) => {
    const valor = valorCartao.replace('.', '').replace(',', '.');

    const somaParcelasCartao = pegarSomaParcelasCartao(listaPagamentosCartoes);

    if (Number(valor) > 0 && (Number(valor).toFixed(2) !== Number(somaParcelasCartao).toFixed(2))) {
      setShowPagamentoCartaoDialog(true);
    }
  };

  const handlePagamentoPix = (valorPix) => {
    const valor = valorPix.replace('.', '').replace(',', '.');

    const somaParcelasPix = pegarSomaParcelasPix(listaPagamentosPix);

    if (Number(valor) > 0 && (Number(valor).toFixed(2) !== Number(somaParcelasPix).toFixed(2))) {
      setShowPagamentoPixDialog(true);
    }
  };

  const handleEditPagamentoCartao = () => {
    setShowPagamentoCartaoDialog(true);
    setValorParcelamentoCartao();
  };

  const handleEditPagamentoPix = () => {
    setShowPagamentoPixDialog(true);
  };

  const calcularValorRestanteCartao = (lista) => {
    let valor = 0;
    // eslint-disable-next-line arrow-body-style
    valor = pegarSomaParcelasCartao(lista);
    setValorRestanteCartao(pagoCartao - valor);
  };

  const calcularValorRestantePix = (lista) => {
    let valor = 0;
    // eslint-disable-next-line arrow-body-style
    valor = pegarSomaParcelasPix(lista);
    setValorRestantePix(pagoDigital - valor);
  };

  const handleAdicionarPagamentoCartao = () => {
    if (valorRestanteCartao < 0) {
      toast.info('A soma dos cartões não podem ser maior que o total do cartão.');
      return false;
    }

    if (tipoCartao === 'DEBITO' && numeroParcelasCartao > 1) {
      toast.info('Pagamento com cartão débito não pode ser parcelado');
      return false;
    }

    const _listaPagamentosCartoes = [...listaPagamentosCartoes];
    if (numeroParcelasCartao > 0) {
      if (valorParcelamentoCartao > 0) {
        for (let x = 0; x < numeroParcelasCartao; x++) {
          const vencimento = moment(new Date().toLocaleDateString(), 'DD-MM-YYYY').add(30 * (x + 1), 'days').toDate().toString();
          const pagamento = {
            item: _listaPagamentosCartoes.length + 1,
            parcela: `${(x + 1) + '/' + numeroParcelasCartao}`,
            tipo: tipoCartao,
            vencimento: formataDataMoment(vencimento, '/'),
            valor: (+valorParcelamentoCartao / numeroParcelasCartao).toFixed(4).toString(),
          };
          _listaPagamentosCartoes.push(pagamento);
        }
        setListaPagamentosCartoes(_listaPagamentosCartoes);
        calcularValorRestanteCartao(_listaPagamentosCartoes);
      } else {
        toast.info('Valor do cartão não pode ser zero.');
      }
    } else {
      toast.info('Número de parcelas não pode ser zero.');
    }
  };

  const handleAdicionarPagamentoPix = () => {
    if (valorRestantePix < 0) {
      toast.info('A soma dos Pagamentos Digitais não podem ser maior que o total do pagamento digital.');
      return false;
    }

    const _listaPagamentosPix = [...listaPagamentosPix];
    if (valorPagamentoPix > 0) {
      const vencimento = moment(new Date().toLocaleDateString(), 'DD-MM-YYYY');
      const pagamento = {
        item: _listaPagamentosPix.length + 1,
        tipo: tipoPix,
        vencimento: formataDataMoment(vencimento, '/'),
        valor: (+valorPagamentoPix).toFixed(2).toString(),
      };
      _listaPagamentosPix.push(pagamento);
      setListaPagamentosPix(_listaPagamentosPix);
      calcularValorRestantePix(_listaPagamentosPix);
    } else {
      toast.info('Valor do Pag. Digital não pode ser zero.');
    }
  };

  const handleExcluirPagamentoCartao = async (e) => {
    try {
      setIsLoading(true);
      const item = get(e, 'item', 0);

      const _listaPagamentosCartoes = [...listaPagamentosCartoes];

      const index = _listaPagamentosCartoes.indexOf(_listaPagamentosCartoes.find(element => element.item === item));

      if (index >= 0) {
        _listaPagamentosCartoes.splice(index, 1);

        _listaPagamentosCartoes.forEach(element => {
          if (element.item > item) {
            const _index = _listaPagamentosCartoes.indexOf(element);

            _listaPagamentosCartoes[_index].item = _listaPagamentosCartoes[_index].item - 1;
          }
        });
      }

      setListaPagamentosCartoes(_listaPagamentosCartoes);
      calcularValorRestanteCartao(_listaPagamentosCartoes);
    } catch (err) {
      toast.info('Selecione um registro para exclusão');
    } finally {
      setIsLoading(false);
    }
  };

  const handleExcluirPagamentoPix = async (e) => {
    try {
      setIsLoading(true);
      const item = get(e, 'item', 0);

      const _listaPagamentosPix = [...listaPagamentosPix];

      const index = _listaPagamentosPix.indexOf(_listaPagamentosPix.find(element => element.item === item));

      if (index >= 0) {
        _listaPagamentosPix.splice(index, 1);

        _listaPagamentosPix.forEach(element => {
          if (element.item > item) {
            const _index = _listaPagamentosPix.indexOf(element);

            _listaPagamentosPix[_index].item = _listaPagamentosPix[_index].item - 1;
          }
        });
      }

      setListaPagamentosPix(_listaPagamentosPix);
      calcularValorRestantePix(_listaPagamentosPix);
    } catch (err) {
      toast.info('Selecione um registro para exclusão');
    } finally {
      setIsLoading(false);
    }
  };

  const actionBodyTemplatePagamentoCartao = (rowData) => (
    <div className="row col-12">
      <div className="col-lg-4">
        <button
          type="button"
          title="Excluir parcela cartão"
          className="btn-success rounded outlined"
          style={{ borderRadius: '15px' }}
          onClick={() => handleExcluirPagamentoCartao(rowData)}
        >
          <i className="pi pi-trash"></i>
        </button>
      </div>
    </div>
  );

  const actionBodyTemplatePagamentoPix = (rowData) => (
    <div className="row col-12">
      <div className="col-lg-4">
        <button
          type="button"
          title="Excluir Pix"
          className="btn-success rounded outlined"
          style={{ borderRadius: '15px' }}
          onClick={() => handleExcluirPagamentoPix(rowData)}
        >
          <i className="pi pi-trash"></i>
        </button>
      </div>
    </div>
  );

  window.addEventListener('keydown', (e) => {
    if (e.code === 'F1' || e.code === 'F5'
      || e.code === 'F3' || e.code === 'F10') {
      e.preventDefault();
    } else return true;
  });

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Selecionar Empresa</h6>}
        visible={showEscolhaEmpresaDialog}
        style={{ width: '58vw' }}
        onHide={handleCancelEscolhaEmpresa}
        footer={dialogEscolhaEmpresaFooter}
      >
        <div className="row form-elo">
          <div className="col-1">
          </div>
          <div className="col-10">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Empresa
              </Input155px>
              <Dropdown
                options={listaEmpresas}
                value={cnpjEmpresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setCnpjEmpresa(e.target.value)}
              />
            </div>
          </div>
          <div className="col-1">
          </div>
        </div>
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Pagamento Cartões</h6>}
        visible={showPagamentoCartaoDialog}
        style={{ width: '68vw' }}
        onHide={() => onHidePagamentoCartaoDialog()}
        footer={dialogPagamentoCartaoFooter}
      >
        <div className="row form-elo">
          <div className="col-12">
            <DataTable
              className="datatable-nfcepagamentoCartoes"
              value={listaPagamentosCartoes}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              sortField="item"
              selectionMode="single"
              selection={selectedPagamentoCartao}
              onSelectionChange={e => setSelectedPagamentoCartao(e.value)}
              dataKey="item"
              emptyMessage=" "
              scrollable
              scrollHeight="350px"
            >
              <Column field="item" header="item" sort="false"></Column>
              <Column field="tipo" header="Tipo" sort="false"></Column>
              <Column field="parcela" header="parcela" sort="false"></Column>
              <Column field="vencimento" header="Vencimento" sort="false"></Column>
              <Column field="valor" header="Valor" sort="false"></Column>
              <Column body={actionBodyTemplatePagamentoCartao} exportable={false} style={{ width: '50px' }}></Column>
            </DataTable>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo
              </Input155px>
              <Dropdown
                options={listaTiposCartoes}
                value={tipoCartao}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipoCartao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Parcelas
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                inputId="minmax-buttons"
                value={numeroParcelasCartao}
                onValueChange={(e) => setNumeroParcelasCartao(e.value)}
                mode="decimal"
                showButtons
                min={0}
                max={100}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Valor
              </Input100px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorParcelamentoCartao}
                onValueChange={e => setValorParcelamentoCartao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-1">
            <div className="p-inputgroup">
              <Button label="" title="Adicionar" icon="pi pi-plus" onClick={() => handleAdicionarPagamentoCartao()} />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Restando
              </Input100px>
              <InputNumber
                disabled
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorRestanteCartao}
                onValueChange={e => setValorRestanteCartao(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Pagamento Digital</h6>}
        visible={showPagamentoPixDialog}
        style={{ width: '68vw' }}
        onHide={() => onHidePagamentoPixDialog()}
        footer={dialogPagamentoPixFooter}
      >
        <div className="row form-elo">
          <div className="col-12">
            <DataTable
              className="datatable-nfcepagamentoCartoes"
              value={listaPagamentosPix}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              sortField="item"
              selectionMode="single"
              selection={selectedPagamentoPix}
              onSelectionChange={e => setSelectedPagamentoPix(e.value)}
              dataKey="item"
              emptyMessage=" "
              scrollable
              scrollHeight="350px"
            >
              <Column field="item" header="item" sort="false"></Column>
              <Column field="tipo" header="Tipo" sort="false"></Column>
              <Column field="valor" header="Valor" sort="false"></Column>
              <Column body={actionBodyTemplatePagamentoPix} exportable={false} style={{ width: '50px' }}></Column>
            </DataTable>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo
              </Input155px>
              <Dropdown
                options={listaTiposPix}
                value={tipoPix}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipoPix(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Valor
              </Input100px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorPagamentoPix}
                onValueChange={e => setValorPagamentoPix(e.target.value)}
              />
            </div>
          </div>
          <div className="col-1">
            <div className="p-inputgroup">
              <Button label="" title="Adicionar" icon="pi pi-plus" onClick={() => handleAdicionarPagamentoPix()} />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Restando
              </Input100px>
              <InputNumber
                disabled
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorRestantePix}
                onValueChange={e => setValorRestantePix(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Deletar Produto</h6>}
        visible={showConfirmDeleteProdutoDialog}
        style={{ width: '58vw' }}
        onHide={() => onHideConfirmDeleteProduto()}
        footer={dialogDeleteProdutoFooter}
      >
        <div>
          <div className="row form-elo">
            <div className="col-12">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Produto
                </Input155px>
                <InputText
                  disabled
                  maxLength={100}
                  value={selectedProdutos !== null ? selectedProdutos.descricao.toUpperCase() : ''}
                />
              </div>
            </div>
          </div>
          <div className="row form-elo">
            <div className="col-6">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Qtde
                </Input155px>
                <InputText
                  disabled
                  maxLength={15}
                  value={selectedProdutos !== null ? selectedProdutos.qCom.toString() : 0}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Valor
                </Input155px>
                <InputText
                  disabled
                  maxLength={15}
                  value={selectedProdutos !== null ? selectedProdutos.totalItem : 0}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={showProdutoNaoPodeSerFracionado}
        style={{ width: '58vw' }}
        onHide={() => onHideProdutoNaoPodeSerFracionado()}
      >
        <div className="row form-elo">
          <center>
            <h6 className="card-header" style={{ textAlign: 'center', fontSize: `${fontSizeTitulo - 20}px` }}>Produto não pode ser fracionado</h6>
          </center>
        </div>
        <div className="row form-elo">
          <center>
            <Button label="(ESC) para continuar" icon="pi pi-times" onClick={() => onHideProdutoNaoPodeSerFracionado()} />
          </center>
        </div>
      </Dialog>

      <Dialog
        visible={showCodigoProdutoInvalido}
        style={{ width: '58vw' }}
        onHide={() => onHideCodigoProdutoInvalido()}
      >
        <div className="row form-elo">
          <center>
            <h6 className="card-header" style={{ textAlign: 'center', fontSize: `${fontSizeTitulo - 20}px` }}>Código do Produto Inválido</h6>
          </center>
        </div>
        <div className="row form-elo">
          <center>
            <Button label="(ESC) para continuar" icon="pi pi-times" onClick={() => onHideCodigoProdutoInvalido()} />
          </center>
        </div>
      </Dialog>

      <Dialog
        visible={showQuantidadeProdutoInvalida}
        style={{ width: '58vw' }}
        onHide={() => onHideQuantidadeProdutoInvalida()}
      >
        <div className="row form-elo">
          <center>
            <h6 className="card-header" style={{ textAlign: 'center', fontSize: `${fontSizeTitulo - 20}px` }}>Quantidade do Produto Inválida</h6>
          </center>
        </div>
        <div className="row form-elo">
          <center>
            <Button label="(ESC) para continuar" icon="pi pi-times" onClick={() => onHideQuantidadeProdutoInvalida()} />
          </center>
        </div>
      </Dialog>

      <Dialog
        visible={showProdutoNaoEncontrado}
        style={{ width: '58vw' }}
        onHide={() => onHideProdutoNaoEncontrado()}
      >
        <div className="row form-elo">
          <center>
            <h6 className="card-header" style={{ textAlign: 'center', fontSize: `${fontSizeTitulo - 20}px` }}>Produto não encontrado na base de dados</h6>
          </center>
        </div>
        <div className="row form-elo">
          <center>
            <Button label="(ESC) para continuar" icon="pi pi-times" onClick={() => onHideProdutoNaoEncontrado()} />
          </center>
        </div>
      </Dialog>

      <Dialog
        header="Consulta de Participantes"
        visible={showConsultaParticipante}
        style={{ width: '98vw' }}
        onHide={() => setShowConsultaParticipante(false)}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParent} empresaCnpj={cnpjEmpresa} />
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Consulta Produtos</h6>}
        visible={showConsultaProduto}
        style={{ width: '98vw' }}
        onHide={() => setShowConsultaProduto(false)}
      >
        <ConsultaProduto menuMode={false} childToParent={childToParentConsultaProduto} empresaCnpj={cnpjEmpresa} somenteLeitura />
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Consulta NFCe</h6>}
        visible={showConsultaNFCe}
        style={{ width: '98vw' }}
        onHide={() => setShowConsultaNFCe(false)}
      >
        <ConsultaNFCe menuMode={false} childToParent={childToParentConsultaNFCe} empresaCnpj={cnpjEmpresa} somenteLeitura />
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Qual o número do cupom fiscal a emitir</h6>}
        visible={showAlterarNumeroCupom}
        style={{ width: '58vw' }}
        onHide={() => onHideAlterarNumeroCupom()}
        footer={dialogAlterarNumeroCupomFooter}
      >
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input400px className="p-inputgroup-addon">
                Qual o próximo número da sequência de cupom
              </Input400px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                value={alterarNumeroCupom}
                onValueChange={e => setNNf(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Cancelar Cupom</h6>}
        visible={showConfirmCancelarCupomDialog}
        style={{ width: '40vw' }}
        onHide={() => setShowConfirmCancelarCupomDialog(false)}
        footer={dialogCancelarCupomFooter}
      >
        Deseja realmente cancelar o cupom?
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Comandos</h6>}
        visible={showComandosDialog}
        style={{ width: '40vw' }}
        onHide={() => onHideComandosDialog()}
      >
        <div className="col-12">
          <center>
            <div className="row form-elo">
              <center>
                <Button label="(Ctrl + F3) Cancelar Cupom " icon="pi pi-times" style={{ width: '80%' }} onClick={handleCancelarCupom} />
              </center>
            </div>
            <div className="row form-elo">
              <center>
                <Button label="(Ctrl + F10) Consulta Produtos" icon="pi pi-search" style={{ width: '80%' }} onClick={handleConsultaProdutos} />
              </center>
            </div>
            <div className="row form-elo">
              <center>
                <Button label="(Ctrl + F9) Fechar Cupom" icon="pi pi-save" style={{ width: '80%' }} onClick={handleF9} />
              </center>
            </div>
            <div className="row form-elo">
              <center>
                <Button label="(Ctrl + R) Reimprimir Cupom" icon="pi pi-print" style={{ width: '80%' }} onClick={handleConsultaNFCe} />
              </center>
            </div>
          </center>
        </div>
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Administrativo</h6>}
        visible={showAdministrativoDialog}
        style={{ width: '40vw' }}
        onHide={() => onHideAdministrativoDialog()}
      >
        <div className="col-12">
          <center>
            <Button label="Cancelar Cupom Enviado" icon="pi pi-print" style={{ width: '80%' }} onClick={handleConsultaNFCe} />
          </center>
        </div>
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Fechamento Cupom</h6>}
        visible={showPagamentoDialog}
        style={{ width: '98vw' }}
        onHide={() => {
          setShowPagamentoDialog(false);
          setInputDadosEntradaFocus();
        }}
      >
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Total Cupom
              </Input155px>
              <InputNumber
                disabled
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalCupom}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Dinheiro
              </Input100px>
              <InputNumber
                id="pagoDinheiro"
                onFocus={(e) => e.target.select()}
                autoFocus
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={pagoDinheiro}
                onValueChange={e => setPagoDinheiro(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Cheque
              </Input100px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={pagoCheque}
                onValueChange={e => setPagoCheque(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Cartão
              </Input100px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={pagoCartao}
                onValueChange={e => {
                  setPagoCartao(e.target.value);
                  setValorRestanteCartao(e.target.value);
                  setValorParcelamentoCartao(e.target.value);
                }}
                onBlur={(e) => { e.target.value.toString !== '0,00' ? handlePagamentoCartao(e.target.value) : ''; }}
              />
              <Button label="" title="Editar" icon="pi pi-pencil" onClick={() => handleEditPagamentoCartao()} />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Pag. Digital
              </Input100px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={pagoDigital}
                onValueChange={e => {
                  setPagoDigital(e.target.value);
                  setValorRestantePix(e.target.value);
                }}
                onBlur={(e) => handlePagamentoPix(e.target.value)}
              />
              <Button label="" title="Editar" icon="pi pi-pencil" onClick={() => handleEditPagamentoPix()} />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Cliente
              </Input155px>
              <InputText
                maxLength={255}
                value={clienteNome}
                onChange={e => setClienteNome(e.target.value)}
                style={{ fontSize: `${fontSizeDadosEntrada - 20}px` }}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaParticipante(true)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                CNPJ/CPF
              </Input100px>
              <InputText
                onFocus={(e) => e.target.select()}
                autoFocus
                maxLength={20}
                value={clienteCnpj}
                onChange={e => setClienteCnpj(e.target.value)}
                style={{ fontSize: `${fontSizeDadosEntrada - 20}px` }}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Inscrição Estadual
              </Input155px>
              <InputText
                onFocus={(e) => e.target.select()}
                autoFocus
                maxLength={20}
                value={clienteIe}
                onChange={e => setClienteIe(e.target.value)}
                style={{ fontSize: `${fontSizeDadosEntrada - 20}px` }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                E-Mail
              </Input155px>
              <InputText
                onFocus={(e) => e.target.select()}
                autoFocus
                maxLength={100}
                value={clienteEMail}
                onChange={e => setClienteEMail(e.target.value)}
                style={{ fontSize: `${fontSizeDadosEntrada - 20}px` }}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Desconto R$
              </Input100px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorDesconto}
                onValueChange={e => setValorDesconto(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Valor Pago
              </Input100px>
              <InputNumber
                disabled
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorPago}
                onValueChange={e => setValorPago(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Valor Troco
              </Input100px>
              <InputNumber
                disabled
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorTroco}
                onValueChange={e => setValorTroco(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
          </div>
          <div className="col-2">
            <center>
              <Button
                hidden={!liberarFechamentoCupom}
                type="button"
                className="btn btn-success"
                onClick={salvarRegistro}
              >
                <i className="pi pi-check"></i>
                Fechar Cupom
              </Button>
            </center>
          </div>
          <div className="col-2">
            <center>
              <Button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  fecharDialogos();
                  setInputDadosEntradaFocus();
                }}
              >
                <i className="pi pi-times"></i>
                Voltar a Tela Anterior
              </Button>
            </center>
          </div>
          <div className="col-4">
          </div>
        </div>
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center', fontSize: `${fontSizeTitulo}px` }}>Caixa Livre</h6>}
        visible
        maximized
        showHeader="false"
        onHide={() => handleSair()}
        footer={dialogEmissaoNFCeFooter}
        className="dialog-emissaonfce"
      >
        <div className="row form-elo">
          <div className="col-8">
            <div className="col-12">
              {renderTipoAmbiente()}
            </div>
            <div className="col-12">
              <div className="p-inputgroup">
                <InputText
                  ref={inputDadosEntradaFocus}
                  autoFocus
                  maxLength={50}
                  value={dadosEntrada.toUpperCase()}
                  onChange={e => setDadosEntrada(e.target.value.toUpperCase())}
                  style={{ fontSize: `${fontSizeDadosEntrada}px` }}
                  onBlur={e => {
                    if (!showPagamentoDialog && !showConfirmDeleteProdutoDialog
                        && !showProdutoNaoEncontrado && !showQuantidadeProdutoInvalida
                        && !showCodigoProdutoInvalido && !showEscolhaEmpresaDialog
                        && !showComandosDialog && !showAdministrativoDialog
                        && !showProdutoNaoPodeSerFracionado && !showAlterarNumeroCupom) {
                      setTimeout(() => {
                        e.target.focus();
                      }, 100);
                    }
                  }}
                  onKeyDown={async (e) => {
                    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                      handleAdicionarProduto(dadosEntrada);
                    }
                    if (e.code === 'Insert' || (e.ctrlKey && e.code === 'F10')) {
                      handleConsultaProdutos();
                    }
                    if (e.code === 'Delete') {
                      handleDeleteProduto();
                    }
                    if (e.ctrlKey && e.code === 'KeyA') {
                      handleAdministrativo();
                    }
                    if (e.ctrlKey && e.code === 'KeyC') {
                      handleComandos();
                    }
                    if (e.ctrlKey && e.code === 'KeyI') {
                      handleConsultaNFCe();
                    }
                    if (e.ctrlKey && e.code === 'KeyS') {
                      handleSair();
                    }
                    if (e.code === 'F5') {
                      e.preventDefault();
                    }
                    if (e.code === 'F9' || (e.ctrlKey && e.code === 'F9')) {
                      handleF9();
                    }
                    if (e.ctrlKey && e.code === 'F3') {
                      handleCancelarCupom();
                    }
                  }}
                />
              </div>
            </div>
            <div className="col-12">
              <DataTable
                className="datatable-nfceprodutos"
                value={listaProdutos}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                sortField="item"
                selectionMode="single"
                selection={selectedProdutos}
                onSelectionChange={e => setSelectedProdutos(e.value)}
                dataKey="item"
                emptyMessage=" "
                scrollable
                scrollHeight={alturaScrollProdutos + 'px'}
              >
                <Column field="qCom" header="Qtde" sort="false" style={{ maxWidth: '10%', fontSize: 25 }}></Column>
                <Column field="uCom" header="UN" sort="false" style={{ maxWidth: '7%', fontSize: 25 }}></Column>
                <Column field="descricao" header="Descrição" sort="false" style={{ fontSize: 25 }}></Column>
                <Column field="precoBruto" header="Preço" sort="false" style={{ maxWidth: '15%', fontSize: 25 }}></Column>
                <Column field="totalItem" header="Total" sort="false" style={{ maxWidth: '20%', backgroundColor: '#ECEFF1', fontSize: 25 }}></Column>
              </DataTable>
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Dropdown
                disabled={mudarEmpresaDisable}
                options={listaEmpresas}
                value={cnpjEmpresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => {
                  setCnpjEmpresa(e.target.value);
                  const consultaPrevisaoFinanceiraCnpjEmpresa = e.target.value;
                  dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
                  toast.info('Deseja realmente mudar a empresa');
                }}
              />
            </div>
            <div className="col-12">
              <div className="row form-elo">
                <div className="col-12">
                  <div className="p-inputgroup">
                    <InputText
                      disabled
                      maxLength={15}
                      value={formataMoeda(totalCupom)}
                      style={{ textAlign: 'center', color: 'white', fontSize: `${fontSizeTitulo - 10}px`, backgroundColor: 'green' }}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="p-inputgroup">
                    <Input100px className="p-inputgroup-addon">
                      Ítens
                    </Input100px>
                    <InputText
                      disabled
                      maxLength={15}
                      value={listaProdutos.length}
                      style={{ textAlign: 'center', color: 'black', fontSize: `${fontSizeTitulo - 30}px` }}
                    />
                    <Input100px className="p-inputgroup-addon">
                      Volumes
                    </Input100px>
                    <InputText
                      disabled
                      maxLength={15}
                      value={totalVolumes}
                      style={{ textAlign: 'center', color: 'black', fontSize: `${fontSizeTitulo - 30}px` }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
