/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get, orderBy } from 'lodash';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import { displayErrors, formataMoeda, formataTelefone } from '../../../util/diversos';

export default function PdfEstampariaPedido({ payloadPrint, finishPrintPedido }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadPrint, 'id', 0);

  const idUsuario = get(store.getState().auth.user, 'id', 0);

  function headRows() {
    return [
      { qtde: 'Qtde', descricao: 'Descricao', precoFinal: 'Valor UN', totalItem: 'SubTotal' },
    ];
  }

  const [headerTabelaImpressao] = useState(headRows());

  useEffect(() => {
    async function getData(img) {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF();
      try {
        const usuario = await axiosAdmin.get(`/usuario/${idUsuario}`);
        let cnpjEmpresa = usuario.data.listaEmpresas[0].cnpj;
        if (cnpjEmpresa.length === 14) {
          cnpjEmpresa = cnpjEmpresa.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }

        const { data } = await axiosAdmin.get(`/estampariapedido/${id}`);

        if (data.idUsuario !== idUsuario) {
          displayErrors('Acesso negado', dispatch, navigate, '/consultaestampariapedido', '');
        }

        const result = [];
        const _listaItem = [];

        let qtdeItens = 0;
        data.listaEstampariaPedidoItem.forEach(element => {
          const _listaTipoServico = [];
          element.listaEstampariaPedidoTipoServico.forEach(_tipoServico => {
            _listaTipoServico.push({
              parteVestuario: _tipoServico.parteVestuario,
              silkar: _tipoServico.silkar,
              silk: get(_tipoServico.silk, 'id', null),
              sublimar: _tipoServico.sublimar,
              sublimacaoTipo: _tipoServico.sublimacaoTipo,
              bordar: _tipoServico.bordar,
              bordado: get(_tipoServico.bordado, 'id', null),
            });
          });

          _listaItem.push({
            id: get(element.produto, 'id', 0),
            novoRegistro: true,
            item: element.item,
            produto: get(element.produto, 'registro', 0),
            descricao: get(element.produto, 'descricao', ''),
            siglaUnidadeMedida: get(element.produto.unidadeMedida, 'sigla', ''),
            quantidade: element.quantidade,
            precoMercadoria: element.precoMercadoria,
            precoAcessorio: element.precoAcessorio,
            precoBruto: element.precoBruto,
            precoFinal: element.precoFinal,
            totalItem: element.totalItem,
            cancelado: element.cancelado,
            modelo: get(element.estampariaModelo, 'id', 0),
            tamanho: get(element.estampariaTamanho, 'id', 0),
            malha: get(element.estampariaMalha, 'id', 0),
            cor: get(element.estampariaCor, 'id', 0),
            gola: get(element.estampariaGola, 'id', 0),
            golaAcabamento: get(element.estampariaGolaAcabamento, 'id', 0),
            golaCor: get(element.estampariaGolaCor, 'id', 0),
            manga: get(element.estampariaManga, 'id', 0),
            mangaCor: get(element.estampariaMangaCor, 'id', 0),
            mangaAcabamento: get(element.estampariaMangaAcabamento, 'id', 0),
            mangaAcabamentoCor: get(element.estampariaMangaAcabamentoCor, 'id', 0),
            listaEstampariaPedidoTipoServico: _listaTipoServico,
          });

          data.qtde = element.quantidade;
          qtdeItens += element.quantidade;
          let descricao = get(element.produto, 'descricao', '').trim();

          if (element.produto.bolso) {
            descricao += `, Bolso: ${element.produto.bolso}`;
          }
          if (element.estampariaModelo) {
            descricao += `, ${element.estampariaModelo.descricao}`;
          }
          if (element.estampariaTamanho) {
            descricao += `, ${element.estampariaTamanho.descricao}`;
          }
          if (element.estampariaMalha) {
            descricao += `, ${element.estampariaMalha.descricao}`;
          }
          if (element.estampariaCor) {
            descricao += `, ${element.estampariaCor.descricao}\n`;
          }
          descricao += '\n';
          if (element.estampariaBolso) {
            descricao += '\n';
            descricao += `Bolso: ${element.estampariaBolso.descricao}`;
            /* if (element.estampariaBolsoCor) {
              descricao += ` na cor ${element.estampariaBolsoCor.descricao}`;
            } */
          }
          if (element.estampariaGola) {
            descricao += '\n';
            descricao += `Gola: ${element.estampariaGola.descricao}`;
            if (element.estampariaGolaAcabamento) {
              descricao += ` com acabamento ${element.estampariaGolaAcabamento.descricao}`;
            }
            /* if (element.estampariaGolaCor) {
              descricao += ` na cor ${element.estampariaGolaCor.descricao}`;
            } */
          }
          if (element.estampariaManga) {
            descricao += '\n';
            descricao += `Manga: ${element.estampariaManga.descricao}`;
            /* if (element.estampariaMangaCor) {
              descricao += ` cor ${element.estampariaMangaCor.descricao}`;
            } */
            if (element.estampariaMangaAcabamento) {
              descricao += ` com acabamento ${element.estampariaMangaAcabamento.descricao}`;
            }
            /* if (element.estampariaMangaAcabamentoCor) {
              descricao += ` cor ${element.estampariaMangaAcabamentoCor.descricao}`;
            } */
          }
          descricao += '\n';
          for (let x = 0; x < element.listaEstampariaPedidoTipoServico.length; x++) {
            const servico = element.listaEstampariaPedidoTipoServico[x];
            if (servico.parteVestuario === 'FRENTE') {
              if (servico.silkar || servico.sublimar || servico.bordar) {
                descricao += '\n';
                descricao += 'Frente:';
              }
              if (servico.silkar) {
                if (servico.silk.descricao && servico.silk.descricao !== undefined) {
                  descricao += '\n';
                  descricao += `   Silk: ${servico.silk.descricao} `;
                }
              }
              if (servico.sublimar) {
                if (servico.sublimacao.descricao && servico.sublimacao.descricao !== undefined) {
                  descricao += '\n';
                  descricao += `   Sublimar: ${servico.sublimacao.descricao}`;
                }
              }
              if (servico.bordar) {
                if (servico.bordado.descricao && servico.bordado.descricao !== undefined) {
                  descricao += '\n';
                  descricao += `   Bordar: ${servico.bordado.descricao}`;
                }
              }
            }
            if (servico.parteVestuario === 'COSTAS') {
              if (servico.silkar || servico.sublimar || servico.bordar) {
                descricao += '\n';
                descricao += 'Costas:';
              }
              if (servico.silkar) {
                if (servico.silk.descricao && servico.silk.descricao !== undefined) {
                  descricao += '\n';
                  descricao += `   Silk: ${servico.silk.descricao} `;
                }
              }
              if (servico.sublimar) {
                if (servico.sublimacao.descricao && servico.sublimacao.descricao !== undefined) {
                  descricao += '\n';
                  descricao += `   Sublimar: ${servico.sublimacao.descricao}`;
                }
              }
              if (servico.bordar) {
                if (servico.bordado.descricao && servico.bordado.descricao !== undefined) {
                  descricao += '\n';
                  descricao += `   Bordar: ${servico.bordado.descricao}`;
                }
              }
            }
            if (servico.parteVestuario === 'MANGAESQUERDA') {
              if (servico.silkar || servico.sublimar || servico.bordar) {
                descricao += '\n';
                descricao += 'Manga Esquerda:';
              }
              if (servico.silkar) {
                if (servico.silk.descricao && servico.silk.descricao !== undefined) {
                  descricao += '\n';
                  descricao += `   Silk: ${servico.silk.descricao} `;
                }
              }
              if (servico.sublimar) {
                if (servico.sublimacao.descricao && servico.sublimacao.descricao !== undefined) {
                  descricao += '\n';
                  descricao += `   Sublimar: ${servico.sublimacao.descricao}`;
                }
              }
              if (servico.bordar) {
                if (servico.bordado.descricao && servico.bordado.descricao !== undefined) {
                  descricao += '\n';
                  descricao += `   Bordar: ${servico.bordado.descricao}`;
                }
              }
            }
            if (servico.parteVestuario === 'MANGADIREITA') {
              if (servico.silkar || servico.sublimar || servico.bordar) {
                descricao += '\n';
                descricao += 'Manga Direita:';
              }
              if (servico.silkar) {
                if (servico.silk.descricao && servico.silk.descricao !== undefined) {
                  descricao += '\n';
                  descricao += `   Silk: ${servico.silk.descricao} `;
                }
              }
              if (servico.sublimar) {
                if (servico.sublimacao.descricao && servico.sublimacao.descricao !== undefined) {
                  descricao += '\n';
                  descricao += `   Sublimar: ${servico.sublimacao.descricao}`;
                }
              }
              if (servico.bordar) {
                if (servico.bordado.descricao && servico.bordado.descricao !== undefined) {
                  descricao += '\n';
                  descricao += `   Bordar: ${servico.bordado.descricao}`;
                }
              }
            }
          }

          if (element.listaEstampariaPedidoItemAcessorio.length > 0) {
            descricao += '\n';
            descricao += 'Acréscimo(s): ';
            element.listaEstampariaPedidoItemAcessorio.forEach(_listaAcessorios => {
              descricao += `${_listaAcessorios.estampariaAcessorio.descricao}, `;
            });
          }

          data.descricao = descricao;
          data.precoFinal = formataMoeda(element.precoFinal);
          data.totalItem = formataMoeda(element.totalItem);
          result.push({ ...data });
        });

        // linha em branco
        let dados = {
          qtde: '',
          descricao: data.observacao,
          precoFinal: '',
          totalItem: '',
        };
        result.push({ ...dados });

        dados = {
          qtde: qtdeItens.toString(),
          descricao: 'Item(s) ',
          precoFinal: ' Total',
          totalItem: formataMoeda(data.valorTotal),
        };

        result.push({ ...dados });

        doc.autoTable({
          styles: { fontSize: 10 },
          margin: { bottom: 58, top: 38, left: 5, right: 5 },
          theme: 'grid',
          head: headerTabelaImpressao,
          body: result,
          didDrawPage: (_dados) => {
            // Header
            doc.rect(5, 5, 200, 30);
            if (!img.complete) {
              doc.addImage('logo.png', 'JPEG', 10, 12, 20, 15);
            } else {
              doc.addImage(img, 'JPEG', 9, 8, 25, 25);
            }
            doc.setFontSize(20);
            doc.text('Pedido: ', 40, 13);
            doc.text(id.toString(), 65, 13);

            doc.setFontSize(8);
            usuario.data.listaEmpresas[0].nomeFantasia ? doc.text(usuario.data.listaEmpresas[0].nomeFantasia, 135, 8) : '';
            cnpjEmpresa ? doc.text(`CNPJ: ${cnpjEmpresa}`, 135, 12) : '';
            usuario.data.email ? doc.text(usuario.data.email, 135, 16) : '';
            if (usuario.data.listaEmpresas[0].endereco && usuario.data.listaEmpresas[0].enderecoNumero) {
              const endereco = `${usuario.data.listaEmpresas[0].endereco} - ${usuario.data.listaEmpresas[0].enderecoNumero}`;
              doc.text(endereco, 135, 20);
            }
            usuario.data.listaEmpresas[0].uf ? doc.text(usuario.data.listaEmpresas[0].uf, 135, 24) : '';
            usuario.data.listaEmpresas[0].cidade ? doc.text(usuario.data.listaEmpresas[0].cidade, 142, 24) : '';
            usuario.data.listaEmpresas[0].telefone_1 ? doc.text(usuario.data.listaEmpresas[0].telefone_1, 135, 28) : '';
            usuario.data.listaEmpresas[0].telefone_2 ? doc.text(usuario.data.listaEmpresas[0].telefone_2, 160, 28) : '';

            doc.setFontSize(10);
            data.nomePedido ? doc.text(data.nomePedido.substring(0, 44), 40, 18) : '';
            doc.setFontSize(8);

            let cliente = '';
            if (data.cliente) {
              if (data.cliente.cnpj && data.cliente.cnpj.length === 14) {
                doc.text('CNPJ: ', 40, 22);
                doc.text(data.cliente.cnpj, 50, 22);
              }
              doc.text('Cliente: ', 40, 25);
              cliente = `${data.cliente.id} - ${data.cliente.razaoSocial.substring(0, 45)}`;
              doc.text(cliente, 50, 25);

              doc.text('Telefone: ', 40, 29);
              data.cliente.telefone_1 ? doc.text(formataTelefone(data.cliente.telefone_1), 53, 29) : '';
            }

            doc.text('Vendedor: ', 40, 33);
            data.vendedor ? doc.text(data.vendedor.codigo + ' - ' + data.vendedor.nome, 54, 33) : '';

            doc.setFontSize(8);
            doc.text('Data:', 135, 33);
            doc.setFontSize(10);
            doc.text(new Date(data.data[0], data.data[1] - 1, data.data[2]).toLocaleDateString('pt-br'), 144, 33);
            doc.setFontSize(8);
            doc.text('Prev. Entrega:', 166, 33);
            doc.setFontSize(10);
            data.dataPrevisaoEntrega ? doc.text(new Date(data.dataPrevisaoEntrega[0], data.dataPrevisaoEntrega[1] - 1, data.dataPrevisaoEntrega[2]).toLocaleDateString('pt-br'), 185, 33) : '';

            doc.setFontSize(8);
            // footer
            const yPos = _dados.cursor.y;

            let MensagemPadraoPagamento = '';
            MensagemPadraoPagamento += 'Pagamento: PIX ou link para cartão de débito ou crédito, depósito ou transferência. Pagamento presencial em dinheiro ou cartão de débito ou crédito.\n';
            MensagemPadraoPagamento += ' Valor dividido em até 3x no cartão terá acréscimo de 10% no valor total. NÃO aceitamos cheques.';

            let totalPago = 0;
            let contador = 0;
            let linha = yPos + 11;
            let colunaData = 24;
            const lista = orderBy(data.listaEstampariaPedidoPagamento, ['entrada', 'dataPagamento'], ['desc', 'asc']);
            lista.forEach(element => {
              if (contador % 2 === 0) {
                colunaData = 7;
                if (contador > 0) {
                  linha = linha + 4;
                }
              } else {
                colunaData = colunaData + 105;
              }

              doc.setFontSize(8);
              if (element.entrada) {
                doc.text('Entrada:', colunaData, linha);

                if (element.meioPagamento.descricao !== undefined) {
                  data.entradaMeioPagamento = element.meioPagamento.descricao;
                }

                if (element.numeroParcelas !== undefined && element.numeroParcelas !== null) {
                  if (element.numeroParcelas > 1) {
                    data.entradaMeioPagamento += ' ' + element.numeroParcelas.toString() + 'x';
                  }
                }

                data.entradaDataPagamento = element.dataPagamento;
                data.entradaValor = formataMoeda(element.valor);

                let entradaDataPagamento;
                if (data.entradaDataPagamento !== undefined) {
                  entradaDataPagamento = `${('00' + data.entradaDataPagamento[2]).slice(-2)}/${('00' + data.entradaDataPagamento[1]).slice(-2)}/${data.entradaDataPagamento[0]}`;
                }
                doc.text(`${entradaDataPagamento ? entradaDataPagamento : ''}`, colunaData + 13, linha);
                doc.text(`${data.entradaMeioPagamento ? data.entradaMeioPagamento.substring(0, 35) : ''}`, colunaData + 28, linha);
                doc.text(`${data.entradaValor ? data.entradaValor : ''}`, colunaData + 73, linha);
                totalPago += element.valor;
              } else {
                doc.text('Restante:', colunaData, linha);

                if (element.meioPagamento.descricao !== undefined) {
                  data.entradaMeioPagamento = element.meioPagamento.descricao;
                }

                if (element.numeroParcelas !== undefined && element.numeroParcelas !== null) {
                  if (element.numeroParcelas > 1) {
                    data.entradaMeioPagamento += ' ' + element.numeroParcelas.toString() + 'x';
                  }
                }

                let restanteDataPagamento;
                if (element.dataPagamento !== undefined) {
                  restanteDataPagamento = `${('00' + element.dataPagamento[2]).slice(-2)}/${('00' + element.dataPagamento[1]).slice(-2)}/${element.dataPagamento[0]}`;
                }
                doc.text(`${restanteDataPagamento ? restanteDataPagamento : ''}`, colunaData + 13, linha);
                doc.text(`${data.entradaMeioPagamento ? data.entradaMeioPagamento.substring(0, 35) : ''}`, colunaData + 28, linha);
                doc.text(`${element.valor ? formataMoeda(element.valor) : ''}`, colunaData + 73, linha);
                totalPago += element.valor;
              }
              contador++;
            });

            doc.text(`Recebido: ${formataMoeda(totalPago)}`, 7, yPos + 5);
            doc.text(`Restante: ${formataMoeda(data.valorTotal - totalPago)}`, 100, yPos + 5);
            doc.setFontSize(8);
            doc.rect(5, yPos + 7, 200, 27);
            doc.text(MensagemPadraoPagamento, 7, yPos + 30);
            doc.text('Visto Cliente:', 7, yPos + 39);
            doc.text('______________________________________________________________________________________', 25, yPos + 39);
            doc.text('Data:', 162, yPos + 39);
            doc.text('_____/_____/__________', 170, yPos + 39);

            // footer
            const { pageSize } = doc.internal;
            const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
            doc.text(`Pag ${doc.internal.getNumberOfPages()}`, _dados.settings.margin.right, pageHeight - 8);
            const periodo = new Date();
            doc.text('Impresso: ', 172, pageHeight - 8);
            // eslint-disable-next-line prefer-template
            doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 189, pageHeight - 8);
          },
        });
        window.open(doc.output('bloburl'));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('erro', err);
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        // funcao no pai pra fechar a tela de impressao
        finishPrintPedido();
      }
    }

    const loadImage = async () => {
      let config = '';
      try {
        config = await axiosAdmin.get(`/configuracaosistema?idUsuario=${idUsuario}`);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }

      const img = new Image();
      img.src = `/logotipos/${config.data.urlLogotipo}`;
      await img.decode().then(() => {

      }).catch(() => {
        img.src = 'logo.png';
      });
      getData(img);
    };
    loadImage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch, navigate]);

  return (
    <div></div>
  );
}
