export function obterListaCodNatCc() {
  return (
    [
      { label: 'Selecione...', value: '' },
      { label: 'Contas de Ativo', value: '01' },
      { label: 'Contas de Passivo', value: '02' },
      { label: 'Patrimônio Líquido', value: '03' },
      { label: 'Contas de Resultado', value: '04' },
      { label: 'Contas de Compensação', value: '05' },
      { label: 'Outras', value: '09' },
    ]
  );
}

export function obterListaIndCta() {
  return (
    [
      { label: 'Selecione...', value: '' },
      { label: 'Sintética (grupo de contas)', value: 'S' },
      { label: 'Analítica (conta)', value: 'A' },
    ]
  );
}
