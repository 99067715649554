export function obterListaIndicadorProcessoRef() {
  return (
    [
      { label: '0 - SEFAZ', value: 'SEFAZ' },
      { label: '1 - Justiça Federal', value: 'JUSTICAFEDERAL' },
      { label: '2 - Justiça Estadual', value: 'JUSTICAESTADUAL' },
      { label: '3 - Secex/RFB', value: 'SECEXRFB' },
      { label: '9 - Outros', value: 'OUTROS' },
    ]
  );
}
