import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { Dropdown } from 'primereact/dropdown';

import * as actions from '../../../../store/modules/auth/actions';

import { displayErrors } from '../../../../util/diversos';
import { Input155px } from '../../../../styles/inputGroup';

import Card from '../../../../components/card';
import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Loading from '../../../loading';

export default function StatusServico() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [empresa, setEmpresa] = useState(null);
  const [listaEmpresa, setListaEmpresa] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const newTitle = 'Configuração do Sistema - Edição';

    dispatch(actions.titleHeaderChange({ newTitle }));

    let mostrarMensagemErro = true;

    async function getData() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];
        const { data: _usuario } = await axiosAdmin.get(`/usuario/${idUsuario}`);

        const listaEmpresas = get(_usuario, 'listaEmpresas', []);

        let idEmpresaSelecionar = 0;

        listaEmpresas.forEach(element => {
          listDropdown.push({ label: `${element.razaoSocial}  (${element.nomeFantasia})`, value: element.id });

          if (idEmpresaSelecionar === 0 || idEmpresaSelecionar > element.id) {
            idEmpresaSelecionar = element.id;
          }
        });

        setListaEmpresa(listDropdown);
        setEmpresa(idEmpresaSelecionar);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  async function consultarStatusServico() {
    try {
      setIsLoading(true);

      const { data: statusRetorno } = await axiosAdmin.get(`/nfeutil/statusservico/${empresa}`);

      if (statusRetorno.substring(0, 4) === 'Erro') {
        toast.error(statusRetorno);
      } else {
        toast.success(statusRetorno);
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível consultar o status do serviço');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErros = false;

    if (formErros) return;

    consultarStatusServico();
  };

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>Configuração do Sistema - Edição</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Consultar Status Serviço
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-lg-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Empresa
              </Input155px>
              <Dropdown
                options={listaEmpresa}
                value={empresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setEmpresa(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
