import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { DataTable } from 'primereact/datatable';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { TabView, TabPanel } from 'primereact/tabview';
import { addLocale } from 'primereact/api';

import moment from 'moment';

// import createLog from 'localstorage-logger';

import * as actions from '../../../../store/modules/auth/actions';

import { displayErrors, findInList, converteData, formataDataArray, formataMoeda, dateTimeToArray, formataDataMoment } from '../../../../util/diversos';
import { Input100px, Input155px } from '../../../../styles/inputGroup';
import { fillColumns } from '../../../../components/table';

import Card from '../../../../components/card';
import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Loading from '../../../loading';

export default function CadastroEstampariaRecebimentoPedido({ payloadCadastro, childToParentCadastro }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const log = createLog({ logName: 'recebimentopedido' + moment().format('YYYYMMDDHHmmss'), maxLogSizeInBytes: 1000 * 1024 });

  const id = get(payloadCadastro, 'id', 0);

  const [dataPedido, setDataPedido] = useState('');
  const [nomePedido, setNomePedido] = useState('');
  const [razaoSocialCliente, setRazaoSocialCliente] = useState('');
  const [valorTotal, setValorTotal] = useState(0);
  const [valorPago, setValorPago] = useState(0);
  const [listaPagamentoEfetuado, setListaPagamentoEfetuado] = useState([]);
  const [listaPagamento, setListaPagamento] = useState([]);
  const [selectedPagamentos, setSelectedPagamentos] = useState(null);
  const [showCadastroPagamento, setShowCadastroPagamento] = useState(false);
  const [showConfirmDeletePagamento, setShowConfirmDeletePagamento] = useState(false);
  const [pagamentoEntrada, setPagamentoEntrada] = useState(false);
  const [pagamentoMeioPagamento, setPagamentoMeioPagamento] = useState(0);
  const [pagamentoDataPagamento, setPagamentoDataPagamento] = useState(null);
  const [pagamentoNumeroParcelas, setPagamentoNumeroParcelas] = useState(1);
  const [pagamentoValor, setPagamentoValor] = useState(0);
  const [listaMeioPagamento, setListaMeioPagamento] = useState([]);
  const [tabPagamento, setTabPagamento] = useState(1);
  const [columnsSetupPagamento] = useState([
    { field: 'entrada', header: 'Entrada', sort: true, booleano: true },
    { field: 'dataPagamento', header: 'Data Pagamento', sort: false },
    { field: 'descricaoMeioPagamento', header: 'Meio Pagamento', sort: false },
    { field: 'numeroParcelas', header: 'Parcelas', sort: false },
    { field: 'valor', header: 'Valor Pagamento', sort: false },
  ]);
  const [listaPagamentoEntrada] = useState([
    { label: 'Sim', value: true },
    { label: 'Não', value: false },
  ]);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const newTitle = 'Recebimento de Pedido';

    dispatch(actions.titleHeaderChange({ newTitle }));

    let mostrarMensagemErro = true;

    async function getMeioPagamento() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];

        const { data: _meiopagamentos } = await axiosAdmin.get(`/meiopagamento?idUsuario=${idUsuario}`);

        _meiopagamentos.forEach(element => {
          listDropdown.push({ label: `${element.descricao}`, value: element.id, maximoParcelas: element.maximoParcelas });
        });

        setListaMeioPagamento(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/estampariapedido/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultaestampariarecebimentopedido');
          toast.error('Acesso negado');
        }

        const _data = converteData(data.data, true);

        setDataPedido(_data);
        setNomePedido(data.nomePedido);
        setRazaoSocialCliente(get(data, 'cliente.razaoSocial', ''));
        setValorTotal(data.valorTotal);
        setValorPago(data.valorPago);

        let item = 0;
        let itemEfetuado = 0;

        const _listaPagamentoEfetuado = [];
        const _listaPagamento = [];

        data.listaEstampariaPedidoPagamento.forEach(element => {
          const _dataPagamento = converteData(element.dataPagamento, true);

          if (formataDataMoment(moment()) !== formataDataMoment(_dataPagamento)) {
            itemEfetuado = itemEfetuado + 1;

            _listaPagamentoEfetuado.push({
              item,
              entrada: element.entrada,
              meioPagamento: get(element.meioPagamento, 'id', 0),
              descricaoMeioPagamento: get(element.meioPagamento, 'descricao', ''),
              dataPagamento: element.dataPagamento,
              numeroParcelas: element.numeroParcelas,
              valor: element.valor,
            });
          } else {
            item = item + 1;

            _listaPagamento.push({
              item,
              entrada: element.entrada,
              meioPagamento: get(element.meioPagamento, 'id', 0),
              descricaoMeioPagamento: get(element.meioPagamento, 'descricao', ''),
              dataPagamento: element.dataPagamento,
              numeroParcelas: element.numeroParcelas,
              valor: element.valor,
            });
          }
        });

        setListaPagamentoEfetuado(_listaPagamentoEfetuado);
        setListaPagamento(_listaPagamento);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getMeioPagamento();
    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const idOperador = get(store.getState().auth, 'idOperador', null);

      let payloadDataTableList = {};

      const tmpLista = [];

      let item = 1;

      listaPagamento.forEach(element => {
        // eslint-disable-next-line no-undef
        const _element = _.cloneDeep(element);

        _element.dataPagamento = formataDataArray(element.dataPagamento, '-', true);

        _element.item = item;

        item = item + 1;

        tmpLista.push(_element);
      });

      listaPagamentoEfetuado.forEach(elementEfetuado => {
        // eslint-disable-next-line no-undef
        const _elementEfetuado = _.cloneDeep(elementEfetuado);

        _elementEfetuado.dataPagamento = formataDataArray(elementEfetuado.dataPagamento, '-', true);

        _elementEfetuado.item = item;

        item = item + 1;

        tmpLista.push(_elementEfetuado);
      });

      const shippingData = {
        idUsuario,
        operador: idOperador,
        listaEstampariaPedidoPagamento: tmpLista,
      };

      if (id > 0) {
        // log.info('Put id: ' + id);
        payloadDataTableList = get(await axiosAdmin.put(`/estampariapedido/receber/${id}`, shippingData), 'data', {});
        toast.success('Recebimento de Pedido realizado com sucesso');
      }

      childToParentCadastro(payloadDataTableList, id);
    } catch (err) {
      // log.error('Erro: ' + err);
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentCadastro();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErros = false;

    if (formErros) return;

    salvarRegistro();
  };

  function limparCamposPagamento() {
    setPagamentoEntrada(false);
    setPagamentoMeioPagamento(0);
    setPagamentoDataPagamento(moment().toDate());
    setPagamentoNumeroParcelas(1);
    setPagamentoValor(0);
  }

  const onHideCadastroPagamento = () => {
    setShowCadastroPagamento(false);
  };

  const handleOkPagamento = () => {
    if (!pagamentoDataPagamento) {
      toast.warning('Data de Pagamento deve ser informada');
      return;
    }
    if (pagamentoMeioPagamento <= 0) {
      toast.warning('Meio de Pagamento deve ser informado');
      return;
    }
    if (pagamentoValor <= 0) {
      toast.warning('Valor deve ser informado');
      return;
    }
    if (pagamentoNumeroParcelas > findInList(listaMeioPagamento, pagamentoMeioPagamento, 'maximoParcelas')) {
      toast.warning('Número de parcelas excede o máximo de parcelas deste meio de pagamento');
      return;
    }

    const _listaPagamento = listaPagamento;

    const descricaoMeioPagamento = listaMeioPagamento.find(element => element.value === pagamentoMeioPagamento).label;

    let item = 1;

    _listaPagamento.forEach(element => {
      if (element.item > item) {
        item = element.item + 1;
      }
    });

    _listaPagamento.push({
      item,
      entrada: pagamentoEntrada,
      meioPagamento: pagamentoMeioPagamento,
      descricaoMeioPagamento,
      dataPagamento: dateTimeToArray(pagamentoDataPagamento),
      numeroParcelas: pagamentoNumeroParcelas,
      valor: pagamentoValor,
    });

    setValorPago(valorPago + pagamentoValor);

    setListaPagamento(_listaPagamento);

    setShowCadastroPagamento(false);
  };

  const confirmDeletePagamento = () => {
    const item = get(selectedPagamentos, 'item', 0);

    if (item > 0) {
      const _listaPagamento = listaPagamento;

      const { valor: _valorEstorno } = _listaPagamento.find(element => element.item === item);

      const index = _listaPagamento.indexOf(_listaPagamento.find(element => element.item === item));

      if (index >= 0) {
        _listaPagamento.splice(index, 1);
      }

      if (_valorEstorno && _valorEstorno > 0) {
        setValorPago(valorPago - _valorEstorno);
      }

      setSelectedPagamentos(null);
      setListaPagamento(_listaPagamento);
    }

    setShowConfirmDeletePagamento(false);
  };

  const cancelDeletePagamento = () => {
    setShowConfirmDeletePagamento(false);
  };

  const confirmDialogFooterPagamento = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeletePagamento} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeletePagamento} />
    </div>
  );

  const handleExcluirPagamento = (e) => {
    e.preventDefault();

    const item = get(selectedPagamentos, 'item', 0);

    if (item > 0) {
      setShowConfirmDeletePagamento(true);
    } else {
      toast.warning('Por favor, selecione o pagamento para exclusão');
    }
  };

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>Recebimento de Pedido</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>

        <div className="row form-elo">
          <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
            <div className="col-lg-12">
              <div style={{ display: 'flex', justifyContent: 'center', position: 'center' }}>
                <h6>Data do Pedido: {formataDataMoment(dataPedido)} - {nomePedido}</h6>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', position: 'center' }}>
                <h6>Cliente: {razaoSocialCliente}</h6>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', position: 'center' }}>
                <h5>[ Total do Pedido: {formataMoeda(valorTotal)} ]&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;[ Total Pago: {formataMoeda(valorPago)} ]</h5>
              </div>
            </div>
          </div>
        </div>

        <TabView activeIndex={tabPagamento} onTabChange={(e) => setTabPagamento(e.index)}>
          <TabPanel header="Pagamentos já Efetuados">
            <div className="row form-elo">
              <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
                <div className="col-lg-12">
                  <div style={{ display: 'flex', justifyContent: 'center', position: 'center' }}>
                    <h6>Pagamentos já Efetuados</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="estamparia-pedido-pagamento">
              <DataTable
                disabled
                value={listaPagamentoEfetuado}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                sortField="entrada"
                selectionMode="single"
                dataKey="item"
                emptyMessage="Nenhum Ítem cadastrado"
              >
                {fillColumns(columnsSetupPagamento)}
              </DataTable>
            </div>
          </TabPanel>
          <TabPanel header="Pagamentos de Hoje">
            <div className="row form-elo">
              <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
                <div className="col-lg-12">
                  <div style={{ display: 'flex', justifyContent: 'center', position: 'center' }}>
                    <h6>Pagamentos de Hoje</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="estamparia-pedido-pagamento">
              <DataTable
                value={listaPagamento}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                sortField="entrada"
                selectionMode="single"
                selection={selectedPagamentos}
                onSelectionChange={e => setSelectedPagamentos(e.value)}
                selectableRows
                dataKey="item"
                emptyMessage="Nenhum Ítem cadastrado"
              >
                {fillColumns(columnsSetupPagamento)}
              </DataTable>

              <br />

              <Dialog
                header="Confirmação"
                visible={showConfirmDeletePagamento}
                style={{ width: '50vw' }}
                footer={confirmDialogFooterPagamento}
                onHide={() => setShowConfirmDeletePagamento(false)}
              >
                <p>
                  Excluir o pagamento?
                </p>
              </Dialog>

              <Dialog
                header="Pedido - Pagamento"
                visible={showCadastroPagamento}
                style={{ width: '98vw' }}
                onHide={() => onHideCadastroPagamento()}
              >
                <div className="row form-elo">
                  <div className="col-lg-3">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Entrada
                      </Input155px>
                      <Dropdown
                        options={listaPagamentoEntrada}
                        value={pagamentoEntrada}
                        emptyMessage="Nenhum registro a ser exibido"
                        onChange={e => setPagamentoEntrada(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-9">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Meio Pagamento
                      </Input155px>
                      <Dropdown
                        value={pagamentoMeioPagamento}
                        options={listaMeioPagamento}
                        onChange={(e) => setPagamentoMeioPagamento(e.value)}
                        filter
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Data Pagamento
                      </Input155px>
                      <Calendar
                        id="mask"
                        showIcon
                        showTime
                        showSeconds
                        hourFormat="24"
                        mask="99/99/9999 99:99:99"
                        dateFormat="dd/mm/yy"
                        showButtonBar
                        showOnFocus={false}
                        locale="pt-BR"
                        value={pagamentoDataPagamento}
                        onChange={(e) => setPagamentoDataPagamento(e.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input100px className="p-inputgroup-addon">
                        Valor
                      </Input100px>
                      <InputNumber
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={pagamentoValor}
                        onValueChange={e => setPagamentoValor(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Nº Parcelas
                      </Input155px>
                      <InputNumber
                        min={1}
                        max={999}
                        value={pagamentoNumeroParcelas}
                        onValueChange={e => setPagamentoNumeroParcelas(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handleOkPagamento}
                    >
                      <i className="pi pi-check"></i>
                      Ok
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => setShowCadastroPagamento(false)}
                    >
                      <i className="pi pi-times"></i>
                      Cancelar
                    </button>
                  </div>
                </div>
              </Dialog>

              <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <button
                  type="button"
                  title="Incluir Pagamento"
                  className="btn btn-primary"
                  style={{ borderRadius: '15px' }}
                  onClick={() => {
                    limparCamposPagamento(true);
                    setShowCadastroPagamento(true);
                  }}
                >
                  <i className="pi pi-plus"></i>
                </button>
                <button
                  type="button"
                  title="Excluir Pagamento"
                  className="btn btn-danger"
                  style={{ borderRadius: '15px' }}
                  onClick={(e) => handleExcluirPagamento(e)}
                >
                  <i className="pi pi-trash"></i>
                </button>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </Card>
    </div>
  );
}
