import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { get, orderBy } from 'lodash';
import { Base64 } from 'js-base64';
import { save } from 'save-file';

import moment from 'moment';
import Modal from 'react-bootstrap/Modal';

import dateFormat from 'dateformat';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';
import { Input100px, Input155px } from '../../../styles/inputGroup';

import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';
import CadastroRecebimentoEmAberto from './cadastroRecebimentoEmAberto';

export default function ConsultaRecebimentoEmAberto({ somenteLeitura = false}) {
  const [isLoading, setIsLoading] = useState(false);
  const [showCadastro, setShowCadastro] = useState(false);
  const [modalRetaguardaLogAcessoShow, setModalRetaguardaLogAcessoShow] = useState(false);
  const [retaguardaLogAcessoList, setRetaguardaLogAcessoList] = useState([]);
  const [retaguardaLogAcessoTotalRecords, setRetaguardaLogAcessoTotalRecords] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalSelecionado, setTotalSelecionado] = useState(0);
  const [nossoNumeroSelecionar, setNossoNumeroSelecionar] = useState('');
  const [dataTableList, setDataTableList] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedItens, setSelectedItens] = useState(null);
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [payloadCadastro, setPayloadCadastro] = useState({});
  const [dataQuitacao, setDataQuitacao] = useState(null);
  const [showQuitacaoModal, setShowQuitacaoModal] = useState(false);
  const [columnsSetup] = useState([
    { field: 'dataVencimento', header: 'Data Vencimento', sort: true },
    { field: 'nomeEmpresa', header: 'Nome da Empresa', sort: true },
    { field: 'empresa.cidade', header: 'Município', sort: true },
    { field: 'valorAberto', header: 'Valor em Aberto', sort: false },
    { field: 'valorPago', header: 'Valor Pago', sort: false },
    { field: 'empresa.dataLicenca', header: 'Licença', sort: false, empresaDataLicenca: true },
    { field: 'nossoNumero', header: 'Nosso Número', sort: false },
  ]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 1000,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getRetaguardaLogAcesso(cnpjEmpresa, page, size) {
    try {
      setIsLoading(true);

      let params = `/retaguardalogacesso/search?cnpj=${cnpjEmpresa}`;

      const _dataInicial = '01-01-1970 00:00:01';

      const _dataFinal = '31-12-2099 23:59:59';

      params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setRetaguardaLogAcessoList(response.data.content);
      setRetaguardaLogAcessoTotalRecords(response.data.totalElements);
    } finally {
      setIsLoading(false);
    }
  }

  const handleHideModalRetaguardaLogAcesso = () => {
    setModalRetaguardaLogAcessoShow(false);
  };

  const onPageRetaguardaLogAcesso = (event) => {
    setLazyParams(event);

    const cnpj = get(selectedItens[0].empresa, 'cnpj', 0);

    getRetaguardaLogAcesso(cnpj, event.page, event.rows);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function ModalRetaguardaLogAcesso(props) {
    const [retaguardaLogAcessoColumnsSetup] = useState([
      { field: 'dataHoraAcesso', header: 'Data/Hora', sort: false, dataHora: true },
      { field: 'modulo', header: 'Módulo', sort: false },
      { field: 'terminal', header: 'Terminal', sort: false },
      { field: 'autorizado', header: 'Autorizado', sort: false, booleano: true },
    ]);

    if (!props.show) {
      return null;
    }

    const headerTable = (title) => (
      <div className="table-header">
        {title}
      </div>
    );

    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" dialogClassName="main-modal">
        <Modal.Body className="show-grid">
          <div className="row form-elo">
            <div className="col-12">
              <DataTable
                header={headerTable('Log de Acesso do Retaguarda')}
                value={retaguardaLogAcessoList}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                selectionMode="single"
                selectableRows
                dataKey="id"
                paginator
                lazy
                first={lazyParams.first}
                rows={lazyParams.rows}
                totalRecords={retaguardaLogAcessoTotalRecords}
                onPage={onPageRetaguardaLogAcesso}
                emptyMessage="Nenhum registro a ser exibido"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{currentPage} de {totalPages}"
                rowsPerPageOptions={[10, 25, 50]}
              >
                {fillColumns(retaguardaLogAcessoColumnsSetup)}
              </DataTable>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleHideModalRetaguardaLogAcesso}
            type="button"
            className="btn btn-primary"
          >
            <i className="pi pi-times"></i>
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  async function getData(page, size) {
    try {
      setIsLoading(true);

      let params = '/elorecebimento/search?somenteAberto=true';

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  function calcularTotalSelecionado() {
    let _totalSelecionado = 0;

    if (selectedItens && selectedItens.length > 0) {
      selectedItens.forEach(element => {
        _totalSelecionado = _totalSelecionado + element.valorAberto;
      });
    }

    setTotalSelecionado(_totalSelecionado);
  }

  useEffect(() => {
    calcularTotalSelecionado();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItens]);

  useEffect(() => {
    getData(0, lazyParams.rows);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleRegister = () => {
    const id = 0;

    setPayloadCadastro({ id });

    setShowCadastro(true);
  };

  const handleEdit = (e) => {
    e.preventDefault();

    if (!selectedItens || selectedItens.length === 0) {
      toast.warning('Por favor, selecione o registro para edição');
      return;
    }

    if (selectedItens.length > 1) {
      toast.warning('Somente um registro pode ser selecionado para alteração');
      return;
    }

    const id = get(selectedItens[0], 'id', 0);

    if (id > 0) {
      setPayloadCadastro({ id });
      setShowCadastro(true);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    if (!selectedItens || selectedItens.length === 0) {
      toast.warning('Por favor, selecione o registro para exclusão');
      return;
    }

    if (selectedItens.length > 1) {
      toast.warning('Somente um registro pode ser selecionado para exclusão');
      return;
    }

    const id = get(selectedItens[0], 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens[0], 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/elorecebimento/${idExclusao}`);

        setSelectedItens(null);

        getData(0, lazyParams.rows);

        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    getData(event.page, event.rows);
  };

  const handleSelecionarNossoNumero = () => {
    if (!nossoNumeroSelecionar) {
      toast.error('Informe o Nosso Número para busca');
      return;
    }

    let registroEncontrado = false;
    let incluirRegistro = true;

    dataTableList.forEach(element => {
      if (element.nossoNumero === nossoNumeroSelecionar) {
        if (selectedItens && selectedItens.length > 0) {
          selectedItens.forEach(elementSelectedItens => {
            if (element.id === elementSelectedItens.id) {
              toast.warning('Nosso Número já foi selecionado');
              incluirRegistro = false;
            }
          });
        }

        if (incluirRegistro) {
          let _selectedItens = selectedItens;
          if (!_selectedItens) {
            _selectedItens = [];
          }
          _selectedItens.push(element);
          setSelectedItens(_selectedItens);
          calcularTotalSelecionado();
        }

        setNossoNumeroSelecionar('');

        registroEncontrado = true;
      }
    });

    if (!registroEncontrado) {
      toast.error('Nosso Número não encontrado');
    }
  };

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  // eslint-disable-next-line no-unused-vars
  const childToParentConsulta = (payloadDataTableList, id) => {
    if (payloadDataTableList) {
      setSelectedItens(null);

      getData(0, lazyParams.rows);
    }

    setShowCadastro(false);
  };

  const handleQuitacao = (e) => {
    e.preventDefault();

    if (!selectedItens || selectedItens.length <= 0) {
      toast.warning('Por favor, selecione o(s) registro(s) para quitação');
      return;
    }

    let continuar = true;

    selectedItens.forEach(element => {
      if (element.valorAberto && element.valorAberto <= 0) {
        toast.error('Existe um registro já quitado nos registros selecionados. Quitação não permitida');
        continuar = false;
      }
    });

    if (continuar) {
      setDataQuitacao(moment().toDate());
      setShowQuitacaoModal(true);
    }
  };

  const handleFinalizarQuitacao = (ok) => {
    async function salvarQuitacao(shippingData) {
      try {
        setIsLoading(true);

        const _data = dateFormat(dataQuitacao, 'dd-mm-yyyy');

        const params = `/elorecebimento/quitacao?data=${_data}`;

        await axiosAdmin.post(params, shippingData);

        setSelectedItens(null);

        getData(0, lazyParams.rows);

        toast.success('Quitação realizada com sucesso!');
      } catch (err) {
        displayErrors(err, dispatch, navigate, '', 'Não foi possível realizar a quitação');
      } finally {
        setIsLoading(false);
      }
    }

    if (ok) {
      if (!dataQuitacao) {
        toast.error('Data de quitação deve ser informada');
        return;
      }

      const listId = [];

      selectedItens.forEach(element => {
        listId.push(element.id);
      });

      const shippingData = {
        listId,
      };

      salvarQuitacao(shippingData);
    }

    setShowQuitacaoModal(false);
  };

  const handleViewRetaguardaLogAcesso = (e) => {
    e.preventDefault();

    if (!selectedItens || selectedItens.length === 0) {
      toast.warning('Por favor, selecione o registro para visualização do Histórico de Acessos do Retaguarda');
      return;
    }

    if (selectedItens.length > 1) {
      toast.warning('Somente um registro pode ser selecionado para visualização do Histórico de Acessos do Retaguarda');
      return;
    }

    const cnpj = get(selectedItens[0].empresa, 'cnpj', 0);

    if (cnpj && cnpj.length > 0) {
      getRetaguardaLogAcesso(cnpj, 0, lazyParams.rows);
      setModalRetaguardaLogAcessoShow(true);
    } else {
      toast.warning('Empresa selecionada não possui histórico de acessos');
    }
  };

  async function salvarPDF(conteudo, nome) {
    await save(conteudo, nome);
  }

  async function downloadPDFBoleto(id) {
    const params = `/elorecebimento/downloadpdf/${id}`;

    const response = await axiosAdmin.get(params);

    const listFiles = [];

    response.data.forEach(element => {
      const byteCharacters = Base64.atob(element.conteudo);

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      const blobFile = new Blob([byteArray], { type: 'application/pdf' });

      listFiles.push(blobFile);

      salvarPDF(blobFile, element.nome + '.pdf');
    });
  }

  const handleDownloadPDFBoleto = (e) => {
    const id = get(e, 'id', 0);

    try {
      setIsLoading(true);

      downloadPDFBoleto(id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível realizar o download do(s) PDF(s): ' + err);
    } finally {
      setIsLoading(false);
    }
  };

  const renderFooterQuitacaoModal = () => (
    <div>
      <Button label="Não" icon="pi pi-times" onClick={() => handleFinalizarQuitacao(false)} className="p-button-text" />
      <Button label="Sim" icon="pi pi-check" onClick={() => handleFinalizarQuitacao(true)} autoFocus />
    </div>
  );

  const downloadPDFTemplate = (rowData) => (
    rowData.nossoNumero ? (
      <button
        type="button"
        title="Download PDF Boleto"
        className="btn btn-success"
        style={{ borderRadius: '15px' }}
        onClick={() => handleDownloadPDFBoleto(rowData)}
      >
        <i className="pi pi-download"></i>
      </button>
    ) : ('')
  );

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroRecebimentoEmAberto payloadCadastro={payloadCadastro} childToParentConsulta={childToParentConsulta} />
      </Dialog>

      <ModalRetaguardaLogAcesso
        show={modalRetaguardaLogAcessoShow}
        onHide={handleHideModalRetaguardaLogAcesso}
        dialogClassName="modal-90w"
      />

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              Recebimentos em Aberto
            </h6>
          </div>
          <div className="row form-elo">
            <div className="col-5">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Nosso Número
                </Input155px>
                <InputText
                  maxLength={100}
                  value={nossoNumeroSelecionar}
                  onChange={e => setNossoNumeroSelecionar(e.target.value)}
                />
                <Button
                  icon="pi pi-search"
                  className="p-button-warning"
                  onClick={() => handleSelecionarNossoNumero()}
                />
              </div>
            </div>
            <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
              &nbsp;&nbsp;
              <button
                type="button"
                title="Log de Acesso do Retaguarda"
                className="btn btn-secondary"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleViewRetaguardaLogAcesso(e)}
              >
                <i className="pi pi-book"></i>
              </button>
              &nbsp;&nbsp;
              <button
                disabled={somenteLeitura}
                type="button"
                title="Quitar Registros"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleQuitacao(e)}
              >
                <i className="pi pi-wallet"></i>
              </button>
            </div>
            {!somenteLeitura ?
              (
                <div className="col-lg-3" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Total Selecionado
                    </Input155px>
                    <InputNumber
                      disabled
                      mode="currency"
                      inputId="currency-br"
                      currency="BRL"
                      locale="pt-BR"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      value={totalSelecionado}
                      onValueChange={e => setTotalSelecionado(e.target.value)}
                    />
                  </div>
                </div>
              ) : ('')}

          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50, 500, 1000]}
          >
            <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
            {fillColumns(columnsSetup)}
            <Column header="PDF" body={downloadPDFTemplate}></Column>
          </DataTable>
        </div>

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>

        <Dialog
          header="Quitação"
          visible={showQuitacaoModal}
          style={{ width: '98vw' }}
          onHide={() => handleFinalizarQuitacao()}
          footer={renderFooterQuitacaoModal()}
        >
          <div className="row align-items-center">
            <div className="col-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data
                </Input100px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={dataQuitacao}
                  onChange={(e) => setDataQuitacao(e.value)}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </Card>
    </>
  );
}
