/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { get, orderBy } from 'lodash';
import { addLocale } from 'primereact/api';
import { toast } from 'react-toastify';

import dateFormat from 'dateformat';

import * as actions from '../../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors } from '../../../../util/diversos';
import { fillColumns } from '../../../../components/table';
import { Input100px, Input155px, Input200px, InputCampoBusca155px } from '../../../../styles/inputGroup';

// eslint-disable-next-line import/no-cycle
import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Card from '../../../../components/card';
import Loading from '../../../loading';

import CadastroOrdemServicoAparelho from './cadastroOrdemServicoAparelho';

import PdfOsAparelho from '../../relatorio/pdfOsAparelho';

export default function ConsultaOrdemServicoAparelho({ somenteLeitura = false }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const idUsuario = get(store.getState().auth.user, 'id', 0);
  const oficinaOperador = get(store.getState().auth.user, 'operadorOficina', null);
  const email = get(store.getState().auth.user, 'email', 0);

  const [cnpjEmpresa, setCnpjEmpresa] = useState(get(store.getState().auth, 'cnpjEmpresa', null));
  const [listaEmpresas, setListaEmpresas] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [multiEmpresa, setMultiEmpresa] = useState(true);

  const [permitirFechar, setPermitirFechar] = useState(true);
  const [permitirReabrir, setPermitirReabrir] = useState(true);
  const [permitirCancelar, setPermitirCancelar] = useState(true);
  const [permitirImprimir, setPermitirImprimir] = useState(true);
  const [permitirCadastrar, setPermitirCadastrar] = useState(true);
  const [permitirEditar, setPermitirEditar] = useState(true);
  const [permitirExcluir, setPermitirExcluir] = useState(true);
  const [permitirEditarOsOutraOficina, setPermitirEditarOsOutraOficina] = useState(true);

  const [listaOficina, setListaOficina] = useState([]);

  const [findDataInicial, setFindDataInicial] = useState(new Date('01-01-2000'));
  const [findDataFinal, setFindDataFinal] = useState(new Date());
  const [situacao, setSituacao] = useState('ABERTA');
  const [tipo, setTipo] = useState('SERVICO');
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataTableList, setDataTableList] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findField, setFindField] = useState('');
  const [findOficina, setFindOficina] = useState('TODAS');
  // eslint-disable-next-line no-unused-vars
  const [sortField, setSortField] = useState('nomeCliente');
  const [sortOrder, setSortOrder] = useState(null);

  const [showCadastro, setShowCadastro] = useState(false);
  const [payloadCadastro, setPayloadCadastro] = useState({});

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showFecharConfirmDialog, setShowFecharConfirmDialog] = useState(false);
  const [showReabrirConfirmDialog, setShowReabrirConfirmDialog] = useState(false);
  const [showCancelarConfirmDialog, setShowCancelarConfirmDialog] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [payloadPrint, setPayloadPrint] = useState({});

  const [columnsSetup] = useState(
    [
      { field: 'oficinaOperador.nome', header: 'Local', sort: true },
      { field: 'numero', header: 'Número', sort: true },
      { field: 'nomeCliente', header: 'Cliente', sort: true },
      { field: 'telefoneCliente', header: 'Telefone', sort: true },
      { field: 'data', header: 'Data', sort: true },
      { field: 'dataEntrega', header: 'Data Entrega', sort: true },
      { field: 'marca', header: 'Marca', sort: true },
      { field: 'modelo', header: 'Modelo', sort: true },
      { field: 'valorOrcamento', header: 'Valor Prévio', sort: false },
      { field: 'valorFinal', header: 'Valor Final', sort: false },
      { field: 'emailOperador', header: 'Operador', sort: false },
      // { field: 'tecnico.nome', header: 'Técnico', sort: false },
      { field: 'tipo', header: 'Tipo', sort: false },
      { field: 'situacao', header: 'Situação', sort: false },
    ],
  );

  const [columnsSetupMultiEmpresa] = useState(
    [
      { field: 'empresa.razaoSocial', header: 'Empresa', sort: true },
      { field: 'numero', header: 'Número', sort: true },
      { field: 'nomeCliente', header: 'Cliente', sort: true },
      { field: 'telefoneCliente', header: 'Telefone', sort: true },
      { field: 'data', header: 'Data', sort: true },
      { field: 'dataEntrega', header: 'Data Entrega', sort: true },
      { field: 'marca', header: 'Marca', sort: true },
      { field: 'modelo', header: 'Modelo', sort: true },
      { field: 'valorOrcamento', header: 'Valor Prévio', sort: false },
      { field: 'valorFinal', header: 'Valor Final', sort: false },
      { field: 'emailOperador', header: 'Operador', sort: false },
      /// { field: 'tecnico.nome', header: 'Técnico', sort: false },
      { field: 'tipo', header: 'Tipo', sort: false },
      { field: 'situacao', header: 'Situação', sort: false },
    ],
  );

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const [listaSituacao] = useState([
    { label: 'Em aberto', value: 'ABERTA' },
    { label: 'Fechada', value: 'FECHADA' },
    { label: 'Cancelada', value: 'CANCELADA' },
    { label: 'Todas', value: 'TODAS' },
  ]);

  const [listaTipo] = useState([
    { label: 'Serviço', value: 'SERVICO' },
    { label: 'Garantia', value: 'GARANTIA' },
    { label: 'Orçamento', value: 'ORCAMENTO' },
    { label: 'Todas', value: 'TODAS' },
  ]);

  let mostrarMensagemErro = true;

  // eslint-disable-next-line no-shadow
  async function getData(searchFilter, cnpjEmpresa, page = 0, size = 10) {
    try {
      setIsLoading(true);

      let params = `/osaparelho/search?idUsuario=${idUsuario}`;

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = `${dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy')} 00:00:00`;
        const _dataFinal = `${dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy')} 23:59:59`;

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      if (situacao !== 'TODAS') {
        params = `${params}&situacao=${situacao}`;
      }

      if (tipo !== 'TODAS') {
        params = `${params}&tipo=${tipo}`;
      }

      if (cnpjEmpresa !== null) {
        params = `${params}&cnpjEmpresa=${cnpjEmpresa}`;
      }

      if (searchFilter && searchFilter.findField !== '' && searchFilter.findField !== undefined) {
        params = `${params}&${sortField}=${searchFilter.findField}`;
      }

      if (searchFilter && searchFilter.findOficina !== 'TODAS') {
        params = `${params}&idOficina=${searchFilter.findOficina}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      // console.log('params: ', params);

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      if (mostrarMensagemErro) {
        displayErrors(err, dispatch, navigate, '/', '');
      }
      mostrarMensagemErro = false;
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    async function getOficina() {
      try {
        setIsLoading(true);

        const listDropdown = [{ label: 'Todas', value: 'TODAS' }];
        const { data: _oficina } = await axiosAdmin.get(`/oficina?idUsuario=${idUsuario}`);

        _oficina.forEach(element => {
          listDropdown.push({ label: `${element.nome}`, value: element.id });
        });

        setListaOficina(listDropdown);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getOficina();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const operadorGrupoAcesso = get(store.getState().auth.user, 'operadorGrupoAcesso', null);

    if (operadorGrupoAcesso != null) {
      let listaGrupoAcessoBody = [];
      listaGrupoAcessoBody = operadorGrupoAcesso.listaGrupoAcessoBody;
      const permissaoFechar = listaGrupoAcessoBody.filter(el => el.modulo === 'FecharOrdemServicoAparelho');
      const permissaoReabrir = listaGrupoAcessoBody.filter(el => el.modulo === 'ReabrirOrdemServicoAparelho');
      const permissaoCancelar = listaGrupoAcessoBody.filter(el => el.modulo === 'CancelarOrdemServicoAparelho');
      const permissaoImprimir = listaGrupoAcessoBody.filter(el => el.modulo === 'ImprimirOrdemServicoAparelho');
      const permissaoCadastrar = listaGrupoAcessoBody.filter(el => el.modulo === 'CadastrarOrdemServicoAparelho');
      const permissaoEditar = listaGrupoAcessoBody.filter(el => el.modulo === 'EditarOrdemServicoAparelho');
      const permissaoExcluir = listaGrupoAcessoBody.filter(el => el.modulo === 'ExcluirOrdemServicoAparelho');
      const permissaoEditarOsOutraOficina = listaGrupoAcessoBody.filter(el => el.modulo === 'PermitirEditarOrdemServicoAparelhoOutraOficina');

      if (permissaoFechar.length <= 0) {
        setPermitirFechar(false);
      }

      if (permissaoReabrir.length <= 0) {
        setPermitirReabrir(false);
      }

      if (permissaoCancelar.length <= 0) {
        setPermitirCancelar(false);
      }

      if (permissaoCadastrar.length <= 0) {
        setPermitirCadastrar(false);
      }

      if (permissaoEditar.length <= 0) {
        setPermitirEditar(false);
      }

      if (permissaoExcluir.length <= 0) {
        setPermitirExcluir(false);
      }

      if (permissaoImprimir.length <= 0) {
        setPermitirImprimir(false);
      }

      if (permissaoEditarOsOutraOficina.length <= 0) {
        setPermitirEditarOsOutraOficina(false);
      }
    }
  }, []);

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal, findField, findOficina };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  };

  useEffect(() => {
    async function verificarSeMultiEmpresa() {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = null;

        if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
          setCnpjEmpresa(_cnpjEmpresaStore);
          cnpjPreenchido = true;
        }

        _listaEmpresas.forEach(element => {
          if (element.ativo) {
            if (!cnpjPreenchido) {
              setCnpjEmpresa(element.cnpj);
              _cnpjEmpresaStore = element.cnpj;
              cnpjPreenchido = true;
            } else {
              setCnpjEmpresa(null);
            }

            const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

            listDropdown.push({ label: nomeEmpresa, value: element.cnpj, id: element.id });
          }
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (listDropdown.length > 1) {
          setMultiEmpresa(true);
        } else {
          setMultiEmpresa(false);
        }
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    handleFind();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSort = (event) => {
    if (event.sortField === 'nomeCliente' || event.sortField === 'numero') {
      setSortField(event.sortField);
    }
    setSortOrder(event.sortOrder);

    setLazyParams(event);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  function handleCnpjEmpresa(e) {
    setCnpjEmpresa(e.target.value);

    dispatch(actions.cnpjEmpresaChange({ cnpjEmpresa: e.target.value }));

    setDataTableList([]);

    const searchFilter = { findDataInicial, findDataFinal, findField, findOficina };

    getData(searchFilter, e.target.value, 0, lazyParams.rows);
  }

  function selecaoEmpresa() {
    if (listaEmpresas.length > 1) {
      return (
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Empresa
              </Input100px>
              <Dropdown
                options={listaEmpresas}
                value={cnpjEmpresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => handleCnpjEmpresa(e)}
              />
            </div>
          </div>
        </div>
      );
    }
    return '';
  }

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findDataInicial, findDataFinal, findField, findOficina };

    getData(searchFilter, cnpjEmpresa, event.page, event.rows);
  };

  // eslint-disable-next-line consistent-return
  const handleRegister = () => {
    if (!permitirCadastrar) {
      toast.info('Usuário não autorizado a cadastrar.');
      return false;
    }

    if (cnpjEmpresa === null) {
      toast.info('Selecione uma empresa.');
      return false;
    }

    const id = 0;

    setPayloadCadastro({ id, cnpjEmpresa });

    setShowCadastro(true);
  };

  // eslint-disable-next-line consistent-return
  const handleEdit = (e) => {
    e.preventDefault();

    if (!permitirEditar) {
      toast.info('Usuário não autorizado a editar.');
      return false;
    }

    if (oficinaOperador) {
      if (oficinaOperador.id !== selectedItens.oficinaOperador.id) {
        if (!permitirEditarOsOutraOficina) {
          toast.info('Usuário não autorizado a editar ordem de outra oficina.');
          return false;
        }
      }
    }

    if (selectedItens.situacao === 'CANCELADA' || selectedItens.situacao === 'FECHADA') {
      toast.info('Ordem de serviço fechada/cancelada não pode ser editada.');
      return false;
    }

    if (cnpjEmpresa === null) {
      toast.info('Selecione uma empresa.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);
    const cnpj = get(selectedItens.empresa, 'cnpj', 0);

    const status = get(selectedItens, 'situacao', 0);

    if (status === 'FECHADA') {
      toast.info('Ordem de Serviço fechada não pode ser editada.');
      return false;
    }

    if (id > 0) {
      setPayloadCadastro({ id, cnpj });
      setShowCadastro(true);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleFechar = (e) => {
    e.preventDefault();

    if (!permitirFechar) {
      toast.info('Usuário não autorizado a fechar ordem de serviço.');
      return false;
    }

    if (cnpjEmpresa === null) {
      toast.info('Selecione uma empresa.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);
    const status = get(selectedItens, 'situacao', 0);

    if (status === 'FECHADA') {
      toast.info('Ordem de Serviço já está fechada.');
      return false;
    }

    if (id > 0) {
      setShowFecharConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para fechamento');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleReabrir = (e) => {
    e.preventDefault();

    if (!permitirReabrir) {
      toast.info('Usuário não autorizado a reabrir ordem de serviço.');
      return false;
    }

    if (cnpjEmpresa === null) {
      toast.info('Selecione uma empresa.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);
    const status = get(selectedItens, 'situacao', 0);

    if (status === 'ABERTA') {
      toast.info('Ordem de Serviço já está aberta.');
      return false;
    }

    if (id > 0) {
      setShowReabrirConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para abertura');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleCancelar = (e) => {
    e.preventDefault();

    if (!permitirCancelar) {
      toast.info('Usuário não autorizado a cancelar ordem de serviço.');
      return false;
    }

    if (selectedItens.listaOsAparelhoItem.length > 0) {
      toast.info('Não é permitido cancelar ordem de serviço com produtos.');
      return false;
    }

    if (cnpjEmpresa === null) {
      toast.info('Selecione uma empresa.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);
    const status = get(selectedItens, 'situacao', 0);

    if (status === 'CANCELADA') {
      toast.info('Ordem de Serviço já está cancelada.');
      return false;
    }

    if (id > 0) {
      setShowCancelarConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para cancelamento');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleDelete = (e) => {
    e.preventDefault();

    if (!permitirExcluir) {
      toast.info('Usuário não autorizado a excluir.');
      return false;
    }

    if (cnpjEmpresa === null) {
      toast.info('Selecione uma empresa.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const childToParentConsulta = (payloadDataTableList, id) => {
    setSelectedItens(null);
    if (payloadDataTableList) {
      if (id > 0) {
        const index = dataTableList.map(e => e.id).indexOf(id);

        if (index >= 0) {
          dataTableList[index] = payloadDataTableList.data;
        }
      } else {
        const _dataTableList = [];
        _dataTableList.push(payloadDataTableList.data);

        setDataTableList(_dataTableList);

        const _lazyParams = { ...lazyParams, first: 0, page: 0 };
        setLazyParams(_lazyParams);
        setTotalRecords(1);
      }
    }

    setShowCadastro(false);
  };

  const confirmDelete = async () => {
    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/osaparelho/${idExclusao}`);

        const searchFilter = { findDataInicial, findDataFinal, findOficina };

        getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);

        setSelectedItens(null);

        setShowConfirmDialog(false);
        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  const confirmFechar = async () => {
    try {
      setIsLoading(true);

      const idFechar = get(selectedItens, 'id', 0);

      if (idFechar > 0) {
        await axiosAdmin.put(`/osaparelho/fechar/${idFechar}`);

        const searchFilter = { findDataInicial, findDataFinal, findOficina };

        getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);

        setSelectedItens(null);

        setShowFecharConfirmDialog(false);
        toast.success('Fechamento da ordem de serviço concluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível fechar a ordem de serviço');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmReabrir = async () => {
    try {
      setIsLoading(true);

      const idReabrir = get(selectedItens, 'id', 0);

      if (idReabrir > 0) {
        await axiosAdmin.put(`/osaparelho/reabrir/${idReabrir}`);

        const searchFilter = { findDataInicial, findDataFinal, findOficina };

        getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);

        setSelectedItens(null);

        setShowReabrirConfirmDialog(false);
        toast.success('Reabertura da ordem de serviço concluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível reabrir a ordem de serviço');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmCancelar = async () => {
    try {
      setIsLoading(true);

      const idCancelar = get(selectedItens, 'id', 0);

      if (idCancelar > 0) {
        await axiosAdmin.put(`/osaparelho/cancelar/${idCancelar}`);

        const searchFilter = { findDataInicial, findDataFinal, findOficina };

        getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);

        setSelectedItens(null);

        setShowCancelarConfirmDialog(false);
        toast.success('Cancelamento da ordem de serviço concluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível cancelar a ordem de serviço');
    } finally {
      setIsLoading(false);
    }
  };

  const cancelReabrir = () => {
    setShowReabrirConfirmDialog(false);
  };

  const cancelFechar = () => {
    setShowFecharConfirmDialog(false);
  };

  const cancelCancelar = () => {
    setShowCancelarConfirmDialog(false);
  };

  const confirmFecharDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmFechar} />
      <Button label="Não" icon="pi pi-times" onClick={cancelFechar} />
    </div>
  );

  const confirmReabrirDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmReabrir} />
      <Button label="Não" icon="pi pi-times" onClick={cancelReabrir} />
    </div>
  );

  const confirmCancelarDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmCancelar} />
      <Button label="Não" icon="pi pi-times" onClick={cancelCancelar} />
    </div>
  );

  const finishPrintOsAparelho = () => {
    setShowPrint(false);
  };

  const onHidePrint = () => {
    setShowPrint(false);
  };

  // eslint-disable-next-line consistent-return
  const handlePrint = (e) => {
    e.preventDefault();

    if (!permitirImprimir) {
      toast.info('Usuário não autorizado a imprimir.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowPrint(true);
      setPayloadPrint({ id });
        <PdfOsAparelho payloadPrint={payloadPrint} finishPrintOsAparelho={finishPrintOsAparelho} />;
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroOrdemServicoAparelho
          payloadCadastro={payloadCadastro}
          childToParentConsulta={childToParentConsulta}
        />
      </Dialog>

      <Dialog
        header=""
        visible={showPrint}
        style={{ width: '98vw' }}
        onHide={() => onHidePrint()}
        className="dialog-cadastro"
      >
        <PdfOsAparelho payloadPrint={payloadPrint} finishPrintOsAparelho={finishPrintOsAparelho} />
      </Dialog>

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>Ordem de Serviço - {email}</h6>
          </div>
          {selecaoEmpresa()}

          <div className="row form-elo">
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Data inicial
                </Input155px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => setFindDataInicial(e.value)}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Data final
                </Input155px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => {
                    setFindDataFinal(e.value);
                    const consultaEstampariaPedidoDataInicial = findDataInicial;
                    const consultaEstampariaPedidoDataFinal = e.value;
                    dispatch(actions.consultaEstampariaPedidoDataInicialChange({ consultaEstampariaPedidoDataInicial, consultaEstampariaPedidoDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Situação
                </Input155px>
                <Dropdown
                  options={listaSituacao}
                  value={situacao}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => setSituacao(e.target.value)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Tipo
                </Input155px>
                <Dropdown
                  options={listaTipo}
                  value={tipo}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => setTipo(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon" title="Para operador de ordem de serviço">
                  Oficina
                </Input200px>
                <Dropdown
                  options={listaOficina}
                  value={findOficina}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={e => setFindOficina(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Buscar por:
                </Input100px>
                <InputCampoBusca155px className="p-inputgroup-addon">
                  {columnsSetup.find(element => element.field === sortField).header}
                </InputCampoBusca155px>
                <InputText
                  maxLength={250}
                  value={findField}
                  onChange={e => setFindField(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Fechar"
                className="btn btn-info"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleFechar(e)}
              >
                <i className="pi pi-lock"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Reabrir"
                className="btn btn-info"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleReabrir(e)}
              >
                <i className="pi pi-lock-open"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Cancelar"
                className="btn btn-info"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleCancelar(e)}
              >
                <i className="pi pi-ban"></i>
              </button>
              <button
                type="button"
                title="Imprimir"
                className="btn btn-dark"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handlePrint(e)}
              >
                <i className="pi pi-print"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            id="produtos"
            value={dataTableList}
            responsiveLayout="scroll"
            dataKey="id"
            size="small"
            stripedRows
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            loading={isLoading}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {listaEmpresas.length > 1 ? fillColumns(columnsSetupMultiEmpresa) : fillColumns(columnsSetup)}
          </DataTable>
        </div>

      </Card>

      <Dialog
        header="Confirmação"
        visible={showConfirmDialog}
        style={{ width: '50vw' }}
        footer={confirmDialogFooter}
        onHide={() => setShowConfirmDialog(false)}
      >
        <p>
          Excluir o registro?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showFecharConfirmDialog}
        style={{ width: '50vw' }}
        footer={confirmFecharDialogFooter}
        onHide={() => setShowFecharConfirmDialog(false)}
      >
        <p>
          Fechar ordem de serviço?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showReabrirConfirmDialog}
        style={{ width: '50vw' }}
        footer={confirmReabrirDialogFooter}
        onHide={() => setShowReabrirConfirmDialog(false)}
      >
        <p>
          Reabrir Ordem de Serviço?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showCancelarConfirmDialog}
        style={{ width: '50vw' }}
        footer={confirmCancelarDialogFooter}
        onHide={() => setShowCancelarConfirmDialog(false)}
      >
        <p>
          Cancelar Ordem de Serviço?
        </p>
      </Dialog>
    </>
  );
}
