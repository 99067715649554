export function obterListaCteModeloNfOutros() {
  return (
    [
      { label: '00 - Declaração', value: 'DECLARACAO' },
      { label: '10 - Dutoviário', value: 'DUTOVIARIO' },
      { label: '59 - CF-e SAT', value: 'CFESAT' },
      { label: '65 - NFC-e', value: 'NFCE' },
      { label: '99 - Outros', value: 'OUTROS'},
    ]
  );
}
