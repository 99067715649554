/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { get, orderBy, isEmpty } from 'lodash';

import * as actions from '../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';
import { Input100px, InputCampoBusca155px } from '../../../styles/inputGroup';

// eslint-disable-next-line import/no-cycle
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';

export default function ConsultaPontoVenda({ empresaCnpj }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [totalRecords, setTotalRecords] = useState(0);
  const [dataTableList, setDataTableList] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findField, setFindField] = useState('');
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const [multiEmpresa, setMultiEmpresa] = useState(false);
  const [cadastroSincronizado, setCadastroSincronizado] = useState(true);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');

  const [columnsSetup, setColumnsSetup] = useState([
    { field: 'registro', header: 'Registro', sort: true },
    { field: 'descricao', header: 'Descrição', sort: true },
    { field: 'unidadeMedida.sigla', header: 'Unid', sort: true },
    { field: 'listaEstoques.qtdeDeposito', header: 'Depósito', sort: false },
    { field: 'listaEstoques.qtdePrateleira', header: 'Prateleira', sort: false },
    { field: 'precoVenda', header: 'Preço Venda', sort: false },
  ]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  let mostrarMensagemErro = true;

  async function getData(searchFilter, filtrarCnpjEmpresa, page = 0, size = 10) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/produto/search?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (searchFilter && searchFilter.findField !== '') {
        params = `${params}&${sortField}=${searchFilter.findField}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      if (mostrarMensagemErro) {
        displayErrors(err, dispatch, navigate, '/', '');
      }
      mostrarMensagemErro = false;
    } finally {
      setIsLoading(false);
    }
  }

  async function verificarSeMultiEmpresa(idUsuario) {
    try {
      setIsLoading(true);

      const params = `/usuario/${idUsuario}`;

      const response = await axiosAdmin.get(params);

      const _listaEmpresas = get(response.data, 'listaEmpresas', []);

      const listDropdown = [];

      let cnpjPreenchido = false;
      let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

      if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
        setCnpjEmpresa(_cnpjEmpresaStore);
        cnpjPreenchido = true;
      }

      const _cadastroSincronizado = get(response.data, 'cadastroSincronizado', true);

      setCadastroSincronizado(_cadastroSincronizado);

      if (_listaEmpresas.length > 1 && !_cadastroSincronizado) {
        listDropdown.push({ label: 'Todas', value: '' });

        setCnpjEmpresa('');

        cnpjPreenchido = true;

        setColumnsSetup([
          { field: 'empresaNomeAbreviado', header: 'Empresa', sort: false },
          { field: 'registro', header: 'Registro', sort: true },
          { field: 'descricao', header: 'Descrição', sort: true },
          { field: 'unidadeMedida.sigla', header: 'Unid', sort: true },
          { field: 'listaEstoques.qtdeDeposito', header: 'Depósito', sort: false },
          { field: 'listaEstoques.qtdePrateleira', header: 'Prateleira', sort: false },
          { field: 'precoVenda', header: 'Preço Venda', sort: false },
        ]);
      }

      _listaEmpresas.forEach(element => {
        if (!cnpjPreenchido) {
          setCnpjEmpresa(element.cnpj);
          _cnpjEmpresaStore = element.cnpj;
          cnpjPreenchido = true;
        }

        const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

        listDropdown.push({ label: nomeEmpresa, value: element.cnpj });
      });

      // empresaCnpj recebido pelo childtoparent na criacao/edicao de nfe
      if (!isEmpty(empresaCnpj)) {
        _cnpjEmpresaStore = empresaCnpj;
        setCnpjEmpresa(empresaCnpj);
      }

      listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

      setListaEmpresas(listDropdown);

      if (_listaEmpresas.length > 1 && _cadastroSincronizado) {
        setMultiEmpresa(true);
        setColumnsSetup([
          { field: 'registro', header: 'Registro', sort: true },
          { field: 'codigoEan', header: 'Código EAN', sort: true },
          { field: 'descricao', header: 'Descrição', sort: true },
          { field: 'unidadeMedida.sigla', header: 'Unid', sort: false },
          { field: 'precoVenda', header: 'Preço Venda', sort: false },
        ]);
      }

      getData('', _cnpjEmpresaStore, 0, lazyParams.rows);
    } catch (err) {
      if (mostrarMensagemErro) {
        displayErrors(err, dispatch, navigate, '/', '');
      }
      mostrarMensagemErro = false;
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleFind = () => {
    const searchFilter = {
      findField,
    };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  };

  const handleNovaVenda = () => {
    navigate('/cadastronovavenda');
  };

  const onSort = (event) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);

    setLazyParams(event);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const rowExpansionTemplate = (data) => (
    <div>
      <DataTable value={data.listaEstoques} responsiveLayout="scroll">
        <Column field="nomeEmpresa" header="Empresa"></Column>
        <Column field="qtdeDeposito" header="Qtde Depósito"></Column>
        <Column field="qtdePrateleira" header="Qtde Prateleira"></Column>
      </DataTable>
    </div>
  );

  function fillExpander() {
    const _return = multiEmpresa ? <Column expander style={{ width: '3em' }} /> : null;

    return _return;
  }

  function handleCnpjEmpresa(e) {
    setCnpjEmpresa(e.target.value);

    dispatch(actions.cnpjEmpresaChange({ cnpjEmpresa: e.target.value }));

    setDataTableList([]);

    getData('', e.target.value, 0, lazyParams.rows);
  }

  function selecaoEmpresa() {
    if (!cadastroSincronizado && listaEmpresas.length > 1) {
      return (
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Empresa
              </Input100px>
              <Dropdown
                options={listaEmpresas}
                value={cnpjEmpresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => handleCnpjEmpresa(e)}
              />
            </div>
          </div>
        </div>
      );
    }
    return '';
  }

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = {
      findField,
    };

    getData(searchFilter, cnpjEmpresa, event.page, event.rows);
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>Ponto de Venda</h6>
          </div>
          {selecaoEmpresa()}
          <div className="row align-items-center">
            <div className="col-lg-10">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Buscar por:
                </Input100px>
                <InputCampoBusca155px className="p-inputgroup-addon">
                  {columnsSetup.find(element => element.field === sortField).header}
                </InputCampoBusca155px>
                <InputText
                  maxLength={250}
                  value={findField}
                  onChange={e => setFindField(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-2">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                type="button"
                title="Nova Venda"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleNovaVenda}
              >
                <i className="pi pi-shopping-cart"></i>
              </button>
            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            id="produtos"
            value={dataTableList}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            responsiveLayout="scroll"
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="id"
            size="small"
            stripedRows
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            loading={isLoading}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillExpander()}
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>

      </Card>
    </>
  );
}
