export function obterListaTipoManifestacaoEvento() {
  return (
    [
      { label: 'Ciência da Operação', value: 'CIENCIAOPERACAO' },
      { label: 'Confirmação da Operação', value: 'CONFIRMACAOOPERACAO' },
      { label: 'Operação Não Realizada', value: 'OPERACAONAOREALIZADA' },
      { label: 'Desconhecimento da Operação', value: 'DESCONHECIMENTOOPERACAO' },
    ]
  );
}
