import { toast } from 'react-toastify';
import { get } from 'lodash';
import moment from 'moment';

import * as actions from '../store/modules/auth/actions';

export function soNumeros(string) {
  if (!string) return '';

  const _str = string.replace(/[^0-9]/g, '');
  return _str.trim();
}

export function formataTelefone(string) {
  let _str = soNumeros(string);

  if (_str.length === 8) {
    _str = `${_str.slice(0, 4)}-${_str.slice(4, 8)}`;
  } else if (_str.length === 9) {
    _str = `${_str.slice(0, 5)}-${_str.slice(5, 9)}`;
  } else if (_str.length === 10) {
    _str = `(${_str.slice(0, 2)})${_str.slice(2, 6)}-${_str.slice(6, 10)}`;
  } else if (_str.length === 11) {
    _str = `(${_str.slice(0, 2)})${_str.slice(2, 7)}-${_str.slice(7, 11)}`;
  } else if (_str.length === 12) {
    _str = `(${_str.slice(0, 3)})${_str.slice(3, 8)}-${_str.slice(8, 12)}`;
  }

  return _str;
}

export function tratarNumero(numero) {
  if (numero && numero > 0) {
    return numero;
  } else {
    return 0;
  }
}

export function findInList(lista, item, campoBusca) {
  try {
    const _objetoEncontrado = lista.find(element => element.value === item);

    let valorRetorno = 0;

    if (_objetoEncontrado) {
      Object.keys(_objetoEncontrado).forEach((element) => {
        if (element === campoBusca) {
          valorRetorno = _objetoEncontrado[element];
        }
      });
    }

    return valorRetorno;
  } catch (error) {
    return 0;
  }
}

export function formataCnpjCpf(string) {
  let _str = soNumeros(string);

  if (_str.length === 11) {
    _str = `${_str.slice(0, 3)}.${_str.slice(3, 6)}.${_str.slice(6, 9)}-${_str.slice(9)}`;
  } else if (_str.length === 14) {
    _str = `${_str.slice(0, 2)}.${_str.slice(2, 5)}.${_str.slice(5, 8)}/${_str.slice(8, 12)}-${_str.slice(12)}`;
  }

  return _str;
}

export function converteData(data, incluirHora = false) {
  if (data) {
    const ano = data[0];
    const mes = data[1] - 1;
    const dia = data[2];

    if (incluirHora) {
      const hora = data[3];
      const minuto = data[4];
      if (!data[5]) {
        return new Date(ano, mes, dia, hora, minuto);
      } else {
        // eslint-disable-next-line prefer-destructuring
        const segundo = data[5];
        return new Date(ano, mes, dia, hora, minuto, segundo);
      }
    } else {
      return new Date(ano, mes, dia);
    }
  } else {
    return null;
  }
}

export function dateToArray(data) {
  const arrayData = [];

  arrayData.push(moment(data).year());
  arrayData.push(moment(data).month() + 1);
  arrayData.push(moment(data).date());

  return arrayData;
}

export function dateTimeToArray(data) {
  const arrayData = [];

  arrayData.push(moment(data).year());
  arrayData.push(moment(data).month() + 1);
  arrayData.push(moment(data).date());
  arrayData.push(moment(data).hours());
  arrayData.push(moment(data).minutes());
  arrayData.push(moment(data).seconds());

  return arrayData;
}

export function formataDataArray(data, caracterSeparador = '/', hora = false) {
  let _str = '';

  if (data === null) {
    return _str;
  }

  try {
    if (hora) {
      if (!data[5]) {
        _str = `${('00' + data[2]).slice(-2)}${caracterSeparador}${('00' + data[1]).slice(-2)}${caracterSeparador}${('0000' + data[0]).slice(-4)} ${('00' + data[3]).slice(-2)}:${('00' + data[4]).slice(-2)}:00`;
      } else {
        _str = `${('00' + data[2]).slice(-2)}${caracterSeparador}${('00' + data[1]).slice(-2)}${caracterSeparador}${('0000' + data[0]).slice(-4)} ${('00' + data[3]).slice(-2)}:${('00' + data[4]).slice(-2)}:${('00' + data[5]).slice(-2)}`;
      }
    } else {
      _str = `${('00' + data[2]).slice(-2)}${caracterSeparador}${('00' + data[1]).slice(-2)}${caracterSeparador}${('0000' + data[0]).slice(-4)}`;
    }
  } catch (err) {
    _str = '';
  }

  return _str;
}

export function formataDataMoment(data, separadorData = '/', incluirHorario = false) {
  let _str = '';

  if (data === null) {
    return _str;
  }

  const dia = moment(data).date();
  const mes = moment(data).month() + 1;
  const ano = moment(data).year();

  const espacoEmBranco = ' ';
  const separadorHora = ':';

  try {
    if (incluirHorario) {
      const hora = moment(data).hours();
      const minuto = moment(data).minutes();
      const segundo = moment(data).seconds();

      _str = `${('00' + dia).slice(-2)}${separadorData}${('00' + mes).slice(-2)}${separadorData}${('0000' + ano).slice(-4)}${espacoEmBranco}`
           + `${('00' + hora).slice(-2)}${separadorHora}${('00' + minuto).slice(-2)}${separadorHora}${('00' + segundo).slice(-2)}`;
    } else {
      _str = `${('00' + dia).slice(-2)}${separadorData}${('00' + mes).slice(-2)}${separadorData}${('0000' + ano).slice(-4)}`;
    }
  } catch (err) {
    _str = '';
  }

  return _str;
}

export function formataMoeda(value) {
  let _value = value;

  try {
    _value = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  } catch (err) {
    _value = 'R$ 0,00';
  }

  return _value;
}

export function displayErrors(response, dispatch, navigate, navTo, msg) {
  let _msg = msg;

  if (!_msg) {
    _msg = 'Não foi possível se conectar ao servidor';
  }

  if (get(response, 'response.status', 0) === 403) {
    dispatch(actions.loginFailure());
    navigate('/login');
    toast.warning('A sua sessão expirou! Por favor, conecte-se novamente.');
  } else {
    const errors = get(response, 'response.data.errors', []);
    if (errors.length > 0) {
      errors.map(error => toast.error(error));
    } else {
      toast.error(_msg);
      if (navTo) navigate(navTo);
    }
  }
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
}

export function arredondar(numero, casasDecimais) {
  let multiplicador = '1';

  let i = 0;
  let decimais = casasDecimais;
  if (!casasDecimais) {
    decimais = 2;
  }
  decimais = Math.abs(decimais);

  for (i = 0; i < decimais; i++) {
    multiplicador = multiplicador + '0';
  }

  return Math.round(numero * multiplicador) / multiplicador;
}
