import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input155px, Input200px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function ConfiguracaoShipay() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [id, setId] = useState(0);
  const [agenciaNome, setAgenciaNome] = useState();
  const [agenciaNumero, setAgenciaNumero] = useState();
  const [agenciaOperacao, setAgenciaOperacao] = useState();
  const [agenciaConta, setAgenciaConta] = useState();
  const [agenciaTitularConta, setAgenciaTitularConta] = useState();
  const [chavePix, setChavePix] = useState();

  const [formaPagamento, setFormaPagamento] = useState();
  const [endPoint, setEndPoint] = useState();
  const [accessKey, setAccessKey] = useState();
  const [clientId, setClientId] = useState();
  const [secretKey, setSecretKey] = useState();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const newTitle = 'Configuração Shipay';

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getData() {
      try {
        setIsLoading(true);

        const params = '/configuracaoshipay?id=1';

        const { data } = await axiosAdmin.get(params);

        if (data != null) {
          setId(data.id);
          setAgenciaNome(data.agenciaNome);
          setAgenciaNumero(data.agenciaNumero);
          setAgenciaConta(data.agenciaConta);
          setAgenciaOperacao(data.agenciaOperacao);
          setAgenciaTitularConta(data.agenciaTitularConta);
          setChavePix(data.chavePix);

          setFormaPagamento(data.formaPagamento);
          setEndPoint(data.endPoint);
          setClientId(data.clientId);
          setAccessKey(data.accessKey);
          setSecretKey(data.secretKey);
        }
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const shippingData = {
        idUsuario,
        agenciaNome,
        agenciaNumero,
        agenciaOperacao,
        agenciaConta,
        agenciaTitularConta,
        chavePix,
        formaPagamento,
        endPoint,
        clientId,
        accessKey,
        secretKey,
      };

      if (id > 0) {
        await axiosAdmin.put(`/configuracaoshipay/${id}`, shippingData);
        toast.success('Configuração Shipay atualizada com sucesso');
      } else {
        await axiosAdmin.post('/configuracaoshipay', shippingData);
        toast.success('Configuração Shipay salva com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (agenciaNome == null) {
      formErros = true;
      toast.error('Nome da Agência deve ser informado.');
    }
    if (agenciaNumero == null) {
      formErros = true;
      toast.error('Número da Agência deve ser informado.');
    }
    if (agenciaConta == null) {
      formErros = true;
      toast.error('Número da conta deve ser informado.');
    }
    if (agenciaTitularConta == null) {
      formErros = true;
      toast.error('Titular da conta deve ser informado.');
    }
    if (chavePix == null) {
      formErros = true;
      toast.error('Chave PIX deve ser informada.');
    }
    if (formaPagamento == null) {
      formErros = true;
      toast.error('Forma de pagamento deve ser informada.');
    }
    if (endPoint == null) {
      formErros = true;
      toast.error('End Point deve ser informado.');
    }
    if (accessKey == null) {
      formErros = true;
      toast.error('Access Key deve ser informada.');
    }
    if (clientId == null) {
      formErros = true;
      toast.error('Client id deve ser informada.');
    }
    if (secretKey == null) {
      formErros = true;
      toast.error('Secret key deve ser informada.');
    }

    if (formErros) return;

    salvarRegistro();
  };

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>Configuração Shipay</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-lg-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nome Agência
              </Input155px>
              <InputText
                minLength={1}
                maxLength={100}
                value={agenciaNome}
                onChange={e => setAgenciaNome(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Número Agência
              </Input155px>
              <InputText
                minLength={1}
                maxLength={10}
                value={agenciaNumero}
                onChange={e => setAgenciaNumero(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-lg-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Operação
              </Input155px>
              <InputText
                minLength={1}
                maxLength={5}
                value={agenciaOperacao}
                onChange={e => setAgenciaOperacao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Número Conta
              </Input155px>
              <InputText
                minLength={1}
                maxLength={15}
                value={agenciaConta}
                onChange={e => setAgenciaConta(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-lg-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Titular da Conta
              </Input155px>
              <InputText
                minLength={1}
                maxLength={100}
                value={agenciaTitularConta}
                onChange={e => setAgenciaTitularConta(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Chave PIX
              </Input200px>
              <InputText
                maxLength={100}
                value={chavePix}
                onChange={e => setChavePix(e.target.value)}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="row form-elo">
          <div className="col-lg-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Shipay Forma Pagamento
              </Input200px>
              <InputText
                minLength={1}
                maxLength={30}
                value={formaPagamento}
                onChange={e => setFormaPagamento(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Shipay EndPoint
              </Input200px>
              <InputText
                minLength={1}
                maxLength={200}
                value={endPoint}
                onChange={e => setEndPoint(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-lg-12">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Shipay Client ID
              </Input200px>
              <InputText
                minLength={1}
                maxLength={300}
                value={clientId}
                onChange={e => setClientId(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-lg-12">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Shipay Access Key
              </Input200px>
              <InputText
                minLength={1}
                maxLength={200}
                value={accessKey}
                onChange={e => setAccessKey(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-lg-12">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Shipay Secret Key
              </Input200px>
              <InputText
                minLength={1}
                maxLength={300}
                value={secretKey}
                onChange={e => setSecretKey(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
