/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { get } from 'lodash';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import dateFormat from 'dateformat';
import { formataMoeda, displayErrors } from '../../../util/diversos';

import 'react-toastify/dist/ReactToastify.css';

import * as actions from '../../../store/modules/auth/actions';

import { Input100px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';

export default function RelatorioEstampariaRecebimentoPedidoAberto() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  function headRows() {
    return [
      {
        id: 'Id',
        data: 'Data',
        nomePedido: 'Nome do Pedido',
        razaoSocial: 'Razão Social do cliente',
        valorPedido: 'Valor Pedido',
        valorPago: 'Valor Pago',
        valorRestante: 'Valor Restante',
      },
    ];
  }

  const [headerTabelaImpressao] = useState(headRows());

  const handleFind = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const doc = new jsPDF();
      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const usuario = await axiosAdmin.get(`/usuario/${idUsuario}`);

      let params = `/estampariapedido/searchemaberto?idUsuario=${idUsuario}`;

      const _dataInicial = `${dateFormat(findDataInicial, 'dd-mm-yyyy')} 00:00:00`;
      const _dataFinal = `${dateFormat(findDataFinal, 'dd-mm-yyyy')} 23:59:59`;

      params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      params = `${params}&page=0&size=99999`;

      const response = await axiosAdmin.get(params);

      if (response.data.content.length <= 0) {
        toast.info('Nenhum pedido em aberto.');
        return false;
      }

      const config = await axiosAdmin.get(`/configuracaosistema?idUsuario=${idUsuario}`);
      const img = new Image();
      img.src = `/logotipos/${config.data.urlLogotipo}`;
      await img.decode().then(() => {

      }).catch(() => {
        img.src = 'logo.png';
      });

      let data = {
        id: '',
        data: '',
        nomePedido: '',
        razaoSocial: '',
        valorPedido: '',
        valorPago: '',
        valorRestante: '',
      };

      const result = [];
      let somaTotalPedidos = 0;
      let somaValorPago = 0;
      let somaValorRestante = 0;
      const _lista = response.data.content;
      for (let x = 0; x < _lista.length; x++) {
        const pedido = _lista[x];
        data.id = pedido.id;
        data.data = `${('00' + pedido.data[2]).slice(-2)}/${('00' + pedido.data[1]).slice(-2)}/${pedido.data[0]}`;
        data.nomePedido = pedido.nomePedido;
        data.razaoSocial = '';
        if (pedido.cliente !== null) {
          data.razaoSocial = pedido.cliente.razaoSocial;
        }
        data.valorPedido = formataMoeda(pedido.valorTotal);
        data.valorPago = formataMoeda(pedido.valorPago);
        const valorRestante = parseFloat(pedido.valorTotal) - parseFloat(pedido.valorPago);
        somaValorRestante += valorRestante;
        data.valorRestante = formataMoeda(valorRestante);
        somaTotalPedidos += pedido.valorTotal;
        somaValorPago += pedido.valorPago;
        result.push({ ...data });
      }

      // adionar linha vazia
      data = {
        id: '',
        data: '',
        nomePedido: '',
        razaoSocial: '',
        valorPedido: '',
        valorPago: '',
        valorRestante: '',
      };
      result.push({ ...data });

      data.valorPedido = formataMoeda(somaTotalPedidos);
      data.valorPago = formataMoeda(somaValorPago);
      data.valorRestante = formataMoeda(somaValorRestante);
      result.push({ ...data });

      doc.autoTable({
        styles: { fontSize: 8 },
        margin: { top: 38, left: 5, right: 5 },
        columnStyles: {
          4: { cellWidth: 23 },
          5: { cellWidth: 23 },
          6: { cellWidth: 23 },
        },
        theme: 'grid',
        head: headerTabelaImpressao,
        body: result,
        didDrawPage: dados => {
          // Header
          doc.rect(5, 5, 200, 30);
          doc.addImage(img, 'JPEG', 9, 8, 25, 25);
          doc.setFontSize(20);
          doc.text('Pedidos em Aberto ', 50, 15);

          doc.setFontSize(8);
          usuario.data.listaEmpresas[0].nomeFantasia ? doc.text(usuario.data.listaEmpresas[0].nomeFantasia, 147, 13) : '';
          usuario.data.email ? doc.text(usuario.data.email, 147, 17) : '';
          if (usuario.data.listaEmpresas[0].endereco && usuario.data.listaEmpresas[0].enderecoNumero) {
            const endereco = `${usuario.data.listaEmpresas[0].endereco} - ${usuario.data.listaEmpresas[0].enderecoNumero}`;
            doc.text(endereco, 147, 21);
          }
          usuario.data.listaEmpresas[0].uf ? doc.text(usuario.data.listaEmpresas[0].uf, 147, 25) : '';
          usuario.data.listaEmpresas[0].cidade ? doc.text(usuario.data.listaEmpresas[0].cidade, 154, 25) : '';
          usuario.data.listaEmpresas[0].telefone_1 ? doc.text(usuario.data.listaEmpresas[0].telefone_1, 147, 29) : '';
          usuario.data.listaEmpresas[0].telefone_2 ? doc.text(usuario.data.listaEmpresas[0].telefone_2, 172, 29) : '';

          doc.setFontSize(10);
          doc.text('Período:', 50, 32);
          doc.text(dateFormat(findDataInicial, 'dd/mm/yyyy'), 65, 32);
          doc.text(' à ', 84, 32);
          doc.text(dateFormat(findDataFinal, 'dd/mm/yyyy'), 89, 32);

          // footer
          const { pageSize } = doc.internal;
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(`Pag ${doc.internal.getNumberOfPages()}`, dados.settings.margin.right, pageHeight - 8);
          const periodo = new Date();
          doc.text('Impresso: ', 170, pageHeight - 8);
          // eslint-disable-next-line prefer-template
          doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 187, pageHeight - 8);
        },
      });
      window.open(doc.output('bloburl'));
    } catch (err) {
      console.log(err);
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Loading isLoading={isLoading} />
      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              RELATÓRIODE RECEBIMENTO DE PEDIDO EM ABERTO
            </h6>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data inicial
                </Input100px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => {
                    setFindDataInicial(e.value);
                    const consultaEstampariaRecebimentoPedidoDataInicial = e.value;
                    const consultaEstampariaRecebimentoPedidoDataFinal = findDataFinal;
                    dispatch(actions.consultaEstampariaRecebimentoPedidoDataInicialChange({ consultaEstampariaRecebimentoPedidoDataInicial,
                      consultaEstampariaRecebimentoPedidoDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data final
                </Input100px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => {
                    setFindDataFinal(e.value);
                    const consultaEstampariaRecebimentoPedidoDataInicial = findDataInicial;
                    const consultaEstampariaRecebimentoPedidoDataFinal = e.value;
                    dispatch(actions.consultaEstampariaRecebimentoPedidoDataInicialChange({ consultaEstampariaRecebimentoPedidoDataInicial,
                      consultaEstampariaRecebimentoPedidoDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <button
                type="button"
                title="Imprimir"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleFind(e)}
              >
                <i className="pi pi-search"> </i>
              </button>
            </div>
          </div>
          <div className="row align-items-center" style={{ marginTop: '10px' }}>
          </div>
        </div>
        <br />
      </Card>
    </>
  );
}
