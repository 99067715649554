import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { get, orderBy } from 'lodash';
import dateFormat from 'dateformat';
import { toast } from 'react-toastify';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { Input100px } from '../../../styles/inputGroup';
import Card from '../../../components/card';
import Loading from '../../loading';

import { displayErrors } from '../../../util/diversos';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';

export default function RelatorioEstampariaOrdemProducao() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [isLoading, setIsLoading] = useState(false);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [findSituacaoOrdemPedido, setFindSituacaoOrdemPedido] = useState('TODOS');
  const [tipo, setTipo] = useState('SINTETICO');
  const [data, setData] = useState({});
  const [headerTabelaImpressao, setHeaderTabelaImpressao] = useState('');

  const [listaTipo] = useState([
    { label: 'Sintético', value: 'SINTETICO' },
    { label: 'Analítico', value: 'ANALITICO' },
  ]);

  const [listaSituacaoOrdemProducao] = useState([
    { label: 'Planejada', value: 'PLANEJADA' },
    { label: 'Firme', value: 'FIRME' },
    { label: 'Liberada', value: 'LIBERADA' },
    { label: 'Aberta', value: 'ABERTA' },
    { label: 'Iniciada', value: 'INICIADA' },
    { label: 'Parada', value: 'PARADA' },
    { label: 'Finalizada', value: 'FINALIZADA' },
    { label: 'Cancelada', value: 'CANCELADA' },
    { label: 'Todos', value: 'TODOS' },
  ]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const headRows = [
    {
      id: 'id',
      nomePedido: 'Pedido',
      itens: 'Item(s)',
      dataPedido: 'Data Pedido',
      dataInicioProducao: 'Início Prod.',
      dataTerminoProducao: 'Término Prod.',
      situacao: 'Situação',
      garantia: 'Garantia',
    },
  ];

  const headRowsAnalitico = [
    {
      id: 'id',
      nomePedido: 'Pedido',
      item: 'Item',
      quantidade: 'Qtde',
      descricao: 'Descrição',
      observacao: 'Obs',
    },
  ];

  const idUsuario = get(store.getState().auth.user, 'id', 0);

  // eslint-disable-next-line no-shadow
  async function getData(searchFilter, page, size, tipo, img) {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    try {
      setIsLoading(true);

      if (findDataInicial == null && findDataFinal == null) {
        toast.info('Datas devem ser informadas.');
        return;
      }

      if (findDataInicial > findDataFinal) {
        toast.info('Data final anterior a data inicial.');
        return;
      }

      let params = `/estampariaordemproducao?idUsuario=${idUsuario}`;

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = `${dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy')} 00:00:00`;
        const _dataFinal = `${dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy')} 23:59:59`;

        params = `${params}&dataPedidoInicial=${_dataInicial}&dataPedidoFinal=${_dataFinal}`;
      }

      if (searchFilter && searchFilter.findSituacaoOrdemPedido !== 'TODOS') {
        params = `${params}&situacao=${searchFilter.findSituacaoOrdemPedido}`;
      }

      // params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);
      if (response.data <= 0) {
        toast.info('Não há registros a serem exibidos.');
        return;
      }

      if (tipo === 'ANALITICO') {
        const dataAnalitico = {
          id: '0',
          nomePedido: '',
          descricao: '',
          quantidade: '',
          observacao: '',
        };
        setData(dataAnalitico);
      } else {
        const data = {
          id: '0',
          nomePedido: '',
          itens: '',
          dataPedido: '',
          dataInicioProducao: '',
          dataTerminoProducao: '',
          situacao: '',
          garantia: '',
        };
        setData(data);
      }

      const result = [];
      for (let x = 0; x < response.data.length; x++) {
        const ordem = response.data[x];

        if (tipo === 'ANALITICO') {
          const listaItens = orderBy(ordem.listaEstampariaOrdemProducaoItem, 'item', 'asc');
          let itens = '';
          for (let y = 0; y < listaItens.length; y++) {
            const item = listaItens[y];
            if (y === 0) {
              data.id = ordem.id.toString();
              data.nomePedido = ordem.nomePedido.toString();
            } else {
              data.id = '';
              data.nomePedido = '';
            }
            data.item = item.item.toString();
            data.descricao = item.produto.descricao.trim();
            data.quantidade = item.quantidade.toString();
            data.observacao = item.observacao;
            result.push({ ...data });
          }
        } else {
          const listaItens = orderBy(ordem.listaEstampariaOrdemProducaoItem, 'item', 'asc');
          let itens = '';
          for (let y = 0; y < listaItens.length; y++) {
            const item = listaItens[y];
            itens += `${item.item}, `;
          }
          data.itens = itens.slice(0, -2);
          data.id = ordem.id.toString();
          data.nomePedido = ordem.nomePedido.toString();
          if (ordem.dataPedido !== null) {
            data.dataPedido = `${('00' + ordem.dataPedido[2]).slice(-2)}/${('00' + ordem.dataPedido[1]).slice(-2)}/${ordem.dataPedido[0]}`;
          }
          if (ordem.dataInicioProducao !== null) {
            data.dataInicioProducao = `${('00' + ordem.dataInicioProducao[2]).slice(-2)}/${('00' + ordem.dataInicioProducao[1]).slice(-2)}/${ordem.dataInicioProducao[0]}`;
          }
          if (ordem.dataTerminoProducao !== null) {
            data.dataTerminoProducao = `${('00' + ordem.dataTerminoProducao[2]).slice(-2)}/${('00' + ordem.dataTerminoProducao[1]).slice(-2)}/${ordem.dataTerminoProducao[0]}`;
          }
          data.situacao = ordem.situacao;
          data.garantia = ordem.garantia ? 'Sim' : 'Não';
          result.push({ ...data });
        }
      }

      doc.autoTable({
        styles: { fontSize: 8 },
        margin: { top: 38, left: 5, right: 5 },
        theme: 'grid',
        head: headerTabelaImpressao,
        body: result,
        didDrawPage: dados => {
          // Header
          doc.rect(5, 5, 200, 30);
          doc.addImage(img, 'JPEG', 9, 8, 25, 25);
          doc.setFontSize(20);
          doc.text('Relatório de Ordens de Produção ', 55, 15);

          doc.setFontSize(8);
          doc.text('Tipo:', 50, 22);
          doc.text(tipo, 58, 22);

          doc.setFontSize(8);
          doc.text('Situação:', 50, 27);
          doc.text(findSituacaoOrdemPedido, 63, 27);

          doc.setFontSize(8);
          doc.text('Período:', 50, 32);
          doc.text(dateFormat(findDataInicial, 'dd/mm/yyyy'), 63, 32);
          doc.text(' à ', 79, 32);
          doc.text(dateFormat(findDataFinal, 'dd/mm/yyyy'), 83, 32);

          // footer
          const { pageSize } = doc.internal;
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(`Pag ${doc.internal.getNumberOfPages()}`, dados.settings.margin.right, pageHeight - 8);
          doc.text('Elo Informática Barbacena LTDA', 82, pageHeight - 8);
          const periodo = new Date();
          doc.text('Impresso: ', 172, pageHeight - 8);
          doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 189, pageHeight - 8);
        },
      });
      window.open(doc.output('bloburl'));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal, findSituacaoOrdemPedido };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    const loadImage = async () => {
      let config = '';
      try {
        config = await axiosAdmin.get(`/configuracaosistema?idUsuario=${idUsuario}`);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }

      const img = new Image();
      img.src = `/logotipos/${config.data.urlLogotipo}`;
      await img.decode().then(() => {

      }).catch(() => {
        img.src = 'logo.png';
      });
      getData(searchFilter, 0, lazyParams.rows, tipo, img);
    };
    loadImage();
  };

  useEffect(() => {
    setTipo(tipo);
    if (tipo === 'ANALITICO') {
      setHeaderTabelaImpressao(headRowsAnalitico);
    } else {
      setHeaderTabelaImpressao(headRows);
    }
  }, [tipo])

  return (
    <>
      <Loading isLoading={isLoading} />

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}> Relatório Estamparia Ordens Produção </h6>
        </div>

        <div className="row align-items-center">
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data inicial
              </Input100px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={findDataInicial}
                onChange={(e) => setFindDataInicial(e.value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data final
              </Input100px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={findDataFinal}
                onChange={(e) => setFindDataFinal(e.value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Situação
              </Input100px>
              <Dropdown
                options={listaSituacaoOrdemProducao}
                value={findSituacaoOrdemPedido}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setFindSituacaoOrdemPedido(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-2">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Tipo
              </Input100px>
              <Dropdown
                options={listaTipo}
                value={tipo}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-1">
            <button
              type="button"
              title="Buscar"
              className="btn btn-success"
              style={{ borderRadius: '15px' }}
              onClick={handleFind}
            >
              <i className="pi pi-search"></i>
            </button>
          </div>
        </div>
      </Card>
    </>
  );
}
