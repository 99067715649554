import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Password } from 'primereact/password';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { get, orderBy } from 'lodash';

import dateFormat from 'dateformat';

import 'react-toastify/dist/ReactToastify.css';

import * as actions from '../../../../store/modules/auth/actions';

import { displayErrors } from '../../../../util/diversos';
import { fillColumns } from '../../../../components/table';
import { Input100px, Input155px, Input200px } from '../../../../styles/inputGroup';

import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Card from '../../../../components/card';
import Loading from '../../../loading';
import CadastroEstampariaPedido from './cadastroEstampariaPedido';
import PdfEstampariaPedido from '../../relatorio/pdfEstampariaPedido';

import ConsultaParticipante from '../../participante/consultaParticipante';

export default function ConsultaEstampariaPedido({ menuMode, childToParent, somenteLeitura = false }) {
  const [isLoading, setIsLoading] = useState(false);
  const [showCadastro, setShowCadastro] = useState(false);
  const [showConsultaParticipante, setShowConsultaParticipante] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataTableList, setDataTableList] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showAlterarSituacao, setShowAlterarSituacao] = useState(false);
  const [filtroAtualizado, setFiltroAtualizado] = useState(null);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [findNomePedido, setFindNomePedido] = useState('');
  const [findSituacaoPedido, setFindSituacaoPedido] = useState('TODOS');
  const [findIdCliente, setFindIdCliente] = useState(null);
  const [findNomeCliente, setFindNomeCliente] = useState('');
  const [findIdPedido, setFindIdPedido] = useState(0);
  const [novaSituacaoPedido, setNovaSituacaoPedido] = useState('TODOS');
  const [senhaAdm, setSenhaAdm] = useState(null);
  const [senhaAdmInformada, setSenhaAdmInformada] = useState(null);
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [payloadCadastro, setPayloadCadastro] = useState({});
  const [payloadPrint, setPayloadPrint] = useState({});
  const [columnsSetup] = useState([
    { field: 'id', header: 'Número', sort: true },
    { field: 'data', header: 'Data', sort: true },
    { field: 'nomePedido', header: 'Nome do Pedido', sort: true },
    { field: 'cliente.razaoSocial', header: 'Razão Social do Cliente', sort: false },
    { field: 'valorTotal', header: 'Valor do Pedido', sort: false },
    { field: 'garantia', header: 'Garantia', sort: false, booleano: true },
    { field: 'situacao', header: 'Situação', sort: false },
    { field: 'vendedor.nome', header: 'Vendedor', sort: false },
  ]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });
  const [listaSituacaoPedido] = useState([
    { label: 'Aberto', value: 'ABERTO' },
    { label: 'Pendente', value: 'PENDENTE' },
    { label: 'Cancelado', value: 'CANCELADO' },
    { label: 'Fechado', value: 'FECHADO' },
    { label: 'Todos', value: 'TODOS' },
  ]);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getData(searchFilter, page, size) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/estampariapedido/search?idUsuario=${idUsuario}`;

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = `${dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy')} 00:00:00`;
        const _dataFinal = `${dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy')} 23:59:59`;

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      if (searchFilter && searchFilter.findNomePedido !== '') {
        params = `${params}&nomePedido=${findNomePedido}`;
      }

      if (searchFilter && searchFilter.findIdPedido > 0) {
        params = `${params}&idPedido=${findIdPedido}`;
      }

      if (searchFilter && searchFilter.findIdCliente > 0) {
        params = `${params}&idParticipante=${findIdCliente}`;
      }

      if (searchFilter && searchFilter.findSituacaoPedido !== 'TODOS') {
        params = `${params}&situacao=${searchFilter.findSituacaoPedido}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setFindNomePedido('');
      setFindIdCliente(0);
      setFindNomeCliente('');
      setFindIdPedido(0);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal, findNomePedido, findSituacaoPedido, findIdCliente, findIdPedido };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, 0, lazyParams.rows);
  };

  function renderBotaoSelect() {
    if (!menuMode) {
      return (
        <button
          type="button"
          title="Selecionar"
          className="btn btn-info"
          style={{ borderRadius: '15px' }}
          onClick={() => childToParent(selectedItens)}
        >
          <i className="pi pi-check"></i>
        </button>
      );
    }
    return '';
  }

  useEffect(() => {
    async function verificarSeMultiEmpresa(idUsuario, searchFilter) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        await axiosAdmin.get(params);

        getData(searchFilter, 0, lazyParams.rows);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    async function getConfiguracaoSistema() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const params = `/configuracaosistema?idUsuario=${idUsuario}`;

        const response = await axiosAdmin.get(params);

        setSenhaAdm(get(response.data, 'senhaAdm', ''));
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getConfiguracaoSistema();

    if (findDataInicial === null) {
      setFindDataInicial(new Date(store.getState().auth.consultaEstampariaPedidoDataInicial));
      setFindDataFinal(new Date(store.getState().auth.consultaEstampariaPedidoDataFinal));

      const _dataInicial = new Date(store.getState().auth.consultaEstampariaPedidoDataInicial);
      const _dataFinal = new Date(store.getState().auth.consultaEstampariaPedidoDataFinal);

      const searchFilter = { findDataInicial: _dataInicial, findDataFinal: _dataFinal, findNomePedido, findSituacaoPedido };

      verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0), searchFilter);
    }

    if (filtroAtualizado) {
      setFiltroAtualizado(false);
      handleFind();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filtroAtualizado]);

  const handleRegister = () => {
    const id = 0;

    const clonar = false;

    setPayloadCadastro({ id, clonar });

    setShowCadastro(true);
  };

  const handleEdit = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    let continuarAlteracao = true;

    if (id > 0) {
      const _listaOrdemProducao = get(selectedItens, 'listaEstampariaOrdemProducao', []);

      _listaOrdemProducao.forEach(element => {
        if (element.situacao !== 'PLANEJADA') {
          toast.warning('Existe(m) Ordem de Produção em situação diferente de Planejada. Alteração não permitida');
          continuarAlteracao = false;
        }
        if (!element.geracaoAutomatica) {
          toast.warning('Existe(m) Ordem de Produção gerada manualmente. Alteração não permitida');
          continuarAlteracao = false;
        }
      });

      if (continuarAlteracao) {
        const clonar = false;

        setPayloadCadastro({ id, clonar });
        setShowCadastro(true);
      }
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  const handleAlterarSituacao = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setNovaSituacaoPedido(get(selectedItens, 'situacao', 'TODOS'));
      setShowAlterarSituacao(true);
    } else {
      toast.warning('Por favor, selecione o registro para alterar a situação');
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setSenhaAdmInformada(null);

      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    if (senhaAdmInformada !== senhaAdm) {
      setSenhaAdmInformada(null);

      toast.error('Senha inválida!');

      return;
    }

    setSenhaAdmInformada(null);

    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/estampariapedido/${idExclusao}`);

        const searchFilter = { findDataInicial, findDataFinal, findNomePedido, findSituacaoPedido };

        getData(searchFilter, 0, lazyParams.rows);

        setSelectedItens(null);

        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findDataInicial, findDataFinal, findNomePedido, findSituacaoPedido };

    getData(searchFilter, event.page, event.rows);
  };

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const childToParentCadastro = (payloadDataTableList, id) => {
    if (payloadDataTableList) {
      if (id > 0) {
        const searchFilter = { findDataInicial, findDataFinal, findNomePedido, findSituacaoPedido };

        getData(searchFilter, lazyParams.page, lazyParams.rows);

        setSelectedItens(null);
      } else {
        const _dataTableList = [];
        _dataTableList.push(payloadDataTableList);

        setDataTableList(_dataTableList);

        const _lazyParams = { ...lazyParams, first: 0, page: 0 };
        setLazyParams(_lazyParams);
        setTotalRecords(1);
      }
    }

    setShowCadastro(false);
  };

  const handlePrint = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setPayloadPrint({ id });
      setShowPrint(true);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  const onHidePrint = () => {
    setShowPrint(false);
  };

  const finishPrintPedido = () => {
    setShowPrint(false);
  };

  const onHideAlterarSituacao = () => {
    setShowAlterarSituacao(false);
  };

  const handleClonarPedido = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      const clonar = true;

      setPayloadCadastro({ id, clonar });
      setShowCadastro(true);
    } else {
      toast.warning('Por favor, selecione o pedido para clonagem');
    }
  };

  const confirmAlterarSituacao = async () => {
    setShowAlterarSituacao(false);

    try {
      setIsLoading(true);

      const idPedido = get(selectedItens, 'id', 0);

      if (idPedido > 0) {
        await axiosAdmin.put(`/estampariapedido/alterarsituacao/${idPedido}?situacao=${novaSituacaoPedido}`);

        handleFind();

        setSelectedItens(null);

        toast.success('Situação alterada com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', err);
    } finally {
      setIsLoading(false);
    }
  };

  const cancelAlterarSituacao = () => {
    setShowAlterarSituacao(false);
  };

  const onHideConsultaParticipante = () => {
    setShowConsultaParticipante(false);
  };

  const childToParentParticipante = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setFindIdCliente(e.id);
      }
      if (e.razaoSocial) {
        setFindNomeCliente(e.razaoSocial);
      }
      setShowConsultaParticipante(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const alterarSituacaoFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmAlterarSituacao} />
      <Button label="Não" icon="pi pi-times" onClick={cancelAlterarSituacao} />
    </div>
  );

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroEstampariaPedido payloadCadastro={payloadCadastro} childToParentCadastro={childToParentCadastro} />
      </Dialog>

      <Dialog
        header=""
        visible={showPrint}
        style={{ width: '98vw' }}
        onHide={() => onHidePrint()}
        className="dialog-cadastro"
      >
        <PdfEstampariaPedido payloadPrint={payloadPrint} finishPrintPedido={finishPrintPedido} />
      </Dialog>

      <Dialog
        header="Consulta de Participantes"
        visible={showConsultaParticipante}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaParticipante()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParentParticipante} />
      </Dialog>

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              PEDIDOS
            </h6>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Data inicial
                </Input155px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => {
                    setFindDataInicial(e.value);
                    const consultaEstampariaPedidoDataInicial = e.value;
                    const consultaEstampariaPedidoDataFinal = findDataFinal;
                    dispatch(actions.consultaEstampariaPedidoDataInicialChange({ consultaEstampariaPedidoDataInicial, consultaEstampariaPedidoDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Data final
                </Input155px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => {
                    setFindDataFinal(e.value);
                    const consultaEstampariaPedidoDataInicial = findDataInicial;
                    const consultaEstampariaPedidoDataFinal = e.value;
                    dispatch(actions.consultaEstampariaPedidoDataInicialChange({ consultaEstampariaPedidoDataInicial, consultaEstampariaPedidoDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="p-inputgroup">
                {/* <Input100px className="p-inputgroup-addon">
                  Situação
                </Input100px> */}
                <Dropdown
                  options={listaSituacaoPedido}
                  value={findSituacaoPedido}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => setFindSituacaoPedido(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Alterar Situação"
                className="btn btn-info"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleAlterarSituacao(e)}
              >
                <i className="pi pi-book"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Clonar Pedido"
                className="btn btn-secondary"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleClonarPedido(e)}
              >
                <i className="pi pi-clone"></i>
              </button>
              <button
                type="button"
                title="Imprimir Pedido"
                className="btn btn-dark"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handlePrint(e)}
              >
                <i className="pi pi-eye"></i>
              </button>
              {renderBotaoSelect()}
            </div>
          </div>
          <br />
          <div className="row align-items-center">
            <div className="col-5">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Nome do Pedido
                </Input155px>
                <InputText
                  maxLength={100}
                  value={findNomePedido}
                  onChange={e => setFindNomePedido(e.target.value)}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Cliente
                </Input100px>
                <InputText
                  disabled
                  maxLength={255}
                  value={findNomeCliente}
                  onChange={e => setFindNomeCliente(e.target.value)}
                />
                <Button
                  icon="pi pi-search"
                  className="p-button-warning"
                  onClick={() => setShowConsultaParticipante(true)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Nº Pedido
                </Input155px>
                <InputNumber
                  mode="decimal"
                  inputId="minmaxfraction"
                  minFractionDigits={0}
                  value={findIdPedido}
                  onValueChange={e => setFindIdPedido(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center" style={{ marginTop: '10px' }}>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <div className="row form-elo">
            <div className="col-12">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Senha Administrador
                </Input200px>
                <Password
                  autoFocus
                  toggleMask
                  feedback={false}
                  id="inputSenha"
                  value={senhaAdmInformada}
                  onChange={e => setSenhaAdmInformada(e.target.value)}
                />
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          header=""
          closeOnEscape={false}
          visible={showAlterarSituacao}
          style={{ width: '98vw' }}
          footer={alterarSituacaoFooter}
          onHide={() => onHideAlterarSituacao()}
          className="dialog-alterar-situacao"
        >
          <Card>
            <div className="card mb-3">
              <h6 className="card-header" style={{ textAlign: 'center' }}>
                Alterar Situação do Pedido
              </h6>
            </div>
            <div className="row align-items-center">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Situação
                  </Input100px>
                  <Dropdown
                    options={listaSituacaoPedido}
                    value={novaSituacaoPedido}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={(e) => setNovaSituacaoPedido(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </Card>
        </Dialog>

      </Card>
    </>
  );
}
