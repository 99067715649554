import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { get } from 'lodash';
import dateFormat from 'dateformat';
import { toast } from 'react-toastify';
import { Calendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { Input100px, Input155px } from '../../../styles/inputGroup';
import Card from '../../../components/card';
import Loading from '../../loading';
import { formataMoeda, displayErrors } from '../../../util/diversos';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';

import ConsultaVendedor from '../vendedor/consultaVendedor';

export default function RelatorioEstampariaPedidoVendedores() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [showConsultaVendedor, setShowConsultaVendedor] = useState(false);
  const [nomeVendedor, setNomeVendedor] = useState('Todos');
  const [idVendedor, setIdVendedor] = useState('');
  const [codigoVendedor, setCodigoVendedor] = useState('');
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [findSituacaoPedido, setFindSituacaoPedido] = useState('TODOS');

  const [listaVendedores, setListaVendedores] = useState([]);

  const [listaSituacaoPedido] = useState([
    { label: 'Todos', value: 'TODOS' },
    { label: 'Aberto', value: 'ABERTO' },
    { label: 'Fechado', value: 'FECHADO' },
  ]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const headRows = [
    {
      id: 'id',
      nomePedido: 'Pedido',
      cliente: 'Cliente',
      dataPedido: 'Data Pedido',
      dataEntrega: 'Data Entrega',
      situacao: 'Situação',
      valorTotal: 'Total',
    },
  ];

  const headRowsSintetico = [
    {
      nomeVendedor: 'Vendedor',
      quantidade: 'Qtde Pedidos',
      total: 'Total',
    },
  ];

  const idUsuario = get(store.getState().auth.user, 'id', 0);

  useEffect(() => {
    // eslint-disable-next-line consistent-return
    async function getVendedores() {
      try {
        setIsLoading(true);
        const _listaVendedores = [];

        const params = `/vendedor?idUsuario=${idUsuario}`;

        const { data: _vendedores } = await axiosAdmin.get(params);
        if (_vendedores <= 0) {
          toast.info('Não há registros a serem exibidos.');
          return false;
        }

        _listaVendedores.push({ label: 'Todos', value: 'TODOS', codigo: '' });

        _vendedores.forEach(element => {
          _listaVendedores.push({ label: element.nome, value: element.id, codigo: element.codigo });
        });

        setListaVendedores(_listaVendedores);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getVendedores();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getData(searchFilter, page, size, img) {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    try {
      setIsLoading(true);

      if (findDataInicial == null && findDataFinal == null) {
        toast.info('Datas devem ser informadas.');
        return;
      }

      if (findDataInicial > findDataFinal) {
        toast.info('Data final anterior a data inicial.');
        return;
      }

      let params = `/estampariapedido?idUsuario=${idUsuario}`;
      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = `${dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy')} 00:00:00`;
        const _dataFinal = `${dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy')} 23:59:59`;

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }
      if (searchFilter && searchFilter.findSituacaoPedido !== 'TODOS') {
        params = `${params}&situacao=${searchFilter.findSituacaoPedido}`;
      }
      if (idVendedor > 0) {
        params = `${params}&idVendedor=${idVendedor}`;
      }
      const response = await axiosAdmin.get(params);
      if (response.data <= 0) {
        toast.info('Não há registros a serem exibidos.');
        return;
      }

      let data = {
        id: '0',
        nomePedido: '',
        cliente: '',
        dataPedido: '',
        dataEntrega: '',
        valorTotal: '',
        situacao: '',
      };

      const result = [];
      let totalPedidos = 0;
      if (idVendedor > 0) { // analitico
        for (let x = 0; x < response.data.length; x++) {
          const pedido = response.data[x];

          data.id = pedido.id.toString();
          data.nomePedido = pedido.nomePedido.toString();
          if (pedido.cliente != null) {
            const id = pedido.cliente.id.toString();
            data.cliente = `${id} - ${pedido.cliente.razaoSocial}`;
          }
          if (pedido.dataPedido !== null) {
            data.dataPedido = `${('00' + pedido.data[2]).slice(-2)}/${('00' + pedido.data[1]).slice(-2)}/${pedido.data[0]}`;
          }
          if (pedido.dataEntrega !== null) {
            data.dataEntrega = `${('00' + pedido.dataEntrega[2]).slice(-2)}/${('00' + pedido.dataEntrega[1]).slice(-2)}/${pedido.dataEntrega[0]}`;
          }

          if (findSituacaoPedido !== 'CANCELADO') {
            data.situacao = pedido.situacao;
            if (pedido.situacao !== 'CANCELADO') {
              data.valorTotal = `${formataMoeda(pedido.valorTotal)}`;
              totalPedidos += pedido.valorTotal;
            } else {
              data.valorTotal = `${formataMoeda(pedido.valorTotal)}`;
            }
          } else {
            data.valorTotal = `${formataMoeda(pedido.valorTotal)}`;
            totalPedidos += pedido.valorTotal;
          }
          result.push({ ...data });
        }

        // adionar linha vazia
        data = {
          id: '',
          nomePedido: '',
          cliente: '',
          dataPedido: '',
          dataEntrega: '',
          situacao: '',
          valorTotal: '',
        };
        result.push({ ...data });

        data.dataEntrega = 'Total';
        data.valorTotal = `${formataMoeda(totalPedidos)}`;

        result.push({ ...data });
      } else { // sintético
        let totalFinal = 0;
        listaVendedores.forEach(el => {
          const codigo = el.codigo.trim();
          const nome = el.label.trim();

          if (el.value > 0) {
            let quantidade = 0;
            let total = 0;
            for (let x = 0; x < response.data.length; x++) {
              const pedido = response.data[x];

              if (pedido.vendedor && pedido.vendedor.id === el.value) {
                quantidade++;
                total += pedido.valorTotal;
              }
            }
            totalFinal += total;

            data = {
              nomeVendedor: codigo + ' - ' + nome,
              quantidade: quantidade.toString(),
              total: `${formataMoeda(total)}`,
            };

            result.push({ ...data });
          }
        });

        data = {
          nomeVendedor: '',
          quantidade: '',
          total: '',
        };
        result.push({ ...data });

        data = {
          nomeVendedor: '',
          quantidade: 'Total',
          total: `${formataMoeda(totalFinal)}`,
        };

        result.push({ ...data });
      }

      doc.autoTable({
        styles: { fontSize: 8 },
        margin: { top: 38, left: 5, right: 5 },
        columnStyles: {
          6: { cellWidth: 23 },
        },
        theme: 'grid',
        head: idVendedor > 0 ? headRows : headRowsSintetico,
        body: result,
        didDrawPage: dados => {
          // Header
          doc.rect(5, 5, 200, 30);
          doc.addImage(img, 'JPEG', 9, 8, 25, 25);
          doc.setFontSize(20);
          doc.text('Relatório de Pedidos por Vendedor', 50, 15);

          doc.setFontSize(8);
          doc.text('Situação:', 50, 27);
          doc.text(findSituacaoPedido, 63, 27);

          doc.setFontSize(8);
          doc.text('Vendedor:', 131, 32);
          doc.setFontSize(11);
          // eslint-disable-next-line no-unused-expressions
          (codigoVendedor && nomeVendedor) ? (
            doc.text((codigoVendedor + ' - ' + nomeVendedor).substring(0, 25), 145, 32)
          ) : (
            doc.text('Todos', 145, 32)
          );

          doc.setFontSize(8);
          doc.text('Período:', 50, 32);
          doc.text(dateFormat(findDataInicial, 'dd/mm/yyyy'), 63, 32);
          doc.text(' à ', 79, 32);
          doc.text(dateFormat(findDataFinal, 'dd/mm/yyyy'), 83, 32);

          // footer
          const { pageSize } = doc.internal;
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(`Pag ${doc.internal.getNumberOfPages()}`, dados.settings.margin.right, pageHeight - 8);
          doc.text('Elo Informática Barbacena LTDA', 82, pageHeight - 8);
          const periodo = new Date();
          doc.text('Impresso: ', 172, pageHeight - 8);
          // eslint-disable-next-line prefer-template
          doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 189, pageHeight - 8);
        },
      });
      window.open(doc.output('bloburl'));
    } catch (e) {
      // console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal, findSituacaoPedido };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    const loadImage = async () => {
      let config = '';
      try {
        config = await axiosAdmin.get(`/configuracaosistema?idUsuario=${idUsuario}`);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }

      const img = new Image();
      img.src = `/logotipos/${config.data.urlLogotipo}`;
      await img.decode().then(() => {

      }).catch(() => {
        img.src = 'logo.png';
      });
      getData(searchFilter, 0, lazyParams.rows, img);
    };
    loadImage();
  };

  const childToParentVendedor = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdVendedor(e.id);
      }
      if (e.codigo) {
        setCodigoVendedor(e.codigo);
      }
      if (e.nome) {
        setNomeVendedor(e.nome);
      }
      setShowConsultaVendedor(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const onHideConsultaVendedor = () => {
    setShowConsultaVendedor(false);
  };

  // eslint-disable-next-line consistent-return
  async function handleBuscarVendedorByCodigo(e) {
    try {
      setIsLoading(true);

      if (e.length <= 0) {
        setIdVendedor('');
        setNomeVendedor('');
        return false;
      }

      let params = `/vendedor?idUsuario=${idUsuario}`;

      params = `${params}&codigo=${e.trim()}`;

      const response = await axiosAdmin.get(params);

      if (response.data.length > 0) {
        setIdVendedor(response.data[0].id);
        setNomeVendedor(response.data[0].nome);
        setCodigoVendedor(response.data[0].codigo);
      } else {
        setIdVendedor('');
        setNomeVendedor('');
        setCodigoVendedor('');
        toast.info('Nenhum vendedor com esse código encontrado');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Vendedor"
        visible={showConsultaVendedor}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaVendedor()}
      >
        <ConsultaVendedor menuMode={false} childToParent={childToParentVendedor} />
      </Dialog>

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}> Relatório Pedidos por Vendedor </h6>
        </div>

        <div className="row form-elo">
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data inicial
              </Input100px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={findDataInicial}
                onChange={(e) => setFindDataInicial(e.value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data final
              </Input100px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={findDataFinal}
                onChange={(e) => setFindDataFinal(e.value)}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Situação
              </Input100px>
              <Dropdown
                options={listaSituacaoPedido}
                value={findSituacaoPedido}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setFindSituacaoPedido(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Cód. Vendedor
              </Input155px>
              <InputText
                maxLength={255}
                value={codigoVendedor}
                onChange={e => setCodigoVendedor(e.target.value)}
                onBlur={e => handleBuscarVendedorByCodigo(e.target.value)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaVendedor(true)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <InputText
                disabled
                maxLength={255}
                value={nomeVendedor}
              />
            </div>
          </div>
          <div className="col-4">
          </div>
          <div className="col-lg-1">
            <button
              type="button"
              title="Buscar"
              className="btn btn-success"
              style={{ borderRadius: '15px' }}
              onClick={handleFind}
            >
              <i className="pi pi-search"></i>
            </button>
          </div>
        </div>
      </Card>
    </>
  );
}
