import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Badge } from 'primereact/badge';
import { addLocale } from 'primereact/api';
import { get, orderBy } from 'lodash';

import dateFormat from 'dateformat';

import * as actions from '../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';
import { Input100px, Input155px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';
import CadastroMovimentacaoFinanceira from './cadastroMovimentacaoFinanceira';

export default function ConsultaMovimentacaoFinanceira({ menuMode, childToParent }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [permitirCadastrar, setPermitirCadastrar] = useState(true);
  const [permitirEditar, setPermitirEditar] = useState(true);
  const [permitirExcluir, setPermitirExcluir] = useState(true);
  const [permitirQuitar, setPermitirQuitar] = useState(true);
  const [permitirConciliar, setPermitirConciliar] = useState(true);
  const [permitirCancelarConciliacao, setPermitirCancelarConciliacao] = useState(true);

  const [showFiltroModal, setShowFiltroModal] = useState(false);
  const [showConciliacaoModal, setShowConciliacaoModal] = useState(false);
  const [showQuitacaoModal, setShowQuitacaoModal] = useState(false);
  const [showCadastro, setShowCadastro] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showCancelarConciliacao, setShowCancelarConciliacao] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listaContasBancarias, setListaContasBancarias] = useState([]);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [listaCentroCusto, setListaCentroCusto] = useState([]);
  const [listaMeioPagamento, setListaMeioPagamento] = useState([]);
  const [contaBancariaConciliacao, setContaBancariaConciliacao] = useState(0);
  const [disabledContaBancariaConciliacao, setDisabledContaBancariaConciliacao] = useState(false);
  const [efetivadoConciliacao, setEfetivadoConciliacao] = useState(true);
  const [livroCaixaConciliacao, setLivroCaixaConciliacao] = useState(true);
  const [dataConciliacao, setDataConciliacao] = useState(null);
  const [dataQuitacao, setDataQuitacao] = useState(null);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [dataTableList, setDataTableList] = useState([]);
  const [filtroAtualizado, setFiltroAtualizado] = useState(null);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [findOperacaoFinanceira, setFindOperacaoFinanceira] = useState('PAGAR');
  const [tmpOperacaoFinanceira, setTmpOperacaoFinanceira] = useState('PAGAR');
  const [findPrevisao, setFindPrevisao] = useState('T');
  const [tmpPrevisao, setTmpPrevisao] = useState('T');
  const [findCentroCusto, setFindCentroCusto] = useState(0);
  const [tmpCentroCusto, setTmpCentroCusto] = useState(0);
  const [findMeioPagamento, setFindMeioPagamento] = useState(0);
  const [tmpMeioPagamento, setTmpMeioPagamento] = useState(0);
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [payloadCadastro, setPayloadCadastro] = useState({});
  const [columnsSetup] = useState([
    { field: 'dataEmissao', header: 'Emissão', sort: true },
    { field: 'dataVencimento', header: 'Vencimento', sort: true },
    { field: 'centroCusto.codigo', header: 'Centro Custo', sort: false },
    { field: 'meioPagamento.descricao', header: 'Meio Pagamento', sort: false },
    { field: 'nomeParticipante', header: 'Participante', sort: false },
    { field: 'valorTotal', header: 'Valor Total', sort: false },
    { field: 'valorAberto', header: 'Valor Aberto', sort: false },
    { field: 'previsao', header: 'Previsão', sort: false },
    { field: 'movimentacaoBancaria', header: 'Conciliado', sort: false },
  ]);
  const [listaOperacaoFinanceira] = useState([
    { label: 'Pagar', value: 'PAGAR' },
    { label: 'Receber', value: 'RECEBER' },
  ]);
  const [listaPrevisao] = useState([
    { label: 'Não', value: 'N' },
    { label: 'Somente Previsão', value: 'S' },
    { label: 'Todos', value: 'T' },
  ]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  async function getData(searchFilter, filtrarCnpjEmpresa, page, size) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/financeiro/search?idUsuario=${idUsuario}&operacao=${findOperacaoFinanceira}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy');

        const _dataFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy');

        params = `${params}&dataVencimentoInicial=${_dataInicial}&dataVencimentoFinal=${_dataFinal}`;
      }

      if (searchFilter && searchFilter.findCentroCusto > 0) {
        params = `${params}&centroCusto=${searchFilter.findCentroCusto}`;
      }

      if (searchFilter && searchFilter.findMeioPagamento > 0) {
        params = `${params}&meioPagamento=${searchFilter.findMeioPagamento}`;
      }

      if (findPrevisao !== 'T') {
        let blnPrevisao = true;
        if (findPrevisao === 'N') {
          blnPrevisao = false;
        }

        params = `${params}&previsao=${blnPrevisao}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal, findCentroCusto, findMeioPagamento };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    setSelectedItens(null);

    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  };

  useEffect(() => {
    const operadorGrupoAcesso = get(store.getState().auth.user, 'operadorGrupoAcesso', null);

    if (operadorGrupoAcesso != null) {
      let listaGrupoAcessoBody = [];
      listaGrupoAcessoBody = operadorGrupoAcesso.listaGrupoAcessoBody;
      const permissaoCadastrar = listaGrupoAcessoBody.filter(el => el.modulo === 'CadastrarMovimentacaoFinanceira');
      const permissaoEditar = listaGrupoAcessoBody.filter(el => el.modulo === 'EditarMovimentacaoFinanceira');
      const permissaoExcluir = listaGrupoAcessoBody.filter(el => el.modulo === 'ExcluirMovimentacaoFinanceira');
      const permissaoQuitar = listaGrupoAcessoBody.filter(el => el.modulo === 'QuitarMovimentacaoFinanceira');
      const permissaoConciliar = listaGrupoAcessoBody.filter(el => el.modulo === 'ConciliarMovimentacaoFinanceira');
      const permissaoCancelarConciliacao = listaGrupoAcessoBody.filter(el => el.modulo === 'CancelarConciliacaoMovimentacaoFinanceira');

      if (permissaoCadastrar.length <= 0) {
        setPermitirCadastrar(false);
      }

      if (permissaoEditar.length <= 0) {
        setPermitirEditar(false);
      }

      if (permissaoExcluir.length <= 0) {
        setPermitirExcluir(false);
      }

      if (permissaoQuitar.length <= 0) {
        setPermitirQuitar(false);
      }

      if (permissaoConciliar.length <= 0) {
        setPermitirConciliar(false);
      }

      if (permissaoCancelarConciliacao.length <= 0) {
        setPermitirCancelarConciliacao(false);
      }
    }
  }, []);

  useEffect(() => {
    let mostrarMensagemErro = true;

    async function getCentroCusto() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];

        const { data: _centrocustos } = await axiosAdmin.get(`/centrocusto?idUsuario=${idUsuario}`);

        _centrocustos.forEach(element => {
          listDropdown.push({
            id: element.id,
            label: `${element.codigo} ${element.descricao}`,
            value: element.id,
            codigo: `${element.codigo}`,
            descricao: `${element.descricao}`,
            operacao: `${element.operacaoFinanceira}`,
          });
        });

        listDropdown.unshift({
          id: 0,
          label: 'Todos',
          value: 0,
          codigo: '',
          descricao: '',
          operacao: '',
        });

        setListaCentroCusto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getMeioPagamento() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];

        const { data: _meiopagamentos } = await axiosAdmin.get(`/meiopagamento?idUsuario=${idUsuario}`);

        _meiopagamentos.forEach(element => {
          listDropdown.push({ label: `${element.descricao}`, value: element.id });
        });

        listDropdown.unshift({ label: 'Todos', value: 0 });

        setListaMeioPagamento(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function verificarSeMultiEmpresa(idUsuario, searchFilter) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        const _cnpjConsultaMovimentacaoFinanceiraCjpEmpresa = store.getState().auth.consultaMovimentacaoFinanceiraCnpjEmpresa;

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjConsultaMovimentacaoFinanceiraCjpEmpresa === null || _cnpjConsultaMovimentacaoFinanceiraCjpEmpresa === '') {
          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            const consultaMovimentacaoFinanceiraCnpjEmpresa = _cnpjEmpresaStore;
            dispatch(actions.consultaMovimentacaoFinanceiraCnpjEmpresaChange({ consultaMovimentacaoFinanceiraCnpjEmpresa }));
            cnpjPreenchido = true;
          }
        }

        _listaEmpresas.forEach(element => {
          if (element.ativo) {
            if (!cnpjPreenchido && (_cnpjConsultaMovimentacaoFinanceiraCjpEmpresa === null || _cnpjConsultaMovimentacaoFinanceiraCjpEmpresa === '')) {
              setCnpjEmpresa(element.cnpj);
              const consultaMovimentacaoFinanceiraCnpjEmpresa = element.cnpj;
              dispatch(actions.consultaMovimentacaoFinanceiraCnpjEmpresaChange({ consultaMovimentacaoFinanceiraCnpjEmpresa }));
              _cnpjEmpresaStore = element.cnpj;
              cnpjPreenchido = true;
            }

            const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

            listDropdown.push({ label: nomeEmpresa, value: element.cnpj, id: element.id });
          }
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (!cnpjPreenchido && _cnpjConsultaMovimentacaoFinanceiraCjpEmpresa !== null && _cnpjConsultaMovimentacaoFinanceiraCjpEmpresa !== '') {
          setCnpjEmpresa(_cnpjConsultaMovimentacaoFinanceiraCjpEmpresa);
          _cnpjEmpresaStore = _cnpjConsultaMovimentacaoFinanceiraCjpEmpresa;
        }

        getCentroCusto();

        getMeioPagamento();

        getData(searchFilter, _cnpjEmpresaStore, 0, lazyParams.rows);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    function isDateBeforeToday(date) {
      return new Date(date.toDateString()) < new Date(new Date().toDateString());
    }

    async function getContaBancaria() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];

        const { data: _contasbancarias } = await axiosAdmin.get(`/contabancaria?idUsuario=${idUsuario}`);

        _contasbancarias.forEach(element => {
          listDropdown.push({ id: element.id, label: `${element.banco} ${element.bancoDescricao} ${element.agencia} ${element.conta} ${element.descricao}`, value: element.id });
        });

        setListaContasBancarias(listDropdown);
      } catch (err) {
        displayErrors(`Não foi possível acessar a tabela de Contas Bancárias: ${err}`, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    if (findDataInicial === null) {
      setFindDataInicial(new Date(store.getState().auth.consultaMovimentacaoFinanceiraDataInicial));
      setFindDataFinal(new Date(store.getState().auth.consultaMovimentacaoFinanceiraDataFinal));

      const _dataInicial = new Date(store.getState().auth.consultaMovimentacaoFinanceiraDataInicial);
      let _dataFinal = new Date(store.getState().auth.consultaMovimentacaoFinanceiraDataFinal);

      if (isDateBeforeToday(_dataFinal)) {
        setFindDataFinal(new Date());
        _dataFinal = new Date();
      }

      const searchFilter = { findDataInicial: _dataInicial, findDataFinal: _dataFinal, findCentroCusto, findMeioPagamento };

      verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0), searchFilter);
    }

    getContaBancaria();

    if (filtroAtualizado) {
      setFiltroAtualizado(false);
      handleFind();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filtroAtualizado]);

  // eslint-disable-next-line consistent-return
  const handleRegister = () => {
    if (!permitirCadastrar) {
      toast.info('Usuário não autorizado a cadastrar.');
      return false;
    }
    const id = 0;

    setPayloadCadastro({ id, cnpjEmpresa, findOperacaoFinanceira });

    setShowCadastro(true);
  };

  // eslint-disable-next-line consistent-return
  const handleEdit = (e) => {
    e.preventDefault();

    if (!permitirEditar) {
      toast.info('Usuário não autorizado a editar.');
      return false;
    }

    if (!selectedItens || selectedItens.length === 0) {
      toast.warning('Por favor, selecione o registro para edição');
      return false;
    }

    if (selectedItens.length > 1) {
      toast.warning('Somente um registro pode ser selecionado para alteração');
      return false;
    }

    const id = get(selectedItens[0], 'id', 0);

    if (id > 0) {
      const idMovimentacaoBancaria = get(selectedItens[0].movimentacaoBancaria, 'id', 0);
      const idLivroCaixa = get(selectedItens[0].livroCaixa, 'id', 0);

      if (idMovimentacaoBancaria > 0 || idLivroCaixa > 0) {
        toast.warning('Registro conciliado, alteração não permitida');
      } else {
        setPayloadCadastro({ id, cnpjEmpresa, findOperacaoFinanceira });
        setShowCadastro(true);
      }
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleDelete = (e) => {
    e.preventDefault();

    if (!permitirExcluir) {
      toast.info('Usuário não autorizado a excluir.');
      return false;
    }

    if (!selectedItens || selectedItens.length === 0) {
      toast.warning('Por favor, selecione o registro para exclusão');
      return false;
    }

    if (selectedItens.length > 1) {
      toast.warning('Somente um registro pode ser selecionado para exclusão');
      return false;
    }

    const id = get(selectedItens[0], 'id', 0);

    if (id > 0) {
      const idMovimentacaoBancaria = get(selectedItens[0].movimentacaoBancaria, 'id', 0);
      const idLivroCaixa = get(selectedItens[0].livroCaixa, 'id', 0);

      if (idMovimentacaoBancaria > 0 || idLivroCaixa > 0) {
        toast.warning('Registro conciliado, exclusão não permitida');
      } else {
        const idOrigem = get(selectedItens[0], 'idOrigem', 0);
        if (idOrigem !== null && idOrigem > 0) {
          toast.error('Registro de origem do Retaguarda não pode ser excluído!');
        } else {
          setShowConfirmDialog(true);
        }
      }
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleConciliacao = (e) => {
    e.preventDefault();

    if (!permitirConciliar) {
      toast.info('Usuário não autorizado a cadastrar.');
      return false;
    }

    if (!selectedItens || selectedItens.length <= 0) {
      toast.warning('Por favor, selecione o(s) registro(s) para conciliação');
      return false;
    }

    let continuar = true;

    selectedItens.forEach(element => {
      let idMovimentacaoBancaria = get(element.movimentacaoBancaria, 'id', 0);
      if (idMovimentacaoBancaria === null) {
        idMovimentacaoBancaria = 0;
      }

      let idLivroCaixa = get(element.livroCaixa, 'id', 0);
      if (idLivroCaixa === null) {
        idLivroCaixa = 0;
      }

      if (idMovimentacaoBancaria > 0 || idLivroCaixa > 0) {
        toast.error('Existe um registro já conciliado nos registros selecionados. Conciliação não permitida');
        continuar = false;
      }
    });

    if (continuar) {
      setContaBancariaConciliacao(0);
      setDataConciliacao(null);
      setLivroCaixaConciliacao(false);
      setEfetivadoConciliacao(true);
      setShowConciliacaoModal(true);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleQuitacao = (e) => {
    e.preventDefault();

    if (!permitirQuitar) {
      toast.info('Usuário não autorizado a cadastrar.');
      return false;
    }

    if (!selectedItens || selectedItens.length <= 0) {
      toast.warning('Por favor, selecione o(s) registro(s) para quitação');
      return false;
    }

    let continuar = true;

    selectedItens.forEach(element => {
      if (element.valorAberto && element.valorAberto <= 0) {
        toast.error('Existe um registro já quitado nos registros selecionados. Quitação não permitida');
        continuar = false;
      }
    });

    if (continuar) {
      setDataQuitacao(null);
      setShowQuitacaoModal(true);
    }
  };

  const handleFinalizarQuitacao = (ok) => {
    async function salvarQuitacao(shippingData) {
      try {
        setIsLoading(true);

        const _data = dateFormat(dataQuitacao, 'dd-mm-yyyy');

        const params = `/financeiro/quitacao?data=${_data}`;

        await axiosAdmin.post(params, shippingData);

        const searchFilter = { findDataInicial, findDataFinal };

        getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);

        toast.success('Quitação realizada com sucesso!');
      } catch (err) {
        displayErrors(err, dispatch, navigate, '', 'Não foi possível realizar a quitação');
      } finally {
        setIsLoading(false);
      }
    }

    if (ok) {
      if (!dataQuitacao) {
        toast.error('Data de quitação deve ser informada');
        return;
      }

      const listId = [];

      selectedItens.forEach(element => {
        listId.push(element.id);
      });

      const shippingData = {
        listId,
      };

      salvarQuitacao(shippingData);
    }

    setShowQuitacaoModal(false);
  };

  const renderFooterQuitacaoModal = () => (
    <div>
      <Button label="Não" icon="pi pi-times" onClick={() => handleFinalizarQuitacao(false)} className="p-button-text" />
      <Button label="Sim" icon="pi pi-check" onClick={() => handleFinalizarQuitacao(true)} autoFocus />
    </div>
  );

  // eslint-disable-next-line consistent-return
  const handleCancelarConciliacao = (e) => {
    e.preventDefault();

    if (!permitirCancelarConciliacao) {
      toast.info('Usuário não autorizado a cadastrar.');
      return false;
    }

    if (!selectedItens || selectedItens.length <= 0) {
      toast.warning('Por favor, selecione o(s) registro(s) para cancelar a conciliação');
      return false;
    }

    let continuar = true;

    selectedItens.forEach(element => {
      let idMovimentacaoBancaria = get(element.movimentacaoBancaria, 'id', 0);
      if (idMovimentacaoBancaria === null) {
        idMovimentacaoBancaria = 0;
      }

      let idLivroCaixa = get(element.livroCaixa, 'id', 0);
      if (idLivroCaixa === null) {
        idLivroCaixa = 0;
      }

      if (idMovimentacaoBancaria <= 0 && idLivroCaixa <= 0) {
        toast.error('Existe um registro não conciliado nos registros selecionados.');
        continuar = false;
      }
    });

    if (continuar) {
      setShowCancelarConciliacao(true);
    }
  };

  const handleLimparSelecao = (e) => {
    e.preventDefault();

    setSelectedItens(null);
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens[0], 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/financeiro/${idExclusao}`);

        const _itens = dataTableList.filter(val => val.id !== idExclusao);
        setDataTableList(_itens);

        setSelectedItens(null);

        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  const confirmCancelarConciliacao = async () => {
    setShowCancelarConciliacao(false);

    async function cancelarConciliacao(shippingData) {
      try {
        setIsLoading(true);

        await axiosAdmin.post('/financeiro/cancelarconciliacao', shippingData);

        const searchFilter = { findDataInicial, findDataFinal };

        getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);

        toast.success('Cancelamento da Conciliação realizada com sucesso!');
      } catch (err) {
        displayErrors(err, dispatch, navigate, '', 'Não foi possível realizar a conciliação');
      } finally {
        setIsLoading(false);
      }
    }

    const listId = [];

    selectedItens.forEach(element => {
      listId.push(element.id);
    });

    const shippingData = {
      listId,
    };

    cancelarConciliacao(shippingData);
  };

  const cancelCancelarConciliacao = () => {
    setShowCancelarConciliacao(false);
  };

  const cancelarConcialiacaoFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmCancelarConciliacao} />
      <Button label="Não" icon="pi pi-times" onClick={cancelCancelarConciliacao} />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    // setSelectedItens(null);

    const searchFilter = { findDataInicial, findDataFinal };

    getData(searchFilter, cnpjEmpresa, event.page, event.rows);
  };

  const onHideFiltroModal = (ok) => {
    setShowFiltroModal(false);
    if (ok) {
      setFindOperacaoFinanceira(tmpOperacaoFinanceira);
      setFindPrevisao(tmpPrevisao);
      setFindCentroCusto(tmpCentroCusto);
      setFindMeioPagamento(tmpMeioPagamento);
      setFiltroAtualizado(true);
    } else {
      setTmpOperacaoFinanceira(findOperacaoFinanceira);
      setTmpPrevisao(findPrevisao);
      setTmpCentroCusto(findCentroCusto);
      setTmpMeioPagamento(findMeioPagamento);
    }
  };

  const renderFooterFiltroModal = () => (
    <div>
      <Button label="Não" icon="pi pi-times" onClick={() => onHideFiltroModal(false)} className="p-button-text" />
      <Button label="Sim" icon="pi pi-check" onClick={() => onHideFiltroModal(true)} autoFocus />
    </div>
  );

  const onHideConciliacaoModal = (ok) => {
    async function salvarConciliacao(shippingData) {
      try {
        setIsLoading(true);

        const _data = dateFormat(dataConciliacao, 'dd-mm-yyyy HH:MM:ss');

        const params = `/financeiro/conciliacao?data=${_data}&idContaBancaria=${contaBancariaConciliacao}&efetivado=${efetivadoConciliacao}`;

        await axiosAdmin.post(params, shippingData);

        const searchFilter = { findDataInicial, findDataFinal };

        getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);

        toast.success('Conciliação realizada com sucesso!');
      } catch (err) {
        displayErrors(err, dispatch, navigate, '', 'Não foi possível realizar a conciliação');
      } finally {
        setIsLoading(false);
      }
    }

    if (ok) {
      if (!dataConciliacao) {
        toast.error('Data de conciliação deve ser informada');
        return;
      }
      if (!livroCaixaConciliacao) {
        if (!contaBancariaConciliacao || contaBancariaConciliacao <= 0) {
          toast.error('Conta Bancária para conciliação deve ser informada');
          return;
        }
      }

      const listId = [];

      selectedItens.forEach(element => {
        listId.push(element.id);
      });

      const shippingData = {
        listId,
      };

      salvarConciliacao(shippingData);
    }

    setShowConciliacaoModal(false);
  };

  const renderFooterConciliacaoModal = () => (
    <div>
      <Button label="Não" icon="pi pi-times" onClick={() => onHideConciliacaoModal(false)} className="p-button-text" />
      <Button label="Sim" icon="pi pi-check" onClick={() => onHideConciliacaoModal(true)} autoFocus />
    </div>
  );

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const childToParentCadastro = (payloadDataTableList, id) => {
    if (payloadDataTableList) {
      if (id > 0) {
        const index = dataTableList.map(e => e.id).indexOf(id);

        if (index >= 0) {
          dataTableList[index] = payloadDataTableList;
        }
      } else {
        const _dataTableList = [];
        _dataTableList.push(payloadDataTableList);

        setDataTableList(_dataTableList);

        const _lazyParams = { ...lazyParams, first: 0, page: 0 };
        setLazyParams(_lazyParams);
        setTotalRecords(1);
      }
    }

    setShowCadastro(false);
  };

  const numeroRegistrosSelecionados = () => {
    let registros = 0;

    if (selectedItens) {
      registros = selectedItens.length;
    }

    return registros;
  };

  function renderBotaoSelect() {
    if (!menuMode) {
      return (
        <button
          type="button"
          title="Selecionar"
          className="btn btn-info"
          style={{ borderRadius: '15px' }}
          onClick={() => childToParent(selectedItens)}
        >
          <i className="pi pi-check"></i>
        </button>
      );
    }
    return '';
  }

  async function licencaValida(empresaId) {
    // eslint-disable-next-line consistent-return
    const retorno = await axiosAdmin.get(`/empresa/verificardatalicenca/${empresaId}`);
    return retorno.data;
  }

  // eslint-disable-next-line consistent-return
  async function handleCnpjEmpresa(e) {
    let empresaId = 0;

    listaEmpresas.forEach(element => {
      if (element.value === e.target.value) {
        // eslint-disable-next-line no-unused-expressions
        empresaId = element.id;
      }
    });

    const isLicencaValida = await licencaValida(empresaId);
    if (!isLicencaValida) {
      toast.info('Cliente com licença expirada. Efetuar pagamento mensalidade.');
      // navigate('/pagamento');
      return false;
    }

    setCnpjEmpresa(e.target.value);

    const consultaMovimentacaoFinanceiraCnpjEmpresa = e.target.value;

    dispatch(actions.consultaMovimentacaoFinanceiraCnpjEmpresaChange({ consultaMovimentacaoFinanceiraCnpjEmpresa }));
  }

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroMovimentacaoFinanceira payloadCadastro={payloadCadastro} childToParentCadastro={childToParentCadastro} />
      </Dialog>

      <Card>
        <div>
          <div className="card mb-3" onClick={() => setShowFiltroModal(true)}>
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              CONTAS A {findOperacaoFinanceira}
            </h6>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-11">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Empresa
                </Input100px>
                <Dropdown
                  options={listaEmpresas}
                  value={cnpjEmpresa}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => handleCnpjEmpresa(e)}
                />
              </div>
            </div>
            <div className="col-lg-1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <i className="pi pi-chevron-circle-down mr-4 p-text-secondary p-overlay-badge" style={{ fontSize: '2rem' }}>
                <Badge value={numeroRegistrosSelecionados()}></Badge>
              </i>
            </div>
          </div>
          <br />
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data inicial
                </Input100px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => {
                    setFindDataInicial(e.value);
                    const consultaMovimentacaoFinanceiraDataInicial = e.value;
                    const consultaMovimentacaoFinanceiraDataFinal = findDataFinal;
                    dispatch(actions.consultaMovimentacaoFinanceiraDataInicialChange({ consultaMovimentacaoFinanceiraDataInicial, consultaMovimentacaoFinanceiraDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data final
                </Input100px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => {
                    setFindDataFinal(e.value);
                    const consultaMovimentacaoFinanceiraDataInicial = findDataInicial;
                    const consultaMovimentacaoFinanceiraDataFinal = e.value;
                    dispatch(actions.consultaMovimentacaoFinanceiraDataInicialChange({ consultaMovimentacaoFinanceiraDataInicial, consultaMovimentacaoFinanceiraDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <button
                type="button"
                title="Filtrar"
                className="btn btn-dark"
                style={{ borderRadius: '15px' }}
                onClick={() => setShowFiltroModal(true)}
              >
                Filtrar
                <i className="pi pi-filter"></i>
              </button>
              &nbsp;&nbsp;
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
              &nbsp;&nbsp;
              <button
                type="button"
                title="Quitar Registros"
                className="btn btn-dark"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleQuitacao(e)}
              >
                <i className="pi pi-wallet"></i>
              </button>
              <button
                type="button"
                title="Conciliar"
                className="btn btn-dark"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleConciliacao(e)}
              >
                <i className="pi pi-sort-amount-up-alt"></i>
              </button>
              <button
                type="button"
                title="Cancelar Conciliação"
                className="btn btn-dark"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleCancelarConciliacao(e)}
              >
                <i className="pi pi-times"></i>
              </button>
              <button
                type="button"
                title="Limpar Seleção"
                className="btn btn-dark"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleLimparSelecao(e)}
              >
                <i className="pi pi-minus-circle"></i>
              </button>
              {renderBotaoSelect()}
            </div>
          </div>
          <div className="row align-items-center" style={{ marginTop: '10px' }}>
          </div>

          <Dialog
            header="Filtro Financeiro"
            visible={showFiltroModal}
            style={{ width: '98vw' }}
            onHide={() => onHideFiltroModal()}
            footer={renderFooterFiltroModal()}
          >
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Operação
                  </Input100px>
                  <Dropdown
                    options={listaOperacaoFinanceira}
                    value={tmpOperacaoFinanceira}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setTmpOperacaoFinanceira(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Listar Previsão
                  </Input155px>
                  <Dropdown
                    options={listaPrevisao}
                    value={tmpPrevisao}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setTmpPrevisao(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <br></br>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Centro Custo
                  </Input155px>
                  <Dropdown
                    options={listaCentroCusto}
                    value={tmpCentroCusto}
                    onChange={(e) => setTmpCentroCusto(e.value)}
                    filter
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Meio Pagamento
                  </Input155px>
                  <Dropdown
                    options={listaMeioPagamento}
                    value={tmpMeioPagamento}
                    onChange={(e) => setTmpMeioPagamento(e.value)}
                    filter
                  />
                </div>
              </div>
            </div>
          </Dialog>

          <Dialog
            header="Quitação"
            visible={showQuitacaoModal}
            style={{ width: '98vw' }}
            onHide={() => handleFinalizarQuitacao()}
            footer={renderFooterQuitacaoModal()}
          >
            <div className="row align-items-center">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Data
                  </Input100px>
                  <Calendar
                    id="mask"
                    showIcon
                    mask="99/99/9999"
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showOnFocus={false}
                    locale="pt-BR"
                    value={dataQuitacao}
                    onChange={(e) => setDataQuitacao(e.value)}
                  />
                </div>
              </div>
            </div>
          </Dialog>

          <Dialog
            header="Conciliação Bancária"
            visible={showConciliacaoModal}
            style={{ width: '98vw' }}
            onHide={() => onHideConciliacaoModal()}
            footer={renderFooterConciliacaoModal()}
          >
            <div className="row align-items-center">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Data
                  </Input100px>
                  <Calendar
                    id="mask"
                    showIcon
                    showTime
                    showSeconds
                    hourFormat="24"
                    mask="99/99/9999 99:99:99"
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showOnFocus={false}
                    locale="pt-BR"
                    value={dataConciliacao}
                    onChange={(e) => setDataConciliacao(e.value)}
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Conta Bancária
                  </Input155px>
                  <Dropdown
                    disabled={disabledContaBancariaConciliacao}
                    options={listaContasBancarias}
                    value={contaBancariaConciliacao}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={(e) => setContaBancariaConciliacao(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-1" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <div className="field-checkbox">
                  <Checkbox
                    inputId="binary"
                    checked={livroCaixaConciliacao}
                    onChange={e => {
                      setLivroCaixaConciliacao(e.checked);
                      if (e.checked) {
                        setContaBancariaConciliacao(0);
                        setDisabledContaBancariaConciliacao(true);
                      } else {
                        setDisabledContaBancariaConciliacao(false);
                      }
                    }}
                  />
                  <label htmlFor="binary">
                    &nbsp;Livro Cx
                  </label>
                </div>
              </div>
              <div className="col-lg-1" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                <div className="field-checkbox">
                  <Checkbox
                    inputId="binary"
                    checked={efetivadoConciliacao}
                    onChange={e => setEfetivadoConciliacao(e.checked)}
                  />
                  <label htmlFor="binary">
                    &nbsp;Efetivado
                  </label>
                </div>
              </div>
            </div>
          </Dialog>
        </div>

        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>

        <Dialog
          header="Confirmação"
          visible={showCancelarConciliacao}
          style={{ width: '50vw' }}
          footer={cancelarConcialiacaoFooter}
          onHide={() => setShowCancelarConciliacao(false)}
        >
          <p>
            Deseja realmente cancelar a conciliação desse(s) registro(s)?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
