import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { isAlpha } from 'validator';

import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input100px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosAdmin from '../../../services/axiosAdmin';

export default function CadastroUnidadeFederacao() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(useParams(), 'id', 0);

  const [codigo, setCodigo] = useState(0);
  const [descricao, setDescricao] = useState('');
  const [sigla, setSigla] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let newTitle = 'Unidade da Federação - Cadastro';
    if (id) {
      newTitle = 'Unidade da Federação - Edição';
    }

    dispatch(actions.titleHeaderChange({ newTitle }));

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/unidadefederacao/${id}`);

        setCodigo(data.codigo);
        setDescricao(data.descricao);
        setSigla(data.sigla);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      // eslint-disable-next-line prefer-template
      const codigoEnvio = ('00' + codigo).slice(-2);

      const shippingData = {
        codigo: codigoEnvio,
        descricao,
        sigla,
      };

      if (id > 0) {
        await axiosAdmin.put(`/unidadefederacao/${id}`, shippingData);
        toast.success('Unidade da Federação atualizada com sucesso');
      } else {
        await axiosAdmin.post('/unidadefederacao', shippingData);
        toast.success('Unidade da Federação cadastrada com sucesso');
      }

      navigate('/consultaunidadefederacao');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    navigate('/consultaunidadefederacao');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (codigo < 1 || codigo > 99) {
      formErros = true;
      toast.error('Código inválido');
    }
    if (descricao.length < 3 || descricao.length > 50) {
      formErros = true;
      toast.error('Descrição deve ter entre 3 a 50 caracteres');
    }
    if (sigla.length !== 2) {
      formErros = true;
      toast.error('Sigla deve ter 2 caracteres');
    }
    if (!isAlpha(sigla)) {
      formErros = true;
      toast.error('Sigla deve ter somente caracteres de A a Z');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const changeCaseSigla = (event) => {
    event.preventDefault();

    setSigla(event.target.value.toUpperCase());
  };

  const titulo = () => (id ? 'Unidade da Federação - Alteração' : 'Unidade da Federação - Inclusão');

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Código
              </Input100px>
              <InputNumber
                inputId="inputCodigo"
                min={1}
                max={99}
                value={codigo}
                onValueChange={e => setCodigo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-9">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Descrição
              </Input100px>
              <InputText
                id="inputDescricao"
                maxLength={20}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Sigla
              </Input100px>
              <InputText
                id="inputSigla"
                maxLength={2}
                value={sigla}
                onChange={changeCaseSigla}
              />
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-lg-12">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
