export function obterListaTipoMovimentacaoProduto() {
  return (
    [
      { label: 'Entrada de Produtos', value: 'ENTRADAESTOQUE' },
      { label: 'Transferência de Produtos', value: 'TRANSFERENCIA' },
      { label: 'Saída de Produto Garantia', value: 'SAIDAGARANTIA' },
      // { label: 'Saída Acerto de Estoque', value: 'SAIDAACERTOESTOQUE' },
      // { label: 'Saída por Perda', value: 'SAIDAPERDA' },
      // { label: 'Saída por Furto', value: 'SAIDAFURTO' },
      // { label: 'Saída Consumo Próprio', value: 'SAIDACONSUMOPROPRIO' },
    ]
  );
}
