/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { get } from 'lodash';
import dateFormat from 'dateformat';
import { toast } from 'react-toastify';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Input100px } from '../../../styles/inputGroup';
import Card from '../../../components/card';
import Loading from '../../loading';
import { formataMoeda, displayErrors } from '../../../util/diversos';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';

export default function RelatorioEstampariaCaixa() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const [isLoading, setIsLoading] = useState(false);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal] = useState(null);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  function headRows() {
    return [
      {
        dataHora: 'Data',
        tipo: 'Tipo',
        historico: 'Histórico',
        meioPagamento: 'Meio de Pagamento',
        subTotal: 'Valor',
      },
    ];
  }

  const [headerTabelaImpressao] = useState(headRows());

  function headRowsResumo() {
    return [
      {
        meioPagamento: 'Meio de Pagamento',
        valor: 'Valor',
      },
    ];
  }

  const [headerTabelaResumo] = useState(headRowsResumo());

  const idUsuario = get(store.getState().auth.user, 'id', 0);

  async function getData(searchFilter, img) {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    // eslint-disable-next-line new-cap
    const doc2 = new jsPDF();
    try {
      setIsLoading(true);

      const usuario = await axiosAdmin.get(`/usuario/${idUsuario}`);

      if (findDataInicial == null) {
        toast.info('Datas devem ser informadas.');
        return;
      }

      let params = `/estampariacaixa?idUsuario=${idUsuario}`;
      if (searchFilter && searchFilter.findDataInicial !== '' && searchFilter.findDataFinal !== '') {
        const _dataInicial = `${dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy')} 00:00:00`;
        const _dataFinal = `${dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy')} 23:59:59`;

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      const response = await axiosAdmin.get(params);

      if (response.data <= 0) {
        toast.info('Não há registros a serem exibidos.');
        return;
      }

      const data = {
        dataHora: '',
        tipo: '',
        historico: '',
        meioPagamento: '',
        subTotal: '',
      };

      const result = [];
      // const fluxo = _.sortBy(response.data.content, 'id');
      const fluxo = response.data;
      for (let x = 0; x < fluxo.length; x++) {
        const caixa = fluxo[x];

        data.dataHora = `${('00' + caixa.data[2]).slice(-2)}/${('00' + caixa.data[1]).slice(-2)}/${caixa.data[0]} ${('00' + caixa.data[3]).slice(-2)}:${('00' + caixa.data[4]).slice(-2)}:${caixa.data[5] ? ('00' + caixa.data[5]).slice(-2) : '00'}`;
        data.tipo = caixa.tipoRegistroCaixa;
        data.historico = caixa.historico;
        data.meioPagamento = caixa.meioPagamento.descricao;
        data.subTotal = formataMoeda(caixa.valor);

        result.push({ ...data });
      }

      doc.autoTable({
        styles: { fontSize: 8 },
        margin: { top: 38, left: 5, right: 5 },
        columnStyles: {
          4: { cellWidth: 23 },
        },
        theme: 'grid',
        head: headerTabelaImpressao,
        body: result,
        didDrawPage: dados => {
          // Header
          doc.rect(5, 5, 200, 30);
          doc.addImage(img, 'JPEG', 9, 8, 25, 25);
          doc.setFontSize(20);
          doc.text('Relatório de Caixa ', 50, 15);

          doc.setFontSize(8);
          usuario.data.listaEmpresas[0].nomeFantasia ? doc.text(usuario.data.listaEmpresas[0].nomeFantasia, 147, 13) : '';
          usuario.data.email ? doc.text(usuario.data.email, 147, 17) : '';
          if (usuario.data.listaEmpresas[0].endereco && usuario.data.listaEmpresas[0].enderecoNumero) {
            const endereco = `${usuario.data.listaEmpresas[0].endereco} - ${usuario.data.listaEmpresas[0].enderecoNumero}`;
            doc.text(endereco, 147, 21);
          }
          usuario.data.listaEmpresas[0].uf ? doc.text(usuario.data.listaEmpresas[0].uf, 147, 25) : '';
          usuario.data.listaEmpresas[0].cidade ? doc.text(usuario.data.listaEmpresas[0].cidade, 154, 25) : '';
          usuario.data.listaEmpresas[0].telefone_1 ? doc.text(usuario.data.listaEmpresas[0].telefone_1, 147, 29) : '';
          usuario.data.listaEmpresas[0].telefone_2 ? doc.text(usuario.data.listaEmpresas[0].telefone_2, 172, 29) : '';

          doc.setFontSize(10);
          doc.text('Data:', 50, 32);
          doc.text(dateFormat(findDataInicial, 'dd/mm/yyyy'), 60, 32);

          // footer
          const { pageSize } = doc.internal;
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(`Pag ${doc.internal.getNumberOfPages()}`, dados.settings.margin.right, pageHeight - 8);
          const periodo = new Date();
          doc.text('Impresso: ', 170, pageHeight - 8);
          // eslint-disable-next-line prefer-template
          doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 187, pageHeight - 8);
        },
      });
      window.open(doc.output('bloburl'));

      let paramsResumo = `/estampariacaixa?idUsuario=${idUsuario}`;
      if (searchFilter && searchFilter.findDataInicial !== '' && searchFilter.findDataFinal !== '') {
        const _dataInicial = `${dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy')} 00:00:00`;
        const _dataFinal = `${dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy')} 23:59:59`;

        paramsResumo = `${paramsResumo}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }
      paramsResumo = `${paramsResumo}&resumoMeioPagamento=true`;

      const responseResumo = await axiosAdmin.get(paramsResumo);

      let dataResumo = {
        meioPagamento: '',
        valor: '',
      };

      let resumo = [];
      const fluxoResumo = responseResumo.data;
      let valorTotalResumo = 0;
      for (let x = 0; x < fluxoResumo.length; x++) {
        const caixaResumo = fluxoResumo[x];

        dataResumo.meioPagamento = caixaResumo.meioPagamento.descricao;
        dataResumo.valor = formataMoeda(caixaResumo.valor);
        valorTotalResumo += caixaResumo.valor;

        resumo.push({ ...dataResumo });
      }

      // eslint-disable-next-line no-undef
      const ordenado = _.sortBy(resumo, 'meioPagamento');
      resumo = ordenado;

      dataResumo = {
        meioPagamento: '',
        valor: '',
      };
      resumo.push({ ...dataResumo });

      dataResumo.valor = formataMoeda(valorTotalResumo);
      resumo.push({ ...dataResumo });

      doc2.autoTable({
        styles: { fontSize: 8 },
        margin: { top: 38, left: 5, right: 5 },
        columnStyles: {
          1: { cellWidth: 35 },
        },
        theme: 'grid',
        head: headerTabelaResumo,
        body: resumo,
        didDrawPage: dados => {
          // Header
          doc2.rect(5, 5, 200, 30);
          doc2.addImage(img, 'JPEG', 9, 8, 25, 25);
          doc2.setFontSize(20);
          doc2.text('Resumo de Caixa ', 50, 15);

          doc2.setFontSize(8);
          usuario.data.listaEmpresas[0].nomeFantasia ? doc2.text(usuario.data.listaEmpresas[0].nomeFantasia, 147, 13) : '';
          usuario.data.email ? doc2.text(usuario.data.email, 147, 17) : '';
          if (usuario.data.listaEmpresas[0].endereco && usuario.data.listaEmpresas[0].enderecoNumero) {
            const endereco = `${usuario.data.listaEmpresas[0].endereco} - ${usuario.data.listaEmpresas[0].enderecoNumero}`;
            doc2.text(endereco, 147, 21);
          }
          usuario.data.listaEmpresas[0].uf ? doc2.text(usuario.data.listaEmpresas[0].uf, 147, 25) : '';
          usuario.data.listaEmpresas[0].cidade ? doc2.text(usuario.data.listaEmpresas[0].cidade, 154, 25) : '';
          usuario.data.listaEmpresas[0].telefone_1 ? doc2.text(usuario.data.listaEmpresas[0].telefone_1, 147, 29) : '';
          usuario.data.listaEmpresas[0].telefone_2 ? doc2.text(usuario.data.listaEmpresas[0].telefone_2, 172, 29) : '';

          doc2.setFontSize(10);
          doc2.text('Data:', 50, 32);
          doc2.text(dateFormat(findDataInicial, 'dd/mm/yyyy'), 60, 32);

          // footer
          const { pageSize } = doc2.internal;
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc2.text(`Pag ${doc.internal.getNumberOfPages()}`, dados.settings.margin.right, pageHeight - 8);
          const periodo = new Date();
          doc2.text('Impresso: ', 170, pageHeight - 8);
          // eslint-disable-next-line prefer-template
          doc2.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 187, pageHeight - 8);
        },
      });
      window.open(doc2.output('bloburl'));
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal };

    const loadImage = async () => {
      let config = '';
      try {
        config = await axiosAdmin.get(`/configuracaosistema?idUsuario=${idUsuario}`);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }

      const img = new Image();
      img.src = `/logotipos/${config.data.urlLogotipo}`;
      await img.decode().then(() => {

      }).catch(() => {
        img.src = 'logo.png';
      });
      getData(searchFilter, img);
    };
    loadImage();
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}> Relatório de Caixa </h6>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data
              </Input100px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={findDataInicial}
                onChange={(e) => setFindDataInicial(e.value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <button
              type="button"
              title="Buscar"
              className="btn btn-success"
              style={{ borderRadius: '15px' }}
              onClick={handleFind}
            >
              <i className="pi pi-search"></i>
            </button>
          </div>
        </div>
      </Card>
    </>
  );
}
