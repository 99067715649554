export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const PERSIST_REHYDRATE = 'persist/REHYDRATE';

export const TITLEHEADER_CHANGE = 'TITLEHEADER_CHANGE';

export const CNPJEMPRESA_CHANGE = 'CNPJEMPRESA_CHANGE';

export const CONSULTAMOVIMENTACAOFINANCEIRADATAINICIAL_CHANGE = 'CONSULTAMOVIMENTACAOFINANCEIRADATAINICIAL_CHANGE';

export const CONSULTAMOVIMENTACAOFINANCEIRACNPJEMPRESA_CHANGE = 'CONSULTAMOVIMENTACAOFINANCEIRACNPJEMPRESA_CHANGE';

export const CONSULTAPREVISAOFINANCEIRADATAINICIAL_CHANGE = 'CONSULTAPREVISAOFINANCEIRADATAINICIAL_CHANGE';

export const CONSULTAPREVISAOFINANCEIRACNPJEMPRESA_CHANGE = 'CONSULTAPREVISAOFINANCEIRACNPJEMPRESA_CHANGE';

export const CONSULTAMOVIMENTACAOBANCARIADATAINICIAL_CHANGE = 'CONSULTAMOVIMENTACAOBANCARIADATAINICIAL_CHANGE';

export const CONSULTAMOVIMENTACAOBANCARIACONTABANCARIA_CHANGE = 'CONSULTAMOVIMENTACAOBANCARIACONTABANCARIA_CHANGE';

export const CONSULTALIVROCAIXADATAINICIAL_CHANGE = 'CONSULTALIVROCAIXADATAINICIAL_CHANGE';

export const CONSULTALIVROCAIXACNPJEMPRESA_CHANGE = 'CONSULTALIVROCAIXACNPJEMPRESA_CHANGE';

export const CONSULTAFLUXOCAIXADATAINICIAL_CHANGE = 'CONSULTAFLUXOCAIXADATAINICIAL_CHANGE';

export const CONSULTAFLUXOCAIXACNPJEMPRESA_CHANGE = 'CONSULTAFLUXOCAIXACNPJEMPRESA_CHANGE';

export const CONSULTAESTAMPARIAPEDIDODATAINICIAL_CHANGE = 'CONSULTAESTAMPARIAPEDIDODATAINICIAL_CHANGE';

export const CONSULTAESTAMPARIARECEBIMENTOPEDIDODATAINICIAL_CHANGE = 'CONSULTAESTAMPARIARECEBIMENTOPEDIDODATAINICIAL_CHANGE';

export const CONSULTAESTAMPARIAORDEMPRODUCAODATAINICIAL_CHANGE = 'CONSULTAESTAMPARIAORDEMPRODUCAODATAINICIAL_CHANGE';

export const CONSULTAESTAMPARIAORCAMENTODATAINICIAL_CHANGE = 'CONSULTAESTAMPARIAORCAMENTODATAINICIAL_CHANGE';
