import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { addLocale } from 'primereact/api';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors, converteData, formataDataMoment } from '../../../util/diversos';
import { Input200px, Input100px } from '../../../styles/inputGroup';
import { obterListaCodNatCc, obterListaIndCta } from '../../../services/contaAnaliticaService';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function CadastroContaAnalitica({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);

  const [codigo, setCodigo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [data, setData] = useState(null);
  const [codNatCc, setCodNatCC] = useState('');
  const [indCta, setIndCta] = useState('');
  const [nivel, setNivel] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  useEffect(() => {
    let newTitle = 'Conta Analítica Contábil - Cadastro';
    if (id) {
      newTitle = 'Conta Analítica Contábil - Edição';
    }

    dispatch(actions.titleHeaderChange({ newTitle }));

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data: _dataGet } = await axiosAdmin.get(`/contaanalitica/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (_dataGet.idUsuario !== idUsuario) {
          navigate('/consultacontaanalitica');
          toast.error('Acesso negado');
        }

        const _data = converteData(_dataGet.data);

        setCodigo(_dataGet.codigo);
        setDescricao(_dataGet.descricao);
        setData(_data);
        setCodNatCC(_dataGet.codNatCc);
        setIndCta(_dataGet.indCta);
        setNivel(_dataGet.nivel);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const _data = formataDataMoment(data, '-');

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const cnpjEmpresa = get(payloadCadastro, 'cnpjEmpresa', '');

      const shippingData = {
        idUsuario,
        cnpjEmpresa,
        codigo,
        descricao,
        data: _data,
        codNatCc,
        indCta,
        nivel,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/contaanalitica/${id}`, shippingData), 'data', {});
        toast.success('Conta Analítica Contabil atualizada com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/contaanalitica', shippingData), 'data', {});
        toast.success('Conta Analítica Contabil cadastrada com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!codigo) {
      formErros = true;
      toast.error('Código deve ser informado.');
    }
    if (descricao.length < 3 || descricao.length > 50) {
      formErros = true;
      toast.error('Descrição deve ter entre 3 a 50 caracteres');
    }
    if (!data) {
      formErros = true;
      toast.error('Data da Inclusão/Alteração deve ser informada.');
    }
    if (!codNatCc) {
      formErros = true;
      toast.error('Código da Natureza da Conta deve ser informado.');
    }
    if (!indCta) {
      formErros = true;
      toast.error('Indicador do Tipo de Conta deve ser informado.');
    }
    if (nivel < 0) {
      formErros = true;
      toast.error('Nível da Conta não pode ser negativo.');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const listaCodNatCc = obterListaCodNatCc();
  const listaIndCta = obterListaIndCta();

  const titulo = () => (id ? 'Conta Analítica Contábil - Alteração' : 'Conta Analítica Contábil - Inclusão');

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Código
              </Input100px>
              <InputText
                autoFocus
                maxLength={60}
                value={codigo}
                onChange={e => setCodigo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Descrição
              </Input100px>
              <InputText
                maxLength={60}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Data Inclusão/Alteração
              </Input200px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={data}
                onChange={(e) => setData(e.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Natureza da Conta
              </Input200px>
              <Dropdown
                options={listaCodNatCc}
                value={codNatCc}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setCodNatCC(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Tipo de Conta
              </Input200px>
              <Dropdown
                options={listaIndCta}
                value={indCta}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setIndCta(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Nível
              </Input200px>
              <InputNumber
                inputId="inputCodigo"
                min={0}
                max={999999}
                value={nivel}
                onValueChange={e => setNivel(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
