import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input100px, Input155px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

import ConsultaParticipante from '../../empresa/participante/consultaParticipante';

import { obterListaTipoBandeiraCartao } from '../../../services/tipoBandeiraCartao';

export default function CadastroBandeiraCartao({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const id = get(payloadCadastro, 'id', 0);

  const [empresaCnpj, setEmpresaCnpj] = useState(get(payloadCadastro, 'cnpjEmpresa', 0));
  const [descricao, setDescricao] = useState('');
  const [tBand, setTBand] = useState(null);
  const [idParticipante, setIdParticipante] = useState(null);
  const [nomeParticipante, setNomeParticipante] = useState('');

  const [showConsultaParticipante, setShowConsultaParticipante] = useState(false);

  const listaTipoBandeiraCartao = obterListaTipoBandeiraCartao();

  useEffect(() => {
    let newTitle = 'Centro de Custo - Cadastro';
    if (id) {
      newTitle = 'Centro de Custo - Edição';
    }

    const mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/bandeiracartao/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultabandeiracartao');
          toast.error('Acesso negado');
        }

        setEmpresaCnpj(data.cnpjEmpresa);
        setDescricao(data.descricao);
        setIdParticipante(data.participante ? data.participante.id : null);
        setNomeParticipante(data.participante ? data.participante.razaoSocial : '');
        setTBand(data.tband);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const shippingData = {
        idUsuario,
        descricao,
        idParticipante,
        tBand,
        cnpjEmpresa: empresaCnpj.trim(),
      };

      // console.log('shippingData: ', shippingData);

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/bandeiracartao/${id}`, shippingData), 'data', {});
        toast.success('Bandeira de Cartão atualizada com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/bandeiracartao', shippingData), 'data', {});
        toast.success('Bandeira de Cartão cadastrada com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const childToParentParticipante = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdParticipante(e.id);
      }
      if (e.razaoSocial) {
        setNomeParticipante(e.razaoSocial);
      }
      setShowConsultaParticipante(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!descricao) {
      formErros = true;
      toast.error('Descrição deve ser informada');
    }

    if (!tBand) {
      formErros = true;
      toast.error('Bandeira para NFe deve ser informada');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id ? 'Bandeira de Cartão - Alteração' : 'Bandeira de Cartão - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>{titulo()}</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const onHideConsultaParticipante = () => {
    setShowConsultaParticipante(false);
  };

  const limparParticipanteSelecionado = () => {
    setIdParticipante(null);
    setNomeParticipante('');
  };

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Participantes"
        visible={showConsultaParticipante}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaParticipante()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParentParticipante} empresaCnpj={empresaCnpj} />
      </Dialog>

      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-9">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Descrição
              </Input100px>
              <InputText
                maxLength={40}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Participante
              </Input155px>
              <InputText
                disabled
                maxLength={255}
                value={nomeParticipante}
                onChange={e => setNomeParticipante(e.target.value)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaParticipante(true)}
              />
              <Button
                icon="pi pi-times"
                className="p-button-danger"
                onClick={() => limparParticipanteSelecionado()}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Bandeira para NFe
              </Input155px>
              <Dropdown
                options={listaTipoBandeiraCartao}
                value={tBand}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTBand(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
