import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { get, orderBy } from 'lodash';

import ReactFileReader from 'react-file-reader';
import ofx from 'node-ofx-parser/ofx';

import dateFormat from 'dateformat';

import * as actions from '../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors, replaceAll } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';
import { Input155px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';
import CadastroMovimentacaoBancaria from './cadastroMovimentacaoBancaria';
import ConsultaMovimentacaoFinanceira from '../movimentacaoFinanceira/consultaMovimentacaoFinanceira';

export default function ConsultaMovimentacaoBancaria() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [permitirCadastrar, setPermitirCadastrar] = useState(true);
  const [permitirEditar, setPermitirEditar] = useState(true);
  const [permitirExcluir, setPermitirExcluir] = useState(true);

  const [showCadastro, setShowCadastro] = useState(false);
  const [showConsistenciaOfx, setShowConsistenciaOfx] = useState(false);
  const [showConsultaMovimentacaoFinanceira, setShowConsultaMovimentacaoFinanceira] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [idConsistenciaOfx, setIdConsistenciaOfx] = useState(0);
  const [listaConsistenciaOfx, setListaConsistenciaOfx] = useState([]);
  const [listaContaBancaria, setListaContaBancaria] = useState([]);
  const [contaBancaria, setContaBancaria] = useState(0);
  const [dataTableList, setDataTableList] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmDialogRemoverVinculo, setShowConfirmDialogRemoverVinculo] = useState(false);
  const [selectedItens, setSelectedItens] = useState(null);
  const [selectedItensConsistenciaOfx, setSelectedItensConsistenciaOfx] = useState(null);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [payloadCadastro, setPayloadCadastro] = useState({});
  const [columnsSetup] = useState([
    { field: 'data', header: 'Data', sort: true },
    { field: 'numeroDocumento', header: 'Nº Documento', sort: false },
    { field: 'historico', header: 'Histórico', sort: false },
    { field: 'valor', header: 'Valor', sort: false },
    { field: 'operacaoBancaria', header: 'D/C', sort: false },
    { field: 'saldo', header: 'Saldo', sort: false },
  ]);
  const [columnsConsistenciaOfx] = useState([
    { field: 'trnType', header: 'Tipo', sort: false },
    { field: 'memo', header: 'Histórico', sort: false },
    { field: 'valor', header: 'Valor', sort: false },
    { field: 'dc', header: 'Operação', sort: false },
    { field: 'idFinanceiro', header: 'ID Financeiro', sort: false },
  ]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  async function getData(searchFilter, page, size) {
    if (contaBancaria <= 0 || contaBancaria === null) {
      toast.warning('Por favor, selecione a Conta Bancária');

      return;
    }

    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/movimentacaobancaria/search?idUsuario=${idUsuario}&contaBancaria=${contaBancaria}`;

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy HH:MM:ss');

        const _dataFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy HH:MM:ss');

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, 0, lazyParams.rows);
  };

  useEffect(() => {
    const operadorGrupoAcesso = get(store.getState().auth.user, 'operadorGrupoAcesso', null);

    if (operadorGrupoAcesso != null) {
      let listaGrupoAcessoBody = [];
      listaGrupoAcessoBody = operadorGrupoAcesso.listaGrupoAcessoBody;
      const permissaoCadastrar = listaGrupoAcessoBody.filter(el => el.modulo === 'CadastrarMovimentacaoBancaria');
      const permissaoEditar = listaGrupoAcessoBody.filter(el => el.modulo === 'EditarMovimentacaoBancaria');
      const permissaoExcluir = listaGrupoAcessoBody.filter(el => el.modulo === 'ExcluirMovimentacaoBancaria');

      if (permissaoCadastrar.length <= 0) {
        setPermitirCadastrar(false);
      }

      if (permissaoEditar.length <= 0) {
        setPermitirEditar(false);
      }

      if (permissaoExcluir.length <= 0) {
        setPermitirExcluir(false);
      }
    }
  }, []);

  useEffect(() => {
    async function getContaBancaria() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];

        const { data: _contasbancarias } = await axiosAdmin.get(`/contabancaria?idUsuario=${idUsuario}`);

        _contasbancarias.forEach(element => {
          listDropdown.push({
            id: element.id,
            label: `${element.banco} ${element.bancoDescricao} ${element.agencia} ${element.conta} ${element.descricao}`,
            codigoBanco: element.banco,
            value: element.id,
          });
        });

        setListaContaBancaria(listDropdown);
      } catch (err) {
        displayErrors(`Não foi possível acessar a tabela de Contas Bancárias: ${err}`, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    if (findDataInicial === null) {
      setFindDataInicial(new Date(store.getState().auth.consultaMovimentacaoBancariaDataInicial));
      setFindDataFinal(new Date(store.getState().auth.consultaMovimentacaoBancariaDataFinal));
      setContaBancaria(store.getState().auth.consultaMovimentacaoBancariaContaBancaria);
    }

    getContaBancaria();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // eslint-disable-next-line consistent-return
  const handleRegister = () => {
    if (!permitirCadastrar) {
      toast.info('Usuário não autorizado a cadastrar.');
      return false;
    }

    const id = 0;

    if (!contaBancaria || contaBancaria <= 0) {
      toast.warning('Por favor, selecione a conta bancária');
      return false;
    }

    setPayloadCadastro({ id, contaBancaria });

    setShowCadastro(true);
  };

  // eslint-disable-next-line consistent-return
  const handleEdit = (e) => {
    e.preventDefault();

    if (!permitirEditar) {
      toast.info('Usuário não autorizado a editar.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      const idFinanceiro = get(selectedItens, 'idFinanceiro', 0);

      if (idFinanceiro !== null && idFinanceiro > 0) {
        toast.warning('Registro gerado por conciliação, alteração não permitida');
      } else {
        setPayloadCadastro({ id, contaBancaria });
        setShowCadastro(true);
      }
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleDelete = (e) => {
    e.preventDefault();

    if (!permitirExcluir) {
      toast.info('Usuário não autorizado a excluir.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      const idFinanceiro = get(selectedItens, 'idFinanceiro', 0);

      if (idFinanceiro !== null && idFinanceiro > 0) {
        toast.warning('Registro gerado por conciliação, exclusão não permitida');
      } else {
        setShowConfirmDialog(true);
      }
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/movimentacaobancaria/${idExclusao}`);

        const searchFilter = { findDataInicial, findDataFinal };

        getData(searchFilter, 0, lazyParams.rows);

        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  async function enviarConciliacaoOfx(listaEnvio) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const _newArraySTMTTRN = [];

      listaEnvio.forEach(element => {
        const _data = element.dtPosted.slice(6, 10) + element.dtPosted.slice(3, 5) + element.dtPosted.slice(0, 2);

        _newArraySTMTTRN.push({
          dtPosted: _data,
          fitId: element.fitId,
          memo: element.memo,
          refNum: element.refNum,
          trnAmt: element.trnAmt,
          trnType: element.trnType,
          idFinanceiro: element.idFinanceiro,
          idMovimentacaoBancaria: element.idMovimentacaoBancaria,
        });
      });

      const params = `/movimentacaobancaria/ofx?idUsuario=${idUsuario}&contaBancaria=${contaBancaria}&somenteConferencia=false`;

      await axiosAdmin.post(params, _newArraySTMTTRN);

      toast.success('Conciliação realizada com sucesso.');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível enviar o arquivo para conciliação');
    } finally {
      setIsLoading(false);
    }
  }

  const confirmConsistenciaOfx = () => {
    setShowConsistenciaOfx(false);

    enviarConciliacaoOfx(listaConsistenciaOfx);
  };

  const cancelConsistenciaOfx = () => {
    setShowConsistenciaOfx(false);
  };

  const confirmDialogConsistenciaOfxFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmConsistenciaOfx} />
      <Button label="Não" icon="pi pi-times" onClick={cancelConsistenciaOfx} />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findDataInicial, findDataFinal };

    getData(searchFilter, event.page, event.rows);
  };

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const childToParentCadastro = (payloadDataTableList, id) => {
    if (payloadDataTableList) {
      if (id > 0) {
        const searchFilter = { findDataInicial, findDataFinal };

        getData(searchFilter, lazyParams.page, lazyParams.rows);
      } else {
        const _dataTableList = [];
        _dataTableList.push(payloadDataTableList);

        setDataTableList(_dataTableList);

        const _lazyParams = { ...lazyParams, first: 0, page: 0 };
        setLazyParams(_lazyParams);
        setTotalRecords(1);
      }
    }

    setShowCadastro(false);
  };

  async function enviarVerificacaoConciliacaoOfx(arraySTMTTRN, codigoBanco) {
    try {
      if (contaBancaria <= 0 || contaBancaria === null) {
        toast.warning('Por favor, selecione a Conta Bancária');
        return;
      }

      let intCodigoBanco = null;

      try {
        intCodigoBanco = parseInt(codigoBanco, 10);
      } catch (err) {
        intCodigoBanco = 0;
      }

      const strCodigoBanco = (`000${intCodigoBanco}`).slice(-3);

      const index = listaContaBancaria.map(e => e.id).indexOf(contaBancaria);

      if (strCodigoBanco !== listaContaBancaria[index].codigoBanco) {
        toast.error('Arquivo OFX não pertence ao Banco informado na Conta Bancária!');
        return;
      }

      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const _newArraySTMTTRN = [];

      arraySTMTTRN.forEach(element => {
        _newArraySTMTTRN.push({
          checkNum: element.CHECKNUM,
          dtPosted: element.DTPOSTED,
          fitId: element.FITID,
          memo: element.MEMO,
          refNum: element.REFNUM,
          trnAmt: element.TRNAMT,
          trnType: element.TRNTYPE,
        });
      });

      const params = `/movimentacaobancaria/ofx?idUsuario=${idUsuario}&contaBancaria=${contaBancaria}&codigoBanco=${strCodigoBanco}&somenteConferencia=true`;

      const retornoConsistencia = get(await axiosAdmin.post(params, _newArraySTMTTRN), 'data', []);

      const _listaConsistenciaOfx = [];

      let id = 0;

      retornoConsistencia.forEach(element => {
        let dc = 'Crédito';
        let valor = element.trnAmt;
        if (element.trnAmt < 0) {
          dc = 'Debito';
          valor *= -1;
        }

        let { financeiroNovo } = element;
        if (!financeiroNovo) {
          if (element.idFinanceiro === null || element.idFinanceiro <= 0) {
            financeiroNovo = true;
          }
        }

        _listaConsistenciaOfx.push({
          id,
          dtPosted: element.dtPosted,
          fitId: element.fitId,
          memo: element.memo,
          trnAmt: element.trnAmt,
          trnType: element.trnType,
          idFinanceiro: element.idFinanceiro,
          idMovimentacaoBancaria: element.idMovimentacaoBancaria,
          financeiroNovo,
          dc,
          valor,
        });

        id += 1;
      });

      setListaConsistenciaOfx(_listaConsistenciaOfx);

      setShowConsistenciaOfx(true);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível enviar o arquivo para conciliação');
    } finally {
      setIsLoading(false);
    }
  }

  const handleImportarOFX = files => {
    const reader = new FileReader();
    reader.onload = function () {
      const data = ofx.parse(reader.result);
      if (get(data, 'OFX.BANKMSGSRSV1.STMTTRNRS.STMTRS.BANKTRANLIST.STMTTRN', null) === null) {
        toast.warning('Arquivo não possui nenhum registro para conciliação!');
      } else {
        const codigoBanco = get(data, 'OFX.BANKMSGSRSV1.STMTTRNRS.STMTRS.BANKACCTFROM.BANKID', null);

        enviarVerificacaoConciliacaoOfx(data.OFX.BANKMSGSRSV1.STMTTRNRS.STMTRS.BANKTRANLIST.STMTTRN, codigoBanco);
      }
    };
    reader.readAsText(files[0]);
  };

  const vincularFinanceiroOfx = (rowData) => {
    if (rowData.idMovimentacaoBancaria !== null && rowData.idMovimentacaoBancaria > 0) {
      toast.warning('Registro já conciliado, alteração não permitida.');
    } else {
      setIdConsistenciaOfx(rowData.id);

      setShowConsultaMovimentacaoFinanceira(true);
    }
  };

  const cancelRemoverVinculo = () => {
    setShowConfirmDialogRemoverVinculo(false);
  };

  const confirmRemoverVinculo = async () => {
    setShowConfirmDialogRemoverVinculo(false);

    const index = listaConsistenciaOfx.map(e => e.id).indexOf(idConsistenciaOfx);

    if (!listaConsistenciaOfx[index].financeiroNovo) {
      toast.error('Financeiro já informado anteriormente, vinculo não pode ser removido por esta tela.');
      return;
    }

    listaConsistenciaOfx[index].idFinanceiro = null;
  };

  const confirmDialogFooterRemoverVinculo = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmRemoverVinculo} />
      <Button label="Não" icon="pi pi-times" onClick={cancelRemoverVinculo} />
    </div>
  );

  const removerVinculoFinanceiroOfx = (rowData) => {
    if (rowData.idMovimentacaoBancaria !== null && rowData.idMovimentacaoBancaria > 0) {
      toast.warning('Registro já conciliado, alteração não permitida.');
      return;
    }
    if (rowData.idFinanceiro === null || rowData.idFinanceiro <= 0) {
      toast.warning('Ítem não possui financeiro vinculado.');
      return;
    }

    setIdConsistenciaOfx(rowData.id);

    setShowConfirmDialogRemoverVinculo(true);
  };

  const dataConsistenciaOfxTemplate = (rowData) => replaceAll(rowData.dtPosted, '-', '/');

  const idMovimentacaoBancariaConsistenciaOfxTemplate = (rowData) => {
    if (rowData.idMovimentacaoBancaria === null || rowData.idMovimentacaoBancaria <= 0) {
      return 'Não';
    } else {
      return 'Sim';
    }
  };

  const actionBodyTemplateConsistenciaOfx = (rowData) => (
    <>
      <Button
        icon="pi pi-dollar"
        title="Vincular a Financeiro"
        className="p-button-rounded p-button-success"
        onClick={() => vincularFinanceiroOfx(rowData)}
      />
      &nbsp;&nbsp;
      <Button
        icon="pi pi-file-excel"
        title="Excluir Vínculo"
        className="p-button-rounded p-button-danger"
        onClick={() => removerVinculoFinanceiroOfx(rowData)}
      />
    </>
  );

  const childToParentMovimentacaoFinanceira = (element) => {
    if (!element || element.length === 0) {
      toast.warning('Por favor, selecione o registro para edição');
      return;
    }

    if (element.length > 1) {
      toast.warning('Somente um registro pode ser selecionado');
      return;
    }

    const idElement = get(element[0], 'id', 0);

    let continuar = true;

    listaConsistenciaOfx.forEach(_itemConsistenciaOfx => {
      if (_itemConsistenciaOfx.idFinanceiro === idElement) {
        toast.error('Financeiro já vinculado a outro ítem.');
        continuar = false;
      }
    });

    if (continuar) {
      if (idElement > 0) {
        const index = listaConsistenciaOfx.map(e => e.id).indexOf(idConsistenciaOfx);

        listaConsistenciaOfx[index].idFinanceiro = idElement;

        setShowConsultaMovimentacaoFinanceira(false);
      } else {
        toast.warning('Por favor, selecione o registro');
      }
    }
  };

  const onHideConsultaMovimentacaoFinanceira = () => {
    setShowConsultaMovimentacaoFinanceira(false);
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroMovimentacaoBancaria payloadCadastro={payloadCadastro} childToParentCadastro={childToParentCadastro} />
      </Dialog>

      <Dialog
        header="Conciliação por Arquivo OFX"
        closeOnEscape={false}
        closable={false}
        visible={showConsistenciaOfx}
        style={{ width: '98vw' }}
        footer={confirmDialogConsistenciaOfxFooter}
        onHide={() => setShowConsistenciaOfx(false)}
        className="dialog-cosistencia-ofx"
      >
        <Dialog
          header="Consulta de Movimentação Financeira"
          visible={showConsultaMovimentacaoFinanceira}
          style={{ width: '98vw' }}
          onHide={() => onHideConsultaMovimentacaoFinanceira()}
        >
          <ConsultaMovimentacaoFinanceira menuMode={false} childToParent={childToParentMovimentacaoFinanceira} />
        </Dialog>

        <div className="card">
          <DataTable
            value={listaConsistenciaOfx}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            selectionMode="single"
            selection={selectedItensConsistenciaOfx}
            onSelectionChange={e => setSelectedItensConsistenciaOfx(e.value)}
            selectableRows
            dataKey="id"
            paginator
            rows={8}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[8, 25, 50]}
          >
            <Column field="dtPosted" header="Data" body={dataConsistenciaOfxTemplate}></Column>
            {fillColumns(columnsConsistenciaOfx)}
            <Column field="idMovimentacaoBancaria" header="Já Conciliado" body={idMovimentacaoBancariaConsistenciaOfxTemplate}></Column>
            <Column body={actionBodyTemplateConsistenciaOfx} exportable={false}></Column>
          </DataTable>
        </div>
      </Dialog>

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              Movimentação Bancária
            </h6>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Conta Bancária
                </Input155px>
                <Dropdown
                  options={listaContaBancaria}
                  value={contaBancaria}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => {
                    setContaBancaria(e.target.value);
                    const consultaMovimentacaoBancariaContaBancaria = e.target.value;
                    dispatch(actions.consultaMovimentacaoBancariaContaBancariaChange({ consultaMovimentacaoBancariaContaBancaria }));
                  }}
                />
              </div>
            </div>
          </div>
          <br />
          <div className="row align-items-center">
            <div className="col-lg-4">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Data inicial
                </Input155px>
                <Calendar
                  id="mask"
                  showIcon
                  showTime
                  showSeconds
                  hourFormat="24"
                  mask="99/99/9999 99:99:99"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => {
                    setFindDataInicial(e.value);
                    const consultaMovimentacaoBancariaDataInicial = e.value;
                    const consultaMovimentacaoBancariaDataFinal = findDataFinal;
                    dispatch(actions.consultaMovimentacaoBancariaDataInicialChange({ consultaMovimentacaoBancariaDataInicial, consultaMovimentacaoBancariaDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Data final
                </Input155px>
                <Calendar
                  id="mask"
                  showIcon
                  showTime
                  showSeconds
                  hourFormat="24"
                  mask="99/99/9999 99:99:99"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => {
                    setFindDataFinal(e.value);
                    const consultaMovimentacaoBancariaDataInicial = findDataInicial;
                    const consultaMovimentacaoBancariaDataFinal = e.value;
                    dispatch(actions.consultaMovimentacaoBancariaDataInicialChange({ consultaMovimentacaoBancariaDataInicial, consultaMovimentacaoBancariaDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
            </div>
            <div className="col-lg-1">
              <ReactFileReader handleFiles={handleImportarOFX} fileTypes=".ofx">
                <button
                  type="button"
                  title="Importar OFX"
                  className="btn btn-info"
                  style={{ borderRadius: '15px' }}
                >
                  <i className="pi pi-download"></i>OFX
                </button>
              </ReactFileReader>
            </div>
          </div>
          <div className="row align-items-center" style={{ marginTop: '10px' }}>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>

        <Dialog
          header="Confirmação"
          visible={showConfirmDialogRemoverVinculo}
          style={{ width: '50vw' }}
          footer={confirmDialogFooterRemoverVinculo}
          onHide={() => setShowConfirmDialogRemoverVinculo(false)}
        >
          <p>
            Remover o vínculo financeiro?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
