/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unneeded-ternary */
import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get, orderBy, isNumber } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { AutoComplete } from 'primereact/autocomplete';
import { ToggleButton } from 'primereact/togglebutton';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import moment from 'moment';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors, converteData, replaceAll, formataDataMoment, formataMoeda, arredondar } from '../../../util/diversos';
import { Input100px, Input155px, Input200px, Input250px, Input400px } from '../../../styles/inputGroup';
import { fillColumns } from '../../../components/table';

import { obterListaTipoMovimentacao } from '../../../services/tipoMovimentacaoService';
import { obterListaCodigoSituacao } from '../../../services/situacaoDocumento';

import { obterListaCteTipoTomador } from '../../../services/cteTipoTomador';
import { obterListaCteTipoServico } from '../../../services/cteTipoServico';
import { obterListaCteTipoEmissao } from '../../../services/cteTipoEmissao';
import { obterListaCteModalidade } from '../../../services/cteModalidade';
import { obterListaCteModeloNfProdutor } from '../../../services/cteModeloNfProdutor';
import { obterListaCteModeloNfOutros } from '../../../services/cteModeloNfOutros';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

import ConsultaParticipante from '../participante/consultaParticipante';
import ConsultaNaturezaOperacao from '../naturezaOperacao/consultaNaturezaOperacao';

export default function CadastroCtePropria({ payloadCadastro, childToParentConsulta }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [disableEdicaoEnviada, setDisableEdicaoEnviada] = useState(false);

  // console.log('payload: ', payloadCadastro);

  const id = get(payloadCadastro, 'id', 0);
  const empresaCnpj = get(payloadCadastro, 'cnpjEmpresa', 0);
  const tipoMovimentacao = get(payloadCadastro, 'tipoMovimentacao', '');
  const propsTipoMovimentacao = get(payloadCadastro, 'propsTipoMovimentacao', '');

  let seriePadrao = get(payloadCadastro, 'seriePadrao', '1');
  if (!seriePadrao) {
    seriePadrao = '1';
  }

  const [ufEmpresa, setUfEmpresa] = useState(get(payloadCadastro, 'uf', ''));

  const [flag, setFlag] = useState('D'); // D = digitada , T = transmitida
  const [chaveAcesso, setChaveAcesso] = useState('');
  const [protocolo, setProtocolo] = useState('');
  const [numeroRecibo, setNumeroRecibo] = useState('');
  const [emissaoPropria, setEmissaoPropria] = useState(propsTipoMovimentacao === 'entrada' ? 'false' : 'true');
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');

  const [filteredCfop, setFilteredCfop] = useState(null);

  const [serie, setSerie] = useState(seriePadrao);
  const [numeroCt, setNumeroCt] = useState('0');
  const [dhEmi, setDhEmi] = useState(new Date());
  const [tpNf, setTpNf] = useState(propsTipoMovimentacao === 'entrada' ? 'ENTRADA' : 'SAIDA');
  const [cfop, setCfop] = useState('');
  const [natOp, setNatOp] = useState('');
  const [codSit, setCodSit] = useState('REGULAR');
  const [tipoTomador, setTipoTomador] = useState('REMETENTE');
  const [tipoServico, setTipoServico] = useState('NORMAL');
  const [retira, setRetira] = useState(true); // se o recebedor retira no destino
  const [globalizado, setGlobalizado] = useState(false); // varios documentos mas apenas um tomador (remetente ou destinatario)
  const [tpEmis, setTpEmis] = useState('NORMAL'); // contingencia
  const [modalidade, setModalidade] = useState('RODOVIARIO');

  const [idTomador, setIdTomador] = useState(null);
  const [nomeTomador, setNomeTomador] = useState('');
  const [ieTomador, setIeTomador] = useState('');

  const [idRemetente, setIdRemetente] = useState(null);
  const [nomeRemetente, setNomeRemetente] = useState('');
  const [ieRemetente, setIeRemetente] = useState('');

  const [idDestinatario, setIdDestinatario] = useState(null);
  const [nomeDestinatario, setNomeDestinatario] = useState('');
  const [ieDestinatario, setIeDestinatario] = useState('');

  const [showConsultaTomador, setShowConsultaTomador] = useState(false);
  const [showConsultaRemetente, setShowConsultaRemetente] = useState(false);
  const [showConsultaDestinatario, setShowConsultaDestinatario] = useState(false);
  const [showConsultaNaturezaOperacao, setShowConsultaNaturezaOperacao] = useState(false);
  const [showConfirmCancelarDigitacaoCTe, setShowConfirmCancelarDigitacaoCTe] = useState(false);

  const [showCadastroNfeRef, setShowCadastroNfeRef] = useState(false);

  const [showConfirmDeleteNfeRef, setShowConfirmDeleteNfeRef] = useState(false);

  const [listaNfeRef, setListaNfeRef] = useState([]);
  const [listaNatOps, setListaNatOps] = useState([]);
  const [listaCfops, setListaCfops] = useState([]);
  const [setListaEmpresas] = useState([]);

  const [editandoNfeRef, setEditandoNfeRef] = useState(0);

  const [selectedNfeRef, setSelectedNfeRef] = useState(null);
  const [filteredNatOp, setFilteredNatOp] = useState(null);

  const listaCodigoSituacao = obterListaCodigoSituacao();
  const listaTipoMovimentacao = obterListaTipoMovimentacao();
  const listaTipoTomador = obterListaCteTipoTomador();
  const listaTipoServico = obterListaCteTipoServico();
  const listaTipoEmissao = obterListaCteTipoEmissao();
  const listaModalidade = obterListaCteModalidade();
  const listaModeloNfProdutor = obterListaCteModeloNfProdutor();
  const listaModeloNfOutros = obterListaCteModeloNfOutros();

  const [nfeRefIdEmitente, setNfeRefIdEmitente] = useState(null);
  const [razaoSocialNfeRefEmitente, setRazaoSocialNfeRefEmitente] = useState('');
  const [nfeRefChaveAcesso, setNfeRefChaveAcesso] = useState('');
  const [nfeRefMod, setNfeRefMod] = useState('');
  const [nfeRefSerie, setNfeRefSerie] = useState('');
  const [nfeRefNumeroNF, setNfeRefNumeroNF] = useState('');
  const [nfeRefDataEmissao, setNfeRefDataEmissao] = useState(null);
  const [nfeRefPeso, setNfeRefPeso] = useState(0);
  const [nfeRefValorTotal, setNfeRefValorTotal] = useState(0);
  const [nfeRefSuframa, setNfeRefSuframa] = useState(0);
  const [nfeRefDataEntrega, setNfeRefDataEntrega] = useState(null);
  const [nfeRefNumeroRomaneio, setNfeRefNumeroRomaneio] = useState(null);
  const [nfeRefNumeroPedido, setNfeRefNumeroPedido] = useState(null);
  const [nfeRefModeloNfProdutor, setNfeRefModeloNfProdutor] = useState(null);
  const [nfeRefModeloNfOutros, setNfeRefModeloNfOutros] = useState(null);
  const [nfeRefCfopPredominante, setNfeRefCfopPredominante] = useState(null);
  const [nfeRefValorBcIcms, setNfeRefValorBcIcms] = useState('');
  const [nfeRefValorIcms, setNfeRefValorIcms] = useState('');
  const [nfeRefValorBcIcmsSt, setNfeRefValorBcIcmsSt] = useState('');
  const [nfeRefValorIcmsSt, setNfeRefValorIcmsSt] = useState('');
  const [nfeRefValorTotalProdutos, setNfeRefValorTotalProdutos] = useState('');
  const [nfeRefDescricao, setNfeRefDescricao] = useState('');

  const [columnsSetupNFeRef] = useState([
    { field: 'item', header: 'Item', sort: true },
    { field: 'razaoSocial', header: 'Emitente', sort: true },
    { field: 'nnf', header: 'Número', sort: true },
    { field: 'mod', header: 'Modelo', sort: true },
    { field: 'serie', header: 'Serie', sort: true },
    { field: 'refNFe', header: 'Chave Acesso', sort: true },
  ]);

  const searchCfop = (event) => {
    setCfop(event.query);

    const listDropdown = [];
    listaCfops.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredCfop(listDropdown);
  };

  async function setUltimoNumero(serie) {
    if (emissaoPropria && id === 0) {
      try {
        setIsLoading(true);

        (serie === '') ? serie = '1' : '';

        await axiosAdmin.get(`/cte/ultimonumero?cnpjEmpresa=${empresaCnpj}&emissaoPropria=${emissaoPropria}&serie=${serie}`).then(res => {
          const ultimoNumero = res.data;
          if (res.data !== null) {
            const proximoNumero = parseInt(ultimoNumero, 10) + 1;
            setNumeroCt(proximoNumero.toString());
          }
        }).catch(() => {
          setNumeroCt(numeroCt);
        });
      } catch (err) {
        toast.warn('Não foi possível achar o número da nota');
      } finally {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    if (propsTipoMovimentacao !== 'entrada') {
      setUltimoNumero(serie.toString());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serie]);

  useEffect(() => {
    let newTitle = 'NF-e Emissão Própria - Cadastro';
    if (id) {
      newTitle = 'NF-e Emissão Própria - Edição';
    }

    dispatch(actions.titleHeaderChange({ newTitle }));

    const chaveAcesso = get(payloadCadastro, 'chaveAcesso', '');

    if (!id && tipoMovimentacao !== 'entrada') return; // tipoMovimentacao vem do handleregister do consultanfe

    const getDataById = async () => {
      const { data } = await axiosAdmin.get(`/cte/${id}`);

      // console.log('nfe: ', data);

      if (data.flag === 'T' && data.emissaoPropria) {
        setDisableEdicaoEnviada(true);
      }
      return data;
    };

    async function getData() {
      try {
        let data = null;
        if (id > 0) {
          data = await getDataById();
        }

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultanfepropria');
          toast.error('Acesso negado');
        }

        const _dhEmi = converteData(data.dhEmi, true);

        setFlag(data.flag);
        setEmissaoPropria(data.emissaoPropria);
        setChaveAcesso(data.chaveAcesso);
        setProtocolo(data.protocolo);
        setNumeroRecibo(data.numeroRecibo);
        setNumeroCt(data.numeroCt);
        setDhEmi(_dhEmi);
        setTpNf(data.tpNf);
        setNatOp(data.natOp);
        setTpEmis(data.tpEmis);
        setSerie(data.serie);
        setCodSit(data.codSit);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, navigate]);

  async function verificarSeMultiEmpresa(idUsuario) {
    try {
      setIsLoading(true);

      const params = `/usuario/${idUsuario}`;

      const response = await axiosAdmin.get(params);

      const _listaEmpresas = get(response.data, 'listaEmpresas', []);

      const listDropdown = [];

      let cnpjPreenchido = false;
      let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

      if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
        setCnpjEmpresa(_cnpjEmpresaStore);
        cnpjPreenchido = true;
      }

      _listaEmpresas.forEach(element => {
        if (!cnpjPreenchido) {
          setCnpjEmpresa(element.cnpj);
          _cnpjEmpresaStore = element.cnpj;
          cnpjPreenchido = true;
        }

        if (element.cnpj === _cnpjEmpresaStore) {
          setUfEmpresa(element.uf); // para calculo tributos
        }

        const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

        listDropdown.push({ label: nomeEmpresa, value: element.cnpj });
      });

      listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

      setListaEmpresas(listDropdown);
    } catch (err) {
      // if (mostrarMensagemErro) {
      //   displayErrors(err, dispatch, navigate, '/', '');
      // }
      // mostrarMensagemErro = false;
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  async function getNatOps() {
    const idUsuario = get(store.getState().auth.user, 'id', 0);

    const listDropdown = [];
    try {
      setIsLoading(true);

      const { data: _natOps } = await axiosAdmin.get(`/naturezaoperacao/?idUsuario=${idUsuario}`);

      _natOps.forEach(element => {
        listDropdown.push({ label: element.descricao, value: element.id });
      });

      setListaNatOps(listDropdown);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getNatOps();

    async function getCfops() {
      const listDropdown = [];
      try {
        setIsLoading(true);

        const { data: _cfops } = await axiosAdmin.get('/cfop/');

        _cfops.forEach(element => {
          const descricao = element.codigo + ' - ' + element.descricao.trim();
          listDropdown.push({ label: descricao, value: element.id });
        });

        setListaCfops(listDropdown);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }
    getCfops();

    async function getUnidadeFederacao() {
      const listDropdown = [];
      try {
        setIsLoading(true);

        const { data: _ufs } = await axiosAdmin.get('/unidadefederacao/');

        _ufs.forEach(element => {
          listDropdown.push({ label: element.sigla, value: element.sigla });
        });
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }
    getUnidadeFederacao();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);
      const cnpjEmpresa = get(payloadCadastro, 'cnpjEmpresa', '');

      let _dhEmi = null;
      if (dhEmi) {
        _dhEmi = formataDataMoment(dhEmi, '-', true);
      }

      const shippingData = {
        flag,
        chaveAcesso,
        protocolo,
        numeroRecibo,
        emissaoPropria,
        idUsuario,
        cnpjEmpresa,
        tomador: idTomador,
        remetente: idRemetente,
        destinatario: idDestinatario,
        cCt: Math.random().toString().slice(2, 10),
        numeroCt,
        dhEmi: _dhEmi,
        tpNf,
        natOp: natOp.label ? natOp.label : natOp,
        tpEmis,
        codSit,
        serie,
        cfop: cfop.label ? cfop.label.substring(0, 4) : cfop,
        tipoTomador,
        tpServ: tipoServico,
        retira,
        globalizado,
        mod: modalidade,
      };

      // console.log('shippingdata: ', shippingData);

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/cte/${id}`, shippingData), 'data', {});
        payloadDataTableList.id = id + 1;
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/cte', shippingData), 'data', {});
      }

      toast.success('CT-e salvo com sucesso');
      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      // console.log('err:', err);
      displayErrors(err, dispatch, navigate, '', 'Erro ao salvar o registro: ' + err);
    } finally {
      setIsLoading(false);
    }
  }

  // eslint-disable-next-line consistent-return
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (numeroCt <= 0) {
      toast.info('Número da CT-e incorreto.');
      return false;
    }

    if (dhEmi === null) {
      toast.info('Data inválida.');
      return false;
    }

    if (cfop === null || cfop === '') {
      toast.info('CFOP inválido.');
      return false;
    }

    if (natOp === null || natOp === '') {
      toast.info('Natureza da operação inválida.');
      return false;
    }

    if (idTomador === null) {
      toast.info('Tomador da CT-e não selecionado.');
      return false;
    }

    if (idRemetente === null) {
      toast.info('Remetente da CT-e não selecionado.');
      return false;
    }

    if (idDestinatario === null) {
      toast.info('Destinatário da CT-e não selecionado.');
      return false;
    }

    salvarRegistro();
  };

  const onHideConsultaTomador = () => {
    setShowConsultaTomador(false);
  };

  const onHideConsultaRemetente = () => {
    setShowConsultaRemetente(false);
  };

  const onHideConsultaDestinatario = () => {
    setShowConsultaDestinatario(false);
  };

  const onHideConsultaNaturezaOperacao = () => {
    setShowConsultaNaturezaOperacao(false);
    getNatOps();
  };

  const childToParentTomador = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (empresaCnpj === e.cnpjEmpresa.trim()) {
        setIdTomador(idElement);

        if (e.razaoSocial) {
          setNomeTomador(e.razaoSocial);
          setIeTomador(e.inscricaoEstadual);
        }

        setShowConsultaTomador(false);
      } else {
        toast.warning('Tomador selecionado não pertence a empresa emitente da CT-e');
      }
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentRemetente = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (empresaCnpj === e.cnpjEmpresa.trim()) {
        setIdRemetente(idElement);

        if (e.razaoSocial) {
          setNomeRemetente(e.razaoSocial);
          setIeRemetente(e.inscricaoEstadual);
        }

        setShowConsultaRemetente(false);
      } else {
        toast.warning('Remetente selecionado não pertence a empresa emitente da CT-e');
      }
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentDestinatario = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (empresaCnpj === e.cnpjEmpresa.trim()) {
        setIdDestinatario(idElement);

        if (e.razaoSocial) {
          setNomeDestinatario(e.razaoSocial);
          setIeDestinatario(e.inscricaoEstadual);
        }

        setShowConsultaDestinatario(false);
      } else {
        toast.warning('Destinatário selecionado não pertence a empresa emitente da CT-e');
      }
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentNatOp = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.descricao) {
        setNatOp(e.descricao);
      }
      setShowConsultaNaturezaOperacao(false);
      getNatOps();
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const cancelEdicaoEnviada = () => {
    childToParentConsulta();
  };

  const searchNatOp = (event) => {
    setNatOp(event.query);

    const listDropdown = [];
    listaNatOps.forEach(element => {
      if (element.label.toLowerCase().startsWith(event.query.toLowerCase())) {
        listDropdown.push({ label: element.label, value: element.value });
      }
    });

    setFilteredNatOp(listDropdown);
  };

  const confirmCancelarDigitacaoCTe = () => {
    childToParentConsulta();
  };

  const cancelCancelarDigitacaoCTe = () => {
    setShowConfirmCancelarDigitacaoCTe(false);
  };

  const handleCancel = () => {
    setShowConfirmCancelarDigitacaoCTe(true);
  };

  const confirmDialogFooterCancelarDigitacaoCTe = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmCancelarDigitacaoCTe} />
      <Button label="Não" icon="pi pi-times" onClick={cancelCancelarDigitacaoCTe} />
    </div>
  );

  function limparCamposNfeRef() {
    setNfeRefIdEmitente('');
    setRazaoSocialNfeRefEmitente('');
    setNfeRefChaveAcesso('');
    setNfeRefMod('');
    setNfeRefSerie('');
    setNfeRefNumeroNF('');
  }

  const onHideCadastroNfeRef = () => {
    limparCamposNfeRef();
    setShowCadastroNfeRef(false);
  };

  const handleOkNfeRef = () => {
    if (nfeRefDataEmissao == null) {
      toast.warning('Data Emissão Referenciado deve ser informada');
      return;
    }
    if (nfeRefMod === '') {
      toast.warning('Modelo do Doc. Referenciado deve ser informado');
      return;
    }
    if (nfeRefMod.length < 2) {
      toast.warning('Modelo do Doc. Referenciado deve conter dois dígitos');
      return;
    }
    if (nfeRefSerie === '') {
      toast.warning('Serie do Doc. Referenciado deve ser informada');
      return;
    }
    if (nfeRefNumeroNF === '') {
      toast.warning('Número do Doc. Referenciado deve ser informada');
      return;
    }
    if (nfeRefPeso <= 0) {
      toast.warning('Peso em KG deve ser informado');
      return;
    }
    if (nfeRefValorTotal <= 0) {
      toast.warning('Valor total da Nota Fiscal deve ser informado');
      return;
    }

    if (nfeRefChaveAcesso === '') {
      toast.warning('Chave de Acesso deve ser informada');
      return;
    }
    if (nfeRefChaveAcesso.length < 44) {
      toast.warning('Chave de Acesso deve conter 44 caracteres');
      return;
    }
    if (nfeRefDataEntrega == null) {
      toast.warning('Data Entrega Referenciado deve ser informada');
      return;
    }

    const _listaNfeRef = listaNfeRef;

    let item = 0;
    listaNfeRef.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoNfeRef && editandoNfeRef > 0) {
      item = editandoNfeRef;

      const index = _listaNfeRef.indexOf(_listaNfeRef.find(element => element.item === editandoNfeRef));

      if (index >= 0) {
        _listaNfeRef.splice(index, 1);
      }
    }

    _listaNfeRef.push({
      item,
      dataEmissao: formataDataMoment(nfeRefDataEmissao, '-'),
      mod: nfeRefMod,
      serie: nfeRefSerie,
      nnf: nfeRefNumeroNF,
      nfeRef: nfeRefChaveAcesso,
      dataEntrega: formataDataMoment(nfeRefDataEntrega, '-'),
    });

    console.log(_listaNfeRef);

    setListaNfeRef(_listaNfeRef);

    limparCamposNfeRef();
    setShowCadastroNfeRef(false);
  };

  const handleEditNfeRef = (e) => {
    e.preventDefault();

    const item = get(selectedNfeRef, 'item', 0);

    if (item > 0) {
      const _nfeRefSelecionado = listaNfeRef.find(element => element.item === item);

      if (!_nfeRefSelecionado) {
        toast.warning('Por favor, selecione o Doc. Referenciado para edição');
        return;
      }

      setEditandoNfeRef(item);

      limparCamposNfeRef();

      setNfeRefDataEmissao(moment(_nfeRefSelecionado.dataEmissao, 'DD-MM-YYYY').toDate());
      setNfeRefMod(_nfeRefSelecionado.mod);
      setNfeRefSerie(_nfeRefSelecionado.serie);
      setNfeRefNumeroNF(_nfeRefSelecionado.nnf);

      setNfeRefChaveAcesso(_nfeRefSelecionado.refNFe);

      setShowCadastroNfeRef(true);
    }
  };

  const handleDeleteNfeRef = (e) => {
    e.preventDefault();

    const item = get(selectedNfeRef, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaNfeRef.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o Documento Referenciado para exclusão');
        return;
      }

      setShowConfirmDeleteNfeRef(true);
    } else {
      toast.warning('Por favor, selecione o Documento Referenciado para exclusão');
    }
  };

  const titulo = () => (id ? 'CT-e - Alteração' : 'CT-e - Inclusão');

  return (

    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Tomador"
        visible={showConsultaTomador}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaTomador()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParentTomador} empresaCnpj={empresaCnpj} />
      </Dialog>

      <Dialog
        header="Consulta de Remetente"
        visible={showConsultaRemetente}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaRemetente()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParentRemetente} empresaCnpj={empresaCnpj} />
      </Dialog>

      <Dialog
        header="Consulta de Destinatário"
        visible={showConsultaDestinatario}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaDestinatario()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParentDestinatario} empresaCnpj={empresaCnpj} />
      </Dialog>

      <Dialog
        header="Consulta de Natureza da Operação"
        visible={showConsultaNaturezaOperacao}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaNaturezaOperacao()}
      >
        <ConsultaNaturezaOperacao menuMode={false} childToParent={childToParentNatOp} />
      </Dialog>

      <Dialog
        header="Confirmação Cancelamento da digitação da CTe"
        visible={showConfirmCancelarDigitacaoCTe}
        style={{ width: '50vw' }}
        footer={confirmDialogFooterCancelarDigitacaoCTe}
        onHide={() => showConfirmCancelarDigitacaoCTe(false)}
      >
        <p>
          Deseja realmente cancelar a digitação da NFe?
        </p>
      </Dialog>

      <Dialog
        header="CT-e - Documento Fiscal Referenciado"
        visible={showCadastroNfeRef}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastroNfeRef()}
      >
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Emissão*
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={nfeRefDataEmissao}
                onChange={(e) => setNfeRefDataEmissao(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Modelo*
              </Input100px>
              <InputText
                maxLength={2}
                value={nfeRefMod}
                onChange={e => setNfeRefMod(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Série*
              </Input100px>
              <InputText
                minLength={1}
                maxLength={3}
                value={nfeRefSerie}
                onChange={e => setNfeRefSerie(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Número*
              </Input100px>
              <InputText
                maxLength={9}
                value={nfeRefNumeroNF}
                onChange={e => setNfeRefNumeroNF(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Peso Total em KG
              </Input200px>
              <InputNumber
                disabled={disableEdicaoEnviada}
                onFocus={(e) => e.target.select()}
                maxFractionDigits={0}
                value={nfeRefPeso}
                onValueChange={e => setNfeRefPeso(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Valor Total Nota fiscal
              </Input200px>
              <InputNumber
                disabled
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={nfeRefValorTotal}
                onValueChange={e => setNfeRefValorTotal(e.target.value)}
              />
            </div>
          </div>
        </div>
        <TabView>
          <TabPanel header="NF-e/CT-e ">
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Chave Acesso
                  </Input200px>
                  <InputText
                    maxLength={44}
                    value={nfeRefChaveAcesso}
                    onChange={e => setNfeRefChaveAcesso(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    PIN Suframa
                  </Input200px>
                  <InputText
                    maxLength={44}
                    value={nfeRefSuframa}
                    onChange={e => setNfeRefSuframa(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Data Entrega
                  </Input155px>
                  <Calendar
                    id="mask"
                    showIcon
                    mask="99/99/9999"
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showOnFocus={false}
                    locale="pt-BR"
                    value={nfeRefDataEntrega}
                    onChange={(e) => setNfeRefDataEntrega(e.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Avulsa/Produtor">
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Número Romaneio
                  </Input200px>
                  <InputText
                    maxLength={44}
                    value={nfeRefNumeroRomaneio}
                    onChange={e => setNfeRefNumeroRomaneio(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Número Pedido
                  </Input200px>
                  <InputText
                    maxLength={44}
                    value={nfeRefNumeroPedido}
                    onChange={e => setNfeRefNumeroPedido(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Modelo Nota Fiscal
                  </Input155px>
                  <Dropdown
                    disabled={disableEdicaoEnviada}
                    options={listaModeloNfProdutor}
                    value={nfeRefModeloNfProdutor}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={(e) => setNfeRefModeloNfProdutor(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    CFOP Predominante
                  </Input200px>
                  <InputText
                    maxLength={44}
                    value={nfeRefCfopPredominante}
                    onChange={e => setNfeRefCfopPredominante(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Valor BC do ICMS
                  </Input200px>
                  <InputNumber
                    disabled
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={nfeRefValorBcIcms}
                    onValueChange={e => setNfeRefValorBcIcms(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Valor ICMS
                  </Input200px>
                  <InputNumber
                    disabled
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={nfeRefValorIcms}
                    onValueChange={e => setNfeRefValorIcms(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Valor BC do ICMS ST
                  </Input200px>
                  <InputNumber
                    disabled
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={nfeRefValorBcIcmsSt}
                    onValueChange={e => setNfeRefValorBcIcmsSt(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Valor ICMS ST
                  </Input200px>
                  <InputNumber
                    disabled
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={nfeRefValorIcmsSt}
                    onValueChange={e => setNfeRefValorIcmsSt(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Valor Total dos Produtos
                  </Input200px>
                  <InputNumber
                    disabled
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={nfeRefValorTotalProdutos}
                    onValueChange={e => setNfeRefValorTotalProdutos(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Outros">
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Modelo Nota Fiscal
                  </Input200px>
                  <Dropdown
                    disabled={disableEdicaoEnviada}
                    options={listaModeloNfOutros}
                    value={nfeRefModeloNfOutros}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={(e) => setNfeRefModeloNfOutros(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-12">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Descrição
                  </Input200px>
                  <InputText
                    maxLength={150}
                    value={nfeRefDescricao}
                    onChange={e => setNfeRefDescricao(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>

        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleOkNfeRef}
            >
              <i className="pi pi-check"></i>
              Ok
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowCadastroNfeRef(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <div className="row form-elo">
          <div className="col-2">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Série*
              </Input155px>
              <InputNumber
                disabled={disableEdicaoEnviada}
                onFocus={(e) => e.target.select()}
                maxFractionDigits={0}
                value={serie}
                onValueChange={e => {
                  setSerie(e.target.value);
                  propsTipoMovimentacao !== 'entrada' ? setUltimoNumero(e.target.value) : '';
                }}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Número*
              </Input155px>
              <InputNumber
                disabled={disableEdicaoEnviada}
                onFocus={(e) => e.target.select()}
                maxFractionDigits={0}
                value={numeroCt}
                onValueChange={e => setNumeroCt(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Emissão*
              </Input155px>
              <Calendar
                disabled={disableEdicaoEnviada}
                id="mask"
                showIcon
                showTime
                hourFormat="24"
                showSeconds
                mask="99/99/9999 99:99:99"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dhEmi}
                onChange={(e) => setDhEmi(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Movimentação
              </Input155px>
              <Dropdown
                disabled={disableEdicaoEnviada}
                options={listaTipoMovimentacao}
                value={tpNf}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTpNf(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                CFOP*
              </Input100px>
              <AutoComplete
                value={cfop}
                maxLength={4}
                suggestions={filteredCfop}
                completeMethod={searchCfop}
                field="label"
                dropdown
                forceSelection
                onChange={(e) => setCfop(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo Tomador
              </Input155px>
              <Dropdown
                disabled={disableEdicaoEnviada}
                options={listaTipoTomador}
                value={tipoTomador}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipoTomador(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo Serviço
              </Input155px>
              <Dropdown
                disabled={disableEdicaoEnviada}
                options={listaTipoServico}
                value={tipoServico}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipoServico(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Natureza da Operação*
              </Input200px>
              <AutoComplete
                disabled={disableEdicaoEnviada}
                value={natOp}
                minLength={1}
                maxLength={60}
                suggestions={filteredNatOp}
                completeMethod={searchNatOp}
                field="label"
                dropdown
                forceSelection
                onChange={(e) => setNatOp(e.target.value)}
              />
              <Button
                disabled={disableEdicaoEnviada}
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaNaturezaOperacao(true)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Código Situação
              </Input155px>
              <Dropdown
                disabled={disableEdicaoEnviada}
                options={listaCodigoSituacao}
                value={codSit}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setCodSit(e.target.value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Retira
              </Input155px>
              <ToggleButton
                disabled={disableEdicaoEnviada}
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={retira}
                onChange={(e) => setRetira(e.value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Globalizado
              </Input155px>
              <ToggleButton
                disabled={disableEdicaoEnviada}
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={globalizado}
                onChange={(e) => setGlobalizado(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo Emissão
              </Input155px>
              <Dropdown
                disabled={disableEdicaoEnviada}
                options={listaTipoEmissao}
                value={tpEmis}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTpEmis(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Modalidade
              </Input155px>
              <Dropdown
                disabled={disableEdicaoEnviada}
                options={listaModalidade}
                value={modalidade}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setModalidade(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="row form-elo">
          <TabView>
            <TabPanel header="Participantes">
              <div className="row form-elo">
                <div className="col-8">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Tomador*
                    </Input155px>
                    <InputText
                      disabled
                      maxLength={255}
                      value={nomeTomador}
                      onChange={e => setNomeTomador(e.target.value)}
                    />
                    <Button
                      disabled={disableEdicaoEnviada}
                      icon="pi pi-search"
                      className="p-button-warning"
                      onClick={() => setShowConsultaTomador(true)}
                    />

                  </div>
                </div>
                <div className="col-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Inscr. Estadual
                    </Input155px>
                    <InputText
                      disabled
                      maxLength={20}
                      value={ieTomador}
                      onChange={e => setIeTomador(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-8">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Remetente*
                    </Input155px>
                    <InputText
                      disabled
                      maxLength={255}
                      value={nomeRemetente}
                      onChange={e => setNomeRemetente(e.target.value)}
                    />
                    <Button
                      disabled={disableEdicaoEnviada}
                      icon="pi pi-search"
                      className="p-button-warning"
                      onClick={() => setShowConsultaRemetente(true)}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Inscr. Estadual
                    </Input155px>
                    <InputText
                      disabled
                      maxLength={20}
                      value={ieRemetente}
                      onChange={e => setIeRemetente(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-8">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Destinatário*
                    </Input155px>
                    <InputText
                      disabled
                      maxLength={255}
                      value={nomeDestinatario}
                      onChange={e => setNomeDestinatario(e.target.value)}
                    />
                    <Button
                      disabled={disableEdicaoEnviada}
                      icon="pi pi-search"
                      className="p-button-warning"
                      onClick={() => setShowConsultaDestinatario(true)}
                    />
                  </div>
                </div>
                <div className="col-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Inscr. Estadual
                    </Input155px>
                    <InputText
                      disabled
                      maxLength={20}
                      value={ieDestinatario}
                      onChange={e => setIeDestinatario(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="NF-e Referenciadas">
              <div className="estamparia-pedido-item">
                <DataTable
                  value={listaNfeRef}
                  size="small"
                  stripedRows
                  responsiveLayout="scroll"
                  sortField="item"
                  selectionMode="single"
                  selection={selectedNfeRef}
                  onSelectionChange={e => setSelectedNfeRef(e.value)}
                  selectableRows
                  dataKey="item"
                  emptyMessage="Nenhum Item cadastrado"
                >
                  {fillColumns(columnsSetupNFeRef)}
                </DataTable>
              </div>
              <br />
              <div className="row form-elo">
                <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    disabled={disableEdicaoEnviada}
                    type="button"
                    title="Inclusão Doc. Referenciado"
                    className="btn btn-success"
                    style={{ borderRadius: '15px' }}
                    onClick={() => {
                      setEditandoNfeRef(0);
                      limparCamposNfeRef();
                      setShowCadastroNfeRef(true);
                    }}
                  >
                    <i className="pi pi-plus"></i>
                  </button>
                  <button
                    disabled={disableEdicaoEnviada}
                    type="button"
                    title="Alterar Doc. Referenciado"
                    className="btn btn-warning"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleEditNfeRef(e)}
                  >
                    <i className="pi pi-pencil"></i>
                  </button>
                  <button
                    disabled={disableEdicaoEnviada}
                    type="button"
                    title="Cancelar Doc. Referenciado"
                    className="btn btn-danger"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleDeleteNfeRef(e)}
                  >
                    <i className="pi pi-trash"></i>
                  </button>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Informações de Carga">
            </TabPanel>
            <TabPanel header="Quantidades de Carga">
            </TabPanel>
            <TabPanel header="Serviços (Valores)">
            </TabPanel>
            <TabPanel header="Cobrança">
            </TabPanel>
            <TabPanel header="Rodoviário">
            </TabPanel>
            <TabPanel header="Outras Informações">
            </TabPanel>
          </TabView>
        </div>

        <div className="row float-end">
          <div className="col-lg-12 ">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
