import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

import moment from 'moment';

import * as actions from '../../../../store/modules/auth/actions';

import { displayErrors, formataDataMoment } from '../../../../util/diversos';
import { Input155px } from '../../../../styles/inputGroup';

import Card from '../../../../components/card';
import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Loading from '../../../loading';

export default function CadastroEstampariaCaixaReforco({ payloadCadastro, childToParentCadastro }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);

  const [historico, setHistorico] = useState('');
  const [valor, setValor] = useState(0);
  const [idDinheiro, setIdDinheiro] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let newTitle = 'Reforço de Caixa (Estamparia) - Cadastro';
    if (id) {
      newTitle = 'Reforço de Caixa (Estamparia) - Edição';
    }

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getIdDinheiro() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const { data } = await axiosAdmin.get(`/meiopagamento?idUsuario=${idUsuario}&descricao=DINHEIRO&exact=S`);

        if (data.length > 0) {
          setIdDinheiro(get(data[0], 'id', null));
        }
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getIdDinheiro();

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/estampariacaixa/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultaestampariacaixareforco');
          toast.error('Acesso negado');
        }

        setHistorico(data.historico);
        setValor(data.valor);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const idOperador = get(store.getState().auth, 'idOperador', null);

      let payloadDataTableList = {};

      const _data = formataDataMoment(moment(), '-', true);

      const shippingData = {
        idUsuario,
        data: _data,
        operador: idOperador,
        idEstampariaPedido: null,
        tipoRegistroCaixa: 'REFORCO',
        meioPagamento: idDinheiro,
        historico,
        valor,
      };

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/estampariacaixa/${id}`, shippingData), 'data', {});
        toast.success('Reforço de Caixa atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/estampariacaixa', shippingData), 'data', {});
        toast.success('Reforço de Caixa cadastrado com sucesso');
      }

      childToParentCadastro(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentCadastro();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!historico) {
      formErros = true;
      toast.error('Histórico deve ser informado');
    }
    if (valor <= 0) {
      formErros = true;
      toast.error('Valor deve ser informado');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id ? 'Reforço de Caixa - Alteração' : 'Reforço de Caixa - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>{titulo()}</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-8">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Histórico
              </Input155px>
              <InputText
                autoFocus
                // maxLength={30}
                value={historico}
                onChange={e => setHistorico(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor
              </Input155px>
              <InputNumber
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valor}
                onValueChange={e => setValor(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
