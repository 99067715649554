import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { get, orderBy } from 'lodash';
import dateFormat from 'dateformat';
import { toast } from 'react-toastify';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { Input100px } from '../../../styles/inputGroup';
import Card from '../../../components/card';
import Loading from '../../loading';

import * as actions from '../../../store/modules/auth/actions';

import { formataMoeda, displayErrors } from '../../../util/diversos';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';

import { obterListaTipoMovimentacao } from '../../../services/tipoMovimentacaoService';

export default function RelatorioNFe() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const idUsuario = get(store.getState().auth.user, 'id', 0);

  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [tipoMovimentacao, setTipoMovimentacao] = useState('SAIDA');
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [tipoModeloRelatorio, setTipoModeloRelatorio] = useState('PADRAO');

  const [listaEmpresas, setListaEmpresas] = useState([]);
  const listaTipoMovimentacao = obterListaTipoMovimentacao();

  const [listaTipoModeloRelatorio] = useState([
    { label: 'Padrão', value: 'PADRAO' },
    { label: 'Sintegra', value: 'SINTEGRA' },
  ]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const headRows = [
    {
      data: 'Emissão',
      tipo: 'Tipo', // emissao propria true = saida
      numero: 'Número',
      modelo: 'Modelo',
      serie: 'Série',
      cnpj: 'CNPJ/CPF',
      razaoSocial: 'Razão Social',
      totalNf: 'Total Nota',
    },
  ];

  const headRowsSintegra = [
    {
      modelo: 'Mod',
      tipo: 'E/S', // emissao propria true = saida
      data: 'Data',
      numero: 'Número',
      cnpj: 'CNPJ/CPF',
      cfop: 'CFOP',
      aliq: 'Aliq',
      bcIcms: 'B.C.ICMS',
      vIcms: 'Vr.ICMS',
      vIsento: 'V.Isento',
      vOutras: 'V.Outras',
      vIcmsSt: 'Valor ST',
      vIpi: 'Vlr. IPI',
      totalNf: 'Total Nota',
    },
  ];

  // eslint-disable-next-line no-shadow
  const getData = async (searchFilter, filtrarCnpjEmpresa, page, size, tipoMovimentacao, img) => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    try {
      setIsLoading(true);

      if (findDataInicial == null && findDataFinal == null) {
        toast.info('Datas devem ser informadas.');
        return;
      }

      if (findDataInicial > findDataFinal) {
        toast.info('Data final anterior a data inicial.');
        return;
      }

      let params = `/nfe?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy');
        const _dataFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy');

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      if (tipoMovimentacao === 'SAIDA') {
        params = `${params}&emissaoPropria=true`;
      }

      const response = await axiosAdmin.get(params);

      if (response.data <= 0) {
        toast.info('Não há registros a serem exibidos.');
        return;
      }

      let razaoSocial = '';
      listaEmpresas.forEach(element => {
        if (element.value === cnpjEmpresa) {
          // eslint-disable-next-line no-unused-expressions
          (element.label !== '') ? razaoSocial = element.label : '';
        }
      });

      let data = {
        data: '',
        tipo: '',
        numero: '',
        modelo: '',
        serie: '',
        cnpj: '',
        razaoSocial: '',
        totalNf: '',
      };

      const result = [];

      // preencher os dados do relatorio
      let valorTotalNotasFiscais = 0;
      let contadorNotas = 1;
      response.data.forEach(element => {
        data.data = dateFormat(element.data, 'dd/mm/yyyy');

        if (tipoModeloRelatorio === 'PADRAO') {
          data.tipo = element.emissaoPropria ? 'Saída' : 'Entrada';
        } else {
          data.tipo = element.emissaoPropria ? 'S' : 'E';
        }

        data.numero = element.numeroNf;
        data.modelo = element.mod;
        data.serie = element.serie;
        data.cnpj = element.participante.cnpj;
        data.razaoSocial = element.participante.razaoSocial;
        data.totalNf = formataMoeda(element.totalVNF);

        if (tipoModeloRelatorio === 'SINTEGRA') {
          let cfopAtual = '';
          let somaBcIcms = 0;
          let somaVIcms = 0;
          let somaVIsento = 0;
          let somaVOutras = 0;
          let somaVIcmsSt = 0;
          let somaVIpi = 0;
          let somaTotaNf = 0;

          // preciso order a lista de item por cfop e ...
          const listaItens = orderBy(element.listaNFeItem, 'cfop', 'ASC');
          let contadorItens = 1;
          listaItens.forEach(item => {
            if (contadorItens > 1) {
              data.modelo = '';
              data.tipo = ''; // emissao propria true = saida
              data.data = '';
              data.numero = '';
              data.cnpj = '';
            }

            let cfop = '';
            if (element.emissaoPropria) {
              cfop = (element.idDest === 'INTERNA') ? '5' + item.cfop : '6' + item.cfop;
            } else {
              cfop = (element.idDest === 'INTERNA' && !element.emissaoPropria) ? '1' + item.cfop : '2' + item.cfop;
            }

            if (item.picms > 0) { data.aliq = item.picms; } else { data.aliq = '0'; }

            if (item.vbc > 0) { data.bcIcms = item.vbc; } else { data.bcIcms = '0'; }
            if (item.vicms > 0) { data.vIcms = item.vicms; } else { data.vIcms = '0'; }
            if (item.visento > 0) { data.vIsento = item.visento; } else { data.vIsento = '0'; }
            if (item.voutro > 0) { data.vOutras = item.voutro; } else { data.vOutras = '0'; }
            if (item.vicmsSt > 0) { data.vIcmsSt = item.vicmsSt; } else { data.vIcmsSt = '0'; }
            if (item.ipiVIpi > 0) { data.vIpi = item.ipiVIpi; } else { data.vIpi = '0'; }

            data.totalNf = formataMoeda(item.vprod);

            data.cfop = cfop;

            somaBcIcms = somaBcIcms + +data.bcIcms;
            somaVIcms = somaVIcms + +data.vIcms;
            somaVIsento = somaVIsento + +data.vIsento;
            somaVOutras = somaVOutras + +data.vOutras;
            somaVIcmsSt = somaVIcmsSt + +data.vIcmsSt;
            somaVIpi = somaVIpi + +data.vIpi;
            somaTotaNf = somaTotaNf + +item.vprod;

            if (item.cfop !== cfopAtual) {
              cfopAtual = item.cfop;
              result.push({ ...data });
            } else {
              // mostrar soma dos itens de mesmo cfop
              somaBcIcms = somaBcIcms + +data.bcIcms;
              somaVIcms = somaVIcms + +data.vIcms;
              somaVIsento = somaVIsento + +data.vIsento;
              somaVOutras = somaVOutras + +data.vOutras;
              somaVIcmsSt = somaVIcmsSt + +data.vIcmsSt;
              somaVIpi = somaVIpi + +data.vIpi;
              somaTotaNf = somaTotaNf + +item.vprod;
            }

            contadorItens++;
          });

          if (listaItens.length > 1) {
            data.cnpj = 'Totais ->';
            data.cfop = '';
            data.aliq = '';
            data.bcIcms = somaBcIcms;
            data.vIcms = somaVIcms;
            data.vIsento = somaVIsento;
            data.vOutras = somaVOutras;
            data.vIcmsSt = somaVIcmsSt;
            data.vIpi = somaVIpi;
            data.totalNf = formataMoeda(somaTotaNf);
            result.push({ ...data });
          }

          data = {
            data: '',
            tipo: '',
            numero: '',
            modelo: '',
            serie: '',
            cnpj: '',
            razaoSocial: '',
            totalNf: '',
          };
        } else {
          result.push({ ...data });
        }

        if (tipoModeloRelatorio === 'SINTEGRA') {
          if (contadorNotas < response.data.length) {
            result.push({ ...data });
          }
        }

        valorTotalNotasFiscais = valorTotalNotasFiscais + +element.totalVNF;
        contadorNotas++;
      });

      // linha em branco
      data = {
        data: '',
        tipo: '',
        numero: '',
        modelo: '',
        serie: '',
        cnpj: '',
        razaoSocial: '',
        totalNf: '',
      };
      result.push({ ...data });

      data = {
        data: '',
        tipo: '',
        numero: '',
        modelo: '',
        serie: '',
        cnpj: '',
        razaoSocial: '',
        totalNf: formataMoeda(valorTotalNotasFiscais),
      };
      result.push({ ...data });

      doc.autoTable({
        styles: { fontSize: 8 },
        margin: { top: 38, left: 5, right: 5 },
        columnStyles: tipoModeloRelatorio === 'PADRAO' ? {
          0: { cellWidth: 19 },
          1: { cellWidth: 12 },
          2: { cellWidth: 15 },
          3: { cellWidth: 15 },
          4: { cellWidth: 12 },
          5: { cellWidth: 29 },
          7: { cellWidth: 25 },
        } : {
          0: { cellWidth: 9 },
        },
        theme: 'grid',
        head: tipoModeloRelatorio === 'PADRAO' ? headRows : headRowsSintegra,
        body: result,
        didDrawPage: dados => {
          // Header
          doc.rect(5, 5, 200, 30);
          doc.addImage(img, 'JPEG', 9, 12, 25, 15);
          doc.setFontSize(20);
          doc.text('Relatório de Notas Fiscais', 65, 15);

          doc.setFontSize(10);
          doc.text('Empresa:', 50, 32);
          doc.text(razaoSocial, 66, 32);

          doc.setFontSize(10);
          doc.text(dateFormat(new Date(), 'dd/mm/yyyy'), 185, 32);

          // footer
          const { pageSize } = doc.internal;
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(`Pag ${doc.internal.getNumberOfPages()}`, dados.settings.margin.right, pageHeight - 8);
          doc.text('Elo Informática Barbacena LTDA', 82, pageHeight - 8);
          const periodo = new Date();
          doc.text('Impresso: ', 172, pageHeight - 8);
          doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 189, pageHeight - 8);
        },
      });
      window.open(doc.output('bloburl'));
    } catch (e) {
      toast.warn('Não foi possível gerar a impressão, ' + e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function verificarSeMultiEmpresa() {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        const _cnpjConsultaPrevisaoFinanceiraCjpEmpresa = store.getState().auth.consultaPrevisaoFinanceiraCnpjEmpresa;

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '') {
          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            const consultaPrevisaoFinanceiraCnpjEmpresa = _cnpjEmpresaStore;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            cnpjPreenchido = true;
          }
        }

        _listaEmpresas.forEach(element => {
          if (!cnpjPreenchido && (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '')) {
            setCnpjEmpresa(element.cnpj);
            const consultaPrevisaoFinanceiraCnpjEmpresa = element.cnpj;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            _cnpjEmpresaStore = element.cnpj;
            cnpjPreenchido = true;
          }

          const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

          listDropdown.push({ label: nomeEmpresa, value: element.cnpj, serie: element.nfeSerie, uf: element.uf });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (!cnpjPreenchido && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== null && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== '') {
          setCnpjEmpresa(_cnpjConsultaPrevisaoFinanceiraCjpEmpresa);
          _cnpjEmpresaStore = _cnpjConsultaPrevisaoFinanceiraCjpEmpresa;
        }
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    if (findDataInicial === null) {
      setFindDataInicial(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial));
      setFindDataFinal(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal));

      const _dataInicial = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial);
      const _dataFinal = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal);

      const searchFilter = { findDataInicial: _dataInicial, findDataFinal: _dataFinal };

      verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0), searchFilter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    const loadImage = async () => {
      let config = '';
      try {
        config = await axiosAdmin.get(`/configuracaosistema?idUsuario=${idUsuario}`);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }

      const img = new Image();
      img.src = `/logotipos/${config.data.urlLogotipo}`;
      await img.decode().then(() => {

      }).catch(() => {
        img.src = 'logo.png';
      });
      getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows, tipoMovimentacao, img);
    };
    loadImage();
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}> Relatório de Notas Fiscais </h6>
        </div>

        <div className="row form-elo">
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data inicial
              </Input100px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={findDataInicial}
                onChange={(e) => setFindDataInicial(e.value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data final
              </Input100px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={findDataFinal}
                onChange={(e) => setFindDataFinal(e.value)}
              />
            </div>
          </div>
          <div className="col-lg-2">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Tipo
              </Input100px>
              <Dropdown
                options={listaTipoMovimentacao}
                value={tipoMovimentacao}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipoMovimentacao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Modelo
              </Input100px>
              <Dropdown
                options={listaTipoModeloRelatorio}
                value={tipoModeloRelatorio}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipoModeloRelatorio(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-1">
            <button
              type="button"
              title="Imprimir"
              className="btn btn-success"
              style={{ borderRadius: '15px' }}
              onClick={handleFind}
            >
              <i className="pi pi-print"></i>
            </button>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-lg-6">
            {listaEmpresas.length > 1 ? (
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Empresa
                </Input100px>
                <Dropdown
                  options={listaEmpresas}
                  value={cnpjEmpresa}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => {
                    setCnpjEmpresa(e.target.value);
                  }}
                />
              </div>
            ) : ('')}
          </div>
        </div>
      </Card>
    </>
  );
}
