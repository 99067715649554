import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { toast } from 'react-toastify';
import { Column } from 'primereact/column';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Chart } from 'primereact/chart';
import { addLocale } from 'primereact/api';
import { get, orderBy } from 'lodash';

import dateFormat from 'dateformat';

import * as actions from '../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors, formataMoeda } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';
import { Input100px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';

export default function PainelFinanceiro() {
  const [isLoading, setIsLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [gridContaBancaria, setGridContaBancaria] = useState(0);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [dataTableList, setDataTableList] = useState([]);
  const [filtroAtualizado, setFiltroAtualizado] = useState(null);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [aReceber, setAReceber] = useState([]);
  const [aPagar, setAPagar] = useState([]);
  const [contaBancariaList, setContaBancariaList] = useState([]);
  const [columnsSetup] = useState([
    { field: 'operacaoFinanceira', header: 'Operação', sort: false },
    { field: 'dataEmissao', header: 'Emissão', sort: true },
    { field: 'dataVencimento', header: 'Vencimento', sort: true },
    { field: 'centroCusto.codigo', header: 'Centro Custo', sort: false },
    { field: 'meioPagamento.descricao', header: 'Meio Pagamento', sort: false },
    { field: 'nomeParticipante', header: 'Participante', sort: false },
    { field: 'valorTotal', header: 'Valor Total', sort: false },
    { field: 'valorAberto', header: 'Valor Aberto', sort: false },
    { field: 'previsao', header: 'Previsão', sort: false },
  ]);
  const [columnsAReceber, setColumnsAReceber] = useState([]);
  const [columnsAPagar, setColumnsAPagar] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });
  const [chartData, setChartData] = useState({});
  const [lightOptions] = useState({
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        labels: {
          color: '#495057',
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#495057',
        },
        grid: {
          color: '#ebedef',
        },
      },
      y: {
        ticks: {
          color: '#495057',
        },
        grid: {
          color: '#ebedef',
        },
      },
    },
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getData(searchFilter, filtrarCnpjEmpresa, page, size) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/financeiro/search?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy');

        const _dataFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy');

        params = `${params}&dataVencimentoInicial=${_dataInicial}&dataVencimentoFinal=${_dataFinal}`;
      }

      const blnPrevisao = true;
      params = `${params}&previsao=${blnPrevisao}`;

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getReceber(filtrarCnpjEmpresa) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/financeiro/dashboardreceber?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      const response = await axiosAdmin.get(params);

      const _dataReceber = response.data;

      const _primeiroItem = _dataReceber.shift();

      const _columnsAReceber = [];
      _columnsAReceber.push({ field: 'descricao', header: _primeiroItem.descricao });
      _columnsAReceber.push({ field: 'valor', header: formataMoeda(_primeiroItem.valor) });

      setAReceber(response.data);
      setColumnsAReceber(_columnsAReceber);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getPagar(filtrarCnpjEmpresa) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/financeiro/dashboardpagar?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      const response = await axiosAdmin.get(params);

      const _dataPagar = response.data;

      const _primeiroItem = _dataPagar.shift();

      const _columnsAPagar = [];
      _columnsAPagar.push({ field: 'descricao', header: _primeiroItem.descricao });
      _columnsAPagar.push({ field: 'valor', header: formataMoeda(_primeiroItem.valor) });

      setAPagar(response.data);
      setColumnsAPagar(_columnsAPagar);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getSaldoLivroCaixa(filtrarCnpjEmpresa) {
    let saldoRetorno = 0;
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);
      const params = `/livrocaixa/saldo?idUsuario=${idUsuario}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      const response = await axiosAdmin.get(params);

      saldoRetorno = response.data;
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
    return saldoRetorno;
  }

  async function getContaBancaria(saldoLivroCaixa) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const params = `/contabancaria?idUsuario=${idUsuario}`;

      const response = await axiosAdmin.get(params);

      const _responseData = response.data;
      while (_responseData.length < 4) {
        _responseData.push({ bancoDescricao: '', conta: '', valor: null });
      }

      _responseData.unshift({ bancoDescricao: 'LIVRO CAIXA', conta: '', saldo: saldoLivroCaixa });

      setContaBancariaList(_responseData);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getFluxoCaixa(searchFilter, filtrarCnpjEmpresa) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/fluxocaixa?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy');
        const _dataFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy');

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      const response = await axiosAdmin.get(params);

      const arrayLabels = [];
      const arraySaldo = [];
      const arrayCredito = [];
      const arrayDebito = [];

      response.data.forEach(element => {
        arrayLabels.push(dateFormat(element.data, 'dd/mm/yyyy'));
        arraySaldo.push(element.saldo);
        arrayCredito.push(element.valorCredito);
        arrayDebito.push(element.valorDebito);
      });

      setChartData({
        labels: arrayLabels,
        datasets: [{
          type: 'line',
          label: 'Saldo',
          borderColor: '#42A5F5',
          borderWidth: 2,
          fill: false,
          tension: 0.4,
          data: arraySaldo,
        }, {
          type: 'bar',
          label: 'Crédito',
          backgroundColor: '#66BB6A',
          data: arrayCredito,
          borderColor: 'white',
          borderWidth: 2,
        }, {
          type: 'bar',
          label: 'Débito',
          backgroundColor: '#ff3333',
          data: arrayDebito,
        }],
      });
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
    getFluxoCaixa(searchFilter, cnpjEmpresa);

    getSaldoLivroCaixa(cnpjEmpresa).then(resposta => {
      getContaBancaria(resposta);
    });
  };

  function tamanhoGridDashboard() {
    let height = 0;

    if (document.getElementById('dataTableReceber')) {
      height = document.getElementById('dataTableReceber').clientHeight;
    }

    return height;
  }

  function debounce(fn, ms) {
    let timer;
    // eslint-disable-next-line no-unused-vars
    return _ => {
      clearTimeout(timer);
      // eslint-disable-next-line no-shadow, no-unused-vars
      timer = setTimeout(_ => {
        timer = null;
        // eslint-disable-next-line prefer-rest-params
        fn.apply(this, arguments);
      }, ms);
    };
  }

  useEffect(() => {
    const debouncedHandleResize = debounce(() => {
      setGridContaBancaria(tamanhoGridDashboard());
    }, 100);

    setGridContaBancaria(tamanhoGridDashboard());

    window.addEventListener('resize', debouncedHandleResize);

    // eslint-disable-next-line no-unused-vars
    return _ => { window.removeEventListener('resize', debouncedHandleResize); };
  }, [contaBancariaList]);

  useEffect(() => {
    async function verificarSeMultiEmpresa(idUsuario, searchFilter) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        const _cnpjConsultaLivroCaixaCnpjEmpresa = store.getState().auth.consultaLivroCaixaCnpjEmpresa;

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjConsultaLivroCaixaCnpjEmpresa === null || _cnpjConsultaLivroCaixaCnpjEmpresa === '') {
          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            const consultaLivroCaixaCnpjEmpresa = _cnpjEmpresaStore;
            dispatch(actions.consultaLivroCaixaCnpjEmpresaChange({ consultaLivroCaixaCnpjEmpresa }));
            cnpjPreenchido = true;
          }
        }

        _listaEmpresas.forEach(element => {
          if (element.ativo) {
            if (!cnpjPreenchido && (_cnpjConsultaLivroCaixaCnpjEmpresa === null || _cnpjConsultaLivroCaixaCnpjEmpresa === '')) {
              setCnpjEmpresa(element.cnpj);
              const consultaLivroCaixaCnpjEmpresa = element.cnpj;
              dispatch(actions.consultaLivroCaixaCnpjEmpresaChange({ consultaLivroCaixaCnpjEmpresa }));
              _cnpjEmpresaStore = element.cnpj;
              cnpjPreenchido = true;
            }

            const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

            listDropdown.push({ label: nomeEmpresa, value: element.cnpj, id: element.id });
          }
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (!cnpjPreenchido && _cnpjConsultaLivroCaixaCnpjEmpresa !== null && _cnpjConsultaLivroCaixaCnpjEmpresa !== '') {
          setCnpjEmpresa(_cnpjConsultaLivroCaixaCnpjEmpresa);
          _cnpjEmpresaStore = _cnpjConsultaLivroCaixaCnpjEmpresa;
        }

        getData(searchFilter, _cnpjEmpresaStore, 0, lazyParams.rows);
        getFluxoCaixa(searchFilter, _cnpjEmpresaStore);
        getReceber(_cnpjEmpresaStore);
        getPagar(_cnpjEmpresaStore);

        getSaldoLivroCaixa(_cnpjEmpresaStore).then(resposta => {
          getContaBancaria(resposta);
        });
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    if (findDataInicial === null) {
      const _dataInicial = new Date();
      const _dataFinal = new Date(new Date().getTime() + (30 * 24 * 60 * 60 * 1000));

      setFindDataInicial(_dataInicial);
      setFindDataFinal(_dataFinal);

      const searchFilter = { findDataInicial: _dataInicial, findDataFinal: _dataFinal };

      verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0), searchFilter);
    }

    if (filtroAtualizado) {
      setFiltroAtualizado(false);
      handleFind();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filtroAtualizado]);

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findDataInicial, findDataFinal };

    getData(searchFilter, cnpjEmpresa, event.page, event.rows);
  };

  function formataSaldo(value) {
    let _value = value;

    if (!value) {
      return ' ';
    }

    try {
      _value = value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    } catch (err) {
      _value = 'R$ 0,00';
    }

    return _value;
  }

  const saldoBodyTemplate = (rowData) => formataSaldo(rowData.saldo);

  async function licencaValida(empresaId) {
    // eslint-disable-next-line consistent-return
    const retorno = await axiosAdmin.get(`/empresa/verificardatalicenca/${empresaId}`);
    return retorno.data;
  }

  // eslint-disable-next-line consistent-return
  async function handleCnpjEmpresa(e) {
    let empresaId = 0;

    listaEmpresas.forEach(element => {
      if (element.value === e.target.value) {
        // eslint-disable-next-line no-unused-expressions
        empresaId = element.id;
      }
    });

    const isLicencaValida = await licencaValida(empresaId);
    if (!isLicencaValida) {
      toast.info('Cliente com licença expirada. Efetuar pagamento mensalidade.');
      // navigate('/pagamento');
      return false;
    }

    setCnpjEmpresa(e.target.value);

    const consultaLivroCaixaCnpjEmpresa = e.target.value;

    dispatch(actions.consultaLivroCaixaCnpjEmpresaChange({ consultaLivroCaixaCnpjEmpresa }));
  }

  return (
    <>
      <Loading isLoading={isLoading} />

      <Card>
        <div style={{ paddingBottom: '5px' }}>
          {/* <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              PAINEL FINANCEIRO
            </h6>
          </div> */}
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data inicial
                </Input100px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => setFindDataInicial(e.value)}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data final
                </Input100px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => setFindDataFinal(e.value)}
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Empresa
                </Input100px>
                <Dropdown
                  options={listaEmpresas}
                  value={cnpjEmpresa}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => handleCnpjEmpresa(e)}
                />
              </div>
            </div>
            <div className="col-lg-1" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
            </div>
          </div>
          <div style={{ paddingBottom: '15px' }}></div>
          <div className="row align-items-center">
            <div className="col-lg-4">
              <DataTable
                id="dataTableReceber"
                size="small"
                showGridlines
                value={aReceber}
                responsiveLayout="scroll"
              >
                {fillColumns(columnsAReceber)}
              </DataTable>
            </div>
            <div className="col-lg-4">
              <DataTable
                size="small"
                showGridlines
                value={aPagar}
                responsiveLayout="scroll"
              >
                {fillColumns(columnsAPagar)}
              </DataTable>
            </div>
            <div className="col-lg-4">
              <DataTable
                size="small"
                showGridlines
                rowHeight={50}
                value={contaBancariaList}
                responsiveLayout="scroll"
                scrollHeight={`${gridContaBancaria}px`}
              >
                <Column field="bancoDescricao" header="Banco" />
                <Column field="conta" header="Conta" />
                <Column field="saldo" header="Saldo" body={saldoBodyTemplate} />
              </DataTable>
            </div>
          </div>
          <div className="row align-items-center" style={{ marginTop: '10px' }}>
          </div>
          <div className="row align-items-center">
            <Chart type="bar" data={chartData} options={lightOptions} />
          </div>
        </div>
        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 20, 50]}
          >
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>
      </Card>
    </>
  );
}
