import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Column } from 'primereact/column';
import { InputNumber } from 'primereact/inputnumber';
import { addLocale } from 'primereact/api';
import { get, orderBy } from 'lodash';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors } from '../../../../util/diversos';
import { Input155px } from '../../../../styles/inputGroup';
import { fillColumns } from '../../../../components/table';

import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Card from '../../../../components/card';
import Loading from '../../../loading';
import PdfEstampariaOrdemProducao from '../../relatorio/pdfEstampariaOrdemProducao';

export default function GerarEstampariaOrdemProducao() {
  const [isLoading, setIsLoading] = useState(false);
  const [novoNumero, setNovoNumero] = useState(0);
  const [novoNumeroId, setNovoNumeroId] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataTableList, setDataTableList] = useState([]);
  const [agregadosTableList, setAgregadosTableList] = useState([]);
  const [filtroAtualizado, setFiltroAtualizado] = useState(null);
  const [selectedItens, setSelectedItens] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showTelaAgregados, setShowTelaAgregados] = useState(false);
  const [showNovoNumero, setShowNovoNumero] = useState(false);
  const [showPrintOrdemProducao, setShowPrintOrdemProducao] = useState(false);
  const [payloadPrint, setPayloadPrint] = useState({});
  const [columnsSetup] = useState([
    { field: 'data', header: 'Data', sort: true },
    { field: 'nomePedido', header: 'Nome do Pedido', sort: true },
    { field: 'cliente.razaoSocial', header: 'Razão Social do Cliente', sort: false },
    { field: 'valorTotal', header: 'Valor do Pedido', sort: false },
    { field: 'situacao', header: 'Situação', sort: false },
    { field: 'vendedor.nome', header: 'Vendedor', sort: false },
  ]);
  const [agregadosColumnsSetup] = useState([
    { field: 'numero', header: 'Nº OP', sort: true },
    // { field: 'item.produto.registro', header: 'Registro', sort: false },
    { field: 'item.produto.descricao', header: 'Descrição do Produto', sort: false },
    { field: 'item.quantidade', header: 'Quantidade', sort: false },
    { field: 'item.estampariaMalha.descricao', header: 'Malha', sort: false },
    { field: 'item.estampariaModelo.descricao', header: 'Modelo', sort: false },
    { field: 'item.estampariaTamanho.descricao', header: 'Tamanho', sort: false },
    { field: 'item.estampariaCor.descricao', header: 'Cor', sort: false },
    { field: 'item.estampariaBolso.descricao', header: 'Bolso', sort: false },
  ]);
  const [columnsSetupItemAcessorio] = useState([
    { field: 'estampariaAcessorio.descricao', header: 'Acessório', sort: true },
    { field: 'quantidade', header: 'Quantidade', sort: false },
    { field: 'valorAcrescimo', header: 'Valor Acréscimo', sort: false },
    { field: 'totalAcrescimo', header: 'Total Acréscimo', sort: false },
  ]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getData(page, size) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/estampariapedido/search?idUsuario=${idUsuario}&gerarOrdemProducao=true`;

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function agregarOrdemProducao() {
    try {
      setIsLoading(true);

      const id = get(selectedItens, 'id', 0);

      const { data } = await axiosAdmin.get(`/estampariapedido/agregar-ordem-producao/${id}`);

      const _listaItem = [];

      let contador = 0;

      data.forEach(element => {
        element.listaEstampariaPedidoItem.forEach(item => {
          contador = contador + 1;

          _listaItem.push({
            id: contador,
            numero: element.numero,
            item,
          });
        });
      });

      setAgregadosTableList(_listaItem);

      setShowTelaAgregados(true);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível gerar a Ordem de Produção');
    } finally {
      setIsLoading(false);
    }
  }

  async function enviarAgregadosOrdemProducao() {
    try {
      setIsLoading(true);

      const id = get(selectedItens, 'id', 0);

      const idOperador = get(store.getState().auth, 'idOperador', 0);

      const { data } = await axiosAdmin.put(`/estampariapedido/gerar-ordem-producao/${id}?idOperador=${idOperador}`, agregadosTableList);

      data.forEach(element => {
        setPayloadPrint({ id: element });
        setShowPrintOrdemProducao(true);
      });

      toast.success('Ordem de Produção gerada com sucesso');

      setShowTelaAgregados(false);

      getData(0, lazyParams.rows);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível gerar a Ordem de Produção');
    } finally {
      setIsLoading(false);
      setShowConfirm(false);
    }
  }

  const confirmGerarOrdemProducao = () => {
    enviarAgregadosOrdemProducao();

    setShowConfirm(false);
  };

  const cancelGerarOrdemProducao = () => {
    setShowConfirm(false);
  };

  const confirmDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmGerarOrdemProducao} />
      <Button label="Não" icon="pi pi-times" onClick={cancelGerarOrdemProducao} />
    </div>
  );

  const handleGerarOrdemProducao = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      agregarOrdemProducao();
    } else {
      toast.warning('Por favor, selecione o pedido para Gerar a Ordem de Produção');
    }
  };

  const onHideTelaAgregados = () => {
    setShowTelaAgregados(false);
  };

  const onHideNovoNumero = () => {
    setShowNovoNumero(false);
  };

  const handleAlterarNovoNumero = () => {
    const index = agregadosTableList.indexOf(agregadosTableList.find(element => element.id === novoNumeroId));

    const novaLista = agregadosTableList;

    novaLista[index].numero = novoNumero;

    setAgregadosTableList(novaLista);

    setShowNovoNumero(false);
  };

  useEffect(() => {
    async function verificarSeMultiEmpresa(idUsuario) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        await axiosAdmin.get(params);

        getData(0, lazyParams.rows);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0));

    if (filtroAtualizado) {
      setFiltroAtualizado(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filtroAtualizado]);

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    getData(event.page, event.rows);
  };

  function handleAlterarNumeroOP(rowData) {
    setNovoNumero(rowData.numero);

    setNovoNumeroId(rowData.id);

    setShowNovoNumero(true);
  }

  const alteraAgregadoTemplate = (rowData) => (
    <button
      type="button"
      title="Alterar o Número da Ordem Produção"
      className="btn btn-success"
      style={{ borderRadius: '15px' }}
      onClick={() => handleAlterarNumeroOP(rowData)}
    >
      <i className="pi pi-pencil"></i>
    </button>
  );

  const handleFocus = (event) => event.target.select();

  const headerTable = (title) => (
    <div className="table-header">
      {title}
    </div>
  );

  const allowExpansion = (rowData) => rowData.orders.length > 0;

  function rowExpansionTemplate(data) {
    let frenteSilk = '';
    let frenteSublimacao = '';
    let frenteBordado = '';

    let costasSilk = '';
    let costasSublimacao = '';
    let costasBordado = '';

    let mangaDireitaSilk = '';
    let mangaDireitaSublimacao = '';
    let mangaDireitaBordado = '';

    let mangaEsquerdaSilk = '';
    let mangaEsquerdaSublimacao = '';
    let mangaEsquerdaBordado = '';

    data.item.listaEstampariaPedidoTipoServico.forEach(tipoServicoElement => {
      if (tipoServicoElement.parteVestuario === 'FRENTE') {
        if (tipoServicoElement.silkar && tipoServicoElement.silk.descricao) { frenteSilk = tipoServicoElement.silk.descricao; }
        if (tipoServicoElement.sublimar && tipoServicoElement.sublimacao.descricao) { frenteSublimacao = tipoServicoElement.sublimacao.descricao; }
        if (tipoServicoElement.bordar && tipoServicoElement.bordado.descricao) { frenteBordado = tipoServicoElement.bordado.descricao; }
      } else if (tipoServicoElement.parteVestuario === 'COSTAS') {
        if (tipoServicoElement.silkar && tipoServicoElement.silk.descricao) { costasSilk = tipoServicoElement.silk.descricao; }
        if (tipoServicoElement.sublimar && tipoServicoElement.sublimacao.descricao) { costasSublimacao = tipoServicoElement.sublimacao.descricao; }
        if (tipoServicoElement.bordar && tipoServicoElement.bordado.descricao) { costasBordado = tipoServicoElement.bordado.descricao; }
      } else if (tipoServicoElement.parteVestuario === 'MANGADIREITA') {
        if (tipoServicoElement.silkar && tipoServicoElement.silk.descricao) { mangaDireitaSilk = tipoServicoElement.silk.descricao; }
        if (tipoServicoElement.sublimar && tipoServicoElement.sublimacao.descricao) { mangaDireitaSublimacao = tipoServicoElement.sublimacao.descricao; }
        if (tipoServicoElement.bordar && tipoServicoElement.bordado.descricao) { mangaDireitaBordado = tipoServicoElement.bordado.descricao; }
      } else if (tipoServicoElement.parteVestuario === 'MANGAESQUERDA') {
        if (tipoServicoElement.silkar && tipoServicoElement.silk.descricao) { mangaEsquerdaSilk = tipoServicoElement.silk.descricao; }
        if (tipoServicoElement.sublimar && tipoServicoElement.sublimacao.descricao) { mangaEsquerdaSublimacao = tipoServicoElement.sublimacao.descricao; }
        if (tipoServicoElement.bordar && tipoServicoElement.bordado.descricao) { mangaEsquerdaBordado = tipoServicoElement.bordado.descricao; }
      }
    });

    return (
      <div className="p-3">
        <table>
          <thead>
            <tr>
              <th scope="col">Manga</th>
              <th scope="col">Manga Cor</th>
              <th scope="col">Manga Acabamento</th>
              <th scope="col">Manga Acab. Cor</th>
              <th scope="col">Gola</th>
              <th scope="col">Gola Cor</th>
              <th scope="col">Gola Acabamento</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{get(data.item.estampariaManga, 'descricao', '')}</td>
              <td>{get(data.item.estampariaMangaCor, 'descricao', '')}</td>
              <td>{get(data.item.estampariaMangaAcabamento, 'descricao', '')}</td>
              <td>{get(data.item.estampariaMangaAcabamentoCor, 'descricao', '')}</td>
              <td>{get(data.item.estampariaGola, 'descricao', '')}</td>
              <td>{get(data.item.estampariaGolaCor, 'descricao', '')}</td>
              <td>{get(data.item.estampariaGolaAcabamento, 'descricao', '')}</td>
            </tr>
          </tbody>
          <br />
          <thead>
            <tr>
              <th scope="col"> </th>
              <th scope="col">Silk</th>
              <th scope="col">Sublimação</th>
              <th scope="col">Bordado</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="col">Frente</th>
              <td>{frenteSilk}</td>
              <td>{frenteSublimacao}</td>
              <td>{frenteBordado}</td>
            </tr>
            <tr>
              <th scope="col">Costas</th>
              <td>{costasSilk}</td>
              <td>{costasSublimacao}</td>
              <td>{costasBordado}</td>
            </tr>
            <tr>
              <th scope="col">Manga Direita</th>
              <td>{mangaDireitaSilk}</td>
              <td>{mangaDireitaSublimacao}</td>
              <td>{mangaDireitaBordado}</td>
            </tr>
            <tr>
              <th scope="col">Manga Esquerda</th>
              <td>{mangaEsquerdaSilk}</td>
              <td>{mangaEsquerdaSublimacao}</td>
              <td>{mangaEsquerdaBordado}</td>
            </tr>
          </tbody>
        </table>
        <br />
        {(data.item.listaEstampariaPedidoItemAcessorio.length > 0) ? (
          <div className="estamparia-pedido-item-acessorio">
            <DataTable
              value={data.item.listaEstampariaPedidoItemAcessorio}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              emptyMessage="Nenhum acessório"
            >
              {fillColumns(columnsSetupItemAcessorio)}
            </DataTable>
          </div>
        ) : ('Sem acessório') }
      </div>
    );
  }

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-6">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>Número da Ordem de Produção</h6>
            </div>
          </div>
          <div className="col-lg-6">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                type="button"
                className="btn btn-success"
                onClick={() => handleAlterarNovoNumero()}
              >
                <i className="pi pi-check"></i>
                Alterar
              </button>
              <button
                onClick={() => onHideNovoNumero()}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const onHidePrintOrdemProducao = () => {
    setShowPrintOrdemProducao(false);
  };

  const finishPrintOrdemProducao = () => {
    setShowPrintOrdemProducao(false);
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>
            PEDIDOS AGUARDANDO ORDEM DE PRODUÇÃO
          </h6>
        </div>

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>

        <br />

        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div className="row align-items-center">
            <div className="col-lg-12">
              <button
                type="button"
                title="Gerar Ordem de Produção"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleGerarOrdemProducao(e)}
              >
                <i className="pi pi-upload"> Gerar Ordem de Produção </i>
              </button>
            </div>
          </div>
          <div className="row align-items-center" style={{ marginTop: '10px' }}>
          </div>
        </div>

        <Dialog
          header=""
          visible={showTelaAgregados}
          style={{ width: '98vw' }}
          className="dialog-cadastro"
          onHide={() => onHideTelaAgregados()}
        >
          <div className="col-12" style={{ paddingTop: '30px' }}>
            <DataTable
              header={headerTable('Agregação de Ítens para a Ordem de Produção')}
              value={agregadosTableList}
              expandedRows={expandedRows}
              onRowToggle={(e) => setExpandedRows(e.data)}
              // eslint-disable-next-line react/jsx-no-bind
              rowExpansionTemplate={rowExpansionTemplate}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              sortField={sortField}
              sortOrder={sortOrder}
              onSort={onSort}
              editMode="cell"
              dataKey="id"
              paginator
              rows={50}
              emptyMessage="Nenhum registro a ser exibido"
              paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
              currentPageReportTemplate="{currentPage} de {totalPages}"
              rowsPerPageOptions={[10, 25, 50]}
            >
              <Column expander={allowExpansion} style={{ width: '5rem' }} />
              {fillColumns(agregadosColumnsSetup)}
              <Column header=" " body={alteraAgregadoTemplate}></Column>
            </DataTable>
          </div>
          <br />
          <div className="col-12">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <button
                    type="button"
                    title="Gerar Ordem de Produção"
                    className="btn btn-success"
                    style={{ borderRadius: '15px' }}
                    onClick={() => setShowConfirm(true)}
                  >
                    <i className="pi pi-check"> Gerar Ordem de Produção </i>
                  </button>
                  &nbsp;&nbsp;&nbsp;
                  <button
                    onClick={() => onHideTelaAgregados()}
                    type="button"
                    style={{ borderRadius: '15px' }}
                    className="btn btn-danger"
                  >
                    <i className="pi pi-times"></i>
                    Cancelar
                  </button>
                </div>
              </div>
              <div className="row align-items-center" style={{ marginTop: '10px' }}>
              </div>
            </div>
          </div>
          <br />
        </Dialog>

        <Dialog
          header=""
          visible={showNovoNumero}
          style={{ width: '70vw' }}
          className="dialog-cadastro"
          onHide={() => onHideNovoNumero()}
        >
          <div className="row form-elo" style={{ paddingTop: '30px' }}>
            { botoesFinalizar() }
          </div>
          <div className="row form-elo">
            <div className="col-6">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Número
                </Input155px>
                <InputNumber
                  autoFocus
                  min={1}
                  value={novoNumero}
                  onValueChange={e => setNovoNumero(e.target.value)}
                  onFocus={handleFocus}
                />
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          header="Confirmação"
          visible={showConfirm}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirm(false)}
        >
          <p>
            Deseja realmente gerar a Ordem de Produção?
          </p>
        </Dialog>

        <Dialog
          header=""
          visible={showPrintOrdemProducao}
          style={{ width: '98vw' }}
          onHide={() => onHidePrintOrdemProducao()}
          className="dialog-cadastro"
        >
          <PdfEstampariaOrdemProducao payloadPrint={payloadPrint} finishPrintOrdemProducao={finishPrintOrdemProducao} />
        </Dialog>
      </Card>
    </>
  );
}
