export function obterListaModalidadeFrete() {
  return (
    [
      { label: 'Contratação do Frete por conta do Remetente (CIF)', value: 'CIF' },
      { label: 'Contratação do Frete por conta do destinatário/remetente (FOB)', value: 'FOB' },
      { label: 'Contratação do Frete por conta de terceiros', value: 'TERCEIROS' },
      { label: 'Transporte próprio por conta do remetente', value: 'PROPRIOREMETENTE' },
      { label: 'Transporte próprio por conta do destinatário', value: 'PROPRIODESTINATARIO' },
      { label: 'Sem Ocorrência de transporte', value: 'SEMTRANSPORTE' },
    ]
  );
}
