import React from 'react';

import { Column } from 'primereact/column';
import { get } from 'lodash';

import { formataCnpjCpf, formataDataArray, formataMoeda } from '../util/diversos';

function setupQtdeDeposito(rowData) {
  const { listaEstoques } = rowData;

  if (listaEstoques.length === 1) {
    return get(listaEstoques[0], 'qtdeDeposito', 0);
  }

  return 0;
}

function setupQtdePrateleira(rowData) {
  const { listaEstoques } = rowData;

  if (listaEstoques.length === 1) {
    return get(listaEstoques[0], 'qtdePrateleira', 0);
  }

  return 0;
}

function setupBoolean(valorBooleano) {
  return valorBooleano ? 'Sim' : 'Não';
}

function setupMovimentacaoBancaria(movimentacaoBancaria, livroCaixa) {
  if (movimentacaoBancaria !== null && movimentacaoBancaria.id > 0) {
    return movimentacaoBancaria.contaBancaria.conta;
  } else if (livroCaixa !== null && livroCaixa.id > 0) {
    return 'Livro Caixa';
  } else {
    return '';
  }
}

const formataBooleano = (valorBooleano) => {
  if (valorBooleano === null || valorBooleano <= 0) {
    return 'Não';
  } else {
    return 'Sim';
  }
};

function chavePorValor(obj, val) {
  let _valueReturn = null;

  // eslint-disable-next-line consistent-return
  Object.keys(obj).forEach((item) => {
    if (item === val) {
      _valueReturn = obj[item];
      return _valueReturn;
    }
  });

  return _valueReturn;
}

function substituiTracoPorBarra(data) {
  return data.replaceAll('-', '/');
}

const onMask = (col) => {
  if (col.booleano === true) {
    return (rowData) => formataBooleano(chavePorValor(rowData, col.field));
  }
  if (col.empresaDataLicenca === true) {
    return (rowData) => formataDataArray(rowData.empresa.dataLicenca);
  }
  if (col.dataHora === true) {
    return (rowData) => formataDataArray(chavePorValor(rowData, col.field), '/', true);
  }
  if (col.dataAbertura === true) {
    return (rowData) => formataDataArray(chavePorValor(rowData, col.field), '/', true);
  }
  if (col.field === 'valorMensal') {
    return (rowData) => formataMoeda(rowData.valorMensal);
  }
  if (col.field.slice(0, 5) === 'preco' || col.field.slice(0, 5) === 'total' || col.field.slice(0, 5) === 'valor' || col.field.slice(0, 4) === 'pago'
  || col.field.slice(0, 5) === 'saldo' || col.moeda === true) {
    return (rowData) => formataMoeda(chavePorValor(rowData, col.field));
  }
  if (col.formatarDataComBarra === true) {
    return (rowData) => substituiTracoPorBarra(chavePorValor(rowData, col.field));
  }
  if (col.field.slice(0, 4) === 'data' || (col.formatarData === true)) {
    return (rowData) => formataDataArray(chavePorValor(rowData, col.field));
  }
  if (col.field === 'cnpj') {
    return (rowData) => formataCnpjCpf(rowData.cnpj);
  }
  if (col.field === 'listaEstoques.qtdeDeposito') {
    return (rowData) => setupQtdeDeposito(rowData);
  }
  if (col.field === 'listaEstoques.qtdePrateleira') {
    return (rowData) => setupQtdePrateleira(rowData);
  }
  if (col.field === 'previsao') {
    return (rowData) => setupBoolean(rowData.previsao);
  }
  if (col.field === 'movimentacaoBancaria') {
    return (rowData) => setupMovimentacaoBancaria(rowData.movimentacaoBancaria, rowData.livroCaixa);
  }

  return '';
};

export function fillColumns(dadosRecebidos) {
  const cols = dadosRecebidos.map(col => (
    <Column
      key={col.field}
      field={col.field}
      header={col.header}
      sortable={col.sort}
      body={onMask(col)}
    />
  ));

  return cols;
}
