import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { TabView, TabPanel } from 'primereact/tabview';
import { Divider } from 'primereact/divider';

import * as actions from '../../../../store/modules/auth/actions';

import { displayErrors, tratarNumero, findInList } from '../../../../util/diversos';
import { Input100px, Input120px, Input155px } from '../../../../styles/inputGroup';
import { fillColumns } from '../../../../components/table';

import Card from '../../../../components/card';
import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Loading from '../../../loading';

import ConsultaProduto from '../../produto/consultaProduto';

export default function CadastroEstampariaItemPadrao({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);

  const [showConsultaProduto, setShowConsultaProduto] = useState(false);
  const [showCadastroItemAcessorio, setShowCadastroItemAcessorio] = useState(false);
  const [showConfirmDeleteItemAcessorio, setShowConfirmDeleteItemAcessorio] = useState(false);
  const [mostrarManga, setMostrarManga] = useState(false);
  const [mostrarMangaAcabamento, setMostrarMangaAcabamento] = useState(false);
  const [mostrarGola, setMostrarGola] = useState(false);
  const [mostrarGolaAcabamento, setMostrarGolaAcabamento] = useState(false);
  const [mostrarBolso, setMostrarBolso] = useState(false);
  const [editandoItemAcessorio, setEditandoItemAcessorio] = useState(0);
  const [tipoServicoActiveTabIndex, setTipoServicoActiveTabIndex] = useState(0);
  const [descricao, setDescricao] = useState('');
  const [itemIdProduto, setItemIdProduto] = useState(0);
  const [itemDescricaoProduto, setItemDescricaoProduto] = useState('');
  const [itemModelo, setItemModelo] = useState(null);
  const [itemTamanho, setItemTamanho] = useState(null);
  const [itemMalha, setItemMalha] = useState(null);
  const [itemCor, setItemCor] = useState(null);
  const [itemBolso, setItemBolso] = useState(null);
  const [itemBolsoCor, setItemBolsoCor] = useState(null);
  const [itemGola, setItemGola] = useState(null);
  const [itemGolaAcabamento, setItemGolaAcabamento] = useState(null);
  const [itemGolaCor, setItemGolaCor] = useState(null);
  const [itemManga, setItemManga] = useState(null);
  const [itemMangaAcabamento, setItemMangaAcabamento] = useState(null);
  const [itemMangaCor, setItemMangaCor] = useState(null);
  const [itemMangaAcabamentoCor, setItemMangaAcabamentoCor] = useState(null);
  const [itemFrenteSilkar, setItemFrenteSilkar] = useState(false);
  const [itemFrenteSilk, setItemFrenteSilk] = useState(null);
  const [itemFrenteSublimar, setItemFrenteSublimar] = useState(false);
  const [itemFrenteSublimacao, setItemFrenteSublimacao] = useState(null);
  const [itemFrenteBordar, setItemFrenteBordar] = useState(false);
  const [itemFrenteBordado, setItemFrenteBordado] = useState(null);
  const [itemCostasSilkar, setItemCostasSilkar] = useState(false);
  const [itemCostasSilk, setItemCostasSilk] = useState(null);
  const [itemCostasSublimar, setItemCostasSublimar] = useState(false);
  const [itemCostasSublimacao, setItemCostasSublimacao] = useState(null);
  const [itemCostasBordar, setItemCostasBordar] = useState(false);
  const [itemCostasBordado, setItemCostasBordado] = useState(null);
  const [itemMangaDireitaSilkar, setItemMangaDireitaSilkar] = useState(false);
  const [itemMangaDireitaSilk, setItemMangaDireitaSilk] = useState(null);
  const [itemMangaDireitaSublimar, setItemMangaDireitaSublimar] = useState(false);
  const [itemMangaDireitaSublimacao, setItemMangaDireitaSublimacao] = useState(null);
  const [itemMangaDireitaBordar, setItemMangaDireitaBordar] = useState(false);
  const [itemMangaDireitaBordado, setItemMangaDireitaBordado] = useState(null);
  const [itemMangaEsquerdaSilkar, setItemMangaEsquerdaSilkar] = useState(false);
  const [itemMangaEsquerdaSilk, setItemMangaEsquerdaSilk] = useState(null);
  const [itemMangaEsquerdaSublimar, setItemMangaEsquerdaSublimar] = useState(false);
  const [itemMangaEsquerdaSublimacao, setItemMangaEsquerdaSublimacao] = useState(null);
  const [itemMangaEsquerdaBordar, setItemMangaEsquerdaBordar] = useState(false);
  const [itemMangaEsquerdaBordado, setItemMangaEsquerdaBordado] = useState(null);
  const [itemAcessorio, setItemAcessorio] = useState(null);
  const [itemAcessorioQuantidade, setItemAcessorioQuantidade] = useState(0);
  const [itemAcessorioValorAcrescimo, setItemAcessorioValorAcrescimo] = useState(0);
  const [itemAcessorioTotalAcrescimo, setItemAcessorioTotalAcrescimo] = useState(0);
  const [listaCor, setListaCor] = useState([]);
  const [listaBolso, setListaBolso] = useState([]);
  const [listaGola, setListaGola] = useState([]);
  const [listaGolaAcabamento, setListaGolaAcabamento] = useState([]);
  const [listaManga, setListaManga] = useState([]);
  const [listaMangaAcabamento, setListaMangaAcabamento] = useState([]);
  const [listaMalha, setListaMalha] = useState([]);
  const [listaModelo, setListaModelo] = useState([]);
  const [listaTamanho, setListaTamanho] = useState([]);
  const [listaBordado, setListaBordado] = useState([]);
  const [listaSilk, setListaSilk] = useState([]);
  const [listaSublimacao, setListaSublimacao] = useState([]);
  const [listaAcessorio, setListaAcessorio] = useState([]);
  const [listaItemAcessorio, setListaItemAcessorio] = useState([]);
  const [selectedItensAcessorios, setSelectedItensAcessorios] = useState(null);
  const [columnsSetupItemAcessorio] = useState([
    { field: 'descricaoAcessorio', header: 'Acessório', sort: true },
    { field: 'quantidade', header: 'Quantidade', sort: false },
    { field: 'valorAcrescimo', header: 'Valor Acréscimo', sort: false },
    { field: 'totalAcrescimo', header: 'Total Acréscimo', sort: false },
  ]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let newTitle = 'Ítem Padrão (Estamparia) - Cadastro';
    if (id) {
      newTitle = 'Ítem Padrão (Estamparia) - Edição';
    }

    let mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getEstampariaModelo() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _modelos } = await axiosAdmin.get(`/estampariamodelo?idUsuario=${idUsuario}`);

        _modelos.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaModelo(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaTamanho() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _tamanhos } = await axiosAdmin.get(`/estampariatamanho?idUsuario=${idUsuario}`);

        _tamanhos.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaTamanho(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaMalha() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _malhas } = await axiosAdmin.get(`/estampariamalha?idUsuario=${idUsuario}`);

        _malhas.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaMalha(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaCor() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _cores } = await axiosAdmin.get(`/estampariacor?idUsuario=${idUsuario}`);

        _cores.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaCor(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaBolso() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _bolsos } = await axiosAdmin.get(`/estampariabolso?idUsuario=${idUsuario}`);

        _bolsos.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaBolso(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaGola() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _golas } = await axiosAdmin.get(`/estampariagola?idUsuario=${idUsuario}`);

        _golas.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaGola(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaGolaAcabamento() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _golaacabamentos } = await axiosAdmin.get(`/estampariagolaacabamento?idUsuario=${idUsuario}`);

        _golaacabamentos.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaGolaAcabamento(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaManga() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _mangas } = await axiosAdmin.get(`/estampariamanga?idUsuario=${idUsuario}`);

        _mangas.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaManga(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaMangaAcabamento() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _mangaacabamentos } = await axiosAdmin.get(`/estampariamangaacabamento?idUsuario=${idUsuario}`);

        _mangaacabamentos.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaMangaAcabamento(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaBordado() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _bordados } = await axiosAdmin.get(`/estampariabordado?idUsuario=${idUsuario}`);

        _bordados.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaBordado(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaSilk() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _silks } = await axiosAdmin.get(`/estampariasilk?idUsuario=${idUsuario}`);

        _silks.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo, numeroTelas: element.numeroTelas });
        });

        setListaSilk(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaSublimacao() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _sublimacoes } = await axiosAdmin.get(`/estampariasublimacao?idUsuario=${idUsuario}`);

        _sublimacoes.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaSublimacao(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getEstampariaAcessorio() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const listDropdown = [];
        const { data: _acessorios } = await axiosAdmin.get(`/estampariaacessorio?idUsuario=${idUsuario}`);

        _acessorios.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.id, valorAcrescimo: element.valorAcrescimo });
        });

        setListaAcessorio(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    getEstampariaModelo();
    getEstampariaTamanho();
    getEstampariaMalha();
    getEstampariaCor();
    getEstampariaBolso();
    getEstampariaGola();
    getEstampariaGolaAcabamento();
    getEstampariaManga();
    getEstampariaMangaAcabamento();
    getEstampariaBordado();
    getEstampariaSilk();
    getEstampariaSublimacao();
    getEstampariaAcessorio();

    setMostrarManga(false);
    setMostrarMangaAcabamento(false);
    setMostrarGola(false);
    setMostrarGolaAcabamento(false);
    setMostrarBolso(false);

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/estampariaitempadrao/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultaestampariaitempadrao');
          toast.error('Acesso negado');
        }

        setDescricao(data.descricao);
        setItemIdProduto(get(data.produto, 'id', null));
        setItemDescricaoProduto(get(data.produto, 'descricao', ''));
        setMostrarManga(data.produto.estampariaManga);
        setMostrarMangaAcabamento(data.produto.estampariaMangaAcabamento);
        setMostrarGola(data.produto.estampariaGola);
        setMostrarGolaAcabamento(data.produto.estampariaGolaAcabamento);
        setMostrarBolso(data.produto.estampariaBolso);
        setItemModelo(get(data.estampariaModelo, 'id', null));
        setItemTamanho(get(data.estampariaTamanho, 'id', null));
        setItemMalha(get(data.estampariaMalha, 'id', null));
        setItemCor(get(data.estampariaCor, 'id', null));
        setItemBolso(get(data.estampariaBolso, 'id', null));
        setItemBolsoCor(get(data.estampariaBolsoCor, 'id', null));
        setItemGola(get(data.estampariaGola, 'id', null));
        setItemGolaAcabamento(get(data.estampariaGolaAcabamento, 'id', null));
        setItemGolaCor(get(data.estampariaGolaCor, 'id', null));
        setItemManga(get(data.estampariaManga, 'id', null));
        setItemMangaAcabamento(get(data.estampariaMangaAcabamento, 'id', null));
        setItemMangaCor(get(data.estampariaMangaCor, 'id', null));
        setItemMangaAcabamentoCor(get(data.estampariaMangaAcabamentoCor, 'id', null));

        data.listaEstampariaItemPadraoTipoServico.forEach(element => {
          if (element.parteVestuario === 'FRENTE') {
            setItemFrenteSilkar(element.silkar);
            setItemFrenteSilk(get(element.silk, 'id', null));
            setItemFrenteSublimar(element.sublimar);
            setItemFrenteSublimacao(get(element.sublimacao, 'id', null));
            setItemFrenteBordar(element.bordar);
            setItemFrenteBordado(get(element.bordado, 'id', null));
          }
          if (element.parteVestuario === 'COSTAS') {
            setItemCostasSilkar(element.silkar);
            setItemCostasSilk(get(element.silk, 'id', null));
            setItemCostasSublimar(element.sublimar);
            setItemCostasSublimacao(get(element.sublimacao, 'id', null));
            setItemCostasBordar(element.bordar);
            setItemCostasBordado(get(element.bordado, 'id', null));
          }
          if (element.parteVestuario === 'MANGADIREITA') {
            setItemMangaDireitaSilkar(element.silkar);
            setItemMangaDireitaSilk(get(element.silk, 'id', null));
            setItemMangaDireitaSublimar(element.sublimar);
            setItemMangaDireitaSublimacao(get(element.sublimacao, 'id', null));
            setItemMangaDireitaBordar(element.bordar);
            setItemMangaDireitaBordado(get(element.bordado, 'id', null));
          }
          if (element.parteVestuario === 'MANGAESQUERDA') {
            setItemMangaEsquerdaSilkar(element.silkar);
            setItemMangaEsquerdaSilk(get(element.silk, 'id', null));
            setItemMangaEsquerdaSublimar(element.sublimar);
            setItemMangaEsquerdaSublimacao(get(element.sublimacao, 'id', null));
            setItemMangaEsquerdaBordar(element.bordar);
            setItemMangaEsquerdaBordado(get(element.bordado, 'id', null));
          }
        });

        const _listaItemAcessorio = [];

        let item = 0;

        data.listaEstampariaItemPadraoAcessorio.forEach(_acessorio => {
          item = item + 1;

          _listaItemAcessorio.push({
            item,
            estampariaAcessorio: get(_acessorio, 'estampariaAcessorio.id', 0),
            descricaoAcessorio: get(_acessorio, 'estampariaAcessorio.descricao', ''),
            quantidade: _acessorio.quantidade,
            valorAcrescimo: _acessorio.valorAcrescimo,
            totalAcrescimo: _acessorio.totalAcrescimo,
          });
        });

        setListaItemAcessorio(_listaItemAcessorio);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let _itemGolaCor = itemGolaCor;
      let _itemMangaCor = itemMangaCor;
      let _itemMangaAcabamentoCor = itemMangaAcabamentoCor;

      if (!itemModelo) {
        toast.warning('Modelo deve ser informado');
        setTipoServicoActiveTabIndex(0);
        return;
      }
      if (!itemMalha) {
        toast.warning('Malha deve ser informada');
        setTipoServicoActiveTabIndex(0);
        return;
      }
      if (!itemCor) {
        toast.warning('Cor deve ser informada');
        setTipoServicoActiveTabIndex(0);
        return;
      }
      if (!itemGolaCor) {
        setItemGolaCor(itemCor);
        _itemGolaCor = itemCor;
      }
      if (!itemMangaCor) {
        setItemMangaCor(itemCor);
        _itemMangaCor = itemCor;
      }
      if (!itemMangaAcabamentoCor) {
        setItemMangaAcabamentoCor(itemCor);
        _itemMangaAcabamentoCor = itemCor;
      }
      if (!itemBolso && mostrarBolso) {
        toast.warning('Bolso deve ser informado');
        setTipoServicoActiveTabIndex(0);
        return;
      }
      if (!itemGola && mostrarGola) {
        toast.warning('Gola deve ser informada');
        setTipoServicoActiveTabIndex(0);
        return;
      }
      if (!itemGolaAcabamento && mostrarGolaAcabamento) {
        toast.warning('Acabamento da Gola deve ser informado');
        setTipoServicoActiveTabIndex(0);
        return;
      }
      if (!itemManga && mostrarManga) {
        toast.warning('Manga deve ser informada');
        setTipoServicoActiveTabIndex(0);
        return;
      }
      if (!itemMangaAcabamento && mostrarMangaAcabamento) {
        toast.warning('Acabamento da Manga deve ser informado');
        setTipoServicoActiveTabIndex(0);
        return;
      }
      if (itemFrenteSilkar && (!itemFrenteSilk || itemFrenteSilk <= 0)) {
        toast.warning('Frente - Tipo de Silk deve ser informado');
        setTipoServicoActiveTabIndex(1);
        return;
      }
      if (itemFrenteSublimar && (!itemFrenteSublimacao || itemFrenteSublimacao <= 0)) {
        toast.warning('Frente - Tipo de Sublimação deve ser informado');
        setTipoServicoActiveTabIndex(1);
        return;
      }
      if (itemFrenteBordar && (!itemFrenteBordado || itemFrenteBordado <= 0)) {
        toast.warning('Frente - Bordado deve ser informado');
        setTipoServicoActiveTabIndex(1);
        return;
      }
      if (itemCostasSilkar && (!itemCostasSilk || itemCostasSilk <= 0)) {
        toast.warning('Costas - Tipo de Silk deve ser informado');
        setTipoServicoActiveTabIndex(2);
        return;
      }
      if (itemCostasSublimar && (!itemCostasSublimacao || itemCostasSublimacao <= 0)) {
        toast.warning('Costas - Tipo de Sublimação deve ser informado');
        setTipoServicoActiveTabIndex(2);
        return;
      }
      if (itemCostasBordar && (!itemCostasBordado || itemCostasBordado <= 0)) {
        toast.warning('Costas - Bordado deve ser informado');
        setTipoServicoActiveTabIndex(2);
        return;
      }
      if (itemMangaDireitaSilkar && (!itemMangaDireitaSilk || itemMangaDireitaSilk <= 0)) {
        toast.warning('Manga Direita - Tipo de Silk deve ser informado');
        setTipoServicoActiveTabIndex(3);
        return;
      }
      if (itemMangaDireitaSublimar && (!itemMangaDireitaSublimacao || itemMangaDireitaSublimacao <= 0)) {
        toast.warning('Manga Direita - Tipo de Sublimação deve ser informado');
        setTipoServicoActiveTabIndex(3);
        return;
      }
      if (itemMangaDireitaBordar && (!itemMangaDireitaBordado || itemMangaDireitaBordado <= 0)) {
        toast.warning('Manga Direita - Bordado deve ser informado');
        setTipoServicoActiveTabIndex(3);
        return;
      }
      if (itemMangaEsquerdaSilkar && (!itemMangaEsquerdaSilk || itemMangaEsquerdaSilk <= 0)) {
        toast.warning('Manga Esquerda - Tipo de Silk deve ser informado');
        setTipoServicoActiveTabIndex(4);
        return;
      }
      if (itemMangaEsquerdaSublimar && (!itemMangaEsquerdaSublimacao || itemMangaEsquerdaSublimacao <= 0)) {
        toast.warning('Manga Esquerda - Tipo de Sublimação deve ser informado');
        setTipoServicoActiveTabIndex(4);
        return;
      }
      if (itemMangaEsquerdaBordar && (!itemMangaEsquerdaBordado || itemMangaEsquerdaBordado <= 0)) {
        toast.warning('Manga Esquerda - Bordado deve ser informado');
        setTipoServicoActiveTabIndex(4);
        return;
      }

      let payloadDataTableList = {};

      const shippingData = {
        idUsuario,
        descricao,
        produto: itemIdProduto,
        modelo: itemModelo,
        tamanho: itemTamanho,
        malha: itemMalha,
        cor: itemCor,
        bolso: itemBolso,
        bolsoCor: itemBolsoCor,
        gola: itemGola,
        golaAcabamento: itemGolaAcabamento,
        golaCor: _itemGolaCor,
        manga: itemManga,
        mangaAcabamento: itemMangaAcabamento,
        mangaCor: _itemMangaCor,
        mangaAcabamentoCor: _itemMangaAcabamentoCor,
        listaEstampariaItemPadraoAcessorio: listaItemAcessorio,
        listaEstampariaItemPadraoTipoServico: [
          {
            parteVestuario: 'FRENTE',
            silkar: itemFrenteSilkar,
            silk: itemFrenteSilk,
            sublimar: itemFrenteSublimar,
            sublimacao: itemFrenteSublimacao,
            bordar: itemFrenteBordar,
            bordado: itemFrenteBordado,
          },
          {
            parteVestuario: 'COSTAS',
            silkar: itemCostasSilkar,
            silk: itemCostasSilk,
            sublimar: itemCostasSublimar,
            sublimacao: itemCostasSublimacao,
            bordar: itemCostasBordar,
            bordado: itemCostasBordado,
          },
          {
            parteVestuario: 'MANGADIREITA',
            silkar: itemMangaDireitaSilkar,
            silk: itemMangaDireitaSilk,
            sublimar: itemMangaDireitaSublimar,
            sublimacao: itemMangaDireitaSublimacao,
            bordar: itemMangaDireitaBordar,
            bordado: itemMangaDireitaBordado,
          },
          {
            parteVestuario: 'MANGAESQUERDA',
            silkar: itemMangaEsquerdaSilkar,
            silk: itemMangaEsquerdaSilk,
            sublimar: itemMangaEsquerdaSublimar,
            sublimacao: itemMangaEsquerdaSublimacao,
            bordar: itemMangaEsquerdaBordar,
            bordado: itemMangaEsquerdaBordado,
          },
        ],
      };

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/estampariaitempadrao/${id}`, shippingData), 'data', {});
        toast.success('Ítem Padrão (Estamparia) atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/estampariaitempadrao', shippingData), 'data', {});
        toast.success('Ítem Padrão (Estamparia) cadastrado com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!descricao) {
      formErros = true;
      toast.error('Descrição deve ser informada');
    }
    if (itemIdProduto <= 0) {
      toast.warning('Produto deve ser informado');
      return;
    }

    if (formErros) return;

    salvarRegistro();
  };

  function limparCamposItemAcessorio() {
    setItemAcessorio(null);
    setItemAcessorioQuantidade(0);
    setItemAcessorioValorAcrescimo(0);
    setItemAcessorioTotalAcrescimo(0);
  }

  const handleFocus = (event) => event.target.select();

  const titulo = () => (id ? 'Ítem Padrão (Estamparia) - Alteração' : 'Ítem Padrão (Estamparia) - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>{titulo()}</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const onHideConsultaProduto = () => {
    setShowConsultaProduto(false);
  };

  const childToParentConsultaProduto = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        if (e.tipo !== '09') {
          toast.warning('Produto para ítem padrão deve ser do tipo Serviço');
        } else {
          setItemIdProduto(e.id);
          setItemDescricaoProduto(e.descricao);
          setMostrarManga(e.estampariaManga);
          setMostrarMangaAcabamento(e.estampariaMangaAcabamento);
          setMostrarGola(e.estampariaGola);
          setMostrarGolaAcabamento(e.estampariaGolaAcabamento);
          setMostrarBolso(e.estampariaBolso);
        }
      }
      setShowConsultaProduto(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const confirmDeleteItemAcessorio = () => {
    const item = get(selectedItensAcessorios, 'item', 0);

    if (item > 0) {
      const _listaItemAcessorio = listaItemAcessorio;

      const index = _listaItemAcessorio.indexOf(_listaItemAcessorio.find(element => element.item === item));

      if (index >= 0) {
        _listaItemAcessorio.splice(index, 1);

        _listaItemAcessorio.forEach(element => {
          if (element.item > item) {
            const _index = _listaItemAcessorio.indexOf(element);

            _listaItemAcessorio[_index].item = _listaItemAcessorio[_index].item - 1;
          }
        });
      }

      setListaItemAcessorio(_listaItemAcessorio);
    }

    setShowConfirmDeleteItemAcessorio(false);
  };

  const cancelDeleteItemAcessorio = () => {
    setShowConfirmDeleteItemAcessorio(false);
  };

  const confirmDialogFooterItemAcessorio = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDeleteItemAcessorio} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDeleteItemAcessorio} />
    </div>
  );

  const onHideCadastroItemAcessorio = () => {
    setShowCadastroItemAcessorio(false);
  };

  const handleOkItemAcessorio = () => {
    if (!itemAcessorio || itemAcessorio <= 0) {
      toast.warning('Acessório deve ser informado');
      return;
    }
    if (itemAcessorioQuantidade <= 0) {
      toast.warning('Quantidade deve ser informada');
      return;
    }
    if (itemAcessorioTotalAcrescimo <= 0) {
      toast.warning('Valor deve ser informado');
      return;
    }

    const _listaItemAcessorio = listaItemAcessorio;

    const descricaoAcessorio = listaAcessorio.find(element => element.value === itemAcessorio).label;

    let item = 0;
    listaItemAcessorio.forEach(element => {
      if (element.item > item) {
        item = element.item;
      }
    });
    item = item + 1;

    if (editandoItemAcessorio && editandoItemAcessorio > 0) {
      item = editandoItemAcessorio;

      const index = _listaItemAcessorio.indexOf(_listaItemAcessorio.find(element => element.item === editandoItemAcessorio));

      if (index >= 0) {
        _listaItemAcessorio.splice(index, 1);
      }
    }

    _listaItemAcessorio.push({
      item,
      estampariaAcessorio: itemAcessorio,
      descricaoAcessorio,
      quantidade: itemAcessorioQuantidade,
      valorAcrescimo: itemAcessorioValorAcrescimo,
      totalAcrescimo: itemAcessorioTotalAcrescimo,
    });

    setListaItemAcessorio(_listaItemAcessorio);

    setShowCadastroItemAcessorio(false);
  };

  const handleIncluirItemAcessorio = (e) => {
    e.preventDefault();

    setEditandoItemAcessorio(0);

    limparCamposItemAcessorio();

    setShowCadastroItemAcessorio(true);
  };

  const handleEditItemAcessorio = (e) => {
    e.preventDefault();

    const item = get(selectedItensAcessorios, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItemAcessorio.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o ítem para edição');
        return;
      }

      setEditandoItemAcessorio(item);

      limparCamposItemAcessorio();

      setItemAcessorio(_itemSelecionado.estampariaAcessorio);
      setItemAcessorioQuantidade(_itemSelecionado.quantidade);
      setItemAcessorioValorAcrescimo(_itemSelecionado.valorAcrescimo);
      setItemAcessorioTotalAcrescimo(_itemSelecionado.totalAcrescimo);

      setShowCadastroItemAcessorio(true);
    } else {
      toast.warning('Por favor, selecione o ítem para edição');
    }

    setShowCadastroItemAcessorio(true);
  };

  const handleDeleteItemAcessorio = (e) => {
    e.preventDefault();

    const item = get(selectedItensAcessorios, 'item', 0);

    if (item > 0) {
      const _itemSelecionado = listaItemAcessorio.find(element => element.item === item);

      if (!_itemSelecionado) {
        toast.warning('Por favor, selecione o acessório para exclusão');
        return;
      }

      setShowConfirmDeleteItemAcessorio(true);
    } else {
      toast.warning('Por favor, selecione o acessório para exclusão');
    }
  };

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Produtos"
        visible={showConsultaProduto}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaProduto()}
      >
        <ConsultaProduto menuMode={false} childToParent={childToParentConsultaProduto} somenteLeitura />
      </Dialog>

      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Descrição
              </Input155px>
              <InputText
                autoFocus
                maxLength={100}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Produto
              </Input155px>
              <InputText
                disabled
                value={itemDescricaoProduto}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaProduto(true)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <TabView activeIndex={tipoServicoActiveTabIndex} onTabChange={(e) => setTipoServicoActiveTabIndex(e.index)}>
            <TabPanel header="Especificações">
              <div className="row form-elo">
                <div className="col-lg-3">
                  <div className="p-inputgroup">
                    <Input100px className="p-inputgroup-addon">
                      Tamanho
                    </Input100px>
                    <Dropdown
                      filter
                      showClear
                      filterBy="label"
                      options={listaTamanho}
                      value={itemTamanho}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemTamanho(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="p-inputgroup">
                    <Input100px className="p-inputgroup-addon">
                      Modelo
                    </Input100px>
                    <Dropdown
                      filter
                      showClear
                      filterBy="label"
                      options={listaModelo}
                      value={itemModelo}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemModelo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="p-inputgroup">
                    <Input100px className="p-inputgroup-addon">
                      Malha
                    </Input100px>
                    <Dropdown
                      filter
                      showClear
                      filterBy="label"
                      options={listaMalha}
                      value={itemMalha}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemMalha(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="p-inputgroup">
                    <Input100px className="p-inputgroup-addon">
                      Cor
                    </Input100px>
                    <Dropdown
                      filter
                      showClear
                      filterBy="label"
                      options={listaCor}
                      value={itemCor}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemCor(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                {mostrarManga ? (
                  <>
                    <div className="col-lg-3">
                      <div className="p-inputgroup">
                        <Input100px className="p-inputgroup-addon">
                          Manga
                        </Input100px>
                        <Dropdown
                          filter
                          showClear
                          filterBy="label"
                          options={listaManga}
                          value={itemManga}
                          emptyMessage="Nenhum registro a ser exibido"
                          onChange={e => setItemManga(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="p-inputgroup">
                        <Input100px className="p-inputgroup-addon">
                          Manga Cor
                        </Input100px>
                        <Dropdown
                          filter
                          showClear
                          filterBy="label"
                          options={listaCor}
                          value={itemMangaCor}
                          emptyMessage="Nenhum registro a ser exibido"
                          onChange={e => setItemMangaCor(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                ) : ('')}
                {mostrarMangaAcabamento ? (
                  <>
                    <div className="col-lg-3">
                      <div className="p-inputgroup">
                        <Input120px className="p-inputgroup-addon">
                          Manga Acab
                        </Input120px>
                        <Dropdown
                          filter
                          showClear
                          filterBy="label"
                          options={listaMangaAcabamento}
                          value={itemMangaAcabamento}
                          emptyMessage="Nenhum registro a ser exibido"
                          onChange={e => setItemMangaAcabamento(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="p-inputgroup">
                        <Input155px className="p-inputgroup-addon">
                          Manga Acab Cor
                        </Input155px>
                        <Dropdown
                          filter
                          showClear
                          filterBy="label"
                          options={listaCor}
                          value={itemMangaAcabamentoCor}
                          emptyMessage="Nenhum registro a ser exibido"
                          onChange={e => setItemMangaAcabamentoCor(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                ) : ('')}
              </div>
              <div className="row form-elo">
                {mostrarGola ? (
                  <>
                    <div className="col-lg-3">
                      <div className="p-inputgroup">
                        <Input100px className="p-inputgroup-addon">
                          Golas
                        </Input100px>
                        <Dropdown
                          filter
                          showClear
                          filterBy="label"
                          options={listaGola}
                          value={itemGola}
                          emptyMessage="Nenhum registro a ser exibido"
                          onChange={e => setItemGola(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="p-inputgroup">
                        <Input120px className="p-inputgroup-addon">
                          Gola Cor
                        </Input120px>
                        <Dropdown
                          filter
                          showClear
                          filterBy="label"
                          options={listaCor}
                          value={itemGolaCor}
                          emptyMessage="Nenhum registro a ser exibido"
                          onChange={e => setItemGolaCor(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                ) : ('')}
                <div className="col-lg-3">
                  {mostrarGolaAcabamento ? (
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Gola Acabamento
                      </Input155px>
                      <Dropdown
                        filter
                        showClear
                        filterBy="label"
                        options={listaGolaAcabamento}
                        value={itemGolaAcabamento}
                        emptyMessage="Nenhum registro a ser exibido"
                        onChange={e => setItemGolaAcabamento(e.target.value)}
                      />
                    </div>
                  ) : ('')}
                </div>
              </div>
              <div className="row form-elo">
                {mostrarBolso ? (
                  <>
                    <div className="col-lg-3">
                      <div className="p-inputgroup">
                        <Input100px className="p-inputgroup-addon">
                          Bolso
                        </Input100px>
                        <Dropdown
                          filter
                          showClear
                          filterBy="label"
                          options={listaBolso}
                          value={itemBolso}
                          emptyMessage="Nenhum registro a ser exibido"
                          onChange={e => setItemBolso(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <div className="p-inputgroup">
                        <Input155px className="p-inputgroup-addon">
                          Bolso Cor
                        </Input155px>
                        <Dropdown
                          filter
                          showClear
                          filterBy="label"
                          options={listaCor}
                          value={itemBolsoCor}
                          emptyMessage="Nenhum registro a ser exibido"
                          onChange={e => setItemBolsoCor(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                ) : ('')}
              </div>
            </TabPanel>
            <TabPanel header="Frente">
              <div className="row form-elo">
                <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="p-inputgroup">
                    <Checkbox
                      checked={itemFrenteSilkar}
                      onChange={e => {
                        setItemFrenteSilkar(e.checked);
                        if (!e.checked) {
                          setItemFrenteSilk(null);
                        }
                      }}
                    >
                    </Checkbox>
                    <label
                      htmlFor="cb1"
                      className="p-checkbox-label"
                    >
                        &nbsp;Silkar
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Tipo de Silk
                    </Input155px>
                    <Dropdown
                      disabled={!itemFrenteSilkar}
                      filter
                      showClear
                      filterBy="label"
                      options={listaSilk}
                      value={itemFrenteSilk}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemFrenteSilk(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="p-inputgroup">
                    <Checkbox
                      checked={itemFrenteSublimar}
                      onChange={e => {
                        setItemFrenteSublimar(e.checked);
                        if (!e.checked) {
                          setItemFrenteSublimacao(null);
                        }
                      }}
                    >
                    </Checkbox>
                    <label
                      htmlFor="cb1"
                      className="p-checkbox-label"
                    >
                        &nbsp;Sublimar
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Tipo Sublimação
                    </Input155px>
                    <Dropdown
                      disabled={!itemFrenteSublimar}
                      filter
                      showClear
                      filterBy="label"
                      options={listaSublimacao}
                      value={itemFrenteSublimacao}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemFrenteSublimacao(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="p-inputgroup">
                    <Checkbox
                      checked={itemFrenteBordar}
                      onChange={e => {
                        setItemFrenteBordar(e.checked);
                        if (!e.checked) {
                          setItemFrenteBordado(null);
                        }
                      }}
                    >
                    </Checkbox>
                    <label
                      htmlFor="cb1"
                      className="p-checkbox-label"
                    >
                        &nbsp;Bordar
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Bordado
                    </Input155px>
                    <Dropdown
                      disabled={!itemFrenteBordar}
                      filter
                      showClear
                      filterBy="label"
                      options={listaBordado}
                      value={itemFrenteBordado}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemFrenteBordado(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Costas">
              <div className="row form-elo">
                <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="p-inputgroup">
                    <Checkbox
                      checked={itemCostasSilkar}
                      onChange={e => {
                        setItemCostasSilkar(e.checked);
                        if (!e.checked) {
                          setItemCostasSilk(null);
                        }
                      }}
                    >
                    </Checkbox>
                    <label
                      htmlFor="cb1"
                      className="p-checkbox-label"
                    >
                        &nbsp;Silkar
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Tipo de Silk
                    </Input155px>
                    <Dropdown
                      disabled={!itemCostasSilkar}
                      filter
                      showClear
                      filterBy="label"
                      options={listaSilk}
                      value={itemCostasSilk}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemCostasSilk(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="p-inputgroup">
                    <Checkbox
                      checked={itemCostasSublimar}
                      onChange={e => {
                        setItemCostasSublimar(e.checked);
                        if (!e.checked) {
                          setItemCostasSublimacao(null);
                        }
                      }}
                    >
                    </Checkbox>
                    <label
                      htmlFor="cb1"
                      className="p-checkbox-label"
                    >
                        &nbsp;Sublimar
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Tipo Sublimação
                    </Input155px>
                    <Dropdown
                      disabled={!itemCostasSublimar}
                      filter
                      showClear
                      filterBy="label"
                      options={listaSublimacao}
                      value={itemCostasSublimacao}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemCostasSublimacao(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="p-inputgroup">
                    <Checkbox
                      checked={itemCostasBordar}
                      onChange={e => {
                        setItemCostasBordar(e.checked);
                        if (!e.checked) {
                          setItemCostasBordado(null);
                        }
                      }}
                    >
                    </Checkbox>
                    <label
                      htmlFor="cb1"
                      className="p-checkbox-label"
                    >
                        &nbsp;Bordar
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Bordado
                    </Input155px>
                    <Dropdown
                      disabled={!itemCostasBordar}
                      filter
                      showClear
                      filterBy="label"
                      options={listaBordado}
                      value={itemCostasBordado}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemCostasBordado(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Manga Direita">
              <div className="row form-elo">
                <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="p-inputgroup">
                    <Checkbox
                      checked={itemMangaDireitaSilkar}
                      onChange={e => {
                        setItemMangaDireitaSilkar(e.checked);
                        if (!e.checked) {
                          setItemMangaDireitaSilk(null);
                        }
                      }}
                    >
                    </Checkbox>
                    <label
                      htmlFor="cb1"
                      className="p-checkbox-label"
                    >
                        &nbsp;Silkar
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Tipo de Silk
                    </Input155px>
                    <Dropdown
                      disabled={!itemMangaDireitaSilkar}
                      filter
                      showClear
                      filterBy="label"
                      options={listaSilk}
                      value={itemMangaDireitaSilk}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemMangaDireitaSilk(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="p-inputgroup">
                    <Checkbox
                      checked={itemMangaDireitaSublimar}
                      onChange={e => {
                        setItemMangaDireitaSublimar(e.checked);
                        if (!e.checked) {
                          setItemMangaDireitaSublimacao(null);
                        }
                      }}
                    >
                    </Checkbox>
                    <label
                      htmlFor="cb1"
                      className="p-checkbox-label"
                    >
                        &nbsp;Sublimar
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Tipo Sublimação
                    </Input155px>
                    <Dropdown
                      disabled={!itemMangaDireitaSublimar}
                      filter
                      showClear
                      filterBy="label"
                      options={listaSublimacao}
                      value={itemMangaDireitaSublimacao}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemMangaDireitaSublimacao(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="p-inputgroup">
                    <Checkbox
                      checked={itemMangaDireitaBordar}
                      onChange={e => {
                        setItemMangaDireitaBordar(e.checked);
                        if (!e.checked) {
                          setItemMangaDireitaBordado(null);
                        }
                      }}
                    >
                    </Checkbox>
                    <label
                      htmlFor="cb1"
                      className="p-checkbox-label"
                    >
                        &nbsp;Bordar
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Bordado
                    </Input155px>
                    <Dropdown
                      disabled={!itemMangaDireitaBordar}
                      filter
                      showClear
                      filterBy="label"
                      options={listaBordado}
                      value={itemMangaDireitaBordado}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemMangaDireitaBordado(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Manga Esquerda">
              <div className="row form-elo">
                <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="p-inputgroup">
                    <Checkbox
                      checked={itemMangaEsquerdaSilkar}
                      onChange={e => {
                        setItemMangaEsquerdaSilkar(e.checked);
                        if (!e.checked) {
                          setItemMangaEsquerdaSilk(null);
                        }
                      }}
                    >
                    </Checkbox>
                    <label
                      htmlFor="cb1"
                      className="p-checkbox-label"
                    >
                        &nbsp;Silkar
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Tipo de Silk
                    </Input155px>
                    <Dropdown
                      disabled={!itemMangaEsquerdaSilkar}
                      filter
                      showClear
                      filterBy="label"
                      options={listaSilk}
                      value={itemMangaEsquerdaSilk}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemMangaEsquerdaSilk(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="p-inputgroup">
                    <Checkbox
                      checked={itemMangaEsquerdaSublimar}
                      onChange={e => {
                        setItemMangaEsquerdaSublimar(e.checked);
                        if (!e.checked) {
                          setItemMangaEsquerdaSublimacao(null);
                        }
                      }}
                    >
                    </Checkbox>
                    <label
                      htmlFor="cb1"
                      className="p-checkbox-label"
                    >
                        &nbsp;Sublimar
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Tipo Sublimação
                    </Input155px>
                    <Dropdown
                      disabled={!itemMangaEsquerdaSublimar}
                      filter
                      showClear
                      filterBy="label"
                      options={listaSublimacao}
                      value={itemMangaEsquerdaSublimacao}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemMangaEsquerdaSublimacao(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-lg-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div className="p-inputgroup">
                    <Checkbox
                      checked={itemMangaEsquerdaBordar}
                      onChange={e => {
                        setItemMangaEsquerdaBordar(e.checked);
                        if (!e.checked) {
                          setItemMangaEsquerdaBordado(null);
                        }
                      }}
                    >
                    </Checkbox>
                    <label
                      htmlFor="cb1"
                      className="p-checkbox-label"
                    >
                        &nbsp;Bordar
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="p-inputgroup">
                    <Input155px className="p-inputgroup-addon">
                      Bordado
                    </Input155px>
                    <Dropdown
                      disabled={!itemMangaEsquerdaBordar}
                      filter
                      showClear
                      filterBy="label"
                      options={listaBordado}
                      value={itemMangaEsquerdaBordado}
                      emptyMessage="Nenhum registro a ser exibido"
                      onChange={e => setItemMangaEsquerdaBordado(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Acessórios">
              <div className="estamparia-itempadrao-acessorio">
                <DataTable
                  value={listaItemAcessorio}
                  size="small"
                  stripedRows
                  responsiveLayout="scroll"
                  sortField="item"
                  selectionMode="single"
                  selection={selectedItensAcessorios}
                  onSelectionChange={e => setSelectedItensAcessorios(e.value)}
                  selectableRows
                  dataKey="item"
                  emptyMessage="Nenhum Acessório cadastrado"
                >
                  {fillColumns(columnsSetupItemAcessorio)}
                </DataTable>
              </div>
              <br />

              <Dialog
                header="Confirmação"
                visible={showConfirmDeleteItemAcessorio}
                style={{ width: '50vw' }}
                footer={confirmDialogFooterItemAcessorio}
                onHide={() => setShowConfirmDeleteItemAcessorio(false)}
              >
                <p>
                  Excluir o acessório?
                </p>
              </Dialog>

              <Dialog
                header="Ítem Padrão - Acessório"
                visible={showCadastroItemAcessorio}
                style={{ width: '98vw' }}
                onHide={() => onHideCadastroItemAcessorio()}
              >
                <div className="row form-elo">
                  <div className="col-lg-12">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Acessório
                      </Input155px>
                      <Dropdown
                        filter
                        showClear
                        filterBy="label"
                        options={listaAcessorio}
                        value={itemAcessorio}
                        emptyMessage="Nenhum registro a ser exibido"
                        onChange={e => {
                          setItemAcessorio(e.target.value);
                          const valorAcrescimo = tratarNumero(findInList(listaAcessorio, e.target.value, 'valorAcrescimo'));
                          setItemAcessorioValorAcrescimo(valorAcrescimo);
                          setItemAcessorioTotalAcrescimo(itemAcessorioQuantidade * valorAcrescimo);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Quantidade
                      </Input155px>
                      <InputNumber
                        mode="decimal"
                        inputId="minmaxfraction"
                        minFractionDigits={3}
                        maxFractionDigits={3}
                        value={itemAcessorioQuantidade}
                        onValueChange={e => {
                          setItemAcessorioTotalAcrescimo(e.target.value * itemAcessorioValorAcrescimo);
                          setItemAcessorioQuantidade(e.target.value);
                        }}
                        onFocus={handleFocus}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Valor
                      </Input155px>
                      <InputNumber
                        disabled
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemAcessorioValorAcrescimo}
                        onValueChange={e => setItemAcessorioValorAcrescimo(e.target.value)}
                        onFocus={handleFocus}
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="p-inputgroup">
                      <Input155px className="p-inputgroup-addon">
                        Total Acessório
                      </Input155px>
                      <InputNumber
                        disabled
                        mode="currency"
                        inputId="currency-br"
                        currency="BRL"
                        locale="pt-BR"
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        value={itemAcessorioTotalAcrescimo}
                        onValueChange={e => setItemAcessorioTotalAcrescimo(e.target.value)}
                        onFocus={handleFocus}
                      />
                    </div>
                  </div>
                </div>
                <div className="row form-elo">
                  <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={handleOkItemAcessorio}
                    >
                      <i className="pi pi-check"></i>
                      Ok
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => setShowCadastroItemAcessorio(false)}
                    >
                      <i className="pi pi-times"></i>
                      Cancelar
                    </button>
                  </div>
                </div>
              </Dialog>
              <div className="row form-elo">
                <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <button
                    type="button"
                    title="Incluir Acessório"
                    className="btn btn-primary"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleIncluirItemAcessorio(e)}
                  >
                    <i className="pi pi-plus"></i>
                  </button>
                  <button
                    type="button"
                    title="Alterar Acessório"
                    className="btn btn-warning"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleEditItemAcessorio(e)}
                  >
                    <i className="pi pi-pencil"></i>
                  </button>
                  <button
                    type="button"
                    title="Cancelar Acessório"
                    className="btn btn-danger"
                    style={{ borderRadius: '15px' }}
                    onClick={(e) => handleDeleteItemAcessorio(e)}
                  >
                    <i className="pi pi-trash"></i>
                  </button>
                </div>
                <Divider />
              </div>
            </TabPanel>
          </TabView>
        </div>
      </Card>
    </div>
  );
}
