export function obterListaIpiCst() {
  return (
    [
      { label: '00 - Entrada com recuperação de crédito', value: '00' },
      { label: '01 - Entrada Tributada com Alíquota Zero', value: '01' },
      { label: '02 - Entrada Isenta', value: '02' },
      { label: '03 - Entrada não Tributada ', value: '03' },
      { label: '04 - Entrada Imune', value: '04' },
      { label: '05 - Entrada com Suspensão', value: '05' },
      { label: '49 - Outras Entradas', value: '49' },
      { label: '50 - Saída Tributada', value: '50' },
      { label: '51 - Saída Tributada com Alíquota Zero', value: '51' },
      { label: '52 - Saída Isenta', value: '52' },
      { label: '53 - Saída não Tributada', value: '53' },
      { label: '54 - Saída Imune', value: '54' },
      { label: '55 - Saída com Suspensão', value: '55' },
      { label: '99 - Outras Saídas', value: '99' },
    ]
  );
}
