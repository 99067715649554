import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmail } from 'validator';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { TreeSelect } from 'primereact/treeselect';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Dropdown } from 'primereact/dropdown';
import { ToggleButton } from 'primereact/togglebutton';

import * as actions from '../../../store/modules/auth/actions';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosAdmin from '../../../services/axiosAdmin';

import { displayErrors } from '../../../util/diversos';
import { Input155px, Input200px } from '../../../styles/inputGroup';

import { obterListaTipoUsuario } from '../../../services/usuarioService';

export default function CadastroUsuario() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(useParams(), 'id', 0);

  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [senhaSync, setSenhaSync] = useState('');
  const [cadastroSincronizado, setCadastroSincronizado] = useState(true);
  const [tipoUsuario, setTipoUsuario] = useState('');
  const [modeloNegocio, setModeloNegocio] = useState('PADRAO');
  const [moduloNfe, setModuloNfe] = useState(false);
  const [moduloFinanceiro, setModuloFinanceiro] = useState(false);
  const [senha, setSenha] = useState('');
  const [senhaRepeticao, setSenhaRepeticao] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [nodeEmpresas, setNodeEmpresas] = useState(null);
  const [selectedNodeEmpresas, setSelectedNodeEmpresas] = useState(null);
  const [listaModeloNegocio] = useState([
    { label: 'Padrão', value: 'PADRAO' },
    { label: 'Estamparia', value: 'ESTAMPARIA' },
    { label: 'Marmoraria', value: 'MARMORARIA' },
  ]);

  useEffect(() => {
    let newTitle = 'Usuário - Cadastro';
    if (id) {
      newTitle = 'Usuário - Edição';
    }

    let mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getEmpresas() {
      try {
        setIsLoading(true);

        const dadosKey = [];

        const { data: empresas } = await axiosAdmin.get('/empresa');

        empresas.forEach(element => {
          dadosKey.push({ key: element.id, label: element.razaoSocial });
        });

        setNodeEmpresas(dadosKey);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    getEmpresas();

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/usuario/${id}`);

        setNome(data.nome);
        setEmail(data.email);
        setCadastroSincronizado(data.cadastroSincronizado);
        setTipoUsuario(data.tipoUsuario);
        setModeloNegocio(data.modeloNegocio);
        setModuloNfe(data.moduloNfe);
        setModuloFinanceiro(data.moduloFinanceiro);
        setSenha('');
        setSenhaRepeticao('');
        setSenhaSync(data.senhaSync);

        let dadosSetKey = {};

        data.listaEmpresas.forEach(element => {
          dadosSetKey = { ...dadosSetKey, [element.id]: { checked: true, partialChecked: true } };
        });

        setSelectedNodeEmpresas(dadosSetKey);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const listaEmpresas = [];

      if (selectedNodeEmpresas !== null) {
        Object.keys(selectedNodeEmpresas).forEach((item) => {
          listaEmpresas.push(+item);
        });
      }

      const shippingData = {
        nome,
        email,
        cadastroSincronizado,
        tipoUsuario,
        modeloNegocio,
        moduloNfe,
        moduloFinanceiro,
        senha,
        senhaSync,
        listaEmpresas,
      };

      if (id > 0) {
        await axiosAdmin.put(`/usuario/${id}`, shippingData);
        toast.success('Usuário atualizado com sucesso');
      } else {
        await axiosAdmin.post('/usuario', shippingData);
        toast.success('Usuário cadastrado com sucesso');
      }

      navigate('/consultausuario');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    navigate('/consultausuario');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (nome.length < 3 || nome.length > 50) {
      formErros = true;
      toast.error('Nome do usuário deve ter entre 3 a 50 caracteres');
    }
    if (!isEmail(email)) {
      formErros = true;
      toast.error('E-mail inválido.');
    }
    if (!tipoUsuario) {
      formErros = true;
      toast.error('Tipo de Usuário deve ser informado');
    }
    if (!modeloNegocio) {
      formErros = true;
      toast.error('Modelo de Negócio deve ser informado');
    }
    if (!id && senha.length < 3) {
      formErros = true;
      toast.error('Senha deve ter no mínimo 3 caracteres');
    }
    if (!id && senha !== senhaRepeticao) {
      formErros = true;
      toast.error('Senhas digitadas não conferem');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const listaTipoUsuario = obterListaTipoUsuario();

  const titulo = () => (id ? 'Usuário - Alteração' : 'Usuário - Inclusão');

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <div className="row form-elo">
          <div className="col-8">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nome
              </Input155px>
              <InputText
                id="inputNome"
                maxLength={50}
                value={nome}
                onChange={e => setNome(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Cadastro Sincronizado
              </Input200px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={cadastroSincronizado}
                onChange={(e) => setCadastroSincronizado(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                E-mail
              </Input155px>
              <InputText
                id="inputEmail"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo de Usuário
              </Input155px>
              <Dropdown
                options={listaTipoUsuario}
                value={tipoUsuario}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setTipoUsuario(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Modelo Negócio
              </Input155px>
              <Dropdown
                options={listaModeloNegocio}
                value={modeloNegocio}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setModeloNegocio(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Módulo NF-e
              </Input155px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={moduloNfe}
                onChange={(e) => setModuloNfe(e.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Módulo Financeiro
              </Input155px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={moduloFinanceiro}
                onChange={(e) => setModuloFinanceiro(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Senha
              </Input155px>
              <Password
                disabled={id}
                id="inputSenha"
                toggleMask
                weakLabel="Fraca"
                mediumLabel="Média"
                strongLabel="Forte"
                value={senha}
                onChange={e => setSenha(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Repita a Senha
              </Input155px>
              <Password
                disabled={id}
                id="inputSenhaRepeticao"
                toggleMask
                weakLabel="Fraca"
                mediumLabel="Média"
                strongLabel="Forte"
                value={senhaRepeticao}
                onChange={e => setSenhaRepeticao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Empresa(s)
              </Input155px>
              <TreeSelect
                value={selectedNodeEmpresas}
                className="form-control"
                selectionMode="checkbox"
                filter
                emptyMessage="Nenhum registro a ser exibido"
                options={nodeEmpresas}
                onChange={(e) => setSelectedNodeEmpresas(e.value)}
                placeholder="Selecione..."
              >
              </TreeSelect>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
