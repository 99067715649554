export function obterListaTipoViaTransporte() {
  return (
    [
      { label: '1 - Marítima', value: 'MARITIMA' },
      { label: '2 - Fluvial', value: 'FLUVIAL' },
      { label: '3 - Lacustre', value: 'LACUSTRE' },
      { label: '4 - Aérea', value: 'AEREA' },
      { label: '5 - Postal', value: 'POSTAL' },
      { label: '6 - Ferroviária', value: 'FERROVIARIA' },
      { label: '7 - Rodoviária', value: 'RODOVIARIA' },
      { label: '8 - Conduto', value: 'CONDUTO' },
      { label: '9 - Meios Próprios', value: 'MEIOSPROPRIOS' },
      { label: '10 - Entrada/Saída Ficta', value: 'FICTA' },
    ]
  );
}
