import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';

import ReactFileReader from 'react-file-reader';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input155px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function AtualizaTabelaFiscal() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tipoTabela, setTipoTabela] = useState('CFOP');
  const [listaTipoTabela] = useState([
    { label: 'CFOP', value: 'CFOP' },
    { label: 'CEST', value: 'CEST' },
    { label: 'NCM', value: 'NCM' },
    { label: 'IBPT', value: 'IBPT' },
  ]);
  const [isLoading, setIsLoading] = useState(false);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  useEffect(() => {
    const newTitle = 'Atualização de Tabelas Fiscais';

    dispatch(actions.titleHeaderChange({ newTitle }));
  }, [dispatch, navigate]);

  async function enviarTabelaFiscal(textoArquivo) {
    try {
      setIsLoading(true);

      let params = '';

      switch (tipoTabela) {
        case 'CFOP':
          params = '/cfop/importartabela';
          break;
        case 'CEST':
          params = '/cest/importartabela';
          break;
        case 'NCM':
          params = '/ncm/importartabela';
          break;
        case 'IBPT':
          params = '/ibpt/importartabela';
          break;
        default:
          break;
      }

      if (params === '') {
        toast.error('Tipo de Tabela Fiscal não informada!');
        return;
      }

      if (tipoTabela === 'NCM') {
        axiosAdmin.defaults.headers.post = { 'Content-Type': 'application/json' };
      } else {
        axiosAdmin.defaults.headers.post = { 'Content-Type': 'text/plain' };
      }

      await axiosAdmin.post(params, textoArquivo);

      toast.success('Tabela Fiscal atualizada com sucesso');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível enviar o arquivo!');
    } finally {
      setIsLoading(false);
    }
  }

  const handleImportaTabelaFiscal = files => {
    const reader = new FileReader();
    reader.onload = function () {
      enviarTabelaFiscal(reader.result);
    };

    reader.readAsText(files[0], tipoTabela === 'NCM' ? 'UTF-8' : 'ISO-8859-1');
  };

  const handleCancel = () => {
    navigate('/');
  };

  function retornaTipoArquivo() {
    if (tipoTabela === 'NCM') {
      return '.json';
    } else if (tipoTabela === 'IBPT') {
      return '.csv';
    } else {
      return '.txt';
    }
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
          <div className="row">
            <div className="col-lg-12">
              <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', top: '20%' }}>
                <h6>Atualização de Tabelas Fiscais</h6>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className="row form-elo">
          <div className="col-lg-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tabela Fiscal
              </Input155px>
              <Dropdown
                options={listaTipoTabela}
                value={tipoTabela}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setTipoTabela(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-lg-12">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ReactFileReader handleFiles={handleImportaTabelaFiscal} fileTypes={retornaTipoArquivo()} multipleFiles={false}>
                <button
                  type="button"
                  title="Importar Tabela Fiscal"
                  className="btn btn-primary"
                  style={{ borderRadius: '15px' }}
                >
                  <i className="pi pi-download"></i>
                  Importar Tabela
                </button>
              </ReactFileReader>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
