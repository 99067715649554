import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input100px, Input155px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function CadastroContaBancaria({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);

  const [banco, setBanco] = useState('');
  const [agencia, setAgencia] = useState('');
  const [conta, setConta] = useState('');
  const [descricao, setDescricao] = useState('');
  const [saldo, setSaldo] = useState(0);
  const [saldoPrevisto, setSaldoPrevisto] = useState(0);
  const [listaBancos, setListaBancos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let newTitle = 'Conta Bancária - Cadastro';
    if (id) {
      newTitle = 'Conta Bancária - Edição';
    }

    let mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getBancos() {
      try {
        setIsLoading(true);

        const listDropdown = [];

        const { data: _bancos } = await axiosAdmin.get('/banco');

        _bancos.forEach(element => {
          listDropdown.push({ label: `${element.codigo} ${element.descricao}`, value: element.codigo });
        });

        setListaBancos(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    getBancos();

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/contabancaria/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultacontabancaria');
          toast.error('Acesso negado');
        }

        setBanco(data.banco);
        setAgencia(data.agencia);
        setConta(data.conta);
        setDescricao(data.descricao);
        setSaldo(data.saldo);
        setSaldoPrevisto(data.saldoPrevisto);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const shippingData = {
        idUsuario,
        banco,
        bancoDescricao: banco.slice(4),
        agencia,
        conta,
        descricao,
        saldo,
        saldoPrevisto,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/contabancaria/${id}`, shippingData), 'data', {});
        toast.success('Conta Bancária atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/contabancaria', shippingData), 'data', {});
        toast.success('Conta Bancária cadastrado com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!banco) {
      formErros = true;
      toast.error('Banco deve ser informado');
    }

    if (!agencia) {
      formErros = true;
      toast.error('Agência deve ser informada');
    }

    if (!conta) {
      formErros = true;
      toast.error('Conta deve ser informada');
    }

    if (!descricao) {
      formErros = true;
      toast.error('Descrição deve ser informada');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id ? 'Conta Bancária - Alteração' : 'Conta Bancária - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>{titulo()}</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Banco
              </Input100px>
              <Dropdown
                autoFocus
                value={banco}
                options={listaBancos}
                onChange={(e) => setBanco(e.value)}
                filter
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Agência
              </Input155px>
              <InputText
                maxLength={10}
                value={agencia}
                onChange={e => setAgencia(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Conta
              </Input100px>
              <InputText
                maxLength={10}
                value={conta}
                onChange={e => setConta(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-9">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Descrição
              </Input100px>
              <InputText
                maxLength={100}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Saldo
              </Input100px>
              <InputNumber
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={saldo}
                onValueChange={e => setSaldo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Saldo Previsto
              </Input155px>
              <InputNumber
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={saldoPrevisto}
                onValueChange={e => setSaldoPrevisto(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
