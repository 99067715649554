/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';

import * as actions from '../../../store/modules/auth/actions';

import { soNumeros, formataTelefone, displayErrors, formataCnpjCpf } from '../../../util/diversos';
import { Input50px, Input100px, Input155px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function CadastroOficina({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const id = get(payloadCadastro, 'id', 0);
  const [nome, setNome] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [endereco, setEndereco] = useState('');
  const [enderecoNumero, setEnderecoNumero] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');
  const [telefone_1, setTelefone_1] = useState('');
  const [telefone_2, setTelefone_2] = useState('');

  useEffect(() => {
    let newTitle = 'Oficina - Cadastro';
    if (id) {
      newTitle = 'Oficina - Edição';
    }

    dispatch(actions.titleHeaderChange({ newTitle }));

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/oficina/${id}`);

        console.log('data: ', data);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        if (data.idUsuario !== idUsuario) {
          navigate('/consultaoficina');
          toast.error('Acesso negado');
        }

        setNome(data.nome);
        setCnpj(data.cnpj);
        setRazaoSocial(data.razaoSocial);
        setEndereco(data.endereco);
        setEnderecoNumero(data.enderecoNumero);
        setBairro(data.bairro);
        setCidade(data.cidade);
        setUf(data.uf);
        setTelefone_1(data.telefone_1);
        setTelefone_2(data.telefone_2);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const cnpjEmpresa = get(payloadCadastro, 'cnpjEmpresa', '');

      const shippingData = {
        idUsuario,
        cnpjEmpresa,
        nome,
        cnpj,
        razaoSocial,
        endereco,
        enderecoNumero,
        bairro,
        cidade,
        uf,
        telefone_1,
        telefone_2,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/oficina/${id}`, shippingData), 'data', {});
        toast.success('Ofcina atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/oficina', shippingData), 'data', {});
        toast.success('Oficina cadastrado com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (nome.length < 3 || nome.length > 40) {
      formErros = true;
      toast.error('Nome do técnico deve ter entre 3 a 50 caracteres');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id ? 'Técnico - Alteração' : 'Técnico - Inclusão');

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <div className="row form-elo">
          <div className="col-8">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nome
              </Input155px>
              <InputText
                maxLength={40}
                value={nome}
                onChange={e => setNome(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="card mb-3">
          <h6 className="card-header">Dados cabeçalho da ordem de serviço aparelho</h6>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                CPF/CNPJ
              </Input100px>
              <InputText
                maxLength={20}
                value={cnpj}
                onChange={e => setCnpj(e.target.value)}
                onFocus={e => setCnpj(soNumeros(e.target.value))}
                onBlur={e => setCnpj(formataCnpjCpf(e.target.value))}
              />
            </div>
          </div>
          <div className="col-8">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Razão Social
              </Input155px>
              <InputText
                maxLength={100}
                value={razaoSocial}
                onChange={e => setRazaoSocial(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Endereço
              </Input100px>
              <InputText
                maxLength={100}
                value={endereco}
                onChange={e => setEndereco(e.target.value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Número
              </Input100px>
              <InputText
                maxLength={20}
                value={enderecoNumero}
                onChange={e => setEnderecoNumero(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Bairro
              </Input155px>
              <InputText
                maxLength={50}
                value={bairro}
                onChange={e => setBairro(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Cidade
              </Input100px>
              <InputText
                value={cidade}
                onChange={e => setCidade(e.target.value)}
              />
            </div>
          </div>
          <div className="col-2">
            <div className="p-inputgroup">
              <Input50px className="p-inputgroup-addon">
                UF
              </Input50px>
              <InputText
                value={uf}
                onChange={e => setUf(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Telefone 1
              </Input155px>
              <InputText
                maxLength={20}
                value={telefone_1}
                onChange={e => setTelefone_1(e.target.value)}
                onFocus={e => setTelefone_1(soNumeros(e.target.value))}
                onBlur={e => setTelefone_1(formataTelefone(e.target.value))}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Telefone 2
              </Input100px>
              <InputText
                maxLength={20}
                value={telefone_2}
                onChange={e => setTelefone_2(e.target.value)}
                onFocus={() => setTelefone_2(soNumeros(telefone_2))}
                onBlur={() => setTelefone_2(formataTelefone(telefone_2))}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
