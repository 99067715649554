/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import ReactFileReader from 'react-file-reader';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { Checkbox } from 'primereact/checkbox';
import { get, orderBy } from 'lodash';
import { Base64 } from 'js-base64';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import dateFormat from 'dateformat';
import moment from 'moment';

import { obterListaTipoManifestacaoEvento } from '../../../services/tipoManifestacaoEvento';

import * as actions from '../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors, arredondar, formataDataMoment, formataTelefone } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';
import { Input100px, Input200px, Input400px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';
import CadastroNFePropria from './cadastroCTe';
import ConsultaProduto from '../produto/consultaProduto';
import ConsultaConversaoCfop from '../conversaoCfop/consultaConversaoCfop';

export default function ConsultaCTe(props) {
  const [isLoading, setIsLoading] = useState(false);

  const [propsTipoMovimentacao, setPropsTipoMovimentacao] = useState(props.tipoMovimentacao);

  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [serieEmpresa, setSerieEmpresa] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [ufEmpresa, setUfEmpresa] = useState('');

  const [idSelecionado, setIdSelecionado] = useState('');

  const [empresaTipoAmbiente, setEmpresaTipoAmbiente] = useState('');

  const [listaArquivosZipar, setListaArquivosZipar] = useState([]);
  const listaTipoManifestacaoEvento = obterListaTipoManifestacaoEvento();
  const [tipoManifestacaoEvento, setTipoManifestacaoEvento] = useState('CIENCIAOPERACAO');
  const [valorNotaDestinadaEvento, setValorNotaDestinadaEvento] = useState(0);
  const [showCamposRegistraEvento, setShowCamposRegistraEvento] = useState(false);

  const [dataTableListNotasDestinadas, setDataTableListNotasDestinadas] = useState([]);
  const [selectedNotasDestinadas, setSelectedNotasDestinadas] = useState(null);
  const [filtroDestinadas, setFiltroDestinadas] = useState('1');
  const destinadaOptions = [
    { label: 'Todas', value: '0' },
    { label: 'A Importar', value: '1' },
    { label: 'A Manifestar', value: '2' },
  ];

  const [showConsultaProduto, setShowConsultaProduto] = useState(false);
  const [showConsultaConversaoCfop, setShowConsultaConversaoCfop] = useState(false);
  const [showColetaXmlDialog, setShowColetaXmlDialog] = useState(false);
  const [showConfirmCancelamentoColetaXmlDialog, setShowConfirmCancelamentoColetaXmlDialog] = useState(false);
  const [arquivoXml, setArquivoXml] = useState(null);
  const [disablebotaoProcessar, setDisableBotaoProcessar] = useState(false);
  const [nameArquivoXml, setNameArquivoXml] = useState('');
  const [dataEmissao, setDataEmissao] = useState(new Date());

  const [cnpjFornecedor, setCnpjFornecedor] = useState('');
  const [razaoSocialFornecedor, setRazaoSocialFornecedor] = useState('');
  const [inscricaoEstadualFornecedor, setInscricaoEstadualFornecedor] = useState('');
  const [ufFornecedor, setUfFornecedor] = useState('');
  const [selectedNFeItens, setSelectedNFeItens] = useState(null);
  const [dataTableListNFeItem, setDataTableListNFeItem] = useState([]);

  const [motivoCancelamento, setMotivoCancelamento] = useState('');
  const [motivoCartaCorrecao, setMotivoCartaCorrecao] = useState('');
  const [motivoRegistraEvento, setMotivoRegistraEvento] = useState('Ciência da Operação');
  const [sequenciaEventoCartaCorrecao, setSequenciaEventoCartaCorrecao] = useState('');
  const [numeroLoteCartaCorrecao, setNumeroLoteCartaCorrecao] = useState('1');
  const [cnpjCartaCorrecao, setCnpjCartaCorrecao] = useState('');
  const [chaveAcesso, setChaveAcesso] = useState('');
  const [showCadastro, setShowCadastro] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [dataTableList, setDataTableList] = useState([]);
  const [dataTableListCartaCorrecao, setDataTableListCartaCorrecao] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConfirmCancelamentoDialog, setShowConfirmCancelamentoDialog] = useState(false);
  const [showConfirmCartaCorrecaoDialog, setShowConfirmCartaCorrecaoDialog] = useState(false);
  const [showConfirmRegistraEventoDialog, setShowConfirmRegistraEventoDialog] = useState(false);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [findDataInicialDestinadas, setFindDataInicialDestinadas] = useState(new Date('2000-01-01'));
  const [findDataFinalDestinadas, setFindDataFinalDestinadas] = useState(null);
  const [sortField, setSortField] = useState('item');
  const [sortOrder, setSortOrder] = useState(null);
  const [payloadCadastro, setPayloadCadastro] = useState({});

  const [columnsSetup] = useState([
    { field: 'data', header: 'Data', sort: true },
    { field: 'serie', header: 'Série', sort: false },
    { field: 'numeroNf', header: 'Número', sort: true },
    { field: 'chaveAcesso', header: 'Chave de Acesso', sort: true },
    { field: 'participante.razaoSocial', header: 'Participante', sort: false },
    { field: 'totalVNF', header: 'Total NF-e', sort: false },
    { field: 'codSit', header: 'Situação', sort: false },
    { field: 'finNFe', header: 'Finalidade', sort: false },
    { field: 'flag', header: 'Enviada', sort: true },
  ]);
  const [columnsSetupEntrada] = useState([
    { field: 'dhEmi', header: 'Emissão', sort: true, formatarData: true },
    { field: 'dhSaiEnt', header: 'Saída', sort: true, formatarData: true },
    { field: 'numeroNf', header: 'Número', sort: true },
    { field: 'chaveAcesso', header: 'Chave de Acesso' },
    { field: 'participante.cnpj', header: 'CNPJ', sort: false },
    { field: 'participante.razaoSocial', header: 'Participante', sort: true },
    { field: 'totalVNF', header: 'Total NF-e', sort: false },
    { field: 'totalVProd', header: 'Total Produtos', sort: false },
  ]);
  const [columnsSetupNotasDestinadas] = useState([
    { field: 'lancada', header: 'Lançada', sort: true },
    { field: 'data', header: 'Data', sort: true },
    { field: 'cnpj', header: 'CNPJ', sort: false },
    { field: 'razaoSocial', header: 'Razão Social', sort: true },
    { field: 'valor', header: 'Total NF-e', sort: false },
    { field: 'chaveAcesso', header: 'Chave de Acesso', sort: false },
    { field: 'situacao', header: 'Situação', sort: false },
    { field: 'manifestada', header: 'Manifestada', sort: false },
  ]);
  const [columnsSetupCartaCorrecao] = useState([
    { field: 'chaveAcesso', header: 'Chave Acesso', sort: false },
    { field: 'data', header: 'Data Evento', sort: false },
    { field: 'sequencia', header: 'Sequência Evento', sort: false },
    { field: 'motivo', header: 'Motivo', sort: false },
  ]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getData(searchFilter, filtrarCnpjEmpresa, page, size, tipoMovimentacao) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/nfe/search?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      let emissaoPropria = true;
      // eslint-disable-next-line no-unused-expressions
      tipoMovimentacao === 'entrada' ? emissaoPropria = false : '';

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy');

        const _dataFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy');
        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}&emissaoPropria=${emissaoPropria}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getDataNotasDestinadas(searchFilter, filtrarCnpjEmpresa, page, size) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/nfedestinada/search?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (filtroDestinadas === '1') {
        params = `${params}&lancada=false`;
      }

      if (filtroDestinadas === '2') {
        params = `${params}&manifestada=false`;
      }

      if (searchFilter && searchFilter.findDataInicialDestinadas !== '') {
        const _dataInicial = dateFormat(searchFilter.findDataInicialDestinadas, 'dd-mm-yyyy');

        const _dataFinal = dateFormat(searchFilter.findDataFinalDestinadas, 'dd-mm-yyyy');
        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setDataTableListNotasDestinadas(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  async function getDataCartaCorrecao(chave) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/evento?idUsuario=${idUsuario}`;

      if (cnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${cnpjEmpresa}`;
      }

      params = `${params}&chaveAcesso=${chave}&tipo=CARTACORRECAO`;

      const response = await axiosAdmin.get(params);

      setDataTableListCartaCorrecao(response.data);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows, propsTipoMovimentacao);
  };

  const handleFindDestinadas = () => {
    const searchFilter = { findDataInicialDestinadas, findDataFinalDestinadas };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getDataNotasDestinadas(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  };

  useEffect(() => {
    async function verificarSeMultiEmpresa(idUsuario, searchFilter) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        const _cnpjConsultaPrevisaoFinanceiraCjpEmpresa = store.getState().auth.consultaPrevisaoFinanceiraCnpjEmpresa;

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '') {
          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            const consultaPrevisaoFinanceiraCnpjEmpresa = _cnpjEmpresaStore;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            cnpjPreenchido = true;
          }
        }

        _listaEmpresas.forEach(element => {
          if (!cnpjPreenchido && (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '')) {
            setCnpjEmpresa(element.cnpj);
            setSerieEmpresa(element.nfeSerie);
            setUfEmpresa(element.uf);
            const consultaPrevisaoFinanceiraCnpjEmpresa = element.cnpj;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            _cnpjEmpresaStore = element.cnpj;
            cnpjPreenchido = true;
          }

          listDropdown.push(
            {
              label: element.razaoSocial.trim(),
              value: element.cnpj,
              serie: element.nfeSerie,
              uf: element.uf,
              nfeAmbiente: element.nfeAmbiente,
              aliquotaCofins: element.aliquotaCofins,
              aliquotaPis: element.aliquotaPis,
              id: element.id,
            });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (!cnpjPreenchido && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== null && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== '') {
          setCnpjEmpresa(_cnpjConsultaPrevisaoFinanceiraCjpEmpresa);
          _cnpjEmpresaStore = _cnpjConsultaPrevisaoFinanceiraCjpEmpresa;
        }

        if (propsTipoMovimentacao === 'saida') {
          getData(searchFilter, _cnpjEmpresaStore, 0, lazyParams.rows);
        }
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    if (findDataInicial === null) {
      setFindDataInicial(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial));
      setFindDataFinal(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal));

      const _dataInicial = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial);
      const _dataFinal = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal);

      const searchFilter = { findDataInicial: _dataInicial, findDataFinal: _dataFinal };

      verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0), searchFilter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const searchFilter = { findDataInicial, findDataFinal };
    if (propsTipoMovimentacao === 'saida') {
      listaEmpresas.forEach(element => {
        // console.log('empresa: ', element);
        if (element.value === cnpjEmpresa) {
          // eslint-disable-next-line no-unused-expressions
          element.nfeAmbiente === 'PRODUCAO' ? setEmpresaTipoAmbiente('Produção') : setEmpresaTipoAmbiente('AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL');
        }
      });
    }
    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows, propsTipoMovimentacao);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpjEmpresa, listaEmpresas, propsTipoMovimentacao]);

  const limpaCamposColetaXml = () => {
    setArquivoXml(null);
    setNameArquivoXml('');
    setCnpjFornecedor('');
    setRazaoSocialFornecedor('');
    setInscricaoEstadualFornecedor('');
    setUfFornecedor('');
    setDataTableListNFeItem([]);
    setDisableBotaoProcessar(false);
  };

  async function licencaValida(empresaId) {
    // eslint-disable-next-line consistent-return
    const retorno = await axiosAdmin.get(`/empresa/verificardatalicenca/${empresaId}`);
    return retorno.data;
  }

  // eslint-disable-next-line consistent-return
  const handleColetaXml = async () => {
    let empresaId = 0;

    listaEmpresas.forEach(element => {
      if (element.value === cnpjEmpresa) {
        empresaId = element.id;
      }
    });

    const isLicencaValida = await licencaValida(empresaId);
    if (!isLicencaValida) {
      toast.info('Cliente com licença expirada. Efetuar pagamento mensalidade.');
      navigate('/pagamento');
      return false;
    }

    limpaCamposColetaXml();
    setShowColetaXmlDialog(true);
    const searchFilter = { findDataInicialDestinadas, findDataFinalDestinadas };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);
    getDataNotasDestinadas(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  };

  // eslint-disable-next-line no-shadow, consistent-return
  const handleRegister = async (tipoMovimentacao, arquivoXml) => {
    const id = 0;

    let seriePadrao = '1';
    let uf = '';
    let aliquotaPis = 0;
    let aliquotaCofins = 0;
    let empresaId = 0;

    listaEmpresas.forEach(element => {
      if (element.value === cnpjEmpresa) {
        // eslint-disable-next-line no-unused-expressions
        (element.serie !== '') ? seriePadrao = element.serie : '';
        uf = element.uf;
        aliquotaCofins = element.aliquotaCofins;
        aliquotaPis = element.aliquotaPis;
        empresaId = element.id;
      }
    });

    const isLicencaValida = await licencaValida(empresaId);
    if (!isLicencaValida) {
      toast.info('Cliente com licença expirada. Efetuar pagamento mensalidade.');
      navigate('/pagamento');
      return false;
    }

    setPayloadCadastro(
      {
        id,
        cnpjEmpresa,
        seriePadrao,
        uf,
        chaveAcesso,
        tipoMovimentacao,
        propsTipoMovimentacao,
        arquivoXml,
        aliquotaPis,
        aliquotaCofins,
      },
    );

    setShowCadastro(true);
  };

  // eslint-disable-next-line consistent-return
  const handleEdit = async (e) => {
    e.preventDefault();

    let seriePadrao = '1';
    let uf = 'MG';
    let empresaId = 0;

    listaEmpresas.forEach(element => {
      if (element.value === cnpjEmpresa) {
        // eslint-disable-next-line no-unused-expressions
        (element.serie !== '') ? seriePadrao = element.serie : '';
        uf = element.uf;
        empresaId = element.id;
      }
    });

    const isLicencaValida = await licencaValida(empresaId);
    if (!isLicencaValida) {
      toast.info('Cliente com licença expirada. Efetuar pagamento mensalidade.');
      navigate('/pagamento');
      return false;
    }

    const id = get(selectedItens, 'id', 0);
    const codigoSituacao = get(selectedItens, 'codSit', 0);
    const enviada = get(selectedItens, 'flag', 0);

    if (codigoSituacao === 'CANCELADO') {
      toast.warn('Nota cancelada não pode ser editada');
      return false;
    }

    if (codigoSituacao === 'DENEGADO') {
      toast.warn('Nota DENEGADA não pode ser editada');
      return false;
    }

    if (id > 0) {
      setPayloadCadastro({ id, cnpjEmpresa, enviada, seriePadrao, uf });
      setShowCadastro(true);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const enviada = get(selectedItens, 'flag', 0);

    if (enviada === 'T' && propsTipoMovimentacao !== 'entrada') {
      toast.warn('Nota enviada não pode ser excluída');
      return;
    }

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const handleCartaCorrecao = (e) => {
    const enviada = get(e, 'flag', 0);
    const codigoSituacao = get(e, 'codSit', 0);
    setChaveAcesso(get(e, 'chaveAcesso', 0));
    setCnpjCartaCorrecao(cnpjEmpresa);
    setIdSelecionado(get(e, 'id', 0));

    if (enviada !== 'T') {
      toast.warn('Somente Nota enviada pode ter carta de correção.');
      return;
    }
    if (codigoSituacao !== 'REGULAR') {
      toast.warn('Somente notas regulares podem ter carta de correção.');
      return;
    }

    const id = get(e, 'id', 0);

    if (id > 0) {
      getDataCartaCorrecao(get(e, 'chaveAcesso', 0));
      setShowConfirmCartaCorrecaoDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para criar a carta de correção');
    }
  };

  const handleCancelamento = (e) => {
    const enviada = get(e, 'flag', 0);
    const codigoSituacao = get(e, 'codSit', 0);
    setIdSelecionado(get(e, 'id', 0));

    if (enviada !== 'T') {
      toast.warn('Somente Nota enviada pode ser cancelada');
      return;
    }

    if (codigoSituacao === 'CANCELADO') {
      toast.warn('Nota já esta cancelada.');
      return;
    }

    const id = get(e, 'id', 0);

    if (id > 0) {
      setShowConfirmCancelamentoDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para cancelamento');
    }
  };

  const cancelCancelamento = () => {
    setShowConfirmCancelamentoDialog(false);
    setMotivoCancelamento('');
  };

  const cancelCartaCorrecao = () => {
    setShowConfirmCartaCorrecaoDialog(false);
    setMotivoCartaCorrecao('');
  };

  const cancelRegistraEvento = () => {
    setShowConfirmRegistraEventoDialog(false);
    setMotivoRegistraEvento('');
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/nfe/${idExclusao}`);

        const _itens = dataTableList.filter(val => val.id !== idExclusao);
        setDataTableList(_itens);

        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let motivo = '';
    if (tipoManifestacaoEvento === 'CIENCIAOPERACAO') { motivo = 'Ciência da Operação'; }
    if (tipoManifestacaoEvento === 'CONFIRMACAOOPERACAO') { motivo = 'Confirmação da Operação'; }

    setMotivoRegistraEvento(motivo);
  }, [tipoManifestacaoEvento]);

  const confirmRegistraEvento = async () => {
    try {
      if (!chaveAcesso) {
        toast.info('chave de acesso inválida.');
        return;
      }
      if (chaveAcesso.includes(cnpjEmpresa)) {
        toast.info('Não é possível manisfestar uma nota de emissão própria.');
        return;
      }

      if (motivoRegistraEvento.length < 15) {
        toast.info('Motivo do evento inválido.');
        return;
      }

      setIsLoading(true);

      const id = get(selectedNotasDestinadas, 'id', 0);
      const idUsuario = get(store.getState().auth.user, 'id', 0);

      if (id > 0) {
        await axiosAdmin.get(`nfeutil/eventonfe?idUsuario=${idUsuario}&cnpjEmpresa=${cnpjEmpresa}&chaveAcesso=${chaveAcesso}&motivoEvento=${motivoRegistraEvento}&evento=${tipoManifestacaoEvento}`);
        setShowConfirmRegistraEventoDialog(false);

        toast.success('Evento enviado com sucesso');

        handleFindDestinadas();
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível manisfestar o evento');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmCartaCorrecao = async () => {
    if (motivoCartaCorrecao.length < 15) {
      toast.info('Motivo da carta de correção inválido.');
      return;
    }

    setShowConfirmCartaCorrecaoDialog(false);

    try {
      setIsLoading(true);

      const id = idSelecionado;

      if (id > 0) {
        await axiosAdmin.get(`/nfeutil/cartacorrecaonfe?id=${id}&motivocorrecao=${motivoCartaCorrecao}&cnpj=${cnpjCartaCorrecao}&sequencia=${sequenciaEventoCartaCorrecao}&numerolote=${numeroLoteCartaCorrecao}`);

        toast.success('Registro Cartao Correção realizado com sucesso');
        handleFind();
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível registrar a carta de correção');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmCancelamento = async () => {
    if (motivoCancelamento.length < 15) {
      toast.warn('Motivo cancelamento deve conter no mínimo 15 caracteres.');
      return;
    }

    setShowConfirmCancelamentoDialog(false);

    try {
      setIsLoading(true);

      const id = idSelecionado;

      if (id > 0) {
        await axiosAdmin.get(`/nfeutil/cancelarnfe?id=${id}&motivocancelamento=${motivoCancelamento}`);

        toast.success('Cancelamento registrado com sucesso');
        handleFind();
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível cancelar nota fiscal');
    } finally {
      setIsLoading(false);
    }
  };

  const downloadPdf = (response, chave) => {
    try {
      const byteCharacters = Base64.atob(response.data);

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      const blobFile = new Blob([byteArray], { type: 'application/pdf' });

      listaArquivosZipar.push({ file: blobFile, fileName: chave + '.pdf' });
    } catch (e) {
      toast.warn('Erro ao adicionar o pdf ao arquivo .zip');
    }
  };

  const visualizarPdf = (response) => {
    try {
      const byteCharacters = Base64.atob(response.data);

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      const blobFile = new Blob([byteArray], { type: 'application/pdf' });

      const pdf = URL.createObjectURL(blobFile);
      window.open(pdf);
    } catch (e) {
      toast.warn('Erro ao adicionar o pdf ao arquivo .zip');
    }
  };

  const handleVisualizarPdf = async (e) => {
    if (e.codSit === 'DENEGADO') {
      toast.info('Nota Denegada não pode ser impressa.');
      return;
    }

    try {
      setIsLoading(true);

      const id = get(e, 'id', 0);
      const chave = get(e, 'chaveAcesso', 0);

      if (id > 0) {
        await axiosAdmin.get(`/nfeutil/downloaddanfe?chaveacesso=${chave}`).then(res => {
          visualizarPdf(res, chave);
        });
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível baixar os arquivos');
    } finally {
      setIsLoading(false);
    }
  };

  const downloadXml = (responseXml, chave) => {
    try {
      const byteCharactersXml = Base64.atob(responseXml.data);

      const byteNumbersXml = new Array(byteCharactersXml.length);
      for (let i = 0; i < byteCharactersXml.length; i++) {
        byteNumbersXml[i] = byteCharactersXml.charCodeAt(i);
      }

      const byteArrayXml = new Uint8Array(byteNumbersXml);

      const blobFileXml = new Blob([byteArrayXml], { type: 'application/xml' });

      listaArquivosZipar.push({ file: blobFileXml, fileName: chave + '.xml' });
    } catch (e) {
      toast.info('Erro ao adicionar o arquivo xml ao .zip');
    }
  };

  const handleDownloadPdf = async (e) => {
    try {
      const id = get(e, 'id', 0);
      const chave = get(e, 'chaveAcesso', 0);

      if (id > 0) {
        await axiosAdmin.get(`/nfeutil/downloaddanfe?chaveacesso=${chave}`).then(res => {
          downloadPdf(res, chave);
        });
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível baixar os arquivos');
    }
  };

  const handleDownloadXml = async (e) => {
    try {
      const id = get(e, 'id', 0);
      const chave = get(e, 'chaveAcesso', 0);

      if (id > 0) {
        await axiosAdmin.get(`/xml/obterxml?chaveacesso=${chave}`).then(res => {
          downloadXml(res, chave);
        });
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível baixar os arquivos');
    }
  };

  const criarEBaixarArquivoZip = async (arquivos, chave) => {
    try {
      const zip = new JSZip();

      arquivos.forEach(element => {
        zip.file(element.fileName, element.file);
      });

      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, chave + '.zip');
      });

      setListaArquivosZipar([]);

      toast.success('Arquivo baixado com sucesso!');
    } catch (e) {
      toast.warn('Erro ao baixar arquivo zip.');
    }
  };

  const handleSalvarArquivos = async (e) => {
    if (e.codSit === 'DENEGADO') {
      toast.info('Nota Denegada não pode ser impressa.');
      return;
    }

    try {
      setIsLoading(true);
      const chave = get(e, 'chaveAcesso', 0);
      await handleDownloadXml(e);
      await handleDownloadPdf(e);
      await criarEBaixarArquivoZip(listaArquivosZipar, chave);
    } catch (err) {
      toast.warn('Não foi possível fazer download dos arquivos.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleImprimirCartaCorrecao = async (e) => {
    try {
      setIsLoading(true);

      // eslint-disable-next-line prefer-destructuring
      const modelo = e.modelo;
      // eslint-disable-next-line prefer-destructuring
      const serie = e.serie;
      const numero = e.chaveAcesso.substring(25, 34);
      const dataEvento = ('00' + e.data[2]).slice(-2) + '/' + ('00' + e.data[1]).slice(-2) + '/' + e.data[0];
      const xCorrecao = e.motivo;
      const dataEmissaoCarta = dataEvento;

      // eslint-disable-next-line new-cap
      const doc = new jsPDF('landscape');

      doc.autoTable({
        styles: { fontSize: 10 },
        margin: { bottom: 58, top: 38, left: 5, right: 5 },
        theme: 'grid',
        head: '',
        body: [],
        didDrawPage: (_dados) => {
          // Header
          doc.rect(5, 5, 287, 30);

          doc.setFontSize(20);
          doc.text('CARTA DE CORREÇÃO ELETRÔNICA', 85, 15);
          doc.setFontSize(15);
          doc.text('Não possui valor fiscal. Simples Representação do evento indicado abaixo', 57, 23);
          doc.setFontSize(15);
          doc.text('CONSULTE A AUTENTICIDADE NO SITE DA SEFAZ AUTORIZADORA', 58, 30);

          doc.setFontSize(15);
          doc.text('NOTA FISCAL ELETRÔNICA - NFe', 5, 43);

          // quadro nota fiscal
          doc.rect(5, 46, 287, 30);

          // modelo
          doc.rect(5, 46, 50, 15);
          doc.setFontSize(10);
          doc.text('MODELO', 7, 51);
          doc.setFontSize(20);
          doc.text(modelo, 25, 58);

          // serie
          doc.rect(55, 46, 50, 15);
          doc.setFontSize(10);
          doc.text('SÉRIE', 57, 51);
          doc.setFontSize(20);
          doc.text(serie, 70, 58);

          // numero
          doc.rect(105, 46, 90, 15);
          doc.setFontSize(10);
          doc.text('NÚMERO', 107, 51);
          doc.setFontSize(20);
          doc.text(numero, 125, 58);

          // data emissao
          doc.rect(195, 46, 97, 15);
          doc.setFontSize(10);
          doc.text('MÊS/ANO DA EMISSÃO', 197, 51);
          doc.setFontSize(20);
          doc.text(dataEmissaoCarta, 225, 58);

          // chaveacesso
          doc.rect(5, 61, 287, 15);
          doc.setFontSize(10);
          doc.text('CHAVE DE ACESSO', 7, 66);
          doc.setFontSize(20);
          doc.text(e.chaveAcesso, 55, 72);

          doc.setFontSize(15);
          doc.text('CARTA DE CORREÇÃO ELETRÔNICA', 5, 83);

          // quadro carta correcao
          doc.rect(5, 86, 287, 15);

          // orgão
          doc.rect(5, 86, 50, 15);
          doc.setFontSize(10);
          doc.text('ÓRGÃO', 7, 91);
          doc.setFontSize(20);
          doc.text(e.codigoOrgao, 25, 98);

          // data do evento
          doc.rect(55, 86, 140, 15);
          doc.setFontSize(10);
          doc.text('DATA DO EVENTO', 108, 91);
          doc.setFontSize(20);
          doc.text(dataEvento, 115, 98);

          // sequencia
          doc.rect(195, 86, 97, 15);
          doc.setFontSize(10);
          doc.text('SEQUÊNCIA DO EVENTO', 198, 91);
          doc.setFontSize(20);
          doc.text(e.sequencia, 235, 98);

          doc.setFontSize(15);
          doc.text('MOTIVO DA CARTA DE CORREÇÃO', 5, 109);

          // quadro carta correcao
          doc.rect(5, 112, 287, 50);

          doc.setFontSize(14);
          doc.text(xCorrecao.substring(0, 120), 8, 118);
          doc.text(xCorrecao.substring(120, 240), 8, 124);
          doc.text(xCorrecao.substring(240, 360), 8, 130);
          doc.text(xCorrecao.substring(360, 480), 8, 136);
          doc.text(xCorrecao.substring(480, 600), 8, 142);

          // footer
          doc.setFontSize(8);
          const { pageSize } = doc.internal;
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(`Pag ${doc.internal.getNumberOfPages()}`, _dados.settings.margin.right, pageHeight - 8);
          const periodo = new Date();
          doc.text('Impresso: ', 255, pageHeight - 8);
          // eslint-disable-next-line prefer-template
          doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 270, pageHeight - 8);
        },
      });
      window.open(doc.output('bloburl'));
    } catch (err) {
      // console.log(err);
      displayErrors(err, dispatch, navigate, '', 'Não foi possível imprimir a carta de correção');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDialogFooter = (
    <div>
      <Button className="btn-success" label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  const confirmCancelamentoDialogFooter = (
    <div>
      <Button className="btn-success" label="Sim" icon="pi pi-check" onClick={confirmCancelamento} />
      <Button label="Não" icon="pi pi-times" onClick={cancelCancelamento} />
    </div>
  );

  const confirmCartaCorrecaoDialogFooter = (
    <div>
      <Button className="btn-success" label="Sim" icon="pi pi-check" onClick={confirmCartaCorrecao} />
      <Button label="Não" icon="pi pi-times" onClick={cancelCartaCorrecao} />
    </div>
  );

  const confirmRegistraEventoDialogFooter = (
    <div>
      <Button className="btn-success" label="Sim" icon="pi pi-check" onClick={confirmRegistraEvento} />
      <Button label="Não" icon="pi pi-times" onClick={cancelRegistraEvento} />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findDataInicial, findDataFinal };

    getData(searchFilter, cnpjEmpresa, event.page, event.rows);
  };

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const childToParentConsulta = (payloadDataTableList, id) => {
    if (payloadDataTableList) {
      if (id > 0) {
        const index = dataTableList.map(e => e.id).indexOf(id);

        if (index >= 0) {
          dataTableList[index] = payloadDataTableList;
        }
      } else {
        const _dataTableList = [];
        _dataTableList.push(payloadDataTableList);

        setDataTableList(_dataTableList);

        const _lazyParams = { ...lazyParams, first: 0, page: 0 };
        setLazyParams(_lazyParams);
        setTotalRecords(1);
      }
    }

    setShowCadastro(false);
  };

  const handleVincularProduto = () => {
    setShowConsultaProduto(true);
  };

  // eslint-disable-next-line arrow-body-style
  const actionBodyNFeItensTemplate = (rowData) => {
    return (
      <div className="row col-12">
        <div className="col-lg-3">
          <button
            type="button"
            title="Vincular produto"
            className="btn-success rounded outlined"
            style={{ borderRadius: '15px' }}
            onClick={() => {
              handleVincularProduto();
              setSelectedNFeItens(rowData);
            }}
          >
            <i className="pi pi-tag"></i>
          </button>
        </div>
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => {
    if (rowData.flag === 'T' && propsTipoMovimentacao !== 'entrada') {
      return (
        <div className="row col-12">
          <div className="col-lg-3">
            <button
              type="button"
              title="Visualizar PDF"
              className="btn-dark rounded outlined"
              style={{ borderRadius: '15px' }}
              onClick={() => handleVisualizarPdf(rowData)}
            >
              <i className="pi pi-eye"></i>
            </button>
          </div>
          <div className="col-lg-3">
            <button
              type="button"
              title="Download PDF/XML"
              className="btn-success rounded outlined"
              style={{ borderRadius: '15px' }}
              onClick={() => handleSalvarArquivos(rowData)}
            >
              <i className="pi pi-download"></i>
            </button>
          </div>
          <div className="col-lg-3">
            <button
              type="button"
              title="Criar Carta de Correção"
              className="btn-info rounded outlined"
              style={{ borderRadius: '15px' }}
              onClick={() => handleCartaCorrecao(rowData)}
            >
              <i className="pi pi-envelope"></i>
            </button>
          </div>
          <div className="col-lg-3">
            <button
              type="button"
              title="Efetuar Cancelamento"
              className="btn-danger rounded outlined"
              style={{ borderRadius: '15px' }}
              onClick={() => handleCancelamento(rowData)}
            >
              <i className="pi pi-ban"></i>
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row col-12">
          <div className="col-lg-3">
            <button
              type="button"
              title="Visualizar PDF"
              className="btn-dark rounded outlined"
              style={{ borderRadius: '15px' }}
              onClick={() => handleVisualizarPdf(rowData)}
            >
              <i className="pi pi-eye"></i>
            </button>
          </div>
        </div>
      );
    }
  };

  const actionBodyTemplateCartaCorrecao = (rowData) => (
    <div className="row col-12">
      <div className="col-lg-4">
        <button
          type="button"
          title="Imprimir Carta Correção"
          className="btn-success rounded outlined"
          style={{ borderRadius: '15px' }}
          onClick={() => handleImprimirCartaCorrecao(rowData)}
        >
          <i className="pi pi-print"></i>
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    setDataTableList([]);
    setPropsTipoMovimentacao(props.tipoMovimentacao);
    if (props.tipoMovimentacao === 'saida') {
      const searchFilter = { findDataInicial, findDataFinal };

      listaEmpresas.forEach(element => {
        if (element.value === cnpjEmpresa) {
          // eslint-disable-next-line no-unused-expressions
          element.nfeAmbiente === 'PRODUCAO' ? setEmpresaTipoAmbiente('Produção') : setEmpresaTipoAmbiente('AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL');
        }
      });

      getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const renderTipoAmbiente = () => {
    if (empresaTipoAmbiente === 'AMBIENTE DE HOMOLOGAÇÃO - SEM VALOR FISCAL') {
      return (
        <h6 className="card-header" style={{ textAlign: 'center' }}>
          {empresaTipoAmbiente}
        </h6>
      );
    }
    return '';
  };

  const montaEmitente = async (data) => {
    setCnpjFornecedor(data.cnpj);
    setRazaoSocialFornecedor(data.xnome);
    setInscricaoEstadualFornecedor(data.ie);
    setUfFornecedor(data.enderEmit.uf);
  };

  // eslint-disable-next-line consistent-return
  const getCsosnCst = async () => {
    try {
      const response = await axiosAdmin.get('/conversaocsosncst?idUsuario=1');
      if (response.data) {
        return response.data;
      }
      return null;
    } catch (e) {
      toast.info('Não foi possível pegar a lista de Conversão CSOSC CST');
    }
  };

  const getImposto = async (data) => {
    let icms = '';
    data.forEach(el => {
      if (el.name.includes('ICMS')) {
        const result = Object.keys(el.value).map((key) => [el.value[key]]);
        // eslint-disable-next-line no-restricted-syntax
        for (const value of result) {
          if (value[0] !== null) {
            // eslint-disable-next-line prefer-destructuring
            icms = value[0];
            break;
          }
        }
      }
    });
    return icms;
  };

  const getConversaoXmlNfeByCnpjFornecedorandCodigoProduto = async (cnpjFor, cProd) => {
    try {
      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const response = await axiosAdmin.get(`/conversaoxmlnfe?idUsuario=${idUsuario}&cnpjFornecedor=${cnpjFor}&codigoFornecedor=${cProd}`);

      return response;
    } catch (e) {
      toast.info('Não foi possível pegar a conversão de xml nfe');
    }
    return null;
  };

  const getConversaoXmlNfe = async (item, chave, cProd, cnpjFor) => {
    try {
      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let response = await axiosAdmin.get(`/conversaoxmlnfe?idUsuario=${idUsuario}&chaveAcesso=${chave}&item=${item}`);

      if (response.data == null) {
        response = await getConversaoXmlNfeByCnpjFornecedorandCodigoProduto(cnpjFor, cProd);
      }
      return response;
    } catch (e) {
      toast.info('Não foi possível pegar a conversão de xml nfe');
    }
    return null;
  };

  const getConversaoCfop = async (cfop, cst) => {
    try {
      const idUsuario = get(store.getState().auth.user, 'id', 0);
      const response = await axiosAdmin.get(`/conversaocfop?idUsuario=${idUsuario}&cfopOrigem=${cfop}&cstOrigem=${cst}`);
      return response;
    } catch (e) {
      toast.info('Não foi possível pegar a conversão de cfop');
    }
    return null;
  };

  const getProdutoById = async (id) => {
    try {
      return await axiosAdmin.get(`/produto/${id}`);
    } catch (e) {
      toast.info('Não foi possível pegar a descrição do produto');
    }
    return '';
  };

  const getProdutoByRegistro = async (id) => {
    try {
      const idUsuario = get(store.getState().auth.user, 'id', 0);
      const response = await axiosAdmin.get(`/produto?idUsuario=${idUsuario}&cnpjEmpresa=${cnpjEmpresa}&registro=${id}`);
      if (response.data.length > 0) {
        return response.data[0];
      }
    } catch (e) {
      toast.info('Não foi possível pegar a descrição do produto');
    }
    return '';
  };

  const isNFeItensOk = async () => {
    let isOk = true;
    dataTableListNFeItem.forEach(element => {
      if (parseFloat(element.registro) > 0 && element.descricao && parseFloat(element.qtdePorCx) > 0 && element.cfopDestino && element.cstDestino) {
        element.ok = 'Ok';
      } else {
        isOk = false;
      }
    });
    return isOk;
  };

  const montaItens = async (data, chave, cnpjFor) => {
    try {
      const listaItem = [];
      let cst = '';
      const listaCsosnCst = await getCsosnCst();
      data.forEach(async el => {
        await getImposto(el.imposto.content).then(async res => {
          if (res.csosn !== undefined) {
            if (listaCsosnCst) {
              const cstDestino = await listaCsosnCst.find(element => element.csosnOrigem === res.csosn);
              cst = res.orig + cstDestino.cstDestino;
            }
          } else {
            cst = res.orig + res.cst;
          }

          const dataItem = {
            item: el.nitem,
            cprod: el.prod.cprod,
            xprod: el.prod.xprod,
            ucom: el.prod.ucom,
            qcom: arredondar(el.prod.qcom, 2),
            cfop: el.prod.cfop,
            cst,
            descricao: '',
          };

          await getConversaoCfop(el.prod.cfop, cst).then(resp => {
            if (resp.data.length > 0) {
              dataItem.cfopDestino = resp.data[0].cfopDestino;
              dataItem.cstDestino = resp.data[0].cstDestino;
            }
          });

          // pegar a conversaoxmlnfe e se houver dados preencher
          await getConversaoXmlNfe(el.nitem, chave, el.prod.cprod, cnpjFor).then(async conv => {
            if (conv.data !== null) {
              // console.log('conv.data: ', conv.data);
              const produto = await getProdutoById(conv.data.idProduto);
              // console.log('produto by id: ', produto);
              dataItem.qtdePorCx = conv.data.qtdePorCx;
              dataItem.cfopDestino = conv.data.cfopDestino;
              dataItem.cstDestino = conv.data.cstDestino;
              dataItem.descricao = produto.data.descricao;
              dataItem.registro = produto.data.registro;
              dataItem.idProduto = conv.data.idProduto;
              if (chave === conv.data.chaveAcesso) {
                dataItem.idConversaoXmlNFe = conv.data.id;
              } else {
                dataItem.idConversaoXmlNFe = undefined;
              }
            }
          });

          if (parseFloat(dataItem.registro) > 0 && dataItem.descricao && parseFloat(dataItem.qtdePorCx) > 0 && dataItem.cfopDestino && dataItem.cstDestino) {
            dataItem.ok = 'Ok';
          }

          listaItem.push(dataItem);
        });

        if (data.length === listaItem.length) {
          // eslint-disable-next-line no-undef
          const _listaItem = _.sortBy(listaItem, 'item', 'ASC');
          setDataTableListNFeItem([..._listaItem]);
        }
      });
    } catch (e) {
      toast.warn('Erro ao processar xml');
    }
  };

  // eslint-disable-next-line consistent-return
  const existeParticipante = async (data) => {
    if (data !== undefined) {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        // eslint-disable-next-line prefer-destructuring
        let cnpj = data.cnpj;
        if (data.cpf) {
          cnpj = data.cpf;
        }

        const params = `/participante?idUsuario=${idUsuario}&cnpj=${cnpj}&cnpjEmpresa=${cnpjEmpresa}`;

        const response = await axiosAdmin.get(params);

        if (response.data.length > 0) {
          return true;
        }
        return false;
      } catch (e) {
        toast.info('Não foi possível coletar o xml' + e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const salvarParticipanteTransportadora = async (data) => {
    try {
      setIsLoading(true);

      if (!await existeParticipante(data)) {
        const idUsuario = get(store.getState().auth.user, 'id', 0);

        // eslint-disable-next-line prefer-destructuring
        let cnpj = data.cnpj;
        if (data.cpf) {
          cnpj = data.cpf;
        }

        const response = await axiosAdmin.get(`municipio?descricao=${data.xmun}`);
        const codigoMunicipio = response.data[0].codigo;

        const shippingDataParticipante = {
          idUsuario,
          cnpjEmpresa,
          cnpj,
          inscricaoEstadual: data.ie ? data.ie : 'ISENTO',
          razaoSocial: data.xnome,
          nomeFantasia: '',
          endereco: data.xender,
          enderecoNumero: 0,
          enderecoComplemento: '',
          enderecoReferencia: '',
          bairro: 'centro',
          codigoMunicipio,
          cidade: data.xmun,
          uf: data.uf,
          codigoPais: '01058',
          cep: '00.000-000',
          telefone_1: '',
          telefone_2: '',
          ativo: true,
          dataCadastro: formataDataMoment(moment(), '-'),
          dataNascimento: '',
          email: '',
          rg: '',
          registroCliente: 0,
          registroFornecedor: 0,
          limiteCredito: 0,
          observacao: '',
          estadoCivil: '',
          nomePai: '',
          nomeMae: '',
          nomeConjuge: '',
          nomePaiConjuge: '',
          nomeMaeConjuge: '',
          cpfConjuge: '',
          rgConjuge: '',
          nomeContato: '',
          indIEDest: 0,
          autorizarCarteira: false,
        };

        await axiosAdmin.post('/participante', shippingDataParticipante);
      }
    } catch (e) {
      toast.warn('Não foi possível cadastrar a transportadora' + e);
    } finally {
      setIsLoading(false);
    }
  };

  const salvarParticipante = async (data) => {
    try {
      setIsLoading(true);

      if (!await existeParticipante(data)) {
        const idUsuario = get(store.getState().auth.user, 'id', 0);

        let codigoPais = data.enderEmit.cpais;
        if (codigoPais.length !== 5) {
          codigoPais = '0' + codigoPais;
        }

        // console.log('data ender emit: ', data.enderEmit);

        const cep = data.enderEmit.cep.substring(0, 2) + '.' + data.enderEmit.cep.substring(2, 5) + '-' + data.enderEmit.cep.substring(5, 8);

        // if (data.ie !== null && data.ie !== undefined && data.ie.length < 13) {
        //  data.ie = ('0000000000000' + data.ie).slice(-13);
        // }

        const shippingDataParticipante = {
          idUsuario,
          cnpjEmpresa,
          cnpj: data.cnpj,
          inscricaoEstadual: data.ie ? data.ie : 'ISENTO',
          razaoSocial: data.xnome,
          nomeFantasia: data.xfant ? data.xfant : '',
          endereco: data.enderEmit.xlgr,
          enderecoNumero: data.enderEmit.nro ? data.enderEmit.nro : 0,
          enderecoComplemento: data.enderEmit.xcpl ? data.enderEmit.xcpl : '',
          enderecoReferencia: '',
          bairro: data.enderEmit.xbairro ? data.enderEmit.xbairro : '',
          codigoMunicipio: data.enderEmit.cmun,
          cidade: data.enderEmit.xmun,
          uf: data.enderEmit.uf,
          codigoPais,
          cep,
          telefone_1: formataTelefone(data.enderEmit.fone),
          telefone_2: '',
          ativo: true,
          dataCadastro: formataDataMoment(moment(), '-'),
          dataNascimento: '',
          email: data.email ? data.email : '',
          rg: '',
          registroCliente: 0,
          registroFornecedor: 0,
          limiteCredito: 0,
          observacao: '',
          estadoCivil: '',
          nomePai: '',
          nomeMae: '',
          nomeConjuge: '',
          nomePaiConjuge: '',
          nomeMaeConjuge: '',
          cpfConjuge: '',
          rgConjuge: '',
          nomeContato: '',
          indIEDest: 0,
          autorizarCarteira: false,
        };

        // console.log('shippin participante: ', shippingDataParticipante);

        await axiosAdmin.post('/participante', shippingDataParticipante);
      }
    } catch (err) {
      // displayErrors(err, dispatch, navigate, '/', '');
      // toast.warn('Não foi possível cadastrar o fornecedor. Cadastre manualmente antes de prosseguir' + err);
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const processarXml = async (data) => {
    let empresaId = 0;

    listaEmpresas.forEach(element => {
      if (element.value === cnpjEmpresa) {
        empresaId = element.id;
      }
    });

    // eslint-disable-next-line consistent-return
    await axiosAdmin.get(`/empresa/verificardatalicenca/${empresaId}`).then(res => {
      if (!res.data) {
        toast.info('Cliente com licença expirada. Efetuar pagamento mensalidade.');
        navigate('/pagamento');
        return false;
      }
    });

    if (data.nfe.infNFe.ide.mod === '57') {
      toast.info('Notas Fiscal de Transporte não pode ser coletada');
      return false;
    }

    if (cnpjEmpresa !== data.nfe.infNFe.dest.cnpj && cnpjEmpresa !== data.nfe.infNFe.dest.cpf) {
      toast.warn('CNPJ do destinatário do XML não confere com o CNPJ da empresa');
      return false;
    }

    try {
      setIsLoading(true);

      setChaveAcesso(data.protNFe.infProt.chNFe);
      setDataEmissao(data.nfe.infNFe.ide.dhEmi);

      await montaEmitente(data.nfe.infNFe.emit);
      salvarParticipante(data.nfe.infNFe.emit);
      if (data.nfe.infNFe.transp.transporta) {
        salvarParticipanteTransportadora(data.nfe.infNFe.transp.transporta);
      }
      await montaItens(data.nfe.infNFe.det, data.protNFe.infProt.chNFe, data.nfe.infNFe.emit.cnpj);
      setDisableBotaoProcessar(true);
    } catch (e) {
      toast.info('Não foi possível coletar o xml 1');
    } finally {
      setIsLoading(false);
    }
  };

  const viewItensError = () => {
    for (let x = 0; x < dataTableListNFeItem.length; x++) {
      const element = dataTableListNFeItem[x];
      if (!element.registro || parseFloat(element.registro) <= 0) {
        toast.info(`Registro do item ${element.item} não informado`);
        break;
      }
      if (parseFloat(element.qtdePorCx) <= 0) {
        toast.info(`Qtde por cx do item ${element.item} não informada`);
        break;
      }
      if (!element.cfopDestino) {
        toast.info(`Conversão CFOP do item ${element.item} não cadastrada`);
        break;
      }
    }
  };

  const salvarConversaoXmlNFe = async () => {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const _dataEmissao = formataDataMoment(dataEmissao, '-');

      dataTableListNFeItem.forEach(async element => {
        const dataConversao = {
          cfopDestino: element.cfopDestino,
          chaveAcesso,
          cnpjFornecedor,
          codigoFornecedor: element.cprod,
          cstDestino: element.cstDestino,
          dataEmissao: _dataEmissao,
          idProduto: element.idProduto,
          idUsuario,
          item: element.item,
          qtdePorCx: parseFloat(element.qtdePorCx.toString().replace('.', '').replace(',', '.')),
          id: element.idConversaoXmlNFe,
        };

        // salvar conversaoxmlnfe
        if (dataConversao.id === undefined) {
          await axiosAdmin.post('/conversaoxmlnfe', dataConversao);
        } else {
          await axiosAdmin.put(`/conversaoxmlnfe/${dataConversao.id}`, dataConversao);
        }
      });
    } catch (e) {
      toast.warn('Erro ao salvar conversão xml nfe' + e);
    } finally {
      setIsLoading(false);
    }
  };

  const existeXml = async () => {
    try {
      const retorno = await axiosAdmin.get(`/xml/obterxml?chaveacesso=${chaveAcesso}`);
      return retorno.status;
    } catch (err) {
      return false;
    }
  };

  const salvarXml = async () => {
    try {
      const existe = await existeXml();
      if (!existe) {
        const xml = Base64.atob(arquivoXml.base64.split(',')[1]);
        const shippinData = {
          chaveAcesso,
          xml,
        };

        await axiosAdmin.post('/xml', shippinData);
      }
    } catch (e) {
      toast.warn('Erro ao salvar xml ' + e);
    }
  };

  const confirmFinalizarColetaXml = async () => {
    const itensOk = await isNFeItensOk();
    if (!itensOk) {
      viewItensError();
      return;
    }

    try {
      setIsLoading(true);

      await salvarConversaoXmlNFe();
      await salvarParticipante();
      await salvarXml();

      setShowColetaXmlDialog(false);

      handleRegister('entrada', arquivoXml);
    } catch (e) {
      toast.warn('Erro ao salvar conversão xml nfe' + e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function verifica() {
      await isNFeItensOk(dataTableListNFeItem);
    }
    verifica();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTableListNFeItem]);

  useEffect(() => {
    setNameArquivoXml('');
    setArquivoXml(null);
  }, [selectedNotasDestinadas]);

  const coletaByNotaFiscalDestinada = async () => {
    try {
      setIsLoading(true);

      if (!selectedNotasDestinadas || selectedNotasDestinadas.length === 0) {
        toast.warning('Por favor, selecione o registro para coleta');
        return;
      }

      const id = get(selectedNotasDestinadas, 'id', 0);

      if (id > 0) {
        const chave = get(selectedNotasDestinadas, 'chaveAcesso', 0);

        if (chave) {
          await axiosAdmin.get(`/xml/obterxml?chaveacesso=${chave}`).then(async response => {
            const _arquivoXml = {
              base64: `data:text/xml;base64,${response.data}`,
              fileList: {
                // eslint-disable-next-line quote-props
                '0': {},
              },
            };

            setArquivoXml(_arquivoXml);

            const shippingData = {
              xml: response.data,
            };

            const resposta = await axiosAdmin.post('/nfeutil/lexml/', shippingData);

            await processarXml(resposta.data);
          });
        }
      } else {
        toast.warning('Por favor, selecione o registro para coleta');
      }
    } catch (err) {
      toast.warn('Arquivo não contêm xml válido.' + err);
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const notaLancada = async (arquivo) => {
    try {
      if (arquivo) {
        setIsLoading(true);
        const idUsuario = get(store.getState().auth.user, 'id', 0);
        const chave = arquivo.protNFe.infProt.chNFe;

        const response = await axiosAdmin.get(`/nfe/chaveacesso?idUsuario=${idUsuario}&cnpjEmpresa=${cnpjEmpresa}&chaveAcesso=${chave}`);
        if (response.data.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    } catch (err) {
      toast.warn('Erro ao buscar nota fiscal por chave de acesso' + err);
    } finally {
      setIsLoading(false);
    }
  };

  const coletaByArquivoXml = async () => {
    try {
      setIsLoading(true);

      if (arquivoXml.base64.split(',')[1] !== null) {
        const shippingData = {
          xml: arquivoXml.base64.split(',')[1],
        };

        const resposta = await axiosAdmin.post('/nfeutil/lexml/', shippingData);

        // console.log('resposta lexml: ', resposta.data);

        // verifica se nota ja foi lancada
        if (await notaLancada(resposta.data)) {
          toast.info('Nota Fiscal já lancada no sistema');
          return;
        }

        const cnpjDest = resposta.data.nfe.infNFe.dest.cnpj;
        if (cnpjDest !== cnpjEmpresa) {
          toast.warn('CNPJ destinatário do XML difere do CNPJ Empresa selecionada');
          // return;
        }

        await processarXml(resposta.data);
      } else {
        toast.success('Não foi possível carregar o xml.');
      }
    } catch (e) {
      toast.warn('Arquivo não contêm xml válido.' + e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRegistraEvento = () => {
    const id = get(selectedNotasDestinadas, 'id', 0);

    if (id > 0) {
      setChaveAcesso(get(selectedNotasDestinadas, 'chaveAcesso', 0));
      setRazaoSocialFornecedor(get(selectedNotasDestinadas, 'razaoSocial', ''));
      setValorNotaDestinadaEvento(get(selectedNotasDestinadas, 'valor', 0));
      setShowCamposRegistraEvento(true);
    } else {
      setChaveAcesso('');
      setRazaoSocialFornecedor('');
      setValorNotaDestinadaEvento('');
      setShowCamposRegistraEvento(false);
    }
    setMotivoRegistraEvento('Ciência da Operação');
    setShowConfirmRegistraEventoDialog(true);
  };

  const confirmColetaXml = async () => {
    try {
      setIsLoading(true);

      if (arquivoXml === null && (!selectedNotasDestinadas || selectedNotasDestinadas.length === 0)) {
        toast.info('Nenhum arquivo xml ou nota destinada selecionada');
        return;
      }

      if (selectedNotasDestinadas && selectedNotasDestinadas.lancada === true) {
        toast.info('Nota destinada já foi lançada no sistema');
        return;
      }

      if (arquivoXml) {
        await coletaByArquivoXml(arquivoXml);
      } else {
        coletaByNotaFiscalDestinada();
      }
    } catch (e) {
      toast.warn('Arquivo não contêm xml válido.');
    } finally {
      setIsLoading(false);
    }
  };

  const cancelColetaXml = () => {
    if (dataTableListNFeItem.length > 0) {
      setShowConfirmCancelamentoColetaXmlDialog(true);
    } else {
      setShowColetaXmlDialog(false);
    }
  };

  const confirmCancelColetaXml = () => {
    setShowColetaXmlDialog(false);
    setShowConfirmCancelamentoColetaXmlDialog(false);
  };

  const confirmCancelarColetaXmlDialogFooter = (
    <div>
      <Button className="btn-success" label="Sim" icon="pi pi-check" onClick={confirmCancelColetaXml} />
      <Button label="Não" icon="pi pi-times" onClick={() => setShowConfirmCancelamentoColetaXmlDialog(false)} />
    </div>
  );

  const handleConversaoCfop = () => {
    setShowConsultaConversaoCfop(true);
  };

  const confirmColetaXmlDialogFooter = (
    <div className="row form-elo">
      <div className="col-2">
        {(arquivoXml !== null && cnpjFornecedor !== '') ? (
          <Button label="Conversão CFOP" className="btn btn-primary" icon="pi pi-sync" onClick={handleConversaoCfop} />
        ) : (
          <Button
            label="Registrar Evento"
            className="btn btn-success"
            icon="pi pi-send"
            onClick={handleRegistraEvento}
          />
        )}
      </div>
      <div className="col-6">

      </div>
      <div className="col-4">
        {disablebotaoProcessar ? (
          <Button
            label="Continuar"
            className="btn btn-success"
            disabled={!disablebotaoProcessar}
            icon="pi pi-check"
            onClick={confirmFinalizarColetaXml}
          />
        ) : (
          <Button
            label="Processar"
            className="btn btn-success"
            disabled={disablebotaoProcessar}
            icon="pi pi-check"
            onClick={confirmColetaXml}
          />
        )}
        <Button
          label="Cancelar"
          className="btn btn-danger"
          icon="pi pi-times"
          onClick={cancelColetaXml}
        />
      </div>
    </div>
  );

  const handleFiles = async (files) => {
    if (files != null) {
      if (!files.base64.split(',')[0].includes('xml')) {
        toast.warn('O arquivo coletado não é um xml');
        return;
      }

      setArquivoXml(files);
      setNameArquivoXml(files.fileList[0].name);
    }
  };

  const onHideConsultaProduto = () => {
    setShowConsultaProduto(false);
  };

  const handleConfirmVincularProduto = async (e) => {
    selectedNFeItens.idProduto = e.id;
    selectedNFeItens.registro = e.registro;
    selectedNFeItens.descricao = e.descricao.trim();
    // eslint-disable-next-line no-unused-expressions
    e.qtdePorCx > 0 ? selectedNFeItens.qtdePorCx = e.qtdePorCx : selectedNFeItens.qtdePorCx = '';
  };

  const childToParentConsultaProduto = async (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      await handleConfirmVincularProduto(e);
      await isNFeItensOk();
      setShowConsultaProduto(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const verificaNovaConversaoCfop = async (lista) => {
    lista.forEach(async element => {
      await getConversaoCfop(element.cfop, element.cst).then(resp => {
        if (resp.data.length > 0) {
          element.cfopDestino = resp.data[0].cfopDestino;
          element.cstDestino = resp.data[0].cstDestino;
        }
      });
      setDataTableListNFeItem([...lista]);
    });
  };

  const onHideConsultaConversaoCfop = async () => {
    await verificaNovaConversaoCfop(dataTableListNFeItem);
    setShowConsultaConversaoCfop(false);
  };

  const childToParentConsultaConversaoCfop = async () => {

  };

  const onCellEditComplete = async (e) => {
    const { rowData, field, originalEvent: event } = e;
    switch (field) {
      case 'registro':
        if (event.target.value.length > 0 && parseFloat(event.target.value) > 0) {
          await getProdutoByRegistro(event.target.value).then(res => {
            if (res) {
              rowData.registro = event.target.value;
              rowData.idProduto = res.id;
              rowData.descricao = res.descricao;
              rowData.qtdePorCx = res.qtdePorCx;
            } else {
              toast.info('Produto não encontrado');
            }
          });
          setDataTableListNFeItem([...dataTableListNFeItem]);
          await isNFeItensOk();
        } else {
          rowData.registro = '';
          rowData.descricao = '';
          rowData.qtdePorCx = '';
          rowData.ok = '';
          event.preventDefault();
        }
        break;
      default:
        if (event.target.value.length > 0) {
          rowData[field] = event.target.value;
          // eslint-disable-next-line no-unused-expressions
          (rowData.qtdePorCx === '0,00') ? rowData.ok = '' : '';
          await isNFeItensOk();
        } else {
          event.preventDefault();
        }
        break;
    }
  };

  const handleFocus = (event) => { event.target.value = ''; event.target.select(); };

  const qtdeporcxEditor = (options) => (
    <InputNumber
      onFocus={handleFocus}
      value={options.value}
      onValueChange={(e) => options.editorCallback(e.value)}
      mode="decimal"
      minFractionDigits={2}
      style={{ height: '20px', minWidth: '5rem' }}
    />
  );

  const registroEditor = (options) => (
    <InputNumber
      onFocus={handleFocus}
      value={options.value}
      onValueChange={(e) => options.editorCallback(e.value)}
      style={{ height: '20px', minWidth: '5rem' }}
    />
  );

  // eslint-disable-next-line consistent-return
  const cellEditor = (options) => {
    if (options.field === 'qtdePorCx') {
      return qtdeporcxEditor(options);
    }
    if (options.field === 'registro') {
      return registroEditor(options);
    }
  };

  const larguraColunaOpcoes = propsTipoMovimentacao !== 'entrada' ? '170px' : '0px';

  const statusOkTemplate = (rowData) => {
    let isChecked = false;
    if (rowData.ok) {
      isChecked = true;
    }
    return <Checkbox className="custom-checkbox" checked={isChecked} icon="pi pi-check" />;
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Produtos"
        visible={showConsultaProduto}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaProduto()}
      >
        <ConsultaProduto menuMode={false} childToParent={childToParentConsultaProduto} empresaCnpj={cnpjEmpresa} />
      </Dialog>

      <Dialog
        header="Cadastro Conversão CFOP"
        visible={showConsultaConversaoCfop}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaConversaoCfop()}
      >
        <ConsultaConversaoCfop menuMode={false} childToParent={childToParentConsultaConversaoCfop} empresaCnpj={cnpjEmpresa} />
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Coleta da Nota fiscal pelo XML ou Nota Fiscal Destinada</h6>}
        visible={showColetaXmlDialog}
        style={{ width: '90vw' }}
        footer={confirmColetaXmlDialogFooter}
        onHide={() => setShowColetaXmlDialog(false)}
      >
        {(arquivoXml !== null && cnpjFornecedor !== '') ? (
          <>
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Fornecedor
                  </Input100px>
                  <InputText
                    disabled
                    maxLength={14}
                    value={cnpjFornecedor}
                    onChange={e => setCnpjFornecedor(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <InputText
                    disabled
                    maxLength={100}
                    value={razaoSocialFornecedor}
                    onChange={e => setRazaoSocialFornecedor(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="p-inputgroup">
                  <InputText
                    disabled
                    maxLength={11}
                    value={inscricaoEstadualFornecedor}
                    onChange={e => setInscricaoEstadualFornecedor(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-1">
                <div className="p-inputgroup">
                  <InputText
                    disabled
                    maxLength={11}
                    value={ufFornecedor}
                    onChange={e => setUfFornecedor(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <DataTable
                value={dataTableListNFeItem}
                size="small"
                dataKey="item"
                selectionMode="single"
                editMode="cell"
                selection={selectedNFeItens}
                onSelectionChange={e => setSelectedNFeItens(e.value)}
              >
                <Column field="item" header="Ítem" sortable></Column>
                <Column field="cprod" header="Código"></Column>
                <Column field="xprod" header="Descrição Fornecedor"></Column>
                <Column field="registro" header="Registro" style={{ width: '10%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
                <Column field="descricao" header="Descrição"></Column>
                <Column field="ucom" header="Unid"></Column>
                <Column field="qcom" header="Qtde na NF"></Column>
                <Column field="qtdePorCx" header="Qtde por cx" style={{ width: '5%', background: '#ecffe6' }} editor={(options) => cellEditor(options)} onCellEditComplete={onCellEditComplete}></Column>
                <Column field="cfop" header="CFOP"></Column>
                <Column field="cst" header="CST"></Column>
                <Column field="cfopDestino" header="CFOP"></Column>
                <Column field="cstDestino" header="CST"></Column>
                <Column field="ok" header="OK" style={{ width: '3%', background: '#ecffe6' }} body={statusOkTemplate} disabled></Column>
                <Column body={actionBodyNFeItensTemplate} exportable={false} style={{ width: '50px' }}></Column>
              </DataTable>
            </div>
          </>
        ) : (
          <>
            <div className="row form-elo">
              <div className="col-lg-3">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Data inicial
                  </Input100px>
                  <Calendar
                    showIcon
                    mask="99/99/9999"
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showOnFocus={false}
                    locale="pt-BR"
                    value={findDataInicialDestinadas}
                    onChange={(e) => {
                      setFindDataInicialDestinadas(e.value);
                      const consultaPrevisaoFinanceiraDataInicial = e.value;
                      const consultaPrevisaoFinanceiraDataFinal = findDataFinal;
                      dispatch(actions.consultaPrevisaoFinanceiraDataInicialChange({ consultaPrevisaoFinanceiraDataInicial, consultaPrevisaoFinanceiraDataFinal }));
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Data final
                  </Input100px>
                  <Calendar
                    showIcon
                    mask="99/99/9999"
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showOnFocus={false}
                    locale="pt-BR"
                    value={findDataFinalDestinadas}
                    onChange={(e) => {
                      setFindDataFinalDestinadas(e.value);
                      const consultaPrevisaoFinanceiraDataInicial = findDataInicial;
                      const consultaPrevisaoFinanceiraDataFinal = e.value;
                      dispatch(actions.consultaPrevisaoFinanceiraDataInicialChange({ consultaPrevisaoFinanceiraDataInicial, consultaPrevisaoFinanceiraDataFinal }));
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Filtrar por:
                  </Input100px>
                  <Dropdown
                    options={destinadaOptions}
                    value={filtroDestinadas}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={(e) => setFiltroDestinadas(e.target.value)}
                  />
                  <button
                    type="button"
                    title="Buscar"
                    className="btn btn-success"
                    style={{ borderRadius: '15px' }}
                    onClick={handleFindDestinadas}
                  >
                    <i className="pi pi-search"></i>
                  </button>
                </div>
              </div>
              <div className="col-2">
                <div className="p-inputgroup">
                  <ReactFileReader fileTypes=".xml" base64 handleFiles={handleFiles}>
                    <div className="p-inputgroup">
                      <InputText
                        disabled
                        value={nameArquivoXml}
                        onChange={e => setNameArquivoXml(e.target.value)}
                      />
                      <button
                        title="Baixar Via Arquivo XML"
                        type="button"
                        className="btn btn-primary"
                        onClick={() => setSelectedNotasDestinadas(null)}
                      >
                        <i className="pi pi-download"></i>
                        XML
                      </button>
                    </div>
                  </ReactFileReader>
                </div>
              </div>
            </div>
            <div className="card">
              <DataTable
                value={dataTableListNotasDestinadas}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                sortField={sortField}
                sortOrder={sortOrder}
                onSort={onSort}
                selectionMode="single"
                selection={selectedNotasDestinadas}
                onSelectionChange={e => setSelectedNotasDestinadas(e.value)}
                selectableRows
                dataKey="id"
                paginator
                lazy
                first={lazyParams.first}
                rows={lazyParams.rows}
                totalRecords={totalRecords}
                onPage={onPage}
                emptyMessage="Nenhum registro a ser exibido"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{currentPage} de {totalPages}"
                rowsPerPageOptions={[10, 25, 50]}
              >
                {fillColumns(columnsSetupNotasDestinadas)}
              </DataTable>
            </div>
          </>
        )}
      </Dialog>

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroNFePropria payloadCadastro={payloadCadastro} childToParentConsulta={childToParentConsulta} />
      </Dialog>

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              Conhecimento de Transporte Eletrônico - Digitação
            </h6>
            {renderTipoAmbiente()}

          </div>
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data inicial
                </Input100px>
                <Calendar
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => {
                    setFindDataInicial(e.value);
                    const consultaPrevisaoFinanceiraDataInicial = e.value;
                    const consultaPrevisaoFinanceiraDataFinal = findDataFinal;
                    dispatch(actions.consultaPrevisaoFinanceiraDataInicialChange({ consultaPrevisaoFinanceiraDataInicial, consultaPrevisaoFinanceiraDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data final
                </Input100px>
                <Calendar
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => {
                    setFindDataFinal(e.value);
                    const consultaPrevisaoFinanceiraDataInicial = findDataInicial;
                    const consultaPrevisaoFinanceiraDataFinal = e.value;
                    dispatch(actions.consultaPrevisaoFinanceiraDataInicialChange({ consultaPrevisaoFinanceiraDataInicial, consultaPrevisaoFinanceiraDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              {listaEmpresas.length > 1 ? (
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Empresa
                  </Input100px>
                  <Dropdown
                    options={listaEmpresas}
                    value={cnpjEmpresa}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={(e) => {
                      setCnpjEmpresa(e.target.value);
                      const consultaPrevisaoFinanceiraCnpjEmpresa = e.target.value;
                      dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
                    }}
                  />
                </div>
              ) : ('')}
            </div>
            <div className="col-lg-3">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              { propsTipoMovimentacao === 'entrada' ? (
                <button
                  type="button"
                  title="XML"
                  className="btn btn-info"
                  style={{ borderRadius: '15px' }}
                  onClick={handleColetaXml}
                >
                  <i className="pi pi-download"></i>
                </button>
              ) : ('')}
              <button
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(propsTipoMovimentacao === 'entrada' ? columnsSetupEntrada : columnsSetup)}
            <Column body={actionBodyTemplate} exportable={false} style={{ width: `${larguraColunaOpcoes}` }}></Column>
          </DataTable>
        </div>

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>

        <Dialog
          header="Confirmação"
          visible={showConfirmCancelamentoColetaXmlDialog}
          style={{ width: '50vw' }}
          footer={confirmCancelarColetaXmlDialogFooter}
          onHide={() => setShowConfirmCancelamentoColetaXmlDialog(false)}
        >
          <p>
            Deseja cancelar a entrada de nota fiscal?
          </p>
        </Dialog>

        <Dialog
          header={<h6 className="card-header" style={{ textAlign: 'center' }}>Registro de Evento de Nota Fiscal Destinada</h6>}
          visible={showConfirmRegistraEventoDialog}
          style={{ width: '90vw' }}
          footer={confirmRegistraEventoDialogFooter}
          onHide={() => setShowConfirmRegistraEventoDialog(false)}
        >
          <div className="row form-elo">
            <div className="col-6">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Chave de Acesso
                </Input200px>
                <InputText
                  maxLength={44}
                  value={chaveAcesso}
                />
              </div>
            </div>
          </div>
          {showCamposRegistraEvento ? (
            <>
              <div className="row form-elo">
                <div className="col-6">
                  <div className="p-inputgroup">
                    <Input200px className="p-inputgroup-addon">
                      Fornecedor
                    </Input200px>
                    <InputText
                      disabled
                      maxLength={14}
                      value={razaoSocialFornecedor}
                      onChange={e => setRazaoSocialFornecedor(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-elo">
                <div className="col-6">
                  <div className="p-inputgroup">
                    <Input200px className="p-inputgroup-addon">
                      Valor
                    </Input200px>
                    <InputNumber
                      disabled
                      mode="currency"
                      inputId="currency-br"
                      currency="BRL"
                      locale="pt-BR"
                      minFractionDigits={2}
                      maxFractionDigits={2}
                      value={valorNotaDestinadaEvento}
                      onValueChange={e => valorNotaDestinadaEvento(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : ('')}
          <div className="row form-elo">
            <div className="col-6">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Movimentação
                </Input200px>
                <Dropdown
                  options={listaTipoManifestacaoEvento}
                  value={tipoManifestacaoEvento}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => setTipoManifestacaoEvento(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row form-elo">
            <div className="col-12">
              <p>
                Motivo deve conter mais de 15 caracteres.
              </p>
            </div>
            <div className="col-12">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Motivo do Evento
                </Input200px>
                <InputText
                  onFocus={(e) => e.target.select()}
                  maxLength={249}
                  value={motivoRegistraEvento}
                  onChange={e => setMotivoRegistraEvento(e.target.value)}
                />
              </div>
            </div>
          </div>
          <p>
            Confirmar o envio do evento?
          </p>
        </Dialog>

        <Dialog
          header={<h6 className="card-header" style={{ textAlign: 'center' }}>Carta de Correção</h6>}
          visible={showConfirmCartaCorrecaoDialog}
          style={{ width: '90vw' }}
          footer={confirmCartaCorrecaoDialogFooter}
          onHide={() => setShowConfirmCartaCorrecaoDialog(false)}
        >
          <div className="row form-elo">
            <div className="col-12">
              <p>
                Aviso: Não poderão ser sanados erros relacionados às variáveis consideradas no cálculo do valor do imposto.
                Tais como: valor da operação ou da prestação, base de cálculo e alíquota, dados cadastrais que impliquem
                alteração na identidade ou no enderecço de localização do remetente ou destinatário, data de emissão da
                NF-e ou data de saída da mercadoria.
              </p>
            </div>
          </div>
          <div className="row form-elo">
            <div className="col-6">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Chave de Acesso
                </Input200px>
                <InputText
                  disabled
                  maxLength={50}
                  value={chaveAcesso}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="p-inputgroup">
                <Input400px className="p-inputgroup-addon">
                  Código do orgão da recepção do evento
                </Input400px>
                <InputText
                  disabled
                  maxLength={2}
                  value={chaveAcesso.substring(0, 2)}
                />
              </div>
            </div>
          </div>
          <div className="row form-elo">
            <div className="col-6">
              <div className="p-inputgroup">
                <Input400px className="p-inputgroup-addon">
                  CNPJ ou CPF do autor do evento
                </Input400px>
                <InputText
                  disabled
                  maxLength={14}
                  value={cnpjCartaCorrecao}
                  onChange={e => setCnpjCartaCorrecao(e.target.value)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Sequência do evento
                </Input200px>
                <InputText
                  maxLength={5}
                  value={sequenciaEventoCartaCorrecao}
                  onChange={e => setSequenciaEventoCartaCorrecao(e.target.value)}
                />
              </div>
            </div>
            <div className="col-3">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Id do Lote de envio
                </Input200px>
                <InputText
                  maxLength={5}
                  value={numeroLoteCartaCorrecao}
                  onChange={e => setNumeroLoteCartaCorrecao(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row form-elo">
            <div className="col-12">
              <p>
                A correção mais recente substitui as anteriores. Motivo deve contar mais de 15 caracteres.
              </p>
            </div>
            <div className="col-12">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Motivo da Correção
                </Input200px>
                <InputText
                  maxLength={249}
                  value={motivoCartaCorrecao}
                  onChange={e => setMotivoCartaCorrecao(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row form-elo">
            <div className="col-12">
              <DataTable
                value={dataTableListCartaCorrecao}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                sortField={sortField}
                sortOrder={sortOrder}
                onSort={onSort}
                selectionMode="single"
                selection={selectedItens}
                onSelectionChange={e => setSelectedItens(e.value)}
                selectableRows
                dataKey="id"
                paginator
                lazy
                first={lazyParams.first}
                rows={lazyParams.rows}
                totalRecords={totalRecords}
                onPage={onPage}
                emptyMessage="Nenhum registro a ser exibido"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{currentPage} de {totalPages}"
                rowsPerPageOptions={[10, 25, 50]}
              >
                {fillColumns(columnsSetupCartaCorrecao)}
                <Column body={actionBodyTemplateCartaCorrecao} exportable={false} style={{ width: '50px' }}></Column>
              </DataTable>
            </div>
          </div>
          <p>
            Criar carta de correção?
          </p>
        </Dialog>

        <Dialog
          header={<h6 className="card-header" style={{ textAlign: 'center' }}>Cancelamento da Nota Fiscal</h6>}
          visible={showConfirmCancelamentoDialog}
          style={{ width: '70vw' }}
          footer={confirmCancelamentoDialogFooter}
          onHide={() => setShowConfirmCancelamentoDialog(false)}
        >
          <div className="row form-elo">
            <div className="col-12">
              <div className="p-inputgroup">
                <Input200px className="p-inputgroup-addon">
                  Motivo do Cancelamento
                </Input200px>
                <InputText
                  maxLength={50}
                  value={motivoCancelamento}
                  onChange={e => setMotivoCancelamento(e.target.value)}
                />
              </div>
            </div>
          </div>
          <p>
            Cancelar a Nota Fiscal Selecionada?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
