/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unneeded-ternary */
import React, { useState, useEffect, useRef } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get, orderBy, isNumber, findLast, isEmpty } from 'lodash';

import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { Base64 } from 'js-base64';
import moment from 'moment';
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';

import useSound from 'use-sound';
import mySound from '../../../sounds/notify.wav';

import * as actions from '../../../store/modules/auth/actions';

import { soNumeros, displayErrors, formataDataMoment, formataMoeda, arredondar, replaceAll, converteData, formataDataArray } from '../../../util/diversos';
import { Input100px, Input155px, Input400px, Input200px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

import { fillColumns } from '../../../components/table';

import ConsultaParticipante from '../participante/consultaParticipante';
import ConsultaProduto from '../produto/consultaProduto';

export default function CadastrarNovaVenda() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [playSound] = useSound(mySound);

  const UseFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => { htmlElRef.current && htmlElRef.current.focus(); };

    return [htmlElRef, setFocus];
  };

  const [inputProdutoRegistroFocus, setInputProdutoRegistroFocus] = UseFocus();
  const [inputProdutoQuantidadeFocus, setInputProdutoQuantidadeFocus] = UseFocus();
  const [inputProdutoDescontoFocus, setInputProdutoDescontoFocus] = UseFocus();
  const [inputProdutoPrecoVendaFocus, setInputProdutoPrecoVendaFocus] = UseFocus();

  const [produtoId, setProdutoId] = useState(null);
  const [produtoRegistro, setProdutoRegistro] = useState(null);
  const [produtoDescricao, setProdutoDescricao] = useState(null);
  const [produtoDescricaoResumida, setProdutoDescricaoResumida] = useState(null);
  const [produtoUnidadeMedida, setProdutoUnidadeMedida] = useState(null);
  const [produtoQuantidade, setProdutoQuantidade] = useState(1);
  const [produtoPrecoBruto, setProdutoPrecoBruto] = useState(0);
  const [produtoDesconto, setProdutoDesconto] = useState(0);
  const [produtoPrecoVenda, setProdutoPrecoVenda] = useState(0);
  const [produtoPrecoVenda2, setProdutoPrecoVenda2] = useState(0);
  const [produtoPrecoVenda3, setProdutoPrecoVenda3] = useState(0);
  const [produtoPrecoVenda4, setProdutoPrecoVenda4] = useState(0);
  const [produtoPrecoVenda5, setProdutoPrecoVenda5] = useState(0);
  const [produtoPrecoPromocao, setProdutoPrecoPromocao] = useState(0);
  const [produtoDataInicioPromocao, setProdutoDataInicioPromocao] = useState(null);
  const [produtoDataFimPromocao, setProdutoDataFimPromocao] = useState(null);

  const idUsuario = get(store.getState().auth.user, 'id', 0);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [listaEmpresas, setListaEmpresas] = useState([]);

  const [mudarEmpresaDisable, setMudarEmpresaDisable] = useState(true);
  const [listaProdutos, setListaProdutos] = useState([]);
  const [selectedProdutos, setSelectedProdutos] = useState(null);
  const [totalVenda, setTotalVenda] = useState(formataMoeda(0));
  const [totalVolumes, setTotalVolumes] = useState(0);

  const [listaPagamentos, setListaPagamentos] = useState([]);

  const [pagoDinheiro, setPagoDinheiro] = useState(0);
  const [pagoCheque, setPagoCheque] = useState(0);
  const [pagoCartao, setPagoCartao] = useState(0);
  const [pagoDigital, setPagoDigital] = useState(0);
  const [valorDesconto, setValorDesconto] = useState(0);
  const [valorPago, setValorPago] = useState(0);
  const [valorTroco, setValorTroco] = useState(0);

  const [tabelaPrecoAuxiliar, setTabelaPrecoAuxiliar] = useState('precoVenda');
  const [columnsSetupItem] = useState([
    { name: 'item', field: 'item', header: 'Item', sort: true},
    { field: 'registro', header: 'Registro', sort: true },
    { name: 'descricao', field: 'descricao', header: 'Descrição', sort: true, width: '50%' },
    { field: 'unidadeMedida', header: 'UN', sort: true },
    { field: 'quantidade', header: 'Qtde', sort: true },
    { field: 'precoVenda', header: 'Preço Venda', sort: false, moeda: true },
    { field: 'total', header: 'Total', sort: false, moeda: true },
    { field: 'desconto', header: 'Desc. %', sort: false },
  ]);

  const [listaTiposCartoes, setListaTiposCartoes] = useState([
    { label: 'Crédito', value: 'CREDITO' },
    { label: 'Débito', value: 'DEBITO' },
  ]);

  const [listaTiposPix, setListaTiposPix] = useState([
    { label: 'Pix', value: 'PIX' },
    { label: 'GooglePlay', value: 'GOOGLEPLAY' },
    { label: 'Pic Pay', value: 'PICPAY' },
    { label: 'Mercado Pago', value: 'MERCADOPAGO' },
  ]);

  const [listaTabelasPrecoAuxiliar, setListaTabelasPrecoAuxiliar] = useState([
    { label: 'Tabela 1', value: 'precoVenda' },
    { label: 'Tabela 2', value: 'precoVenda2' },
    { label: 'Tabela 3', value: 'precoVenda3' },
    { label: 'Tabela 4', value: 'precoVenda4' },
    { label: 'Tabela 5', value: 'precoVenda5' },
  ]);

  const [listaPagamentosCartoes, setListaPagamentosCartoes] = useState([]);
  const [selectedPagamentoCartao, setSelectedPagamentoCartao] = useState(null);
  const [tipoCartao, setTipoCartao] = useState('CREDITO');
  const [numeroParcelasCartao, setNumeroParcelasCartao] = useState(1);
  const [valorParcelamentoCartao, setValorParcelamentoCartao] = useState(0);
  const [valorRestanteCartao, setValorRestanteCartao] = useState(0);

  const [listaPagamentosPix, setListaPagamentosPix] = useState([]);
  const [selectedPagamentoPix, setSelectedPagamentoPix] = useState(null);
  const [tipoPix, setTipoPix] = useState('PIX');
  const [valorPagamentoPix, setValorPagamentoPix] = useState(0);
  const [valorRestantePix, setValorRestantePix] = useState(0);

  const [showEscolhaEmpresaDialog, setShowEscolhaEmpresaDialog] = useState(false);
  const [showComandosDialog, setShowComandosDialog] = useState(false);
  const [showAdministrativoDialog, setShowAdministrativoDialog] = useState(false);
  const [showConsultaProduto, setShowConsultaProduto] = useState(false);
  const [showConfirmDeleteProdutoDialog, setShowConfirmDeleteProdutoDialog] = useState(false);
  const [showConfirmCancelarVendaDialog, setShowConfirmCancelarVendaDialog] = useState(false);
  const [showProdutoNaoPodeSerFracionado, setShowProdutoNaoPodeSerFracionado] = useState(false);
  const [showCodigoProdutoInvalido, setShowCodigoProdutoInvalido] = useState(false);
  const [showQuantidadeProdutoInvalida, setShowQuantidadeProdutoInvalida] = useState(false);
  const [showProdutoNaoEncontrado, setShowProdutoNaoEncontrado] = useState(false);
  const [showPagamentoDialog, setShowPagamentoDialog] = useState(false);
  const [showConsultaParticipante, setShowConsultaParticipante] = useState(false);
  const [showPagamentoCartaoDialog, setShowPagamentoCartaoDialog] = useState(false);
  const [showPagamentoPixDialog, setShowPagamentoPixDialog] = useState(false);
  const [liberarFechamentoVenda, setLiberarFechamentoVenda] = useState(false);

  // dav
  const [idParticipante, setIdParticipante] = useState(null);
  const [clienteCnpj, setClienteCnpj] = useState('');
  const [clienteIe, setClienteIe] = useState('');
  const [clienteNome, setClienteNome] = useState('');
  const [clienteEMail, setClienteEMail] = useState('');

  const [totalVDesc, setTotalVDesc] = useState(0);

  const [vTroco, setVTroco] = useState(0);

  const fontSizeTitulo = (window.screen.availWidth / window.screen.availHeight) * 25;
  const fontSizeDadosEntrada = (window.screen.availWidth / window.screen.availHeight) * 20;
  const alturaScrollProdutos = window.innerHeight * 0.6;

  useEffect(() => {
    async function verificarSeMultiEmpresa() {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;
        const response = await axiosAdmin.get(params);
        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        const _cnpjConsultaPrevisaoFinanceiraCjpEmpresa = store.getState().auth.consultaPrevisaoFinanceiraCnpjEmpresa;

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '') {
          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            const consultaPrevisaoFinanceiraCnpjEmpresa = _cnpjEmpresaStore;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            cnpjPreenchido = true;
          }
        }

        _listaEmpresas.forEach(element => {
          if (!cnpjPreenchido && (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '')) {
            setCnpjEmpresa(element.cnpj);
            const consultaPrevisaoFinanceiraCnpjEmpresa = element.cnpj;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            _cnpjEmpresaStore = element.cnpj;
            cnpjPreenchido = true;
          }

          listDropdown.push({
            id: element.id,
            label: element.razaoSocial.trim(),
            value: element.cnpj,
            serie: element.nfeSerie,
            uf: element.uf,
            digitosBalanca: element.balancaDigitosCodigo,
            balancaPorValor: element.balancaPorValor,
          });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (!cnpjPreenchido && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== null && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== '') {
          setCnpjEmpresa(_cnpjConsultaPrevisaoFinanceiraCjpEmpresa);
          _cnpjEmpresaStore = _cnpjConsultaPrevisaoFinanceiraCjpEmpresa;
        }

        if (listDropdown.length > 1) {
          setShowEscolhaEmpresaDialog(true);
        }
        // getData(searchFilter, _cnpjEmpresaStore, 0, lazyParams.rows);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    verificarSeMultiEmpresa();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const limparCamposProduto = () => {
    setProdutoId(0);
    setProdutoRegistro('');
    setProdutoDescricao('');
    setProdutoDescricaoResumida('');
    setProdutoUnidadeMedida('');
    setProdutoQuantidade(1);
    setProdutoPrecoBruto(0);
    setProdutoDesconto(0);
    setProdutoPrecoVenda(0);
    setProdutoPrecoVenda2(0);
    setProdutoPrecoVenda3(0);
    setProdutoPrecoVenda4(0);
    setProdutoPrecoVenda5(0);
    setProdutoPrecoPromocao(0);
    setProdutoDataInicioPromocao(null);
    setProdutoDataFimPromocao(null);
  };

  useEffect(() => {
    limparCamposProduto();
  }, [listaProdutos]);

  const zerarTotaisVenda = () => {
    setTotalVDesc(0);
  };

  const fecharDialogos = () => {
    zerarTotaisVenda();
    setShowPagamentoDialog(false);
    setShowEscolhaEmpresaDialog(false);
    setShowAdministrativoDialog(false);
    setShowComandosDialog(false);
    setShowConfirmDeleteProdutoDialog(false);
    setShowConsultaProduto(false);
  };

  const inicializarVenda = () => {
    setListaProdutos([]);
    setSelectedProdutos(null);
    setTotalVenda(0);
    setTotalVolumes(0);

    fecharDialogos();
    limparCamposProduto();
  };

  useEffect(() => {
    let empresa = 0;
    listaEmpresas.forEach(el => {
      if (el.value === cnpjEmpresa) {
        empresa = el.id;
        setTotalVolumes(0);
      }
    });
    setShowPagamentoDialog(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cnpjEmpresa]);

  const handleCancelarVenda = () => {
    if (listaProdutos.length > 0) {
      setShowConfirmCancelarVendaDialog(true);
      setShowComandosDialog(false);
      return false;
    }
    toast.info('Nenhuma venda em aberto para cancelamento');
  };

  const handleConsultaProdutos = () => {
    setShowComandosDialog(false);
    setShowConsultaProduto(true);
  };

  const handleComandos = () => {
    setShowComandosDialog(true);
  };

  const handleAdministrativo = () => {
    setShowAdministrativoDialog(true);
  };

  // eslint-disable-next-line consistent-return
  const getProduto = async (codigo) => {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/produto?idUsuario=${idUsuario}`;

      params = `${params}&cnpjEmpresa=${cnpjEmpresa}`;

      params = `${params}&registro=${codigo}`;

      const response = await axiosAdmin.get(params);

      if (response.data.length > 0) {
        return response.data;
      } else {
        setShowProdutoNaoEncontrado(true);
        playSound();
        return null;
      }
    } catch (err) {
      toast.warn('Produto não encontrado ou falha de conexão' + err);
    } finally {
      setIsLoading(false);
    }
  };

  const zerarDescontoAlteracaoTabela = (listaProdutos) => {
    listaProdutos.forEach(el => {
      el.desconto = 0;
    });
  };

  const calcularTotalVenda = (listaProdutos) => {
    const _listaProdutos = [...listaProdutos];
    let total = 0;
    total = _listaProdutos.reduce((total, item) => {
      const valor = item.total ? item.total : 0;
      return total + +valor;
    }, 0);
    setTotalVenda(total);
  };

  const onHideConfirmDeleteProduto = () => {
    setShowConfirmDeleteProdutoDialog(false);
    setSelectedProdutos(null);
    setInputProdutoRegistroFocus();
  };

  const handleConfirmDeleteProduto = () => {
    const _listaProdutos = listaProdutos.filter(el => el.item !== selectedProdutos.item);

    // reordenar o item na lista
    _listaProdutos.forEach(element => {
      if (element.item > selectedProdutos.item) {
        const _index = _listaProdutos.indexOf(element);

        _listaProdutos[_index].item = _listaProdutos[_index].item - 1;
      }
    });

    setListaProdutos(_listaProdutos);
    setSelectedProdutos('');
    setShowConfirmDeleteProdutoDialog(false);
    calcularTotalVenda(_listaProdutos);
    setInputProdutoRegistroFocus();
  };

  const handleDeleteProduto = () => {
    setShowConfirmDeleteProdutoDialog(true);
  };

  const montaShippingData = async (_listaProdutos) => {
    const idUsuario = get(store.getState().auth.user, 'id', 0);

    const _vTroco = 0;

    const shippingData = {
      idUsuario,
      cnpjEmpresa: cnpjEmpresa.trim(),
      idParticipante,
      clienteNome,
      clienteCnpj,
      clienteIe,
      clienteEMail,
      totalVDesc: valorDesconto,
      vTroco: ((+pagoDinheiro + +pagoCheque + +pagoCartao + +pagoDigital) - (+totalVenda + +valorDesconto)).toFixed(2),
    };

    localStorage.setItem('venda', JSON.stringify(shippingData));

    // console.log('shippingdata: ', shippingData);

    return shippingData;
  };

  const calcularDescontoPorPrecoBrutoEPrecoVenda = async (precoVenda) => {
    if (produtoPrecoBruto > precoVenda) {
      setProdutoDesconto((((precoVenda - produtoPrecoBruto) / produtoPrecoBruto) * 100 * -1).toFixed(2));
    }
  };

  const atualizarListaProdutoPorTabela = async () => {
    let preco = 0;

    listaProdutos.forEach(el => {
      switch (tabelaPrecoAuxiliar) {
        case 'precoVenda':
          preco = el.precoVenda < el.precoBruto ? el.precoBruto : el.precoVenda;
          break;
        case 'precoVenda2':
          preco = el.precoVenda < el.precoVenda2 ? el.precoVenda : el.precoVenda2;
          break;
        case 'precoVenda3':
          preco = el.precoVenda < el.precoVenda3 ? el.precoVenda : el.precoVenda3;
          break;
        case 'precoVenda4':
          preco = el.precoVenda < el.precoVenda4 ? el.precoVenda : el.precoVenda4;
          break;
        case 'precoVenda5':
          preco = el.precoVenda < el.precoVenda5 ? el.precoVenda : el.precoVenda5;
          break;
        default:
          break;
      }

      if (preco > 0) {
        el.precoVenda = preco;
        el.total = preco * el.quantidade;
      }
    });

    setListaProdutos([]);
    setListaProdutos(listaProdutos);
    calcularTotalVenda(listaProdutos);
    zerarDescontoAlteracaoTabela(listaProdutos);
  };

  // eslint-disable-next-line consistent-return
  const handleAdicionarProduto = async () => {
    try {
      setIsLoading(true);

      if (produtoQuantidade <= 0) {
        inputProdutoQuantidadeFocus.current.focus();
        toast.info('Quantidade deve ser informada');
        return false;
      }

      if (produtoPrecoBruto <= 0) {
        toast.info('Preço bruto deve ser informado');
        return false;
      }

      if (produtoDesconto < 0) {
        inputProdutoQuantidadeFocus.current.focus();
        toast.info('Desconto inválido');
        return false;
      }

      if (produtoPrecoVenda <= 0) {
        inputProdutoQuantidadeFocus.current.focus();
        toast.info('Preço de Venda inválido');
        return false;
      }

      if (produtoRegistro) {
        if (produtoPrecoPromocao > 0 && produtoPrecoPromocao < produtoPrecoVenda) {
          let dataInicio = '';
          let dataFim = '';
          const dataAtual = new Date();
          dataAtual.setHours(0, 0, 1, 0);

          let usarPromocao = false;

          if (produtoDataInicioPromocao !== null && produtoDataFimPromocao !== null) {
            dataInicio = new Date(produtoDataInicioPromocao[0], produtoDataInicioPromocao[1] - 1, produtoDataInicioPromocao[2]);
            dataInicio.setHours(0, 0, 0, 0);
            dataFim = new Date(produtoDataFimPromocao[0], produtoDataFimPromocao[1] - 1, produtoDataFimPromocao[2]);
            dataFim.setHours(23, 59, 59, 0);

            if (dataInicio <= dataAtual && dataAtual <= dataFim) {
              usarPromocao = true;
            }
          } else if (produtoDataInicioPromocao !== null && produtoDataFimPromocao === null) {
            dataInicio = new Date(produtoDataInicioPromocao[0], produtoDataInicioPromocao[1] - 1, produtoDataInicioPromocao[2]);
            dataInicio.setHours(0, 0, 0, 0);

            if (dataAtual >= dataInicio) {
              usarPromocao = true;
            }
          } else if (produtoDataFimPromocao !== null && produtoDataInicioPromocao === null) {
            dataFim = new Date(produtoDataFimPromocao[0], produtoDataFimPromocao[1] - 1, produtoDataFimPromocao[2]);
            dataFim.setHours(23, 59, 59, 0);

            if (dataAtual <= dataFim) {
              usarPromocao = true;
            }
          } else if (produtoDataInicioPromocao === null && produtoDataFimPromocao === null) {
            usarPromocao = true;
          }

          if (usarPromocao) {
            setProdutoPrecoBruto(produtoPrecoPromocao);
          }
        }

        if ((produtoUnidadeMedida === 'UN' || produtoUnidadeMedida === 'PC') && !Number.isInteger(Number(produtoQuantidade))) {
          setShowProdutoNaoPodeSerFracionado(true);
          playSound();
          return false;
        }

        // verificar se produto ja existe na lista e substituir
        let existeProduto = false;
        listaProdutos.forEach(el => {
          if (el.id === produtoId) {
            existeProduto = true;
            el.id = produtoId;
            el.registro = produtoRegistro;
            el.descricao = produtoDescricaoResumida ? produtoDescricaoResumida : produtoDescricao;
            el.unidadeMedida = produtoUnidadeMedida ? produtoUnidadeMedida : 'UN';
            el.quantidade = produtoQuantidade;
            el.precoBruto = produtoPrecoBruto;
            el.desconto = produtoDesconto > 0 ? produtoDesconto : '0,00';
            el.precoVenda = produtoPrecoVenda;
            el.precoVenda2 = produtoPrecoVenda2;
            el.precoVenda3 = produtoPrecoVenda3;
            el.precoVenda4 = produtoPrecoVenda4;
            el.precoVenda5 = produtoPrecoVenda5;
            el.total = +produtoPrecoVenda * +produtoQuantidade;
          }
        });

        if (!existeProduto) {
          listaProdutos.push({
            item: listaProdutos.length + 1,
            id: produtoId,
            registro: produtoRegistro,
            descricao: produtoDescricaoResumida ? produtoDescricaoResumida : produtoDescricao,
            unidadeMedida: produtoUnidadeMedida ? produtoUnidadeMedida : 'UN',
            quantidade: produtoQuantidade,
            precoBruto: produtoPrecoBruto,
            desconto: produtoDesconto > 0 ? produtoDesconto : '0,00',
            precoVenda: produtoPrecoVenda,
            precoVenda2: produtoPrecoVenda2,
            precoVenda3: produtoPrecoVenda3,
            precoVenda4: produtoPrecoVenda4,
            precoVenda5: produtoPrecoVenda5,
            total: +produtoPrecoVenda * +produtoQuantidade,
          });
        }

        setListaProdutos(listaProdutos);

        // atualizarListaProdutoPorTabela();

        limparCamposProduto();

        calcularTotalVenda(listaProdutos);

        const _listaProdutos = orderBy(listaProdutos, 'item', 'asc');

        montaShippingData(_listaProdutos);
      }
    } catch (error) {
      console.log('error: ', error);
      toast.info('Ocorreu um erro ao adicionar produto');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSair = () => {
    if (listaProdutos.length > 0) {
      toast.info('Venda com produtos, saída não permitida.');
      return false;
    }
    navigate('/consultapontovenda');
  };

  useEffect(() => {
    if (totalVenda > 0) {
      const dinheiro = arredondar(pagoDinheiro, -2);
      const cheque = arredondar(pagoCheque, -2);
      const cartao = arredondar(pagoCartao, -2);
      const digital = arredondar(pagoDigital, -2);
      const desconto = arredondar(valorDesconto, -2);

      if (desconto < 0) {
        setValorDesconto(0);
        toast.info('Valor desconto não pode ser negativo');
        return false;
      }

      let troco = 0;

      const pago = dinheiro + cheque + cartao + digital;
      if (pago >= (totalVenda - desconto)) {
        if (desconto < totalVenda) {
          setLiberarFechamentoVenda(true);
        } else {
          setLiberarFechamentoVenda(false);
          toast.info('Desconto deve ser menor que o valor do venda');
        }
      } else {
        setLiberarFechamentoVenda(false);
      }

      troco = (pago - (totalVenda - desconto)) > 0 ? pago - (totalVenda - desconto) : 0;

      setValorTroco(arredondar(troco, -2));
      setValorPago(arredondar(pago - desconto, -2));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagoDinheiro, pagoCheque, pagoCartao, pagoDigital, valorDesconto]);

  const inicializarFechamentoVenda = () => {
    setPagoDinheiro(0);
    setPagoCheque(0);
    setPagoCartao(0);
    setPagoDigital(0);
    setIdParticipante(null);
    setClienteNome('');
    setClienteCnpj('');
    setClienteIe('');
    setClienteEMail('');
    setValorDesconto(0);
    setValorTroco(0);
    setListaPagamentos([]);
    setListaPagamentosCartoes([]);
    setNumeroParcelasCartao(1);
    setValorParcelamentoCartao(0);
    setValorRestanteCartao(0);
    setListaPagamentosPix([]);
    setValorPagamentoPix(0);
    setValorRestantePix(0);
  };

  const handlePagamento = async () => {
    if (totalVenda > 0) {
      inicializarFechamentoVenda();
      setShowPagamentoDialog(true);
    }
  };

  const handleF9 = async (e) => {
    if (listaProdutos.length > 0) {
      await handlePagamento();
      // const input = document.getElementById('pagoDinheiro');
      // input.focus();
    }
  };

  const childToParent = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (cnpjEmpresa === e.cnpjEmpresa.trim()) {
        setIdParticipante(idElement);

        if (e.razaoSocial) {
          setClienteNome(e.razaoSocial);
          setClienteCnpj(e.cnpj);
          setClienteIe(e.inscricaoEstadual);
          setClienteEMail(e.email);
        }

        setShowConsultaParticipante(false);
      } else {
        toast.warning('Participante selecionado não pertence a empresa emitente da NFC-e');
      }
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentConsultaProduto = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (cnpjEmpresa === e.cnpjEmpresa.trim()) {
        if (e.id) {
          setProdutoId(e.id);
          setProdutoRegistro(e.registro);
          setProdutoDescricao(e.descricao.trim());
          setProdutoDescricaoResumida(e.descricaoResumida ? e.descricaoResumida.trim() : '');
          setProdutoUnidadeMedida(e.unidadeMedida.sigla);
          setProdutoPrecoBruto(e.precoVenda);
          setProdutoDesconto(0);
          setProdutoPrecoVenda(e.precoVenda);
          setProdutoPrecoVenda2(e.precoVenda2);
          setProdutoPrecoVenda3(e.precoVenda3);
          setProdutoPrecoVenda4(e.precoVenda4);
          setProdutoPrecoVenda5(e.precoVenda5);

          switch (tabelaPrecoAuxiliar) {
            case 'precoVenda2':
              if (e.precoVenda2 > 0) {
                setProdutoPrecoVenda(e.precoPromocao > 0 ? e.precoPromocao : e.precoVenda2);
              }
              break;
            case 'precoVenda3':
              if (e.precoVenda2 > 0) {
                setProdutoPrecoVenda(e.precoPromocao > 0 ? e.precoPromocao : e.precoVenda3);
              }
              break;
            case 'precoVenda4':
              if (e.precoVenda2 > 0) {
                setProdutoPrecoVenda(e.precoPromocao > 0 ? e.precoPromocao : e.precoVenda3);
              }
              break;
            case 'precovenda5':
              if (e.precoVenda2 > 0) {
                setProdutoPrecoVenda(e.precoPromocao > 0 ? e.precoPromocao : e.precoVenda3);
              }
              break;
            default:
              break;
          }
          // console.log('e.promocao: ', e);
          if (e.precoPromocao > 0) {
            setProdutoPrecoBruto(e.precoPromocao);
            setProdutoPrecoPromocao(e.precoPromocao);
            setProdutoDataInicioPromocao(e.datainiciopromocao);
            setProdutoDataFimPromocao(e.dataFimPromocao);
          }
        }
        setShowConsultaProduto(false);
        setInputProdutoQuantidadeFocus();
      } else {
        toast.warn('Produto selecionado não pertence a empresa emitente da NFC-e');
      }
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  async function licencaValida(empresaId) {
    // eslint-disable-next-line consistent-return
    const retorno = await axiosAdmin.get(`/empresa/verificardatalicenca/${empresaId}`);
    return retorno.data;
  }

  const handleConfirmEscolhaEmpresa = async () => {
    let empresaId = 0;

    listaEmpresas.forEach(element => {
      if (element.value === cnpjEmpresa) {
        empresaId = element.id;
      }
    });

    const isLicencaValida = await licencaValida(empresaId);
    if (!isLicencaValida) {
      toast.info('Cliente com licença expirada. Efetuar pagamento mensalidade.');
      navigate('/pagamento');
      return false;
    }

    setProdutoRegistro(null);
    setTotalVenda(formataMoeda(0));
    setListaProdutos([]);
    setShowEscolhaEmpresaDialog(false);
    setInputProdutoRegistroFocus();
    // coletarVendaPendente();
  };

  const calcularProdutoDesconto = async () => {
    if (valorDesconto > 0) {
      const percDesc = valorDesconto / totalVenda;
      listaProdutos.forEach(el => {
        el.desconto = (+el.precoBruto * +percDesc) * el.qCom;
        el.precoVenda = +el.precoBruto - (+el.precoBruto * +percDesc);
      });
    }
  };

  const montaPagamento = async () => {
    let pagamento = {};
    /*
      dVenc: formataDataMoment(dhEmi, '-', true),
      indPag: 0, //  0 = a vista, 1 = prazo
      tPag: '01',

      01=Dinheiro
      02=Cheque
      03=Cartão de Crédito
      04=Cartão de Débito
      05=Crédito Loja
      10=Vale Alimentação
      11=Vale Refeição
      12=Vale Presente
      13=Vale Combustível
      15=Boleto Bancário
      16=Depósito Bancário
      17=Pagamento Instantâneo (PIX)
      18=Transferência bancária, Carteira Digital
      19=Programa de fidelidade, Cashback, Crédito Virtual
      90=Sem Pagamento;
      99=Outros.

      xPag: '', // descricao opcional
      vPag: 0, // valor pagamento
      cardTpIntegra: 2, // nao obrigatorio 1 = TEF, 2 = POS
      cardCnpj: '', // nao obrigatorio cnpj do adquirente
      cardTBand: '', // nao obrigatorio cnpj a instituicao de pagamento
      cardCAut: '', // nao obrigatorio numero da autorizacao da autorizacao do cartao
      */

    setListaPagamentos([]);

    if (pagoDinheiro > 0) {
      // pagamento.dVenc = formataDataMoment(dhEmi, '-', false);
      pagamento.indPag = 'AVISTA';
      pagamento.tPag = 'DINHEIRO';
      pagamento.vPag = pagoDinheiro;
      pagamento.xPag = 'DINHEIRO';
      listaPagamentos.push(pagamento);
    }

    if (listaPagamentosCartoes.length > 0) {
      listaPagamentosCartoes.forEach(el => {
        el.vencimento = replaceAll(el.vencimento, '/', '-');
      });

      listaPagamentosCartoes.forEach(el => {
        pagamento = {};
        pagamento.indPag = 'APRAZO';
        pagamento.dVenc = el.vencimento;
        pagamento.tPag = el.tipo === 'CREDITO' ? 'CARTAOCREDITO' : 'CARTAODEBITO';
        pagamento.vPag = el.valor;
        pagamento.xPag = el.tipo;
        listaPagamentos.push(pagamento);
      });
    }

    if (listaPagamentosPix.length > 0) {
      listaPagamentosPix.forEach(el => {
        el.vencimento = replaceAll(el.vencimento, '/', '-');
      });

      listaPagamentosPix.forEach(el => {
        pagamento = {};
        pagamento.indPag = 'AVISTA';
        // pagamento.dVenc = formataDataMoment(dhEmi, '-', false);
        pagamento.tPag = 'PIX';
        pagamento.xPag = el.tipo;
        pagamento.vPag = el.valor;
        listaPagamentos.push(pagamento);
      });
    }
  };

  const visualizarVenda = (response) => {
    try {
      const byteCharacters = Base64.atob(response.data);

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blobFile = new Blob([byteArray], { type: 'application/pdf' });

      const pdf = URL.createObjectURL(blobFile);
      window.open(pdf);
    } catch (e) {
      toast.warn('Erro ao adicionar o pdf ao arquivo .zip');
    }
  };

  const handleVisualizarVenda = async (e) => {
    try {
      const id = get(e, 'id', 0);
      const chave = get(e, 'chaveAcesso', 0);

      if (id > 0) {
        await axiosAdmin.get(`/nfceutil/downloaddanfce?chaveacesso=${chave}`).then(res => {
          visualizarVenda(res, chave);
        });
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível baixar os arquivos');
    }
  };

  const salvarRegistro = async () => {
    setIsLoading(true);

    if (isNaN(valorDesconto) || valorDesconto === '' || valorDesconto === undefined || valorDesconto === null || valorDesconto < 0) {
      toast.info('valor desconto inválido');
      return false;
    }

    if (+pagoDinheiro + +pagoCheque + +pagoCartao + +pagoDigital <= 0) {
      toast.info('Confira as formas de pagamento antes do envio.');
      return false;
    }

    if (pagoCartao > 0 && listaPagamentosCartoes.length <= 0) {
      toast.info('Confira as formas de pagamento de cartão.');
      return false;
    }

    if (pagoDigital > 0 && listaPagamentosPix.length <= 0) {
      toast.info('Confira as formas de pagamento de pix.');
      return false;
    }

    if (pagoCartao > 0 || pagoDigital > 0) {
      if (valorTroco > 0) {
        toast.info('Troco não permitido para pagamento multiplo contendo cartão ou digital.');
        return false;
      }
    }

    if ((totalVenda - valorDesconto) > 3000 && clienteNome === '' && clienteCnpj === '') {
      toast.info('NFC-e com valor maior que R$ 3.000,00 deve ter indentificação do cliente.');
      return false;
    }

    if (clienteNome !== '' && clienteCnpj === '') {
      toast.info('Nome e CNPJ/CPF precisam devem ser preenchidos.');
      setLiberarFechamentoVenda(true);
      return false;
    }

    if (clienteNome === '' && clienteCnpj !== '') {
      toast.info('Nome e CNPJ/CPF precisam devem ser preenchidos.');
      setLiberarFechamentoVenda(true);
      return false;
    }

    if (clienteCnpj !== '' && (clienteCnpj.length !== 11 && clienteCnpj.length !== 14)) {
      toast.info('CNPJ/CPF devem ter 14 ou 11 caracteres respectivamente.');
      setLiberarFechamentoVenda(true);
      return false;
    }

    if (clienteCnpj !== '') {
      if (!cpfValidator.isValid(soNumeros(clienteCnpj)) && !cnpjValidator.isValid(soNumeros(clienteCnpj))) {
        toast.info('CNPJ/CPF inválido.');
        setLiberarFechamentoVenda(true);
        return false;
      }
    }

    const idUsuario = get(store.getState().auth.user, 'id', 0);

    try {
      setLiberarFechamentoVenda(false);
      await calcularProdutoDesconto().then(async () => {
        await montaPagamento().then(async () => {
          const _listaProdutos = orderBy(listaProdutos, 'item', 'asc');

          const shippingData = await montaShippingData(_listaProdutos);

          // console.log('shippingData', shippingData);

          const vendaPendente = JSON.parse(localStorage.getItem('venda'));
          if (vendaPendente !== null && vendaPendente.cnpjEmpresa === cnpjEmpresa) {
            shippingData.id = vendaPendente.id;
          }

          if (shippingData.id && shippingData.id > 0) {
            // await axiosAdmin.delete(`/nfce/${shippingData.id}`);
          } else {
            shippingData.id = '';
          }
        });
      });
    } catch (err) {
      // console.log('err: ', err);
      displayErrors(err, dispatch, '/', '');
    } finally {
      setIsLoading(false);
      setLiberarFechamentoVenda(true);
    }
  };

  const handleConfirmCancelarVenda = () => {
    setListaProdutos([]);
    setTotalVenda(formataMoeda(0));
    setTotalVolumes(0);
    setProdutoRegistro(null);
    setSelectedProdutos(null);
    setShowConfirmCancelarVendaDialog(false);
    localStorage.removeItem('venda');
  };

  const handleCancelDeleteProduto = () => {
    setSelectedProdutos(null);
    setShowConfirmDeleteProdutoDialog(false);
  };

  const handleCancelEscolhaEmpresa = () => {
    handleSair();
  };

  const dialogEscolhaEmpresaFooter = (
    <div>
      <Button
        type="button"
        className="btn btn-success"
        onClick={handleConfirmEscolhaEmpresa}
      >
        <i className="pi pi-check"></i>
        Confirmar
      </Button>
      <Button
        type="button"
        className="btn btn-danger"
        onClick={handleCancelEscolhaEmpresa}
      >
        <i className="pi pi-times"></i>
        Cancelar
      </Button>
    </div>
  );

  const handleConfirmPagamentosCartao = () => {
    toast.info('confirmação de cartões');
    if (valorRestanteCartao !== 0 && listaPagamentosCartoes.length > 0) {
      toast.info('Total informado cartão não confere com parcela(s) cadastrada(s)');
      return false;
    }
    setShowPagamentoCartaoDialog(false);
  };

  const handleConfirmaPagamentosPix = () => {
    toast.info('confirmção de pix');
    if (valorRestantePix !== 0 && listaPagamentosPix.length > 0) {
      toast.info('Total informado pix não confere com pix cadastrado(s)');
      return false;
    }
    setShowPagamentoPixDialog(false);
  };

  const dialogPagamentoCartaoFooter = (
    <div>
      <Button
        type="button"
        className="btn btn-success"
        onClick={() => handleConfirmPagamentosCartao()}
      >
        <i className="pi pi-check"></i>
        Continuar
      </Button>
    </div>
  );

  const dialogPagamentoPixFooter = (
    <div>
      <Button
        type="button"
        className="btn btn-success"
        onClick={() => handleConfirmaPagamentosPix()}
      >
        <i className="pi pi-check"></i>
        Continuar
      </Button>
    </div>
  );

  const dialogCancelarVendaFooter = (
    <div>
      <Button
        type="button"
        className="btn btn-success"
        onClick={handleConfirmCancelarVenda}
      >
        <i className="pi pi-check"></i>
        Sim
      </Button>
      <Button
        type="button"
        className="btn btn-danger"
        onClick={() => setShowConfirmCancelarVendaDialog(false)}
      >
        <i className="pi pi-times"></i>
        Não
      </Button>
    </div>
  );

  const dialogDeleteProdutoFooter = (
    <div>
      <Button
        type="button"
        className="btn btn-success"
        onClick={handleConfirmDeleteProduto}
      >
        <i className="pi pi-check"></i>
        Continuar
      </Button>
      <Button
        type="button"
        className="btn btn-danger"
        onClick={handleCancelDeleteProduto}
      >
        <i className="pi pi-times"></i>
        Cancelar
      </Button>
    </div>
  );

  // eslint-disable-next-line consistent-return
  async function handleBuscarProdutoByIdUsuarioEmpresa(e) {
    if (e.length <= 0 || e === '0') {
      inputProdutoRegistroFocus;
      return false;
    }

    try {
      setIsLoading(true);

      let params = `/produto/search?idUsuario=${idUsuario}`;

      params = `${params}&cnpjEmpresa=${cnpjEmpresa}`;

      params = `${params}&registro=${e.trim()}`;

      const response = await axiosAdmin.get(params);

      if (response.data) {
        // console.log(response.data.content[0]);
        setProdutoId(response.data.content[0].id);
        setProdutoRegistro(response.data.content[0].registro);
        setProdutoDescricao(response.data.content[0].descricao);
        setProdutoDescricaoResumida(response.data.content[0].descricaoResumida);
        setProdutoUnidadeMedida(response.data.content[0].unidadeMedida.sigla);
        setProdutoPrecoBruto(response.data.content[0].precoPromocao > 0 ? response.data.content[0].precoPromocao : response.data.content[0].precoVenda);
        setProdutoPrecoVenda(response.data.content[0].precoPromocao > 0 ? response.data.content[0].precoPromocao : response.data.content[0].precoVenda);
        setProdutoPrecoVenda2(response.data.content[0].precoPromocao > 0 ? response.data.content[0].precoPromocao : response.data.content[0].precoVenda2);
        setProdutoPrecoVenda3(response.data.content[0].precoPromocao > 0 ? response.data.content[0].precoPromocao : response.data.content[0].precoVenda3);
        setProdutoPrecoVenda4(response.data.content[0].precoPromocao > 0 ? response.data.content[0].precoPromocao : response.data.content[0].precoVenda4);
        setProdutoPrecoVenda5(response.data.content[0].precoPromocao > 0 ? response.data.content[0].precoPromocao : response.data.content[0].precoVenda5);
        setProdutoDesconto(0);
        setProdutoPrecoPromocao(response.data.content[0].precoPromocao);
        setProdutoDataInicioPromocao(response.data.content[0].dataInicioPromocao);
        setProdutoDataFimPromocao(response.data.content[0].dataFimPromocao);

        let precoVenda = 0;
        switch (tabelaPrecoAuxiliar) {
          case 'precoVenda':
            if (response.data.content[0].precoVenda > 0) {
              precoVenda = response.data.content[0].precoPromocao > 0 ? response.data.content[0].precoPromocao : response.data.content[0].precoVenda;
            }
            break;
          case 'precoVenda2':
            if (response.data.content[0].precoVenda2 > 0) {
              precoVenda = response.data.content[0].precoPromocao > 0 ? response.data.content[0].precoPromocao : response.data.content[0].precoVenda2;
            }
            break;
          case 'precoVenda3':
            if (response.data.content[0].precoVenda2 > 0) {
              precoVenda = response.data.content[0].precoPromocao > 0 ? response.data.content[0].precoPromocao : response.data.content[0].precoVenda3;
            }
            break;
          case 'precoVenda4':
            if (response.data.content[0].precoVenda2 > 0) {
              precoVenda = response.data.content[0].precoPromocao > 0 ? response.data.content[0].precoPromocao : response.data.content[0].precoVenda3;
            }
            break;
          case 'precovenda5':
            if (response.data.content[0].precoVenda2 > 0) {
              precoVenda = response.data.content[0].precoPromocao > 0 ? response.data.content[0].precoPromocao : response.data.content[0].precoVenda3;
            }
            break;
          default:
            break;
        }

        setProdutoPrecoVenda(precoVenda);
        calcularDescontoPorPrecoBrutoEPrecoVenda(precoVenda);

        setInputProdutoQuantidadeFocus();
      } else {
        toast.info('Produto não encontrado');
        setInputProdutoRegistroFocus();
      }
    } catch (err) {
      setProdutoDescricao('');
      setInputProdutoRegistroFocus();
      toast.info('Produto não encontrado');

      // displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const calcularPrecoVenda = (desconto) => {
    if (desconto > 0) {
      setProdutoPrecoVenda(produtoPrecoVenda - (produtoPrecoVenda * (desconto / 100)));
    }
  };

  const dialogDavFooter = (
    <>
      <div className="row form-elo">
        <div className="col-3">
          <div className="p-inputgroup">
            <Input155px className="p-inputgroup-addon">
              Produto*
            </Input155px>
            <InputText
              ref={inputProdutoRegistroFocus}
              value={produtoRegistro}
              onChange={e => setProdutoRegistro(e.target.value)}
              onBlur={e => {
                handleBuscarProdutoByIdUsuarioEmpresa(e.target.value);
                inputProdutoQuantidadeFocus;
              }}
              onKeyDown={async (e) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                  handleBuscarProdutoByIdUsuarioEmpresa(e.target.value);
                }
              }}
            />
            <Button
              type="Button"
              className="p-button-warning"
              onClick={() => setShowConsultaProduto(true)}
            >
              <i className="pi pi-search"></i>
            </Button>
          </div>
        </div>
        <div className="col-6">
          <div className="p-inputgroup">
            <InputText
              disabled
              maxLength={255}
              value={produtoDescricao}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="p-inputgroup">
            <Input155px className="p-inputgroup-addon">
              Unidade
            </Input155px>
            <InputText
              disabled
              value={produtoUnidadeMedida}
            />
          </div>
        </div>
      </div>
      <div className="row form-elo">
        <div className="col-3">
          <div className="p-inputgroup">
            <Input155px className="p-inputgroup-addon">
              Quantidade*
            </Input155px>
            <InputNumber
              inputRef={inputProdutoQuantidadeFocus}
              onFocus={(e) => e.target.select()}
              mode="decimal"
              inputId="minmaxfraction"
              minFractionDigits={4}
              maxFractionDigits={4}
              value={produtoQuantidade}
              onValueChange={(e) => {
                setProdutoQuantidade(e.target.value);
              }}
              onKeyDown={async (e) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                  setInputProdutoDescontoFocus();
                }
              }}
              style={{ fontSize: `${fontSizeDadosEntrada}px` }}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="p-inputgroup">
            <Input155px className="p-inputgroup-addon">
              Preço Bruto
            </Input155px>
            <InputNumber
              disabled
              // inputRef={inputValorUnitarioFocus}
              onFocus={(e) => e.target.select()}
              mode="currency"
              inputId="currency-br"
              currency="BRL"
              locale="pt-BR"
              minFractionDigits={2}
              maxFractionDigits={2}
              value={produtoPrecoBruto}
              onValueChange={e => setProdutoPrecoBruto(e.target.value)}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="p-inputgroup">
            <Input155px className="p-inputgroup-addon">
              Desconto %
            </Input155px>
            <InputNumber
              inputRef={inputProdutoDescontoFocus}
              onFocus={(e) => e.target.select()}
              locale="pt-BR"
              minFractionDigits={2}
              maxFractionDigits={2}
              value={produtoDesconto}
              onValueChange={e => {
                setProdutoDesconto(e.target.value);
                calcularPrecoVenda(e.target.value);
              }}
              onKeyDown={async (e) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                  setInputProdutoPrecoVendaFocus();
                }
              }}
            />
          </div>
        </div>
        <div className="col-3">
          <div className="p-inputgroup">
            <Input155px className="p-inputgroup-addon">
              Preço Venda
            </Input155px>
            <InputNumber
              inputRef={inputProdutoPrecoVendaFocus}
              onFocus={(e) => e.target.select()}
              mode="currency"
              inputId="currency-br"
              currency="BRL"
              locale="pt-BR"
              minFractionDigits={2}
              maxFractionDigits={2}
              value={produtoPrecoVenda}
              onValueChange={e => {
                setProdutoPrecoVenda(e.target.value);
              }}
              onBlur={e => {
                handleAdicionarProduto();
              }}
              onKeyDown={async (e) => {
                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                  setInputProdutoRegistroFocus();
                }
              }}
            />
          </div>
        </div>
      </div>
      <div className="row form-elo">
        <div className="col-3" style={{ fontSize: '32px', textAlign: 'left' }}>
          <Dropdown
            disabled={mudarEmpresaDisable}
            options={listaEmpresas}
            value={cnpjEmpresa}
            emptyMessage="Nenhum registro a ser exibido"
            onChange={(e) => {
              setCnpjEmpresa(e.target.value);
              const consultaPrevisaoFinanceiraCnpjEmpresa = e.target.value;
              dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
              toast.info('Deseja realmente mudar a empresa');
            }}
          />
        </div>
        <div className="col-2" style={{ textAlign: 'left' }}>
          <div className="p-inputgroup">
            <Input100px className="p-inputgroup-addon">
              Tabela Preço
            </Input100px>
            <Dropdown
              options={listaTabelasPrecoAuxiliar}
              value={tabelaPrecoAuxiliar}
              emptyMessage="Nenhum registro a ser exibido"
              onChange={(e) => setTabelaPrecoAuxiliar(e.target.value)}
            />
          </div>
        </div>
        <div className="col-4" style={{ fontSize: '32px', textAlign: 'right' }}>
          <center>
            <Button label="Fechar Venda" icon="pi pi-save" onClick={handleF9} />
            <Button label="Comandos" icon="pi pi-sort-up" onClick={handleComandos} />
            <Button label="Sair" icon="pi pi-times" onClick={handleSair} />
          </center>
        </div>
        <div className="col-3">
          <div className="p-inputgroup">
            <Input155px className="p-inputgroup-addon">
              Total
            </Input155px>
            <InputText
              disabled
              maxLength={15}
              value={formataMoeda(totalVenda)}
              style={{ textAlign: 'center', color: 'white', fontSize: `${fontSizeTitulo - 28}px`, backgroundColor: 'green' }}
            />
          </div>
        </div>
      </div>
    </>
  );

  const onHideProdutoNaoEncontrado = () => {
    setProdutoRegistro(null);
    setProdutoDescricao(null);
    setProdutoUnidadeMedida(null);
    setShowProdutoNaoEncontrado(false);
    setInputProdutoRegistroFocus();
  };

  const onHideCodigoProdutoInvalido = () => {
    setProdutoRegistro(null);
    setProdutoDescricao(null);
    setProdutoUnidadeMedida(null);
    setShowCodigoProdutoInvalido(false);
    setInputProdutoRegistroFocus();
  };

  const onHideQuantidadeProdutoInvalida = () => {
    setShowQuantidadeProdutoInvalida(false);
  };

  const onHidePagamentoCartaoDialog = () => {
    setShowPagamentoCartaoDialog(false);
  };

  const onHidePagamentoPixDialog = () => {
    setShowPagamentoPixDialog(false);
  };

  const onHideComandosDialog = () => {
    setShowComandosDialog(false);
  };

  const onHideAdministrativoDialog = () => {
    setShowAdministrativoDialog(false);
  };

  const onHideProdutoNaoPodeSerFracionado = () => {
    setShowProdutoNaoPodeSerFracionado(false);
  };

  const pegarSomaParcelasCartao = (lista) => {
    let valor = 0;
    // eslint-disable-next-line arrow-body-style
    valor = lista.reduce((valor, item) => {
      return valor + +item.valor;
    }, 0);

    return valor;
  };

  const pegarSomaParcelasPix = (lista) => {
    let valor = 0;
    // eslint-disable-next-line arrow-body-style
    valor = lista.reduce((valor, item) => {
      return valor + +item.valor;
    }, 0);

    return valor;
  };

  const handlePagamentoCartao = (valorCartao) => {
    const valor = valorCartao.replace('.', '').replace(',', '.');

    const somaParcelasCartao = pegarSomaParcelasCartao(listaPagamentosCartoes);

    if (Number(valor) > 0 && (Number(valor).toFixed(2) !== Number(somaParcelasCartao).toFixed(2))) {
      setShowPagamentoCartaoDialog(true);
    }
  };

  const handlePagamentoPix = (valorPix) => {
    const valor = valorPix.replace('.', '').replace(',', '.');

    const somaParcelasPix = pegarSomaParcelasPix(listaPagamentosPix);

    if (Number(valor) > 0 && (Number(valor).toFixed(2) !== Number(somaParcelasPix).toFixed(2))) {
      setShowPagamentoPixDialog(true);
    }
  };

  const handleEditPagamentoCartao = () => {
    setShowPagamentoCartaoDialog(true);
    setValorParcelamentoCartao();
  };

  const handleEditPagamentoPix = () => {
    setShowPagamentoPixDialog(true);
  };

  const calcularValorRestanteCartao = (lista) => {
    let valor = 0;
    // eslint-disable-next-line arrow-body-style
    valor = pegarSomaParcelasCartao(lista);
    setValorRestanteCartao(pagoCartao - valor);
  };

  const calcularValorRestantePix = (lista) => {
    let valor = 0;
    // eslint-disable-next-line arrow-body-style
    valor = pegarSomaParcelasPix(lista);
    setValorRestantePix(pagoDigital - valor);
  };

  const handleAdicionarPagamentoCartao = () => {
    if (valorRestanteCartao < 0) {
      toast.info('A soma dos cartões não podem ser maior que o total do cartão.');
      return false;
    }

    if (tipoCartao === 'DEBITO' && numeroParcelasCartao > 1) {
      toast.info('Pagamento com cartão débito não pode ser parcelado');
      return false;
    }

    const _listaPagamentosCartoes = [...listaPagamentosCartoes];
    if (numeroParcelasCartao > 0) {
      if (valorParcelamentoCartao > 0) {
        for (let x = 0; x < numeroParcelasCartao; x++) {
          const vencimento = moment(new Date().toLocaleDateString(), 'DD-MM-YYYY').add(30 * (x + 1), 'days').toDate().toString();
          const pagamento = {
            item: _listaPagamentosCartoes.length + 1,
            parcela: `${(x + 1) + '/' + numeroParcelasCartao}`,
            tipo: tipoCartao,
            vencimento: formataDataMoment(vencimento, '/'),
            valor: (+valorParcelamentoCartao / numeroParcelasCartao).toFixed(4).toString(),
          };
          _listaPagamentosCartoes.push(pagamento);
        }
        setListaPagamentosCartoes(_listaPagamentosCartoes);
        calcularValorRestanteCartao(_listaPagamentosCartoes);
      } else {
        toast.info('Valor do cartão não pode ser zero.');
      }
    } else {
      toast.info('Número de parcelas não pode ser zero.');
    }
  };

  const handleAdicionarPagamentoPix = () => {
    if (valorRestantePix < 0) {
      toast.info('A soma dos Pagamentos Digitais não podem ser maior que o total do pagamento digital.');
      return false;
    }

    const _listaPagamentosPix = [...listaPagamentosPix];
    if (valorPagamentoPix > 0) {
      const vencimento = moment(new Date().toLocaleDateString(), 'DD-MM-YYYY');
      const pagamento = {
        item: _listaPagamentosPix.length + 1,
        tipo: tipoPix,
        vencimento: formataDataMoment(vencimento, '/'),
        valor: (+valorPagamentoPix).toFixed(2).toString(),
      };
      _listaPagamentosPix.push(pagamento);
      setListaPagamentosPix(_listaPagamentosPix);
      calcularValorRestantePix(_listaPagamentosPix);
    } else {
      toast.info('Valor do Pag. Digital não pode ser zero.');
    }
  };

  const handleExcluirPagamentoCartao = async (e) => {
    try {
      setIsLoading(true);
      const item = get(e, 'item', 0);

      const _listaPagamentosCartoes = [...listaPagamentosCartoes];

      const index = _listaPagamentosCartoes.indexOf(_listaPagamentosCartoes.find(element => element.item === item));

      if (index >= 0) {
        _listaPagamentosCartoes.splice(index, 1);

        _listaPagamentosCartoes.forEach(element => {
          if (element.item > item) {
            const _index = _listaPagamentosCartoes.indexOf(element);

            _listaPagamentosCartoes[_index].item = _listaPagamentosCartoes[_index].item - 1;
          }
        });
      }

      setListaPagamentosCartoes(_listaPagamentosCartoes);
      calcularValorRestanteCartao(_listaPagamentosCartoes);
    } catch (err) {
      toast.info('Selecione um registro para exclusão');
    } finally {
      setIsLoading(false);
    }
  };

  const handleExcluirPagamentoPix = async (e) => {
    try {
      setIsLoading(true);
      const item = get(e, 'item', 0);

      const _listaPagamentosPix = [...listaPagamentosPix];

      const index = _listaPagamentosPix.indexOf(_listaPagamentosPix.find(element => element.item === item));

      if (index >= 0) {
        _listaPagamentosPix.splice(index, 1);

        _listaPagamentosPix.forEach(element => {
          if (element.item > item) {
            const _index = _listaPagamentosPix.indexOf(element);

            _listaPagamentosPix[_index].item = _listaPagamentosPix[_index].item - 1;
          }
        });
      }

      setListaPagamentosPix(_listaPagamentosPix);
      calcularValorRestantePix(_listaPagamentosPix);
    } catch (err) {
      toast.info('Selecione um registro para exclusão');
    } finally {
      setIsLoading(false);
    }
  };

  const actionBodyTemplatePagamentoCartao = (rowData) => (
    <div className="row col-12">
      <div className="col-lg-4">
        <button
          type="button"
          title="Excluir parcela cartão"
          className="btn-success rounded outlined"
          style={{ borderRadius: '15px' }}
          onClick={() => handleExcluirPagamentoCartao(rowData)}
        >
          <i className="pi pi-trash"></i>
        </button>
      </div>
    </div>
  );

  const actionBodyTemplatePagamentoPix = (rowData) => (
    <div className="row col-12">
      <div className="col-lg-4">
        <button
          type="button"
          title="Excluir Pix"
          className="btn-success rounded outlined"
          style={{ borderRadius: '15px' }}
          onClick={() => handleExcluirPagamentoPix(rowData)}
        >
          <i className="pi pi-trash"></i>
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    atualizarListaProdutoPorTabela();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabelaPrecoAuxiliar]);

  window.addEventListener('keydown', (e) => {
    if (e.code === 'F1' || e.code === 'F5'
      || e.code === 'F9' || e.code === 'F3') {
      //
    } else if (e.code === 'F10') {
      setShowConsultaProduto(true);
    } else if (e.ctrlKey && e.code === 'Delete') {
      e.preventDefault;
      handleDeleteProduto();
    } else return true;
  });

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Selecionar Empresa</h6>}
        visible={showEscolhaEmpresaDialog}
        style={{ width: '58vw' }}
        onHide={handleCancelEscolhaEmpresa}
        footer={dialogEscolhaEmpresaFooter}
      >
        <div className="row form-elo">
          <div className="col-1">
          </div>
          <div className="col-10">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Empresa
              </Input155px>
              <Dropdown
                options={listaEmpresas}
                value={cnpjEmpresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setCnpjEmpresa(e.target.value)}
              />
            </div>
          </div>
          <div className="col-1">
          </div>
        </div>
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Pagamento Cartões</h6>}
        visible={showPagamentoCartaoDialog}
        style={{ width: '68vw' }}
        onHide={() => onHidePagamentoCartaoDialog()}
        footer={dialogPagamentoCartaoFooter}
      >
        <div className="row form-elo">
          <div className="col-12">
            <DataTable
              className="datatable-nfcepagamentoCartoes"
              value={listaPagamentosCartoes}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              sortField="item"
              selectionMode="single"
              selection={selectedPagamentoCartao}
              onSelectionChange={e => setSelectedPagamentoCartao(e.value)}
              dataKey="item"
              emptyMessage=" "
              scrollable
              scrollHeight="350px"
            >
              <Column field="item" header="item" sort="false"></Column>
              <Column field="tipo" header="Tipo" sort="false"></Column>
              <Column field="parcela" header="parcela" sort="false"></Column>
              <Column field="vencimento" header="Vencimento" sort="false"></Column>
              <Column field="valor" header="Valor" sort="false"></Column>
              <Column body={actionBodyTemplatePagamentoCartao} exportable={false} style={{ width: '50px' }}></Column>
            </DataTable>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo
              </Input155px>
              <Dropdown
                options={listaTiposCartoes}
                value={tipoCartao}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipoCartao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Parcelas
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                inputId="minmax-buttons"
                value={numeroParcelasCartao}
                onValueChange={(e) => setNumeroParcelasCartao(e.value)}
                mode="decimal"
                showButtons
                min={0}
                max={100}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Valor
              </Input100px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorParcelamentoCartao}
                onValueChange={e => setValorParcelamentoCartao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-1">
            <div className="p-inputgroup">
              <Button label="" title="Adicionar" icon="pi pi-plus" onClick={() => handleAdicionarPagamentoCartao()} />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Restando
              </Input100px>
              <InputNumber
                disabled
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorRestanteCartao}
                onValueChange={e => setValorRestanteCartao(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Pagamento Digital</h6>}
        visible={showPagamentoPixDialog}
        style={{ width: '68vw' }}
        onHide={() => onHidePagamentoPixDialog()}
        footer={dialogPagamentoPixFooter}
      >
        <div className="row form-elo">
          <div className="col-12">
            <DataTable
              className="datatable-nfcepagamentoCartoes"
              value={listaPagamentosPix}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              sortField="item"
              selectionMode="single"
              selection={selectedPagamentoPix}
              onSelectionChange={e => setSelectedPagamentoPix(e.value)}
              dataKey="item"
              emptyMessage=" "
              scrollable
              scrollHeight="350px"
            >
              <Column field="item" header="item" sort="false"></Column>
              <Column field="tipo" header="Tipo" sort="false"></Column>
              <Column field="valor" header="Valor" sort="false"></Column>
              <Column body={actionBodyTemplatePagamentoPix} exportable={false} style={{ width: '50px' }}></Column>
            </DataTable>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo
              </Input155px>
              <Dropdown
                options={listaTiposPix}
                value={tipoPix}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipoPix(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Valor
              </Input100px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorPagamentoPix}
                onValueChange={e => setValorPagamentoPix(e.target.value)}
              />
            </div>
          </div>
          <div className="col-1">
            <div className="p-inputgroup">
              <Button label="" title="Adicionar" icon="pi pi-plus" onClick={() => handleAdicionarPagamentoPix()} />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Restando
              </Input100px>
              <InputNumber
                disabled
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorRestantePix}
                onValueChange={e => setValorRestantePix(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Deletar Produto</h6>}
        visible={showConfirmDeleteProdutoDialog}
        style={{ width: '58vw' }}
        onHide={() => onHideConfirmDeleteProduto()}
        footer={dialogDeleteProdutoFooter}
      >
        <div>
          <div className="row form-elo">
            <div className="col-12">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Produto
                </Input155px>
                <InputText
                  disabled
                  maxLength={100}
                  value={selectedProdutos !== null ? selectedProdutos.descricao : ''}
                />
              </div>
            </div>
          </div>
          <div className="row form-elo">
            <div className="col-6">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Qtde
                </Input155px>
                <InputText
                  disabled
                  maxLength={15}
                  value={selectedProdutos !== null ? selectedProdutos.quantidade : 0}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Valor
                </Input155px>
                <InputText
                  disabled
                  maxLength={15}
                  value={selectedProdutos !== null ? selectedProdutos.total : 0}
                />
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      <Dialog
        visible={showProdutoNaoPodeSerFracionado}
        style={{ width: '58vw' }}
        onHide={() => onHideProdutoNaoPodeSerFracionado()}
      >
        <div className="row form-elo">
          <center>
            <h6 className="card-header" style={{ textAlign: 'center', fontSize: `${fontSizeTitulo - 20}px` }}>Produto não pode ser fracionado</h6>
          </center>
        </div>
        <div className="row form-elo">
          <center>
            <Button label="(ESC) para continuar" icon="pi pi-times" onClick={() => onHideProdutoNaoPodeSerFracionado()} />
          </center>
        </div>
      </Dialog>

      <Dialog
        visible={showCodigoProdutoInvalido}
        style={{ width: '58vw' }}
        onHide={() => onHideCodigoProdutoInvalido()}
      >
        <div className="row form-elo">
          <center>
            <h6 className="card-header" style={{ textAlign: 'center', fontSize: `${fontSizeTitulo - 20}px` }}>Código do Produto Inválido</h6>
          </center>
        </div>
        <div className="row form-elo">
          <center>
            <Button label="(ESC) para continuar" icon="pi pi-times" onClick={() => onHideCodigoProdutoInvalido()} />
          </center>
        </div>
      </Dialog>

      <Dialog
        visible={showQuantidadeProdutoInvalida}
        style={{ width: '58vw' }}
        onHide={() => onHideQuantidadeProdutoInvalida()}
      >
        <div className="row form-elo">
          <center>
            <h6 className="card-header" style={{ textAlign: 'center', fontSize: `${fontSizeTitulo - 20}px` }}>Quantidade do Produto Inválida</h6>
          </center>
        </div>
        <div className="row form-elo">
          <center>
            <Button label="(ESC) para continuar" icon="pi pi-times" onClick={() => onHideQuantidadeProdutoInvalida()} />
          </center>
        </div>
      </Dialog>

      <Dialog
        visible={showProdutoNaoEncontrado}
        style={{ width: '58vw' }}
        onHide={() => onHideProdutoNaoEncontrado()}
      >
        <div className="row form-elo">
          <center>
            <h6 className="card-header" style={{ textAlign: 'center', fontSize: `${fontSizeTitulo - 20}px` }}>Produto não encontrado na base de dados</h6>
          </center>
        </div>
        <div className="row form-elo">
          <center>
            <Button label="(ESC) para continuar" icon="pi pi-times" onClick={() => onHideProdutoNaoEncontrado()} />
          </center>
        </div>
      </Dialog>

      <Dialog
        header="Consulta de Participantes"
        visible={showConsultaParticipante}
        style={{ width: '98vw' }}
        onHide={() => setShowConsultaParticipante(false)}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParent} empresaCnpj={cnpjEmpresa} />
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Consulta Produtos</h6>}
        visible={showConsultaProduto}
        style={{ width: '98vw' }}
        onHide={() => setShowConsultaProduto(false)}
      >
        <ConsultaProduto menuMode={false} childToParent={childToParentConsultaProduto} empresaCnpj={cnpjEmpresa} somenteLeitura />
      </Dialog>

      {/* <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Consulta NFCe</h6>}
        visible={showConsultaNFCe}
        style={{ width: '98vw' }}
        onHide={() => setShowConsultaNFCe(false)}
      >
        <ConsultaNFCe menuMode={false} childToParent={childToParentConsultaNFCe} empresaCnpj={cnpjEmpresa} somenteLeitura />
      </Dialog> */}

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Cancelar Venda</h6>}
        visible={showConfirmCancelarVendaDialog}
        style={{ width: '40vw' }}
        onHide={() => setShowConfirmCancelarVendaDialog(false)}
        footer={dialogCancelarVendaFooter}
      >
        Deseja realmente cancelar a venda?
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Comandos</h6>}
        visible={showComandosDialog}
        style={{ width: '40vw' }}
        onHide={() => onHideComandosDialog()}
      >
        <div className="col-12">
          <center>
            <div className="row form-elo">
              <center>
                <Button label="(Ctrl + F3) Cancelar Venda " icon="pi pi-times" style={{ width: '80%' }} onClick={handleCancelarVenda} />
              </center>
            </div>
          </center>
        </div>
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center' }}>Fechamento Venda</h6>}
        visible={showPagamentoDialog}
        style={{ width: '98vw' }}
        onHide={() => {
          setShowPagamentoDialog(false);
        }}
      >
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Total Venda
              </Input155px>
              <InputNumber
                disabled
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={totalVenda}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Dinheiro
              </Input155px>
              <InputNumber
                id="pagoDinheiro"
                onFocus={(e) => e.target.select()}
                autoFocus
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={pagoDinheiro}
                onValueChange={e => setPagoDinheiro(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Cheque
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={pagoCheque}
                onValueChange={e => setPagoCheque(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Cartão
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={pagoCartao}
                onValueChange={e => {
                  setPagoCartao(e.target.value);
                  setValorRestanteCartao(e.target.value);
                  setValorParcelamentoCartao(e.target.value);
                }}
                onBlur={(e) => { e.target.value.toString !== '0,00' ? handlePagamentoCartao(e.target.value) : ''; }}
              />
              <Button label="" title="Editar" icon="pi pi-pencil" onClick={() => handleEditPagamentoCartao()} />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Pag. Digital
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={pagoDigital}
                onValueChange={e => {
                  setPagoDigital(e.target.value);
                  setValorRestantePix(e.target.value);
                }}
                onBlur={(e) => handlePagamentoPix(e.target.value)}
              />
              <Button label="" title="Editar" icon="pi pi-pencil" onClick={() => handleEditPagamentoPix()} />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Cliente
              </Input155px>
              <InputText
                maxLength={255}
                value={clienteNome}
                onChange={e => setClienteNome(e.target.value)}
                style={{ fontSize: `${fontSizeDadosEntrada - 20}px` }}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaParticipante(true)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                CNPJ/CPF
              </Input155px>
              <InputText
                onFocus={(e) => e.target.select()}
                autoFocus
                maxLength={20}
                value={clienteCnpj}
                onChange={e => setClienteCnpj(e.target.value)}
                style={{ fontSize: `${fontSizeDadosEntrada - 20}px` }}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Inscrição Estadual
              </Input155px>
              <InputText
                onFocus={(e) => e.target.select()}
                autoFocus
                maxLength={20}
                value={clienteIe}
                onChange={e => setClienteIe(e.target.value)}
                style={{ fontSize: `${fontSizeDadosEntrada - 20}px` }}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                E-Mail
              </Input155px>
              <InputText
                onFocus={(e) => e.target.select()}
                autoFocus
                maxLength={100}
                value={clienteEMail}
                onChange={e => setClienteEMail(e.target.value)}
                style={{ fontSize: `${fontSizeDadosEntrada - 20}px` }}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Desconto R$
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorDesconto}
                onValueChange={e => setValorDesconto(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Pago
              </Input155px>
              <InputNumber
                disabled
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorPago}
                onValueChange={e => setValorPago(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Troco
              </Input155px>
              <InputNumber
                disabled
                onFocus={(e) => e.target.select()}
                // mode="currency"
                // inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorTroco}
                onValueChange={e => setValorTroco(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
          </div>
          <div className="col-2">
            <center>
              <Button
                hidden={!liberarFechamentoVenda}
                type="button"
                className="btn btn-success"
                onClick={salvarRegistro}
              >
                <i className="pi pi-check"></i>
                Fechar Venda
              </Button>
            </center>
          </div>
          <div className="col-2">
            <center>
              <Button
                type="button"
                className="btn btn-danger"
                onClick={() => {
                  fecharDialogos();
                  setInputProdutoRegistroFocus();
                }}
              >
                <i className="pi pi-times"></i>
                Voltar a Tela Anterior
              </Button>
            </center>
          </div>
          <div className="col-4">
          </div>
        </div>
      </Dialog>

      <Dialog
        header={<h6 className="card-header" style={{ textAlign: 'center', fontSize: `${fontSizeTitulo}px` }}>Venda</h6>}
        visible
        maximized
        showHeader="false"
        onHide={() => handleSair()}
        footer={dialogDavFooter}
        className="dialog-dav"
      >
        <div className="row form-elo">
          <div className="col-12">
            <DataTable
              className="datatable-nfceprodutos"
              value={listaProdutos}
              size="small"
              stripedRows
              responsiveLayout="scroll"
              sortField="item"
              selectionMode="single"
              selection={selectedProdutos}
              onSelectionChange={e => setSelectedProdutos(e.value)}
              dataKey="item"
              emptyMessage=" "
              scrollable
              scrollHeight={alturaScrollProdutos + 'px'}
              style={{ fontSize: 18}}
              showGridlines
              resizableColumns
            >
              {fillColumns(columnsSetupItem)}
            </DataTable>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
