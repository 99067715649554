export function obterListaFinalidadeEmissao() {
  return (
    [
      { label: 'Normal', value: 'NORMAL' },
      { label: 'Complementar', value: 'COMPLEMENTAR' },
      { label: 'Ajuste', value: 'AJUSTE' },
      { label: 'Devolução', value: 'DEVOLUCAO' },
    ]
  );
}
