import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { get, orderBy } from 'lodash';

import dateFormat from 'dateformat';

import 'react-toastify/dist/ReactToastify.css';

import * as actions from '../../../../store/modules/auth/actions';

import { displayErrors } from '../../../../util/diversos';
import { fillColumns } from '../../../../components/table';
import { Input100px, Input155px } from '../../../../styles/inputGroup';

import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Card from '../../../../components/card';
import Loading from '../../../loading';
import CadastroEstampariaOrcamento from './cadastroEstampariaOrcamento';
import PdfEstampariaOrcamento from '../../relatorio/pdfEstampariaOrcamento';

export default function ConsultaEstampariaOrcamento({ menuMode, childToParent, somenteLeitura = false }) {
  const [isLoading, setIsLoading] = useState(false);

  const [showCadastro, setShowCadastro] = useState(false);
  const [showPrint, setShowPrint] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataTableList, setDataTableList] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [filtroAtualizado, setFiltroAtualizado] = useState(null);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [findNomeOrcamento, setFindNomeOrcamento] = useState('');
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [payloadCadastro, setPayloadCadastro] = useState({});
  const [payloadPrint, setPayloadPrint] = useState({});
  const [columnsSetup] = useState([
    { field: 'data', header: 'Data', sort: true },
    { field: 'nomeOrcamento', header: 'Nome do Orçamento', sort: true },
    { field: 'cliente.razaoSocial', header: 'Razão Social do Cliente', sort: false },
    { field: 'valorTotal', header: 'Valor do Orçamento', sort: false },
    { field: 'vendedor.nome', header: 'Vendedor', sort: false },
  ]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  async function getData(searchFilter, page, size) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/estampariaorcamento/search?idUsuario=${idUsuario}`;

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy');
        const _dataFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy');

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      if (searchFilter && searchFilter.findNomeOrcamento !== '') {
        params = `${params}&nomeOrcamento=${findNomeOrcamento}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setFindNomeOrcamento('');

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal, findNomeOrcamento };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, 0, lazyParams.rows);
  };

  function renderBotaoSelect() {
    if (!menuMode) {
      return (
        <button
          type="button"
          title="Selecionar"
          className="btn btn-info"
          style={{ borderRadius: '15px' }}
          onClick={() => childToParent(selectedItens)}
        >
          <i className="pi pi-check"></i>
        </button>
      );
    }
    return '';
  }

  useEffect(() => {
    async function verificarSeMultiEmpresa(idUsuario, searchFilter) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        await axiosAdmin.get(params);

        getData(searchFilter, 0, lazyParams.rows);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    if (findDataInicial === null) {
      setFindDataInicial(new Date(store.getState().auth.consultaEstampariaOrcamentoDataInicial));
      setFindDataFinal(new Date(store.getState().auth.consultaEstampariaOrcamentoDataFinal));

      const _dataInicial = new Date(store.getState().auth.consultaEstampariaOrcamentoDataInicial);
      const _dataFinal = new Date(store.getState().auth.consultaEstampariaOrcamentoDataFinal);

      const searchFilter = { findDataInicial: _dataInicial, findDataFinal: _dataFinal, findNomeOrcamento };

      verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0), searchFilter);
    }

    if (filtroAtualizado) {
      setFiltroAtualizado(false);
      handleFind();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filtroAtualizado]);

  const handleRegister = () => {
    const id = 0;

    setPayloadCadastro({ id });

    setShowCadastro(true);
  };

  const handleEdit = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setPayloadCadastro({ id });
      setShowCadastro(true);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/estampariaorcamento/${idExclusao}`);

        const searchFilter = { findDataInicial, findDataFinal, findNomeOrcamento };

        getData(searchFilter, 0, lazyParams.rows);

        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findDataInicial, findDataFinal, findNomeOrcamento };

    getData(searchFilter, event.page, event.rows);
  };

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const childToParentCadastro = (payloadDataTableList, id) => {
    if (payloadDataTableList) {
      if (id > 0) {
        const searchFilter = { findDataInicial, findDataFinal, findNomeOrcamento };

        getData(searchFilter, lazyParams.page, lazyParams.rows);
      } else {
        const _dataTableList = [];
        _dataTableList.push(payloadDataTableList);

        setDataTableList(_dataTableList);

        const _lazyParams = { ...lazyParams, first: 0, page: 0 };
        setLazyParams(_lazyParams);
        setTotalRecords(1);
      }
    }

    setShowCadastro(false);
  };

  const handlePrint = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setPayloadPrint({ id });
      setShowPrint(true);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  const onHidePrint = () => {
    setShowPrint(false);
  };

  const finishPrintOrcamento = () => {
    setShowPrint(false);
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroEstampariaOrcamento payloadCadastro={payloadCadastro} childToParentCadastro={childToParentCadastro} />
      </Dialog>

      <Dialog
        header=""
        visible={showPrint}
        style={{ width: '98vw' }}
        onHide={() => onHidePrint()}
        className="dialog-cadastro"
      >
        <PdfEstampariaOrcamento payloadPrint={payloadPrint} finishPrintOrcamento={finishPrintOrcamento} />
      </Dialog>

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              ORÇAMENTOS
            </h6>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data inicial
                </Input100px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => {
                    setFindDataInicial(e.value);
                    const consultaEstampariaOrcamentoDataInicial = e.value;
                    const consultaEstampariaOrcamentoDataFinal = findDataFinal;
                    dispatch(actions.consultaEstampariaOrcamentoDataInicialChange({ consultaEstampariaOrcamentoDataInicial, consultaEstampariaOrcamentoDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data final
                </Input100px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => {
                    setFindDataFinal(e.value);
                    const consultaEstampariaOrcamentoDataInicial = findDataInicial;
                    const consultaEstampariaOrcamentoDataFinal = e.value;
                    dispatch(actions.consultaEstampariaOrcamentoDataInicialChange({ consultaEstampariaOrcamentoDataInicial, consultaEstampariaOrcamentoDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
              <button
                type="button"
                title="Imprimir Orçamento"
                className="btn btn-dark"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handlePrint(e)}
              >
                <i className="pi pi-eye"></i>
              </button>
              {renderBotaoSelect()}
            </div>
          </div>
          <br />
          <div className="row align-items-center">
            <div className="col-8">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Nome do Orçamento
                </Input155px>
                <InputText
                  maxLength={100}
                  value={findNomeOrcamento}
                  onChange={e => setFindNomeOrcamento(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center" style={{ marginTop: '10px' }}>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
