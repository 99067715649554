import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input100px, Input155px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function CadastroMeioPagamento({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);

  const bloquearDescricao = get(payloadCadastro, 'sistema', false);

  const [descricao, setDescricao] = useState('');
  const [maximoParcelas, setMaximoParcelas] = useState(1);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let newTitle = 'Meio de Pagamento - Cadastro';
    if (id) {
      newTitle = 'Meio de Pagamento - Edição';
    }

    dispatch(actions.titleHeaderChange({ newTitle }));

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/meiopagamento/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultameiopagamento');
          toast.error('Acesso negado');
        }

        setDescricao(data.descricao);
        setMaximoParcelas(data.maximoParcelas);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const sistema = false;

      let payloadDataTableList = {};

      const shippingData = {
        idUsuario,
        descricao,
        maximoParcelas,
        sistema,
      };

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/meiopagamento/${id}`, shippingData), 'data', {});
        toast.success('Meio de Pagamento atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/meiopagamento', shippingData), 'data', {});
        toast.success('Meio de Pagamento cadastrado com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!descricao) {
      formErros = true;
      toast.error('Descrição deve ser informada');
    }

    if (maximoParcelas <= 0) {
      formErros = true;
      toast.error('Número máximo de parcelas inválido');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id ? 'Meio de Pagamento - Alteração' : 'Meio de Pagamento - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>{titulo()}</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-9">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Descrição
              </Input100px>
              <InputText
                autoFocus
                disabled={bloquearDescricao}
                maxLength={100}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Máximo Parcelas
              </Input155px>
              <InputNumber
                min={0}
                max={999}
                value={maximoParcelas}
                onValueChange={e => setMaximoParcelas(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
