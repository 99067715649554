export function obterListaTipoBandeiraCartao() {
  return (
    [
      { label: 'Visa', value: 'VISA' },
      { label: 'MasterCard', value: 'MASTERCARD' },
      { label: 'American Express', value: 'AMERICANEXPRESS' },
      { label: 'Sorocred', value: 'SOROCRED' },
      { label: 'Diners Club', value: 'DINERSCLUB' },
      { label: 'Elo', value: 'ELO' },
      { label: 'Hiper Card', value: 'HIPERCARD' },
      { label: 'Aura', value: 'AURA' },
      { label: 'Cabal', value: 'CABAL' },
      { label: 'Outros', value: 'OUTROS' },
    ]
  );
}
