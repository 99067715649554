export function obterListaModalidadeBcIcms() {
  return (
    [
      { label: 'Margem Valor Agregado (%)', value: '0' },
      { label: 'Pauta (Valor)', value: '1' },
      { label: 'Preço Tabelado Máximo', value: '2' },
      { label: 'Valor da Operação', value: '3' },
    ]
  );
}
