import React from 'react';

import { Navigate } from 'react-router-dom';
import { get } from 'lodash';

import 'react-toastify/dist/ReactToastify.css';

import { toast } from 'react-toastify';
import store from '../store/store';

export default function PrivateRoute({ children, tipoUsuarioRota, linkName }) {
  const { isLoggedIn } = store.getState().auth;
  const custonId = 'unico';

  const operadorGrupoAcesso = store.getState().auth.user.operadorGrupoAcesso ? store.getState().auth.user.operadorGrupoAcesso : null;
  // console.log('operadorgrupoacesso: ', operadorGrupoAcesso);

  let listaGrupoAcessoBody = [];
  let existePath = true;

  if (operadorGrupoAcesso != null) {
    listaGrupoAcessoBody = operadorGrupoAcesso.listaGrupoAcessoBody;
    existePath = listaGrupoAcessoBody.filter(el => el.modulo === linkName);

    if (existePath.length <= 0 && linkName !== 'Home') {
      if (!toast.isActive()) {
        toast.info('Acesso não autorizado para o usuário atual', { toastId: custonId });
        return null;
      }
    }
  }

  const tipoUsuarioAtual = get(store.getState().auth.user, 'tipoUsuario', '');

  if (isLoggedIn) {
    if (tipoUsuarioAtual !== tipoUsuarioRota) {
      return null;
    } else {
      return children;
    }
  }

  return <Navigate to="/login" />;
}
