import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Base64 } from 'js-base64';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { get } from 'lodash';
import dateFormat from 'dateformat';
import { toast } from 'react-toastify';
import { ToggleButton } from 'primereact/togglebutton';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { Input100px, Input155px } from '../../../../styles/inputGroup';
import Card from '../../../../components/card';
import Loading from '../../../loading';

import * as actions from '../../../../store/modules/auth/actions';

import { displayErrors } from '../../../../util/diversos';

import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';

export default function ExportarXML() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const idUsuario = get(store.getState().auth.user, 'id', 0);

  const [listaArquivosZipar, setListaArquivosZipar] = useState([]);

  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [tipoMovimentacao] = useState('SAIDA');
  const [exportarPdf, setExportarPdf] = useState(true);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');

  const [listaEmpresas, setListaEmpresas] = useState([]);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const adicionarXmlListaZipar = (responseXml, chave) => {
    try {
      const byteCharactersXml = Base64.atob(responseXml.data);

      const byteNumbersXml = new Array(byteCharactersXml.length);
      for (let i = 0; i < byteCharactersXml.length; i++) {
        byteNumbersXml[i] = byteCharactersXml.charCodeAt(i);
      }

      const byteArrayXml = new Uint8Array(byteNumbersXml);

      const blobFileXml = new Blob([byteArrayXml], { type: 'application/xml' });

      listaArquivosZipar.push({ file: blobFileXml, fileName: chave + '.xml' });
    } catch (e) {
      toast.info('Erro ao adicionar o arquivo xml ao .zip');
    }
  };

  const adicionarPdfListaZipar = (response, chave) => {
    try {
      const byteCharacters = Base64.atob(response.data);

      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      const blobFile = new Blob([byteArray], { type: 'application/pdf' });

      listaArquivosZipar.push({ file: blobFile, fileName: chave + '.pdf' });
    } catch (e) {
      toast.warn('Erro ao adicionar o pdf ao arquivo .zip');
    }
  };

  const downloadXml = async (e) => {
    try {
      const id = get(e, 'id', 0);
      const chave = get(e, 'chaveAcesso', 0);

      if (id > 0) {
        await axiosAdmin.get(`/xml/obterxml?chaveacesso=${chave}`).then(res => {
          adicionarXmlListaZipar(res, chave);
        });
      }
    } catch (err) {
      // displayErrors(err, dispatch, navigate, '', 'Não foi possível baixar os arquivos');
    }
  };

  const downloadPdf = async (e) => {
    try {
      const id = get(e, 'id', 0);
      const chave = get(e, 'chaveAcesso', 0);

      if (id > 0) {
        await axiosAdmin.get(`/nfeutil/downloaddanfe?chaveacesso=${chave}`).then(res => {
          adicionarPdfListaZipar(res, chave);
        });
      }
    } catch (err) {
      // displayErrors(err, dispatch, navigate, '', 'Não foi possível baixar os arquivos');
    }
  };

  const criarEBaixarArquivoZip = async (arquivos, razaoSocial) => {
    try {
      const zip = new JSZip();

      arquivos.forEach(element => {
        zip.file(element.fileName, element.file);
      });

      zip.generateAsync({ type: 'blob' }).then((content) => {
        const _dataInicial = dateFormat(findDataInicial, 'dd-mm-yyyy');
        const _dataFinal = dateFormat(findDataFinal, 'dd-mm-yyyy');
        saveAs(content, `${razaoSocial} arquivos fiscais ${_dataInicial} à ${_dataFinal} .zip`);
      });

      setListaArquivosZipar([]);
    } catch (e) {
      toast.warn('Erro ao baixar arquivo zip.');
    }
  };

  // eslint-disable-next-line no-shadow
  const getData = async (searchFilter, filtrarCnpjEmpresa, tipoMovimentacao) => {
    try {
      setIsLoading(true);

      if (findDataInicial == null && findDataFinal == null) {
        toast.info('Datas devem ser informadas.');
        return;
      }

      if (findDataInicial > findDataFinal) {
        toast.info('Data final anterior a data inicial.');
        return;
      }

      let params = `/nfe?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy');
        const _dataFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy');

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      if (tipoMovimentacao === 'SAIDA') {
        params = `${params}&emissaoPropria=true`;
      }

      const response = await axiosAdmin.get(params);

      const arquivos = response.data.filter(el => el.flag === 'T');

      if (arquivos <= 0) {
        toast.info('Não há registros a serem exibidos.');
        return;
      }

      let razaoSocial = '';
      listaEmpresas.forEach(element => {
        if (element.value === cnpjEmpresa) {
          // eslint-disable-next-line no-unused-expressions
          (element.label !== '') ? razaoSocial = element.label : '';
        }
      });

      arquivos.forEach(async element => {
        await downloadXml(element);

        if (exportarPdf) await downloadPdf(element);

        await criarEBaixarArquivoZip(listaArquivosZipar, razaoSocial);

        toast.success('Exportação efetuada com sucesso');
      });
    } catch (e) {
      toast.warn('Não foi possível baixar os documentos fiscais.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function verificarSeMultiEmpresa() {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        const _cnpjConsultaPrevisaoFinanceiraCjpEmpresa = store.getState().auth.consultaPrevisaoFinanceiraCnpjEmpresa;

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '') {
          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            const consultaPrevisaoFinanceiraCnpjEmpresa = _cnpjEmpresaStore;
            dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
            cnpjPreenchido = true;
          }
        }

        _listaEmpresas.forEach(element => {
          if (element.ativo) {
            if (!cnpjPreenchido && (_cnpjConsultaPrevisaoFinanceiraCjpEmpresa === null || _cnpjConsultaPrevisaoFinanceiraCjpEmpresa === '')) {
              setCnpjEmpresa(element.cnpj);
              const consultaPrevisaoFinanceiraCnpjEmpresa = element.cnpj;
              dispatch(actions.consultaPrevisaoFinanceiraCnpjEmpresaChange({ consultaPrevisaoFinanceiraCnpjEmpresa }));
              _cnpjEmpresaStore = element.cnpj;
              cnpjPreenchido = true;
            }

            const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

            listDropdown.push({ label: nomeEmpresa, value: element.cnpj, id: element.id, serie: element.nfeSerie, uf: element.uf });
          }
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (!cnpjPreenchido && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== null && _cnpjConsultaPrevisaoFinanceiraCjpEmpresa !== '') {
          setCnpjEmpresa(_cnpjConsultaPrevisaoFinanceiraCjpEmpresa);
          _cnpjEmpresaStore = _cnpjConsultaPrevisaoFinanceiraCjpEmpresa;
        }
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    if (findDataInicial === null) {
      setFindDataInicial(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial));
      setFindDataFinal(new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal));

      const _dataInicial = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataInicial);
      const _dataFinal = new Date(store.getState().auth.consultaPrevisaoFinanceiraDataFinal);

      const searchFilter = { findDataInicial: _dataInicial, findDataFinal: _dataFinal };

      verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0), searchFilter);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleExportar = () => {
    const searchFilter = { findDataInicial, findDataFinal };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, cnpjEmpresa, tipoMovimentacao);
  };

  async function licencaValida(empresaId) {
    // eslint-disable-next-line consistent-return
    const retorno = await axiosAdmin.get(`/empresa/verificardatalicenca/${empresaId}`);
    return retorno.data;
  }

  // eslint-disable-next-line consistent-return
  async function handleCnpjEmpresa(e) {
    let empresaId = 0;

    listaEmpresas.forEach(element => {
      if (element.value === e.target.value) {
        // eslint-disable-next-line no-unused-expressions
        empresaId = element.id;
      }
    });

    const isLicencaValida = await licencaValida(empresaId);
    if (!isLicencaValida) {
      toast.info('Cliente com licença expirada. Efetuar pagamento mensalidade.');
      // navigate('/pagamento');
      return false;
    }

    setCnpjEmpresa(e.target.value);
  }

  return (
    <>
      <Loading isLoading={isLoading} />

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}> Exportação de XML </h6>
        </div>

        <div className="row form-elo">
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data inicial
              </Input100px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={findDataInicial}
                onChange={(e) => setFindDataInicial(e.value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Data final
              </Input100px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={findDataFinal}
                onChange={(e) => setFindDataFinal(e.value)}
              />
            </div>
          </div>
          { /* <div className="col-lg-2">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Tipo
              </Input100px>
              <Dropdown
                options={listaTipoMovimentacao}
                value={tipoMovimentacao}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipoMovimentacao(e.target.value)}
              />
            </div>
          </div> */ }
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Gerar Pdfs
              </Input155px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={exportarPdf}
                onChange={(e) => setExportarPdf(e.value)}
              />
            </div>
          </div>
          <div className="col-lg-1">
            <button
              type="button"
              title="Exportar para Arquivo"
              className="btn btn-success"
              style={{ borderRadius: '15px' }}
              onClick={handleExportar}
            >
              <i className="pi pi-download"></i>
            </button>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-lg-6">
            {listaEmpresas.length > 1 ? (
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Empresa
                </Input100px>
                <Dropdown
                  options={listaEmpresas}
                  value={cnpjEmpresa}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => handleCnpjEmpresa(e)}
                />
              </div>
            ) : ('')}
          </div>
        </div>
      </Card>
    </>
  );
}
