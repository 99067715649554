export function obterListaMotivoDesoneracao() {
  return (
    [
      { label: '01 - Táxi', value: 'TAXI' },
      { label: '03 - Produtor Agropecuário', value: 'PRODUTORAGROPECUARIO' },
      { label: '04 - Frotista/Locadora', value: 'FROTISTALOCADORA' },
      { label: '05 - Diplomático/Consular', value: 'DIPLOMATICOCONSULAR' },
      { label: '06 - Utilitários e motocicletas da Amazônia Ocidental e Área de Livre Comércio', value: 'AMAZONIALIVRE' },
      { label: '07 - SUFRAMA', value: 'SUFRAMA' },
      { label: '08 - Venda a órgão Público', value: 'VENDAORGAOPUBLICO' },
      { label: '09 - Outros', value: 'OUTROS' },
      { label: '10 - Deficiente Condutor', value: 'DEFICIENTECONDUTOR' },
      { label: '11 - Deficiente não condutor', value: 'DEFICIENTENAOCONDUTOR' },
      { label: '90 - Solicitado pelo Fisco', value: 'SOLICITADOFISCO' },
    ]
  );
}
