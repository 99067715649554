import React, { useState } from 'react';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { get } from 'lodash';
import dateFormat from 'dateformat';
import { toast } from 'react-toastify';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Input200px } from '../../../styles/inputGroup';
import Card from '../../../components/card';
import Loading from '../../loading';

import { displayErrors } from '../../../util/diversos';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';

export default function RelatorioEstampariaServico() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  function headRows() {
    return [
      { id: 'id', dataPedido: 'Data', nomePedido: 'Pedido', cliente: 'Cliente', qtdeTotal: 'Qtde', servico: 'Serviço(s)' },
    ];
  }

  const [headerTabelaImpressao] = useState(headRows());

  const idUsuario = get(store.getState().auth.user, 'id', 0);

  async function getData(searchFilter, img) {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF('landscape');
    try {
      setIsLoading(true);

      if (findDataInicial == null && findDataFinal == null) {
        toast.info('Datas devem ser informadas.');
        return;
      }

      if (findDataInicial > findDataFinal) {
        toast.info('Data final anterior a data inicial.');
        return;
      }

      // let params = `/estampariapedido/search?idUsuario=${idUsuario}`;
      let params = `/estampariapedido/buscar-servico?idUsuario=${idUsuario}`;
      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy');
        const _dataFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy');

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      const response = await axiosAdmin.get(params);

      if (response.data <= 0) {
        toast.info('Não há registros a serem exibidos.');
        return;
      }

      let data = {
        id: '0',
        dataPedido: '',
        nomePedido: '',
        cliente: '',
        qtdeTotal: '',
        servico: '',
      };

      let qtdeTotalBordado = 0;
      let qtdeTotalSilk = 0;
      let qtdeTotalSublimacao = 0;

      const result = [];

      for (let x = 0; x < response.data.length; x++) {
        const pedido = response.data[x];

        data.id = pedido.id.toString();
        data.nomePedido = pedido.nomePedido.toString();

        if (pedido.razaoSocialCliente) {
          data.cliente = `${pedido.razaoSocialCliente}`;
        }

        if (pedido.dataPedido !== null) {
          data.dataPedido = `${('00' + pedido.data[2]).slice(-2)}/${('00' + pedido.data[1]).slice(-2)}/${pedido.data[0]}`;
        }

        if (pedido.quantidadeTotal) {
          data.qtdeTotal = pedido.quantidadeTotal;
        }

        let descricao = '';
        if (pedido.bordado) {
          descricao += 'Bordado: ';
          if (pedido.quantidadeBordado) {
            descricao += pedido.quantidadeBordado;
            qtdeTotalBordado += pedido.quantidadeBordado;
          }
        }
        if (pedido.silk) {
          if (descricao !== '') {
            descricao += ', ';
          }
          descricao += 'Silk: ';
          if (pedido.quantidadeSilk) {
            descricao += pedido.quantidadeSilk;
            qtdeTotalSilk += pedido.quantidadeSilk;
          }
        }
        if (pedido.sublimacao) {
          if (descricao !== '') {
            descricao += ', ';
          }
          descricao += 'Sublimação: ';
          if (pedido.quantidadeSublimacao) {
            descricao += pedido.quantidadeSublimacao;
            qtdeTotalSublimacao += pedido.quantidadeSublimacao;
          }
        }

        data.servico = descricao;

        result.push({ ...data });
      }

      // adionar linha vazia
      data = {
        id: '',
        dataPedido: '',
        nomePedido: '',
        cliente: '',
        qtdeTotal: '',
        servico: '',
      };

      result.push({ ...data });

      let stringTotais = '';
      if (qtdeTotalBordado > 0) {
        stringTotais += 'Bordados: ' + qtdeTotalBordado.toString() + '          ';
      }
      if (qtdeTotalSilk > 0) {
        stringTotais += 'Silks: ' + qtdeTotalSilk.toString() + '          ';
      }
      if (qtdeTotalSublimacao > 0) {
        stringTotais += 'Sublimação: ' + qtdeTotalSublimacao.toString();
      }

      if (qtdeTotalBordado > 0 || qtdeTotalSilk > 0 || qtdeTotalSublimacao > 0) {
        data = {
          id: '',
          dataPedido: '',
          nomePedido: 'Totais',
          cliente: stringTotais,
          qtdeTotal: '',
          servico: '',
        }
        result.push({ ...data });
      }
    
      doc.autoTable({
        styles: { fontSize: 8 },
        margin: { top: 38, left: 5, right: 5 },
        theme: 'grid',
        head: headerTabelaImpressao,
        body: result,
        didDrawPage: dados => {
          // Header
          doc.rect(5, 5, 287, 30);
          doc.addImage(img, 'JPEG', 9, 8, 25, 25);
          doc.setFontSize(20);
          doc.text('Relatório de Serviços ', 108, 15);

          doc.setFontSize(8);
          doc.text('Período:', 50, 32);
          doc.text(dateFormat(findDataInicial, 'dd/mm/yyyy'), 63, 32);
          doc.text(' à ', 79, 32);
          doc.text(dateFormat(findDataFinal, 'dd/mm/yyyy'), 83, 32);

          // footer
          const { pageSize } = doc.internal;
          const pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight();
          doc.text(`Pag ${doc.internal.getNumberOfPages()}`, dados.settings.margin.right, pageHeight - 8);
          const periodo = new Date();
          doc.text('Impresso: ', 264, pageHeight - 8);
          // eslint-disable-next-line prefer-template
          doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 278, pageHeight - 8);
        },
      });
      window.open(doc.output('bloburl'));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal };
    const loadImage = async () => {
      let config = '';
      try {
        config = await axiosAdmin.get(`/configuracaosistema?idUsuario=${idUsuario}`);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }

      const img = new Image();
      img.src = `/logotipos/${config.data.urlLogotipo}`;
      await img.decode().then(() => {

      }).catch(() => {
        img.src = 'logo.png';
      });
      getData(searchFilter, img);
    };
    loadImage();
  };

  return (
    <>
      <Loading isLoading={isLoading} />

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}> Relatório Serviços </h6>
        </div>

        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Data previsão inicial
              </Input200px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={findDataInicial}
                onChange={(e) => setFindDataInicial(e.value)}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Data previsão final
              </Input200px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={findDataFinal}
                onChange={(e) => setFindDataFinal(e.value)}
              />
            </div>
          </div>

          <div className="col-lg-3">
            <button
              type="button"
              title="Buscar"
              className="btn btn-success"
              style={{ borderRadius: '15px' }}
              onClick={handleFind}
            >
              <i className="pi pi-search"></i>
            </button>
          </div>
        </div>
      </Card>
    </>
  );
}
