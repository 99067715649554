export function obterListaModalidadeBcIcmsSt() {
  return (
    [
      { label: 'Preço Tabelado ou Máximo Sugerido', value: '0' },
      { label: 'Lista Negativa (Valor)', value: '1' },
      { label: 'Lista Positiva (Valor)', value: '2' },
      { label: 'Lista Neutra (Valor)', value: '3' },
      { label: 'Margem Valor Agregado (%)', value: '4' },
      { label: 'Pauta (Valor)', value: '5' },
    ]
  );
}
