export function obterListaCteTipoEmissao() {
  return (
    [
      { label: 'Sem Contingência', value: 'NORMAL' },
      { label: 'Regime Especial NFF', value: 'NFF' },
      { label: 'EPEC', value: 'EPEC pela SVC' },
      { label: 'Contingência  SVC - RS', value: 'SVCRS' },
      { label: 'Contingência  SVC - SP', value: 'SVCSP' },
    ]
  );
}
