import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { ToggleButton } from 'primereact/togglebutton';
import { get, orderBy } from 'lodash';

import Modal from 'react-bootstrap/Modal';

import 'react-toastify/dist/ReactToastify.css';

import { fillColumns } from '../../../components/table';
import { displayErrors } from '../../../util/diversos';
import { Input100px, InputCampoBusca155px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosAdmin from '../../../services/axiosAdmin';

import CadastroEmpresa from './cadastroEmpresa';

export default function ConsultaEmpresa({ menuMode, childToParent, somenteLeitura = false }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [payloadCadastro, setPayloadCadastro] = useState({});

  if (location.state != null) {
    // eslint-disable-next-line no-param-reassign
    somenteLeitura = location.state.somenteLeitura;
  }

  const [inativos, setInativos] = useState(false);

  const [dataTableList, setDataTableList] = useState(null);
  const [retaguardaLogAcessoList, setRetaguardaLogAcessoList] = useState([]);
  const [retaguardaLogAcessoTotalRecords, setRetaguardaLogAcessoTotalRecords] = useState(0);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findField, setFindField] = useState('');
  const [sortField, setSortField] = useState('razaoSocial');
  const [sortOrder, setSortOrder] = useState(null);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showCadastro, setShowCadastro] = useState(false);
  const [modalRetaguardaLogAcessoShow, setModalRetaguardaLogAcessoShow] = useState(false);

  let columns = [];

  if (somenteLeitura) {
    columns = [
      { field: 'cnpj', header: 'CNPJ', sort: true },
      { field: 'razaoSocial', header: 'Razão Social', sort: true },
      { field: 'nomeFantasia', header: 'Nome Fantasia', sort: true },
      { field: 'cidade', header: 'Cidade', sort: true },
      { field: 'telefone_1', header: 'Telefone', sort: false },
      { field: 'valorMensal', header: 'Valor Mensal', sort: false },
      { field: 'dataLicenca', header: 'Data Licença', sort: false },
    ];
  } else {
    columns = [
      { field: 'cnpj', header: 'CNPJ', sort: true },
      { field: 'razaoSocial', header: 'Razão Social', sort: true },
      { field: 'nomeFantasia', header: 'Nome Fantasia', sort: true },
      { field: 'cidade', header: 'Cidade', sort: true },
      { field: 'valorMensal', header: 'Valor Mensal', sort: false },
      { field: 'diaVencimento', header: 'Dia', sort: false },
      { field: 'dataLicenca', header: 'Data Licença', sort: false },
      { field: 'gerarBoleto', header: 'Boleto', sort: true, booleano: true },
      { field: 'ativo', header: 'Ativo', sort: true, booleano: true },
    ];
  }

  const [columnsSetup] = useState(columns);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  async function getRetaguardaLogAcesso(cnpjEmpresa, page, size) {
    try {
      setIsLoading(true);

      let params = `/retaguardalogacesso/search?cnpj=${cnpjEmpresa}`;

      const _dataInicial = '01-01-1970 00:00:01';

      const _dataFinal = '31-12-2099 23:59:59';

      params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setRetaguardaLogAcessoList(response.data.content);
      setRetaguardaLogAcessoTotalRecords(response.data.totalElements);
    } finally {
      setIsLoading(false);
    }
  }

  const handleHideModalRetaguardaLogAcesso = () => {
    setModalRetaguardaLogAcessoShow(false);
  };

  const onPageRetaguardaLogAcesso = (event) => {
    setLazyParams(event);

    const cnpj = get(selectedItens, 'cnpj', 0);

    getRetaguardaLogAcesso(cnpj, event.page, event.rows);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function ModalRetaguardaLogAcesso(props) {
    const [retaguardaLogAcessoColumnsSetup] = useState([
      { field: 'dataHoraAcesso', header: 'Data/Hora', sort: false, dataHora: true },
      { field: 'modulo', header: 'Módulo', sort: false },
      { field: 'terminal', header: 'Terminal', sort: false },
      { field: 'autorizado', header: 'Autorizado', sort: false, booleano: true },
    ]);

    if (!props.show) {
      return null;
    }

    const headerTable = (title) => (
      <div className="table-header">
        {title}
      </div>
    );

    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" dialogClassName="main-modal">
        <Modal.Body className="show-grid">
          <div className="row form-elo">
            <div className="col-12">
              <DataTable
                header={headerTable('Log de Acesso do Retaguarda')}
                value={retaguardaLogAcessoList}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                selectionMode="single"
                selectableRows
                dataKey="id"
                paginator
                lazy
                first={lazyParams.first}
                rows={lazyParams.rows}
                totalRecords={retaguardaLogAcessoTotalRecords}
                onPage={onPageRetaguardaLogAcesso}
                emptyMessage="Nenhum registro a ser exibido"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{currentPage} de {totalPages}"
                rowsPerPageOptions={[10, 25, 50]}
              >
                {fillColumns(retaguardaLogAcessoColumnsSetup)}
              </DataTable>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleHideModalRetaguardaLogAcesso}
            type="button"
            className="btn btn-primary"
          >
            <i className="pi pi-times"></i>
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  async function getData(searchFilter) {
    try {
      setIsLoading(true);

      let params = '/empresa';
      let separador = '?';

      if (inativos) {
        params = `${params}${separador}somenteAtivo=false`;
        separador = '&';
      }

      if (searchFilter && searchFilter.findField !== '') {
        params = `${params}${separador}${sortField}=${searchFilter.findField}`;
      }

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = {
      findField,
    };

    setFindField('');

    getData(searchFilter);
  };

  useEffect(() => {
    getData('');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleRegister = () => {
    const id = 0;

    setPayloadCadastro({ id });

    setShowCadastro(true);
  };

  const handleEdit = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setPayloadCadastro({ id });
      setShowCadastro(true);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  const handleDelete = (e) => {
    e.preventDefault();

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/empresa/${idExclusao}`);

        const _itens = dataTableList.filter(val => val.id !== idExclusao);
        setDataTableList(_itens);

        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const handleViewRetaguardaLogAcesso = (e) => {
    e.preventDefault();

    if (!selectedItens) {
      toast.warning('Por favor, selecione o registro para visualização do Histórico de Acessos do Retaguarda');
    } else {
      const cnpj = get(selectedItens, 'cnpj', 0);

      if (cnpj && cnpj.length > 0) {
        getRetaguardaLogAcesso(cnpj, 0, lazyParams.rows);
        setModalRetaguardaLogAcessoShow(true);
      } else {
        toast.warning('Empresa selecionada não possui histórico de acessos');
      }
    }
  };

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const childToParentConsulta = (payloadDataTableList, id) => {
    if (payloadDataTableList) {
      if (id > 0) {
        const index = dataTableList.map(e => e.id).indexOf(id);

        if (index >= 0) {
          dataTableList[index] = payloadDataTableList.data;
        }
      } else {
        const _dataTableList = [];
        _dataTableList.push(payloadDataTableList.data);

        setDataTableList(_dataTableList);
      }
    }

    setShowCadastro(false);
  };

  function renderBotaoSelect() {
    if (!menuMode) {
      return (
        <button
          type="button"
          title="Selecionar"
          className="btn btn-info"
          style={{ borderRadius: '15px' }}
          onClick={() => childToParent(selectedItens)}
        >
          <i className="pi pi-check"></i>
        </button>
      );
    }
    return '';
  }

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroEmpresa
          payloadCadastro={payloadCadastro}
          childToParentConsulta={childToParentConsulta}
        />
      </Dialog>

      <ModalRetaguardaLogAcesso
        show={modalRetaguardaLogAcessoShow}
        onHide={handleHideModalRetaguardaLogAcesso}
        dialogClassName="modal-90w"
      />

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>Empresas</h6>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Buscar por:
                </Input100px>
                <InputCampoBusca155px className="p-inputgroup-addon">
                  {columnsSetup.find(element => element.field === sortField).header}
                </InputCampoBusca155px>
                <InputText
                  maxLength={250}
                  value={findField}
                  onChange={e => setFindField(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Log de Acesso do Retaguarda"
                className="btn btn-secondary"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleViewRetaguardaLogAcesso(e)}
              >
                <i className="pi pi-book"></i>
              </button>
              {renderBotaoSelect()}
            </div>
            <div className="col-lg-2">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Inativos
                </Input100px>
                <ToggleButton
                  onLabel="Sim"
                  offLabel="Não"
                  onIcon="pi pi-check"
                  offIcon="pi pi-times"
                  checked={inativos}
                  onChange={(e) => setInativos(e.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            selectionMode="single"
            stripedRows
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            dataKey="id"
            paginator
            rows={10}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
