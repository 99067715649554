import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import * as actions from '../../../store/modules/auth/actions';

import { soNumeros, formataCnpjCpf, displayErrors, formataMoeda, converteData, formataDataMoment } from '../../../util/diversos';
import { Input100px, Input155px } from '../../../styles/inputGroup';

import ConsultaParticipante from '../participante/consultaParticipante';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function CadastroMovimentacaoFinanceira({ payloadCadastro, childToParentCadastro }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);
  const cnpjEmpresaPayload = get(payloadCadastro, 'cnpjEmpresa', '');
  const operacaoFinanceiraPayLoad = get(payloadCadastro, 'findOperacaoFinanceira', 'PAGAR');

  const [listaEmpresa, setListaEmpresa] = useState([]);
  const [listaCentroCusto, setListaCentroCusto] = useState([]);
  const [listaMeioPagamento, setListaMeioPagamento] = useState([]);
  const [showConsultaParticipante, setShowConsultaParticipante] = useState(false);
  const [showRateioCusto, setShowRateioCusto] = useState(false);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [dataEmissao, setDataEmissao] = useState(null);
  const [dataVencimento, setDataVencimento] = useState(null);
  const [dataQuitacao, setDataQuitacao] = useState(null);
  const [centroCusto, setCentroCusto] = useState(0);
  const [centroCustoRateio, setCentroCustoRateio] = useState(0);
  const [valorRateio, setValorRateio] = useState(0);
  const [meioPagamento, setMeioPagamento] = useState(0);
  const [cnpjParticipante, setCnpjParticipante] = useState('');
  const [nomeParticipante, setNomeParticipante] = useState('');
  const [numeroDocumento, setNumeroDocumento] = useState('');
  const [historico, setHistorico] = useState('');
  const [valorTotal, setValorTotal] = useState(0);
  const [valorDesconto, setValorDesconto] = useState(0);
  const [valorQuitado, setValorQuitado] = useState(0);
  const [operacaoFinanceira, setOperacaoFinanceira] = useState('PAGAR');
  const [idOrigem, setIdOrigem] = useState(null);
  const [previsao, setPrevisao] = useState(false);
  const [selectedRateioCusto, setSelectedRateioCusto] = useState(null);
  const [listaRateioCusto, setListaRateioCusto] = useState([]);
  const [atualizarLista, setAtualizarLista] = useState(false);
  const [listaOperacaoFinanceira] = useState([
    { label: 'Pagar', value: 'PAGAR' },
    { label: 'Receber', value: 'RECEBER' },
  ]);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
  }, [atualizarLista]);

  useEffect(() => {
    let newTitle = 'Movimentação Financeira - Cadastro';
    if (id > 0) {
      newTitle = 'Movimentação Financeira - Edição';
    }

    let mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getEmpresas() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        _listaEmpresas.forEach(element => {
          const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

          listDropdown.push({ label: nomeEmpresa, value: element.cnpj });
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresa(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getCentroCusto() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];

        const { data: _centrocustos } = await axiosAdmin.get(`/centrocusto?idUsuario=${idUsuario}`);

        _centrocustos.forEach(element => {
          listDropdown.push({
            id: element.id,
            label: `${element.codigo} ${element.descricao}`,
            value: element.id,
            codigo: `${element.codigo}`,
            descricao: `${element.descricao}`,
            operacao: `${element.operacaoFinanceira}`,
          });
        });

        setListaCentroCusto(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    async function getMeioPagamento() {
      try {
        setIsLoading(true);

        const idUsuario = get(store.getState().auth.user, 'id', 0);

        const listDropdown = [];

        const { data: _meiopagamentos } = await axiosAdmin.get(`/meiopagamento?idUsuario=${idUsuario}`);

        _meiopagamentos.forEach(element => {
          listDropdown.push({ label: `${element.descricao}`, value: element.id });
        });

        setListaMeioPagamento(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    getEmpresas();

    getCentroCusto();

    getMeioPagamento();

    if (id <= 0) {
      setCnpjEmpresa(cnpjEmpresaPayload);
      setOperacaoFinanceira(operacaoFinanceiraPayLoad);
      return;
    }

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/financeiro/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          displayErrors('Acesso negado', dispatch, navigate, '/consultamovimentacaofinanceira', '');
        }

        const _dataEmissao = converteData(data.dataEmissao);

        const _dataVencimento = converteData(data.dataVencimento);

        const _dataQuitacao = converteData(data.dataQuitacao);

        const _listRateioCusto = [];
        if (data.listaRateioCusto && data.listaRateioCusto.length > 0) {
          data.listaRateioCusto.forEach(element => {
            _listRateioCusto.push({ centroCusto: element.centroCusto.id, codigo: `${element.centroCusto.codigo}`, descricao: `${element.centroCusto.descricao}`, valor: element.valor });
          });
        }

        setCnpjEmpresa(data.cnpjEmpresa.trim());
        setDataEmissao(_dataEmissao);
        setDataVencimento(_dataVencimento);
        setDataQuitacao(_dataQuitacao);
        setCentroCusto(data.centroCusto.id);
        setMeioPagamento(data.meioPagamento.id);
        setCnpjParticipante(data.cnpjParticipante);
        setNomeParticipante(data.nomeParticipante);
        setNumeroDocumento(data.numeroDocumento);
        setHistorico(data.historico);
        setValorTotal(data.valorTotal);
        setValorDesconto(data.valorDesconto);
        setValorQuitado(data.valorQuitado);
        setOperacaoFinanceira(data.operacaoFinanceira);
        setIdOrigem(data.idOrigem);
        setPrevisao(data.previsao);
        setListaRateioCusto(_listRateioCusto);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let _dataEmissao = null;
      if (dataEmissao) {
        _dataEmissao = formataDataMoment(dataEmissao, '-');
      }

      let _dataVencimento = null;
      if (dataVencimento) {
        _dataVencimento = formataDataMoment(dataVencimento, '-');
      }

      let _dataQuitacao = null;
      if (dataQuitacao) {
        _dataQuitacao = formataDataMoment(dataQuitacao, '-');
      }

      let totalRateioCusto = 0;
      listaRateioCusto.forEach(elementListaRateioCusto => {
        totalRateioCusto += elementListaRateioCusto.valor;
      });

      if (totalRateioCusto > 0 && totalRateioCusto !== valorTotal - valorDesconto) {
        toast.error('Total do rateio difere do total do documento');
        return;
      }

      const shippingData = {
        idUsuario,
        cnpjEmpresa,
        dataEmissao: _dataEmissao,
        dataVencimento: _dataVencimento,
        dataQuitacao: _dataQuitacao,
        centroCusto,
        meioPagamento,
        cnpjParticipante,
        nomeParticipante,
        numeroDocumento,
        historico,
        valorTotal,
        valorDesconto,
        valorQuitado,
        operacaoFinanceira,
        idOrigem,
        previsao,
        listaRateioCusto,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/financeiro/${id}`, shippingData), 'data', {});
        toast.success('Movimentação Financeira atualizada com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/financeiro', shippingData), 'data', {});
        toast.success('Movimentação Financeira cadastrada com sucesso');
      }

      childToParentCadastro(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentCadastro();
  };

  const desabilitarAlteracao = () => {
    if (idOrigem === null || idOrigem <= 0) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (desabilitarAlteracao()) {
      formErros = true;
      toast.error('Registro de origem do Retaguarda não pode ser alterado!');
    }

    if (!cnpjEmpresa) {
      formErros = true;
      toast.error('Empresa deve ser informada');
    }
    if (!dataEmissao) {
      formErros = true;
      toast.error('Data de emissão deve ser informada');
    }
    if (!dataVencimento) {
      formErros = true;
      toast.error('Data de vencimento deve ser informada');
    }
    if (!centroCusto) {
      formErros = true;
      toast.error('Centro de custo deve ser informado');
    }

    const indexCusto = listaCentroCusto.map(elementCusto => elementCusto.id).indexOf(centroCusto);

    if (listaCentroCusto[indexCusto].operacao !== operacaoFinanceira) {
      formErros = true;
      toast.error('Operação Financeira do Centro de Custo não pode ser diferente da operação financeira do Documento');
    }

    if (!meioPagamento) {
      formErros = true;
      toast.error('Meio de pagamento deve ser informado');
    }
    if (valorTotal <= 0) {
      formErros = true;
      toast.error('Valor total deve ser informado');
    }
    if (valorDesconto < 0) {
      formErros = true;
      toast.error('Valor do desconto não pode ser negativo');
    }
    if (valorQuitado < 0) {
      formErros = true;
      toast.error('Valor quitado não pode ser negativo');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id > 0 ? 'Movimentação Financeira - Alteração' : 'Movimentação Financeira - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h5>{titulo()}</h5>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                disabled={desabilitarAlteracao()}
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const onHideConsultaParticipante = () => {
    setShowConsultaParticipante(false);
  };

  const onHideRateioCusto = () => {
    setShowRateioCusto(false);
  };

  const childToParent = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.cnpj) {
        setCnpjParticipante(e.cnpj);
      }
      if (e.razaoSocial) {
        setNomeParticipante(e.razaoSocial);
      }
      setShowConsultaParticipante(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const valorTemplate = (rowData) => formataMoeda(rowData.valor);

  const handleIncluirRateio = () => {
    let incluirRateio = true;

    if (centroCustoRateio <= 0) {
      toast.warning('Centro de custo deve ser informado');
      return;
    }

    if (valorRateio <= 0) {
      toast.warning('Valor deve ser informado');
      return;
    }

    listaRateioCusto.forEach(elementListaRateioCusto => {
      if (elementListaRateioCusto.centroCusto === centroCustoRateio && incluirRateio === true) {
        toast.warning('Centro de custo já existe no rateio, inclusão não permitida');
        incluirRateio = false;
      }
    });

    if (incluirRateio === false) return;

    let codigoRateioCusto = '';
    let descricaoRateioCusto = '';

    listaCentroCusto.forEach(elementListaCentroCusto => {
      if (elementListaCentroCusto.value === centroCustoRateio) {
        codigoRateioCusto = elementListaCentroCusto.codigo;
        descricaoRateioCusto = elementListaCentroCusto.descricao;
      }
    });

    listaRateioCusto.push({ centroCusto: centroCustoRateio, codigo: `${codigoRateioCusto}`, descricao: `${descricaoRateioCusto}`, valor: valorRateio });

    setCentroCustoRateio(0);
    setValorRateio(0);

    setAtualizarLista(!atualizarLista);
  };

  const handleExcluirRateio = () => {
    const idExclusao = get(selectedRateioCusto, 'centroCusto', 0);

    if (idExclusao <= 0) {
      toast.warning('Por favor, selecione o registro para exclusão');
    }

    const _listaRateioCusto = listaRateioCusto.filter(val => val.centroCusto !== idExclusao);
    setListaRateioCusto(_listaRateioCusto);

    setAtualizarLista(!atualizarLista);
  };

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Participantes"
        visible={showConsultaParticipante}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaParticipante()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParent} />
      </Dialog>

      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-lg-8">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Empresa
              </Input100px>
              <Dropdown
                disabled
                options={listaEmpresa}
                value={cnpjEmpresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setCnpjEmpresa(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-3">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Operação
              </Input100px>
              <Dropdown
                autoFocus
                options={listaOperacaoFinanceira}
                value={operacaoFinanceira}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setOperacaoFinanceira(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-1" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <div className="field-checkbox">
              <Checkbox
                inputId="binary"
                checked={previsao}
                onChange={e => setPrevisao(e.checked)}
              />
              <label htmlFor="binary">
                &nbsp;Previsão
              </label>
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Emissão
              </Input100px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataEmissao}
                onChange={(e) => setDataEmissao(e.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Vencimento
              </Input100px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataVencimento}
                onChange={(e) => setDataVencimento(e.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Quitação
              </Input100px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataQuitacao}
                onChange={(e) => setDataQuitacao(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Centro Custo
              </Input155px>
              <Dropdown
                value={centroCusto}
                options={listaCentroCusto}
                onChange={(e) => setCentroCusto(e.value)}
                filter
              />
              <Button
                icon="pi pi-wallet"
                title="Rateio de Custo"
                className="p-button-raised p-button-text"
                onClick={() => setShowRateioCusto(true)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Meio Pagamento
              </Input155px>
              <Dropdown
                value={meioPagamento}
                options={listaMeioPagamento}
                onChange={(e) => setMeioPagamento(e.value)}
                filter
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                CPF/CNPJ
              </Input100px>
              <InputText
                maxLength={20}
                value={cnpjParticipante}
                onChange={e => setCnpjParticipante(e.target.value)}
                onFocus={e => setCnpjParticipante(soNumeros(e.target.value))}
                onBlur={e => setCnpjParticipante(formataCnpjCpf(e.target.value))}
              />
            </div>
          </div>
          <div className="col-8">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nome Participante
              </Input155px>
              <InputText
                maxLength={100}
                value={nomeParticipante}
                onChange={e => setNomeParticipante(e.target.value)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaParticipante(true)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nº Documento
              </Input155px>
              <InputText
                maxLength={100}
                value={numeroDocumento}
                onChange={e => setNumeroDocumento(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Histórico
              </Input100px>
              <InputText
                maxLength={255}
                value={historico}
                onChange={e => setHistorico(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Total
              </Input155px>
              <InputNumber
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorTotal}
                onValueChange={e => setValorTotal(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Desconto
              </Input155px>
              <InputNumber
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorDesconto}
                onValueChange={e => setValorDesconto(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Quitado
              </Input155px>
              <InputNumber
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorQuitado}
                onValueChange={e => setValorQuitado(e.target.value)}
              />
            </div>
          </div>
        </div>

        <Dialog
          header="Rateio de Custo"
          visible={showRateioCusto}
          style={{ width: '98vw' }}
          onHide={() => onHideRateioCusto()}
        >
          <div className="row form-elo">
            <div className="col-6">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Centro Custo
                </Input155px>
                <Dropdown
                  value={centroCustoRateio}
                  options={listaCentroCusto}
                  onChange={(e) => setCentroCustoRateio(e.target.value)}
                  filter
                />
              </div>
            </div>
            <div className="col-4">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Valor
                </Input100px>
                <InputNumber
                  mode="currency"
                  inputId="currency-br"
                  currency="BRL"
                  locale="pt-BR"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  value={valorRateio}
                  onValueChange={e => setValorRateio(e.target.value)}
                />
              </div>
            </div>
            <div className="col-2">
              <button
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleIncluirRateio}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleExcluirRateio(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
            </div>
          </div>
          <DataTable
            value={listaRateioCusto}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            selectionMode="single"
            selection={selectedRateioCusto}
            onSelectionChange={e => setSelectedRateioCusto(e.value)}
            selectableRows
            dataKey="codigo"
            emptyMessage=" "
          >
            <Column field="codigo" header="Código"></Column>
            <Column field="descricao" header="Descrição"></Column>
            <Column field="valor" header="Valor" body={valorTemplate}></Column>
          </DataTable>
        </Dialog>
      </Card>
    </div>
  );
}
