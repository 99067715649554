/* eslint-disable camelcase */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';

import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import printJS from 'print-js';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import { displayErrors, formataMoeda } from '../../../util/diversos';

export default function PdfOsAparelho({ payloadPrint, finishPrintOsAparelho }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadPrint, 'id', 0);

  const idUsuario = get(store.getState().auth.user, 'id', 0);

  function headRows() {
    return [
      { item: 'item', registro: 'Registro', descricao: 'Descricao', quantidade: 'Quantidade', valorUnitario: 'Valor Unitário', valorTotal: 'Total' },
    ];
  }

  const [headerTabelaImpressao] = useState(headRows());

  useEffect(() => {
    async function getData(img) {
      // eslint-disable-next-line new-cap

      try {
        const usuario = await axiosAdmin.get(`/usuario/${idUsuario}`);
        let cnpjEmpresa = usuario.data.listaEmpresas[0].cnpj;
        if (cnpjEmpresa.length === 14) {
          cnpjEmpresa = cnpjEmpresa.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
        }

        const { data } = await axiosAdmin.get(`/osaparelho/${id}`);

        // console.log('data: ', data);

        if (data.idUsuario !== idUsuario) {
          displayErrors('Acesso negado', dispatch, navigate, '/consultaordemservicoaparelho', '');
        }

        let tipoMovimentacao = '';
        if (data.tipo === 'SERVICO') {
          tipoMovimentacao = 'Serviço';
        }
        if (data.tipo === 'GARANTIA') {
          tipoMovimentacao = 'Garantia';
        }
        if (data.tipo === 'ORCAMENTO') {
          tipoMovimentacao = 'Orçamento';
        }

        const empresa = data.empresa ? data.empresa : null;
        const oficina = data.oficinaOperador ? data.oficinaOperador : null;

        const result = [];

        let dados = {};
        let valorTotal = 0;
        data.listaOsAparelhoItem.forEach(el => {
          dados.item = el.item;
          dados.registro = el.produto.registro;
          dados.descricao = el.produto.descricao.trim();
          dados.quantidade = el.quantidade;
          dados.valorUnitario = `${formataMoeda(el.valorUnitario)}`;
          dados.valorTotal = `${formataMoeda(+el.quantidade * +el.valorUnitario)}`;

          valorTotal += +el.quantidade * +el.valorUnitario;

          result.push({ ...dados });
        });

        if (valorTotal > 0) {
          dados = {
            item: '',
            registro: '',
            descricao: '',
            quantidade: '',
            valorUnitario: 'Total O.S.',
            valorTotal: `${formataMoeda(+valorTotal)}`,
          };

          result.push({ ...dados });
        }

        // eslint-disable-next-line new-cap
        const doc = new jsPDF('p', 'mm', 'a4');

        // Header
        doc.rect(10, 5, 190, 26);
        if (!img.complete) {
          doc.addImage('logo.png', 'JPEG', 13, 12, 15, 15);
        } else {
          doc.addImage(img, 'JPEG', 13, 8, 20, 20);
        }

        doc.setFontSize(15);
        doc.text('Ordem Nº: ', 40, 11);
        doc.text(data.numero.toString(), 67, 11);

        doc.setFontSize(8);
        doc.text('Cliente: ', 40, 17);
        doc.setFontSize(10);
        doc.text(data.nomeCliente, 54, 17);
        doc.setFontSize(8);
        doc.text('Telefone: ', 40, 22);
        doc.setFontSize(10);
        doc.text(data.telefoneCliente, 54, 22);

        if (oficina != null) {
          doc.setFontSize(12);
          doc.text(oficina.nome, 84, 23);
        }

        doc.setFontSize(10);
        doc.text('Data: ', 40, 28);
        doc.setFontSize(12);
        doc.text(`${('00' + data.data[2]).slice(-2)}/${('00' + data.data[1]).slice(-2)}/${data.data[0]} ${('00' + data.data[3]).slice(-2)}:${('00' + data.data[4]).slice(-2)}`, 49, 28);

        doc.setFontSize(20);
        doc.text(tipoMovimentacao, 95, 12);

        doc.setFontSize(8);
        doc.text('Previsão Entrega: ', 84, 28);
        doc.setFontSize(10);
        if (data.dataPrevisaoEntrega !== null) {
          doc.text(`${('00' + data.dataPrevisaoEntrega[2]).slice(-2)}/${('00' + data.dataPrevisaoEntrega[1]).slice(-2)}/${data.dataPrevisaoEntrega[0]}  ${('00' + data.dataPrevisaoEntrega[3]).slice(-2)}:${('00' + data.dataPrevisaoEntrega[4]).slice(-2)}`, 108, 28);
        }

        doc.setFontSize(8);
        const razaoSocial = data.oficinaOperador && data.oficinaOperador.razaoSocial ? data.oficinaOperador.razaoSocial : empresa.nomeFantasia;
        doc.text(razaoSocial, 145, 9);

        const cnpj = data.oficinaOperador && data.oficinaOperador.cnpj ? data.oficinaOperador.cnpj : empresa.cnpj;
        doc.text(`CNPJ: ${cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')}`, 145, 13);

        const endereco = data.oficinaOperador && data.oficinaOperador.endereco ? data.oficinaOperador.endereco : empresa.endereco;
        const enderecoNumero = data.oficinaOperador && data.oficinaOperador.enderecoNumero ? data.oficinaOperador.enderecoNumero : empresa.enderecoNumero;
        const enderecoComNumero = `${endereco} - ${enderecoNumero}`;
        doc.text(enderecoComNumero, 145, 17);

        const uf = data.oficinaOperador && data.oficinaOperador.uf ? data.oficinaOperador.uf : empresa.uf;
        doc.text(uf, 145, 21);

        const cidade = data.oficinaOperador && data.oficinaOperador.cidade ? data.oficinaOperador.cidade : empresa.cidade;
        doc.text(cidade, 152, 21);

        const bairro = data.oficinaOperador && data.oficinaOperador.bairro ? data.oficinaOperador.bairro : empresa.bairro;
        doc.text(bairro, 145, 25);

        const telefone_1 = data.oficinaOperador && data.oficinaOperador.telefone_1 ? data.oficinaOperador.telefone_1 : empresa.telefone_1;
        doc.text(telefone_1, 145, 29);

        const telefone_2 = data.oficinaOperador && data.oficinaOperador.telefone_2 ? data.oficinaOperador.telefone_2 : empresa.telefone_2;
        doc.text(telefone_2, 170, 29);

        // caracteristicas do aparelho
        doc.rect(10, 31, 190, 12);

        doc.setFontSize(8);
        doc.text('Marca: ', 11, 35);
        doc.setFontSize(10);
        doc.text(data.marca, 21, 35);

        doc.setFontSize(8);
        doc.text('Modelo: ', 11, 40);
        doc.setFontSize(10);
        doc.text(data.modelo, 23, 40);

        doc.setFontSize(8);
        doc.text('IMEI: ', 80, 35);
        doc.setFontSize(10);
        doc.text(data.imei, 88, 35);

        doc.setFontSize(8);
        doc.text('Slot Chip: ', 80, 40);
        doc.setFontSize(10);
        doc.text(data.slotChip ? 'Sim' : 'Não', 94, 40);

        doc.setFontSize(8);
        doc.text('Condição de Teste: ', 118, 35);
        doc.setFontSize(10);
        doc.text(data.condicaoTeste ? 'Sim' : 'Não', 143, 35);

        doc.setFontSize(8);
        doc.text('Aparelho Liga: ', 118, 40);
        doc.setFontSize(10);
        doc.text(data.aparelhoLiga ? 'Sim' : 'Não', 137, 40);

        doc.setFontSize(8);
        doc.text('Aparelho Molhado: ', 165, 35);
        doc.setFontSize(10);
        doc.text(data.aparelhoMolhado ? 'Sim' : 'Não', 190, 35);

        doc.setFontSize(8);
        doc.text('Aparelho Carrega: ', 165, 40);
        doc.setFontSize(10);
        doc.text(data.aparelhoCarrega ? 'Sim' : 'Não', 189, 40);

        // queixas
        doc.rect(10, 43, 95, 14);

        doc.setFontSize(10);
        doc.text('Queixas do Cliente: ', 11, 47);
        doc.setFontSize(8);
        doc.text(data.queixas.substring(0, 59), 11, 51);
        doc.text(data.queixas.substring(60, 119), 11, 55);

        // avalicao e servicos
        doc.rect(105, 43, 95, 14);
        doc.setFontSize(10);
        doc.text('Avaliação Técnica / Serviço Realizado: ', 107, 47);
        doc.setFontSize(8);
        doc.text(data.avaliacaoTecnica ? data.avaliacaoTecnica.substring(0, 59) : '', 107, 52);
        doc.text(data.avaliacaoTecnica ? data.avaliacaoTecnica.substring(60, 119) : '', 107, 55);

        // observacao
        doc.rect(10, 57, 190, 11);

        doc.setFontSize(10);
        doc.text('Observações: ', 11, 61);
        doc.setFontSize(8);
        doc.text(data.observacao ? data.observacao.substring(0, 120) : '', 11, 65);

        // informacoes orcamento previo
        doc.rect(10, 111, 95, 14);

        doc.setFontSize(10);
        doc.text('Orçamento Prévio: ' + formataMoeda(data.valorOrcamento), 11, 115);
        doc.setFontSize(8);
        doc.text('OBS: Valor sujeito a alteração após a avaliação técnica.', 11, 119);
        doc.text('Caso haja alteração de valor, o serviço será feito mediante autorização', 11, 123);

        // avalicao e servicos
        doc.rect(105, 111, 95, 14);
        doc.setFontSize(8);
        doc.text('Após 60(sessenta) dias a partir da data da O.S o aparelho será desmonta-', 106, 114);
        doc.text('do e utilizado peças para pagamento do serviço feito. O cliente é inteira-', 106, 117);
        doc.text('mente responsável pela total procedência do aparelho. Só será entregue o ', 106, 120);
        doc.text('aparelho mediante apresentação da 2ª via do serviço. ', 106, 123);

        // ultima parte
        doc.rect(10, 125, 190, 20);
        doc.setFontSize(10);
        doc.text('APARELHO ENTREGUE TESTADO EM PERFEITAS CONDIÇÕES', 11, 129);

        doc.setFontSize(8);
        doc.text('Data Entrega: ', 130, 129);
        doc.setFontSize(10);
        doc.text(data.dataEntrega ? `${('00' + data.dataEntrega[2]).slice(-2)}/${('00' + data.dataEntrega[1]).slice(-2)}/${data.dataEntrega[0]}` : '', 148, 129);

        if (data.tipo === 'SERVICO') {
          doc.setFontSize(8);
          doc.text('Garantia: ', 172, 129);
          doc.setFontSize(10);
          doc.text('____ dias', 184, 129);
        }

        doc.setFontSize(8);
        doc.text('Assinatura do Cliente: ', 11, 135);
        doc.text('_______________________________________________________________ ', 37, 135);

        doc.setFontSize(8);
        doc.text('Garantia referente apenas ao serviço realizado. Aparelhos que caíram ou molharam não tem garantia.', 11, 141);
        doc.text('Horário de Funcionamento: Segunda a Sexta de 09:00H às 18:00H e Sábado: 9:00 às 13:00H.', 11, 144);

        const periodo = new Date();
        doc.text('Impresso: ', 169, 144);
        // eslint-disable-next-line prefer-template
        doc.text((`${('00' + periodo.getDate()).slice(-2)}/${('00' + (+periodo.getMonth() + 1)).slice(-2)}/${periodo.getFullYear()}`).toString(), 184, 144);

        doc.autoTable({
          styles: { fontSize: 7 },
          margin: { bottom: 185, top: 68, left: 10, right: 10 },
          theme: 'grid',
          head: headerTabelaImpressao,
          body: result,
          didDrawPage: () => {

          },
        });
        window.open(doc.output('bloburl'));
        // const ordemPrint = doc.output('blob');
        // const blobUrl = URL.createObjectURL(ordemPrint);
        // printJS(blobUrl);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('erro', err);
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        // funcao no pai pra fechar a tela de impressao
        finishPrintOsAparelho();
      }
    }

    const loadImage = async () => {
      let config = '';
      try {
        config = await axiosAdmin.get(`/configuracaosistema?idUsuario=${idUsuario}`);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      }

      const img = new Image();
      img.src = `/logotipos/${config.data.urlLogotipo}`;
      await img.decode().then(() => {

      }).catch(() => {
        img.src = 'logo.png';
      });
      getData(img);
    };
    loadImage();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dispatch, navigate]);

  return (
    <div></div>
  );
}
