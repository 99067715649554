import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { ToggleButton } from 'primereact/togglebutton';
import { InputNumber } from 'primereact/inputnumber';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input200px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

import ConsultaParticipante from '../participante/consultaParticipante';
import ConsultaBandeiraCartao from '../bandeiraCartao/consultaBandeiraCartao';

import { obterListaTipoBandeiraCartao } from '../../../services/tipoBandeiraCartao';
import { obterListaTipoOperacaoBancariaCartao } from '../../../services/tipoOperacaoBancariaCartaoService';

export default function CadastroAdministradoraCartao({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const id = get(payloadCadastro, 'id', 0);

  const [empresaCnpj, setEmpresaCnpj] = useState(get(payloadCadastro, 'cnpjEmpresa', 0));
  const [codigo, setCodigo] = useState('');
  const [descricao, setDescricao] = useState('');
  const [bandeira, setBandeira] = useState('OUTROS');
  const [tipoOperacao, setTipoOperacao] = useState('CREDITO');
  const [ativo, setAtivo] = useState(true);
  const [prazo, setPrazo] = useState(0);
  const [prazoParcelas, setPrazoParcelas] = useState(0);
  const [taxaAdministracao, setTaxaAdministracao] = useState(0);

  const [idParticipante, setIdParticipante] = useState(null);
  const [nomeParticipante, setNomeParticipante] = useState('');

  const [idBandeiraCartao, setIdBandeiraCartao] = useState(null);
  const [descricaoBandeira, setDescricaoBandeira] = useState('');

  const [showConsultaParticipante, setShowConsultaParticipante] = useState(false);
  const [showConsultaBandeiraCartao, setShowConsultaBandeiraCartao] = useState(false);

  const listaTipoBandeiraCartao = obterListaTipoBandeiraCartao();
  const listaTipoOperacaoBancariaCartao = obterListaTipoOperacaoBancariaCartao();

  useEffect(() => {
    let newTitle = 'Administradora de Cartão - Cadastro';
    if (id) {
      newTitle = 'Administradora de Cartão - Edição';
    }

    const mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/administradoracartao/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultaadministradoracartao');
          toast.error('Acesso negado');
        }

        setEmpresaCnpj(data.cnpjEmpresa);
        setCodigo(data.codigo);
        setDescricao(data.descricao);
        setIdParticipante(data.participante ? data.participante.id : null);
        setNomeParticipante(data.participante ? data.participante.razaoSocial : '');
        setBandeira(data.bandeira);
        setTipoOperacao(data.tipoOperacao);
        setAtivo(data.ativo);
        setPrazo(data.prazo);
        setPrazoParcelas(data.prazoParcelas);
        setTaxaAdministracao(data.taxaAdministracao);
        setIdBandeiraCartao(data.bandeiraCartao.id);
        setDescricaoBandeira(data.bandeiraCartao.descricao);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const shippingData = {
        idUsuario,
        codigo,
        descricao,
        idParticipante,
        idBandeiraCartao,
        bandeira,
        cnpjEmpresa: empresaCnpj.trim(),
        tipoOperacao,
        ativo,
        prazo,
        prazoParcelas,
        taxaAdministracao,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/administradoracartao/${id}`, shippingData), 'data', {});
        toast.success('Administradora de Cartão atualizada com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/administradoracartao', shippingData), 'data', {});
        toast.success('Administradora de Cartão cadastrada com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const childToParentParticipante = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdParticipante(e.id);
      }
      if (e.razaoSocial) {
        setNomeParticipante(e.razaoSocial);
      }
      setShowConsultaParticipante(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentBandeiraCartao = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdBandeiraCartao(e.id);
      }
      if (e.descricao) {
        setDescricaoBandeira(e.descricao);
      }
      setShowConsultaBandeiraCartao(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!descricao) {
      formErros = true;
      toast.error('Descrição deve ser informada');
    }

    if (!bandeira) {
      formErros = true;
      toast.error('Bandeira para NFe deve ser informada');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id ? 'Administradora de Cartão - Alteração' : 'Administradora de Cartão - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>{titulo()}</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const onHideConsultaParticipante = () => {
    setShowConsultaParticipante(false);
  };

  const onHideConsultaBandeiraCartao = () => {
    setShowConsultaParticipante(false);
  };

  const limparParticipanteSelecionado = () => {
    setIdParticipante(null);
    setNomeParticipante('');
  };

  const limparBandeiraCartaoSelecionado = () => {
    setIdBandeiraCartao(null);
    setDescricaoBandeira('');
  };

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Participantes"
        visible={showConsultaParticipante}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaParticipante()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParentParticipante} empresaCnpj={empresaCnpj} />
      </Dialog>

      <Dialog
        header="Consulta de Bandeira de cartão"
        visible={showConsultaBandeiraCartao}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaBandeiraCartao()}
      >
        <ConsultaBandeiraCartao menuMode={false} childToParent={childToParentBandeiraCartao} empresaCnpj={empresaCnpj} />
      </Dialog>

      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Código
              </Input200px>
              <InputText
                maxLength={40}
                value={codigo}
                onChange={e => setCodigo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-6">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Descrição
              </Input200px>
              <InputText
                maxLength={40}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Ativo
              </Input200px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={ativo}
                onChange={(e) => setAtivo(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Tipo
              </Input200px>
              <Dropdown
                options={listaTipoOperacaoBancariaCartao}
                value={tipoOperacao}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipoOperacao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Prazo 1ª parcela
              </Input200px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="decimal"
                inputId="minmaxfraction"
                maxFractionDigits={0}
                value={prazo}
                onValueChange={(e) => setPrazo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Prazo entre parcelas
              </Input200px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="decimal"
                inputId="minmaxfraction"
                maxFractionDigits={0}
                value={prazoParcelas}
                onValueChange={(e) => setPrazoParcelas(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Taxa administracao
              </Input200px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="decimal"
                inputId="minmaxfraction"
                maxFractionDigits={2}
                value={taxaAdministracao}
                onValueChange={(e) => setTaxaAdministracao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-5">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Participante
              </Input200px>
              <InputText
                disabled
                maxLength={255}
                value={nomeParticipante}
                onChange={e => setNomeParticipante(e.target.value)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaParticipante(true)}
              />
              <Button
                icon="pi pi-times"
                className="p-button-danger"
                onClick={() => limparParticipanteSelecionado()}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Bandeira
              </Input200px>
              <InputText
                disabled
                maxLength={40}
                value={descricaoBandeira}
                onChange={e => setDescricaoBandeira(e.target.value)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaBandeiraCartao(true)}
              />
              <Button
                icon="pi pi-times"
                className="p-button-danger"
                onClick={() => limparBandeiraCartaoSelecionado()}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Bandeira para NFe
              </Input200px>
              <Dropdown
                options={listaTipoBandeiraCartao}
                value={bandeira}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setBandeira(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
