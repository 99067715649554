import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';

import moment from 'moment';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input200px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function GerarRecebimento() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anoMes, setAnoMes] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  useEffect(() => {
    const newTitle = 'Gerar Recebimentos';

    dispatch(actions.titleHeaderChange({ newTitle }));
  }, [dispatch, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const _anoMes = moment(anoMes).format('MMYYYY');

      await axiosAdmin.put(`/elorecebimento/gerarrecebimento/${_anoMes}`);

      toast.success('Recebimentos gerados com sucesso');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    navigate('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formErros = false;

    if (formErros) return;

    salvarRegistro();
  };

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-8">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>Gerar Recebimentos</h6>
            </div>
          </div>
          <div className="col-lg-4">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Gerar Recebimentos
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Mês/Ano para Geração
              </Input200px>
              <Calendar
                view="month"
                mask="99/9999"
                dateFormat="mm/yy"
                locale="pt-BR"
                yearNavigator
                yearRange="2010:2099"
                value={anoMes}
                onChange={(e) => setAnoMes(e.value)}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
