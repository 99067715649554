export function obterListaCteTipoServico() {
  return (
    [
      { label: 'Normal', value: 'NORMAL' },
      { label: 'Subcontração', value: 'SUBCONTRATACAO' },
      { label: 'Redespacho', value: 'REDESPACHO' },
      { label: 'Intermediário', value: 'INTERMEDIARIO' },
    ]
  );
}
