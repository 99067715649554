import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get, isEmpty } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { displayErrors, formataDataMoment, converteData } from '../../../util/diversos';
import { Input100px, Input155px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import Card from '../../../components/card';
import Loading from '../../loading';
import axiosAdmin from '../../../services/axiosAdmin';

import ConsultaParticipante from '../../administrador/empresa/consultaEmpresa';
import ConsultaModulo from '../modulo/consultaModulo';

export default function CadastroTicket({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const id = get(payloadCadastro, 'id', 0);
  const tipoUsuario = get(store.getState().auth.user, 'tipoUsuario', null);

  const [cnpjEmpresa, setCnpjEmpresa] = useState(get(payloadCadastro, 'cnpj', ''));
  const [listaEmpresas, setListaEmpresas] = useState([]);

  const [dataAbertura, setDataAbertura] = useState(new Date());
  const [dataFechamento, setDataFechamento] = useState(null);
  const [idEmpresa, setIdEmpresa] = useState(null);
  const [nomeEmpresa, setNomeEmpresa] = useState('');
  const [tela, setTela] = useState('');
  const [situacao, setSituacao] = useState('ABERTO');
  const [tipo, setTipo] = useState('INDEFINIDO');
  const [descricao, setDescricao] = useState('');
  const [solucao, setSolucao] = useState('');
  const [idUsuario, setIdUsuario] = useState(get(store.getState().auth.user, 'id', null));

  const [descricaoModulo, setDescricaoModulo] = useState('');
  const [idModulo, setIdModulo] = useState(null);

  const [showConsultaParticipante, setShowConsultaParticipante] = useState(false);
  const [showConsultaModulo, setShowConsultaModulo] = useState(false);

  const [listaSituacao] = useState([
    { label: 'Aberto', value: 'ABERTO' },
    { label: 'Andamento', value: 'ANDAMENTO' },
    { label: 'Fechado', value: 'FECHADO' },
  ]);

  const [listaTipo] = useState([
    { label: 'Indefinido', value: 'INDEFINIDO' },
    { label: 'Suporte Online', value: 'SUPORTEONLINE' },
    { label: 'Visita in loco', value: 'VISITAINLOCO' },
    { label: 'Alteração Sistema', value: 'ALTERACAOSISTEMA' },
  ]);

  useEffect(() => {
    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/ticket/${id}`);

        const _dataAbertura = converteData(data.dataAbertura, true);
        const _dataFechamento = converteData(data.dataFechamento, true);

        setDataAbertura(_dataAbertura);
        setDataFechamento(_dataFechamento);
        setTela(data.tela);
        setSituacao(data.situacao);
        setTipo(data.tipo);
        setDescricao(data.descricao.trim());
        setSolucao(data.solucao.trim());
        setIdUsuario(data.idUsuario);

        if (tipoUsuario === 'EMPRESA') {
          setCnpjEmpresa(get(data.empresa, 'cnpj', null));
        } else {
          setIdEmpresa(get(data.empresa, 'id', null));
          setNomeEmpresa(get(data.empresa, 'razaoSocial', ''));
        }

        setDescricaoModulo(get(data.modulo, 'descricao', ''));
        setIdModulo(get(data.modulo, 'id', null));
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, navigate]);

  useEffect(() => {
    async function verificarSeMultiEmpresa() {
      if (tipoUsuario === 'EMPRESA') {
        try {
          setIsLoading(true);

          const params = `/usuario/${idUsuario}`;

          const response = await axiosAdmin.get(params);

          const _listaEmpresas = get(response.data, 'listaEmpresas', []);

          const listDropdown = [];

          let cnpjPreenchido = false;
          let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            cnpjPreenchido = true;
          }

          _listaEmpresas.forEach(element => {
            if (!cnpjPreenchido) {
              setCnpjEmpresa(element.cnpj);
              setIdEmpresa(element.id);
              _cnpjEmpresaStore = element.cnpj;
              cnpjPreenchido = true;
            }

            listDropdown.push(
              {
                label: element.razaoSocial,
                value: element.cnpj,
                id: element.id,
              });
          });

          // empresaCnpj recebido pelo childtoparent na criacao/edicao de nfe
          if (!isEmpty(cnpjEmpresa)) {
            setCnpjEmpresa(cnpjEmpresa);
          }

          listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

          setListaEmpresas(listDropdown);
        } catch (err) {
          displayErrors(err, dispatch, navigate, '/', '');
        } finally {
          setIsLoading(false);
        }
      }
    }

    verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // eslint-disable-next-line consistent-return
  async function salvarRegistro() {
    try {
      setIsLoading(true);

      let _dataAbertura = null;
      if (dataAbertura) {
        _dataAbertura = formataDataMoment(dataAbertura, '-', true);
      }

      let _dataFechamento = null;
      if (dataFechamento) {
        _dataFechamento = formataDataMoment(dataFechamento, '-', true);
      }

      if (situacao === 'FECHADO' && dataFechamento === null) {
        _dataFechamento = formataDataMoment(new Date(), '-', true);
      }

      if (situacao === 'ABERTO') {
        _dataFechamento = null;
      }

      let idEmpresaSelecionada = idEmpresa;
      if (tipoUsuario === 'EMPRESA') {
        listaEmpresas.forEach(el => {
          if (el.value === cnpjEmpresa) {
            idEmpresaSelecionada = el.id;
          }
        });
      }

      const shippingData = {
        dataAbertura: _dataAbertura,
        dataFechamento: _dataFechamento,
        idEmpresa: idEmpresaSelecionada,
        idModulo,
        situacao,
        tipo,
        tela,
        descricao,
        solucao,
        idUsuario,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = await axiosAdmin.put(`/ticket/${id}`, shippingData);
        toast.success('Ticket atualizado com sucesso');
      } else {
        payloadDataTableList = await axiosAdmin.post('/ticket', shippingData);
        toast.success('Ticket cadastrado com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (situacao.length < 3) {
      formErros = true;
      toast.error('Situação deve ser informada');
    }

    if (idEmpresa === null) {
      toast.info('Empresa deve ser informada.');
      return false;
    }

    if (idModulo === null) {
      toast.info('Módulo deve ser informado.');
      return false;
    }

    if (descricao.length < 3 || descricao.length > 510) {
      formErros = true;
      toast.error('Descricao deve ter entre 3 a 510 caracteres');
    }

    // eslint-disable-next-line consistent-return
    if (formErros) return;

    salvarRegistro();
  };

  const onHideConsultaParticipante = () => {
    setShowConsultaParticipante(false);
  };

  const childToParentParticipante = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdEmpresa(e.id);
      }
      if (e.razaoSocial) {
        setNomeEmpresa(e.razaoSocial);
      }
      setShowConsultaParticipante(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const childToParentModulo = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdModulo(e.id);
      }
      if (e.descricao) {
        setDescricaoModulo(e.descricao);
      }
      setShowConsultaModulo(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const onHideConsultaModulo = () => {
    setShowConsultaModulo(false);
  };

  // eslint-disable-next-line consistent-return
  async function handleBuscarModuloById(e) {
    try {
      setIsLoading(true);

      if (e.length <= 0) {
        setDescricaoModulo('');
        return false;
      }

      const params = `/modulo/${e.trim()}`;

      const response = await axiosAdmin.get(params);

      if (response.data) {
        setDescricaoModulo(response.data.descricao);
      } else {
        setDescricaoModulo('');
        toast.info('Nenhum módulo com esse código encontrado');
      }
    } catch (err) {
      setDescricaoModulo('');
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const titulo = () => (id ? 'Ticket - Alteração' : 'Ticket - Inclusão');

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Empresas"
        visible={showConsultaParticipante}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaParticipante()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParentParticipante} somenteLeitura="true" />
      </Dialog>

      <Dialog
        header="Consulta de Módulo"
        visible={showConsultaModulo}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaModulo()}
      >
        <ConsultaModulo menuMode={false} childToParent={childToParentModulo} />
      </Dialog>

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Abertura
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                showTime
                showSeconds
                hourFormat="24"
                mask="99/99/9999 99:99:99"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataAbertura}
                onChange={(e) => setDataAbertura(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Fechamento
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                showTime
                showSeconds
                hourFormat="24"
                mask="99/99/9999 99:99:99"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataFechamento}
                onChange={(e) => setDataFechamento(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Situação
              </Input155px>
              <Dropdown
                options={listaSituacao}
                value={situacao}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setSituacao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo
              </Input155px>
              <Dropdown
                options={listaTipo}
                value={tipo}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Empresa
              </Input155px>
              <InputText
                disabled
                maxLength={255}
                value={nomeEmpresa}
                onChange={e => setNomeEmpresa(e.target.value)}
              />
              <Button
                // eslint-disable-next-line no-unneeded-ternary
                disabled={(tipoUsuario === 'EMPRESA') ? true : false}
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaParticipante(true)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Cód. Módulo
              </Input155px>
              <InputText
                maxLength={255}
                value={idModulo}
                onChange={e => setIdModulo(e.target.value)}
                onBlur={e => handleBuscarModuloById(e.target.value)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaModulo(true)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <InputText
                disabled
                maxLength={255}
                value={descricaoModulo}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            {listaEmpresas.length > 1 ? (
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Empresa
                </Input100px>
                <Dropdown
                  options={listaEmpresas}
                  value={cnpjEmpresa}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => setCnpjEmpresa(e.target.value)}
                />
              </div>
            ) : ('')}
          </div>
        </div>

        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tela
              </Input155px>
              <InputText
                id="inputDescricao"
                maxLength={255}
                value={tela}
                onChange={e => setTela(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Descrição
              </Input155px>
              <InputTextarea
                rows={4}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Solução
              </Input155px>
              <InputTextarea
                rows={4}
                value={solucao}
                onChange={e => setSolucao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
