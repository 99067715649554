import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input200px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function CadastroBandeiraCartao({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const id = get(payloadCadastro, 'id', 0);

  const [empresaCnpj, setEmpresaCnpj] = useState(get(payloadCadastro, 'cnpjEmpresa', 0));
  const [descricao, setDescricao] = useState('');
  const [taxaAdministracao, setTaxaAdministracao] = useState(0);

  useEffect(() => {
    let newTitle = 'Centro de Custo - Cadastro';
    if (id) {
      newTitle = 'Centro de Custo - Edição';
    }

    const mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/carteiradigital/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultacarteiradigital');
          toast.error('Acesso negado');
        }

        setEmpresaCnpj(data.cnpjEmpresa);
        setDescricao(data.descricao);
        setTaxaAdministracao(data.taxaAdministracao);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const shippingData = {
        idUsuario,
        cnpjEmpresa: empresaCnpj.trim(),
        descricao,
        taxaAdministracao,
      };

      // console.log('shippingData: ', shippingData);

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/carteiradigital/${id}`, shippingData), 'data', {});
        toast.success('Carteira digital atualizada com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/carteiradigital', shippingData), 'data', {});
        toast.success('Carteira digital cadastrada com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!descricao) {
      formErros = true;
      toast.error('Descrição deve ser informada');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id ? 'Carteira digital - Alteração' : 'Carteira digital - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>{titulo()}</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-8">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Descrição
              </Input200px>
              <InputText
                maxLength={40}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Taxa administracao
              </Input200px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="decimal"
                inputId="minmaxfraction"
                maxFractionDigits={2}
                value={taxaAdministracao}
                onValueChange={(e) => setTaxaAdministracao(e.target.value)}
              />
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
}
