import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input100px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosAdmin from '../../../services/axiosAdmin';

export default function CadastroBanco() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(useParams(), 'id', 0);

  const [codigo, setCodigo] = useState('');
  const [descricao, setDescricao] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let newTitle = 'Banco - Cadastro';
    if (id) {
      newTitle = 'Banco - Edição';
    }

    dispatch(actions.titleHeaderChange({ newTitle }));

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/banco/${id}`);

        setCodigo(data.codigo.trim());
        setDescricao(data.descricao);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const shippingData = {
        codigo,
        descricao,
      };

      if (id > 0) {
        await axiosAdmin.put(`/banco/${id}`, shippingData);
        toast.success('Banco atualizado com sucesso');
      } else {
        await axiosAdmin.post('/banco', shippingData);
        toast.success('Banco cadastrado com sucesso');
      }

      navigate('/consultabanco');
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    navigate('/consultabanco');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (codigo.length !== 3) {
      formErros = true;
      toast.error('Código deve ter 3 números');
    }

    if (!descricao) {
      formErros = true;
      toast.error('Descrição deve ser informada');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id ? 'Banco - Alteração' : 'Banco - Inclusão');

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Código
              </Input100px>
              <InputText
                maxLength={3}
                value={codigo}
                onChange={e => setCodigo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-9">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Descrição
              </Input100px>
              <InputText
                maxLength={100}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
