import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { isEmail } from 'validator';
import { cpf as cpfValidator, cnpj as cnpjValidator } from 'cpf-cnpj-validator';

import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { InputNumber } from 'primereact/inputnumber';
import { AutoComplete } from 'primereact/autocomplete';
import { ToggleButton } from 'primereact/togglebutton';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { TabView, TabPanel } from 'primereact/tabview';

import moment from 'moment';
import store from '../../../store/store';

import { Input200px, Input155px, Input100px, Input50px } from '../../../styles/inputGroup';

import { soNumeros, formataTelefone, formataCnpjCpf, displayErrors, converteData, formataDataMoment } from '../../../util/diversos';

import * as actions from '../../../store/modules/auth/actions';

import Card from '../../../components/card';
import Loading from '../../loading';
import axiosAdmin from '../../../services/axiosAdmin';
import axiosViaCep from '../../../services/axiosViaCep';

export default function CadastroParticipante({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);
  const cnpjEmpresaProps = get(payloadCadastro, 'cnpjEmpresa', '');

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [inscricaoEstadual, setInscricaoEstadual] = useState('');
  const [razaoSocial, setRazaoSocial] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [endereco, setEndereco] = useState('');
  const [enderecoNumero, setEnderecoNumero] = useState('');
  const [enderecoComplemento, setEnderecoComplemento] = useState('');
  const [enderecoReferencia, setEnderecoReferencia] = useState('');
  const [bairro, setBairro] = useState('');
  const [codigoMunicipio, setCodigoMunicipio] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');
  const [codigoPais, setCodigoPais] = useState('01058');
  const [cep, setCep] = useState('');
  const [telefone1, setTelefone1] = useState('');
  const [telefone2, setTelefone2] = useState('');
  const [ativo, setAtivo] = useState(true);
  const [inadimplente, setInadimplente] = useState(false);
  const [dataCadastro, setDataCadastro] = useState(null);
  const [email, setEmail] = useState('');
  const [rg, setRg] = useState('');
  const [registroCliente, setRegistroCliente] = useState(0);
  const [registroFornecedor, setRegistroFornecedor] = useState(0);
  const [limiteCredito, setLimiteCredito] = useState(0);
  const [dataNascimento, setDataNascimento] = useState(null);
  const [observacao, setObservacao] = useState('');
  const [estadoCivil, setEstadoCivil] = useState('');
  const [nomePai, setNomePai] = useState('');
  const [nomeMae, setNomeMae] = useState('');
  const [nomeConjuge, setNomeConjuge] = useState('');
  const [nomePaiConjuge, setNomePaiConjuge] = useState('');
  const [nomeMaeConjuge, setNomeMaeConjuge] = useState('');
  const [cpfConjuge, setCpfConjuge] = useState('');
  const [rgConjuge, setRgConjuge] = useState('');
  const [nomeContato, setNomeContato] = useState('');
  const [indIEDest, setIndIEDest] = useState(0);
  const [autorizarCarteira, setAutorizarCarteira] = useState(false);
  const [listaMunicipios, setListaMunicipios] = useState([]);
  const [filteredMunicipios, setFilteredMunicipios] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [listaIndIEDest] = useState([
    { label: 'Definido pelo Sistema', value: 0 },
    { label: 'Contribuinte ICMS Pagamento à Vista', value: 1 },
    { label: 'Contribuinte Isento de Inscrição', value: 2 },
    { label: 'Não Contribuinte', value: 9 },
  ]);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  useEffect(() => {
    let newTitle = 'Participante - Cadastro';
    if (id) {
      newTitle = 'Participante - Edição';
    }

    let mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getMunicipios() {
      try {
        setIsLoading(true);

        const listDropdown = [];

        const { data: _municipios } = await axiosAdmin.get('/municipio');

        _municipios.forEach(element => {
          listDropdown.push({ label: element.descricao, value: element.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, ''), codigo: element.codigo, uf: element.uf });
        });

        setListaMunicipios(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    getMunicipios();

    if (id <= 0) {
      setCnpjEmpresa(cnpjEmpresaProps);
      return;
    }

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/participante/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultaparticipante');
          toast.error('Acesso negado');
        }

        const _dataCadastro = converteData(data.dataCadastro);

        const _dataNascimento = converteData(data.dataNascimento);

        setCnpjEmpresa(data.cnpjEmpresa.trim());
        setCnpj(formataCnpjCpf(data.cnpj));
        setInscricaoEstadual(data.inscricaoEstadual);
        setRazaoSocial(data.razaoSocial);
        setNomeFantasia(data.nomeFantasia);
        setEndereco(data.endereco);
        setEnderecoNumero(data.enderecoNumero);
        setEnderecoComplemento(data.enderecoComplemento);
        setEnderecoReferencia(data.enderecoReferencia);
        setBairro(data.bairro);
        setCodigoMunicipio(data.codigoMunicipio);
        setCidade(data.cidade);
        setUf(data.uf);
        setCodigoPais(data.codigoPais);
        setCep(data.cep);
        setTelefone1(data.telefone_1);
        setTelefone2(data.telefone_2);
        setAtivo(data.ativo);
        setInadimplente(data.inadimplente);
        setDataCadastro(_dataCadastro);
        setEmail(data.email);
        setRg(data.rg);
        setRegistroCliente(data.registroCliente);
        setRegistroFornecedor(data.registroFornecedor);
        setLimiteCredito(data.limiteCredito);
        setDataNascimento(_dataNascimento);
        setObservacao(data.observacao);
        setEstadoCivil(data.estadoCivil);
        setNomePai(data.nomePai);
        setNomeMae(data.nomeMae);
        setNomeConjuge(data.nomeConjuge);
        setNomePaiConjuge(data.nomePaiConjuge);
        setNomeMaeConjuge(data.nomeMaeConjuge);
        setCpfConjuge(data.cpfConjuge);
        setRgConjuge(data.rgConjuge);
        setNomeContato(data.nomeContato);
        setIndIEDest(data.indIEDest);
        setAutorizarCarteira(get(data, 'autorizarCarteira', false));
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let _dataCadastro = formataDataMoment(moment(), '-');

      if (id > 0) {
        _dataCadastro = formataDataMoment(dataCadastro, '-');
      }

      let _dataNascimento = null;

      if (dataNascimento) {
        _dataNascimento = formataDataMoment(dataNascimento, '-');
      }

      let _inscricaoEstadual = inscricaoEstadual;

      if (!_inscricaoEstadual) {
        _inscricaoEstadual = 'ISENTO';
      }

      const shippingData = {
        idUsuario,
        cnpjEmpresa,
        cnpj: soNumeros(cnpj),
        inscricaoEstadual: _inscricaoEstadual,
        razaoSocial,
        nomeFantasia,
        endereco,
        enderecoNumero,
        enderecoComplemento,
        enderecoReferencia,
        bairro,
        codigoMunicipio,
        cidade,
        uf,
        codigoPais,
        cep,
        telefone_1: telefone1,
        telefone_2: telefone2,
        ativo,
        inadimplente,
        dataCadastro: _dataCadastro,
        dataNascimento: _dataNascimento,
        email,
        rg,
        registroCliente,
        registroFornecedor,
        limiteCredito,
        observacao,
        estadoCivil,
        nomePai,
        nomeMae,
        nomeConjuge,
        nomePaiConjuge,
        nomeMaeConjuge,
        cpfConjuge,
        rgConjuge,
        nomeContato,
        indIEDest,
        autorizarCarteira,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/participante/${id}`, shippingData), 'data', {});
        toast.success('Participante atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/participante', shippingData), 'data', {});
        toast.success('Participante cadastrado com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!cnpj) {
      formErros = true;
      toast.error('CNPJ deve ser informado');
    } else if (!cpfValidator.isValid(soNumeros(cnpj)) && !cnpjValidator.isValid(soNumeros(cnpj))) {
      formErros = true;
      toast.error('CPF/CNPJ inválido');
    }
    if (razaoSocial.length < 3 || razaoSocial.length > 100) {
      formErros = true;
      toast.error('Razão Social deve ter entre 3 e 100 caracteres');
    }
    if (!endereco) {
      formErros = true;
      toast.error('Endereço deve ser informado.');
    }
    if (!enderecoNumero) {
      formErros = true;
      toast.error('Número do endereço deve ser informado.');
    }
    if (!bairro) {
      formErros = true;
      toast.error('Número do endereço deve ser informado.');
    }
    if (!codigoMunicipio) {
      formErros = true;
      toast.error('Código do Município deve ser informado');
    }
    if (codigoMunicipio && codigoMunicipio.length !== 7) {
      formErros = true;
      toast.error('Código do Município deve ter 7 caracteres');
    }
    if (!codigoPais) {
      formErros = true;
      toast.error('Código do País deve ser informado');
    }
    if (codigoPais && codigoPais.length !== 5) {
      formErros = true;
      toast.error('Código do País deve ter 5 caracteres');
    }
    if (limiteCredito < 0) {
      formErros = true;
      toast.error('Limite de Crédito não pode ser negativo');
    }
    if (email && !isEmail(email)) {
      formErros = true;
      toast.error('E-mail inválido.');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const onBlurCep = (e) => {
    e.preventDefault();

    async function getViaCep() {
      try {
        setIsLoading(true);

        const cepConsulta = cep.replace(/[^0-9]/g, '');

        const { data } = await axiosViaCep.get(`/${cepConsulta}/json/`);

        if (!data.erro && !endereco) {
          setEndereco(get(data, 'logradouro', ''));
          setEnderecoComplemento(get(data, 'complemento'));
          setBairro(get(data, 'bairro', ''));
          setCodigoMunicipio(get(data, 'ibge', ''));
          setCidade(get(data, 'localidade', ''));
          setUf(get(data, 'uf', ''));
        }
      } finally {
        setIsLoading(false);
      }
    }
    if (cep && cep.length === 10) {
      getViaCep();
    }
  };

  const searchMunicipios = (event) => {
    const listDropdown = [];

    listaMunicipios.forEach(element => {
      if (element.value.toLowerCase().startsWith(event.query.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''))) {
        listDropdown.push({ label: element.label, value: element.value, codigo: element.codigo, uf: element.uf });
      }
    });

    setFilteredMunicipios(listDropdown);
  };

  const onBlurCodigoMunicipio = (e) => {
    const listDropdown = [];

    listaMunicipios.forEach(element => {
      if (element.codigo !== undefined && element.codigo === e) {
        listDropdown.push({ label: element.label, value: element.value, codigo: element.codigo, uf: element.uf });
      }
    });

    setCidade('');
    setUf('');

    if (listDropdown.length > 0) {
      setCidade(get(listDropdown[0], 'label', ''));
      setUf(get(listDropdown[0], 'uf', ''));
    }
  };

  const handleChangeCidade = (event) => {
    const codigo = get(event.value, 'codigo', '');

    if (codigo !== '' && codigo.length === 7) {
      setCodigoMunicipio(codigo);
      onBlurCodigoMunicipio(codigo);
    } else {
      setCidade(event.value);
    }
  };

  const titulo = () => (id ? 'Participante - Alteração' : 'Participante - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>{titulo()}</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-success"
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                onClick={handleCancel}
                type="button"
                className="btn btn-danger"
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <TabView activeIndex={activeTabIndex} onTabChange={(e) => setActiveTabIndex(e.index)}>
          <TabPanel header="Principal">
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    CPF/CNPJ
                  </Input100px>
                  <InputText
                    maxLength={20}
                    value={cnpj}
                    onChange={e => setCnpj(e.target.value)}
                    onFocus={e => setCnpj(soNumeros(e.target.value))}
                    onBlur={e => setCnpj(formataCnpjCpf(e.target.value))}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Inscrição Estadual
                  </Input155px>
                  <InputText
                    maxLength={20}
                    value={inscricaoEstadual}
                    onChange={e => setInscricaoEstadual(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Razão Social
                  </Input155px>
                  <InputText
                    maxLength={100}
                    value={razaoSocial}
                    onChange={e => setRazaoSocial(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Nome Fantasia
                  </Input155px>
                  <InputText
                    maxLength={50}
                    value={nomeFantasia}
                    onChange={e => setNomeFantasia(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    CEP
                  </Input100px>
                  <InputMask
                    mask="99.999-999"
                    placeholder="  .   -   "
                    maxLength={10}
                    value={cep}
                    onChange={e => setCep(e.target.value)}
                    onBlur={e => onBlurCep(e)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Endereço
                  </Input100px>
                  <InputText
                    maxLength={100}
                    value={endereco}
                    onChange={e => setEndereco(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Número
                  </Input100px>
                  <InputText
                    maxLength={20}
                    value={enderecoNumero}
                    onChange={e => setEnderecoNumero(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Complemento
                  </Input155px>
                  <InputText
                    maxLength={50}
                    value={enderecoComplemento}
                    onChange={e => setEnderecoComplemento(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Bairro
                  </Input155px>
                  <InputText
                    maxLength={50}
                    value={bairro}
                    onChange={e => setBairro(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-8">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    End Referência
                  </Input155px>
                  <InputText
                    maxLength={255}
                    value={enderecoReferencia}
                    onChange={e => setEnderecoReferencia(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Código País
                  </Input155px>
                  <InputText
                    maxLength={5}
                    value={codigoPais}
                    onChange={e => setCodigoPais(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Código Município
                  </Input155px>
                  <InputText
                    maxLength={7}
                    value={codigoMunicipio}
                    onChange={e => setCodigoMunicipio(e.target.value)}
                    onBlur={e => onBlurCodigoMunicipio(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Cidade
                  </Input100px>
                  <AutoComplete
                    value={cidade}
                    maxLength={40}
                    suggestions={filteredMunicipios}
                    completeMethod={searchMunicipios}
                    field="label"
                    dropdown
                    onChange={e => handleChangeCidade(e)}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="p-inputgroup">
                  <Input50px className="p-inputgroup-addon">
                    UF
                  </Input50px>
                  <InputText
                    disabled
                    value={uf}
                    onChange={e => setUf(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Telefone 1
                  </Input100px>
                  <InputText
                    maxLength={20}
                    value={telefone1}
                    onChange={e => setTelefone1(e.target.value)}
                    onFocus={e => setTelefone1(soNumeros(e.target.value))}
                    onBlur={e => setTelefone1(formataTelefone(e.target.value))}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Telefone 2
                  </Input100px>
                  <InputText
                    maxLength={20}
                    value={telefone2}
                    onChange={e => setTelefone2(e.target.value)}
                    onFocus={() => setTelefone2(soNumeros(telefone2))}
                    onBlur={() => setTelefone2(formataTelefone(telefone2))}
                  />
                </div>
              </div>
              <div className="col-4">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Data Nascimento
                  </Input155px>
                  <Calendar
                    id="mask"
                    showIcon
                    mask="99/99/9999"
                    dateFormat="dd/mm/yy"
                    showButtonBar
                    showOnFocus={false}
                    locale="pt-BR"
                    value={dataNascimento}
                    onChange={(e) => setDataNascimento(e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-5">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Limite de Crédito
                  </Input155px>
                  <InputNumber
                    mode="currency"
                    inputId="currency-br"
                    currency="BRL"
                    locale="pt-BR"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    value={limiteCredito}
                    onValueChange={e => setLimiteCredito(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3"></div>
              <div className="col-2">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Inadimplente
                  </Input100px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={inadimplente}
                    onChange={(e) => setInadimplente(e.value)}
                  />
                </div>
              </div>
              <div className="col-2">
                <div className="p-inputgroup">
                  <Input100px className="p-inputgroup-addon">
                    Ativo
                  </Input100px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={ativo}
                    onChange={(e) => setAtivo(e.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Dados Adicionais">
            <div className="row form-elo">
              <div className="col-9">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    E-mail
                  </Input155px>
                  <InputText
                    maxLength={255}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    RG
                  </Input155px>
                  <InputText
                    maxLength={20}
                    value={rg}
                    onChange={e => setRg(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Registro Cliente
                  </Input155px>
                  <InputNumber
                    min={0}
                    max={999999}
                    readOnly
                    value={registroCliente}
                    onValueChange={e => setRegistroCliente(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Reg. Fornecedor
                  </Input155px>
                  <InputNumber
                    min={0}
                    max={999999}
                    readOnly
                    value={registroFornecedor}
                    onValueChange={e => setRegistroFornecedor(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Nome do Pai
                  </Input155px>
                  <InputText
                    maxLength={100}
                    value={nomePai}
                    onChange={e => setNomePai(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Nome da Mãe
                  </Input155px>
                  <InputText
                    maxLength={100}
                    value={nomeMae}
                    onChange={e => setNomeMae(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Estado Civil
                  </Input155px>
                  <InputText
                    maxLength={10}
                    value={estadoCivil}
                    onChange={e => setEstadoCivil(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Nome do Cônjuge
                  </Input155px>
                  <InputText
                    maxLength={100}
                    value={nomeConjuge}
                    onChange={e => setNomeConjuge(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Nome do Pai do Cônjuge
                  </Input200px>
                  <InputText
                    maxLength={100}
                    value={nomePaiConjuge}
                    onChange={e => setNomePaiConjuge(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Nome da Mãe Cônjuge
                  </Input200px>
                  <InputText
                    maxLength={100}
                    value={nomeMaeConjuge}
                    onChange={e => setNomeMaeConjuge(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    CPF do Cônjuge
                  </Input155px>
                  <InputText
                    maxLength={20}
                    value={cpfConjuge}
                    onChange={e => setCpfConjuge(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    RG do Cônjuge
                  </Input155px>
                  <InputText
                    maxLength={20}
                    value={rgConjuge}
                    onChange={e => setRgConjuge(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-lg-6">
                <div className="p-inputgroup">
                  <Input200px className="p-inputgroup-addon">
                    Indicador IE Destinatário
                  </Input200px>
                  <Dropdown
                    options={listaIndIEDest}
                    value={indIEDest}
                    emptyMessage="Nenhum registro a ser exibido"
                    onChange={e => setIndIEDest(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Nome Contato
                  </Input155px>
                  <InputText
                    maxLength={100}
                    value={nomeContato}
                    onChange={e => setNomeContato(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="row form-elo">
              <div className="col-9">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Observação
                  </Input155px>
                  <InputText
                    maxLength={100}
                    value={observacao}
                    onChange={e => setObservacao(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-3">
                <div className="p-inputgroup">
                  <Input155px className="p-inputgroup-addon">
                    Autorizar Carteira
                  </Input155px>
                  <ToggleButton
                    onLabel="Sim"
                    offLabel="Não"
                    onIcon="pi pi-check"
                    offIcon="pi pi-times"
                    checked={autorizarCarteira}
                    onChange={(e) => setAutorizarCarteira(e.value)}
                  />
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </Card>
    </div>
  );
}
