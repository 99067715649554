import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { addLocale } from 'primereact/api';
import { get, orderBy } from 'lodash';

import dateFormat from 'dateformat';

import * as actions from '../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors, formataMoeda } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';
import { Input100px, Input400px } from '../../../styles/inputGroup';

import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';
import CadastroLivroCaixa from './cadastroLivroCaixa';

export default function ConsultaLivroCaixa() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [permitirCadastrar, setPermitirCadastrar] = useState(true);
  const [permitirEditar, setPermitirEditar] = useState(true);
  const [permitirExcluir, setPermitirExcluir] = useState(true);

  const [showCadastro, setShowCadastro] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [saldoLivroCaixa, setSaldoLivroCaixa] = useState(0);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [dataTableList, setDataTableList] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [filtroAtualizado, setFiltroAtualizado] = useState(null);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findDataInicial, setFindDataInicial] = useState(null);
  const [findDataFinal, setFindDataFinal] = useState(null);
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [payloadCadastro, setPayloadCadastro] = useState({});
  const [columnsSetup] = useState([
    { field: 'data', header: 'Data', sort: true },
    { field: 'historico', header: 'Histórico', sort: false },
    { field: 'valorEntrada', header: 'Valor Entrada', sort: false },
    { field: 'valorSaida', header: 'Valor Saída', sort: false },
    { field: 'saldo', header: 'Saldo', sort: false },
  ]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  async function getSaldoLivroCaixa(filtrarCnpjEmpresa) {
    let saldoRetorno = 0;
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);
      const params = `/livrocaixa/saldo?idUsuario=${idUsuario}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      const response = await axiosAdmin.get(params);

      saldoRetorno = response.data;
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
    return saldoRetorno;
  }

  async function getData(searchFilter, filtrarCnpjEmpresa, page, size) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/livrocaixa/search?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy HH:MM:ss');
        const _dataFinal = dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy HH:MM:ss');

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);

      getSaldoLivroCaixa(filtrarCnpjEmpresa).then(resposta => {
        setSaldoLivroCaixa(resposta);
      });
    } catch (err) {
      displayErrors(err, dispatch, navigate, '/', '');
    } finally {
      setIsLoading(false);
    }
  }

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  };

  useEffect(() => {
    const operadorGrupoAcesso = get(store.getState().auth.user, 'operadorGrupoAcesso', null);

    if (operadorGrupoAcesso != null) {
      let listaGrupoAcessoBody = [];
      listaGrupoAcessoBody = operadorGrupoAcesso.listaGrupoAcessoBody;
      const permissaoCadastrar = listaGrupoAcessoBody.filter(el => el.modulo === 'CadastrarLivroCaixa');
      const permissaoEditar = listaGrupoAcessoBody.filter(el => el.modulo === 'EditarLivroCaixa');
      const permissaoExcluir = listaGrupoAcessoBody.filter(el => el.modulo === 'ExcluirLivroCaixa');

      if (permissaoCadastrar.length <= 0) {
        setPermitirCadastrar(false);
      }

      if (permissaoEditar.length <= 0) {
        setPermitirEditar(false);
      }

      if (permissaoExcluir.length <= 0) {
        setPermitirExcluir(false);
      }
    }
  }, []);

  useEffect(() => {
    async function verificarSeMultiEmpresa(idUsuario, searchFilter) {
      try {
        setIsLoading(true);

        const params = `/usuario/${idUsuario}`;

        const response = await axiosAdmin.get(params);

        const _listaEmpresas = get(response.data, 'listaEmpresas', []);

        const listDropdown = [];

        const _cnpjConsultaLivroCaixaCnpjEmpresa = store.getState().auth.consultaLivroCaixaCnpjEmpresa;

        let cnpjPreenchido = false;
        let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

        if (_cnpjConsultaLivroCaixaCnpjEmpresa === null || _cnpjConsultaLivroCaixaCnpjEmpresa === '') {
          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            const consultaLivroCaixaCnpjEmpresa = _cnpjEmpresaStore;
            dispatch(actions.consultaLivroCaixaCnpjEmpresaChange({ consultaLivroCaixaCnpjEmpresa }));
            cnpjPreenchido = true;
          }
        }

        _listaEmpresas.forEach(element => {
          if (element.ativo) {
            if (!cnpjPreenchido && (_cnpjConsultaLivroCaixaCnpjEmpresa === null || _cnpjConsultaLivroCaixaCnpjEmpresa === '')) {
              setCnpjEmpresa(element.cnpj);
              const consultaLivroCaixaCnpjEmpresa = element.cnpj;
              dispatch(actions.consultaLivroCaixaCnpjEmpresaChange({ consultaLivroCaixaCnpjEmpresa }));
              _cnpjEmpresaStore = element.cnpj;
              cnpjPreenchido = true;
            }

            const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

            listDropdown.push({ label: nomeEmpresa, value: element.cnpj, id: element.id });
          }
        });

        listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

        setListaEmpresas(listDropdown);

        if (!cnpjPreenchido && _cnpjConsultaLivroCaixaCnpjEmpresa !== null && _cnpjConsultaLivroCaixaCnpjEmpresa !== '') {
          setCnpjEmpresa(_cnpjConsultaLivroCaixaCnpjEmpresa);
          _cnpjEmpresaStore = _cnpjConsultaLivroCaixaCnpjEmpresa;
        }

        getData(searchFilter, _cnpjEmpresaStore, 0, lazyParams.rows);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    if (findDataInicial === null) {
      setFindDataInicial(new Date(store.getState().auth.consultaLivroCaixaDataInicial));
      setFindDataFinal(new Date(store.getState().auth.consultaLivroCaixaDataFinal));

      const _dataInicial = new Date(store.getState().auth.consultaLivroCaixaDataInicial);
      const _dataFinal = new Date(store.getState().auth.consultaLivroCaixaDataFinal);

      const searchFilter = { findDataInicial: _dataInicial, findDataFinal: _dataFinal };

      verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0), searchFilter);
    }

    if (filtroAtualizado) {
      setFiltroAtualizado(false);
      handleFind();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filtroAtualizado, saldoLivroCaixa]);

  // eslint-disable-next-line consistent-return
  const handleRegister = () => {
    if (!permitirCadastrar) {
      toast.info('Usuário não autorizado a cadastrar.');
      return false;
    }

    const id = 0;

    setPayloadCadastro({ id, cnpjEmpresa });

    setShowCadastro(true);
  };

  // eslint-disable-next-line consistent-return
  const handleEdit = (e) => {
    e.preventDefault();

    if (!permitirEditar) {
      toast.info('Usuário não autorizado a editar.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      const idFinanceiro = get(selectedItens, 'idFinanceiro', 0);

      if (idFinanceiro !== null && idFinanceiro > 0) {
        toast.warning('Registro gerado por conciliação, alteração não permitida');
      } else {
        setPayloadCadastro({ id, cnpjEmpresa });
        setShowCadastro(true);
      }
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleDelete = (e) => {
    e.preventDefault();

    if (!permitirExcluir) {
      toast.info('Usuário não autorizado a excluir.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      const idFinanceiro = get(selectedItens, 'idFinanceiro', 0);

      if (idFinanceiro !== null && idFinanceiro > 0) {
        toast.warning('Registro gerado por conciliação, exclusão não permitida');
      } else {
        setShowConfirmDialog(true);
      }
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/livrocaixa/${idExclusao}`);

        // const _itens = dataTableList.filter(val => val.id !== idExclusao);
        // setDataTableList(_itens);

        const searchFilter = { findDataInicial, findDataFinal };

        getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);

        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  const onSort = (e) => {
    setSortField(e.sortField);
    setSortOrder(e.sortOrder);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findDataInicial, findDataFinal };

    getData(searchFilter, cnpjEmpresa, event.page, event.rows);
  };

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const childToParentCadastro = (payloadDataTableList, id) => {
    if (payloadDataTableList) {
      if (id > 0) {
        const searchFilter = { findDataInicial, findDataFinal };

        getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
      } else {
        const _dataTableList = [];
        _dataTableList.push(payloadDataTableList);

        setDataTableList(_dataTableList);

        const _lazyParams = { ...lazyParams, first: 0, page: 0 };
        setLazyParams(_lazyParams);
        setTotalRecords(1);
      }
    }

    setShowCadastro(false);
  };

  async function licencaValida(empresaId) {
    // eslint-disable-next-line consistent-return
    const retorno = await axiosAdmin.get(`/empresa/verificardatalicenca/${empresaId}`);
    return retorno.data;
  }

  // eslint-disable-next-line consistent-return
  async function handleCnpjEmpresa(e) {
    let empresaId = 0;

    listaEmpresas.forEach(element => {
      if (element.value === e.target.value) {
        // eslint-disable-next-line no-unused-expressions
        empresaId = element.id;
      }
    });

    const isLicencaValida = await licencaValida(empresaId);
    if (!isLicencaValida) {
      toast.info('Cliente com licença expirada. Efetuar pagamento mensalidade.');
      // navigate('/pagamento');
      return false;
    }

    setCnpjEmpresa(e.target.value);
    const consultaLivroCaixaCnpjEmpresa = e.target.value;
    dispatch(actions.consultaLivroCaixaCnpjEmpresaChange({ consultaLivroCaixaCnpjEmpresa }));
  }

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroLivroCaixa payloadCadastro={payloadCadastro} childToParentCadastro={childToParentCadastro} />
      </Dialog>

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>
              LIVRO CAIXA
            </h6>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Empresa
                </Input100px>
                <Dropdown
                  options={listaEmpresas}
                  value={cnpjEmpresa}
                  emptyMessage="Nenhum registro a ser exibido"
                  onChange={(e) => handleCnpjEmpresa(e)}
                />
              </div>
            </div>
            <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Input400px className="p-inputgroup-addon">
                Saldo do Livro Caixa: {formataMoeda(saldoLivroCaixa)}
              </Input400px>
            </div>
          </div>
          <br></br>
          <div className="row align-items-center">
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data inicial
                </Input100px>
                <Calendar
                  id="mask"
                  showIcon
                  showTime
                  showSeconds
                  hourFormat="24"
                  mask="99/99/9999 99:99:99"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => {
                    setFindDataInicial(e.value);
                    const consultaLivroCaixaDataInicial = e.value;
                    const consultaLivroCaixaDataFinal = findDataFinal;
                    dispatch(actions.consultaLivroCaixaDataInicialChange({ consultaLivroCaixaDataInicial, consultaLivroCaixaDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Data final
                </Input100px>
                <Calendar
                  id="mask"
                  showIcon
                  showTime
                  showSeconds
                  hourFormat="24"
                  mask="99/99/9999 99:99:99"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => {
                    setFindDataFinal(e.value);
                    const consultaLivroCaixaDataInicial = findDataInicial;
                    const consultaLivroCaixaDataFinal = e.value;
                    dispatch(actions.consultaLivroCaixaDataInicialChange({ consultaLivroCaixaDataInicial, consultaLivroCaixaDataFinal }));
                  }}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
            </div>
          </div>
          <div className="row align-items-center" style={{ marginTop: '10px' }}>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            value={dataTableList}
            size="small"
            stripedRows
            responsiveLayout="scroll"
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            dataKey="id"
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
