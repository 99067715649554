export function obterListaCteModalidade() {
  return (
    [
      { label: 'Rodoviário', value: 'RODOVIARIO' },
      { label: 'Aéreo', value: 'Aéreo' },
      { label: 'Aquaviário', value: 'AQUAVIARIO' },
      { label: 'Ferroviário', value: 'FERROVIARIO' },
      { label: 'Dutoviário', value: 'DUTOVIARIO' },
      { label: 'Multimodal', value: 'MULTIMODAL' },
    ]
  );
}
