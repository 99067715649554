import React from 'react';

import 'bootswatch/dist/flatly/bootstrap.css';
import '../styles/custom.css';

import 'primereact/resources/themes/lara-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from '../store/store';

import Body from '../components/body';

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Body />
        <ToastContainer autoClose={3000} className="toast-container" />
      </PersistGate>
    </Provider>
  );
}

export default App;
