import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { addLocale } from 'primereact/api';
import { Checkbox } from 'primereact/checkbox';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors, converteData, formataDataMoment } from '../../../util/diversos';
import { Input155px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function CadastroRecebimentoEmAberto({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const id = get(payloadCadastro, 'id', 0);

  const [dataVencimento, setDataVencimento] = useState(null);
  const [dataQuitacao, setDataQuitacao] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [nomeEmpresa, setNomeEmpresa] = useState('');
  const [valorAberto, setValorAberto] = useState(0);
  const [valorPago, setValorPago] = useState(0);
  const [nossoNumero, setNossoNumero] = useState('');
  const [emailEnviado, setEmailEnviado] = useState(false);
  const [listaEmpresa, setListaEmpresa] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  useEffect(() => {
    let newTitle = 'Recebimento - Cadastro';
    if (id) {
      newTitle = 'Recebimento - Edição';
    }

    let mostrarMensagemErro = true;

    dispatch(actions.titleHeaderChange({ newTitle }));

    async function getEmpresas() {
      try {
        setIsLoading(true);

        const listDropdown = [];
        const { data: _empresas } = await axiosAdmin.get('/empresa');

        _empresas.forEach(element => {
          listDropdown.push({ label: `${element.razaoSocial}  (${element.nomeFantasia})`, value: element.id });
        });

        setListaEmpresa(listDropdown);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
        mostrarMensagemErro = false;
      } finally {
        setIsLoading(false);
      }
    }

    getEmpresas();

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/elorecebimento/${id}`);

        const _dataVencimento = converteData(data.dataVencimento);
        const _dataQuitacao = converteData(data.dataQuitacao);

        setDataVencimento(_dataVencimento);
        setDataQuitacao(_dataQuitacao);
        setEmpresa(get(data.empresa, 'id', null));
        setNomeEmpresa(data.nomeEmpresa);
        setValorAberto(data.valorAberto);
        setValorPago(data.valorPago);
        setNossoNumero(data.nossoNumero);
        setEmailEnviado(data.emailEnviado);
      } catch (err) {
        if (mostrarMensagemErro) {
          displayErrors(err, dispatch, navigate, '/', '');
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      let payloadDataTableList = {};

      let _dataVencimento = null;
      if (dataVencimento) {
        _dataVencimento = formataDataMoment(dataVencimento, '-');
      }
      let _dataQuitacao = null;
      if (dataQuitacao) {
        _dataQuitacao = formataDataMoment(dataQuitacao, '-');
      }

      const shippingData = {
        dataVencimento: _dataVencimento,
        dataQuitacao: _dataQuitacao,
        empresa,
        nomeEmpresa,
        valorAberto,
        valorPago,
        nossoNumero,
        emailEnviado,
      };

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/elorecebimento/${id}`, shippingData), 'data', {});
        toast.success('Recebimento atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/elorecebimento', shippingData), 'data', {});
        toast.success('Recebimento cadastrado com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!dataVencimento) {
      formErros = true;
      toast.error('Data de Vencimento deve ser informada');
    }
    if (!empresa || empresa <= 0) {
      formErros = true;
      toast.error('Empresa deve ser informada');
    }
    if (valorAberto < 0) {
      formErros = true;
      toast.error('Valor em Aberto não pode ser negativo');
    }
    if (valorPago < 0) {
      formErros = true;
      toast.error('Valor Pago não pode ser negativo');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id ? 'Recebimento - Alteração' : 'Recebimento - Inclusão');

  function botoesFinalizar() {
    return (
      <div className="card-header" style={{ marginLeft: '12px', marginRight: '12px', width: '98%' }}>
        <div className="row">
          <div className="col-lg-9">
            <div style={{ display: 'flex', justifyContent: 'start', position: 'relative', top: '20%' }}>
              <h6>{titulo()}</h6>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <button
                type="button"
                className="btn btn-success"
                onClick={handleSubmit}
              >
                <i className="pi pi-check"></i>
                Salvar
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={handleCancel}
              >
                <i className="pi pi-times"></i>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card style={{ marginTop: '16px' }}>
        <div className="row form-elo">
          { botoesFinalizar() }
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Vencimento
              </Input155px>
              <Calendar
                autoFocus
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataVencimento}
                onChange={(e) => setDataVencimento(e.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Quitação
              </Input155px>
              <Calendar
                id="mask"
                showIcon
                mask="99/99/9999"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataQuitacao}
                onChange={(e) => setDataQuitacao(e.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Nosso Número
              </Input155px>
              <InputText
                maxLength={100}
                value={nossoNumero}
                onChange={e => setNossoNumero(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-lg-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Empresa
              </Input155px>
              <Dropdown
                filter
                filterBy="label"
                options={listaEmpresa}
                value={empresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => setEmpresa(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-5">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor em Aberto
              </Input155px>
              <InputNumber
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorAberto}
                onValueChange={e => setValorAberto(e.target.value)}
              />
            </div>
          </div>
          <div className="col-5">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Pago
              </Input155px>
              <InputNumber
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorPago}
                onValueChange={e => setValorPago(e.target.value)}
              />
            </div>
          </div>
          <div className="col-2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Checkbox
              inputId="binary"
              checked={emailEnviado}
              onChange={e => setEmailEnviado(e.checked)}
            >
            </Checkbox>
            <label
              htmlFor="cb1"
              className="p-checkbox-label"
            >
                &nbsp;E-Mail Enviado
            </label>
          </div>
        </div>
      </Card>
    </div>
  );
}
